import React, { FC, useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { animateScroll as scroll, scroller } from 'react-scroll'
import { Home as HomeIndex } from './home'

export const Home: FC = () => {
    const location = useLocation()
    const { state }: any = location
    useLayoutEffect(() => {
        if (state && state?.title === 'rental') {
            handleContact()
        } else {
            setTimeout(
                () =>
                    scroll.scrollTo(0, {
                        duration: 1000,
                        delay: 100,
                        smooth: true,
                        isDynamic: true
                    }),
                360
            )
        }
    }, [state])

    const handleContact = () =>
        setTimeout(
            () =>
                scroller.scrollTo('myScrollToElement', {
                    duration: 1000,
                    delay: 100,
                    smooth: true,
                    isDynamic: true
                }),
            360
        )

    return <HomeIndex onContact={handleContact} />
}
