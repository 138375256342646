import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'

import { useResponsive, useShowMessage } from 'shared/hooks'
import { useI18n } from 'core/language/api'

import { SimpleGrid } from '@mantine/core'

import left from '../assets/1.png'
import middle from '../assets/2.png'
import right from '../assets/3.png'
import check from '../assets/check.png'

import type { SkuListItem } from 'core/product/models'

interface PropsItem {
    canDirectBuy: boolean
    description: string[]
    id: string
    image: string
    mobileDescription: string
    name: string
    showPrice: string
    skuList: SkuListItem[]
}
interface Props {
    list: PropsItem[]
    productId: string
    productButtonName: string
    titleSize: string
    isEventOff: boolean
}

const imgArr = [left, middle, right]

const getColor = (index: number) => {
    if (index % 3 === 0) {
        return '#E886CA'
    }
    if (index % 3 === 1) {
        return '#7979E3'
    }
    return '#22BCFF'
}

const getBg = (index: number) => {
    if (index % 3 === 0) {
        return 'linear-gradient(90deg, #81C2FF 0%, #FF94DB 94%)'
    }
    if (index % 3 === 1) {
        return '#7979EA'
    }
    return 'linear-gradient(90deg, #78C1FD 12%, #D7A0FF 91%)'
}

export const EventProductList: FC<Props> = ({
    list,
    productId,
    productButtonName,
    titleSize,
    isEventOff
}) => {
    const { md } = useResponsive()

    const navigate = useNavigate()

    const {
        methods: { t }
    } = useI18n()
    const {
        methods: { error, singleModal }
    } = useShowMessage()

    return (
        <>
            <Container>
                <SimpleGrid cols={md ? 3 : 1} spacing="sm">
                    {list.map(({ id, name, showPrice, description, skuList }, index) => (
                        <ProductItem key={name}>
                            <ProductHeader className="namePriceBox">
                                <ProductImage width={60} height={60} src={imgArr[index % 3]} />
                                <ProductPrice className="namePrice" style={{ color: getColor(index) }}>
                                    ${showPrice}
                                </ProductPrice>
                                <div
                                    className="name namePrice"
                                    style={{ fontSize: `${titleSize}px`, color: getColor(index) }}
                                >
                                    {name}
                                </div>
                            </ProductHeader>
                            <ProductList>
                                {description.map((item: string, index: number) => (
                                    <div className="descriptionItems" key={index}>
                                        {item && <img width={16} height={16} src={check} alt="" />}
                                        <div className="text">{item}</div>
                                    </div>
                                ))}
                            </ProductList>
                            <ProductButton
                                onClick={() => {
                                    if (isEventOff) {
                                        singleModal({
                                            message: (
                                                <>
                                                    <div>{t('error.event.end.title')}</div>
                                                    <div>{t('error.event.end.msg')}</div>
                                                </>
                                            )
                                        })
                                        return
                                    }
                                    skuList[0].stock > 0
                                        ? navigate(`/event/${productId}/products/${id}/create_order`)
                                        : error('This item is sold out')
                                }}
                                style={{ background: getBg(index), textTransform: 'uppercase' }}
                            >
                                {skuList[0].stock > 0 ? productButtonName : 'Sold Out'}
                            </ProductButton>
                        </ProductItem>
                    ))}
                </SimpleGrid>
            </Container>
        </>
    )
}
const Container = styled.div`
    /* flex: 1; */
    width: 100%;
    padding: 40px 0 55px 0;
    display: flex;
    justify-content: center;

    @media (max-width: 576px) {
        width: 100%;
        flex-direction: column;
        padding: 0px 38px 64px;
    }
`

const ProductList = styled.div`
    width: 100%;
    flex: 1;
    padding: 55px 30px 12px 30px;
    background: #3a3e50;
    border-radius: 0px 0px 34px 34px;

    .descriptionItems {
        height: auto;
        display: flex;
        align-items: flex-start;
        margin-bottom: 24px;
        .text {
            width: 100%;
            font-family: Roboto-Regular;
            font-size: 14px;
            line-height: 18px;
            color: #dce1e8;
            letter-spacing: 0;
            margin-left: 10px;
            margin-bottom: 0;
        }
    }
`
const ProductItem = styled.div`
    width: 300px;
    flex-shrink: 0;
    border-radius: 34px;
    background: #222833;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 21px 40px 21px;
    @media (max-width: 576px) {
        width: 100%;
        margin: 40px 0px 0px 0px;
    }
`
const ProductHeader = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 282px;
    padding-bottom: 30px;
    .name {
        width: 100%;
        height: 50px;
        font-family: Roboto-Regular;
        text-align: center;
        color: #7979e3;
        letter-spacing: 0;
        padding: 0px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        line-height: 20px;
    }
`
const ProductPrice = styled.div`
    text-align: center;
    margin-bottom: 20px;
    color: #7979e3;
    font-family: Roboto-Medium;
    font-size: 26px;
    letter-spacing: 0;
    line-height: 28px;
`
const ProductButton = styled.button`
    width: 240px;
    height: 50px;
    line-height: 50px;
    background: #7979ea;
    border-radius: 35.18px;
    margin: 0;
    text-align: center;
    position: absolute;
    top: 258px;
    left: calc(50% - (240px / 2));
    border-radius: 35.18px;
    font-family: Roboto-Bold;
    font-size: 17px;
    letter-spacing: 0;
    color: #ffffff;
`
const ProductImage = styled.img`
    margin: 40px 0px 30px;
`
