import React, { FC } from 'react'
import styled from '@emotion/styled'

import { useResponsive } from 'shared/hooks'

import { Button, Checkbox, Input, Radio } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

import type { EventProductState } from 'core/event/models'

interface Props {
    productId: string
    image: string
    name: string
    showPrice: string
    className?: string
    formData: EventProductState
    onRadioChange: (productId: string) => void
    onCheckBoxChange: (productId: string) => void
    onDescriptionAdd: (productId: string, isShow?: number) => void
    onDescriptionRemove: (index: number, productId: string) => void
    onDescriptionChange: (value: string, index: number, productId: string) => void
}

export const EventProduct: FC<Props> = ({
    productId,
    image,
    name,
    showPrice,
    formData,
    onRadioChange,
    onCheckBoxChange,
    onDescriptionAdd,
    onDescriptionRemove,
    onDescriptionChange
}) => {
    const { md } = useResponsive()

    const onInputAdd = () => {
        if (!formData) return onDescriptionAdd(productId, 1)
        if (!formData.checked) return onDescriptionAdd(productId, 2)
        onDescriptionAdd(formData.productId)
    }

    return (
        <Container md={md}>
            <div className="flex">
                <div className="img" style={{ backgroundImage: `url(${image})` }} />
                <div className="info">
                    <div className="infoTitle">
                        <div className="text">{name}</div>
                    </div>
                    <div className="my-4">
                        {formData &&
                            formData.descriptionList.map((item, index) => (
                                <div
                                    key={index}
                                    className="flex items-center addInput"
                                    style={{ margin: '10px 0' }}
                                >
                                    <Input.TextArea
                                        autoSize
                                        value={item}
                                        style={{ width: md ? 400 : 130, marginRight: 10 }}
                                        onChange={({ target: { value } }) =>
                                            onDescriptionChange(value, index, productId)
                                        }
                                    />
                                    <MinusCircleOutlined
                                        onClick={() => onDescriptionRemove(index, formData.productId)}
                                    />
                                </div>
                            ))}
                        <Button
                            className="addInpBtn"
                            type="dashed"
                            onClick={() => onInputAdd()}
                            block
                            icon={<PlusOutlined style={{ fontSize: 18 }} />}
                            style={{
                                minWidth: '130px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                lineHeight: '3px'
                            }}
                        >
                            Add Discription
                        </Button>
                    </div>
                </div>
                <div className="priceCheck">
                    <div className="price">
                        <span>$</span> {showPrice}
                    </div>
                    <Checkbox
                        checked={formData && formData.checked}
                        onClick={() => onCheckBoxChange(productId)}
                    />
                </div>
            </div>
            <div className="radioBox" style={{ fontFamily: 'PingFang SC', fontSize: 18 }}>
                <div className="radioName">Product Price:</div>
                <Radio.Group
                    className="radios"
                    value={formData ? formData.isDaily : false}
                    onChange={() => onRadioChange(productId)}
                >
                    <Radio className="radioLeft" value={false} style={{ fontSize: 18 }}>
                        Per Event
                    </Radio>
                    <Radio value={true} style={{ fontSize: 18 }}>
                        Per Day
                    </Radio>
                </Radio.Group>
            </div>
        </Container>
    )
}

const Container = styled.div<{ md: boolean }>`
    margin: 10px 0;
    padding: 20px 30px 20px 20px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    @media (max-width: 576px) {
        background: #fff;
        box-shadow: 0 2px 14px 0 rgba(204, 204, 204, 0.5);
    }

    .img {
        width: 100px;
        height: 100px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        cursor: pointer;
        @media (max-width: 576px) {
            width: 50px;
            height: 50px;
            flex-shrink: 0;
        }
    }
    .info {
        padding: 0 ${({ md }) => (md ? '16%' : '20px')} 0 20px;
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    .priceCheck {
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
    }
    .infoTitle {
        font-family: PingFangSC-Semibold;
        font-size: 16px;
        color: #303030;
        line-height: 26px;
        font-weight: bold;
        .text {
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        @media (max-width: 576px) {
            color: #000;
            height: 40px;
            font-size: 16px;
            line-height: 20px;
        }
    }
    .contentBlock {
        box-sizing: content-box;
        position: relative;
        height: 88px;
        overflow: hidden;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #8c8c8c;
        &.showContent {
            min-height: 88px;
            height: auto;
            overflow: visible;
        }
    }
    .more {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #0091ff;
        text-decoration: underline;
        cursor: pointer;
    }
    .price {
        display: flex;
        align-items: center;
        font-family: PingFangSC-Semibold;
        font-size: 18px;
        color: #2f2e2e;
        letter-spacing: 0;
        text-align: center;
        line-height: 3.2rem;
        font-weight: bold;
        @media (max-width: 1440px) {
            font-size: 2.4rem;
            line-height: 3.2rem;
        }
        @media (max-width: 576px) {
            height: 30px;
            font-size: 14px;
            line-height: 20px;
        }
    }
    .addInpBtn {
        width: 400px;
        @media (max-width: 576px) {
            width: 120px;
        }
    }
    .addInput {
        input {
            width: 400px;
            @media (max-width: 576px) {
                width: 120px;
            }
        }
    }
    .radioBox {
        width: 100%;
        display: flex;
        font-family: PingFangSC-Semibold;
        font-size: 18px;
        color: #000000;
        letter-spacing: 0;
        line-height: 25px;
        font-weight: 600;
        margin-top: 40px;
        .radioLeft {
            margin: 0px 100px;
        }
        @media (max-width: 576px) {
            width: 174px;
            flex-direction: column;
            .radioLeft {
                margin-bottom: 20px;
            }
            .radioName {
                margin-bottom: 20px;
            }
            .radioLeft {
                margin-left: 0;
                margin-right: 20px;
            }
        }
    }
`
