import { Language } from 'core/language/models'
const LANGUAGE_KEY = 'Language'

export const getLanguage = () => window.localStorage.getItem(LANGUAGE_KEY)

export const setLanguage = (language: Language) => {
    window.localStorage.setItem(LANGUAGE_KEY, language)
}
export const removeLanguage = () => {
    window.localStorage.removeItem(LANGUAGE_KEY)
}

const CURRENT_USE_LANGUAGE_KEY = 'CURRENT_USE_LANGUAGE'

export const getCurrentUseLanguage = () => window.localStorage.getItem(CURRENT_USE_LANGUAGE_KEY)

export const setCurrentUseLanguage = (language: Language) => {
    window.localStorage.setItem(CURRENT_USE_LANGUAGE_KEY, language)
}

export const removeCurrentUseLanguage = () => {
    window.localStorage.removeItem(CURRENT_USE_LANGUAGE_KEY)
}
