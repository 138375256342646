export interface AffDetail {}

export enum TAB_TYPE_ENUM {
    EMAIL = 'email',
    PHONE = 'phone',
    PASSWORD = 'password',
    AFFILIATE_LINK = 'affiliate-link',
    ADDRESS = 'address',
    ORDERS = 'orders',
    WALLETADDRESS = 'walletaddress'
}

export interface Refer {
    link: string
    shareClickCount: string
    shareRegisterCount: string
    totalAmount: number
    withdrawAmount: number
    ingAmount: number
}

export interface ListParams {
    pageSize: string
    pageNum: string
}

export interface CommissionItem {
    id: string
    accountId: string
    createTime: string
    isGive: boolean
    origin: string
    originId: string
    value: number
    productName: string[]
    totalPrice: number
}
