import React, { FC, useCallback, useEffect, Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { logEvent } from 'firebase/analytics'
import styled from '@emotion/styled'

import { useI18n } from 'core/language/api'
import { useCreateOrder } from 'core/order/api'
import { useMiniCartDrawer } from 'core/cart/api'
import { useCartRender } from './api/useCartRender'
import { useApiError, useSignInRedirect, useShowMessage } from 'shared/hooks'
import { analytics, convertToDollar } from 'shared/utils'

import { Durations } from 'components'
import { DeleteIcon, QuantityBtn } from './components'

import rightIcon from './assets/right-white.png'

import type { CheckoutRequestItem } from 'core/checkout/models'

interface Props {
    onClose: () => void
}

export const MiniCart: FC<Props> = ({ onClose }) => {
    const {
        methods: { t }
    } = useI18n()
    const navigate = useNavigate()
    const { getToken } = useSignInRedirect()

    const {
        methods: { error }
    } = useShowMessage()
    const {
        methods: { handleError }
    } = useApiError('CartIndex', error)

    const {
        list,
        methods: { getList, deleteItem, reduceQuantity, updateQuantity }
    } = useCartRender()

    const {
        orderPrice,
        methods: { calculatePriceByCart }
    } = useCreateOrder()

    const { miniCartDrawerVisible } = useMiniCartDrawer()
    useEffect(() => {
        miniCartDrawerVisible && getList().catch(handleError)
    }, [miniCartDrawerVisible, getList, handleError])

    const onlyProductType = useCallback(
        (showError = true) => {
            if (list?.length) {
                let onlyProductList = true,
                    onlyRentProductList = true
                list?.forEach(({ items }) => {
                    items.forEach(({ productId, skuId, quantity, type }) => {
                        if (type !== 0 && onlyProductList) {
                            onlyProductList = false
                        }
                        if (type !== 1 && onlyRentProductList) {
                            onlyRentProductList = false
                        }
                    })
                })
                if (!onlyProductList && !onlyRentProductList) {
                    showError && error('Purchase and lease cannot be settled at the same time')
                } else {
                    return true
                }
            }
            return false
        },
        [list, error]
    )

    const handleReduceQuantity = useCallback(
        (code: string, quantity: number, skuId: string, timeZone: string, duration: string) => {
            if (quantity > 0) {
                reduceQuantity({ code, value: 1, quantity, skuId, timeZone, duration }).catch(handleError)
            } else {
                error(t('client.cart.fail.cannot_reduce_num'))
            }
        },
        [error, handleError, reduceQuantity, t]
    )

    const handleUpdateQuantity = useCallback(
        (code: string, quantity: number, skuId: string, timeZone: string, duration: string) => {
            updateQuantity({ code, value: 1, quantity, skuId, timeZone, duration }).catch(handleError)
        },
        [handleError, updateQuantity]
    )

    const [showScrollBar, setShowScrollBar] = useState<boolean>(false)

    const handleViewCart = useCallback(() => {
        logEvent(analytics, 'view_cart')
        onClose()
        navigate('/cart')
    }, [navigate, onClose])

    useEffect(() => {
        // if (onlyProductType(false)) {
        const goodsItems: CheckoutRequestItem[] = []
        list?.forEach(({ items }) => {
            items.forEach(({ productId, skuId, quantity, timeZone, duration }) => {
                goodsItems.push({ productId, skuId, quantity, timeZone, duration })
            })
        })
        goodsItems.length > 0 && calculatePriceByCart(goodsItems).catch(handleError)
        // }
    }, [calculatePriceByCart, list, handleError, onlyProductType])

    return (
        <Wrapper>
            <div className="header">
                <div className="close" onClick={() => onClose && onClose()}>
                    <img src={rightIcon} alt="" />
                    Cart
                </div>
            </div>
            <div
                className={`content ${showScrollBar ? 'scrollBar' : ''}`}
                onMouseEnter={() => {
                    setShowScrollBar(true)
                }}
                onMouseLeave={() => {
                    setShowScrollBar(false)
                }}
            >
                {list.map(({ items }, _index) => (
                    <Fragment key={_index}>
                        {items.map(
                            (
                                {
                                    code,
                                    productId,
                                    image,
                                    name,
                                    quantity,
                                    skuId,
                                    price,
                                    stock,
                                    type,
                                    preOrder,
                                    onceLimit,
                                    timeZone,
                                    duration,
                                    durations
                                },
                                itemsIndex
                            ) => (
                                <div className="item" key={itemsIndex + _index}>
                                    <div className="info">
                                        <img src={image} alt="" />
                                        <div className="describe">
                                            <div className="title">{name}</div>
                                            {type === 1 ? (
                                                <div className="info-items">
                                                    <div className="info-item">
                                                        <label>Price: </label>
                                                        <span>$ {price}</span>
                                                    </div>
                                                    <div className="info-item">
                                                        <Durations data={durations} />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="price">
                                                    <span>$</span>
                                                    {convertToDollar(price)}
                                                </div>
                                            )}
                                            <div className="operation">
                                                <QuantityBtn
                                                    quantity={quantity}
                                                    stock={stock}
                                                    preOrder={preOrder}
                                                    onceLimit={onceLimit}
                                                    onReduceQuantity={() =>
                                                        handleReduceQuantity(
                                                            getToken() ? code : productId,
                                                            quantity - 1,
                                                            skuId,
                                                            timeZone || '',
                                                            duration || ''
                                                        )
                                                    }
                                                    onQuantity={() =>
                                                        handleUpdateQuantity(
                                                            getToken() ? code : productId,
                                                            quantity + 1,
                                                            skuId,
                                                            timeZone || '',
                                                            duration || ''
                                                        )
                                                    }
                                                />
                                                <DeleteIcon
                                                    onClick={() => deleteItem(getToken() ? code : productId)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        )}
                    </Fragment>
                ))}
            </div>
            <div className="footer">
                <div className="subtotal">
                    <div className="name">Subtotal</div>
                    <div className="value">${list.length > 0 ? orderPrice?.subTotal : '0.00'}</div>
                </div>
                <div className="btn" onClick={() => handleViewCart()}>
                    View Cart
                </div>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 394px;
    background-color: #fff;
    .header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 68px;
        background-color: #2f2e2e;
        padding-left: 30px;
        .close {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-family: Roboto-Regular;
            font-size: 24px;
            color: #ffffff;
            cursor: pointer;
            img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
        }
    }
    .content {
        height: calc(100vh - 68px - 198px);
        overflow: auto;
        padding: 0 30px;
        .item {
            padding-top: 30px;
            border-bottom: 1px solid #2f2e2e;
            .info {
                position: relative;
                display: flex;
                word-break: break-word;
                > img {
                    width: 100px;
                    height: 100px;
                    margin-right: 15px;
                    background: rgba(60, 60, 60, 0.1);
                    cursor: pointer;
                }
                .describe {
                    flex: 1;
                }
                .title {
                    margin-bottom: 10px;
                    font-family: Roboto-Medium;
                    font-size: 16px;
                    color: #303030;
                    letter-spacing: 0;
                    line-height: 26px;
                }
                .price {
                    margin: 26px 0 14px;
                    font-family: Roboto-Medium;
                    font-size: 18px;
                    color: #000000;
                    letter-spacing: 0;
                    line-height: 18px;
                    span {
                        font-size: 14px;
                        letter-spacing: 0;
                    }
                }
                .info-items {
                    margin-bottom: 14px;
                    .info-item {
                        line-height: 20px;
                        margin-bottom: 8px;
                        label {
                            text-transform: capitalize;
                        }
                    }
                }
            }
            .operation {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 25.5px;
            }
        }
    }
    .content::-webkit-scrollbar {
        width: 8px;
        height: 10px;
        /**/
    }
    .content::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 2px;
    }
    .content::-webkit-scrollbar-thumb {
        background: #fff;
        border-radius: 10px;
    }

    .content.scrollBar::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 8px;
    }
    .footer {
        width: 100%;
        height: 198px;
        padding: 30px;

        font-family: Roboto-Medium;
        font-size: 24px;
        color: #000000;
        line-height: 28px;
        .subtotal {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;
        }
        .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 50px;
            border-radius: 30px;
            background-color: #000000;

            font-size: 25.33px;
            color: #ffffff;
            cursor: pointer;
        }
    }

    @media (max-width: 768px) {
        width: 100%;
        .header {
            width: 80vw;
            height: 56px;
        }
        .content {
            height: calc(100vh - 56px - 248px);
        }
        .footer {
            height: 248px;
        }
    }
`
