import React, { ReactNode } from 'react'

import { useMemoizedFn, useResponsive } from 'shared/hooks'
import { NOTIFICATION_DURATION } from 'shared/config'

import { Toast } from 'antd-mobile'
import { customModal, errorModal, FailIcon, SuccessIcon, successModal, SuccessModalConfig } from 'components'

export interface SingleButtonModalProps {
    message: string | ReactNode
    type: 'success' | 'fail'
    buttonText: string
    onButtonClick: () => void
}

interface SingleModalProps {
    message: string | ReactNode
    buttonText?: string
}

export const useShowMessage = () => {
    const { md } = useResponsive()

    const error = useMemoizedFn((message?: string) => {
        const msg = message || 'error'
        if (md) {
            errorModal(msg)
            return
        }
        Toast.show({
            icon: 'fail',
            content: msg,
            duration: NOTIFICATION_DURATION
        })
    })

    const success = useMemoizedFn((message?: string, config?: SuccessModalConfig) => {
        const msg = message || 'success'
        if (md || config?.title) {
            successModal(msg, config)
            return
        }
        Toast.show({
            icon: 'success',
            content: msg,
            duration: NOTIFICATION_DURATION
        })
    })

    const showSingleButtonModal = useMemoizedFn(
        ({ message, type, buttonText, onButtonClick }: SingleButtonModalProps) =>
            customModal(
                {
                    message,
                    icon: type === 'success' ? <SuccessIcon /> : <FailIcon />,
                    footerButtonProps: [{ text: buttonText, onClick: onButtonClick }]
                },
                {
                    onCancel: onButtonClick
                }
            )
    )

    const singleModal = useMemoizedFn(({ message, buttonText }: SingleModalProps) =>
        customModal({
            message
        })
    )

    /**
     * show the error message based on the Antd Web UI
     */
    const showErrorModal = useMemoizedFn((message?: string) => errorModal(message || 'error'))

    return { methods: { error, showErrorModal, success, showSingleButtonModal, singleModal } }
}
