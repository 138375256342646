import React, { FC } from 'react'
import styled from '@emotion/styled'

import { useResponsive } from 'shared/hooks'

import { SectionTitle, Video } from 'components'
import videoGallery from '../assets/gallery.mp4'

export const VideoBlock: FC = () => {
    const { md } = useResponsive()

    return (
        <Wrapper>
            <div className="px-12 md:px-0">
                <SectionTitle className="mb-20" title="the Crypt Gallery" />
            </div>
            <VideoWrapper className="overflow-hidden" md={md}>
                <Video classNames="mx-auto my-0" src={videoGallery} style={{ maxHeight: 'inherit' }} />
            </VideoWrapper>
        </Wrapper>
    )
}

const VideoWrapper = styled.div<{ md: boolean }>`
    max-height: 67.2rem;
    background-color: ${({ md }) => (md ? '#000' : '#fff')};
`

const Wrapper = styled.div`
    margin-top: 160px;
    @media (max-width: 1024px) {
        margin-top: 50px;
    }
`
