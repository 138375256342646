import { PROJECT_PREFIX } from './eshop-shared-config'

export * from './eshop-shared-config'
export * from './language'
export const PLATFORM_URI_PREFIX = `/${PROJECT_PREFIX}/platform/v2`
export const ADMIN_URI_PREFIX = `/${PROJECT_PREFIX}/admin/v2`
export const API_URI_PREFIX = `/${PROJECT_PREFIX}/api/v2`
export const ADDRESS_URI_PREFIX = `/${PROJECT_PREFIX}/api/v3`
export const SELLER_URI_PREFIX = `/${PROJECT_PREFIX}/seller/v2`
export const CLIENT_URI_PREFIX = `/${PROJECT_PREFIX}/buyer/v2`
export const COMMON_URI_PREFIX = `/${PROJECT_PREFIX}/common/v2`
export const ADMIN_IMAGE_UPLOAD_URL = `${SELLER_URI_PREFIX}/file/uploadPic`
export const CLIENT_IMAGE_UPLOAD_URL = `/${PROJECT_PREFIX}/api/v4/nft/upload/file`
export const CLIENT_ZIP_UPLOAD_URL = `/${PROJECT_PREFIX}/api/v3/nft/upload/zip`
export const SELLER_IMAGE_UPLOAD_URL = `${SELLER_URI_PREFIX}/file/upload/image`
export const DOWNLOAD_URL_IOS = 'https://apps.apple.com/us/app/metasill/id1612433405'
export const DOWNLOAD_URL_ANDROID = 'https://play.google.com/store/apps/details?id=com.metasill.metasill'

export const TIMEZONES = [
    { label: 'America/New_York', value: 'Eastern Time' },
    { label: 'America/Denver', value: 'Mountain Time' },
    { label: 'America/Chicago', value: 'Central Time' },
    { label: 'America/Los_Angeles', value: 'Pacific Time' }
]

export * from './eshop-shared-config'
export * from './language'

export const PAGE_SIZE_WEB = 60
export const PAGE_SIZE_MOBILE = 30

const URL_TEST = 'https://ptest.metasill.io/web_apps/metasill_management/#'
const URL_PRODUCTION = 'https://metasill.io/web_apps/mall_seller_desktop/#'

const URL_TEST_USER = 'https://ptest.metasill.io/web_apps/metasill_website/#'
const URL_PRODUCTION_USER = 'https://metasill.io/#'

export const USER_METASILL_URL = `${
    process.env.NODE_ENV === 'production' ? URL_PRODUCTION_USER : URL_TEST_USER
}/support/returns`

export const ADMIN_LINK_STORE = `${
    process.env.NODE_ENV === 'production' ? URL_PRODUCTION : URL_TEST
}/admin/product`

export const ADMIN_LINK_DEVICE = `${
    process.env.NODE_ENV === 'production' ? URL_PRODUCTION : URL_TEST
}/admin/frame`

export const ADMIN_LINK_NFT = `${
    process.env.NODE_ENV === 'production' ? URL_PRODUCTION : URL_TEST
}/admin/upload`

export const ADMIN_LINK_AD = `${
    process.env.NODE_ENV === 'production' ? URL_PRODUCTION : URL_TEST
}/admin/new_ad_statistics`

export const ADMIN_LINK_SPONSOR = `${
    process.env.NODE_ENV === 'production' ? URL_PRODUCTION : URL_TEST
}/admin/sponsor/my_device`
