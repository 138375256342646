import { useCallback } from 'react'

import { useListState, useMountedPromiseFn } from 'shared/hooks'
import { useI18n } from 'core/language/api'
import { services } from 'core/product/data-access'

import type { ProductInList } from 'core/product/models'

export const useEventProducts = () => {
    const { lang } = useI18n()

    const [state, setState] = useListState<ProductInList>()

    const getListApi = useMountedPromiseFn(services.getEventList)
    const getList = useCallback(() => {
        setState({ loading: true })
        return getListApi(lang)
            .then(list => {
                setState({ list, loading: false })
                return list
            })
            .catch(err => {
                setState({ loading: false })
                return Promise.reject(err)
            })
    }, [getListApi, lang, setState])

    return {
        ...state,
        methods: {
            getList
        }
    }
}
