import React, { useCallback } from 'react'
import ReaptchaEnterprise from '@panalbish/reaptcha-enterprise'
import styled from '@emotion/styled'

import { useApiError, useShowMessage } from 'shared/hooks'
import { useI18n } from 'core/language/api'

const ReaptchaComponent: any = ReaptchaEnterprise

export const Reaptcha: React.FC<{ onVerify: (token: string) => void }> = ({ onVerify }) => {
    const { lang } = useI18n()
    const {
        methods: { error }
    } = useShowMessage()
    const {
        methods: { handleError }
    } = useApiError('Auth', error as any, 'auth')

    const handleRecaptchaError = useCallback(() => {
        handleError('Network Error')
    }, [handleError])
    const onVerifyHandle = useCallback(
        (recaptchaToken: string) => {
            onVerify(recaptchaToken)
        },
        [onVerify]
    )

    return (
        <Wrapper>
            <ReaptchaComponent
                sitekey={(window as any).recaptchaSiteKey}
                onVerify={onVerifyHandle}
                onError={handleRecaptchaError}
                enterprise={true}
                hl={lang}
            />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    box-sizing: border-box;
    .g-recaptcha {
        display: flex;
        justify-content: center;
    }
`
