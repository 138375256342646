import React, { FC, useMemo } from 'react'
import styled from '@emotion/styled'

import { useI18n } from 'core/language/api'

import { Popover } from 'antd'

import { NftStatusEnum } from 'core/management/nft/models'

import reviewing from 'assets/reviewing.png'
import approved from 'assets/approved.png'
import rejected from 'assets/rejected.png'

import { TooltipPlacement } from 'antd/es/tooltip'

const statusImgs = [reviewing, approved, rejected]

interface Props {
    status: NftStatusEnum
    note: string
    notePlacement?: TooltipPlacement
    noteTrigger?: string
    isDirection?: string
}

export const StatusInfo: FC<Props> = ({ status, note, notePlacement, noteTrigger, isDirection }) => {
    const {
        methods: { t }
    } = useI18n()

    const inner = useMemo(
        () => (
            <Wrapper isDirection={isDirection}>
                <Icon src={statusImgs[status]} />
                <Text>{t(`management.nft.status.${status}`)}</Text>
            </Wrapper>
        ),
        [isDirection, status, t]
    )

    return status === NftStatusEnum.Rejected ? (
        <Popover
            placement={notePlacement || 'rightTop'}
            content={status === NftStatusEnum.Rejected ? <Note>{note}</Note> : null}
            trigger={noteTrigger || 'hover'}
        >
            {inner}
        </Popover>
    ) : (
        inner
    )
}

const Note = styled.div`
    width: 140px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 22px;
    font-weight: 400;
`

const Icon = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 10px;
`

const Text = styled.div`
    font-family: Roboto-Regular;
    font-size: 20px;
    color: #000000;
    line-height: 16px;
    font-weight: 400;
`

const Wrapper = styled.div<{ isDirection?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff6eb;
    width: 170px;
    height: 48px;
    border-radius: 24px;
    @media (max-width: 768px) {
        border-radius: ${({ isDirection }) =>
            isDirection === 'left'
                ? '0px 24px 24px 0px'
                : isDirection === 'right'
                ? '24px 0px 0px 24px'
                : '24px'};
    }
`
