import { on, reducer as createReducer } from 'ts-action'
import { setDetail, clearDetail } from './actions'
import { FEATURE_NAME } from './constants'
import { CartItemGroupByStore } from 'core/cart/models'

export interface State {
    detail?: CartItemGroupByStore[] | null
}

export interface GlobalState {
    [FEATURE_NAME]: State
}

export const initialState: State = {}

export const reducer = createReducer(
    initialState,
    on(setDetail, (state, { payload: { detail } }) => {
        return {
            ...state,
            detail
        }
    }),
    on(clearDetail, () => initialState)
)
