import { on, reducer as createReducer } from 'ts-action'
import { setOwnedList, setAuthorizedList } from './actions'

import { FEATURE_NAME } from './constants'
import { MyCollectionList } from 'core/management/nft/models'

export interface State {
    ownedList: MyCollectionList[]
    authorizedList: MyCollectionList[]
}
export interface GlobalState {
    [FEATURE_NAME]: State
}
export const initialState: State = {
    ownedList: [],
    authorizedList: []
}
export const reducer = createReducer(
    initialState,
    on(setOwnedList, (state, { payload: { ownedList } }) => {
        return {
            ...state,
            ownedList
        }
    }),
    on(setAuthorizedList, (state, { payload: { authorizedList } }) => {
        return {
            ...state,
            authorizedList
        }
    })
)
