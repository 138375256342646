import React, { FC, useState, useEffect, useMemo } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { equals } from 'ramda'
import { logEvent } from 'firebase/analytics'
import styled from '@emotion/styled'

import { useProductDetail } from 'core/product/api'
import { useApiError, useResponsive } from 'shared/hooks'
import { analytics } from 'shared/utils'
import { getDurationsObj } from 'shared/config/eshop-shared-config'

import { Swiper } from 'antd-mobile'
import { PageContainer, Container2Col, Paragraph, Spin } from 'components'
import { ProductInfo } from './components/ProductInfo'
import { ProductInfoRent } from './components/ProductInfoRent'

import imgOurApp from './assets/our-app.jpg'
import imgPrivate from './assets/private.jpg'
import imgEvent from './assets/event.jpg'
import imgSpots from './assets/spots.jpg'
import imgInfo from './assets/info.jpg'

export const ProductDetails: FC = () => {
    const {
        methods: { handleError }
    } = useApiError('FeatureProductDetail')
    const { md } = useResponsive()

    const { id, eventId } = useParams<{ id: string; eventId?: string }>()

    const {
        detail,
        loading,
        methods: { getDetail }
    } = useProductDetail(id!, eventId)

    useEffect(() => {
        id && getDetail().catch(handleError)
    }, [getDetail, handleError, id])

    const { state } = useLocation()
    const { pathname } = useLocation()

    useMemo(() => {
        if (!pathname.includes('event') && detail && getDurationsObj()) {
            const { timeZone, durations, duration } = JSON.parse(getDurationsObj() || '')
            detail.timeZone = timeZone
            detail.durations = durations.map((item: string, index: number) => {
                return {
                    label: duration.split(',')[index] + '',
                    value: item
                }
            })
        }
    }, [detail, pathname])

    useEffect(() => {
        if (state && (state as any).hot && detail) {
            logEvent(analytics, 'view_item', {
                items: [{ id: detail.id, name: detail.name, price: detail.showPrice }]
            })
        }
    }, [state, detail])

    const [selectedAttrs, setSelectedAttrs] = useState<Record<string, string>>({})
    useEffect(() => {
        detail?.isMultiSku &&
            setSelectedAttrs(() => {
                const attrs: Record<string, string> = {}
                detail?.attrList?.forEach(({ id, values }) => {
                    attrs[id] = values[0].id
                })
                return attrs
            })
    }, [detail])
    const selectedSku = useMemo(() => {
        if (!detail || !selectedAttrs) return null
        return detail?.skuList?.find(({ specObj }) => equals(specObj, selectedAttrs))
    }, [detail, selectedAttrs])

    const [coverImg, setCoverImg] = useState<string>('')

    const images: string[] = useMemo(() => {
        let images = [] as string[]
        selectedSku?.images.forEach(item => images.push(item))
        detail?.images.forEach(item => images.push(item))
        setCoverImg(images[0])
        return images
    }, [detail, selectedSku])

    return (
        <Spin spinning={loading}>
            <Container>
                <Wrapper>
                    <Cover>
                        {md && (
                            <>
                                <CoverList>
                                    {images?.map((item, index) => (
                                        <img
                                            onClick={() => setCoverImg(item)}
                                            key={index}
                                            src={item}
                                            alt=""
                                        />
                                    ))}
                                </CoverList>
                                <CoverImg>{coverImg && <img src={coverImg} alt="" />}</CoverImg>
                            </>
                        )}
                        {!md && (
                            <Swiper
                                indicatorProps={{
                                    color: 'primary'
                                }}
                            >
                                {images?.map((item, index) => (
                                    <Swiper.Item key={index}>
                                        <img onClick={() => setCoverImg(item)} src={item} alt="" />
                                    </Swiper.Item>
                                ))}
                            </Swiper>
                        )}
                    </Cover>
                    {id &&
                        detail &&
                        (detail?.type === 1 ? (
                            <ProductInfoRent
                                id={id}
                                detail={detail}
                                selectedAttrs={selectedAttrs}
                                setSelectedAttrs={setSelectedAttrs}
                            />
                        ) : (
                            <ProductInfo
                                id={id}
                                detail={detail}
                                selectedAttrs={selectedAttrs}
                                setSelectedAttrs={setSelectedAttrs}
                            />
                        ))}
                </Wrapper>
            </Container>
            {id &&
                detail &&
                (detail?.descriptionType === 0 ? (
                    <div>
                        <Container2Col
                            reverse
                            backgroundColor="rgb(224, 224, 224)"
                            imgSrc={imgOurApp}
                            title="Our MetaSill App"
                            textNodes={[
                                <Paragraph
                                    style={{
                                        fontSize: '18px',
                                        lineHeight: '1.6',
                                        fontFamily: 'PingFangSC-Regular'
                                    }}
                                >
                                    Remotely control your display using our MetaSill app to curate and manage
                                    NFT artwork across all of your frames. Keep your art and environment new
                                    and fresh.
                                </Paragraph>,
                                <Paragraph
                                    style={{
                                        fontSize: '18px',
                                        lineHeight: '1.6',
                                        fontFamily: 'PingFangSC-Regular'
                                    }}
                                >
                                    Checkout this{' '}
                                    <VideoLink
                                        href="https://youtu.be/PJKlN0xlRk8"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        video
                                    </VideoLink>{' '}
                                    for more details.
                                </Paragraph>
                            ]}
                        />
                        <Container2Col
                            backgroundColor="rgb(143, 138, 127)"
                            imgSrc={imgPrivate}
                            title={<div className="text-white">Private Collectors</div>}
                            textNodes={[
                                <Paragraph style={{ color: '#fff' }}>
                                    NFT Art For Your Wall - The MetaSill NFT Frame allows you to display your
                                    NFT artwork in exquisite, painting-like detail. Easily add your NFTs to
                                    create a personalized slideshow for your artwork on our App.
                                </Paragraph>,
                                <Paragraph style={{ color: '#fff' }}>
                                    Crystal Clear Advanced Square Screen - Our advanced, 2K square frame with
                                    an anti-glare matte finish can display your vivid NFT's. The color-dynamic
                                    HD screen produces sharp images with vibrant colors that maximize the
                                    beauty of your NFTs.
                                </Paragraph>
                            ]}
                        />
                        <Container2Col
                            reverse
                            imgSrc={imgEvent}
                            title={`Galleries & Events`}
                            textNodes={[
                                <Paragraph>
                                    Curate Your NFT Collection - Curate and control your choice of the best
                                    NFT artworks with our professional NFT Frames. The MetaSill Square Frame
                                    is the only NFT frame that fits the 1:1 aspect ratio most NFTs use. The
                                    MetaSill NFT Frame can display most NFT formats: images, gifs, videos and
                                    interactives.
                                </Paragraph>
                            ]}
                        />
                        <Container2Col
                            backgroundColor="rgb(218, 216, 201)"
                            imgSrc={imgSpots}
                            title="Public Spots"
                            textNodes={[
                                <Paragraph>
                                    Attract NFT fanatics - Decorate your café, store, restaurant & other
                                    public spaces with innovative NFT art. Our minimalist, modern design
                                    blends with any decor, displaying fun NFTs with vibrant color. Curate the
                                    most popular NFTs and turn your place into an internet-famous spot.
                                </Paragraph>
                            ]}
                        />
                    </div>
                ) : (
                    <div />
                ))}
            {id &&
                detail &&
                (detail?.descriptionType === 0 ? (
                    <Container2Col
                        reverse
                        imgSrc={imgInfo}
                        title="Product Information"
                        textNodes={[
                            <div
                                style={{ fontFamily: 'Roboto-Regular' }}
                                dangerouslySetInnerHTML={{ __html: detail?.mobileDescription || '' }}
                            />
                        ]}
                    />
                ) : (
                    <Container2Col
                        reverse
                        title="Product Information"
                        textNodes={[
                            <div
                                style={{ fontFamily: 'Roboto-Regular' }}
                                dangerouslySetInnerHTML={{ __html: detail?.mobileDescription || '' }}
                            />
                        ]}
                    />
                ))}
        </Spin>
    )
}

const Container = styled(PageContainer)`
    padding-top: 10rem;
    @media (max-width: 576px) {
        padding: 0;
        width: 100%;
    }
`

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 9.1rem;
    img {
        width: 100%;
    }
    @media (max-width: 576px) {
        flex-direction: column;
    }
`

const VideoLink = styled.a`
    text-decoration: underline;
`
const Cover = styled.div`
    display: flex;
    justify-content: space-between;
    width: 50%;
    @media (max-width: 576px) {
        width: 100%;
        flex-direction: column;
    }
`
const CoverList = styled.div`
    width: 8.4rem;
    img {
        width: 100%;
        margin-bottom: 6px;
        cursor: pointer;
    }
`

const CoverImg = styled.div`
    width: 52.4rem;
    box-sizing: border-box;
    img {
        width: 100%;
        height: auto;
    }
    @media (max-width: 576px) {
        width: 100%;
        padding-left: 0;
        margin-bottom: 10px;
    }
`
