import React, { Dispatch, FC, SetStateAction, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { useLocalStorage, useSetState } from '@mantine/hooks'

import { useOwnedNew } from 'core/management/nft/api/useOwnedNew'
import { AuthAddressItem, NftTypeEnum } from 'core/management/nft/models'

import { assocPath } from 'ramda'
import {
    useApiError,
    useLoading,
    useMemoizedFn,
    useRememberManagementSelected,
    useResponsive
} from 'shared/hooks'
import { SimpleGrid, Modal } from '@mantine/core'
import { Checkbox } from '@mantine/core'
import { Popup } from 'antd-mobile'

import { NftListHeader } from './NftSelectorHeader'
import { NftListItem, NftListItemEnum } from './NftSelectorItem'
import { PlaylistFormData } from 'core/management/frame/model'
import { useWalletAddress } from 'core/walletaddress/api'
import { useAuthorized } from 'core/management/nft/api/useAuthorized'

import { InfiniteScroll } from '../../nft/components/InfiniteScroll'
import { SelectAdressPopup } from './SelectAdressPopup'

import pullDown from 'assets/pull_down.png'
import { SelectAddressModal } from './SelectAddressModal'
import { ConnectWallet } from 'modules/management/nft/components/ConnectWallet'
import { CreateWalletModal } from 'modules/user/components/CreateWalletModal'
import { useAuthAddressList } from 'core/management/nft/api/useAddressList'
import { removeAddressToken } from 'shared/config'
import { useNftList } from 'core/management/nft/api/useNftList'
import { useAddressToken } from 'core/management/nft/api/useAddressToken'

const ownedPageKey = 'owned_wallet_address'
const uploadPageKey = 'upload_wallet_address'
const authorizedPageKey = 'authorized_wallet_address'

interface SelectPlaylistNftProps {
    visible: boolean
    setVisible: (visible: boolean) => void
    setPlaylistFormData: Dispatch<SetStateAction<PlaylistFormData | undefined>>
    playlistFormData: PlaylistFormData
}

interface DataType {
    nft_id: string
    preview_url: string
    order: number
}

export const SelectPlaylistNft: FC<SelectPlaylistNftProps> = ({
    visible,
    setVisible,
    setPlaylistFormData,
    playlistFormData
}) => {
    const {
        methods: { handleError }
    } = useApiError('Collections')

    const { md } = useResponsive()

    const [type, setType] = useState(NftTypeEnum.OWNED)
    const [selectNftList, setSelectNftList] = useState<DataType[]>([])
    const [value, setValue] = useState<string[]>([])

    const [visibleAdress, setVisibleAdress] = useState(false)
    const [walletAddress, setWalletAddress] = useState<AuthAddressItem>()
    const [walletAddressInput, setWalletAddressInput] = useState('')
    const [bindModalOpen, setBindModalOpen] = useState<boolean>(false)
    const [isClick, setIsClick] = useState(false)

    const [selectOwnedAddressId, setselectOwnedAddressId] = useLocalStorage({ key: ownedPageKey })
    const [, setselectUploadAddressId] = useLocalStorage({ key: uploadPageKey })
    const [, setselectAuthorizedAddressId] = useLocalStorage({ key: authorizedPageKey })

    const {
        methods: { bind }
    } = useWalletAddress()

    const {
        list: authAddressList,
        methods: { getAddressList }
    } = useAuthAddressList()

    useLayoutEffect(() => {
        getAddressList().catch(handleError)
    }, [getAddressList, handleError, bindModalOpen])

    const [rememberLastSelected, setRememberLastSelected] = useSetState<Record<NftTypeEnum, string>>({
        [NftTypeEnum.OWNED]: '',
        [NftTypeEnum.AUTHORIZED]: '',
        [NftTypeEnum.UPLOAD]: ''
    })

    const mapAuthAddressList = useMemo(() => {
        if (type === NftTypeEnum.OWNED) {
            const data = authAddressList.filter(item => item.addressType !== 0 && item.addressType !== 3)
            return data
        }
        return authAddressList
    }, [authAddressList, type])

    const selectedOwnedId = useRememberManagementSelected(
        ownedPageKey,
        walletAddress?.id,
        walletAddress?.addressType === 0 || walletAddress?.addressType === 3 ? false : isClick
    )
    const selectedUploadId = useRememberManagementSelected(uploadPageKey, walletAddress?.id, isClick)
    const selectedAuthorizedId = useRememberManagementSelected(authorizedPageKey, walletAddress?.id, isClick)

    useEffect(() => {
        if (type === NftTypeEnum.OWNED) {
            const newList = authAddressList.filter(({ addressType }) => addressType !== 0)
            const lastSelectOwned = newList.find(({ id }) => selectedOwnedId === id)

            !!authAddressList.length && setWalletAddress(lastSelectOwned || newList[0])
        } else if (type === NftTypeEnum.AUTHORIZED) {
            !!authAddressList.length &&
                setWalletAddress(() => {
                    const lastAddress = authAddressList.find(({ id }) => selectedAuthorizedId === `${id}`)
                    if (lastAddress && lastAddress.addressType !== 0) return lastAddress

                    return { ...authAddressList[0], name: 'My Account' }
                })
        } else if (type === NftTypeEnum.UPLOAD) {
            !!authAddressList.length &&
                setWalletAddress(() => {
                    const lastAddress = authAddressList.find(({ id }) => selectedUploadId === `${id}`)
                    if (lastAddress && lastAddress.addressType !== 0) return lastAddress

                    return { ...authAddressList[0], name: 'My Account' }
                })
        }
    }, [
        type,
        authAddressList,
        rememberLastSelected,
        mapAuthAddressList,
        selectOwnedAddressId,
        selectedOwnedId,
        selectedAuthorizedId,
        selectedUploadId
    ])

    const {
        loading: uploadLoading,
        list: uploadList,
        uploadHasMore,
        methods: { uploadFetch, uploadLoadMore }
    } = useNftList()

    const {
        loading: ownedLoading,
        hasMoreLoading: ownedHasMoreLoading,
        ownedList,
        total,
        hasMore,
        methods: { firstFetch, loadMore }
    } = useOwnedNew(walletAddress?.address!, type)

    const {
        methods: { getAddressToken }
    } = useAddressToken()

    useEffect(() => {
        if (!walletAddress?.id) return
        if (walletAddress?.addressType === 0 && type === NftTypeEnum.UPLOAD) {
            uploadFetch().catch(handleError)
        } else if (walletAddress?.addressType !== 0) {
            getAddressToken({
                id: `${walletAddress.id}`,
                addressType: `${walletAddress.addressType}`,
                ownerId: `${walletAddress.ownerId}`
            })
                .then(() => {
                    firstFetch()
                        .then(() => {
                            removeAddressToken()
                        })
                        .catch(handleError)
                })
                .catch(handleError)
        }
    }, [firstFetch, handleError, walletAddress, type, uploadFetch, getAddressToken])

    const {
        hasMore: authorizedHasMore,
        authorizedList,
        authorizedTotal,
        methods: { authorizedFetch, authorizedLoadMore }
    } = useAuthorized(type)

    const mapList = useMemo(() => {
        if (type === NftTypeEnum.UPLOAD && walletAddress?.name === 'My Account') {
            const data = uploadList.filter(({ status }) => status === 1)

            return data.map(item => ({ ...item, id: item.nftId }))
        }
        return ownedLoading || ownedHasMoreLoading || (type === NftTypeEnum.OWNED && !walletAddress?.name)
            ? []
            : (type === NftTypeEnum.AUTHORIZED || type === NftTypeEnum.UPLOAD) &&
              walletAddress?.name === 'My Account'
            ? authorizedList
            : ownedList
    }, [authorizedList, ownedHasMoreLoading, ownedList, ownedLoading, type, uploadList, walletAddress])

    useEffect(() => {
        if (!walletAddress?.id) return
        if (type === NftTypeEnum.AUTHORIZED && walletAddress?.name === 'My Account') {
            getAddressToken({
                id: `${walletAddress?.id}`,
                addressType: `${walletAddress?.addressType}`,
                ownerId: `${walletAddress?.ownerId}`
            })
                .then(() => {
                    authorizedFetch()
                        .then(() => {
                            removeAddressToken()
                        })
                        .catch(handleError)
                })
                .catch(handleError)
        }
    }, [authorizedFetch, getAddressList, getAddressToken, handleError, type, walletAddress])

    const onChange = useMemoizedFn((checkedValues: string[]) => {
        const data: DataType[] = []

        checkedValues.forEach((item, i) => {
            data.push({
                nft_id: JSON.parse(item).id,
                preview_url: JSON.parse(item).imgUrl ? JSON.parse(item).imgUrl : JSON.parse(item).thumbUrl,
                order:
                    i +
                    1 +
                    (playlistFormData.nfts.length > 0
                        ? playlistFormData.nfts[playlistFormData.nfts.length - 1].order
                        : 0)
            })
        })

        setSelectNftList([...playlistFormData.nfts, ...data])
        setValue(checkedValues)
    })

    const goBackSaveNfts = useMemoizedFn(() => {
        setSelectNftList([])
        setVisible(false)
        setValue([])
    })

    const onDone = useMemoizedFn(() => {
        if (selectNftList.length) {
            setPlaylistFormData(prev => assocPath(['nfts'], [...selectNftList], prev))
        }
        setVisible(false)
        setSelectNftList([])
        setValue([])
        setType(NftTypeEnum.OWNED)
    })

    useLoading(ownedLoading || ownedHasMoreLoading || uploadLoading)

    const listRenderer = useMemo(() => {
        return (
            <Container>
                <NftListHeader
                    goBack={goBackSaveNfts}
                    type={type}
                    setType={setType}
                    title="Add NFT"
                    onDone={onDone}
                    setLastSelected={() => {
                        setRememberLastSelected({
                            [type]: walletAddress?.id
                        })
                    }}
                />
                {!!authAddressList.filter(({ addressType }) => addressType !== 0).length ||
                type !== NftTypeEnum.OWNED ? (
                    <>
                        {md && (
                            <WebSelectAddress>
                                <SelectAdress>
                                    <span className="box" onClick={() => setVisibleAdress(true)}>
                                        <span className="name">{walletAddress?.name}</span>
                                        <img width={16} height={16} src={pullDown} alt="NFT" />
                                    </span>
                                </SelectAdress>
                            </WebSelectAddress>
                        )}
                        {!md && (
                            <SelectAdress>
                                <span className="box" onClick={() => setVisibleAdress(true)}>
                                    <span className="name">{walletAddress?.name}</span>
                                    <img width={16} height={16} src={pullDown} alt="NFT" />
                                </span>
                            </SelectAdress>
                        )}

                        <TotalLength>
                            <div className="name">Total</div>
                            <span>
                                {walletAddress?.name === 'My Account'
                                    ? type === NftTypeEnum.UPLOAD
                                        ? mapList.length
                                        : authorizedTotal
                                    : total}
                            </span>
                        </TotalLength>
                        <div className={md ? 'addNftlist' : 'list'}>
                            <Checkbox.Group
                                value={value}
                                style={md ? { maxWidth: 1296 } : { width: '100%' }}
                                onChange={onChange}
                            >
                                <SimpleGrid cols={md ? 5 : 2}>
                                    {mapList?.map(item => (
                                        <NftListItem
                                            key={item.id}
                                            type={NftListItemEnum.PLAYLIST}
                                            item={item}
                                            pageType={type}
                                        />
                                    ))}
                                </SimpleGrid>
                                <InfiniteScroll
                                    loadMore={
                                        walletAddress?.name !== 'My Account'
                                            ? type === NftTypeEnum.UPLOAD
                                                ? uploadLoadMore
                                                : loadMore
                                            : authorizedLoadMore
                                    }
                                    hasMore={
                                        walletAddress?.name !== 'My Account'
                                            ? type === NftTypeEnum.UPLOAD
                                                ? uploadHasMore
                                                : hasMore
                                            : authorizedHasMore
                                    }
                                />
                            </Checkbox.Group>
                        </div>
                    </>
                ) : (
                    <div className="flex justify-center  mt-12">
                        <CreateWalletContent>
                            <ConnectWallet
                                onConnectSuccess={(value: string) => {
                                    setWalletAddressInput(value)
                                    setBindModalOpen(true)
                                }}
                            />
                        </CreateWalletContent>
                        <CreateWalletModal
                            walletAddress={walletAddressInput}
                            open={bindModalOpen}
                            setOpen={setBindModalOpen}
                            onBind={bind}
                        />
                    </div>
                )}
            </Container>
        )
    }, [
        authAddressList,
        authorizedHasMore,
        authorizedLoadMore,
        authorizedTotal,
        bind,
        bindModalOpen,
        goBackSaveNfts,
        hasMore,
        loadMore,
        mapList,
        md,
        onChange,
        onDone,
        setRememberLastSelected,
        total,
        type,
        uploadHasMore,
        uploadLoadMore,
        value,
        walletAddress,
        walletAddressInput
    ])

    return (
        <>
            {md ? (
                <Modal
                    zIndex={1200}
                    opened={visible}
                    onClose={() => setVisible(false)}
                    overlayColor="rgba(0,0,0,0)"
                    styles={{
                        header: { display: 'none' },
                        modal: {
                            width: 1296,
                            height: '100%',
                            borderRadius: '20px',
                            top: '0px',
                            overflow: 'auto'
                        },
                        inner: { padding: '0', top: '60px' }
                    }}
                >
                    {listRenderer}
                </Modal>
            ) : (
                <Popup visible={visible} bodyStyle={{ height: '92%', overflow: 'auto' }} mask={false}>
                    {listRenderer}
                </Popup>
            )}
            {walletAddress && md && (
                <SelectAddressModal
                    setWalletAddress={val => {
                        setWalletAddress(val)
                        if (val.addressType !== 0 && val.addressType !== 3) {
                            setselectOwnedAddressId(`${val?.id}`)
                        }
                        setselectUploadAddressId(`${val?.id}`)
                        setselectAuthorizedAddressId(`${val?.id}`)
                    }}
                    walletAddress={walletAddress}
                    list={mapAuthAddressList}
                    type={type}
                    visible={visibleAdress}
                    setVisible={setVisibleAdress}
                    setIsClick={setIsClick}
                />
            )}
            {walletAddress && !md && (
                <SelectAdressPopup
                    setWalletAddress={val => {
                        setWalletAddress(val)
                        if (val.addressType !== 0 && val.addressType !== 3) {
                            setselectOwnedAddressId(`${val?.id}`)
                        }
                        setselectUploadAddressId(`${val?.id}`)
                        setselectAuthorizedAddressId(`${val?.id}`)
                    }}
                    walletAddress={walletAddress}
                    list={mapAuthAddressList}
                    type={type}
                    visible={visibleAdress}
                    setVisible={setVisibleAdress}
                    setIsClick={setIsClick}
                />
            )}
        </>
    )
}

const CreateWalletContent = styled.div`
    padding-top: 10px;
    width: 362px;
    @media (max-width: 768px) {
        width: 325px;
        padding-top: 20px;
    }
`

const WebSelectAddress = styled.div`
    position: absolute;
    right: 72px;
    top: 111px;
`

const Container = styled.div`
    padding: 0 72px;
    position: relative;
    .addNftlist {
        display: flex;
        justify-content: center;
    }
    @media (max-width: 768px) {
        padding: 0 20px;
    }
`

const SelectAdress = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    /* margin-top: 24px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(151, 151, 151, 1); */
    .box {
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f9f9f9;
        border: 1px solid rgba(211, 211, 211, 1);
        border-radius: 8px;
        padding: 6px 12px;
        @media (max-width: 768px) {
            height: 36px;
        }
    }

    .name {
        font-family: OpenSans-Bold;
        font-size: 18px;
        color: #000000;
        font-weight: 700;
        margin-right: 6px;
    }
    @media (max-width: 768px) {
        margin-top: 24px;
    }
`

const TotalLength = styled.div`
    display: flex;
    font-family: OpenSans-Regular;
    font-size: 16px;
    align-items: center;
    color: #acacac;
    margin-right: 6px;
    margin-top: 30px;
    .name {
        font-family: OpenSans-Bold;
        font-size: 18px;
        color: #000000;
        font-weight: 700;
        margin-right: 10px;
    }
    @media (max-width: 768px) {
        margin-top: 24px;
    }
`
