import { action, payload } from 'ts-action'
import { getActionType } from '../constants'
import { STEPS } from 'core/order/models'

export const setSameShipping = action(getActionType('Set SameShipping'), payload<{ sameShipping: boolean }>())

export const setStep = action(getActionType('Set Step'), payload<{ step: STEPS }>())

export const setHistoryStep = action(getActionType('Set HistoryStep'), payload<{ historyStep: STEPS }>())

export const setIsSelfPickUp = action(getActionType('Set IsSelfPickUp'), payload<{ isSelfPickUp: boolean }>())

export const clearCreateOrderInfo = action(getActionType('Clear Create Order Info'))
