import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logEvent } from 'firebase/analytics'

import { services } from 'core/coupon/data-access'
import { actions, selectors } from 'core/coupon/state'
import { useMountedPromiseFn } from 'shared/hooks'
import { analytics } from 'shared/utils'

export const useCoupon = () => {
    const dispatch = useDispatch()

    const [loading, setLoading] = useState<boolean>(false)

    const code = useSelector(selectors.getCoupon)

    const verificationApi = useMountedPromiseFn(services.verification)
    const verification = useCallback(
        (code: string, idStr: string[]) => {
            setLoading(true)
            return verificationApi(code, idStr)
                .then(res => {
                    logEvent(analytics, 'select_promotion')
                    dispatch(actions.setCoupon({ code }))
                    setLoading(false)
                    return res
                })
                .catch(err => {
                    dispatch(actions.setCoupon({ code: '' }))
                    setLoading(false)
                    return Promise.reject(err)
                })
        },
        [dispatch, verificationApi]
    )
    const clearCoupon = () => {
        dispatch(actions.clearCoupon())
    }

    return {
        loading,
        code,
        methods: {
            verification,
            clearCoupon
        }
    }
}
