import { useRef, useState } from 'react'

import { useMemoizedFn, useMountedPromiseFn } from 'shared/hooks'
import { services } from 'core/management/nft/data-access'

import type { MyCollectionList, NftParams } from '../models'
import { usePageSize } from './usePageSize'

// const initParmas = {
//     chain: '1',
//     page_size: 60
// }

export const useOwnedNew = (walletAddress: string, type: 'uploaded' | 'owned' | 'authorized') => {
    const pageSize = usePageSize()

    const [loading, setLoading] = useState(false)
    const [hasMoreLoading, setHasMoreLoading] = useState(false)
    const [ownedList, setOwnedList] = useState<MyCollectionList[]>([])
    const [total, setTotal] = useState(0)
    const nextToken = useRef('')
    const [hasMore, setHasMore] = useState(false)

    const firstFetch = useMemoizedFn(() => {
        const params = { page_size: pageSize, type, chain: '' }
        if (type === 'owned') params.chain = '1'
        return getOwned(params).then(res => {
            setOwnedList(res.nft_list)
            nextToken.current = res.cursor_next || ''
            setHasMore(!!res.cursor_next)
            setTotal(res.total_nft)
        })
    })

    const loadMore = useMemoizedFn(async () => {
        if (!walletAddress) return
        const params = { page_size: 60, type, offset: nextToken.current, chain: '' }
        if (type === 'owned') params.chain = '1'
        const res = await getOwned(params)
        setOwnedList(prev => [...prev, ...res.nft_list])
        nextToken.current = res.cursor_next || ''
        setHasMore(!!res.cursor_next)
    })

    const getOwnedApi = useMountedPromiseFn(services.ownedNew)
    const getOwned = useMemoizedFn((params: NftParams) => {
        if (params.offset) {
            setHasMoreLoading(true)
        } else {
            setLoading(true)
        }
        return getOwnedApi(params, walletAddress)
            .then(res => {
                setHasMoreLoading(false)
                setLoading(false)
                return res
            })
            .catch(err => {
                setLoading(false)
                setHasMoreLoading(false)
                return Promise.reject(err)
            })
    })

    return {
        loading,
        total,
        ownedList,
        hasMore,
        hasMoreLoading,
        methods: {
            firstFetch,
            loadMore
        }
    }
}
