import { useMountedPromiseFn, useListState } from 'shared/hooks'
import { services } from 'core/management/frame/data-access'

import { useCallback } from 'react'
import { AuthorizedDeletes, FramesAuthDeleteList } from '../model'

export const useFrameAuth = () => {
    const [state, setState] = useListState<FramesAuthDeleteList>()

    const getFrameDeleteListApi = useMountedPromiseFn(services.getFrameDeleteList)
    const getFrameDeleteList = useCallback(() => {
        setState({ loading: true })
        const type = '3'
        return getFrameDeleteListApi(type)
            .then(list => {
                setState({ loading: false, list })
                return list
            })
            .catch(err => {
                setState({ loading: false })
                return Promise.reject(err)
            })
    }, [getFrameDeleteListApi, setState])

    const deleteAuthorizedApi = useMountedPromiseFn(services.authorizedDelete)
    const deleteAuthorized = useCallback(
        (params: AuthorizedDeletes[]) => {
            setState({ loading: true })
            return deleteAuthorizedApi(params)
                .then(list => {
                    setState({ loading: false })
                    return list
                })
                .catch(err => {
                    setState({ loading: false })
                    return Promise.reject(err)
                })
        },
        [deleteAuthorizedApi, setState]
    )

    return {
        ...state,
        methods: { getFrameDeleteList, deleteAuthorized }
    }
}
