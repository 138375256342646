import { useI18n } from 'core/language/api'
import { useDetailState, useMemoizedFn, useMountedPromiseFn } from 'shared/hooks'
import { Product, ProductSku } from 'core/scanRent/models'
import { services } from 'core/scanRent/data-access'
import { useState } from 'react'

export const useProductDetail = (id: string) => {
    const { lang } = useI18n()

    const [state, setState] = useDetailState<Product>()
    const [productSku, setProductSku] = useState<ProductSku[]>()

    const getProductDetailApi = useMountedPromiseFn(services.getProduct)

    const getProductDetail = useMemoizedFn(() => {
        setState({ loading: true })
        return getProductDetailApi(lang, id)
            .then(async ({ value }) => {
                setState({ detail: value, loading: false })
                setProductSku(value.skuList)
            })
            .catch(err => {
                setState({ loading: false })
                return Promise.reject(err)
            })
    })

    return {
        ...state,
        productSku,
        methods: {
            getProductDetail
        }
    }
}
