import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { isMobile } from 'react-device-detect'

import { useLoginModal, useMemoizedFn, useMountedPromiseFn } from 'shared/hooks'
import { services } from 'core/auth/data-access'
import { ForgetPasswordPost, SignUpPost, LoginParam } from 'core/auth/models'
import { encryptPassword } from 'core/auth/models'
import {
    setToken,
    setIsTourist,
    removeIsTourist,
    setWalletToken,
    getToken,
    // getAccount,
    // getWallet,
    // removeAccount,
    // removeWallet,
    // setAccount,
    // setWallet,
    WalletEnum,
    setWalletAddress,
    getWalletToken,
    removeToken
} from 'shared/config'

import { useSolana, useWeb3 } from 'wallet'
import { actions } from '../../../state'

export const getWindowLocationHref = () => Promise.resolve(window.location.href)

const initialPermissions = {
    deviceAdmin: false,
    nftAdmin: false,
    storeAdmin: false,
    sponsorAdmin: false,
    sponsorList: []
}

export const usePermissions = () => {
    const setPermissions = useCallback(
        user => sessionStorage.setItem('permissions', JSON.stringify(user)),
        [sessionStorage]
    )

    const getPermissions = useCallback(() => {
        const permissions = sessionStorage.getItem('permissions')
        if (permissions) {
            return JSON.parse(permissions)
        } else {
            return initialPermissions
        }
    }, [])

    const clearPermissions = useCallback(() => setPermissions(initialPermissions), [setPermissions])

    return {
        methods: {
            getPermissions,
            setPermissions,
            clearPermissions
        }
    }
}

export const useAuth = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [loginParam] = useState({
        clientId: 0,
        grantType: 'password'
    })

    const {
        methods: { setPermissions }
    } = usePermissions()

    const signUpApi = useMountedPromiseFn(services.signUp)
    const signUp = useCallback(
        (param: SignUpPost) => {
            setLoading(true)
            return signUpApi({ ...param, ...loginParam })
                .then(data => {
                    setLoading(false)
                    if (typeof data === 'object' && data?.accessToken) {
                        setToken(data.accessToken)
                        setWalletToken(data.walletToken)
                        setWalletAddress(data.walletAddress)
                        return data.accessToken
                    } else {
                        return ''
                    }
                })
                .catch(err => {
                    setLoading(false)
                    return Promise.reject(err)
                })
        },
        [signUpApi, loginParam]
    )

    const signInApi = useMountedPromiseFn(services.signIn)
    const signIn = useCallback(
        (param: LoginParam) => {
            const { password: pwd, nonce } = encryptPassword(param.password)
            setLoading(true)
            return signInApi({
                loginName: param.loginName,
                password: pwd,
                nonce,
                ...loginParam
            })
                .then(({ accessToken, walletToken, walletAddress }) => {
                    setToken(accessToken)
                    setWalletToken(walletToken)
                    setWalletAddress(walletAddress)
                    setLoading(false)
                    return { accessToken }
                })
                .catch(err => {
                    setLoading(false)
                    return Promise.reject(err)
                })
        },
        [signInApi, loginParam]
    )

    const signInWithGoogleApi = useMountedPromiseFn(services.signInWithGoogle)
    const signInWithGoogle = useCallback(
        (idToken: string, shareCode?: string) => {
            setLoading(true)
            return signInWithGoogleApi(idToken, shareCode)
                .then(({ accessToken, walletToken, walletAddress }) => {
                    setToken(accessToken)
                    setWalletToken(walletToken)
                    setWalletAddress(walletAddress)
                    setLoading(false)
                    return { accessToken }
                })
                .catch(err => {
                    setLoading(false)
                    return Promise.reject(err)
                })
        },
        [signInWithGoogleApi]
    )

    const signInWithAppleApi = useMountedPromiseFn(services.signInWithApple)
    const signInWithApple = useCallback(
        (params: Parameters<typeof signInWithAppleApi>[0], shareCode?: string) => {
            setLoading(true)
            return signInWithAppleApi(params, shareCode)
                .then(({ accessToken, walletToken, walletAddress }) => {
                    setToken(accessToken)
                    setWalletToken(walletToken)
                    setWalletAddress(walletAddress)
                    setLoading(false)
                    return { accessToken }
                })
                .catch(err => {
                    setLoading(false)
                    return Promise.reject(err)
                })
        },
        [signInWithAppleApi]
    )

    const touristSignInApi = useMountedPromiseFn(services.touristSignIn)
    const touristSignIn = useCallback(
        (recaptchaToken: string) => {
            setLoading(true)
            return touristSignInApi(recaptchaToken)
                .then(({ accessToken }) => {
                    setToken(accessToken)
                    setIsTourist(true)
                    setLoading(false)
                    return { accessToken }
                })
                .catch(err => {
                    setLoading(false)
                    return Promise.reject(err)
                })
        },
        [touristSignInApi]
    )

    const touristBindApi = useMountedPromiseFn(services.touristBind)
    const touristBind = useCallback(
        (param: SignUpPost) => {
            setLoading(true)
            return touristBindApi(param)
                .then(data => {
                    removeIsTourist()
                    setLoading(false)
                    if (typeof data === 'object' && data?.accessToken) {
                        setToken(data.accessToken)
                        return data.accessToken
                    } else {
                        return ''
                    }
                })
                .catch(err => {
                    setLoading(false)
                    return Promise.reject(err)
                })
        },
        [touristBindApi]
    )

    const forgetPasswordApi = useMountedPromiseFn(services.forgetPassword)
    const forgetPassword = useCallback(
        (param: ForgetPasswordPost) => {
            setLoading(true)
            return forgetPasswordApi(param)
                .then(() => {
                    setLoading(false)
                    return true
                })
                .catch(err => {
                    setLoading(false)
                    return Promise.reject(err)
                })
        },
        [forgetPasswordApi]
    )

    const getUserInfoApi = useMountedPromiseFn(services.getUserInfo)
    const getUserInfo = useCallback(() => {
        setLoading(true)
        return getUserInfoApi()
            .then(
                ({
                    userName,
                    loginName,
                    status,
                    email,
                    phone,
                    link,
                    shareClickCount,
                    shareRegisterCount,
                    totalAmount,
                    withdrawAmount,
                    ingAmount,
                    commissionRate,
                    storeAdmin,
                    sponsorAdmin,
                    userid,
                    role
                }) => {
                    setLoading(false)
                    setPermissions({ ...role, sponsorList: sponsorAdmin })
                    return Promise.resolve({
                        userName: userName || loginName,
                        status,
                        email,
                        phone,
                        link,
                        shareClickCount,
                        shareRegisterCount,
                        totalAmount: totalAmount.toFixed(2),
                        withdrawAmount: withdrawAmount.toFixed(2),
                        ingAmount: ingAmount.toFixed(2),
                        commissionRate,
                        storeAdmin,
                        sponsorList: sponsorAdmin,
                        userid,
                        role
                    })
                }
            )
            .catch(err => {
                setLoading(false)
                return Promise.reject(err)
            })
    }, [getUserInfoApi])

    const changePwdApi = useMountedPromiseFn(services.changePwd)
    const changePwd = useMemoizedFn((oldPassword: string, newPassword: string) => {
        setLoading(true)
        const { password, nonce } = encryptPassword(oldPassword)
        return changePwdApi({ oldPassword: password, nonce, newPassword })
            .then(() => {
                setLoading(false)
                return
            })
            .catch(err => {
                setLoading(false)
                return Promise.reject(err)
            })
    })

    const dispatch = useDispatch()
    const {
        methods: { hide }
    } = useLoginModal()
    const {
        hasSolana,
        methods: { connectPhantom }
    } = useSolana()
    const {
        hasEthereum,
        methods: { connectMetaMask }
    } = useWeb3()

    const getDeepLinkPrefix = useMemoizedFn(async () => {
        const href = await getWindowLocationHref()
        return `${href}?token=${getToken()}&wallet_token=${getWalletToken()}`
    })

    const getDeepLinkMetaMask = useMemoizedFn(async () => {
        const prefix = await getDeepLinkPrefix()
        return `https://metamask.app.link/dapp/${prefix}`
    })

    const getDeepLinkPhantom = useMemoizedFn(async () => {
        const prefix = await getDeepLinkPrefix()
        const hostname = await Promise.resolve(window.location.hostname)
        return `https://phantom.app/ul/browse/${encodeURIComponent(prefix)}?ref=${encodeURIComponent(
            `https://${hostname}`
        )}`
    })

    const connect = useMemoizedFn(async (wallet: WalletEnum) => {
        console.log(getDeepLinkMetaMask())
        console.log(getDeepLinkPhantom())
        let accountRes = ''
        switch (wallet) {
            case WalletEnum.METAMASK:
                if (isMobile && !hasEthereum) {
                    console.info('User will open site in Metamask App')
                    document.location = await getDeepLinkMetaMask()
                    return
                }
                accountRes = await connectMetaMask()
                break
            case WalletEnum.PHANTOM:
                if (isMobile && !hasSolana) {
                    console.info('User will open site in Metamask App')
                    document.location = await getDeepLinkPhantom()
                    return
                }
                accountRes = await connectPhantom()
                break
            default:
                break
        }
        // await bindingWallet(accountRes).catch(handleError)
        hide()
        return accountRes
    })

    // const disconnect = useMemoizedFn(async () => {
    //     switch (getWallet()) {
    //         case WalletEnum.METAMASK:
    //             await disconnectMetaMask()
    //             break
    //         case WalletEnum.PHANTOM:
    //             await disconnectPhantom()
    //             break
    //         default:
    //             break
    //     }
    //     removeUserInfo()
    // })

    const removeUserInfo = useMemoizedFn(() => {
        // removeWallet()
        removeToken()
        // removeWalletToken()
        // dispatch(actions.clearUserAccessToken())
        dispatch(actions.clearWalletAddressList())
    })

    // const initUserInfo = useMemoizedFn(() => {
    //     getToken() && dispatch(actions.setUserAccessToken({ userAccessToken: getToken() }))
    //     getAccount() && dispatch(actions.setUserAccount({ userAccount: getAccount() }))
    //     getToken() && getAccount() && getWallet() && connect(getWallet() as WalletEnum)
    // })

    return {
        loading,
        hasSolana,
        hasEthereum,
        methods: {
            signUp,
            signIn,
            signInWithGoogle,
            signInWithApple,
            touristSignIn,
            touristBind,
            forgetPassword,
            getUserInfo,
            changePwd,
            removeUserInfo,
            connect
            // initUserInfo,
            // disconnect
        }
    }
}
