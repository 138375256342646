import { Language, TranslateVariable, I18nMap } from 'core/language/models'

// Handling internationalization of hints returned by server-side Ruby
const resetKey = (key: string) => {
    switch (key) {
        case 'User not found':
        case 'Invalid account or password':
        case 'Invalid account or password.':
            return 'error.user_not_found'

        case 'Invalid authentication token.':
            return 'error.authention_failed'
        case 'error.info.email':
            return 'error.email_error'
        default:
            return key
    }
}

export const translate = (
    i18nMap: I18nMap,
    lang: Language,
    key: string,
    variable?: TranslateVariable
): string => {
    // console.log('key log => ', key)
    const _key = resetKey(key)
    const keyPath = _key.split('.')
    const currentLangConfig: any = i18nMap[lang] || {}
    const { result } = keyPath.reduce(
        (acc, cur, index) => {
            if (acc.preConfig[cur]) {
                if (index === keyPath.length - 1) {
                    return { result: acc.preConfig[cur], preConfig: {} }
                } else {
                    return { result: '', preConfig: acc.preConfig[cur] as {} }
                }
            } else {
                return { result: '', preConfig: {} }
            }
        },
        { result: '', preConfig: currentLangConfig }
    )
    if (!result) return _key
    if (!variable) return result
    return Object.keys(variable).reduce((acc, cur) => {
        return acc.replace('${{' + cur + '}}', `${variable[cur]}`)
    }, result)
}
