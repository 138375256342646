import React, { FC, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'

import { useI18n } from 'core/language/api'
import { useAdList, useAdStatistics } from 'core/ad-statistics/api'

import { useListSearchForm, useMemoizedFn, useResponsive, useShowMessage } from 'shared/hooks'

import { Spin } from 'components'
import { ColumnProps } from 'antd/lib/table'
import { Button, Table, Input } from 'antd'
import { Modal } from '@mantine/core'
import { EChart } from './components/Echart'
import QRCode from 'qrcode.react'
import moment from 'moment'

import { SignWrapper } from './components/SignWrapper'

import leftIcon from './assets/left.png'
import centerIcon from './assets/center.png'
import rightIcon from './assets/right.png'
import closeIcon from './assets/close.png'
import { AdList } from 'core/ad-statistics/models'
import {
    getSelectEChartTimeData,
    getToken,
    removeSelectEChartTimeData,
    setSelectEChartTimeData
} from 'shared/config'

const linkUrl =
    // eslint-disable-next-line no-useless-escape
    /^((ht|f)tps?):\/\/([\w\-]+(\.[\w\-]+)*\/)*[\w\-]+(\.[\w\-]+)*\/?(\?([\w\-\.,@?^=%&:\/~\+#]*)+)?/

const roleList = ['All', 'Today', 'Last 3 days', 'Last 7 days', 'Last 30 days']
const PAGE_SIZE = 10
const defaultSearchValues = {
    pageNum: '1',
    pageSize: `${PAGE_SIZE}`
}

export const AdStatistics: FC = () => {
    const {
        methods: { t }
    } = useI18n()
    const {
        methods: { showErrorModal }
    } = useShowMessage()
    const { md } = useResponsive()
    const navigate = useNavigate()
    const isLogin = useMemoizedFn(() => getToken())

    const [addOrEdit, setAddOrEdit] = useState('add')
    const [opened, setOpened] = useState(false)
    const [name, setName] = useState('')
    const [link, setLink] = useState('')
    const [selectValue, setSelectValue] = useState('Last 7 days')

    const startTime = useMemo(() => {
        if (selectValue === roleList[0]) {
            return 'all'
        } else if (selectValue === roleList[3]) {
            return moment().add(-7, 'd').format('YYYY-MM-DD')
        } else if (selectValue === roleList[2]) {
            return moment().add(-3, 'd').format('YYYY-MM-DD')
        } else if (selectValue === roleList[1]) {
            return moment().startOf('day').format('YYYY-MM-DD')
        } else if (selectValue === roleList[4]) {
            return moment().subtract(1, 'month').format('YYYY-MM-DD')
        }
        return ''
    }, [selectValue])

    const [selectTime, setSelectTime] = useState(['', ''])
    const [editItem, setEditItem] = useState<AdList>()
    const [defaultTime, setDefaultTime] = useState<Array<moment.Moment | string>>()
    const [timeDate, setTimeDate] = useState<string[]>([])
    const { params, onChangePage } = useListSearchForm(defaultSearchValues, false)
    const [visitsOrUv, setVisitsOrUv] = useState('visits')

    const {
        loading,
        todayScanNumber,
        totalAdNumber,
        totalScanNumber,
        totalHistory: eChartList,
        methods: { getAdStatistics }
    } = useAdStatistics()

    const {
        loading: listLoading,
        list,
        total,
        methods: { getList, create, edit }
    } = useAdList()

    useEffect(() => {
        const defaultTime = getSelectEChartTimeData()
        if (defaultTime) {
            if (
                defaultTime.RangePicker &&
                defaultTime.RangePicker.length > 0 &&
                defaultTime?.RangePicker[0] !== '' &&
                defaultTime.RangePicker[1] !== ''
            ) {
                setDefaultTime([moment(defaultTime.RangePicker[0]), moment(defaultTime.RangePicker[1])])
                setSelectTime([
                    moment(defaultTime.RangePicker?.[0]).format('YYYY-MM-DD'),
                    moment(defaultTime.RangePicker?.[1]).format('YYYY-MM-DD')
                ])
                setTimeDate([
                    moment(defaultTime.RangePicker?.[0]).format('YYYY-MM-DD'),
                    moment(defaultTime.RangePicker?.[1]).format('YYYY-MM-DD')
                ])
            }
            if (defaultTime.time) {
                setSelectValue(defaultTime.time)
            }
            if (defaultTime.type) {
                setVisitsOrUv(defaultTime.type)
            }
        }
        removeSelectEChartTimeData()
    }, [])

    useEffect(() => {
        isLogin() &&
            getList({
                ...params,
                beginDate: selectTime[0] ? selectTime[0] : startTime === 'all' ? '' : startTime,
                endDate: selectTime[1]
                    ? selectTime[1]
                    : startTime === 'all'
                    ? ''
                    : moment().format('YYYY-MM-DD')
            }).catch(err => showErrorModal(t(err.data.msg)))
    }, [getList, isLogin, params, selectTime, showErrorModal, startTime, t])

    useEffect(() => {
        isLogin() &&
            getAdStatistics({
                beginDate: selectTime[0] ? selectTime[0] : startTime === 'all' ? ' ' : startTime,
                endDate: selectTime[1]
                    ? selectTime[1]
                    : startTime === 'all'
                    ? ' '
                    : moment().format('YYYY-MM-DD'),
                isUv: visitsOrUv === 'uv' ? '1' : ''
            }).catch(err => showErrorModal(t(err.data.msg)))
    }, [getAdStatistics, isLogin, selectTime, showErrorModal, startTime, t, visitsOrUv])

    const onEdit = (value: AdList) => {
        setName(value.name)
        setLink(value.link)
        setEditItem(value)
        setOpened(true)
        setAddOrEdit('edit')
    }

    const saveSelect = () => {
        setSelectEChartTimeData(
            JSON.stringify({
                RangePicker: defaultTime,
                time: selectValue,
                type: visitsOrUv
            })
        )
    }

    const columns: ColumnProps<AdList>[] = [
        {
            title: 'Ad Name',
            dataIndex: 'name',
            width: md ? '' : '54px',
            className: 'tableStyle mdTh'
        },
        {
            title: 'Ad link',
            dataIndex: 'link',
            className: 'tableStyle tablePadding mdTh'
        },
        {
            title: 'Visits',
            dataIndex: 'viewCount',
            className: 'tableStyle mdTh',
            width: md ? '200px' : '54px',
            align: 'center',
            render: (viewCount, value) => (
                <Button
                    type="link"
                    style={{ color: '#1677FF' }}
                    onClick={() => {
                        saveSelect()
                        const search = `selectData=${selectValue}&beginTime=${
                            selectTime[0] ? selectTime[0] : ''
                        }&endTime=${selectTime[1] ? selectTime[1] : ''}`
                        navigate(`/ad-statistics/${value.id}?${search}`)
                    }}
                >
                    {viewCount}
                </Button>
            )
        },
        {
            title: 'UV Statistics',
            dataIndex: 'uvCount',
            width: md ? '200px' : '76px',
            className: 'tableStyle mdTh',
            align: 'center',
            render: (uvCount, value) => (
                <Button
                    type="link"
                    style={{ color: '#1677FF' }}
                    onClick={() => {
                        saveSelect()
                        const search = `selectData=${selectValue}&beginTime=${
                            selectTime[0] ? selectTime[0] : ''
                        }&endTime=${selectTime[1] ? selectTime[1] : ''}&page=uv`
                        navigate(`/ad-statistics/${value.id}/uv?${search}`)
                    }}
                >
                    {uvCount}
                </Button>
            )
        },
        {
            title: 'Operation',
            dataIndex: 'id',
            width: md ? '' : '80px',
            className: 'tableStyle mdTh',
            render: (id, value) => (
                <Button
                    type="link"
                    style={{ marginLeft: md ? '10px' : '0px', color: '#1677FF' }}
                    onClick={() => onEdit(value)}
                >
                    Edit
                </Button>
            )
        }
    ]

    const onSave = () => {
        if (!name.trim()) {
            showErrorModal(t('Invalid or empty names'))
            return
        }
        if (!link.trim()) {
            showErrorModal(t('Invalid or empty link'))
            return
        }
        if (!linkUrl.test(link)) {
            showErrorModal(t('Incorrect ad link format'))
            return
        }
        if (addOrEdit === 'add') {
            create({ name: name.trim(), link: link.trim() })
                .then(() => {
                    getList(params).catch(err => showErrorModal(t(err.data.msg)))
                    getAdStatistics({
                        beginDate: selectTime[0] ? selectTime[0] : startTime === 'all' ? '' : startTime,
                        endDate: selectTime[1]
                            ? selectTime[1]
                            : startTime === 'all'
                            ? ' '
                            : moment().format('YYYY-MM-DD'),
                        isUv: visitsOrUv === 'uv' ? '1' : ''
                    }).catch(err => showErrorModal(t(err.data.msg)))
                })
                .catch(err => showErrorModal(t(err.data.msg)))
            setOpened(false)
            setName('')
            setLink('')
        }
        if (addOrEdit === 'edit') {
            editItem &&
                edit(`${editItem.id}`, { name, link })
                    .then(() => {
                        getList(params).catch(err => showErrorModal(t(err.data.msg)))
                        getAdStatistics({
                            beginDate: selectTime[0] ? selectTime[0] : startTime === 'all' ? '' : startTime,
                            endDate: selectTime[1]
                                ? selectTime[1]
                                : startTime === 'all'
                                ? ' '
                                : moment().format('YYYY-MM-DD'),
                            isUv: visitsOrUv === 'uv' ? '1' : ''
                        }).catch(err => showErrorModal(t(err.data.msg)))
                    })
                    .catch(err => showErrorModal(t(err.data.msg)))
            setOpened(false)
            setName('')
            setLink('')
        }
    }

    return (
        <Spin spinning={loading || listLoading}>
            {isLogin() ? (
                <Container>
                    <Content>
                        <Header>
                            <div className="textHeader">Dashboard</div>
                            <div className="box">
                                <HeaderCard className="leftCard">
                                    <div className="left">
                                        <div className="text">Total number of ads</div>
                                        <div className="num">{totalAdNumber}</div>
                                    </div>
                                    <img width={50} height={50} src={leftIcon} alt="AdStatistics" />
                                </HeaderCard>
                                <HeaderCard className="centerCard">
                                    <div className="left">
                                        <div className="text">Today's number of scanning</div>
                                        <div className="num">{todayScanNumber}</div>
                                    </div>
                                    <img width={50} height={50} src={centerIcon} alt="AdStatistics" />
                                </HeaderCard>
                                <HeaderCard className="rightCard">
                                    <div className="left">
                                        <div className="text">Total number of scanning</div>
                                        <div className="num">{totalScanNumber}</div>
                                    </div>
                                    <img width={50} height={50} src={rightIcon} alt="AdStatistics" />
                                </HeaderCard>
                            </div>
                        </Header>
                        <EChart
                            setSelectTime={setSelectTime}
                            roleList={roleList}
                            selectValue={selectValue}
                            setSelectValue={setSelectValue}
                            chartList={eChartList}
                            defaultTime={defaultTime}
                            setDefaultTime={setDefaultTime}
                            visitsOrUv={visitsOrUv}
                            setVisitsOrUv={setVisitsOrUv}
                            timeDate={timeDate}
                            setTimeDate={setTimeDate}
                        />
                        <Create>
                            <CreateBtn
                                onClick={() => {
                                    setOpened(true)
                                    setAddOrEdit('add')
                                }}
                            >
                                Create
                            </CreateBtn>
                        </Create>
                        <div className="table">
                            <Table
                                tableLayout="fixed"
                                columns={columns}
                                dataSource={list}
                                rowKey="id"
                                pagination={
                                    total > 10
                                        ? {
                                              total,
                                              pageSize: +params.pageSize,
                                              current: +params.pageNum,
                                              onChange: pageNum => {
                                                  onChangePage(pageNum)
                                                  // onChangePageSize(pageSize)
                                              },
                                              showSizeChanger: false
                                              // pageSizeOptions: ['20', '50', '100']
                                          }
                                        : false
                                }
                                rowClassName={(_, index) =>
                                    index % 2 === 0 ? 'tableStyle' : 'darkRow tableStyle'
                                }
                            />
                        </div>
                        <Modal
                            opened={opened}
                            withCloseButton={false}
                            onClose={() => {}}
                            centered={true}
                            padding={18}
                            styles={{
                                modal: {
                                    width: md ? '530px' : '350px',
                                    borderRadius: '20px'
                                }
                            }}
                        >
                            <div>
                                <ModalHeader>
                                    <div className="icon">
                                        <img
                                            width={24}
                                            height={24}
                                            src={closeIcon}
                                            alt="adStatistics"
                                            onClick={() => {
                                                setOpened(false)
                                                setName('')
                                                setLink('')
                                            }}
                                        />
                                    </div>
                                    <div className="text">
                                        {addOrEdit === 'edit' ? 'Edit Ads' : 'Add Ads'}
                                    </div>
                                </ModalHeader>
                                <ModalContent>
                                    <div className="title" style={{ marginTop: 0 }}>
                                        Name
                                    </div>
                                    <Input
                                        width={410}
                                        size="large"
                                        value={name}
                                        onChange={({ target: { value } }) => setName(value)}
                                    />
                                    <div className="title">Link</div>
                                    <Input
                                        width={410}
                                        size="large"
                                        value={link}
                                        onChange={({ target: { value } }) => setLink(value)}
                                    />
                                    {addOrEdit === 'edit' && editItem && (
                                        <div>
                                            <div className="title">QR Code</div>
                                            <QRCode value={editItem.qrCodeLink} size={100} fgColor="#000" />
                                        </div>
                                    )}
                                </ModalContent>
                                <ModalButton onClick={onSave}>Save</ModalButton>
                            </div>
                        </Modal>
                    </Content>
                </Container>
            ) : (
                <SignWrapperBox>
                    <SignWrapper></SignWrapper>
                </SignWrapperBox>
            )}
        </Spin>
    )
}
const Content = styled.div`
    max-width: 1000px;
    width: 100%;
`
const Container = styled.div`
    width: 100%;
    min-height: calc(100vh - 68px - 90px);
    padding: 0 15.3% 78px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8f9fa;
    @media (max-width: 768px) {
        width: 100%;
        padding: 0 24px 50px;
        .mdTh {
            padding: 16px 8px;
        }
    }
    .table {
        width: 100%;
        border: 1px solid #f3f4f5;
        border-radius: 10px;
        overflow: hidden;
        .ant-table-thead
            > tr
            > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
            width: 0px !important;
        }
        .ant-table-thead > tr > th {
            background-color: #f3f4f5 !important;
        }
    }
    .tableStyle {
        font-family: Roboto-Regular !important;
    }
`
const SignWrapperBox = styled.div`
    width: 100%;
    height: 600px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
`
const Header = styled.div`
    margin: 50px 0px;
    .textHeader {
        font-family: Roboto-Bold;
        font-size: 36px;
        color: #000000;
        line-height: 28px;
        font-weight: 700;
        margin-bottom: 40px;
    }
    .box {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .leftCard {
        margin-right: 10px;
        background: #04cfc3;
    }
    .centerCard {
        margin-right: 10px;
        background: #4a71f0;
    }
    .rightCard {
        background: #ef864b;
    }
    @media (max-width: 768px) {
        .box {
            flex-direction: column;
        }
    }
`
const HeaderCard = styled.div`
    width: 308px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 26px 16px 26px 30px;
    box-shadow: 0px 10px 20px 0px rgba(183, 183, 183, 0.5);
    border-radius: 10px;
    .text {
        font-family: Roboto-Regular;
        font-size: 15px;
        color: #ffffff;
        line-height: 22px;
        font-weight: 400;
        margin-bottom: 10px;
    }
    .num {
        font-family: Roboto-Bold;
        font-size: 36px;
        color: #ffffff;
        line-height: 36px;
        font-weight: 700;
    }
    @media (max-width: 768px) {
        margin-bottom: 20px;
    }
`
const Create = styled.div`
    margin: 40px 0px 20px;
    display: flex;
    justify-content: flex-end;
`
const CreateBtn = styled.div`
    width: 100px;
    height: 36px;
    background: #000000;
    border-radius: 22px;
    font-family: Roboto-Medium;
    font-size: 18px;
    color: #ffffff;
    text-align: center;
    line-height: 36px;
    font-weight: 500;
    cursor: pointer;
`
const ModalHeader = styled.div`
    margin-bottom: 50px;
    .icon {
        display: flex;
        justify-content: flex-end;
        img {
            cursor: pointer;
        }
    }
    .text {
        font-family: Roboto-Medium;
        font-size: 30px;
        color: #000000;
        text-align: center;
        line-height: 30px;
        font-weight: 500;
    }
`
const ModalContent = styled.div`
    padding: 0px 42px;
    .title {
        font-family: Roboto-Regular;
        font-size: 18px;
        color: #000000;
        line-height: 26px;
        font-weight: 400;
        margin: 30px 0px 10px;
    }
`
const ModalButton = styled.div`
    height: 50px;
    width: 378px;
    margin: 50px auto 32px;
    background: #000000;
    border-radius: 35.18px;
    font-family: Roboto-Medium;
    font-size: 25.33px;
    color: #ffffff;
    text-align: center;
    line-height: 50px;
    font-weight: 500;
    cursor: pointer;
    @media (max-width: 768px) {
        width: 314px;
    }
`
