import React, { FC, useCallback, useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from 'core/auth/api'
import { useApiError, useShowMessage, useSignInRedirect } from 'shared/hooks'
import { getShareCode, removeShareCode } from 'shared/config'
import { Reg } from 'shared/utils'
import { useI18n } from 'core/language/api'
import styled from '@emotion/styled'
import { Button, Spin } from 'components'
import { SIGN_STATUS_ENUM } from 'core/auth/models'

import { Input } from './common/Input'
import { InputWrapper } from './common/InputWrapper'
import { Reaptcha } from './common/Reaptcha'
import { VerificationCode } from './common/VerificationCode'

export const TouristBinging: FC = () => {
    const {
        methods: { t }
    } = useI18n()
    const { redirectBack } = useSignInRedirect()
    const navigate = useNavigate()
    const {
        methods: { error }
    } = useShowMessage()
    const {
        loading,
        methods: { touristBind }
    } = useAuth()
    const {
        methods: { handleError }
    } = useApiError('Auth', error as any, 'auth')

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [loginName, setLoginName] = useState('')
    const [isActiveEmail, setIsActiveEmail] = useState(false)
    const [isActivePwd, setIsActivePwd] = useState(false)
    const [isActivePwd2, setIsActivePwd2] = useState(false)
    const [showReaptcha, setShowReaptcha] = useState(false)
    const [recaptchaToken, setRecaptchaToken] = useState('')
    const [authCode, setAuthCode] = useState('')

    const isEmail = useMemo(() => {
        return Reg.email.test(loginName)
    }, [loginName])

    const isPwd = useMemo(() => {
        return !!password && password === confirmPassword && password.length >= 6 && password.length <= 18
    }, [password, confirmPassword])

    const submitDisabled = useMemo(() => {
        return !(isEmail && isPwd && !!authCode)
    }, [isEmail, isPwd, authCode])

    useEffect(() => {
        setShowReaptcha(isEmail && isPwd)
    }, [loginName, isEmail, isPwd])

    const submitHandle = useCallback(() => {
        const shareCode = getShareCode()
        const submitData = shareCode
            ? {
                  mobile: '',
                  email: loginName,
                  password,
                  authCode,
                  shareCode
              }
            : {
                  mobile: '',
                  email: loginName,
                  password,
                  authCode
              }
        touristBind(submitData)
            .then(token => {
                removeShareCode()
                if (token) {
                    redirectBack()
                } else {
                    navigate('/auth/' + SIGN_STATUS_ENUM.SIGN_IN)
                }
            })
            .catch(err => {
                handleError(err, () => navigate('/auth/' + SIGN_STATUS_ENUM.SIGN_IN))
            })
    }, [loginName, password, authCode, touristBind, redirectBack, navigate, handleError])

    const onVerify = useCallback((token: string) => {
        setRecaptchaToken(token)
        setTimeout(() => {
            setShowReaptcha(false)
        }, 800)
    }, [])

    const resendAuthCode = useCallback(() => {
        setShowReaptcha(true)
        setRecaptchaToken('')
        setAuthCode('')
    }, [setShowReaptcha, setRecaptchaToken, setAuthCode])

    return (
        <Spin spinning={loading}>
            <Wrapper>
                <div className="tip">
                    An affiliate link is assigned to a MetaSill account. Enter your email and set the password
                    to sign up.
                </div>
                <InputWrapper isActive={isActiveEmail}>
                    <Input
                        type="email"
                        value={loginName}
                        placeholder={t('client.auth.placeholder.email')}
                        onChange={setLoginName}
                        onFocus={() => setIsActiveEmail(true)}
                        onBlur={() => setIsActiveEmail(false)}
                        length={[5, 50]}
                    />
                </InputWrapper>
                <InputWrapper isActive={isActivePwd}>
                    <Input
                        type="password"
                        value={password}
                        placeholder={t('Please enter your password')}
                        onChange={setPassword}
                        onFocus={() => setIsActivePwd(true)}
                        onBlur={() => setIsActivePwd(false)}
                    />
                </InputWrapper>
                <InputWrapper isActive={isActivePwd2}>
                    <Input
                        type="password"
                        value={confirmPassword}
                        placeholder={t('Please enter your password')}
                        onChange={setConfirmPassword}
                        onFocus={() => setIsActivePwd2(true)}
                        onBlur={() => setIsActivePwd2(false)}
                    />
                </InputWrapper>

                {recaptchaToken && (
                    <VerificationCode
                        loginName={loginName}
                        recaptchaToken={recaptchaToken}
                        placeholder={t('client.auth.verificationCode.emailCode')}
                        onChange={setAuthCode}
                        onResend={resendAuthCode}
                        onError={err => {
                            handleError(err)
                            setRecaptchaToken('')
                        }}
                        verificationCodeType={'bind'}
                    />
                )}

                {showReaptcha && (
                    <div className="mb-6">
                        <Reaptcha onVerify={onVerify} />
                    </div>
                )}

                <div className="submit-first flex items-center justify-center">
                    <Button className="submit-btn" onClick={submitHandle} disabled={submitDisabled}>
                        {t('Join now')}
                    </Button>
                </div>
            </Wrapper>
        </Spin>
    )
}

const Wrapper = styled.div`
    position: relative;
    box-sizing: border-box;
    .tip {
        margin-bottom: 30px;
        font-family: Roboto-Regular;
        font-size: 16px;
        color: #000000;
        letter-spacing: 0;
        text-align: center;
        line-height: 20px;
    }
    .submit-first {
        margin: 40px auto 12px;
    }
    .submit-btn {
        width: 100%;
        font-family: Roboto-Medium;
        font-size: 25.33px;
        color: #ffffff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 500;
    }
`
