import React, { FC } from 'react'
import styled from '@emotion/styled'

import { useResponsive } from 'shared/hooks'

import { Modal } from 'antd'
import { BillingAddress } from './BillingAddress'

import type { BaseDelivery } from 'core/delivery/models'

export interface AddModalProps {
    visible: boolean
    disabled: boolean
    onCancel: () => void
    onAddBilling: (value: BaseDelivery) => void
}
export const AddModal: FC<AddModalProps> = ({ visible, onCancel, onAddBilling }) => {
    const { md } = useResponsive()

    return (
        <Modal
            open={visible}
            onCancel={onCancel}
            footer={null}
            width={md ? 850 : '100%'}
            destroyOnClose
            style={{ maxWidth: '100%', margin: md ? '0 auto' : 0, top: md ? 100 : 56 }}
        >
            <AddModalContent>
                <AddModalTitle>Add a new address</AddModalTitle>
                <BillingAddress btnTitle="Deliver to this address" onSubmit={onAddBilling} disabled={false} />
            </AddModalContent>
        </Modal>
    )
}

const AddModalContent = styled.div`
    margin: 6px 0 2px 0;
    @media (max-width: 576px) {
        margin: 0;
    }
`
const AddModalTitle = styled.div`
    font-family: Roboto-Medium;
    font-size: 30px;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    line-height: 28px;
    margin-bottom: 30px;
`
