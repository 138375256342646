import React, { FC } from 'react'
import styled from '@emotion/styled'

import { useResponsive } from 'shared/hooks'
import { EventBillingAddressDetail } from 'core/event/models'

import { Button, Modal } from 'antd'

export interface BillingListProps {
    data: EventBillingAddressDetail[]
    visible: boolean
    onCancel: () => void
    onSelect: (id: string) => void
    onAddModalShow: () => void
    selectedBillingId: string
}

export const BillingList: FC<BillingListProps> = ({
    data,
    visible,
    onCancel,
    onSelect,
    onAddModalShow,
    selectedBillingId
}) => {
    const { md } = useResponsive()

    return (
        <Modal
            open={visible}
            onCancel={onCancel}
            footer={null}
            width={md ? 850 : '100%'}
            style={{ maxWidth: '100%', margin: md ? '0 auto' : 0, top: md ? 100 : 56 }}
        >
            <BillingListModal>
                <BillingListTitle>Select a billing address</BillingListTitle>
                {data.map(
                    ({
                        id,
                        firstName,
                        lastName,
                        streetAddress,
                        city,
                        state,
                        zipCode,
                        phone,
                        companyName
                    }) => {
                        return (
                            <BillingListContent
                                key={id}
                                onClick={() => {
                                    onSelect(id)
                                    onCancel()
                                }}
                                className={selectedBillingId === id ? 'active' : ''}
                            >
                                <div>
                                    {firstName} {lastName}
                                </div>
                                <div>{companyName}</div>
                                <div>{streetAddress}</div>
                                <div>
                                    {city} {state} {zipCode}
                                </div>
                                <div>{phone}</div>
                            </BillingListContent>
                        )
                    }
                )}
                <BillingListButton
                    type="primary"
                    onClick={() => {
                        onCancel()
                        onAddModalShow()
                    }}
                >
                    Add a new address
                </BillingListButton>
            </BillingListModal>
        </Modal>
    )
}

const BillingListModal = styled.div`
    margin: 6px 0 2px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
        margin: 0;
        padding: 0;
    }
    .active {
        border: 2px solid rgba(47, 46, 46, 1);
    }
`

const BillingListTitle = styled.div`
    font-family: Roboto-Medium;
    font-size: 24px;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    line-height: 28px;
`

const BillingListContent = styled.div`
    width: 588px;
    height: 152px;
    background: #fff;
    box-shadow: 0px 6px 12px 0px rgba(56, 55, 64, 0.27);
    border-radius: 10px;
    /* height: 152px; */
    padding: 20px 30px;
    margin-top: 30px;
    font-family: Roboto-Medium;
    font-size: 20px;
    color: #333333;
    @media (max-width: 576px) {
        width: 100% !important;
        background: #fff;
        box-shadow: 0px 6px 12px 0px rgba(10, 4, 60, 0.08);
        border-radius: 10px;
        padding: 20px 16px 20px 30px;
    }
`
const BillingListButton = styled(Button)`
    font-family: Roboto-Medium;
    width: 378px;
    height: 50px;
    border-radius: 36px;
    margin-top: 50px;
    font-size: 26px;
    @media (max-width: 576px) {
        width: 100%;
    }
`
