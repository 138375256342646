import React, { CSSProperties, FC, useState } from 'react'
import styled from '@emotion/styled'

import { useAuth } from 'core/auth/api'
import { useApiError, useMemoizedFn, useShowMessage } from 'shared/hooks'

import { Input } from 'antd'
import { Header } from '../components/header'

const oldMsg = 'Please enter the old password'
const newMsg = 'Please enter the new password'
const confirmMsg = 'Please confirm the new password'

const inputStyle: CSSProperties = { width: 354, height: 40 }

export const Password: FC = () => {
    const [oldPwd, setOldPwd] = useState('')
    const [newPwd, setNewPwd] = useState('')
    const [confirmPwd, setConfirmPwd] = useState('')

    const {
        methods: { handleError }
    } = useApiError('Auth')
    const {
        methods: { error, success }
    } = useShowMessage()
    const {
        methods: { changePwd }
    } = useAuth()

    const handleSave = useMemoizedFn(() => {
        if (!oldPwd) {
            error(oldMsg)
            return
        }
        if (!newPwd) {
            error(newMsg)
            return
        }
        if (!confirmPwd) {
            error(confirmMsg)
            return
        }
        if (newPwd !== confirmPwd) {
            error('The password entered twice does not match')
            return
        }
        changePwd(oldPwd, newPwd)
            .then(() => {
                success('Password changed successfully')
                setOldPwd('')
                setNewPwd('')
                setConfirmPwd('')
            })
            .catch(handleError)
    })

    return (
        <Wrapper>
            <Header name="Change Password" onClick={handleSave} />
            <div className="formItem">
                <div className="label">{oldMsg}</div>
                <div className="value">
                    <Input.Password
                        value={oldPwd}
                        placeholder={oldMsg}
                        style={inputStyle}
                        onChange={({ target: { value } }) => setOldPwd(value)}
                    />
                </div>
            </div>
            <div className="formItem">
                <div className="label">{newMsg}</div>
                <div className="value">
                    <Input.Password
                        value={newPwd}
                        placeholder={newMsg}
                        style={inputStyle}
                        onChange={({ target: { value } }) => setNewPwd(value)}
                    />
                </div>
            </div>
            <div className="formItem">
                <div className="label">{confirmMsg}</div>
                <div className="value">
                    <Input.Password
                        value={confirmPwd}
                        placeholder={confirmMsg}
                        style={inputStyle}
                        onChange={({ target: { value } }) => setConfirmPwd(value)}
                    />
                </div>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    .formItem {
        margin-top: 30px;
        .value {
            display: flex;
            font-family: Roboto-Regular;
        }
        .label {
            margin-bottom: 10px;
            font-family: Roboto-Regular;
            font-size: 18px;
            color: #000000;
            line-height: 26px;
        }
        .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 261px;
            height: 40px;
            margin-left: 10px;
            background: rgba(0, 0, 0, 0.3);
            font-family: Roboto-Bold;
            font-size: 18px;
            color: #ffffff;
            cursor: pointer;
        }
        .ant-input {
            font-family: Roboto-Regular;
            font-size: 18px;
            &::placeholder {
                color: #999 !important;
            }
        }
    }
`
