import React, { FC, useEffect } from 'react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { Spin, Pagination } from 'antd'

import { useEventList } from 'core/event/api'
import { useApiError, useListSearchForm, useUserInfo } from 'shared/hooks'
import { LineButton, PageContainer } from 'components'
import { Image } from 'antd'

import defImg from './assets/default-img.png'
import event from './assets/banner_event.png'

const PAGE_SIZE = 12
const defaultSearchValues = {
    pageNum: '1',
    pageSize: `${PAGE_SIZE}`
}

export const EventIndex: FC = () => {
    const navigate = useNavigate()
    const userInfo = useUserInfo()

    const {
        methods: { handleError }
    } = useApiError('FeatureEventList')
    const {
        loading,
        list,
        total,
        methods: { getList }
    } = useEventList()

    const { params, onChangePage } = useListSearchForm(defaultSearchValues, false)
    useEffect(() => {
        getList(params).catch(handleError)
    }, [getList, handleError, params])

    return (
        <Spin spinning={loading}>
            <BannerImg>
                <Image width={'100%'} height={'100%'} src={event} preview={false} />
            </BannerImg>
            <Container>
                <PageHeader>
                    {userInfo?.storeAdmin && (
                        <LineButton className="createBtn" onClick={() => navigate('/event/create')}>
                            Add
                        </LineButton>
                    )}
                </PageHeader>
                <EventList>
                    {list.map((item, index) => (
                        <div className="item" key={index} onClick={() => navigate(`/event/${item.id}`)}>
                            <div
                                className="img"
                                style={{
                                    backgroundImage: `url(${item.firstImg ? item.firstImg : defImg})`
                                }}
                            />
                            <div className="info">
                                <div className="text">{item.title}</div>
                            </div>
                        </div>
                    ))}
                </EventList>
                {total > PAGE_SIZE && (
                    <div className="pagination">
                        <Pagination onChange={onChangePage} pageSize={PAGE_SIZE} total={total} />
                    </div>
                )}
            </Container>
        </Spin>
    )
}

const Container = styled(PageContainer)`
    width: 100%;
    padding: 0 10rem 100px;
    .pagination {
        clear: both;
        display: flex;
        justify-content: flex-end;
        .anticon {
            vertical-align: 2px;
        }
    }
    @media (max-width: 576px) {
        padding: 0 0 50px;
        width: 100%;
        .pagination {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
`

const BannerImg = styled.div`
    width: 100%;
    @media (max-width: 576px) {
        width: 100%;
    }
`
const PageHeader = styled.div`
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    h1 {
        font-family: HelveticaNeue-Bold;
        font-size: 42px;
        color: #000;
        line-height: 50px;
        font-weight: 700;
        margin: 0;
    }
    /* .createBtn {
        width: 100px;
        height: 36px;
    } */
    @media (max-width: 576px) {
        margin: 20px 17px;
        h1 {
            font-size: 30px;
            line-height: 40px;
        }
    }
`

const EventList = styled.div`
    width: 100%;
    display: flex;
    /* justify-content: flex-start; */
    justify-content: space-between;
    flex-wrap: wrap;
    margin-right: -48px;
    .item {
        /* width: calc(33.33% - 48px); */
        width: 400px;
        margin: 0 0 40px 0;
        cursor: pointer;
        background: #fff;
        box-shadow: 0 10px 20px 0 rgba(204, 204, 204, 0.5);
        .img {
            background-position-y: 0 !important;
            width: 100%;
            height: 225px;
            padding-top: 56%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }
    .info {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 68px;
        .text {
            width: calc(100% - 40px);
            font-family: Roboto-Regular;
            font-size: 17px;
            color: #000;
            letter-spacing: 0;
            word-break: break-all;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
    @media (max-width: 768px) {
        margin-right: -24px;
        margin-bottom: 25px;
        .item {
            width: calc(50% - 24px);
            margin: 0 24px 25px 0;
        }
    }
    @media (max-width: 576px) {
        padding: 0 17px 30px;
        .item {
            width: 100%;
            margin: 0 0 30px 0;
        }
    }
`
