import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'

import { Image } from 'antd'
import { Paragraph, SectionContainer } from 'components'

import rental from './assets/rental.jpg'

export const Rental: FC = () => {
    const navigate = useNavigate()
    return (
        <PageWrapper>
            <div className="md:px-12 md:pt-12 pb-20 md:pb-32">
                <Image width={'100%'} src={rental} preview={false} />
            </div>
            <Container>
                <SectionContainer>
                    <div className="px-12 md:px-0 flex flex-wrap">
                        <div className="flex basis-full lg:basis-2/5">
                            <div className="md:pl-12 md:pr-20">
                                <Title className="text-center md:text-left text-8xl font-bold">
                                    MetaSill Rental
                                </Title>
                                <div className="pt-12 md:py-12">
                                    <Paragraph>
                                        Display your NFT or brand in events, galleries, and venues in NYC,
                                        Miami, Los Angeles, Las Vegas, and more through MetaSill. MetaSill
                                        participates in the largest Web3 conferences, events, and galleries in
                                        the United States. Share your NFTs with a larger crowd. Reach IRL
                                        people through the MetaSill Display Network. Display your collection
                                        in MetaSill partnered public locations such as cafés, hotels,
                                        restaurants, galleries, etc.
                                        <ParagraphWant>
                                            Want to display?{' '}
                                            <TextUnderline
                                                onClick={() => navigate('/', { state: { title: 'rental' } })}
                                            >
                                                Contact us
                                            </TextUnderline>{' '}
                                            for a quote.
                                        </ParagraphWant>
                                    </Paragraph>
                                </div>
                                <div className="contact" onClick={() => navigate(`/event`)}>
                                    Event
                                </div>
                            </div>
                        </div>
                        <div className="flex basis-full lg:basis-3/5 pt-16 md:pt-0 md:pr-12">
                            <div className="w-full md:pl-24">
                                <div>
                                    <SubTitle>Prices</SubTitle>
                                    {/* <div className="flex flex-wrap py-8">
                                    <div className="basis-full lg:basis-1/2">
                                        <Text>7-day 22-inch MetaSill Frame: $250</Text>
                                        <Text>7-day 33-inch MetaSill Frame: $300</Text>
                                    </div>
                                    <div className="basis-full lg:basis-1/2">
                                        <Text>30-day 22-inch MetaSill Frame: $600</Text>
                                        <Text>30-day 33-inch MetaSill Frame: $750</Text>
                                    </div>
                                </div> */}
                                    <div className="pb-8">
                                        <Text>MetaSill 33-inch Square NFT Frame: $500 / day</Text>
                                    </div>
                                    <br />
                                </div>
                                <div>
                                    <SubTitle>Deposits</SubTitle>
                                    <div className="py-8">
                                        <Text>
                                            We require a deposit of at least 50% of the retail prices of
                                            frames rented. The deposit is refundable.
                                        </Text>
                                    </div>
                                    <br />
                                </div>
                                {/* <div>
                                <SubTitle>Benefits</SubTitle>
                                <div className="py-8">
                                    <Text>
                                        Sell any MetaSill frame at your event and receive 10% back as a
                                        rebate.
                                        <br />
                                        Rebates will be paid 30 days after the 14 days no questions asked
                                        refund period.
                                    </Text>
                                </div>
                                <br />
                            </div> */}
                                <div>
                                    <SubTitle>Shipping</SubTitle>
                                    <div className="py-8">
                                        <Text>
                                            Except for some major cities in the United States, a shipping fee
                                            will be charged.
                                            <br />
                                            <TextUnderline
                                                onClick={() => navigate('/', { state: { title: 'rental' } })}
                                            >
                                                Contact us
                                            </TextUnderline>{' '}
                                            for more information.
                                            <br />
                                            *Shipping fees will be waived in the following areas:
                                            <br />
                                            Silicon Valley, Los Angeles, NYC, Miami
                                        </Text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SectionContainer>
            </Container>
        </PageWrapper>
    )
}

const PageWrapper = styled.div``

const TextUnderline = styled.span`
    text-decoration: underline;
`

const ParagraphWant = styled.div`
    margin-top: 24px;
`

const Container = styled.div`
    padding: 0 90px 100px;
    width: 100%;
    .contact {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 300px;
        height: 50px;
        background: #000000;
        border-radius: 35.18px;

        font-family: Roboto-Medium;
        font-size: 25.33px;
        color: #ffffff;
        line-height: 30.96px;
        cursor: pointer;
    }
    @media (max-width: 768px) {
        padding: 0 20px 30px;
        .contact {
            width: 100%;
        }
    }
`

const Text = styled.div`
    font-family: Roboto-Medium;
    font-size: 16px;
    color: #000;
    line-height: 28px;
`

const SubTitle = styled.div`
    font-family: HelveticaNeue-Bold;
    font-size: 30px;
    color: #000;
    line-height: 50px;
    font-weight: bold;
`

const Title = styled.div`
    font-family: HelveticaNeue-Bold;
    font-size: 42px;
    color: #000;
    line-height: 50px;
    font-weight: bold;
`
