import { action, payload } from 'ts-action'
import { getActionType } from '../constants'

import { Delivery } from 'core/delivery/models'

export const setList = action(getActionType('Set List'), payload<{ list: Delivery[] }>())

export const setBillingList = action(getActionType('Set Billing List'), payload<{ list: Delivery[] }>())

export const setCurrent = action(getActionType('Set Current'), payload<{ id: string }>())

export const setBillingId = action(getActionType('Set BillingId'), payload<{ billingId: string }>())

export const clearDeliveryIdAndBillingId = action(getActionType('Clear DeliveryId And BillingId'))

export const setUnpaid = action(
    getActionType('Set Unpaid'),
    payload<{ billDeliveryDetail: Delivery; deliveryDetail: Delivery }>()
)
