import { useState } from 'react'

import { useMemoizedFn, useMountedPromiseFn } from 'shared/hooks'
import { services } from 'core/homepage/data-access'
import { ContactUsFormData } from 'core/homepage/models'

export const useContactUs = () => {
    const [loading, setLoading] = useState<boolean>(false)

    const contactUsApi = useMountedPromiseFn(services.contactUs)
    const contactUs = useMemoizedFn((formData: ContactUsFormData) => {
        setLoading(true)
        return contactUsApi(formData)
            .then(() => {
                setLoading(false)
                return true
            })
            .catch(err => {
                setLoading(false)
                return Promise.reject(err)
            })
    })

    return {
        loading,
        methods: { contactUs }
    }
}
