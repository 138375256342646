export enum DeliveryTypeEnum {
    DELIVERY,
    BILLING
}

export interface BaseDelivery {
    firstName: string
    lastName: string
    companyName: string
    streetAddress: string
    apartment: string
    city: string
    state: string
    zipCode: string
    phone: string
    isDefault: boolean
    email?: string
    type?: DeliveryTypeEnum
}

export type DeliveryFormData = BaseDelivery

export interface Delivery extends BaseDelivery {
    id: string
    isDefault: boolean
}

export interface DeliverDefault {
    isDefault: boolean
}

export const StateList =
    'Alabama|Arizona|Arkansas|California|Colorado|Connecticut|Delaware|Florida|Georgia|Idaho|Illinois|Indiana|Iowa|Kansas|Kentucky|Louisiana|Maine|Maryland|Massachusetts|Michigan|Minnesota|Mississippi|Missouri|Montana|Nebraska|Nevada|New Hampshire|New Jersey|New Mexico|New York|North Carolina|North Dakota|Ohio|Oklahoma|Oregon|Pennsylvania|Rhode Island|South Carolina|South Dakota|Tennessee|Texas|Utah|Vermont|Virginia|Washington|West Virginia|Wisconsin|Wyoming'.split(
        '|'
    )
