import { useCallback, useRef } from 'react'
import { useMountedState } from 'react-use'

import { useRefValue } from './useRefValue'

export function useMountedPromiseFn<Result, Args extends any[]>(
    fn: (...args: Args) => Promise<Result>,
    successCallback?: (result: Result) => void,
    errorCallback?: (error: any) => void
): (...args: Args) => Promise<Result> {
    const isMounted = useMountedState()

    const timeIdRef = useRef<number>() // Record the request time and deal with the competition problem of multiple calls to the same interface

    const successCallbackRef = useRefValue(successCallback)
    const errorCallbackRef = useRefValue(errorCallback)

    return useCallback(
        (...args: Args) =>
            new Promise((resolve, reject) => {
                const timeId = Date.now()
                timeIdRef.current = timeId
                fn(...args).then(
                    value => {
                        if (timeId === timeIdRef.current) {
                            successCallbackRef.current && successCallbackRef.current(value)
                            isMounted() && resolve(value)
                        }
                    },
                    error => {
                        if (timeId === timeIdRef.current) {
                            errorCallbackRef.current && errorCallbackRef.current(error)
                            isMounted() && reject(error)
                        }
                    }
                )
            }),
        [fn, successCallbackRef, isMounted, errorCallbackRef]
    )
}

export function useMountedPromiseFnWithoutRace<Result, Args extends any[]>(
    fn: (...args: Args) => Promise<Result>,
    successCallback?: (result: Result) => void,
    errorCallback?: (error: any) => void
): (...args: Args) => Promise<Result> {
    const isMounted = useMountedState()

    const successCallbackRef = useRefValue(successCallback)
    const errorCallbackRef = useRefValue(errorCallback)

    return useCallback(
        (...args: Args) =>
            new Promise((resolve, reject) => {
                // const timeId = Date.now()
                fn(...args).then(
                    value => {
                        successCallbackRef.current && successCallbackRef.current(value)
                        isMounted() && resolve(value)
                    },
                    error => {
                        errorCallbackRef.current && errorCallbackRef.current(error)
                        isMounted() && reject(error)
                    }
                )
            }),
        [fn, successCallbackRef, isMounted, errorCallbackRef]
    )
}
