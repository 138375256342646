import React, { FC, ReactNode, useState } from 'react'
import styles from './index.module.css'

import { OrderItem as OrderItemModels, OrderStatusEnum } from 'core/order/models'
import { useI18n } from 'core/language/api'

import { ShopTitle } from '../ShopTitle'
import { ProductItem, ProductItemRent } from '../ProductItem'

interface OrderItemProps {
    virtual: boolean
    type: number
    items: OrderItemModels[]
    name: string
    icon: string
    status?: OrderStatusEnum
    total?: string
    footer?: ReactNode
    className?: string
    onClick?: () => void
}

export const OrderItem: FC<OrderItemProps> = ({
    virtual,
    type,
    items,
    total,
    name,
    icon,
    footer,
    status,
    className,
    onClick
}) => {
    const {
        methods: { t }
    } = useI18n()

    const [showScrollBar, setShowScrollBar] = useState<boolean>(false)
    return (
        <div
            className={`cursor-pointer ${styles.shopInfo} ${className}`}
            onMouseEnter={() => {
                setShowScrollBar(true)
            }}
            onMouseLeave={() => {
                setShowScrollBar(false)
            }}
        >
            <div onClick={onClick}>
                <ShopTitle
                    iconSrc={icon}
                    name={name}
                    right={
                        status && <div className={styles.shopState}>{t(`order.statusTitle.${status}`)}</div>
                    }
                />
                <div
                    className={`${styles.productList} ${styles['productListCount' + items.length]} ${
                        showScrollBar ? styles.scrollBar : ''
                    }`}
                >
                    {items.map(
                        (
                            {
                                originUnitPrice,
                                quantity,
                                snapshot,
                                depositTotalPrice,
                                depositUnitPrice,
                                timeZone,
                                duration,
                                durations
                            },
                            idx
                        ) =>
                            type === 1 ? (
                                <ProductItemRent
                                    virtual={virtual}
                                    key={idx}
                                    originUnitPrice={originUnitPrice}
                                    quantity={quantity}
                                    depositTotalPrice={depositTotalPrice}
                                    depositUnitPrice={depositUnitPrice}
                                    timeZone={timeZone}
                                    duration={duration}
                                    durations={durations}
                                    snapshot={snapshot}
                                />
                            ) : (
                                <ProductItem
                                    key={idx}
                                    originUnitPrice={originUnitPrice}
                                    quantity={quantity}
                                    snapshot={snapshot}
                                />
                            )
                    )}
                </div>

                {total && (
                    <div className={styles.itemPriceInfo}>
                        <div className={styles.itemPriceKey}>{t('client.order.totalPrice')}</div>
                        <div className={styles.itemPriceValue}>
                            <span className={styles.moneyUnit}>$</span>
                            {total}
                        </div>
                    </div>
                )}
            </div>
            {footer}
        </div>
    )
}
