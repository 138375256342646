import {
    convertToImageUrl,
    getRootUrlPrefix,
    convertToDollar,
    convertToDurations,
    convertTimestampToDateWithTz
} from 'shared/utils'
import {
    convertToAttrMap,
    Spec,
    Product,
    ProductStatusEnum,
    ProductCategoryList,
    ProductInList,
    SkuListItem
} from 'core/product/models'

enum ProductRefrencesTypeEnum {
    PRODUCT_CATEGORY = 'product_category',
    PRODUCT_ATTR = 'product_attr',
    STORE = 'store',
    EVENT = 'event'
}

export interface StoreReference {
    id: string
    name: string
    icon: string
    type: string
}

export interface CategoryReference {
    id: string
    parentId: string
    description: string
    displayOrder: number
    icon: string
    keywords: string
    name: string
    type: ProductRefrencesTypeEnum.PRODUCT_CATEGORY
}

export interface ProductInListDTO {
    id: string
    categoryId: string
    canDirectBuy: boolean
    images: string[]
    description: string
    mobileDescription: string
    name: string
    salesVolume: number
    showPrice: string[]
    skuList: SkuListItem[]
}

export const convertToProductInList = async (
    dtos: ProductInListDTO[],
    references: CategoryReference[]
): Promise<ProductCategoryList[]> => {
    const prefix = await getRootUrlPrefix()
    return references.map(({ name, id }) => {
        return {
            id,
            name,
            products: dtos
                .filter(({ categoryId }) => id === categoryId)
                .map(dto => {
                    const {
                        id,
                        // categoryId,
                        canDirectBuy,
                        images,
                        mobileDescription,
                        description,
                        name,
                        // salesVolume,
                        showPrice,
                        skuList
                    } = dto

                    return {
                        id,
                        name,
                        canDirectBuy,
                        image: convertToImageUrl(prefix, images[0]),
                        showPrice: convertToDollar(showPrice[0]),
                        description,
                        mobileDescription,
                        skuList
                    }
                })
        }
    })
}

export interface ProductAttrReference {
    id: string
    name: string
    type: ProductRefrencesTypeEnum.PRODUCT_ATTR
    values: { id: string; name: string }[]
}

export interface EventReference {
    type: ProductRefrencesTypeEnum.EVENT
    duration: string
    timeZone: string
}

export interface SkuItem {
    id: string
    price: string
    depositPrice: string
    salesVolume: number
    spec: Spec[]
    stock: number
    warning: boolean
    images: string[]
}

export interface ProductDTO {
    id: string
    storeId: string
    categoryId: string
    images: string[]
    isMultiSku: boolean
    isDeliveryFree?: boolean
    mobileDescription: string
    description: string
    name: string
    // parameters: [];
    stock?: number
    warning?: boolean
    salesVolume: number
    showPrice: string[]
    depositPrice: string
    status: ProductStatusEnum
    skuList?: SkuItem[]
    virtual: boolean
    purchaseRestrictions: boolean
    purchaseRestrictionsCount: number
    remainingQ: number
    type: number
    onceLimit: number
    preOrder: boolean
    descriptionType?: number
}

export const convertToProduct = async (
    dto: ProductDTO,
    references: (CategoryReference | ProductAttrReference | StoreReference | EventReference)[]
): Promise<Product> => {
    const prefix = await getRootUrlPrefix()
    const {
        id,
        // storeId,
        categoryId,
        images,
        isMultiSku,
        mobileDescription,
        description,
        name,
        // parameters,
        stock,
        warning,
        salesVolume,
        showPrice,
        depositPrice,
        status,
        skuList,
        virtual,
        purchaseRestrictions,
        purchaseRestrictionsCount,
        remainingQ,
        isDeliveryFree,
        type,
        onceLimit,
        preOrder,
        descriptionType
    } = dto

    let props
    if (dto.isMultiSku) {
        const attrMap = convertToAttrMap(skuList!)
        const attrList = (
            references.filter(
                item => item.type === ProductRefrencesTypeEnum.PRODUCT_ATTR && attrMap[item.id]
            ) as ProductAttrReference[]
        ).map(attr => ({
            ...attr,
            values: attr.values.filter(value => attrMap[attr.id][value.id])
        }))
        const productAttrObj = attrList.reduce((acc, { id, values }) => {
            return { ...acc, [id]: values.reduce((valObj, { id, name }) => ({ ...valObj, [id]: name }), {}) }
        }, {} as any)
        props = {
            isDeliveryFree,
            stock: null,
            warning: undefined,
            skuList: skuList
                ? skuList?.map(sku => ({
                      ...sku,
                      specText: sku.spec
                          .map(({ attrId, valueId }) => productAttrObj[attrId][valueId])
                          .join('/'),
                      specObj: sku.spec.reduce(
                          (acc, { attrId, valueId }) => ({ ...acc, [attrId]: valueId }),
                          {}
                      ),
                      images: sku.images.map(img => convertToImageUrl(prefix, img)),
                      price: convertToDollar(sku.price),
                      depositPrice: convertToDollar(sku.depositPrice)
                  }))
                : null,
            attrList
        }
    } else {
        props = {
            stock,
            warning,
            skuList: null,
            attrList: null
        }
    }

    const storeRef = references.find(
        item => item.type === ProductRefrencesTypeEnum.STORE && item.id === dto.storeId
    ) as StoreReference

    const eventRef = references.find(item => item.type === ProductRefrencesTypeEnum.EVENT) as EventReference

    return {
        id,
        name,
        store: { ...storeRef, icon: convertToImageUrl(prefix, storeRef.icon) },
        category: references.find(
            item => item.type === ProductRefrencesTypeEnum.PRODUCT_CATEGORY && item.id === categoryId
        ),
        showPrice: showPrice[0],
        depositPrice,
        images: images.map(img => convertToImageUrl(prefix, img)),
        isMultiSku,
        mobileDescription,
        description,
        salesVolume,
        status,
        virtual,
        purchaseRestrictions,
        purchaseRestrictionsCount,
        remainingQ,
        type,
        onceLimit,
        preOrder,
        descriptionType,
        ...props,
        timeZone: eventRef?.timeZone,
        durations:
            eventRef?.timeZone && eventRef?.duration
                ? convertToDurations(eventRef?.duration).map(item => ({
                      label: item,
                      value: convertTimestampToDateWithTz(item, eventRef?.timeZone)
                  }))
                : []
    } as any
}

export const convertToEventProduct = (dto: ProductInListDTO, prefix: string): ProductInList => ({
    ...dto,
    image: convertToImageUrl(prefix, dto.images[0]),
    showPrice: dto.showPrice[0]
})
