import { useCallback } from 'react'
import { useI18n } from 'core/language/api'
import { useMountedState } from 'react-use'
import { useRefValue } from './useRefValue'
import { useShowMessage } from './useShowMessage'

const tError = (
    translateScope: string,
    method: (k: string, data: Record<string, string>) => string,
    err: any
): any => {
    const responseErrorMessage = err?.data?.msg || err?.data?.errors?.message || err
    const responseErrorData = err?.data?.data || err?.data?.errors?.data || {}

    if (responseErrorMessage) {
        // Use translator with scope
        const translateKey = translateScope
            ? `${translateScope}.${responseErrorMessage}`
            : responseErrorMessage
        const msg = method(translateKey, responseErrorData)
        if (msg === translateKey && translateScope) {
            // If it is not translated and is a multi-level scope, scope down one level and continue to translate
            const scopeArr = translateScope.split('.')
            const newScope = scopeArr.slice(0, -1).join('.')
            return tError(newScope, method, err)
        }
        return msg
    }
}

export type ErrorNotify = {
    title: string
    message?: string
    okText?: string
    onOk?: () => void
}
export type ErrorNotifyTypes = (options: ErrorNotify | string) => void

export const useApiError = (prefix: string, errorNotify?: (message: string) => void, translateScope = '') => {
    const mounted = useMountedState()

    const {
        methods: { t }
    } = useI18n()
    const {
        methods: { error }
    } = useShowMessage()
    const tRef = useRefValue(t)
    const translateError = useCallback(
        (err: any) => {
            return tError(translateScope, t, err)
        },
        [t, translateScope]
    )
    // const getName = useCallback((fn: any) => {
    //     return typeof fn === 'function' ? fn.name || fn.toString().match(/function\s*([^(]*)\(/)[1] : ''
    // }, [])

    const handleError = useCallback(
        (err: any, onOkCallback?: () => void) => {
            const title = tError(translateScope, tRef.current, err)
            if (title) {
                setTimeout(() => {
                    // if (getName(errorNotify) === 'useModalError') {
                    //     mounted() &&
                    //         errorNotify({
                    //             title,
                    //             message: '',
                    //             okText: t('common.form.actions.ok'),
                    //             onOk() {
                    //                 typeof onOkCallback === 'function' && onOkCallback()
                    //             }
                    //         })
                    // } else {
                    if (!mounted()) return
                    errorNotify ? errorNotify(title) : error(title)
                    // }
                }, 300)
            } else {
                console.error(`[${prefix}] ${err}`)
            }
        },
        [error, errorNotify, mounted, prefix, tRef, translateScope]
    )

    return {
        methods: {
            translateError,
            handleError
        }
    }
}
