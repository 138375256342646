import { useContext } from 'react'

import { LoginModalContext } from 'shared/context'

export const useLoginModal = () => {
    const loginModalContext = useContext(LoginModalContext)

    if (!loginModalContext) {
        throw new Error('the hook useLoginModal must be used in LoginModalProvider.')
    }

    return loginModalContext
}
