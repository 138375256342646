import React, { FC, PropsWithChildren } from 'react'
import styled from '@emotion/styled'

interface Props {
    className?: string
    isActive?: boolean
}

export const InputWrapper: FC<PropsWithChildren<Props>> = ({ className, isActive = false, children }) => {
    return (
        <Wrapper className={`wrapper flex items-center justify-center ${className} ${isActive && 'active'}`}>
            {children}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    box-sizing: content-box;
    width: calc(100% - 2px);
    height: 50px;
    position: relative;
    border: 1px solid #c4c4d3;
    border-radius: 12px;
    margin-bottom: 14px;
    &.active {
        width: calc(100% - 4px);
        height: calc(50px - 2px);
        border: 2px solid #2f2e2e;
        box-shadow: 0 2px 4px 0 rgba(85, 108, 185, 0.5);
    }
`
