import React, { FC, ReactNode } from 'react'
import styles from './index.module.css'

interface ShopTitleProps {
    iconSrc?: string
    name: string
    right?: ReactNode
}

export const ShopTitle: FC<ShopTitleProps> = ({ right }) => {
    return (
        <div className={styles.shop}>
            <div />
            {right && <div className="flex items-center">{right}</div>}
        </div>
    )
}
