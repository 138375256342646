import { useCallback } from 'react'

import { useMountedPromiseFn, useListState } from 'shared/hooks'
import { services } from 'core/event/data-access'
import { ListParams } from 'core/aff/models'
import { EventItem } from '../../../models'

export const useEventList = () => {
    const [state, setState] = useListState<EventItem>()

    const getListApi = useMountedPromiseFn(services.getList)
    const getList = useCallback(
        (params: ListParams) => {
            setState({ loading: true })
            return getListApi(params)
                .then(({ list, total }) => {
                    setState({ list, total, loading: false })
                    return
                })
                .catch(err => {
                    setState({ loading: false })
                    return Promise.reject(err)
                })
        },
        [getListApi, setState]
    )

    return {
        ...state,
        methods: {
            getList
        }
    }
}
