import React, { FC, useCallback, useState } from 'react'
import styled from '@emotion/styled'

import { Modal, Input } from 'antd'
import { Button } from 'components'
import { useApiError, useResponsive } from 'shared/hooks'
import { QrcodeScanner } from './components/QrcodeScanner'
import group from './assets/group.png'
import { ConnectFrame, FramesList } from 'core/management/frame/model'

import closeImg from 'assets/closeImg.png'
import { useFrameDetail } from 'core/management/frame/api/useFrameDetail'

interface FrameCreateModalProps {
    open: boolean
    setOpen: (value: boolean) => void
    getList: () => Promise<FramesList[]>
    getCreate: (params: ConnectFrame) => Promise<any>
}
export const FrameCreateModal: FC<FrameCreateModalProps> = ({ open, setOpen, getList, getCreate }) => {
    const { md } = useResponsive()
    const [device_id, setDevice_id] = useState<string>('')
    const [btnDisabled, setBtnDisabled] = useState<boolean>(true)
    const [device_name, setDevice_name] = useState<string>('')
    const [doneDisabled, setDoneDisabled] = useState<boolean>(true)
    const [frameInfoOpen, setFrameInfoOpen] = useState<boolean>(false)
    const {
        methods: { handleError }
    } = useApiError('FrameCreateModal')

    const {
        methods: { getDetail }
    } = useFrameDetail(device_id)

    const handleSubmit = useCallback(() => {
        const params = { device_id, device_name }

        getCreate(params)
            .then(() => {
                getList()
                setFrameInfoOpen(false)
                setDevice_name('')
                setDevice_id('')
                setBtnDisabled(true)
                setDoneDisabled(true)
            })
            .catch(err => {
                if (err.data.msg === 'Cannot bind device which is in rental mode') {
                    handleError('Cannot bind device which is in rental mode')
                } else if (
                    err.data.msg === 'Bind device failed, Wifi connection of the frame might be interrupted'
                ) {
                    handleError('Bind device failed, Wifi connection of the frame might be interrupted')
                } else if (err.data.msg === 'Device not found by ID') {
                    handleError('Device not found by ID')
                } else if (
                    err.data.msg === "DataError('value too long for type character varying(255)\\n',)"
                ) {
                    handleError('Maximum length of Frame Name is 255.')
                } else {
                    handleError('Bind Frame failed.')
                }
                setFrameInfoOpen(false)
                setDevice_name('')
                setDevice_id('')
                setBtnDisabled(true)
                setDoneDisabled(true)
            })
    }, [device_id, device_name, getCreate, getList, handleError])

    return (
        <>
            {open && (
                <Modal
                    open={open}
                    onCancel={() => {
                        setOpen(false)
                        setDevice_id('')
                        setBtnDisabled(true)
                    }}
                    footer={false}
                    style={{ maxWidth: '100%', margin: md ? '0 auto' : 0, top: md ? 100 : 56 }}
                    wrapClassName="add-wallet-modal"
                    closeIcon={
                        <img
                            style={md ? { marginTop: 24 } : { marginTop: 18 }}
                            width={24}
                            height={24}
                            src={closeImg}
                            onClick={() => setOpen(false)}
                            alt="closeImg"
                        />
                    }
                >
                    <FrameModal>
                        <Title>Connect Frames</Title>
                        {!md && (
                            <>
                                <CreateFrameText style={{ textAlign: 'center' }}>
                                    Scan Metasill Frame QR Code
                                </CreateFrameText>
                                <QrCodeDiv>
                                    <QrCode>
                                        <QrcodeScanner
                                            fps={50}
                                            qrbox={294}
                                            disableFlip={true}
                                            aspectRatio={1.0}
                                            onNewScanResult={(decodedText: string) => {
                                                if (
                                                    ['ustest.yanglee.top', 'localhost', '127.0.0.1'].includes(
                                                        window.location.hostname
                                                    )
                                                ) {
                                                    alert(decodedText)
                                                }
                                                setDevice_id(decodedText)
                                                setOpen(false)
                                                setFrameInfoOpen(true)
                                            }}
                                        />
                                    </QrCode>
                                </QrCodeDiv>
                                <QrTip>Can't find QR code?</QrTip>
                            </>
                        )}
                        <Label>Enter Metasill Frame Serial Number</Label>
                        <FrameInput
                            value={device_id}
                            onChange={({ target: { value } }) => {
                                if (value) {
                                    setBtnDisabled(false)
                                } else {
                                    setBtnDisabled(true)
                                }
                                setDevice_id(value)
                            }}
                            placeholder="xxxx-xxxx-xxxxxxxx"
                        />
                        <BingFrame
                            disabled={btnDisabled}
                            onClick={() => {
                                getDetail()
                                    .then(() => {
                                        setOpen(false)
                                        setFrameInfoOpen(true)
                                    })
                                    .catch(() => {
                                        handleError('Wrong device ID. Please try again.')
                                        setOpen(false)
                                        setDevice_id('')
                                        setBtnDisabled(true)
                                        setDoneDisabled(true)
                                    })
                            }}
                        >
                            Bind this Frame
                        </BingFrame>
                    </FrameModal>
                </Modal>
            )}
            {frameInfoOpen && (
                <Modal
                    open={frameInfoOpen}
                    onCancel={() => {
                        setFrameInfoOpen(false)
                        setDevice_id('')
                        setBtnDisabled(true)
                        setDevice_name('')
                        setDoneDisabled(true)
                    }}
                    footer={false}
                    style={{ maxWidth: '100%', margin: md ? '0 auto' : 0, top: md ? 100 : 56 }}
                    wrapClassName="add-wallet-modal"
                    closeIcon={
                        <img
                            style={md ? { marginTop: 24 } : { marginTop: 18 }}
                            width={24}
                            height={24}
                            src={closeImg}
                            onClick={() => setOpen(false)}
                            alt="closeImg"
                        />
                    }
                >
                    <FrameModal>
                        <Title>Frame Info</Title>
                        {!md && (
                            <>
                                <QrCodeDiv>
                                    <BottomImg src={group} alt="group" />
                                </QrCodeDiv>
                                <FrameTextTips>You almost set!</FrameTextTips>
                            </>
                        )}
                        <FrameInfoLabel>Frame Name</FrameInfoLabel>
                        <FrameInput
                            value={device_name}
                            onChange={({ target: { value } }) => {
                                if (value) {
                                    setDoneDisabled(false)
                                } else {
                                    setDoneDisabled(true)
                                }
                                setDevice_name(value)
                            }}
                            placeholder="Frame 1"
                        />
                        <BingFrame disabled={doneDisabled} onClick={handleSubmit}>
                            Done
                        </BingFrame>
                    </FrameModal>
                </Modal>
            )}
        </>
    )
}
const FrameInput = styled(Input)`
    height: 40px;
`
const CreateFrameText = styled.div`
    font-family: OpenSans-SemiBold;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    color: #838383;
    letter-spacing: 0;
`
const QrCodeDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`
const QrCode = styled.div`
    width: 294px;
    min-height: 294px;
    border: 1px solid #cccccc;
    margin: 24px 0 24px 0;
`
const QrTip = styled.div`
    font-family: Roboto-Regular;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    line-height: 26px;
    font-weight: 400;
`
const BottomImg = styled.img`
    width: 295px;
    height: 295px;
`
const FrameTextTips = styled.div`
    width: 100%;
    text-align: center;
    font-family: OpenSans-Bold;
    font-size: 18px;
    line-height: 18px;
    font-weight: 400;
    color: #000000;
    letter-spacing: 0;
    margin: 40px 0;
`
const Label = styled.div`
    font-family: Roboto-Regular;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    line-height: 26px;
    font-weight: 400;
    margin: 50px 0 9px;
    @media (max-width: 768px) {
        margin: 0 0 9px;
    }
`
const FrameInfoLabel = styled.div`
    font-family: Roboto-Regular;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    line-height: 26px;
    font-weight: 400;
    margin-bottom: 9px;
`
const Title = styled.div`
    font-family: Roboto-Medium;
    font-size: 30px;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    line-height: 28px;
    font-weight: 500;
    margin: 0 auto;
    @media (max-width: 768px) {
        font-size: 24px;
        margin: 0 auto 20px;
    }
`

const FrameModal = styled.div`
    padding: 20px 36px 36px;
    @media (max-width: 768px) {
        padding: 20px 16px 16px;
    }
`
const BingFrame = styled(Button)`
    margin: 50px auto 0;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: #000000;
    border-radius: 35px;
    font-family: Roboto-Medium;
    font-size: 24px !important;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    @media (max-width: 576px) {
        font-family: OpenSans-SemiBold;
        width: 222px;
        height: 50px;
        font-size: 16px !important;
        margin: 30px auto 97px;
    }
`
