import { apiHttp, clientHttp, ItemResponse, pickValue, sellerHttp, SuccessItemResponse } from 'shared/request'
import { ListResponse, SuccessResponse } from 'shared/request'

import { convertToEventDetail, convertToEventList, EventDetailDTO, EventItemDTO } from '../DTO'
import { getRootUrlPrefix, pathJoin } from 'shared/utils'

import { EventBillingAddress, EventForm, EventPersonalInfo, ListParams } from 'core/event/models'
import { CheckoutRequestItem } from 'core/checkout/models'
import { Language } from 'core/language/models'
import { getPaymentClientSecret } from 'shared/config'

const URI = 'event'

export const getList = (params: ListParams) => {
    const url = pathJoin(URI)
    return apiHttp.get<SuccessResponse<ListResponse<EventItemDTO>>>(url, params).then(async res => {
        const prefix = await getRootUrlPrefix()
        const data = res.data.data
        return {
            list: convertToEventList(prefix, data.value),
            total: data.total
        }
    })
}

export const getDetail = (lang: string, id: string) => {
    const url = pathJoin(URI, id)
    return apiHttp
        .get<SuccessResponse<ItemResponse<EventDetailDTO>>>(url, { language: lang })
        .then(pickValue)
        .then(detail => convertToEventDetail(detail))
}

export const setCreate = (postData: EventForm) => {
    const url = pathJoin(URI)
    return sellerHttp.post<SuccessResponse<ItemResponse<void>>>(url, postData).then(pickValue)
}

export const setEdit = (id: string, postData: EventForm) => {
    const url = pathJoin(URI, id)
    return sellerHttp.put<SuccessResponse<ItemResponse<void>>>(url, postData).then(pickValue)
}

export const deleteEvent = (id: string) => {
    const url = pathJoin(URI, id)
    return sellerHttp.delete<SuccessResponse<ItemResponse<void>>>(url).then(pickValue)
}

export const createOrder = (
    lang: Language,
    items: CheckoutRequestItem[],
    personalInfo: EventPersonalInfo,
    billInfo: EventBillingAddress,
    couponCode?: string
) => {
    return clientHttp
        .post<SuccessItemResponse<string[]>>(`/buy/rent/order?language=${lang}`, {
            lang,
            items,
            personalInfo,
            billInfo,
            couponCode,
            clientSecret: getPaymentClientSecret()
        })
        .then(pickValue)
}
