import React, { FC, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'

import { useApiError, useRememberManagementSelected, useResponsive } from 'shared/hooks'
import { useWalletAddress } from 'core/walletaddress/api'
import { useOwnedNew } from 'core/management/nft/api/useOwnedNew'

import { removeAddressToken } from 'shared/config'

import { Spin } from 'components'
import { ConnectWallet } from './ConnectWallet'
import { CollectionListNew } from './CollectionListNew'
import { UploadNoContent } from './StyledComponents'
import { CreateWalletModal } from 'modules/user/components/CreateWalletModal'
import { SelectAddressModal } from 'modules/management/frame/components/SelectAddressModal'
import { SelectAdressPopup } from '../../frame/components/SelectAdressPopup'

import { AuthAddressItem, NftTypeEnum } from 'core/management/nft/models'
import { useAuthAddressList } from 'core/management/nft/api/useAddressList'

import no_content1 from '../assets/no_content1.png'
import no_content3 from '../assets/no_content3.png'
import pullDown from 'assets/pull_down.png'
import { useAddressToken } from 'core/management/nft/api/useAddressToken'

const ownedPageKey = 'owned_wallet_address'
const uploadPageKey = 'upload_wallet_address'
const authorizedPageKey = 'authorized_wallet_address'

export const Owned: FC = () => {
    const { md } = useResponsive()
    const {
        methods: { handleError }
    } = useApiError('Owned')

    const [visible, setVisible] = useState(false)
    const [walletAddress, setWalletAddress] = useState<AuthAddressItem>()
    const [walletAddressInput, setWalletAddressInput] = useState('')
    const [bindModalOpen, setBindModalOpen] = useState<boolean>(false)
    const [isClick, setIsClick] = useState(false)

    const {
        methods: { getWalletAddress, bind }
    } = useWalletAddress()

    const {
        loading,
        list: authAddressList,
        methods: { getAddressList }
    } = useAuthAddressList()

    const {
        loading: ownedLoading,
        hasMoreLoading: ownedHasMoreLoading,
        ownedList,
        hasMore,
        total,
        methods: { firstFetch, loadMore }
    } = useOwnedNew(walletAddress?.address!, 'owned')

    useLayoutEffect(() => {
        getWalletAddress().catch(handleError)
        getAddressList().catch(handleError)
    }, [getAddressList, getWalletAddress, handleError, bindModalOpen])

    useRememberManagementSelected(uploadPageKey, walletAddress?.id, isClick)
    useRememberManagementSelected(authorizedPageKey, walletAddress?.id, isClick)
    const selectedId = useRememberManagementSelected(ownedPageKey, walletAddress?.id, isClick)

    const mapAuthAddressList = useMemo(() => {
        const data = authAddressList.filter(item => item.addressType !== 0 && item.addressType !== 3)
        return data
    }, [authAddressList])

    useEffect(() => {
        const newList = mapAuthAddressList.filter(({ addressType }) => addressType !== 0)
        !!mapAuthAddressList.length &&
            setWalletAddress(() => {
                const lastAddress = mapAuthAddressList.find(({ id }) => selectedId === `${id}`)
                console.log(lastAddress, '1323221')

                if (lastAddress && lastAddress.addressType !== 0) return lastAddress
                return newList[0]
            })
    }, [mapAuthAddressList, selectedId])

    const {
        methods: { getAddressToken }
    } = useAddressToken()

    useEffect(() => {
        if (!walletAddress?.id) return
        getAddressToken({
            id: `${walletAddress.id}`,
            addressType: `${walletAddress.addressType}`,
            ownerId: `${walletAddress.ownerId}`
        })
            .then(() => {
                firstFetch()
                    .then(() => {
                        removeAddressToken()
                    })
                    .catch(handleError)
            })
            .catch(handleError)
    }, [firstFetch, getAddressToken, handleError, walletAddress])

    return (
        <Spin spinning={loading}>
            {mapAuthAddressList.length > 0 ? (
                <Spin spinning={ownedLoading && !ownedHasMoreLoading}>
                    {md && (
                        <WebSelectAddress>
                            <SelectAdress>
                                <span className="box" onClick={() => setVisible(true)}>
                                    <span className="name">{walletAddress?.name}</span>
                                    <img width={16} height={16} src={pullDown} alt="NFT" />
                                </span>
                            </SelectAdress>
                        </WebSelectAddress>
                    )}
                    {!md && (
                        <SelectAdress>
                            <span className="box" onClick={() => setVisible(true)}>
                                <span className="name">{walletAddress?.name}</span>
                                <img width={16} height={16} src={pullDown} alt="NFT" />
                            </span>
                        </SelectAdress>
                    )}

                    {authAddressList && (
                        <TotalLength>
                            <div className="name">Total</div>
                            <span>{total}</span>
                        </TotalLength>
                    )}
                    {ownedList.length > 0 ? (
                        <>
                            {/* <PageHeader
                                title={
                                    <Total>
                                        Total
                                        <span>{total}</span>
                                    </Total>
                                }
                                className="header"
                                border={false}
                            /> */}

                            <CollectionListNew loadMore={loadMore} hasMore={hasMore} list={ownedList} />
                        </>
                    ) : (
                        <UploadNoContent>
                            <img src={md ? no_content1 : no_content3} alt="no_content" />
                        </UploadNoContent>
                    )}
                </Spin>
            ) : (
                <div className="flex justify-center">
                    <CreateWalletContent>
                        <ConnectWallet
                            onConnectSuccess={(value: string) => {
                                setWalletAddressInput(value)
                                setBindModalOpen(true)
                            }}
                        />
                    </CreateWalletContent>
                    <CreateWalletModal
                        walletAddress={walletAddressInput}
                        open={bindModalOpen}
                        setOpen={setBindModalOpen}
                        onBind={bind}
                    />
                    {/* <ConnectWallet setOpen={setOpen} /> */}
                    {/* <EnterWalletAddressModal
                        open={open}
                        setOpen={setOpen}
                        onEnterConnectWallet={onEnterConnectWallet}
                    /> */}
                </div>
            )}
            {walletAddress && md && (
                <SelectAddressModal
                    setWalletAddress={setWalletAddress}
                    walletAddress={walletAddress}
                    list={mapAuthAddressList}
                    type={NftTypeEnum.OWNED}
                    visible={visible}
                    setVisible={setVisible}
                    setIsClick={setIsClick}
                />
            )}
            {walletAddress && !md && (
                <SelectAdressPopup
                    setWalletAddress={setWalletAddress}
                    walletAddress={walletAddress}
                    list={mapAuthAddressList}
                    type={NftTypeEnum.OWNED}
                    visible={visible}
                    setVisible={setVisible}
                    setIsClick={setIsClick}
                />
            )}
        </Spin>
    )
}

const WebSelectAddress = styled.div`
    position: absolute;
    right: 0;
    top: -74px;
`

const CreateWalletContent = styled.div`
    padding-top: 10px;
    width: 362px;
    @media (max-width: 768px) {
        width: 325px;
        padding-top: 20px;
    }
`

const SelectAdress = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    /* margin-top: 24px; */
    /* padding-bottom: 20px; */
    /* border-bottom: 1px solid rgba(151, 151, 151, 1); */
    .box {
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f9f9f9;
        border: 1px solid rgba(211, 211, 211, 1);
        border-radius: 8px;
        padding: 6px 12px;
        @media (max-width: 768px) {
            height: 36px;
        }
    }

    .name {
        font-family: OpenSans-Bold;
        font-size: 18px;
        color: #000000;
        font-weight: 700;
        margin-right: 6px;
    }
`

const TotalLength = styled.div`
    display: flex;
    font-family: OpenSans-Regular;
    font-size: 16px;
    align-items: center;
    color: #acacac;
    margin-right: 6px;
    margin-top: 20px;
    .name {
        font-family: OpenSans-Bold;
        font-size: 18px;
        color: #000000;
        font-weight: 700;
        margin-right: 10px;
    }
`
