import React, { FC, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { Carousel, CarouselProps } from '@mantine/carousel'

import { pathJoin } from 'shared/utils'
import { PROJECT_PREFIX } from 'shared/config'

import left_active from '../assets/left_active.png'
import left_default from '../assets/left_default.png'
import right_active from '../assets/right_active.png'
import right_default from '../assets/right_default.png'
import { useMemoizedFn, useResponsive } from 'shared/hooks'

interface CarouselPageProps {
    list: string[]
}

export const CarouselPage: FC<CarouselPageProps> = ({ list }) => {
    const { md } = useResponsive()

    const [left, setLeft] = useState<string>(left_default)
    const [right, setRight] = useState<string>(right_default)
    // const mobileMargin: boolean = list.length > 3 ? true : false
    const [listData, setListData] = useState<CarouselPageProps['list']>([])

    const initList = useMemoizedFn(() => {
        const lists = [...list] || []
        const listInd = Math.round(list.length % 4)
        const data = (listInd && list.slice(-4)) || []
        lists.splice(-listInd)
        if (md) {
            if ([...lists, ...data].length > 0) {
                setListData([...lists, ...data])
            } else {
                setListData(list)
            }
        } else {
            setListData(list)
        }
    })
    useEffect(() => {
        if (list.length > 0) {
            initList()
        }
    }, [initList, list.length])

    const carouselProps: Partial<CarouselProps> = useMemo(() => {
        if (list.length === 1) {
            return { align: 'center' }
        }
        if (list.length === 2) {
            return { initialSlide: 1 }
        }
        if (list.length === 3) {
            return { initialSlide: 1 }
        }
        if (!md && list.length > 3) {
            return { initialSlide: 0 }
        }
        return { align: 'start' }
    }, [list.length, md])

    return (
        <Container>
            <Carousel
                // dragFree
                slideGap="md"
                height="auto"
                loop
                slidesToScroll={md ? 4 : 1}
                {...carouselProps}
                nextControlIcon={
                    <CarouselImage
                        onMouseEnter={() => setRight(right_active)}
                        onMouseLeave={() => setRight(right_default)}
                        src={md ? right : right_active}
                        alt="right"
                    />
                }
                previousControlIcon={
                    <CarouselImage
                        onMouseEnter={() => setLeft(left_active)}
                        onMouseLeave={() => setLeft(left_default)}
                        src={md ? left : left_active}
                        alt="left"
                    />
                }
            >
                {listData.map((item, index) => (
                    <Carousel.Slide size={md ? 280 : 294} key={index}>
                        <div className="imgBox">
                            <div>
                                <img
                                    sizes="280"
                                    width="100%"
                                    height="100%"
                                    key={item}
                                    src={pathJoin(PROJECT_PREFIX + item)}
                                    alt=""
                                />
                            </div>
                        </div>
                    </Carousel.Slide>
                ))}
            </Carousel>
            {/* {list.length > 1 && (
                <Progress
                    value={scrollProgress}
                    styles={{
                        bar: { backgroundColor: '#000', transitionDuration: '0ms' },
                        root: { backgroundColor: 'rgba(0, 0, 0, 0.3)', maxWidth: 172 }
                    }}
                    size="md"
                    mt={window.innerWidth > 576 ? 50 : 30}
                    mx="auto"
                    mb={window.innerWidth > 576 ? 46 : 24}
                />
            )} */}
        </Container>
    )
}

const Container = styled.div`
    height: 310px;
    .mantine-12sbrde {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        @media (max-width: 576px) {
            width: 100%;
            margin-left: 0;
        }
    }
    .mantine-339u0s {
        width: 1250px;
        height: 300px;
        margin: 0 62px;
        .mantine-fwzgnx {
            margin-top: 45px;
            margin-left: 16px;
            @media (max-width: 576px) {
                margin-top: 40px;
                margin-left: 0px;
            }
        }
        @media (max-width: 1366px) {
            width: 1250px;
            padding: 0;
            margin: 0 34px;
        }
        @media (max-width: 576px) {
            width: 100%;
            padding: 0 14px;
            margin: 0;
        }
    }
    .mantine-4ny9bc {
        height: 30px;
        .mantine-2yup0d {
            background-color: transparent;
            border: 0;
            box-shadow: 0 0 0;
        }
        @media (max-width: 576px) {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
    .mantine-1sn3zau {
        @media (max-width: 576px) {
            /* margin-left: 18vw !important; */
            margin-right: 14vw !important;
        }
    }
    .mantine-1ekk79a {
        padding: 0;
    }
    .imgBox {
        width: 280px;
        /* height: 248px; */
        margin: 0 33px 0 0;
        img {
            width: 100%;
            height: 210px;
            box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.3) !important;
            border-radius: 16px;
            overflow: hidden;
        }
    }
    @media (max-width: 576px) {
        width: 100%;
        height: 300px;
        /* margin-top: 40px; */
        padding: 40px 0;
        .imgBox {
            width: 100%;
            /* height: 253px; */
            margin: 0;
            /* margin: 0 14px 0 0; */
            img {
                height: 220px;
                box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.3) !important;
            }
        }
    }
    .mantine-4ny9bc {
        top: calc(50% - 16px);
    }
`

const CarouselImage = styled.img`
    width: 34px;
    height: 34px;
`
