import React, { FC, useEffect, useMemo, useState } from 'react'
import { useSignInWithApple } from 'react-firebase-hooks/auth'
import styled from '@emotion/styled'
import { logEvent } from 'firebase/analytics'
import { useInterval } from '@mantine/hooks'

import { SignInType } from 'modules/auth/components/SignInEmail'
import { useAuth, useVerificationCode } from 'core/auth/api'
import { getShareCode, removeShareCode, setIsGoogleUser } from 'shared/config'
import { analytics, auth, Reg } from 'shared/utils'
import { useApiError, useMemoizedFn, useResponsive, useSignInRedirect } from 'shared/hooks'
import { useI18n } from 'core/language/api'

import { Input, Modal } from 'antd'
import { Spin } from 'components'
import { Reaptcha } from 'modules/auth/components/common/Reaptcha'

import appleSigninIcon from 'assets/apple-signin.png'

import type { verificationCodeType } from 'core/auth/models'

const initialSecond = 60

export const AppleAuth: FC = () => {
    const { md } = useResponsive()
    const [signInWithApple, user, loading] = useSignInWithApple(auth)

    const {
        methods: { t }
    } = useI18n()
    const [authCodeType, setAuthCodeType] = useState<verificationCodeType>('thirdBind')
    const {
        loading: sendAuthCodeLoading,
        methods: { sendAuthCode }
    } = useVerificationCode()
    const {
        loading: signInLoading,
        methods: { signInWithApple: signInWithAppleMethod }
    } = useAuth()
    const {
        methods: { handleError }
    } = useApiError('AppleAuth')
    const { redirectBack } = useSignInRedirect()
    const [authCode, setAuthCode] = useState('')
    const [email, setEmail] = useState('')
    const [recaptchaVisible, setRecaptchaVisible] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [appleToken, setAppleToken] = useState('')

    useEffect(() => {
        if (user) {
            const accessToken =
                (user.user as any)?.accessToken || (user.user as any)?.stsTokenManager?.accessToken || ''
            const shareCode = getShareCode()
            signInWithAppleMethod({ appleToken: accessToken }, shareCode as string)
                .then(_token => {
                    logEvent(analytics, 'login', {
                        method: SignInType.APPLE
                    })
                    setIsGoogleUser(true)
                    removeShareCode()
                    redirectBack()
                })
                .catch(err => {
                    if (err.data.code === 20500 || err.data.code === 20501) {
                        setModalOpen(true)
                        setAppleToken(accessToken)
                        if (err.data.code === 20501) {
                            setAuthCodeType('bind')
                        }
                        return
                    }
                    return handleError(err)
                })
        }
    }, [handleError, redirectBack, signInWithAppleMethod, user])

    const isEmailValid = useMemo(() => Reg.email.test(email), [email])
    // const [captchaToken, setCaptchaToken] = useState('')
    const [seconds, setSeconds] = useState(initialSecond)
    const canSendAuthCode = useMemo(
        () => isEmailValid && seconds === initialSecond && !recaptchaVisible,
        [isEmailValid, recaptchaVisible, seconds]
    )

    const interval = useInterval(
        () =>
            setSeconds(s => {
                if (s !== 0) return s - 1

                interval.stop()
                return initialSecond
            }),
        1000
    )
    // const handleVerify = useMemoizedFn(() => interval.start())
    const handleVerify = useMemoizedFn((token: string) =>
        sendAuthCode('', email, token, authCodeType).then(res => {
            interval.start()
            // setCaptchaToken(typeof res === 'string' ? res : res.resendToken)
            setRecaptchaVisible(false)
        })
    )
    const handleSendAuthCode = () => {
        if (!canSendAuthCode) return
        // if (recaptchaVisible) {
        //     resendAuthCode(captchaToken).then(setCaptchaToken)
        //     interval.start()
        //     return
        // }
        setRecaptchaVisible(true)
    }

    const submitDisabled = useMemo(
        () => authCode.length !== 6 || !isEmailValid,
        [authCode.length, isEmailValid]
    )
    const handleSigninWithApple = useMemoizedFn(() => {
        if (submitDisabled) {
            return
        }
        signInWithAppleMethod({ appleToken, email, authCode })
            .then(() => {
                logEvent(analytics, 'login', {
                    method: SignInType.APPLE
                })
                setIsGoogleUser(true)
                removeShareCode()
                redirectBack()
            })
            .catch(handleError)
    })

    return (
        <Wrapper>
            <div
                className={`button ${loading ? 'buttonLoading' : ''}`}
                onClick={() => !loading && signInWithApple()}
            >
                <img className="apple-icon" src={appleSigninIcon} alt="" />
            </div>
            <Modal
                centered
                open={modalOpen}
                width={440}
                maskClosable={false}
                footer={null}
                onCancel={() => setModalOpen(false)}
            >
                <Spin spinning={loading || sendAuthCodeLoading || signInLoading}>
                    <ModalWrapper>
                        <ModalTitle>Binding Account</ModalTitle>
                        <div className="my-16 text-center">
                            <ModalInputWrapper>
                                <EmailInput
                                    value={email}
                                    onChange={({ target: { value } }) => setEmail(value)}
                                    placeholder={t('client.auth.placeholder.email')}
                                />
                            </ModalInputWrapper>
                            <ModalInputWrapper>
                                <CaptchaInputWrapper>
                                    <CaptchaInput
                                        value={authCode}
                                        onChange={({ target: { value } }) => setAuthCode(value)}
                                        placeholder="Verification Code"
                                    />
                                    <Divider />
                                    <SendButtonWrapper style={{ margin: md ? '0 50px' : '0 24px' }}>
                                        <SendButton
                                            className={canSendAuthCode ? '' : 'send-disabled'}
                                            onClick={handleSendAuthCode}
                                        >
                                            {seconds === initialSecond ? 'Send' : `${seconds}s`}
                                        </SendButton>
                                    </SendButtonWrapper>
                                </CaptchaInputWrapper>
                            </ModalInputWrapper>
                            {recaptchaVisible && <Reaptcha onVerify={handleVerify} />}
                        </div>
                        <SubmitButton
                            className={submitDisabled ? 'button-disabled' : ''}
                            onClick={handleSigninWithApple}
                        >
                            OK
                        </SubmitButton>
                    </ModalWrapper>
                </Spin>
            </Modal>
        </Wrapper>
    )
}

const SubmitButton = styled.div`
    margin: 20px auto 16px auto;
    background: #000000;
    width: 200px;
    height: 50px;
    border-radius: 35px;
    font-family: Roboto-Medium;
    font-size: 25px;
    color: #ffffff;
    text-align: center;
    line-height: 50px;
    font-weight: 500;
    cursor: pointer;
`

const SendButton = styled.button`
    width: 41px;
    background: none;
    height: 24px;
    font-family: Roboto-Regular;
    font-size: 18px;
    color: #000000;
    line-height: 24px;
    font-weight: 400;
`

const SendButtonWrapper = styled.div`
    .send-disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`

const Divider = styled.div`
    width: 1px;
    border-right: 1px solid rgba(181, 181, 181, 1);
    height: 24px;
`

const CaptchaInput = styled.input`
    flex: 1;
    height: 48px;
    outline: none;
    font-family: Roboto-Regular;
    font-size: 18px;
    text-align: center;
    line-height: 24px;
    font-weight: 400;
    border-radius: 12px;

    &::placeholder {
        color: #a0a0a9;
    }
`

const CaptchaInputWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 50px;
    background: #ffffff;
    border: 1px solid rgba(196, 196, 211, 1);
    border-radius: 12px;
`

const ModalInputWrapper = styled.div`
    padding: 0 20px;
    margin: 14px 0;
`

const ModalTitle = styled.div`
    font-family: Roboto-Medium;
    font-size: 22px;
    color: #000000;
    text-align: center;
    line-height: 30px;
    font-weight: 500;
`

const EmailInput = styled(Input)`
    height: 50px;
    background: #fff;
    border: 1px solid rgba(196, 196, 211, 1);
    border-radius: 12px;
    font-family: Roboto-Regular;
    font-size: 18px;
    letter-spacing: 0;
    text-align: center;
    line-height: 50px;
    font-weight: 400;
`

const ModalWrapper = styled.div`
    .button-disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`

const Wrapper = styled.div`
    margin-left: 20px;
    display: inline-block;
    cursor: pointer;

    .apple-icon {
        width: 50px;
        height: 50px;
    }

    .buttonLoading {
        opacity: 0.8;
    }
`
