import React, { FC } from 'react'
import styled from '@emotion/styled'

import { DOWNLOAD_URL_ANDROID, DOWNLOAD_URL_IOS } from 'shared/config'
import { useMount, useOs, useResponsive } from 'shared/hooks'

import { DownloadApp, NFTFrame, PageContainer, SectionContainer } from 'components'

export const Download: FC = () => {
    const { md } = useResponsive()

    const os = useOs()
    useMount(() => {
        switch (os) {
            case 'ios':
                window.location.href = DOWNLOAD_URL_IOS
                break
            case 'android':
                window.location.href = DOWNLOAD_URL_ANDROID
                break
            default:
                break
        }
    })

    return (
        <Container>
            <SectionContainer>
                {md ? (
                    <NFTFrame />
                ) : (
                    <Content className="flex justify-center items-center ">
                        <div>
                            <Title>Download Our MetaSill App</Title>
                            <div className="flex justify-center mt-20">
                                <DownloadApp spaceClassName="ml-20" />
                            </div>
                        </div>
                    </Content>
                )}
            </SectionContainer>
        </Container>
    )
}

const Title = styled.div`
    font-family: ArialUnicodeMS;
    font-weight: bold;
    font-size: 22px;
    line-height: 1.6;
    text-align: center;
`

const Content = styled.div`
    min-height: calc(100vh - 56px - 90px);
`

const Container = styled(PageContainer)`
    @media (max-width: 576px) {
        padding: 0;
        width: 100%;
    }
`
