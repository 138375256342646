import { on, reducer as createReducer } from 'ts-action'
import {
    setUser,
    clearUser,
    setUserAccessToken,
    clearUserAccessToken,
    setWalletAddressList,
    clearWalletAddressList
} from './actions'
import { FEATURE_NAME } from './constants'
import { assoc, omit } from 'ramda'

import { User } from 'core/auth/models'

import { WalletAddress } from 'core/walletaddress/models'

export interface State {
    user?: User
    userAccessToken?: string
    walletAddressList?: WalletAddress[] // user wallet addresses
}

export interface GlobalState {
    [FEATURE_NAME]: State
}

export const initialState: State = {}

export const reducer = createReducer(
    initialState,
    on(setUser, (state, { payload: { user } }) => assoc('user', user, state)),
    on(clearUser, state => omit(['user'], state)),
    on(setUserAccessToken, (state, { payload: { userAccessToken } }) =>
        assoc('userAccessToken', userAccessToken, state)
    ),
    on(clearUserAccessToken, state => omit(['userAccessToken'], state)),
    on(setWalletAddressList, (state, { payload: { walletAddressList } }) =>
        assoc('walletAddressList', walletAddressList, state)
    ),
    on(clearWalletAddressList, state => omit(['walletAddressList'], state))
)
