import { useCallback } from 'react'

import { useMountedPromiseFn, useListState } from 'shared/hooks'
import { services } from 'core/ad-statistics/data-access'
import { AdList, AdListQuery } from '../../../models'

export const useAdList = () => {
    const [state, setState] = useListState<AdList>()

    const getListApi = useMountedPromiseFn(services.getAdList)
    const getList = useCallback(
        (params: AdListQuery) => {
            setState({ loading: true })
            return getListApi(params)
                .then(({ list, total }) => {
                    setState({ list, total, loading: false })
                    return
                })
                .catch(err => {
                    setState({ loading: false })
                    return Promise.reject(err)
                })
        },
        [getListApi, setState]
    )

    const createApi = useMountedPromiseFn(services.createAd)
    const create = useCallback(
        (params: { name: string; link: string }) => {
            setState({ loading: true })
            return createApi(params)
                .then(() => {
                    setState({ loading: false })
                })
                .catch(err => {
                    setState({ loading: false })
                    return Promise.reject(err)
                })
        },
        [createApi, setState]
    )

    const editApi = useMountedPromiseFn(services.editAd)
    const edit = useCallback(
        (id: string, params: { name: string; link: string }) => {
            setState({ loading: true })
            return editApi(id, params)
                .then(() => {
                    setState({ loading: false })
                })
                .catch(err => {
                    setState({ loading: false })
                    return Promise.reject(err)
                })
        },
        [editApi, setState]
    )

    return {
        ...state,
        methods: {
            getList,
            create,
            edit
        }
    }
}
