import { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { useNavigate, useLocation } from 'react-router-dom'
import { urlQueryString } from 'shared/utils'
import { getToken, removeToken, removeIsTourist, removeWalletToken, removeWalletAddress } from 'shared/config'
import { useCartList } from 'core/cart/api'
import { useCart } from 'shared/hooks'
import { actions } from 'core/auth/state'
import { actions as cartActions } from 'core/cart/state'

export const SIGN_IN_REDIRECT_KEY = 'METASILL_WEBSITE_SIGN_IN_REDIRECT_PATH'

export const useSignInRedirect = () => {
    const dispatch = useDispatch()
    const { search, pathname } = useLocation()
    const { redirect } = urlQueryString.parse(search)
    const navigate = useNavigate()

    const { list, clearLocalCart } = useCart()
    const {
        methods: { syncData, getList: getCartNum }
    } = useCartList()

    useEffect(() => {
        if (redirect) {
            localStorage.setItem(SIGN_IN_REDIRECT_KEY, redirect)
        }
    }, [redirect])

    const loginOut = useCallback(
        (replacePath?: string) => {
            removeToken()
            removeWalletToken()
            removeWalletAddress()
            removeIsTourist()
            dispatch(actions.clearUser())
            dispatch(cartActions.updateCartNum({ cartNumber: 0 }))
            if (replacePath) {
                navigate(replacePath)
            }
        },
        [dispatch, navigate]
    )

    const toLogin = useCallback(
        (redirectPath = '', isReplace = false) => {
            const path = '/auth/sign_in?redirect=' + (redirectPath || pathname)
            if (isReplace) {
                navigate(path)
            } else {
                navigate(path)
            }
        },
        [pathname, navigate]
    )

    const checkLogin = useCallback(
        (redirectPath = '', isReplace = false): Promise<any> => {
            if (!getToken()) {
                return new Promise(resolve => {
                    toLogin(redirectPath, isReplace)
                    resolve(getToken())
                })
            }
            return new Promise(resolve => resolve(!getToken() ? toLogin : null))
        },
        [toLogin]
    )

    const redirectBackHandle = useCallback(
        (signInOrSignUp: boolean = false) => {
            getCartNum().finally(() => {
                const path = localStorage.getItem(SIGN_IN_REDIRECT_KEY)
                if (path) {
                    localStorage.removeItem(SIGN_IN_REDIRECT_KEY)
                    setTimeout(() => {
                        if (path === '/auth/sign_in') {
                            navigate('/mine', { replace: true })
                        } else if (path === '/orders/create' && signInOrSignUp) {
                            navigate(-1)
                        } else {
                            navigate(path, { replace: true })
                        }
                    })
                } else {
                    navigate('/')
                }
            })
        },
        [navigate, getCartNum]
    )

    const redirectBack = useCallback(
        (signInOrSignUp = false) => {
            if (list.length) {
                syncData(list)
                    ?.then(() => {
                        clearLocalCart()
                        redirectBackHandle(signInOrSignUp)
                    })
                    .catch(redirectBackHandle)
            } else {
                redirectBackHandle(signInOrSignUp)
            }
        },
        [clearLocalCart, list, redirectBackHandle, syncData]
    )

    return {
        getToken,
        loginOut,
        toLogin,
        checkLogin,
        redirectBack
    }
}
