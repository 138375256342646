import { useCallback } from 'react'

import { useMountedPromiseFn, useDetailState } from 'shared/hooks'
import { services } from 'core/event/data-access'

import type { EventForm } from '../../../models'

export const useEventCreate = () => {
    const [state, setState] = useDetailState()

    const createApi = useMountedPromiseFn(services.setCreate)
    const create = useCallback(
        (postData: EventForm) => {
            setState({ loading: true })
            return createApi(postData)
                .then(res => {
                    setState({ loading: false })
                    return Promise.resolve(res)
                })
                .catch(err => {
                    setState({ loading: false })
                    return Promise.reject(err)
                })
        },
        [createApi, setState]
    )

    return {
        ...state,
        methods: {
            create
        }
    }
}
