import React, { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { AdStatistics } from './index'
import { AdStatisticsDetail } from './detail'

export const AdStatisticsShell: FC = () => (
    <Routes>
        <Route path="/:id" element={<AdStatisticsDetail />} />
        <Route path="/:id/uv" element={<AdStatisticsDetail />} />
        <Route path="/" element={<AdStatistics />} />
    </Routes>
)
