import React, { FC, ReactNode } from 'react'
import styled from '@emotion/styled'

import backIcon from 'assets/back.png'

interface Props {
    title: string
    onBack?: () => void
    rightNode?: ReactNode
    titleClass?: string
}

export const PageHeaderMobile: FC<Props> = ({ title, onBack, rightNode, titleClass }) => {
    return (
        <Wrapper className="flex items-center">
            <Icon src={backIcon} onClick={() => onBack?.()} />
            <Title className={`flex-1 ${titleClass}`}>{title}</Title>
            <RightNode>{rightNode}</RightNode>
        </Wrapper>
    )
}

const RightNode = styled.div`
    min-width: 24px;
`

const Icon = styled.img`
    width: 24px;
    height: 24px;
`

const Title = styled.div`
    margin: 0 20px;
    font-family: OpenSans-SemiBold;
    font-size: 21px;
    color: #000000;
    text-align: center;
    font-weight: 600;
`

const Wrapper = styled.div`
    padding: 10px 20px;
`
