import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'

import { useI18n } from 'core/language/api'
import { getToken } from 'shared/config'
import { useApiError, useResponsive } from 'shared/hooks'
import { useDeliveryList } from 'core/delivery/api'
import { Spin } from 'components'
import { AddressCard } from 'modules/address/components/AddressCard'
import { AddressList } from '../components/AddressList'
import { Header } from '../components/header'

import noData from '../assets/delivery.png'
import { DeliveryTypeEnum } from 'core/delivery/models'

export const Address: FC<{}> = () => {
    const { md } = useResponsive()
    const [type, setType] = useState<DeliveryTypeEnum>(0)
    const [visible, setVisible] = useState<boolean>(false)
    const [addressTitle, setAddresssTitle] = useState<string>('Add a new address')
    const [addressId, setAddressId] = useState<string>('')
    const {
        methods: { t }
    } = useI18n()
    const {
        methods: { handleError }
    } = useApiError('Address')

    const {
        list,
        billingList,
        loading,
        methods: { getList, remove }
    } = useDeliveryList(type)

    const showedList = useMemo(
        () => (type === DeliveryTypeEnum.DELIVERY ? list : billingList),
        [billingList, list, type]
    )

    useEffect(() => {
        getToken() && getList().catch(handleError)
    }, [getList, handleError])

    const gotoCreate = useCallback(() => {
        setAddresssTitle('Add a new address')
        setVisible(true)
    }, [])

    const gotoEdit = useCallback((id: string) => {
        setAddresssTitle('Edit Address')
        setAddressId(id)
        setVisible(true)
    }, [])

    const gotoRemove = useCallback(
        (id: any) => {
            Modal.confirm({
                title: t('Remove'),
                icon: <ExclamationCircleOutlined />,
                content: t('Confirm remove address?'),
                okText: t('Ok'),
                cancelText: t('Cancel'),
                onOk() {
                    remove(id).catch(handleError)
                }
            })
        },
        [handleError, remove, t]
    )

    const onCancel = () => {
        setVisible(false)
        setAddressId('')
    }

    return (
        <Spin spinning={loading}>
            <Header
                name={
                    <HeaderTitle>
                        <div
                            className={
                                type === DeliveryTypeEnum.DELIVERY ? 'cursor-pointer' : 'type cursor-pointer'
                            }
                            onClick={() => setType(DeliveryTypeEnum.DELIVERY)}
                        >
                            Shipping Address
                        </div>
                        <div
                            className={
                                type === DeliveryTypeEnum.BILLING ? 'cursor-pointer' : 'type cursor-pointer'
                            }
                            onClick={() => setType(DeliveryTypeEnum.BILLING)}
                        >
                            Billing Address
                        </div>
                    </HeaderTitle>
                }
                btnText="Add"
                onClick={gotoCreate}
            />
            <Wrapper>
                {!getToken() && <div className="no-data" />}
                {getToken() &&
                    (!showedList.length && !loading ? (
                        <div className="no-data" />
                    ) : (
                        <div className="address-list mb-20">
                            {showedList.map((item, index) => (
                                <AddressCard
                                    className="address-item"
                                    key={index}
                                    delivery={item}
                                    onEdit={() => gotoEdit(item.id)}
                                    onRemove={() => gotoRemove(item.id)}
                                    addressType={type}
                                />
                            ))}
                        </div>
                    ))}
            </Wrapper>
            <Modal
                open={visible}
                onCancel={onCancel}
                footer={null}
                width={md ? 850 : '100%'}
                destroyOnClose
                style={{ maxWidth: '100%', margin: md ? '0 auto' : 0, top: md ? 100 : 56 }}
            >
                <AddressList
                    addressType={type}
                    addressTitle={addressTitle}
                    addressId={addressId}
                    onCancel={onCancel}
                    onSave={() => {
                        getList().catch(handleError)
                    }}
                />
            </Modal>
        </Spin>
    )
}

const HeaderTitle = styled.div`
    display: flex;
    div,
    .type {
        font-family: Roboto-Medium;
        font-size: 24px;
        color: #000000;
        line-height: 28px;
        margin-right: 40px;
        @media (max-width: 768px) {
            margin: 0;
        }
    }
    .type {
        color: #7f7f7f;
    }
`

const Wrapper = styled.div`
    .address-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .address-item {
        width: 47%;
        height: auto !important;
        margin-top: 30px;
        margin-bottom: 0;
        @media (max-width: 768px) {
            width: 100%;
        }
    }
    .address-edit,
    .address-remove {
        cursor: pointer;
    }
    .no-data {
        width: 100%;
        height: 33rem;
        background: url('${noData}') no-repeat center top 10rem / 23rem auto;
    }
`
