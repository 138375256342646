import { useEffect, useState } from 'react'

import { useSignInRedirect } from './useSignInRedirect'
import { useApiError } from './useApiError'
import { useAuth } from 'core/auth/api'
import { UserInfo } from 'core/auth/models'

export const useUserInfo = () => {
    const { getToken } = useSignInRedirect()
    const {
        methods: { getUserInfo }
    } = useAuth()
    const [userInfo, setUserInfo] = useState<UserInfo>()

    const {
        methods: { handleError }
    } = useApiError('FeatureEventAuth')

    useEffect(() => {
        getToken() &&
            getUserInfo()
                .then(info => info && setUserInfo(info))
                .catch(handleError)
    }, [getToken, handleError, getUserInfo])

    return userInfo
}
