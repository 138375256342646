import React, { FC } from 'react'
import styled from '@emotion/styled'

import { useMemoizedFn } from 'shared/hooks'

import { Flex } from '@mantine/core'

import { NftTypeEnum } from 'core/management/nft/models'

import left_active from '../assets/left_active.png'
interface NftListHeaderProps {
    type: NftTypeEnum
    setType: (type: NftTypeEnum) => void
    goBack: () => void
    title: string
    onDone: () => void
    setLastSelected: () => void
}

export const NftListHeader: FC<NftListHeaderProps> = ({
    type,
    setType,
    goBack,
    title,
    onDone,
    setLastSelected
}) => {
    const handleClick = useMemoizedFn((newType: NftTypeEnum) => {
        type !== newType && setType(newType)
        setLastSelected()
    })

    return (
        <Container>
            <Header>
                <Flex align="center">
                    <HeaderIcon>
                        <img width={20} height={20} onClick={goBack} src={left_active} alt="frame" />
                    </HeaderIcon>
                    <HeaderTitle>{title}</HeaderTitle>
                </Flex>
                <HeaderBtn onClick={onDone}>Done</HeaderBtn>
            </Header>
            <HeaderSelect>
                <HeaderSelectItem
                    className={type === NftTypeEnum.OWNED ? 'active' : ''}
                    onClick={() => handleClick(NftTypeEnum.OWNED)}
                >
                    <span>Owned by me (wallet)</span>
                </HeaderSelectItem>
                <HeaderSelectItem
                    className={type === NftTypeEnum.AUTHORIZED ? 'active' : ''}
                    onClick={() => handleClick(NftTypeEnum.AUTHORIZED)}
                >
                    <span>Shared with me</span>
                </HeaderSelectItem>
                <HeaderSelectItem
                    className={type === NftTypeEnum.UPLOAD ? 'active' : ''}
                    onClick={() => handleClick(NftTypeEnum.UPLOAD)}
                >
                    <span>Uploaded by me</span>
                </HeaderSelectItem>
            </HeaderSelect>
        </Container>
    )
}

const Container = styled.div`
    .active {
        color: #ffffff;
        background: #000000;
        border-radius: 25px;
        @media (max-width: 768px) {
            border-radius: 18px;
        }
    }
    .header {
        height: 24px;
        margin-top: 24px;
    }
`
const Header = styled.div`
    width: 100%;
    height: 82px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(47, 46, 46, 1);
    margin-bottom: 30px;
    @media (max-width: 768px) {
        height: 68px;
        margin-bottom: 24px;
    }
`
const HeaderSelect = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 768px) {
        justify-content: space-between;
    }
`
const HeaderSelectItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 44px;
    padding: 5px 14px;
    border: 1px solid rgba(0, 0, 0, 1);
    border-radius: 22px;
    font-family: Roboto-Medium;
    font-size: 18px;
    color: #000000;
    text-align: center;
    line-height: 44px;
    font-weight: 500;
    margin-right: 24px;
    cursor: pointer;
    @media (max-width: 768px) {
        width: 96px;
        min-height: 50px;
        font-size: 12px;
        line-height: 20px;
        margin-right: 0;
    }
`

const HeaderIcon = styled.div`
    cursor: pointer;
    margin-right: 10px;
`

const HeaderTitle = styled.div`
    font-family: Roboto-Medium;
    font-size: 24px;
    color: #000000;
    line-height: 28px;
    font-weight: 500;
`
const HeaderBtn = styled.div`
    width: 100px;
    height: 36px;
    border: 1px solid rgba(0, 0, 0, 1);
    border-radius: 22px;
    font-family: Roboto-Medium;
    font-size: 18px;
    color: #000000;
    text-align: center;
    line-height: 36px;
    font-weight: 500;
    cursor: pointer;
    @media (max-width: 768px) {
        width: 78px;
        height: 28px;
        font-size: 14.4px;
        border: 0.8px solid rgba(0, 0, 0, 1);
        border-radius: 17.6px;
        line-height: 28px;
    }
`
