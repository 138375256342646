import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { last, path } from 'ramda'
import styled from '@emotion/styled'

import { useNftDetail } from 'core/management/nft/api/useNftDetail'
import { CLIENT_IMAGE_UPLOAD_URL, getToken, CLIENT_ZIP_UPLOAD_URL } from 'shared/config'
import { useApiError, useSetState, useShowMessage, useUserInfo } from 'shared/hooks'

import { Spin, Upload, Input, Modal } from 'antd'
import { BackIcon, PageContainer } from 'components'

import uploadIcon from 'assets/upload-icon.png'

import type { UploadFile, UploadProps } from 'antd/es/upload/interface'
import type { NftFormData, NftUploadFileType } from 'core/management/nft/models'
import { StatusInfo } from './components/StatusInfo'
import { pathJoin } from 'shared/utils'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

const FILE_MAX_SIZE = 210

const acceptFileExt = [
    'image/png',
    'image/jpeg',
    'image/gif',
    'image/svg+xml',
    'image/bmp',
    'video/mp4',
    'video/3gpp',
    'video/quicktime',
    'application/zip',
    'application/x-zip-compressed'
]

const isZipFile = (type: string) => type === 'application/zip' || type === 'application/x-zip-compressed'

const inputFontStyle: CSSProperties = {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#303030',
    lineHeight: '20px'
}
const inputStyle: CSSProperties = { width: '100%', height: 40, ...inputFontStyle }
const initialFormData = {
    name: '',
    description: '',
    tag: [],
    fileId: [''],
    address: '',
    externalUrl: ''
}

export const UploadForm: FC = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const userInfo = useUserInfo()
    const {
        methods: { error, showSingleButtonModal }
    } = useShowMessage()
    const {
        methods: { handleError }
    } = useApiError('UploadForm', error as any)

    const [previewVisible, setPreviewVisible] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [previewTitle, setPreviewTitle] = useState('')
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [tagList, setTagList] = useState<string[]>([''])
    const [formData, setFormData] = useSetState<NftFormData>(initialFormData)
    const [isZip, setIsZip] = useState(false)
    const [, setFileType] = useState<NftUploadFileType>()

    const {
        loading,
        detail,
        methods: { getDetail, edit, create }
    } = useNftDetail()
    useEffect(() => {
        id &&
            getDetail(id)
                .then(({ name, description, tag, fileId, fileUrl, thumbUrl, type, address, externalUrl }) => {
                    setFormData({ name, description, tag, fileId, address, externalUrl })
                    setTagList(tag)
                    setFileList([
                        {
                            uid: '1',
                            name: `${name}`,
                            status: 'done',
                            url: fileUrl,
                            thumbUrl
                        }
                    ])
                    setFileType({
                        type,
                        fileUrl
                    })
                })
                .catch(handleError)
    }, [getDetail, handleError, id, setFormData])

    const handleCancel = () => setPreviewVisible(false)

    const handlePreview = useCallback(
        async (file: UploadFile) => {
            setPreviewImage(file.thumbUrl as string)
            setPreviewVisible(true)
            setPreviewTitle(
                formData.name || file.name || file.thumbUrl!.substring(file.thumbUrl!.lastIndexOf('/') + 1)
            )
        },
        [formData.name]
    )

    const props: UploadProps = {
        listType: 'picture-card',
        className: 'upload-style',
        accept: '.png, .jpg, .jpeg, .gif, .svg, .bmp, .mp4, .mov, .3gp, .quicktime, .zip',
        maxCount: 1,
        headers: {
            Authorization: `bearer ${getToken()}`
        },
        action: file => {
            if (isZipFile(file.type)) return CLIENT_ZIP_UPLOAD_URL
            return CLIENT_IMAGE_UPLOAD_URL
        },
        fileList,
        onRemove: file => {
            const index = fileList.indexOf(file)
            const newFileList = fileList.slice()
            newFileList.splice(index, 1)
            setFileList(newFileList)
            setFormData({ name: '' })
        },
        onPreview: handlePreview,
        beforeUpload: file => {
            if (!acceptFileExt.includes(file.type)) {
                error('File types not supported')
                return Upload.LIST_IGNORE
            }
            const isLt = file.size / 1024 / 1024 < FILE_MAX_SIZE
            if (!isLt) {
                error('The file size exceeds the limit allowed.')
                return Upload.LIST_IGNORE
            }
            if (file.size / 1024 / 1024 > 100) {
                return new Promise(resolve => {
                    Modal.confirm({
                        centered: true,
                        content: (
                            <div>
                                If the file is too large, it will be difficult to load and easy to play out in
                                field, so it is recommended to limit the file to less than 100MB.
                            </div>
                        ),
                        onOk: () => resolve(file),
                        onCancel: () => resolve(Upload.LIST_IGNORE)
                    })
                })
            }
            const reader = new FileReader()
            reader.onload = () => setFileList([file])
            reader.readAsDataURL(file)
        },
        onChange: ({ fileList }) => {
            if (fileList.length === 0) {
                return
            }
            const responseCode = path(['response', 'code'], fileList[0])
            if (responseCode && responseCode !== 200) {
                if (responseCode === 20045) {
                    const msg = path(['response', 'msg'], fileList[0]) as string
                    const fileType = last(msg.split('_'))
                    if (fileType !== '') {
                        error(`The file type is ${fileType}, which is not supported at this time.`)
                    } else {
                        error(
                            'Unknown file type. The file could not be recognized; it may be invalid or corrupted.'
                        )
                    }
                } else if (responseCode === 20046) {
                    const msg = path(['response', 'msg'], fileList[0]) as string
                    const lastIndex = msg.lastIndexOf('_')
                    const fileName = msg.slice(0, lastIndex)
                    const fileType = msg.slice(lastIndex + 1)
                    if (fileType !== '') {
                        error(
                            `Unzipping this file was aborted. The file type of "${fileName}" is ${fileType}, which is not supported at this time.`
                        )
                    } else {
                        error(
                            `Unzipping this file was aborted. The file type of "${fileName}" could not be recognized; it may be invalid or corrupted.`
                        )
                    }
                } else {
                    error('Upload failure')
                }
                setFileList([])
                return
            }
            if (responseCode === 200) {
                const thumbPath = path(['response', 'data', 'thumbUrl'], fileList[0]) as string

                const thumbName = fileList[0].name.substring(0, fileList[0].name.lastIndexOf('.'))
                let thumbUrl = ''
                if (isZipFile(`${fileList[0].type}`)) {
                    const data = path(['response', 'data'], fileList[0]) as Array<{
                        id: string
                        fileName: string
                    }>
                    setFormData({
                        fileId: data.map(({ id }) => id)
                    })
                    setIsZip(true)
                } else {
                    thumbUrl = pathJoin(window.origin, 'metasill', thumbPath)
                    const fileId = [fileList[0].response.data.id]
                    setFormData({
                        fileId: id ? fileId[0] : fileId
                    })
                }

                setFileList([{ ...fileList[0], thumbUrl }])
                setFormData({ name: thumbName })

                return
            }
            setFileList(fileList)
        }
    }

    const handleSubmit = useCallback(() => {
        if (fileList.length <= 0) {
            error('No files to upload')
            return
        }
        if (!formData.name) {
            error('Invalid or empty names')
            return
        }
        // if (!id && userInfo?.role.nftSuperAdmin && !formData.address) {
        //     error('Invalid or empty addresses')
        //     return
        // }
        // if (formData.tag.some(tag => tag.length > 0 && tag.length < 4)) {
        //     error('Each tag needs to be >= 4 characters')
        //     return
        // }

        const fn = id ? edit(id!, formData) : create(formData)
        fn.then(createdId => {
            if (id) {
                showSingleButtonModal({
                    message: 'Edit successfully',
                    type: 'success',
                    buttonText: 'OK',
                    onButtonClick: () => navigate(`/management/nft/${id}`, { replace: true })
                })
                return
            }
            showSingleButtonModal({
                message: 'Upload successfully',
                type: 'success',
                buttonText: 'OK',
                onButtonClick: () => {
                    if ((createdId && createdId?.length <= 0) || isZip || !createdId) {
                        navigate(-1)
                        return
                    }

                    navigate(`/management/nft/${createdId}`)
                }
            })
        }).catch(handleError)
    }, [
        create,
        edit,
        error,
        fileList.length,
        formData,
        handleError,
        id,
        isZip,
        navigate,
        showSingleButtonModal
    ])

    return (
        <Spin spinning={loading}>
            <Container>
                <FormTitle className="flex items-center">
                    <div className="title flex items-center">
                        <BackIcon onClick={() => navigate(-1)} />
                        <div className="titleContent">Media File</div>
                    </div>
                    {detail?.status && (
                        <StatusInfo
                            noteTrigger={window.innerWidth > 768 ? 'hover' : 'click'}
                            notePlacement={window.innerWidth > 768 ? 'rightTop' : 'bottom'}
                            isDirection="right"
                            status={detail.status}
                            note={detail.note}
                        />
                    )}
                </FormTitle>
                <div className="formItems">
                    <div className="item">
                        <div className="label">
                            <div>Upload Image Or Video</div>
                            <div className="tip">
                                Supported file formats: png, jpg, jpeg, gif, svg, mp4, bmp, mov, 3gp,
                                quicktime, zip
                            </div>
                            <div className="tip">Maximum size: {FILE_MAX_SIZE}MB / each</div>
                        </div>
                        <Upload {...props}>
                            {fileList.length < 1 && (
                                <div>
                                    <img src={uploadIcon} width={50} height={50} alt="Upload" />
                                </div>
                            )}
                        </Upload>
                        <Modal
                            open={previewVisible}
                            title={previewTitle}
                            footer={null}
                            onCancel={handleCancel}
                        >
                            <img alt="example" style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                    </div>
                    <div className="item">
                        <div className="label">
                            <div>Name</div>
                        </div>
                        <Input
                            style={inputStyle}
                            value={formData.name}
                            onChange={({ target: { value } }) => setFormData({ name: value })}
                            placeholder="Name"
                        />
                    </div>
                    <div className="item">
                        <div className="label">
                            <div>Description</div>
                        </div>
                        <Input.TextArea
                            style={{ padding: 10, ...inputFontStyle }}
                            autoSize={{ minRows: 3, maxRows: 6 }}
                            value={formData.description}
                            onChange={({ target: { value } }) => setFormData({ description: value })}
                        />
                    </div>
                    {((!id && userInfo?.role?.nftSuperAdmin) || formData.address) && (
                        <div className="item">
                            <div className="label">
                                <div>Authorized Address</div>
                            </div>
                            <Input
                                style={inputStyle}
                                disabled={!!id}
                                value={formData.address}
                                onChange={({ target: { value } }) => setFormData({ address: value })}
                            />
                        </div>
                    )}
                    <div className="item">
                        <div className="label">
                            <div>External URL</div>
                        </div>
                        <Input
                            style={inputStyle}
                            value={formData.externalUrl}
                            onChange={({ target: { value } }) => setFormData({ externalUrl: value })}
                            placeholder=""
                        />
                    </div>
                    <div className="item bottomBtn">
                        <div className="label">
                            <div>Tag</div>
                        </div>
                        {tagList.map((item, index) => (
                            <TagInputs key={index}>
                                <Input
                                    style={inputStyle}
                                    value={item}
                                    onChange={({ target: { value } }) => {
                                        const data = tagList
                                        data[index] = value
                                        setTagList([...data])
                                        setFormData({ tag: data.filter(item => item && item.trim()) })
                                    }}
                                    placeholder={''}
                                />
                                {tagList.length > 1 && (
                                    <MinusCircleOutlined
                                        className="dynamic-delete-button"
                                        style={{ fontSize: '28px', color: '#d9d9d9', marginLeft: '8px' }}
                                        onClick={() => {
                                            const data = tagList
                                            data.splice(index, 1)
                                            setTagList([...data])
                                        }}
                                    />
                                )}
                            </TagInputs>
                        ))}
                        <AddFiledBtn onClick={() => setTagList([...tagList, ''])}>
                            <PlusOutlined style={{ fontSize: 18, marginRight: '8px' }} />
                            Add field
                        </AddFiledBtn>
                    </div>
                    <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
                </div>
            </Container>
        </Spin>
    )
}

const AddFiledBtn = styled.div`
    width: 100%;
    height: 40px;
    border: 1px dashed #000;
    color: #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    &:hover {
        color: #000;
    }
`

const TagInputs = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
`

const SubmitButton = styled.div`
    margin-top: 50px;
    width: 100%;
    height: 50px;
    font-family: Roboto-Medium;
    font-size: 25px;
    color: #ffffff;
    text-align: center;
    line-height: 50px;
    background: #000000;
    border-radius: 35px;
    cursor: pointer;
    @media (max-width: 768px) {
        margin-top: 0;
    }
`
const FormTitle = styled.div`
    margin: 50px 0;
    @media (max-width: 768px) {
        display: flex;
        justify-content: space-between;
        height: 48px;
        padding-left: 20px;
        margin: 10px 0 20px;
    }
`

const Container = styled(PageContainer)`
    width: 580px;
    padding: 0;
    padding-bottom: 80px;
    .title {
        .titleContent {
            margin: 0 50px 0 14px;
            font-family: Roboto-Medium;
            font-size: 36px;
        }
        @media (max-width: 768px) {
            .titleContent {
                font-size: 24px;
                margin: 0 0 0 10px;
            }
        }
    }
    .item {
        margin-bottom: 30px;
        .label {
            margin-bottom: 10px;
            font-family: HelveticaNeue-Medium;
            font-size: 18px;
            color: #000000;
            line-height: 26px;
        }
        .tip {
            font-family: Roboto-Regular;
            font-size: 12px;
            color: #000000;
        }
    }
    .upload-style {
        .ant-upload-list-picture-card-container {
            width: 320px;
            height: 180px;
            @media (max-width: 768px) {
                width: 100%;
            }
        }
        .ant-upload.ant-upload-select-picture-card {
            background-color: #ffffff;
            border: 1px dashed #000;
        }
        .ant-upload {
            width: 320px;
            height: 180px;
            @media (max-width: 768px) {
                width: 100%;
            }
        }
    }
    .ant-upload-list-picture-card .ant-upload-list-item {
        border: #000 solid 1px !important;
    }
    .formItems {
        @media (max-width: 768px) {
            padding: 0 20px;
            width: 100%;
        }
    }
    @media (max-width: 768px) {
        width: 100%;
        .bottomBtn {
            margin-bottom: 30px;
        }
    }
`
