import { useMemo } from 'react'

import { useMemoizedFn } from 'shared/hooks'

export const useSolana = () => {
    const hasSolana = useMemo(() => !!(window as any)?.phantom?.solana?.isPhantom, [])
    /**
     * @description prompts user to connect wallet if it exists
     */
    const connectPhantom = useMemoizedFn(async () => {
        const { solana } = window

        if (solana) {
            try {
                const response = await solana.connect()
                const account = response.publicKey.toString()
                return account
            } catch (err) {
                // { code: 4001, message: 'User rejected the request.' }
            }
        }

        return ''
    })

    /**
     * @description disconnect Phantom wallet
     */
    const disconnectPhantom = useMemoizedFn(async () => await window?.solana?.disconnect())

    return { hasSolana, methods: { connectPhantom, disconnectPhantom } }
}
