import { useState } from 'react'

import { useMemoizedFn, useMountedPromiseFn } from 'shared/hooks'
import { services } from 'core/homepage/data-access'

export const useSubscribe = () => {
    const [loading, setLoading] = useState<boolean>(false)

    const subscribeApi = useMountedPromiseFn(services.subscribe)
    const subscribe = useMemoizedFn((email: string) => {
        setLoading(true)
        return subscribeApi(email)
            .then(() => {
                setLoading(false)
                return true
            })
            .catch(err => {
                setLoading(false)
                return Promise.reject(err)
            })
    })

    return {
        loading,
        methods: { subscribe }
    }
}
