import React, { FC } from 'react'
import styled from '@emotion/styled'

import logoFooter from './assets/logo-footer.png'

export const Footer: FC = () => {
    return (
        <Wrapper className="flex flex-col items-center text-white">
            <div>
                <img src={logoFooter} style={{ width: 164, height: 24 }} alt="" />
            </div>
            <div className="mt-4">Copyright © 2022 MetaSill. All Rights Reserved.</div>
        </Wrapper>
    )
}

const Wrapper = styled.footer`
    padding: 21px 0;
    height: 90px;
    background-color: #2f2e2e;
    font-family: ArialUnicodeMS;
    font-size: 12px;
    color: #9b9b9b;
`
