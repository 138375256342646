import React, { FC } from 'react'
import styled from '@emotion/styled'

import { PersonalInformation } from './PersonalInformation'
import { OrderItemForSummary, OrderPrice } from 'core/order/models'

import type { PersonalInformationForm } from 'core/scanRent/models'

export interface OrderSummaryProps {
    price: OrderPrice
    orderItems: OrderItemForSummary[]
    loading: boolean
    initialValues: PersonalInformationForm
    onSubmit: (value: PersonalInformationForm) => void
    ccLocale: string
    setCcLocale: (val: string) => void
    setPersonalInformationId: (val: string) => void
}

export const OrderSummary: FC<OrderSummaryProps> = ({
    price,
    orderItems,
    loading,
    initialValues,
    onSubmit,
    ccLocale,
    setCcLocale,
    setPersonalInformationId
}) => {
    return (
        <Wrapper>
            <div className="box">
                <PersonalInformation
                    ccLocale={ccLocale}
                    setCcLocale={setCcLocale}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    setPersonalInformationId={setPersonalInformationId}
                />
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    margin: 0 auto;
    width: 580px;
    padding: 30px 24px 50px;
    @media (max-width: 768px) {
        width: 100%;
    }
    .box {
        margin-top: 30px;
    }
`
