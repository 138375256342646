import React, { FC } from 'react'
import styled from '@emotion/styled'

import { PageContainer } from 'components'

export const PrivacyPolicy: FC = () => {
    return (
        <Container>
            <h1>Privacy Policy</h1>
            <div>
                <div>We are committed to protecting your privacy.</div>
                <div>This privacy policy describes the privacy practices for our apps (the "Apps").</div>
            </div>
            <h5>Information we collect</h5>
            <div>
                <div>We may collect the following information when you use our Apps:</div>
                <div className="list">
                    <ul>
                        <li>
                            Personal information you disclose to us, such as emails, usernames, passwords,
                            device IDs, images, videos, files, etc.
                        </li>
                        <li>
                            Information automatically collected, such as IP addresses, device characteristics,
                            log and usage data, device data, location data, etc.
                        </li>
                        <li>
                            Information collected through our Apps, such as geolocation information, mobile
                            device access, mobile device data, push notifications, etc.
                        </li>
                    </ul>
                </div>
            </div>

            <h5>Collected information uses</h5>
            <div>
                <div>We may use the information we collect or receive for the following reasons:</div>
                <div className="list">
                    <ul>
                        <li>To facilitate account creation and logon process.</li>
                        <li>Request feedback.</li>
                        <li>To enable user-to-user communications.</li>
                        <li>To manage user accounts.</li>
                        <li>To send administrative information to you.</li>
                        <li>To protect our services.</li>
                        <li>
                            To enforce our terms, conditions and policies for business purposes, to comply
                            with legal and regulatory requirements or in connection with our contract.
                        </li>
                        <li>To respond to legal requests and prevent harm.</li>
                        <li>Fulfill and manage your orders.</li>
                        <li>To deliver and facilitate delivery of services to the user.</li>
                        <li>To respond to user inquiries/offer support to users.</li>
                        <li>To send you marketing and promotional communications.</li>
                        <li>Deliver targeted advertising to you.</li>
                        <li>To personalize user experience.</li>
                        <li>To improve our products or services.</li>
                    </ul>
                </div>
            </div>
            <div className="time">Last updated February 15, 2022</div>
        </Container>
    )
}

export const PrivacyPolicyZh: FC = () => (
    <Container>
        <h1>隐私政策</h1>
        <div>
            <div>我们致力于保护您的隐私。</div>
            <div>本隐私政策描述了我们的应用程序（以下简称“应用程序”）的隐私惯例。</div>
        </div>
        <h5>我们收集的信息</h5>
        <div>
            <div>当您使用我们的应用程序时，我们可能会收集以下信息：</div>
            <div className="list">
                <ul>
                    <li>您向我们披露的个人信息，例如电子邮件、用户名、密码、设备ID、图像、视频、文件等。</li>
                    <li>自动收集的信息，例如IP地址、设备特征、日志和使用数据、设备数据、位置数据等。</li>
                    <li>
                        通过我们的应用程序收集的信息，例如地理位置信息、移动设备访问、移动设备数据、推送通知等。
                    </li>
                </ul>
            </div>
        </div>

        <h5>收集的信息用途</h5>
        <div>
            <div>我们可能会出于以下原因使用我们收集或接收到的信息：</div>
            <div className="list">
                <ul>
                    <li>便于账户创建和登录过程。</li>
                    <li>请求反馈。</li>
                    <li>促进用户之间的沟通。</li>
                    <li>管理用户账户。</li>
                    <li>向您发送行政信息。</li>
                    <li>保护我们的服务。</li>
                    <li>为商业目的执行我们的条款、条件和政策，遵守法律和监管要求或与我们的合同有关。</li>
                    <li>回应法律请求并防止危害。</li>
                    <li>履行和管理您的订单。</li>
                    <li>向用户提供服务并促进服务的交付。</li>
                    <li>回应用户的查询/为用户提供支持。</li>
                    <li>向您发送营销和推广通讯。</li>
                    <li>向您提供定向广告。</li>
                    <li>个性化用户体验。</li>
                    <li>改进我们的产品或服务。</li>
                </ul>
            </div>
        </div>
        <div className="time">最后更新于 2022 年 2 月 15 日。</div>
    </Container>
)

const Container = styled(PageContainer)`
    width: 100%;
    padding: 50px 200px 100px 200px;
    padding-bottom: 10rem;
    font-family: Roboto-Regular;
    font-size: 16px;
    color: #000000;
    line-height: 24px;
    @media (max-width: 576px) {
        padding: 50px 20px 50px;
        width: 100%;
    }

    h1 {
        font-family: Roboto-Bold;
        font-size: 36px;
        color: #000000;
        line-height: 28px;
    }
    h5 {
        margin: 30px 0;
        font-family: Roboto-Bold;
        font-size: 18px;
        color: #000000;
        line-height: 24px;
    }
    .list {
        padding-left: 35px;
    }
    li {
        list-style-type: disc;
    }
    .time {
        margin-top: 30px;
    }
`
