import React, { FC } from 'react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { LineButton } from 'components'
import { useUserInfo } from 'shared/hooks'

import right from '../assets/right.png'

interface Props {
    title: string
    onSave?: () => void
}

export const FormHeader: FC<Props> = ({ title, onSave }) => {
    const navigate = useNavigate()
    const userInfo = useUserInfo()
    return (
        <Wrapper>
            <div className="name" onClick={() => navigate(-1)}>
                <img src={right} alt="" />
                <span>{title}</span>
            </div>
            {userInfo?.storeAdmin && (
                <LineButton className="createBtn" onClick={() => onSave && onSave()}>
                    Save
                </LineButton>
            )}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 125px;
    padding-top: 68px;
    border-bottom: 1px solid rgba(47, 46, 46, 1);
    .name {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: Roboto-Medium;
        font-size: 24px;
        color: #000;
        letter-spacing: 0;
        line-height: 28px;
        font-weight: 500;
        cursor: pointer;
        img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            transform: rotate(180deg);
        }
    }
    @media (max-width: 576px) {
        width: calc(100% - 40px);
        height: 68px;
        margin: 0 20px;
        padding: 0;
    }
`
