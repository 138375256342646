import { useCallback } from 'react'

import { useDetailState, useMountedPromiseFn } from 'shared/hooks'
import { services } from 'core/delivery/data-access'
import { DeliverDefault, Delivery, DeliveryFormData } from 'core/delivery/models'

export const useDeliveryDetail = (id: string) => {
    const [state, setState] = useDetailState<Delivery>()

    const getDetailApi = useMountedPromiseFn(services.getDetail)
    const getDetail = useCallback(() => {
        setState({ loading: true })
        return getDetailApi(id)
            .then(detail => {
                setState({ detail, loading: false })
                return detail
            })
            .catch(err => {
                setState({ loading: false })
                return Promise.reject(err)
            })
    }, [getDetailApi, id, setState])

    const updateApi = useMountedPromiseFn(services.update)
    const update = useCallback(
        (formData: DeliveryFormData) => {
            setState({ loading: true })
            return updateApi(id, formData)
                .then(() => setState({ loading: false }))
                .catch(err => {
                    setState({ loading: false })
                    return Promise.reject(err)
                })
        },
        [setState, updateApi, id]
    )

    const setDefaultApi = useMountedPromiseFn(services.setdefault)
    const setDefault = useCallback(
        (isDefault: DeliverDefault) => {
            setState({ loading: true })
            return setDefaultApi(id, isDefault)
                .then(() => setState({ loading: false }))
                .catch(err => {
                    setState({ loading: false })
                    return Promise.reject(err)
                })
        },
        [setState, setDefaultApi, id]
    )

    const removeApi = useMountedPromiseFn(services.remove)
    const remove = useCallback(
        (id: string) => {
            setState({ loading: true })
            return removeApi(id)
                .then(() => setState({ loading: false }))
                .catch(err => {
                    setState({ loading: false })
                    return Promise.reject(err)
                })
        },
        [removeApi, setState]
    )

    return {
        ...state,
        methods: {
            getDetail,
            update,
            remove,
            setDefault
        }
    }
}
