import { useMemoizedFn, useMountedPromiseFn, useDetailState } from 'shared/hooks'
import { services } from 'core/management/nft/data-access'

import { MyCollectionList } from '../models'

export const useOwnedDetail = () => {
    const [detail, setDetail] = useDetailState<MyCollectionList>()
    const getOwnedDetailApi = useMountedPromiseFn(services.ownedDetail)
    const getOwnedDetail = useMemoizedFn((id: string) => {
        setDetail({ loading: true })
        return getOwnedDetailApi(id)
            .then(detail => {
                setDetail({ loading: false, detail })
                return detail
            })
            .catch(err => {
                setDetail({ loading: false })
                return Promise.reject(err)
            })
    })

    return {
        ...detail,
        methods: { getOwnedDetail }
    }
}
