import React, { FC, PropsWithChildren } from 'react'
import styled from '@emotion/styled'

import { Button } from 'components'

interface Props {
    disabled?: boolean
    onClick?: () => void
}

export const AddCartButton: FC<PropsWithChildren<Props>> = ({ children, disabled = false, onClick }) => {
    return (
        <Wrapper>
            <Button
                disabled={disabled}
                className={disabled ? 'addCartDisable' : ''}
                style={disabled ? {} : { fontSize: '25px' }}
                onClick={() => !disabled && onClick?.()}
            >
                {children}
            </Button>
        </Wrapper>
    )
}
const Wrapper = styled.div`
    .addCartDisable {
        border: 2px solid #ccc !important;
        background-color: #fff !important;
    }
    .addCartDisable .text-white {
        color: #ccc !important;
        line-height: 46px !important;
    }
`
