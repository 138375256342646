import { useDispatch } from 'react-redux'

import { actions } from 'core/auth/state'
import { services } from 'core/walletaddress/data-access'
import { useMemoizedFn, useMountedPromiseFn, useListState } from 'shared/hooks'

import type { WalletAddress } from 'core/walletaddress/models'

export const useWalletAddress = () => {
    const dispatch = useDispatch()
    const [state, setState] = useListState<WalletAddress>()

    const getWalletListApi = useMountedPromiseFn(services.walletAddress)
    const getWalletAddress = useMemoizedFn(() => {
        setState({ loading: true })
        return getWalletListApi()
            .then(list => {
                setState({ loading: false, list })
                dispatch(actions.setWalletAddressList({ walletAddressList: list }))
                return list
            })
            .catch(err => {
                setState({ loading: false })
                return Promise.reject(err)
            })
    })

    const bindApi = useMountedPromiseFn(services.bind)
    const bind = useMemoizedFn((address: string, name: string) => {
        setState({ loading: true })
        return bindApi(address, name)
            .then(getWalletAddress)
            .catch(err => {
                setState({ loading: false })
                return Promise.reject(err)
            })
    })

    const unBindApi = useMountedPromiseFn(services.unbind)
    const unBind = useMemoizedFn((id: number) => {
        setState({ loading: true })
        return unBindApi(id)
            .then(getWalletAddress)
            .catch(err => {
                setState({ loading: false })
                return Promise.reject(err)
            })
    })

    return {
        ...state,
        methods: { getWalletAddress, bind, unBind }
    }
}
