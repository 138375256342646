import { SpecItem } from 'core/cart/models'
import { CheckoutRequestItem } from 'core/checkout/models'
import { BaseDelivery, Delivery } from 'core/delivery/models'
import { EventBillingAddress, EventPersonalInfo } from 'core/event/models'
import { Language } from 'core/language/models'
import { Store } from 'core/store/models'

type PersonalInfo = Pick<BaseDelivery, 'firstName' | 'lastName' | 'email' | 'phone' | 'companyName'>

export interface CheckoutPersonalInfo extends PersonalInfo {
    email: string
    confirmEmail: string
}

export interface OrderFormData {
    items: CheckoutRequestItem[]
    commentOfStore: Record<string, string>
    couponCode: string
    billingDeliveryId?: string
    deliveryId?: string
    personalInfo?: CheckoutPersonalInfo
    billInfo?: EventBillingAddress
    deliveryType?: number
    clientSecret: string | null
}
export type personalInfoItem = Required<Omit<BaseDelivery, 'type' | 'isDefault'>>
export type billInfoItem = Omit<personalInfoItem, 'email'>

export interface TouristOrderFormData {
    items: CheckoutRequestItem[]
    commentOfStore: Record<string, string>
    couponCode: string
    deliveryType: number
    personalInfo: personalInfoItem | EventPersonalInfo | undefined
    billInfo?: billInfoItem
    clientSecret: string | null
}

export enum OrderStatusEnum {
    // Payments
    UNPAY = 30, // Payment pending
    PAYING = 31, // Payment in progress
    PAID = 32, // To be shipped

    // Shipping
    DELIVERED = 61, // Shipped

    // End
    CLOSED = 90, // Closed
    CANCEL = 91, // Cancelled, active cancellation
    AUTO_CANCEL = 92, // Cancelled, timeout automatically cancelled
    FINISH = 99 // Completed
}

export interface Order {
    type: number
    code: string
    store: Store
    deliveryDetail: Omit<Delivery, 'id' | 'isDefault'>
    items: OrderItem[]
    status: OrderStatusEnum
    hasActions: boolean
    flow: Flow[]
    price: OrderPrice
    createTime: string
    payTime: string | null
    deliveryTime: string | null
    confirmTime: string | null
    cancelTime: string | null
    shipping: OrderShipping | null
    sellerComment: string
    userComment: string
    virtual: boolean
    couponCode: string
    billDeliveryDetail: Delivery
}

export interface Flow {
    status: OrderStatusEnum
    createTime: number
}

export interface OrderItem {
    type: number
    productId: string
    skuId: string
    quantity: number
    originUnitPrice: string
    originTotalPrice: string
    depositTotalPrice: string
    depositUnitPrice: string
    timeZone: string
    duration: string
    durations: string[]
    snapshot: {
        name: string
        image: string
        language: Language
        specText: string
    }
}

export interface OrderPrice {
    subTotal: string
    tax: string
    delivery: string
    discount: string
    total: string
    depositTotal: string
}

export interface OrderShipping {
    companyName: string
    code: string
}

export interface TipOption {
    value: number
    amount: string
    default: boolean
}

export enum STEPS {
    SHIPPING_DETAILS = 1,
    DELIVERY_METHOD = 2,
    BILLING_ADDRESS = 3,
    PAYMENT = 4
}

export interface OrderItemForSummary {
    type: number
    image: string
    name: string
    price: string
    spec?: SpecItem[]
    depositPrice?: string
    productId: string
    quantity: number
    timeZone?: string
    duration?: string
    durations?: string[]
    dayOrHour?: string
    endRentTime?: string
}

export interface PaymentSecrets {
    secretKey: string
    clientSecret: string
}
export interface ScanRentPaymentSuccess {
    accountId: number
    email: string
    password: string
    type: number
    endTime: string
}
