import { useCallback, useState } from 'react'

import { useMountedPromiseFn } from 'shared/hooks'
import { useCart } from 'shared/hooks'
import { getToken } from 'shared/config'
import { useCartList } from '../useCartList'

import { services } from 'core/cart/data-access'

export interface AddItemOptions {
    type: number
    productId: string
    quantity: number
    skuId?: string
    timeZone?: string
    duration?: string
}

export const useAddCartItem = () => {
    const { addCart } = useCart()
    const [loading, setLoading] = useState<boolean>(false)
    const {
        methods: { getList }
    } = useCartList()

    const addItemApi = useMountedPromiseFn(services.addItem)
    const addItemNoAuthApi = useMountedPromiseFn(services.checkCartNoAuth)
    const addItem = useCallback(
        ({ type, productId, quantity, skuId, timeZone, duration }: AddItemOptions) => {
            setLoading(true)
            return (
                getToken()
                    ? addItemApi(productId, quantity, skuId, timeZone, duration).then(() => {
                          setLoading(false)
                          getList()
                          return
                      })
                    : addItemNoAuthApi(productId, quantity, skuId, timeZone, duration).then(() => {
                          addCart({
                              type,
                              productId,
                              quantity,
                              skuId,
                              timeZone,
                              duration
                          })
                          getList()
                          setLoading(false)
                          return
                      })
            ).catch(err => {
                setLoading(false)
                return Promise.reject(err)
            })
        },
        [addCart, addItemApi, addItemNoAuthApi, getList]
    )

    return {
        loading,
        methods: {
            addItem
        }
    }
}
