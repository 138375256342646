import { Store } from 'core/store/models'

export interface Spec {
    attrId: string
    valueId: string
}

export interface BaseSku {
    id: string
    spec: Spec[]
    specText: string
    price: string | null
    depositPrice: string | null
    salesVolume: number
}

export interface Category {
    id: string
    parentId: string
    description: string
    displayOrder: number
    icon: string
    keywords: string
    name: string
}
export interface SkuListItem {
    stock: number
}
export interface ProductInList {
    id: string
    canDirectBuy: boolean
    image: string
    name: string
    showPrice: string
    description: string
    mobileDescription: string
    skuList: SkuListItem[]
}

export interface ProductCategoryList {
    id: string
    name: string
    products: ProductInList[]
}

export enum ProductStatusEnum {
    WAIT_ON_SALE,
    ON_SALE,
    OFF_SALE
}

export interface SkuItem extends BaseSku {
    specObj: Record<string, string>
    stock: number
    virtual: boolean
    warning: boolean
    images: string[]
    onceLimit: number
    preOrder: boolean
    isDeliveryFree: boolean
}

export interface ProductAttr {
    id: string
    name: string
    values: { id: string; name: string }[]
}

export type ProductHot = Pick<Product, 'id' | 'name' | 'images'> & { showPrice: string[]; keyWord: string }

export enum ProductTypeEnum {
    COMMON,
    RENTAL
}

export interface Product {
    id: string
    name: string
    store: Store
    category: Category
    showPrice: string
    depositPrice: string
    images: string[]
    isMultiSku: boolean
    isDeliveryFree: boolean
    mobileDescription: string
    description: string
    stock: number | null
    warning?: boolean
    salesVolume: number
    status: ProductStatusEnum
    skuList: SkuItem[] | null
    attrList: ProductAttr[] | null
    virtual: boolean
    purchaseRestrictions: boolean
    purchaseRestrictionsCount: number
    remainingQ: number
    type: ProductTypeEnum
    onceLimit: number
    preOrder: boolean
    descriptionType?: number

    // event product
    timeZone: string
    duration: string
    durations: Array<{ label: string; value: string }>
}

export type AttrMap = Record<string, Record<string, boolean>>

export const convertToAttrMap = (skuList: Pick<BaseSku, 'spec'>[]): AttrMap => {
    return skuList
        .map(item => item.spec)
        .reduce<Spec[]>((acc, curr) => [...acc, ...curr], [])
        .reduce<AttrMap>((acc, { attrId, valueId }) => {
            if (!acc[attrId]) acc[attrId] = {}
            acc[attrId][valueId] = true
            return acc
        }, {})
}
