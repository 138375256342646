import { map } from 'ramda'
import { clientHttp } from 'shared/request'
import { pickValue, pickValueAndReferences, SuccessItemResponse } from 'shared/request'
import { Language } from 'core/language/models'

import {
    CategoryReference,
    convertToEventProduct,
    convertToProduct,
    convertToProductInList,
    EventReference,
    ProductAttrReference,
    ProductDTO,
    ProductInListDTO
} from '../DTO'
import { pathJoin, convertToDollar } from 'shared/utils'
import { convertToImageUrl, getRootUrlPrefix } from 'shared/utils'
import { ProductHot } from 'core/product/models'

const URI = 'product'

export const getList = (language: Language) => {
    return clientHttp
        .get<SuccessItemResponse<ProductInListDTO[], CategoryReference>>(URI, { language })
        .then(pickValueAndReferences)
        .then(({ value, references }) => convertToProductInList(value, references))
}

export const getEventList = async (language: Language) => {
    const prefix = await getRootUrlPrefix()

    return clientHttp
        .get<SuccessItemResponse<ProductInListDTO[]>>(`${URI}/rent`, { language })
        .then(pickValue)
        .then(map(item => convertToEventProduct(item, prefix)))
}

export const getDetail = (produtId: string, language: Language, eventId?: string) => {
    return getRootUrlPrefix().then(prefix => {
        const url = pathJoin(URI, produtId)
        return clientHttp
            .get<SuccessItemResponse<ProductDTO, CategoryReference | ProductAttrReference | EventReference>>(
                url,
                {
                    language,
                    image_url_prefix: prefix,
                    eventId
                }
            )
            .then(pickValueAndReferences)
            .then(({ value, references }) => convertToProduct(value, references))
    })
}

export const getHotList = async (language: Language) => {
    const url = pathJoin(URI, 'hot')
    const prefix = await getRootUrlPrefix()
    return clientHttp
        .get<SuccessItemResponse<ProductHot[]>>(url, { language })
        .then(pickValue)
        .then(value =>
            value.map(item => {
                item.images = item.images.map(url => convertToImageUrl(prefix, url))
                item.showPrice[0] = convertToDollar(item.showPrice[0])
                return item
            })
        )
}
