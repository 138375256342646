import React, { FC } from 'react'
import { Element } from 'react-scroll'
import styled from '@emotion/styled'

import { useApiError, useMemoizedFn, useSetState, useShowMessage } from 'shared/hooks'
import { useContactUs } from 'core/homepage/api/useContactUs'

import { Input } from 'antd'
import { Button, Paragraph, SectionTitle } from 'components'

import type { ContactUsFormData } from 'core/homepage/models'

export const ContactUs: FC = () => {
    const [formData, setFormData] = useSetState<ContactUsFormData>({} as ContactUsFormData)
    const {
        methods: { contactUs }
    } = useContactUs()
    const {
        methods: { error, success }
    } = useShowMessage()
    const {
        methods: { handleError }
    } = useApiError('ContactUs')

    const handleSubmit = useMemoizedFn(() => {
        if (!formData.email) {
            error('Please input your email')
            return
        }
        if (!formData.msg) {
            error('Please input message')
            return
        }
        contactUs(formData)
            .then(() => {
                success('Thanks for Submiting!')
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    msg: ''
                })
            })
            .catch(handleError)
    })

    return (
        <>
            <Element name="myScrollToElement" />
            <Wrapper className="px-28 pt-32 md:pt-40 flex flex-wrap lg:justify-between">
                <div className="flex basis-full lg:basis-2/5 lg:pr-16">
                    <div className="mb-24 w-full pr-16">
                        <SectionTitle title="Contact Us" />
                        <div className="my-12">
                            <Paragraph>
                                Thanks for your interest in MetaSill Frame. If you have any questions or
                                concerns please contact us.
                            </Paragraph>
                        </div>
                        <Paragraph>Email: contact@metasill.io</Paragraph>
                    </div>
                </div>
                <div className="flex basis-full lg:basis-1/2 lg:pt-8">
                    <form className="w-full">
                        <div className="flex flex-wrap">
                            <div className="basis-full mb-12 md:basis-1/2 md:pr-4">
                                <FormLabel>First Name</FormLabel>
                                <Input
                                    type="text"
                                    name="firstname"
                                    value={formData.firstName}
                                    placeholder=" "
                                    maxLength={100}
                                    onChange={({ target: { value } }) => setFormData({ firstName: value })}
                                />
                            </div>
                            <div className="basis-full mb-12 md:basis-1/2 md:pl-4">
                                <FormLabel>Last Name</FormLabel>
                                <Input
                                    type="text"
                                    name="lastname"
                                    value={formData.lastName}
                                    placeholder=""
                                    maxLength={100}
                                    onChange={({ target: { value } }) => setFormData({ lastName: value })}
                                />
                            </div>
                        </div>
                        <div className="mb-12">
                            <FormLabel>Email *</FormLabel>
                            <Input
                                type="email"
                                name="email"
                                value={formData.email}
                                placeholder=" "
                                required
                                pattern="^.+@.+\.[a-zA-Z]{2,63}$"
                                maxLength={250}
                                onChange={({ target: { value } }) => setFormData({ email: value })}
                            />
                        </div>
                        <div className="mb-24">
                            <FormLabel>Message *</FormLabel>
                            <Input.TextArea
                                rows={8}
                                value={formData.msg}
                                placeholder=""
                                onChange={({ target: { value } }) => setFormData({ msg: value })}
                            />
                        </div>
                        <div className="flex items-center justify-between mb-32 md:mb-48">
                            <span className="text-3xl" />
                            <Button onClick={() => handleSubmit()}>
                                <span>Submit</span>
                            </Button>
                        </div>
                    </form>
                </div>
            </Wrapper>
        </>
    )
}

const Wrapper = styled.div``

const FormLabel = styled.div`
    margin-bottom: 10px;
    font-family: HelveticaNeue;
    font-size: 18px;
    color: #000;
    line-height: 26px;
`
