import React, { FC } from 'react'
import styled from '@emotion/styled'

import backIcon from '../../assets/back-icon.png'

interface PageHeaderProps {
    className?: string
    title?: React.ReactNode | string
    right?: React.ReactNode
    border?: boolean
    onBack?: () => void
}

export const PageHeader: FC<PageHeaderProps> = ({ className, title, right, border = true, onBack }) => {
    return (
        <Wrapper className={className} border={border}>
            <h2 onClick={() => onBack && onBack()}>
                {onBack && <img src={backIcon} alt="" />} {title || 'Address Create'}
            </h2>
            <div className="right">{right}</div>
        </Wrapper>
    )
}

const Wrapper = styled.div<{ border: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 6.8rem;
    border-bottom: ${({ border }) => (border ? '1px solid rgba(47, 46, 46, 1)' : 'none')};
    h2 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 0;
        font-family: Roboto-Medium;
        font-size: 24px;
        color: #000000;
        letter-spacing: 0;
        line-height: 28px;
        font-weight: 500;
        cursor: pointer;
        img {
            width: 30px;
            height: 30px;
            margin-right: 10px;
        }
    }

    @media (max-width: 576px) {
        height: 68px;
        h2 {
            img {
                width: 20px;
                height: 20px;
                margin-right: 6px;
            }
        }
    }
`
