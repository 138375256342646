import { pathJoin } from 'shared/utils'
import { pickValueAndReferences, SuccessItemResponse } from 'shared/request'
import { clientHttp } from 'shared/request'
import { Language } from 'core/language/models'
import { CartItemDTO, convertToCartItem, StoreReference, convertToCartItemNoAuth } from '../DTO'

import { CartCheckItem } from 'core/cart/models'

const URI = 'cart'

export const getList = (language: Language) => {
    return clientHttp
        .get<SuccessItemResponse<{ items: CartItemDTO[] }, StoreReference>>(URI, { language })
        .then(pickValueAndReferences)
        .then(({ value: { items }, references }) => convertToCartItem(items, references))
}

export const getListNoAuth = (language: Language, checkList: CartCheckItem[]) => {
    const url = pathJoin(URI, 'product', 'list')
    return clientHttp
        .post<SuccessItemResponse<{ items: CartItemDTO[] }, StoreReference>>(`${url}?language=${language}`, {
            items: checkList
        })
        .then(pickValueAndReferences)
        .then(({ value: { items }, references }) => convertToCartItem(items, references))
        .then(({ cartList, ineffectiveList }) => convertToCartItemNoAuth(cartList, ineffectiveList))
}

export const addItem = (
    productId: string,
    quantity: number,
    skuId?: string,
    timeZone?: string,
    duration?: string
) => {
    const url = pathJoin(URI, 'item')
    return clientHttp.post<SuccessItemResponse<void>>(url, {
        productId,
        quantity,
        skuId,
        timeZone: timeZone || '',
        duration: duration || ''
    })
}

export const checkCartNoAuth = (
    productId: string,
    quantity: number,
    skuId?: string,
    timeZone?: string,
    duration?: string
) => {
    const url = pathJoin(URI, 'item', 'check')
    return clientHttp.post<SuccessItemResponse<void>>(url, {
        productId,
        quantity,
        skuId,
        timeZone: timeZone || '',
        duration: duration || ''
    })
}

export const deleteItem = (code: string) => {
    const url = pathJoin(URI, 'item', code)
    return clientHttp.delete<SuccessItemResponse<void>>(url)
}

export const addQuantity = (code: string, value: number) => {
    const url = pathJoin(URI, 'item', code, 'quantity')
    return clientHttp.put<SuccessItemResponse<void>>(url, { method: 1, value })
}

export const reduceQuantity = (code: string, value: number) => {
    const url = pathJoin(URI, 'item', code, 'quantity')
    return clientHttp.put<SuccessItemResponse<void>>(url, { method: -1, value })
}

export const updateQuantity = (code: string, value: number) => {
    const url = pathJoin(URI, 'item', code, 'quantity')
    return clientHttp.put<SuccessItemResponse<void>>(url, {
        method: value >= 0 ? 1 : -1,
        value: Math.abs(value)
    })
}

export const editItem = (code: string, value: { skuId?: string; quantity?: number }) => {
    const url = pathJoin(URI, 'item', code)
    return clientHttp.put<SuccessItemResponse<void>>(url, value)
}

export const batchDeleteItem = (codes: string[]) => {
    const url = pathJoin(URI, 'item')
    return clientHttp.delete<SuccessItemResponse<void>>(`${url}?codes=${codes.join(',')}`)
}

export const syncData = (items: CartCheckItem[]) => {
    const url = pathJoin(URI, 'items', 'add')
    return clientHttp.post<SuccessItemResponse<void>>(`${url}`, { items })
}
