import { useMemoizedFn, useMountedPromiseFn, useListState } from 'shared/hooks'
import { services } from '../data-access'
import { AuthAddressItem } from '../models'

export const useAuthAddressList = () => {
    const [state, setState] = useListState<AuthAddressItem>()

    const getAddressListdApi = useMountedPromiseFn(services.getAddressList)
    const getAddressList = useMemoizedFn(() => {
        setState({ loading: true })
        return getAddressListdApi()
            .then(list => {
                setState({ loading: false, list })
            })
            .catch(err => {
                setState({ loading: false })
                return Promise.reject(err)
            })
    })

    return {
        ...state,
        methods: { getAddressList }
    }
}
