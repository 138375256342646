import React, { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import styled from '@emotion/styled'

import { Management } from './index'
import { UploadDetail } from './nft/UploadDetail'
import { UploadForm } from './nft/UploadForm'
import { CollectionDetail } from './nft/components/CollectionDetail'
import { FrameDetail } from './frame/Detail'
import { SelectNft } from './frame/SelectNft'
import { SelectPlaylist } from './frame/SelectPlaylist'
import { PlaylistDelete } from './frame/PlaylistDelete'
// import { SelectPlaylistNft } from './frame/components/SelectPlaylistNft'
import { AuthorizedDelete } from './frame/AuthorizedDelete'

export const ManagementShell: FC = () => (
    <Container>
        <Routes>
            <Route path="/" element={<Navigate to="/management/collections" />} />
            <Route path="/:type" element={<Management />} />
            <Route path="/nft/upload" element={<UploadForm />} />
            <Route path="/nft/:id/upload" element={<UploadForm />} />
            <Route path="/nft/:id/edit" element={<UploadForm />} />
            <Route path="/nft/:id" element={<UploadDetail />} />
            <Route path="/collection/:id" element={<CollectionDetail />} />
            <Route path="/frames/:id" element={<FrameDetail />} />
            <Route path="/frames/:id/rent/:rent_id" element={<FrameDetail />} />
            <Route path="/frames/delete" element={<AuthorizedDelete />} />
            <Route path="/frames/:id/select/nft" element={<SelectNft />} />
            <Route path="/frames/:id/select/playlist" element={<SelectPlaylist />} />
            <Route path="/frames/:id/playlist/delete" element={<PlaylistDelete />} />
            {/* <Route path="/frames/:id/select/playlist_nft" element={<SelectPlaylistNft />} /> */}
        </Routes>
    </Container>
)

const Container = styled.div`
    margin: 0 auto;
    max-width: 1296px;
    min-height: calc(100vh - 68px - 90px);
`
