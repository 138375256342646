import React, { FC, useMemo } from 'react'

import styled from '@emotion/styled'

import { Text, TextProps } from '@mantine/core'
import { Checkbox } from '@mantine/core'
import { Image, Radio } from '@mantine/core'

import type { MyCollectionList, NftInList } from 'core/management/nft/models'
import { useResponsive } from 'shared/hooks'

import defaultImg from 'assets/default.png'

export enum NftListItemEnum {
    NFT = 'nft',
    PLAYLIST = 'playList'
}

interface NftListItemProps {
    type: NftListItemEnum
    item: MyCollectionList | NftInList
    pageType?: string
}

export const NftListItem: FC<NftListItemProps> = ({ type, item, pageType }) => {
    const { md } = useResponsive()
    const labelRenderer = useMemo(() => {
        return (
            <>
                <Image
                    width={md ? 200 : 160}
                    height={md ? 200 : 160}
                    src={
                        (item as MyCollectionList)?.imgUrl
                            ? (item as MyCollectionList).imgUrl
                            : (item as NftInList).thumbUrl
                    }
                    alt="nft"
                    withPlaceholder
                    fit="contain"
                    placeholder={
                        <img src={defaultImg} alt="NFT" width={md ? 200 : 160} height={md ? 200 : 160} />
                    }
                    styles={{
                        imageWrapper: {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }
                    }}
                />
                <ListItemName>
                    <Name lineClamp={2}>{item.name}</Name>
                </ListItemName>
            </>
        )
    }, [item, md])

    return (
        <ListItem>
            {type === NftListItemEnum.PLAYLIST ? (
                <Checkbox
                    color="dark"
                    className="radio"
                    size="lg"
                    value={JSON.stringify({ ...item, pageType: pageType })}
                    styles={{
                        inner: {
                            width: '30px',
                            height: '30px',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            zIndex: 2
                        },
                        input: { width: '30px', height: '30px' },
                        icon: { width: '16px', height: '12px' },
                        body: {
                            position: 'relative'
                        },
                        label: {
                            padding: 0,
                            cursor: 'pointer'
                        }
                    }}
                    label={labelRenderer}
                />
            ) : (
                <Radio
                    color="dark"
                    size="lg"
                    name="listRadio"
                    className="radio"
                    value={`${item.id}`}
                    label={labelRenderer}
                    styles={{
                        body: {
                            position: 'relative'
                        },
                        label: {
                            padding: 0,
                            cursor: 'pointer'
                        },
                        inner: {
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            zIndex: 2
                        }
                    }}
                />
            )}
        </ListItem>
    )
}
const ListItemName = styled.div`
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
        height: 53px;
    }
`
const Name = styled(Text)<TextProps>`
    max-width: 200px;
    font-family: OpenSans-SemiBold;
    font-size: 16px;
    color: #000000;
    line-height: 20px;
    height: 54px;
    padding: 13px 6px;
    letter-spacing: 0;
    overflow: hidden;
    @media (max-width: 768px) {
        max-width: 160px;
        padding: 10px 6px;
        font-family: PingFangSC-Regular;
        font-size: 13px;
        line-height: 16px;
        height: 44px;
    }
`

const ListItem = styled.div`
    flex-shrink: 0;
    width: 200px;
    height: 266px;
    margin-top: 40px;
    margin-right: 58px;
    box-shadow: 0px 5px 11px 0px rgba(222, 222, 222, 0.5);
    .radio {
        width: 16px;
        height: 16px;
        margin-right: 0px !important;
        z-index: 2;
    }
    @media (max-width: 768px) {
        width: 160px;
        height: 213px;
        margin-top: 0;
        margin-bottom: 4px;
        box-shadow: 0px 6px 13px 0px rgba(222, 222, 222, 0.5);
    }
`
