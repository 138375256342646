import dayjs from 'dayjs'
import moment, { Moment } from 'moment-timezone'

export const isBeforeToday = (value: number) => dayjs(value).isBefore(dayjs().startOf('day'))

export const convertDateFromTimestamp = (timestamp: number | string) =>
    dayjs(timestamp).format('MM-DD-YYYY HH:mm:ss')

export const convertTimestampToDateWithTz = (timestamp: number | string, timeZone: string) =>
    moment(+timestamp)
        .tz(timeZone)
        .format('ddd, MMM DD, YYYY')

export const convertDateToTimestampWithTz = (time: Moment, timezone: string) =>
    +moment.tz(time, timezone).format('x')

export const convertToTimeZoneString = (timeZone: string) => timeZone

export const isDST = (timestamp: number | string, timeZone: string) => {
    const offect = +(moment(timestamp).tz(timeZone).utcOffset() / 60)
    return timeZone === 'America/New_York'
        ? offect === -5
            ? true
            : false
        : timeZone === 'America/Denver'
        ? offect === -7
            ? true
            : false
        : timeZone === 'America/Chicago'
        ? offect === -6
            ? true
            : false
        : timeZone === 'America/Los_Angeles'
        ? offect === -8
            ? true
            : false
        : null
}

export const convertToDurations = (duration: string) => duration.split(',')
