import React, { FC, ReactNode } from 'react'
import styled from '@emotion/styled'
import { NO_RETURNS_ID_LIST } from 'shared/config'

interface Props {
    image: string
    name: string
    showPrice: string
    isRent?: boolean
    className?: string
    footer?: ReactNode
    onClick?: () => void
    id: string
}

export const ShopProductItem: FC<Props> = ({
    image,
    name,
    showPrice,
    isRent = false,
    className,
    footer,
    id,
    onClick
}) => {
    return (
        <Container className={className}>
            <div className="img" style={{ backgroundImage: `url(${image})` }} onClick={onClick} />
            <div className="info">
                <div className="infoTitle">
                    <div className="text">{name}</div>
                </div>
                {NO_RETURNS_ID_LIST.includes(+id) ? (
                    <ReturnsText className="text">Final sale. No returns or exchanges.</ReturnsText>
                ) : null}
                <div className="price">
                    <span>$</span>
                    <strong>{showPrice}</strong>
                    {isRent && <small></small>}
                </div>
                {footer}
            </div>
        </Container>
    )
}

const ReturnsText = styled.div`
    margin-bottom: 14px;
    font-family: HelveticaNeue-Medium;
    font-size: 15px;
    line-height: 26px;
    color: #2f2e2e;
    letter-spacing: 0;
    font-weight: 500;
    text-align: center;
    @media (max-width: 576px) {
        font-size: 13px;
    }
`

const Container = styled.div`
    .img {
        width: 100%;
        height: 0;
        padding-top: 100%;
        margin-bottom: 30px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        cursor: pointer;
        @media (max-width: 576px) {
            margin-bottom: 10px;
        }
    }
    .info {
        padding: 0 5px;
    }
    .infoTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        /* height: 52px; */
        margin-bottom: 14px;
        font-family: HelveticaNeue-Medium;
        font-size: 17px;
        line-height: 26px;
        color: #2f2e2e;
        letter-spacing: 0;
        font-weight: 500;
        .text {
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        @media (max-width: 576px) {
            font-family: HelveticaNeue-CondensedBold;
            color: #000;
            height: 40px;
            font-size: 15px;
            line-height: 20px;
            margin-bottom: 6px;
        }
    }
    .price {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
        font-family: HelveticaNeue-Bold;
        font-size: 3rem;
        color: #2f2e2e;
        letter-spacing: 0;
        text-align: center;
        line-height: 3.2rem;
        span {
            display: block;
            margin-right: 5px;
            font-size: 2rem;
            line-height: 2.8rem;
        }
        small {
            font-family: HelveticaNeue-Medium;
            font-size: 1.7rem;
            color: #2f2e2e;
            letter-spacing: 0;
            line-height: 2.6rem;
            margin-left: 1rem;
        }
        @media (max-width: 1440px) {
            margin-bottom: 2rem;
            font-size: 2.4rem;
            line-height: 3.2rem;
            span {
                font-size: 1.8rem;
                line-height: 2rem;
            }
            small {
                font-size: 1.4rem;
                line-height: 2rem;
            }
        }
        @media (max-width: 576px) {
            height: 30px;
            margin-bottom: 14px;
            font-size: 14px;
            line-height: 20px;
            span {
                font-size: 12px;
                line-height: 15px;
            }
            small {
                font-size: 12px;
                line-height: 15px;
            }
        }
    }
    @media (max-width: 576px) {
        width: 100%;
        padding-right: 0;
        margin-bottom: 3rem;
        .info {
            padding: 0 5px 15px;
        }
    }
`
