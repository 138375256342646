import React, { FC, useEffect } from 'react'
import styled from '@emotion/styled'
import { useParams } from 'react-router-dom'

import { useEventDetail } from 'core/event/api'
import { useI18n } from 'core/language/api'
import { useApiError } from 'shared/hooks'

import { Spin } from 'components'
import { EventProductList } from './components/productList'
import { CarouselPage } from './components/carousel'

import bannerProducts from './assets/banner_products.png'

export const EventProducts: FC = () => {
    const { lang } = useI18n()

    const { id } = useParams<{ id: string }>()

    const {
        methods: { handleError }
    } = useApiError('FeatureEventDetail')

    const {
        loading,
        detail,
        methods: { getDetail }
    } = useEventDetail(lang)

    useEffect(() => {
        id && getDetail(id).catch(handleError)
    }, [id, getDetail, handleError])

    return (
        <Spin spinning={loading}>
            {detail && (
                <Container>
                    <ProductHeader>
                        <BannerImg src={bannerProducts} alt="" />
                        <CarouselPage list={detail.images.split(',')} />
                    </ProductHeader>
                    <div className="eventProductListbox">
                        <EventProductList
                            productId={`${detail.id}`}
                            list={detail?.productList.map((item, index) => {
                                return {
                                    ...item,
                                    description: detail?.productRefList[index].description
                                }
                            })}
                            productButtonName={detail?.productButtonName}
                            titleSize={detail?.titleSize}
                            isEventOff={detail?.isOff}
                        />
                    </div>
                </Container>
            )}
        </Spin>
    )
}
const Container = styled.div`
    width: 100%;
    min-height: calc(100vh - 68px - 90px);
    padding: 0;
    display: flex;
    flex-direction: column;
    @media (max-width: 576px) {
        width: 100%;
    }
    .eventProductListbox {
        flex: 1;
        background-color: #2f3441;
    }
`
const ProductHeader = styled.div`
    width: 100%;
    height: 310px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 576px) {
        height: 300px;
        padding-left: 0;
    }
`
const BannerImg = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`
