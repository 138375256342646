import React, { FC, useCallback, ReactNode, useMemo } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'

import { useI18n } from 'core/language/api'
import { TAB_TYPE_ENUM } from 'core/aff/models'
import {
    ADMIN_LINK_DEVICE,
    ADMIN_LINK_NFT,
    ADMIN_LINK_STORE,
    ADMIN_LINK_AD,
    ADMIN_LINK_SPONSOR,
    getIsGoogleUser,
    getIsTourist,
    getToken,
    removeTouristAddress
} from 'shared/config'
import { useResponsive, useSignInRedirect, useUserInfo } from 'shared/hooks'

import { Modal } from 'antd'

import mineIcon from '../assets/mine.png'
import bannerIcon from '../assets/banner.jpg'

interface Props {
    userName: string
    status: number
    children?: ReactNode | undefined
}

export const PersonalCenter: FC<Props> = ({ userName, status, children }) => {
    const { md } = useResponsive()
    const {
        methods: { t }
    } = useI18n()
    const navigate = useNavigate()
    const { type } = useParams<{ type: TAB_TYPE_ENUM }>()

    const { loginOut } = useSignInRedirect()
    const canView = useMemo(() => getToken() && !getIsTourist(), [])

    const handleOutLogin = useCallback(() => {
        removeTouristAddress()
        Modal.confirm({
            title: <div className="logout-tips-content">{t('client.me.isLogout')}</div>,
            okText: t('form.actions.OK'),
            cancelText: t('form.actions.cancel'),
            onOk: () => {
                loginOut('/')
            }
        })
    }, [loginOut, t])

    const userInfo = useUserInfo()

    const canViewAdminButton = useMemo(
        () =>
            userInfo?.storeAdmin ||
            userInfo?.role.nftSuperAdmin ||
            userInfo?.role.deviceAdmin ||
            userInfo?.role.adAdmin ||
            userInfo?.role.sponsorAdmin ||
            (userInfo?.sponsorList && userInfo.sponsorList.length > 0),
        [userInfo]
    )
    const adminLink = useMemo(() => {
        if (userInfo?.storeAdmin) return ADMIN_LINK_STORE
        if (userInfo?.role.nftSuperAdmin) return ADMIN_LINK_NFT
        if (userInfo?.role.deviceAdmin) return ADMIN_LINK_DEVICE
        if (userInfo?.role.adAdmin) return ADMIN_LINK_AD
        if (userInfo?.role.sponsorAdmin || (userInfo?.sponsorList && userInfo.sponsorList.length > 0))
            return ADMIN_LINK_SPONSOR
    }, [userInfo])

    return (
        <Personal>
            {md && (
                <>
                    <div className="banner">
                        <img src={bannerIcon} alt="banner" />
                    </div>
                    <div className="tab">
                        <div className="user-info">
                            <div className="phone rounded-full bg-center bg-no-repeat mx-auto" />
                            {status === 2 ? (
                                <div className="binding text-center text-3xl font-bold">{t('Guest')}</div>
                            ) : (
                                <div className="user-name text-center">{userName}</div>
                            )}
                            {}
                            {canViewAdminButton && (
                                <AdminButtonWrapper>
                                    <AdminButton onClick={() => window.open(adminLink)}>Admin</AdminButton>
                                </AdminButtonWrapper>
                            )}
                            <div className="tabList">
                                <div
                                    className={`item ${
                                        type === TAB_TYPE_ENUM.AFFILIATE_LINK ? 'active' : ''
                                    }`}
                                    onClick={() => navigate(`/mine/${TAB_TYPE_ENUM.AFFILIATE_LINK}`)}
                                >
                                    Affiliate link
                                </div>
                                {canView && (
                                    <div
                                        className={`item ${
                                            type === TAB_TYPE_ENUM.WALLETADDRESS ? 'active' : ''
                                        }`}
                                        onClick={() => navigate(`/mine/${TAB_TYPE_ENUM.WALLETADDRESS}`)}
                                    >
                                        Wallet
                                    </div>
                                )}
                                <div
                                    className={`item ${type === TAB_TYPE_ENUM.ORDERS ? 'active' : ''}`}
                                    onClick={() => navigate(`/mine/${TAB_TYPE_ENUM.ORDERS}`)}
                                >
                                    Order
                                </div>
                                {!getIsTourist() && (
                                    <div
                                        className={`item ${type === TAB_TYPE_ENUM.ADDRESS ? 'active' : ''}`}
                                        onClick={() => navigate(`/mine/${TAB_TYPE_ENUM.ADDRESS}`)}
                                    >
                                        Address
                                    </div>
                                )}
                                {/* {status !== 2 && !getIsGoogleUser() && (
                                    <div
                                        className={`item ${type === TAB_TYPE_ENUM.EMAIL ? 'active' : ''}`}
                                        onClick={() => navigate(`/mine/${TAB_TYPE_ENUM.EMAIL}`)}
                                    >
                                        Email
                                    </div>
                                )} */}
                                {status !== 2 && (
                                    <div
                                        className={`item ${type === TAB_TYPE_ENUM.PHONE ? 'active' : ''}`}
                                        onClick={() => navigate(`/mine/${TAB_TYPE_ENUM.PHONE}`)}
                                    >
                                        Phone
                                    </div>
                                )}
                                {status !== 2 && !getIsGoogleUser() && (
                                    <div
                                        className={`item ${type === TAB_TYPE_ENUM.PASSWORD ? 'active' : ''}`}
                                        onClick={() => navigate(`/mine/${TAB_TYPE_ENUM.PASSWORD}`)}
                                    >
                                        Password
                                    </div>
                                )}

                                <div
                                    className="item"
                                    style={{ textDecoration: 'underline' }}
                                    onClick={handleOutLogin}
                                >
                                    Logout
                                </div>
                            </div>
                        </div>
                        <div className="children">{children}</div>
                    </div>
                </>
            )}
            {!md && <div className="children">{children}</div>}
        </Personal>
    )
}

const AdminButton = styled.div`
    width: 100px;
    border: 1px solid rgba(0, 0, 0, 1);
    border-radius: 22px;
    font-family: Roboto-Medium;
    font-size: 16px;
    color: #000000;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
`

const AdminButtonWrapper = styled.div`
    margin-bottom: 30px;
`

const PersonalDef = styled.div``

const Personal = styled(PersonalDef)`
    position: relative;
    box-sizing: border-box;
    padding-top: 50px;
    .banner {
        position: relative;
        z-index: 1;
    }
    .tab {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .user-info {
        width: 300px;
        position: relative;
        z-index: 2;
        transform: translateY(-5rem);
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .phone {
        width: 10rem;
        height: 10rem;
        background-color: #ebebeb;
        background-image: url('${mineIcon}');
        background-size: 6rem auto;
    }
    .user-name {
        font-family: Roboto-Medium;
        font-size: 18px;
        color: #000;
        letter-spacing: 0;
        line-height: 20px;
        font-weight: 500;
        margin: 20px 0 30px 0;
    }
    .login-out {
        display: inline-block;
        font-family: Roboto-Medium;
        font-size: 16px;
        color: #000;
        letter-spacing: 0;
        text-align: center;
        line-height: 20px;
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;
    }
    .binding {
        margin: 20px 0 30px 0;
    }
    .children {
        flex: 1;
        padding-left: 11rem;
        width: 997px;
    }
    .tabList {
        .item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 300px;
            height: 48px;
            margin-bottom: 14px;
            font-family: Roboto-Medium;
            font-size: 16px;
            color: #000000;
            cursor: pointer;
        }
        .item.active {
            background: #000;
            color: #fff;
        }
    }
    @media (max-width: 768px) {
        width: 100%;
        padding-top: 0;
        .children {
            padding-left: 0;
            width: 100%;
            min-width: auto;
        }
    }
`
