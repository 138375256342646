import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import * as fromCart from 'core/cart/state'
import * as fromLanguage from 'core/language/state'
import * as fromCheckout from 'core/checkout/state'
import * as fromAuth from 'core/auth/state'
import * as fromDelivery from 'core/delivery/state'
import * as fromCoupon from 'core/coupon/state'
import * as fromOrder from 'core/order/state'
import * as fromNft from 'core/management/nft/state'

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export interface RootState {
    [fromLanguage.FEATURE_NAME]: fromLanguage.State
    [fromAuth.FEATURE_NAME]: fromAuth.State
    [fromDelivery.FEATURE_NAME]: fromDelivery.State
    [fromCheckout.FEATURE_NAME]: fromCheckout.State
    [fromCart.FEATURE_NAME]: fromCart.State
    [fromCoupon.FEATURE_NAME]: fromCoupon.State
    [fromOrder.FEATURE_NAME]: fromOrder.State
    [fromNft.FEATURE_NAME]: fromNft.State
}

const reducers = combineReducers<RootState>({
    [fromLanguage.FEATURE_NAME]: fromLanguage.reducer,
    [fromAuth.FEATURE_NAME]: fromAuth.reducer,
    [fromDelivery.FEATURE_NAME]: fromDelivery.reducer,
    [fromCheckout.FEATURE_NAME]: fromCheckout.reducer,
    [fromCart.FEATURE_NAME]: fromCart.reducer,
    [fromCoupon.FEATURE_NAME]: fromCoupon.reducer,
    [fromOrder.FEATURE_NAME]: fromOrder.reducer,
    [fromNft.FEATURE_NAME]: fromNft.reducer
})

export const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)))
