import React, { FC, useEffect, useState } from 'react'
import { assocPath, dissocPath } from 'ramda'
import styled from '@emotion/styled'

import type { ProductInList } from 'core/product/models'
import { useMemoizedFn, useResponsive } from 'shared/hooks'

import { Modal } from 'antd'
import { EventProduct } from '../EventProduct'

import type { EventProductState } from 'core/event/models'

const defaultProductState: EventProductState = {
    productId: '',
    isDaily: false,
    checked: false,
    descriptionList: []
}

interface EventProductModalProps {
    visible: boolean
    list?: ProductInList[]
    defaultProductList: EventProductState[]
    onSave: (productList: EventProductState[]) => void
    setVisible: (visible: boolean) => void
}

export const EventProductModal: FC<EventProductModalProps> = ({
    visible,
    list,
    defaultProductList,
    onSave,
    setVisible
}) => {
    const { md } = useResponsive()

    const [productList, setProductList] = useState<EventProductState[]>(
        defaultProductList.map(item => ({ ...item, checked: true }))
    )

    useEffect(() => {
        setProductList(defaultProductList.map(item => ({ ...item, checked: true })))
    }, [defaultProductList])

    const findProductIndex = useMemoizedFn((productId: string) =>
        productList.findIndex(item => item.productId === productId)
    )

    const handleRadioChange = useMemoizedFn((productId: string) => {
        setProductList(prevData => {
            let newData = [...prevData]
            const productIdx = findProductIndex(productId)
            if (productIdx !== -1) {
                return assocPath([productIdx, 'isDaily'], !newData[productIdx].isDaily, newData)
            }
            newData.push({ ...defaultProductState, productId, isDaily: true })
            return newData
        })
    })

    const handleCheckProduct = useMemoizedFn((productId: string) =>
        setProductList(prevData => {
            const productIdx = findProductIndex(productId)
            if (productIdx !== -1) {
                return assocPath([productIdx, 'checked'], !prevData[productIdx].checked, prevData)
            }
            const newData = [...prevData]
            newData.push({ ...defaultProductState, productId, checked: true, descriptionList: [''] })

            return newData
        })
    )

    const handleAddDescription = useMemoizedFn((productId: string) => {
        setProductList(prevData => {
            const productIdx = findProductIndex(productId)
            if (prevData[productIdx]) {
                return assocPath(
                    [productIdx, 'descriptionList'],
                    [...prevData[productIdx].descriptionList, ''],
                    prevData
                )
            }
            const newData = [...prevData]
            newData.push({ ...defaultProductState, productId, checked: true, descriptionList: [''] })
            return newData
        })
    })

    const handleRemoveDescription = useMemoizedFn((index: number, productId: string) =>
        setProductList(() => {
            const productIdx = findProductIndex(productId)

            if (productList[productIdx].descriptionList.length === 1) {
                return dissocPath([productIdx], productList)
            }

            return dissocPath([productIdx, 'descriptionList', index], productList)
        })
    )

    const handleChangeDescription = useMemoizedFn((value: string, index: number, productId: string) =>
        setProductList(() => {
            const productIdx = findProductIndex(productId)
            return assocPath([productIdx, 'descriptionList', index], value, productList)
        })
    )

    return (
        <Modal
            width={md ? '72%' : '100%'}
            open={visible}
            footer={null}
            onCancel={() => setVisible(false)}
            style={{ maxWidth: '100%', margin: md ? '0 auto' : 0, top: md ? 100 : 56 }}
        >
            <ModalWrapper md={md}>
                <ModalTitle>Event Product</ModalTitle>
                <div>
                    {list?.map(item => (
                        <EventProduct
                            key={item.id}
                            formData={productList?.find(p => p.productId === item.id)!}
                            productId={item.id}
                            image={item.image}
                            name={item.name}
                            showPrice={item.showPrice}
                            onDescriptionAdd={handleAddDescription}
                            onDescriptionRemove={handleRemoveDescription}
                            onDescriptionChange={handleChangeDescription}
                            onRadioChange={handleRadioChange}
                            onCheckBoxChange={handleCheckProduct}
                        />
                    ))}
                </div>
                <ModalButton
                    className="mx-auto text-center cursor-pointer text-white"
                    onClick={() => onSave(productList)}
                >
                    OK
                </ModalButton>
            </ModalWrapper>
        </Modal>
    )
}

const ModalButton = styled.div`
    margin-top: 72px;
    background: #000;
    border-radius: 36px;
    width: 200px;
    height: 50px;
    line-height: 50px;
    font-family: Roboto-Medium;
    font-size: 25px;
`

const ModalTitle = styled.div`
    font-family: Roboto-Medium;
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
`

const ModalWrapper = styled.div<{ md: boolean }>`
    padding: ${({ md }) => (md ? '0px 48px 12px 48px' : '0')};

    .productList::-webkit-scrollbar {
        display: none; /* Chrome Safari */
    }
`
