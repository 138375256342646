import { useUrlParams } from './useUrlParams'
import { setShareCode, getToken } from 'shared/config'
import { useAff } from 'core/aff/api'
import { useEffect } from 'react'

export const useSaveShareCode = () => {
    const { params } = useUrlParams({
        defaultValues: { aff: '' }
    })
    const {
        methods: { readShare }
    } = useAff()
    useEffect(() => {
        if (params.aff) {
            !getToken() && readShare(params.aff)
            setShareCode(params.aff)
        }
    }, [params.aff, readShare])
    // useMount(() => {
    //     if (params.refer) {
    //         readShare(params.refer)
    //         setShareCode(params.refer)
    //     }
    // })
}
