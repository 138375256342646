import { on, reducer as createReducer } from 'ts-action'
import {
    setList,
    setCurrent,
    setBillingId,
    clearDeliveryIdAndBillingId,
    setUnpaid,
    setBillingList
} from './actions'

import { FEATURE_NAME } from './constants'
import { assoc } from 'ramda'

import { Delivery } from 'core/delivery/models'

import { actions as authActions } from 'core/auth/state'

export interface State {
    list: Delivery[]
    billingList: Delivery[]
    currentId?: string
    billingId?: string
    unpaid?: {
        billDeliveryDetail: Delivery
        deliveryDetail: Delivery
    }
}

export interface GlobalState {
    [FEATURE_NAME]: State
}

export const initialState: State = {
    list: [],
    billingList: []
}

export const reducer = createReducer(
    initialState,
    on(setList, (state, { payload: { list } }) => {
        let currentId = state.currentId
        if (currentId) {
            const exists = !!list.find(({ id }) => id === currentId)
            if (!exists) {
                currentId = list.find(({ isDefault }) => isDefault)?.id || list[0]?.id
            }
        } else {
            currentId = list.find(({ isDefault }) => isDefault)?.id || list[0]?.id
        }
        return {
            ...state,
            list,
            currentId
        }
    }),
    on(setBillingList, (state, { payload: { list } }) => ({
        ...state,
        billingList: list
    })),
    on(setCurrent, (state, { payload: { id } }) => assoc('currentId', id, state)),
    on(setBillingId, (state, { payload: { billingId } }) => {
        return {
            ...state,
            billingId
        }
    }),
    on(setUnpaid, (state, { payload: { billDeliveryDetail, deliveryDetail } }) => {
        return {
            ...state,
            unpaid: { billDeliveryDetail, deliveryDetail }
        }
    }),
    on(clearDeliveryIdAndBillingId, state => {
        return {
            ...state,
            currentId: undefined,
            billingId: undefined
        }
    }),
    on(authActions.clearUser, () => initialState)
)
