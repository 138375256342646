import { useCallback, useState } from 'react'

import { useMountedPromiseFn } from 'shared/hooks'
import { services } from 'core/ad-statistics/data-access'
import { AdQuery, AdStatistics } from '../../../models'

export const useAdStatistics = () => {
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState<AdStatistics>()

    const getAdStatisticsApi = useMountedPromiseFn(services.getAdStatistics)
    const getAdStatistics = useCallback(
        (query: AdQuery) => {
            setLoading(true)
            return getAdStatisticsApi(query)
                .then(value => {
                    setLoading(false)
                    setState(value)
                    return value
                })
                .catch(err => {
                    setLoading(false)
                    return Promise.reject(err)
                })
        },
        [getAdStatisticsApi]
    )

    return {
        ...state,
        loading,
        methods: {
            getAdStatistics
        }
    }
}
