import React, { FC, Fragment, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'

import { convertToDollar } from 'shared/utils'
import { setDurationsObj } from 'shared/config/eshop-shared-config'
import { useApiError, useResponsive, useShowMessage, useSignInRedirect } from 'shared/hooks'
import { CartApiReturnType, CartItemRender, CartListRendered } from 'core/cart/models'
import { useI18n } from 'core/language/api'

import { Durations } from 'components'

import { DeleteIcon } from './DeleteIcon'
import { QuantityBtn } from './QuantityBtn'

interface Props {
    list: CartListRendered[]
    deleteItem: (code: string) => Promise<void>
    reduceQuantity: (options: {
        code: string
        value: number
        quantity: number
        skuId?: string
        timeZone?: string
        duration?: string
    }) => CartApiReturnType
    updateQuantity: (options: {
        code: string
        value: number
        quantity: number
        skuId?: string
        timeZone?: string
        duration?: string
    }) => Promise<CartItemRender | void>
}

export const List: FC<Props> = ({ list, deleteItem, reduceQuantity, updateQuantity }) => {
    const navigate = useNavigate()
    const {
        methods: { t }
    } = useI18n()
    const { md } = useResponsive()
    const { getToken } = useSignInRedirect()
    const {
        methods: { error }
    } = useShowMessage()
    const {
        methods: { handleError }
    } = useApiError('CartIndex', error)

    const handleReduceQuantity = useCallback(
        (code: string, quantity: number, skuId: string, timeZone: string, duration: string) => {
            if (quantity > 0) {
                reduceQuantity({ code, value: 1, quantity, skuId, timeZone, duration }).catch(handleError)
            } else {
                error(t('client.cart.fail.cannot_reduce_num'))
            }
        },
        [error, handleError, reduceQuantity, t]
    )

    const handleUpdateQuantity = useCallback(
        (code: string, quantity: number, skuId: string, timeZone: string, duration: string) => {
            updateQuantity({ code, value: 1, quantity, skuId, timeZone, duration }).catch(handleError)
        },
        [handleError, updateQuantity]
    )

    return (
        <ListWrapper>
            {list.map(({ items }, _index) => (
                <Fragment key={_index}>
                    {items.map(
                        (
                            {
                                code,
                                productId,
                                image,
                                name,
                                quantity,
                                skuId,
                                price,
                                depositPrice,
                                spec,
                                stock,
                                type,
                                preOrder,
                                onceLimit,
                                timeZone,
                                duration,
                                durations,
                                virtual
                            },
                            itemsIndex
                        ) => (
                            <div className="item" key={itemsIndex + _index}>
                                <div className="info">
                                    <img
                                        src={image}
                                        alt=""
                                        onClick={() => {
                                            navigate(`/products/${productId}`)
                                            setDurationsObj(
                                                JSON.stringify({
                                                    durations,
                                                    duration,
                                                    timeZone
                                                })
                                            )
                                        }}
                                    />
                                    <div className="content">
                                        <div className="describe">
                                            <div className="title">{name}</div>
                                            {type === 1 ? (
                                                <>
                                                    {!virtual && (
                                                        <p>
                                                            <span>Deposit:</span>${depositPrice}
                                                        </p>
                                                    )}
                                                    <p>
                                                        <span>Price:</span>${price}
                                                    </p>
                                                    {spec?.map(({ attrName, valueName }, specIndex) => (
                                                        <p key={specIndex}>
                                                            <span className="label">{attrName}:</span>
                                                            {valueName}
                                                        </p>
                                                    ))}
                                                    <TimeContext>
                                                        <Durations data={durations} />
                                                    </TimeContext>
                                                </>
                                            ) : (
                                                <>
                                                    <p>${price}</p>
                                                    {spec?.map(({ attrName, valueName }, specIndex) => (
                                                        <p key={specIndex}>
                                                            <span className="label">{attrName}:</span>
                                                            {valueName}
                                                        </p>
                                                    ))}
                                                </>
                                            )}
                                            {/* {preOrder && quantity >= onceLimit && (
                                                <p className="outStock">Pre-Order</p>
                                            )} */}
                                            {!preOrder && quantity >= stock && (
                                                <p className="outStock">Inventory limit reached</p>
                                            )}
                                        </div>
                                        {!md && (
                                            <DeleteIcon
                                                onClick={() => deleteItem(getToken() ? code : productId)}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className={`price ${md ? 'md-price' : ''}`}>
                                    <div className="content">
                                        {md && (
                                            <QuantityBtn
                                                quantity={quantity}
                                                stock={stock}
                                                preOrder={preOrder}
                                                onceLimit={onceLimit}
                                                onReduceQuantity={() =>
                                                    handleReduceQuantity(
                                                        getToken() ? code : productId,
                                                        quantity - 1,
                                                        skuId,
                                                        timeZone || '',
                                                        duration || ''
                                                    )
                                                }
                                                onQuantity={() =>
                                                    handleUpdateQuantity(
                                                        getToken() ? code : productId,
                                                        quantity + 1,
                                                        skuId,
                                                        timeZone || '',
                                                        duration || ''
                                                    )
                                                }
                                            />
                                        )}
                                        <div className="value">
                                            $
                                            {convertToDollar(
                                                (Number(price.replace(/,/g, '')) * quantity).toFixed(2)
                                            )}
                                        </div>
                                        {md ? (
                                            <DeleteIcon
                                                onClick={() => deleteItem(getToken() ? code : productId)}
                                            />
                                        ) : (
                                            <QuantityBtn
                                                quantity={quantity}
                                                stock={stock}
                                                preOrder={preOrder}
                                                onceLimit={onceLimit}
                                                onReduceQuantity={() =>
                                                    handleReduceQuantity(
                                                        getToken() ? code : productId,
                                                        quantity - 1,
                                                        skuId,
                                                        timeZone || '',
                                                        duration || ''
                                                    )
                                                }
                                                onQuantity={() =>
                                                    handleUpdateQuantity(
                                                        getToken() ? code : productId,
                                                        quantity + 1,
                                                        skuId,
                                                        timeZone || '',
                                                        duration || ''
                                                    )
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                </Fragment>
            ))}
        </ListWrapper>
    )
}
const ListWrapper = styled.div`
    min-width: 609px;
    border-top: 1px solid rgba(47, 46, 46, 1);
    .describe {
        .title {
            margin-bottom: 10px;
            font-family: PingFangSC-Semibold;
            font-size: 16px;
            color: #303030;
            letter-spacing: 0;
            line-height: 26px;
            font-weight: 600;
        }
        p {
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #8c8c8c;
            letter-spacing: 0;
            font-weight: 400;
            margin-bottom: 0;
            span {
                display: inline-block;
                margin-right: 10px;
                text-transform: capitalize;
            }
        }
        .label {
            text-transform: capitalize;
        }
    }
    .item {
        position: relative;
        display: flex;
        padding: 2.4rem 0;
        border-bottom: 1px solid rgba(47, 46, 46, 1);
        @media (max-width: 1024px) {
            display: block;
            padding: 24px 0;
        }
    }
    .info {
        position: relative;
        display: flex;
        word-break: break-word;
        > img {
            width: 10rem;
            height: 10rem;
            margin-right: 1.5rem;
            background: rgba(60, 60, 60, 0.1);
            cursor: pointer;
        }
        .content {
            display: flex;
            flex: 1;
            flex-direction: row;
            justify-content: space-between;
        }
        .outStock {
            color: #ff4d4f;
        }
        @media (max-width: 1024px) {
            margin-bottom: 24px;
            > img {
                width: 100px;
                height: 100px;
            }
        }
    }
    .price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 26px;
        .content {
            margin-left: calc(100px + 1.5rem);
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .value {
            font-family: PingFangSC-Semibold;
            font-size: 18px;
            color: #000000;
            letter-spacing: 0;
            line-height: 18px;
            font-weight: 600;
            text-align: left;
        }
    }
    .md-price {
        position: absolute;
        right: 0;
        top: 2.4rem;
        width: 320px;
        .content {
            margin-left: 0;
        }
    }
    @media (max-width: 768px) {
        min-width: auto;
        .describe {
            .time {
                span {
                    display: block;
                }
            }
        }
    }
`

const TimeContext = styled.div`
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #8c8c8c;
`
