import React, { useEffect, useMemo, useState, useRef, FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { Modal, Spin } from 'antd'

import { getIsTourist } from 'shared/config'
import { useI18n } from 'core/language/api'
import { useOrderDetail } from 'core/order/api'
import { useApiError, useShowMessage, useResponsive, useMemoizedFn, useSignInRedirect } from 'shared/hooks'
import { OrderItemForSummary, OrderStatusEnum } from 'core/order/models'
import { usePayBillingAddress } from 'core/order/api/lib/usePayBillingAddress'

import { PayContainer } from './payment'
import { PageContainer, Button, Summary } from 'components'
import { ShowSingleButtonModalMessage } from 'components/ShowSingleButtonModalMessage'
import { useDeliveryCreate, useDeliveryList } from 'core/delivery/api'
import { BaseDelivery, DeliveryTypeEnum } from 'core/delivery/models'
import { EventBillingAddressDetail } from 'core/event/models'
import { BillingList } from 'modules/event/create-order/components/BillingList'
import { AddModal } from 'modules/event/create-order/components/AddModal'
import { BillingAddress } from 'modules/event/create-order/components/BillingAddress'

export const OrderPay: FC = () => {
    const {
        methods: { t }
    } = useI18n()
    const navigate = useNavigate()
    const {
        methods: { error, showSingleButtonModal }
    } = useShowMessage()
    const {
        methods: { handleError }
    } = useApiError('OrderCreate', error as any)
    const { md } = useResponsive()

    const { orderCode } = useParams<{ orderCode: string }>()

    const [cardPay, setCardPay] = useState(true)
    const handleRedirect = useMemoizedFn(() => navigate(`/orders/${orderCode}`, { replace: true }))
    const handleCannotPay = useMemoizedFn(() => {
        showSingleButtonModal({
            message: t('error.cannot_pay'),
            type: 'fail',
            buttonText: t('View order'),
            onButtonClick: handleRedirect
        })
    })
    const payOrderFormRef = useRef<any>()
    const [cardPayLoading, setCardPayLoading] = useState<boolean>(false)
    const payByCard = useMemoizedFn(() => {
        setCardPayLoading(true)
        payOrderFormRef?.current.submit((error?: string) => {
            setCardPayLoading(false)
            setTimeout(() => {
                if (error) {
                    handleCannotPay()
                    return
                }
                showSingleButtonModal({
                    message: (
                        <ShowSingleButtonModalMessage
                            title={t('client.order.pay.success')}
                            context={t('client.order.pay.success_message')}
                        />
                    ),
                    type: 'success',
                    buttonText: t('View Orders'),
                    onButtonClick: handleRedirect
                })
            }, 100)
        })
    })
    const {
        detail,
        loading: detailLoading,
        methods: { getDetail }
    } = useOrderDetail(orderCode!)
    useEffect(() => {
        Modal.destroyAll()
        orderCode &&
            getDetail()
                .then(({ status }) => {
                    switch (status) {
                        case OrderStatusEnum.UNPAY:
                            return
                        case OrderStatusEnum.AUTO_CANCEL:
                            handleCannotPay()
                            return
                        default:
                            handleRedirect()
                            return
                    }
                })
                .catch(handleError)
    }, [getDetail, handleCannotPay, handleError, handleRedirect, md, orderCode, t])

    const summaryOrderItems = useMemo(() => {
        let data: OrderItemForSummary[] = []
        detail?.items.forEach(
            ({
                productId,
                snapshot: { image, name },
                quantity,
                originUnitPrice,
                type,
                depositUnitPrice,
                depositTotalPrice,
                timeZone,
                duration,
                durations
            }) => {
                data.push({
                    type,
                    productId,
                    image,
                    price: originUnitPrice,
                    name,
                    quantity,
                    depositPrice: depositUnitPrice || '0.00',
                    timeZone: timeZone || '',
                    duration: duration || '',
                    durations: durations || []
                })
            }
        )
        return data
    }, [detail])

    const [complete, setComplete] = useState<boolean>(false)

    const [selectBillingModalVisible, setSelectBillingModalVisible] = useState<boolean>(false)
    const [billingButtonText, setBillingButtonText] = useState<string>('')
    const [selectedBillingId, setSelectedBillingId] = useState<string>()
    const [addressData, setAddressData] = useState<EventBillingAddressDetail>()
    const [addBillingModalVisible, setAddBillingModalVisible] = useState<boolean>(false)
    const { getToken } = useSignInRedirect()
    const {
        billingList,
        empty,
        methods: { getList: getBillingList }
    } = useDeliveryList(DeliveryTypeEnum.BILLING)
    const {
        methods: { create: addressCreate }
    } = useDeliveryCreate(DeliveryTypeEnum.BILLING)
    const {
        methods: { updateBillingAddress }
    } = usePayBillingAddress()
    useEffect(() => {
        if (getToken() && !getIsTourist()) {
            getBillingList().catch(handleError)
            setBillingButtonText(!empty ? 'Use a saved address' : '')
        }
    }, [empty, getBillingList, getToken, handleError])

    const handleSelectAddress = useMemoizedFn((id: string) => {
        if (id) {
            setSelectedBillingId(id)
            setBillingButtonText('Change')
            const newData = billingList.filter(item => item.id === id)
            setAddressData(newData[0])
        }
    })

    const handleAddBilling = useMemoizedFn((data: BaseDelivery) => {
        addressCreate(data).then(res => {
            setAddBillingModalVisible(false)
            let addressData = {
                ...data,
                id: res
            }
            setSelectedBillingId(res)
            setAddressData(addressData)
        })
    })

    const handleSubmit = useMemoizedFn((billInfo?: BaseDelivery) => {
        if (!complete) return
        console.log('billInfo => ', billInfo)
        console.log('id => ', selectedBillingId)

        if (cardPay) {
            ;(() => {
                if (selectedBillingId) {
                    return updateBillingAddress(orderCode!, selectedBillingId)
                }
                return updateBillingAddress(orderCode!, '', billInfo)
            })()
                .then(payByCard)
                .catch(handleError)
            return
        }

        payByCard()
    })

    return (
        <Spin spinning={cardPayLoading || detailLoading}>
            <Container>
                {md && <Header />}
                <Main>
                    <FormWrapper>
                        {!md && <Header />}
                        <Shipping>
                            <div className="stepsWrapper payment">
                                <div className="header">
                                    <div className="name">Payment</div>
                                </div>
                                <div className="paymentForm">
                                    <PayContainer
                                        param={{ orderCode: orderCode! }}
                                        innerRef={payOrderFormRef}
                                        setComplete={setComplete}
                                        setCardPay={setCardPay}
                                    />
                                </div>
                                {cardPay ? (
                                    <BillingAddressInfo>
                                        <BillingCardTitle>
                                            <BillingTitle>Billing address</BillingTitle>
                                            <BillingTitleAddress
                                                onClick={() => {
                                                    setSelectBillingModalVisible(true)
                                                    getBillingList()
                                                }}
                                            >
                                                {billingButtonText}
                                            </BillingTitleAddress>
                                        </BillingCardTitle>
                                        {selectedBillingId ? (
                                            <BillingAddressContent>
                                                {addressData && (
                                                    <div key={addressData.id}>
                                                        <div>
                                                            {addressData.firstName} {addressData.lastName}
                                                        </div>
                                                        <div>{addressData.companyName}</div>
                                                        <div>{addressData.streetAddress}</div>
                                                        <div>
                                                            {addressData.city}, {addressData.state}{' '}
                                                            {addressData.zipCode}
                                                        </div>
                                                        {addressData.phone && (
                                                            <div>Phone : {addressData.phone}</div>
                                                        )}
                                                    </div>
                                                )}
                                                <SubButton onClick={handleSubmit} disabled={!complete}>
                                                    Pay
                                                </SubButton>
                                            </BillingAddressContent>
                                        ) : (
                                            <BillingAddress
                                                btnTitle="Pay"
                                                disabled={!complete}
                                                onSubmit={handleSubmit}
                                            />
                                        )}
                                    </BillingAddressInfo>
                                ) : (
                                    <Button
                                        className={`continue ${complete ? 'enable' : ''}`}
                                        onClick={handleSubmit}
                                    >
                                        Pay
                                    </Button>
                                )}
                                <BillingList
                                    data={billingList}
                                    visible={selectBillingModalVisible}
                                    onCancel={() => setSelectBillingModalVisible(false)}
                                    onSelect={handleSelectAddress}
                                    onAddModalShow={() => setAddBillingModalVisible(true)}
                                    selectedBillingId={selectedBillingId ? selectedBillingId : ''}
                                />
                                <AddModal
                                    visible={addBillingModalVisible}
                                    onCancel={() => setAddBillingModalVisible(false)}
                                    onAddBilling={handleAddBilling}
                                    disabled={!complete}
                                />
                            </div>
                        </Shipping>
                    </FormWrapper>
                    <SummaryWrapper>
                        <Summary
                            orderItems={summaryOrderItems}
                            orderPrice={detail?.price}
                            isShowCouponCodeInput={false}
                            couponCodeValue={detail?.couponCode}
                        />
                    </SummaryWrapper>
                </Main>
            </Container>
        </Spin>
    )
}

const Container = styled(PageContainer)`
    width: 100%;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
        padding: 0;
        width: 100%;
    }
`

const Main = styled.div`
    display: flex;
    width: 100%;
    margin: 0 auto;
    flex: 1 0 auto;
    padding: 3rem 13.8rem;
    @media (max-width: 768px) {
        flex-wrap: wrap;
        padding: 0;
    }
`

const FormWrapper = styled.div`
    width: auto;
    flex: 1;
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 20px;
        h2 {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #242424;
            font-size: 26px;
            span {
                padding-right: 16px;
            }
        }
        .edit {
            font-size: 16px;
            text-decoration: underline;
            line-height: 28px;
            cursor: pointer;
            color: #121212;
            margin-top: 3px;
            background-color: #fff;
        }
        .edit:hover {
            color: #1e90ff;
        }
    }
    .stepsWrapper {
        margin-top: 4rem;
        margin-bottom: 4rem;
        border-bottom: 1px solid rgba(47, 46, 46, 1);
    }
    .mb-4rem {
        margin-bottom: 4rem;
    }
    .mb-3rem {
        margin-bottom: 3rem;
    }
    .continue {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 48px;
        margin-top: 4rem;
        margin-bottom: 4rem;
        border-radius: 35.18px;
        background-color: #000;
        font-family: Roboto-Medium;
        font-size: 25.33px;
        color: #ffffff;
        text-align: center;
        line-height: 30.96px;
        cursor: pointer;
    }
    .payment {
        border: none;
    }
    .payment .continue {
        background: #a8a8a8;
        color: #ffffff;
        cursor: auto;
    }
    .payment .continue.enable {
        background-color: #000;
        cursor: pointer;
    }
    .paymentForm {
        padding: 25px 30px 30px;
        background-color: #f4f4f4;
    }
    @media (max-width: 768px) {
        width: 100%;
        flex: auto;
        padding: 0 20px;
        margin-bottom: 50px;
        .continue {
            margin-top: 30px;
            margin-bottom: 30px;
        }
        .paymentForm {
            padding: 0 10px;
        }
    }
`

const Shipping = styled.div`
    padding-top: 5rem;
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4rem;
        .name {
            display: flex;
            font-family: HelveticaNeue-CondensedBold;
            font-size: 3rem;
            color: #000000;
            line-height: 3rem;
        }
        img {
            width: 3rem;
            height: 3rem;
            margin-right: 1rem;
        }
    }
    .addressWrapper {
        font-family: HelveticaNeue;
        font-size: 18px;
        color: #000000;
        line-height: 26px;
    }

    .select {
        font-family: HelveticaNeue;
        font-size: 18px;
        color: #000000;
        text-align: right;
        line-height: 26px;
        text-align: right;
        text-decoration: underline;
        cursor: pointer;
    }
    .select.disable {
        color: #cccccc;
        cursor: auto;
    }
    .deliveryType .item,
    .sameShipping {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;

        font-family: HelveticaNeue;
        font-size: 18px;
        color: #000000;
        line-height: 26px;
        cursor: pointer;
        img {
            width: 1.6rem;
            height: 1.6rem;
            margin-right: 1rem;
        }
    }
    @media (max-width: 768px) {
        padding-top: 0;
        .address {
            flex-wrap: wrap;
            flex-direction: row;
        }
        .headerTop {
            padding-top: 25px;
        }
        .header {
            margin-bottom: 25px;
            .name {
                font-size: 18px;
                line-height: 21.76px;
            }
            img {
                width: 24px;
                height: 24px;
                margin-right: 10px;
            }
        }
        .select {
            line-height: 24px;
        }
        .sameShipping {
            margin-bottom: 20px;
            img {
                width: 16px;
                height: 16px;
                margin-right: 10px;
            }
        }
    }
`

const SummaryWrapper = styled.div`
    width: 384px;
    margin-left: 30px;
    @media (max-width: 768px) {
        width: 100%;
        margin-left: 0;
    }
`

const Header = styled.div`
    margin: 10px auto 30px;
    width: 580px;
    .title {
        margin-bottom: 16px;
        .text {
            opacity: 0.5;
        }
    }

    .Info {
        font-family: HelveticaNeue;
        font-size: 18px;
        color: #000000;
        line-height: 26px;
        font-weight: 400;
    }
    @media (max-width: 768px) {
        width: 100%;
    }
`

const BillingAddressInfo = styled.div`
    .title {
        width: 580px;
        margin: 24px auto 30px;
    }
    @media (max-width: 768px) {
        width: 100%;
    }
`

const BillingTitleAddress = styled.div`
    font-family: HelveticaNeue;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    text-align: right;
    line-height: 26px;
    text-decoration: underline;
    cursor: pointer;
`
const BillingAddressContent = styled.div`
    font-family: HelveticaNeue;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    line-height: 26px;
`
const SubButton = styled(Button)`
    width: 100%;
    height: 50px;
    border-radius: 36px;
    margin-top: 40px;
    font-family: Roboto-Medium;
    font-size: 25.33px;
    color: #fff;
    letter-spacing: 0;
    text-align: center;
    line-height: 30.96px;
    @media (max-width: 768px) {
        width: 100%;
        margin: 30px 0 24px 0;
    }
`
const BillingCardTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    @media (max-width: 768px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
`

const BillingTitle = styled.div`
    font-family: HelveticaNeue-CondensedBold;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: bold;
    @media (max-width: 768px) {
        margin: 0 0 20px 0;
    }
`
