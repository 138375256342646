import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useI18n } from 'core/language/api'
import { useListState, useMountedPromiseFn } from 'shared/hooks'
import { useCart } from 'shared/hooks'
import { getToken } from 'shared/config'
import { actions as cartActions, selectors as cartSelectors } from 'core/cart/state'

import { CartItem, CartItemGroupByStore, CartApiReturnType, CartCheckItem } from 'core/cart/models'
import { services } from 'core/cart/data-access'

export const useCartList = () => {
    const { lang } = useI18n()
    const { list, remove, updateCartByCode, batchDelete } = useCart()
    const [empty, setEmpty] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [cartList, setCartList] = useListState<CartItemGroupByStore>()
    const [ineffectiveList, setIneffectiveList] = useListState<CartItem>()

    const dispatch = useDispatch()
    const cartNum = useSelector(cartSelectors.getCartNum)

    const getListApi = useMountedPromiseFn(services.getList)
    const getListNoAuthApi = useMountedPromiseFn(services.getListNoAuth)
    const getList = useCallback(() => {
        setLoading(true)
        if (getToken()) {
            return getListApi(lang)
                .then(({ cartList, ineffectiveList }) => {
                    setLoading(false)
                    setCartList({ list: cartList })
                    setIneffectiveList({ list: ineffectiveList })
                    setEmpty(!cartList.length && !ineffectiveList.length)
                    let num = 0
                    cartList.forEach(({ items }) => {
                        items.forEach(({ quantity }) => {
                            num += quantity
                        })
                    })
                    dispatch(cartActions.updateCartNum({ cartNumber: num }))

                    return { cartList, ineffectiveList }
                })
                .catch(err => {
                    setLoading(false)
                    return Promise.reject(err)
                })
        } else {
            return getListNoAuthApi(lang, list)
                .then(({ cartList, ineffectiveList }) => {
                    setLoading(false)
                    setCartList({ list: cartList })
                    setIneffectiveList({ list: ineffectiveList })
                    setEmpty(!cartList.length && !ineffectiveList.length)
                    let num = 0
                    cartList.forEach(({ items }) => {
                        items.forEach(({ quantity }) => {
                            num += quantity
                        })
                    })
                    dispatch(cartActions.updateCartNum({ cartNumber: num }))
                    return { cartList, ineffectiveList }
                })
                .catch(err => {
                    setLoading(false)
                    return Promise.reject(err)
                })
        }
    }, [dispatch, setEmpty, getListApi, getListNoAuthApi, lang, list, setCartList, setIneffectiveList])

    const checkCartNoAuthApi = useMountedPromiseFn(services.checkCartNoAuth)
    const checkCartNoAuth = useCallback(
        (options: {
            code: string
            quantity: number
            skuId?: string
            timeZone?: string
            duration?: string
        }) => {
            const { code, quantity, skuId, timeZone, duration } = options
            return checkCartNoAuthApi(code, quantity, skuId, timeZone, duration).catch(err => {
                return Promise.reject(err)
            })
        },
        [checkCartNoAuthApi]
    )
    const deleteItemApi = useMountedPromiseFn(services.deleteItem)
    const deleteItem = useCallback(
        (code: string) => {
            setLoading(true)
            if (getToken()) {
                return deleteItemApi(code)
                    .then(getList)
                    .catch(err => {
                        setLoading(false)
                        return Promise.reject(err)
                    })
            } else {
                remove(code)
                getList()
                setLoading(false)
                return Promise.resolve()
            }
        },
        [deleteItemApi, getList, remove]
    )

    const batchDeleteItemApi = useMountedPromiseFn(services.batchDeleteItem)
    const batchDeleteItem: (code: string[]) => CartApiReturnType = useCallback(
        (codes: string[]) => {
            setLoading(true)
            if (getToken()) {
                return batchDeleteItemApi(codes)
                    .then(getList)
                    .catch(err => {
                        setLoading(false)
                        return Promise.reject(err)
                    })
            } else {
                batchDelete(codes)
                getList()
                setLoading(false)
                return Promise.resolve()
            }
        },
        [batchDelete, batchDeleteItemApi, getList]
    )

    const addQuantityApi = useMountedPromiseFn(services.addQuantity)
    const addQuantity = useCallback(
        (options: {
            code: string
            value: number
            quantity: number
            skuId?: string
            timeZone?: string
            duration?: string
        }) => {
            const { code, value, quantity, skuId, timeZone, duration } = options
            setLoading(true)
            if (getToken()) {
                return addQuantityApi(code, value)
                    .then(getList)
                    .catch(err => {
                        setLoading(false)
                        return Promise.reject(err)
                    })
            } else {
                return checkCartNoAuth({ code, quantity, skuId, timeZone, duration })
                    .then(() => {
                        getList()
                        updateCartByCode(code, quantity, skuId, timeZone, duration)
                        setLoading(false)
                        return Promise.resolve()
                    })
                    .catch(err => {
                        setLoading(false)
                        return Promise.reject(err)
                    })
            }
        },
        [addQuantityApi, checkCartNoAuth, getList, updateCartByCode]
    )

    const updateQuantityApi = useMountedPromiseFn(services.updateQuantity)
    const updateQuantity = useCallback(
        (options: {
            code: string
            value: number
            quantity: number
            skuId?: string
            timeZone?: string
            duration?: string
        }) => {
            const { code, value, quantity, skuId, timeZone, duration } = options
            setLoading(true)
            if (getToken()) {
                return updateQuantityApi(code, value)
                    .then(getList)
                    .catch(err => {
                        setLoading(false)
                        return Promise.reject(err)
                    })
            } else {
                return checkCartNoAuth({ code, quantity, skuId, timeZone, duration })
                    .then(() => {
                        getList()
                        updateCartByCode(code, quantity, skuId, timeZone, duration)
                        setLoading(false)
                        return Promise.resolve()
                    })
                    .catch(err => {
                        setLoading(false)
                        return Promise.reject(err)
                    })
            }
        },
        [updateQuantityApi, checkCartNoAuth, getList, updateCartByCode]
    )

    const reduceQuantityApi = useMountedPromiseFn(services.reduceQuantity)
    const reduceQuantity: (options: {
        code: string
        value: number
        quantity: number
        skuId?: string
        timeZone?: string
        duration?: string
    }) => CartApiReturnType = useCallback(
        ({ code, value, quantity, skuId, timeZone, duration }) => {
            setLoading(true)
            if (getToken()) {
                return reduceQuantityApi(code, value)
                    .then(getList)
                    .catch(err => {
                        setLoading(false)
                        return Promise.reject(err)
                    })
            } else {
                return checkCartNoAuth({ code, quantity, skuId, timeZone, duration })
                    .then(() => {
                        getList()
                        updateCartByCode(code, quantity, skuId, timeZone, duration)
                        setLoading(false)
                        return Promise.resolve()
                    })
                    .catch(err => {
                        setLoading(false)
                        return Promise.reject(err)
                    })
            }
        },
        [reduceQuantityApi, getList, checkCartNoAuth, updateCartByCode]
    )

    const editItemApi = useMountedPromiseFn(services.editItem)
    const editItem: (
        code: string,
        value: { skuId?: string; quantity?: number; timeZone?: string; duration?: string }
    ) => CartApiReturnType = useCallback(
        (code, value) => {
            setLoading(true)
            if (getToken()) {
                return editItemApi(code, value)
                    .then(getList)
                    .catch(err => {
                        setLoading(false)
                        return Promise.reject(err)
                    })
            } else {
                return checkCartNoAuth({
                    code,
                    quantity: value.quantity || 0,
                    skuId: value.skuId,
                    timeZone: value.timeZone,
                    duration: value.duration
                })
                    .then(() => {
                        getList()
                        updateCartByCode(
                            code,
                            value.quantity || 0,
                            value.skuId,
                            value.timeZone,
                            value.duration
                        )
                        setLoading(false)
                        return Promise.resolve()
                    })
                    .catch(err => {
                        setLoading(false)
                        return Promise.reject(err)
                    })
            }
        },
        [checkCartNoAuth, editItemApi, getList, updateCartByCode]
    )

    const syncDataApi = useMountedPromiseFn(services.syncData)
    const syncData = useCallback(
        (items: CartCheckItem[]) => {
            setLoading(true)
            if (getToken()) {
                return syncDataApi(items)
                    .catch(() => {
                        getList()
                    })
                    .catch(err => {
                        setLoading(false)
                        return Promise.reject(err)
                    })
            }
        },
        [syncDataApi, getList]
    )

    const clearCartNum = useCallback(() => {
        dispatch(cartActions.updateCartNum({ cartNumber: 0 }))
    }, [dispatch])

    return {
        empty,
        cartList: cartList.list,
        ineffectiveList: ineffectiveList.list,
        cartNumber: cartNum,
        loading,
        methods: {
            clearCartNum,
            getList,
            deleteItem,
            addQuantity,
            reduceQuantity,
            editItem,
            batchDeleteItem,
            syncData,
            updateQuantity
        }
    }
}
