import React, { forwardRef, useEffect, useState, useCallback, useImperativeHandle, useMemo } from 'react'
import styled from '@emotion/styled'
import { useCoupon } from 'core/coupon/api'

import successIcon from '../assets/success.png'
import warningIcon from '../assets/warning.png'

export interface CouponCodeRefProps {
    reApply: () => void
}

interface CouponCodeProps {
    productIds: string[]
    defaultValue?: string
    onApply?: (value: string) => void
}

enum VERIFY_STATUS {
    NONE = 'none',
    SUCCESS = 'success',
    WARNING = 'warning'
}

export const CouponCode = forwardRef<CouponCodeRefProps, CouponCodeProps>(
    ({ productIds, defaultValue = '', onApply }, ref) => {
        const [couponCode, setCouponCode] = useState<string>('')
        const [verifyCouponCode, setVerifyCouponCode] = useState<VERIFY_STATUS>(VERIFY_STATUS.NONE)

        const {
            // loading,
            methods: { verification }
        } = useCoupon()

        const code = useMemo(() => couponCode || defaultValue || '', [couponCode, defaultValue])

        const handleChange = useCallback(
            (value: string) => {
                setCouponCode(value)
                setVerifyCouponCode(VERIFY_STATUS.NONE)
                if (!value) {
                    onApply?.('')
                }
            },
            [onApply]
        )

        const verificationFn = useCallback(
            (code: string) => {
                if (code) {
                    return verification(code, productIds)
                        .then(() => {
                            setVerifyCouponCode(VERIFY_STATUS.SUCCESS)
                            onApply?.(code)
                        })
                        .catch(err => {
                            setVerifyCouponCode(VERIFY_STATUS.WARNING)
                            onApply?.('')
                        })
                } else {
                    setVerifyCouponCode(VERIFY_STATUS.NONE)
                    onApply?.('')
                }
            },
            [verification, productIds, onApply]
        )

        const handleApply = useCallback(() => {
            if (verifyCouponCode === VERIFY_STATUS.SUCCESS) {
                setCouponCode('')
                setVerifyCouponCode(VERIFY_STATUS.NONE)
                onApply?.('')
            } else if (code) {
                return verificationFn(code)
            }
        }, [verifyCouponCode, code, onApply, verificationFn])

        useImperativeHandle(ref, () => ({
            reApply: () => {
                verificationFn(code)
            }
        }))

        useEffect(() => {
            setCouponCode(defaultValue)
        }, [verificationFn, defaultValue])

        return (
            <Wrapper>
                <div className="title">Enter a promo code</div>

                <div className={`form ${verifyCouponCode}`}>
                    <input
                        value={couponCode}
                        className="input"
                        placeholder="Enter a promo code"
                        onChange={e => handleChange(e.target.value)}
                    />
                    <div className="apply" onClick={() => handleApply()}>
                        {verifyCouponCode !== VERIFY_STATUS.SUCCESS ? 'Apply' : 'Remove'}
                    </div>
                </div>
                <div className={`notValid ${verifyCouponCode}`}>Promo code isn't valid.</div>
            </Wrapper>
        )
    }
)

const Wrapper = styled.div`
    max-width: 324px;
    width: 100%;
    margin: 0 auto 4rem;
    .title {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-family: HelveticaNeue;
        font-size: 17px;
        color: #000000;
        letter-spacing: 0;
        line-height: 26px;
        font-weight: 400;
    }
    .form {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(0, 0, 0, 1);
        font-family: PingFangSC-Semibold;
        font-size: 14px;
        color: #303030;
        letter-spacing: 0;
        line-height: 20px;
    }
    .form::after {
        position: absolute;
        top: 0;
        right: 100px;
        bottom: 0;
        z-index: 1;
        margin: auto;
        display: block;
        content: '';
        width: 16px;
        height: 16px;
    }
    .form.success::after {
        background: url('${successIcon}') no-repeat center / contain;
    }
    .form.warning::after {
        background: url('${warningIcon}') no-repeat center / contain;
    }
    .input {
        position: relative;
        z-index: 2;
        flex: 1;
        width: calc(100% - 90px);
        height: 40px;
        padding: 0 0.5rem;
        background: transparent;
        outline-style: none;
        font-family: Roboto-Medium;
        font-size: 16px;
        color: #000;
        letter-spacing: 0;
        line-height: 20px;
    }
    .input::placeholder {
        font-family: Roboto-Medium;
        font-size: 16px;
        color: #ccc;
    }
    .apply {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90px;
        height: 40px;
        border-left: 1px solid rgba(0, 0, 0, 1);
        cursor: pointer;
        font-family: Roboto-Medium;
        font-size: 14px;
        color: #000;
        letter-spacing: 0;
        text-align: center;
        flex-shrink: 0;
    }
    .notValid {
        font-family: HelveticaNeue;
        font-size: 14px;
        color: #ce5324;
        letter-spacing: 0;
        line-height: 26px;
        margin-top: 2px;
        display: none;
    }
    .notValid.warning {
        display: block;
    }
`
