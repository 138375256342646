import React, { FC, useMemo, useEffect } from 'react'
import styled from '@emotion/styled'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { PageWrapper, Spin } from 'components'
import { useSignInRedirect } from 'shared/hooks'
import { SIGN_STATUS_ENUM } from 'core/auth/models'
import { getIsTourist } from 'shared/config'
import { AuthBox } from './components/Auth'

import bgWebImg from './assets/bg-web.jpg'
import bgMobileImg from './assets/bg-mobile.jpg'

export const FeatureAuthShell: FC = () => {
    const navigate = useNavigate()
    const { getToken } = useSignInRedirect()
    const { type } = useParams()

    useEffect(() => {
        !getIsTourist() && getToken() && navigate('/mine')
    }, [getToken, navigate])

    const formNode = useMemo(() => {
        const token = getToken()
        if (type === SIGN_STATUS_ENUM.TOURIST_BINGING && token) {
            return <AuthBox />
        }
        return token ? <></> : <AuthBox />
    }, [getToken, type])

    return (
        <AuthBg>
            <Spin spinning={false}>
                <Wrapper className="auth-bg">
                    <div className="auth-box">{formNode}</div>
                </Wrapper>
            </Spin>
        </AuthBg>
    )
}

const AuthBgDef = styled.div``

const AuthBg = styled(AuthBgDef)`
    width: 100%;
    height: 100vw;
    min-height: calc(100vh - 68px - 90px);
    max-height: 770px;
    background: #fff url('${bgWebImg}') no-repeat center 0 / cover;
    @media (max-width: 576px) {
        overflow: auto;
        .auth-bg {
            min-height: calc(100vh - 56px - 90px);
            background: #fff url('${bgMobileImg}') no-repeat center 0 / 100% auto;
        }
    }
`

const Wrapper = styled(PageWrapper)`
    padding: 0;
    .auth-box {
        position: absolute;
        left: 0;
        right: 0;
        top: 10vw;
        margin: auto;
        padding: 0 5rem;
        display: flex;
        justify-content: center;
    }
    @media (max-width: 1024px) {
        .auth-box {
            top: 15vw;
        }
    }
    @media (max-width: 768px) {
        .auth-box {
            top: 20vw;
        }
    }
    @media (max-width: 576px) {
        .auth-box {
            box-sizing: border-box;
            height: calc(100vh - 56px - 90px);
            position: absolute;
            top: 41.6vw;
            padding: 0;
            display: flex;
            align-items: flex-start;
            justify-content: center;
        }
    }
`
