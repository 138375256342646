import { FEATURE_NAME } from './constants'
import { GlobalState } from './reducer'
import { createSelector } from 'reselect'

const select = (state: GlobalState) => state[FEATURE_NAME]
export const getList = createSelector(select, state => state.list)
export const getBillingList = createSelector(select, state => state.billingList)
export const getCurrentId = createSelector(select, state => state.currentId)

export const getCurrent = createSelector(getList, getCurrentId, (list, currentId) => {
    if (!currentId) return list.find(({ isDefault }) => isDefault)
    return list.find(({ id }) => id === currentId)
})

export const getBillingId = createSelector(select, state => state.billingId)

export const getBilling = createSelector(getList, getBillingId, (list, billingId) => {
    return list.find(({ id }) => id === billingId)
})

export const getUnpaid = createSelector(select, state => state.unpaid)
