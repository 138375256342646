import { action, payload } from 'ts-action'
import { getActionType } from '../constants'
import { Language, I18nMap } from 'core/language/models'

export const setCurrentLanguage = action(
    getActionType('Set Current Language'),
    payload<{ language: Language }>()
)

export const setI18nMap = action(getActionType('Set I18n Map'), payload<{ i18nMap: I18nMap }>())
