import { useEffect } from 'react'

import { isFunction } from 'shared/utils'

export const useMount = (fn: () => void) => {
    if (process.env.NODE_ENV === 'development') {
        if (!isFunction(fn)) {
            console.error(`useMount: parameter \`fn\` expected to be a function, but got "${typeof fn}".`)
        }
    }

    useEffect(() => {
        fn?.()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}
