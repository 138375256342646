import { BaseDelivery } from 'core/delivery/models'
import { CheckoutPersonalInfo } from 'core/order/models'
import { SkuListItem } from 'core/product/models'

export interface ProductItem {
    productId: string
    isDaily: boolean
    descriptionList: string[]
}

export interface EventProductState extends ProductItem {
    checked: boolean
}

export interface ProductInList {
    id: string
    canDirectBuy: boolean
    image: string
    name: string
    showPrice: string
    description: string
    mobileDescription: string
    isDaily: boolean
    type: number
    skuList: SkuListItem[]
}

export interface EventProductRef {
    duration: string
    isDaily: boolean
    productId: string
    timeZone: string
    type: string
    description: string[]
    durations: Array<{
        label: string
        value: string
    }>
}

export interface EventItem {
    id: number
    title: string
    timeZone: string
    firstImg: string
    address: string
    beginTime: number
    endTime: number
    beginTimeText: string
    beginDateText: string
    endDateText: string
    endTimeText: string
    content: string
    productList: ProductInList[]
    productRefList: EventProductRef[]
    productIds: string[]
    words: string
    buttonName: string
    productButtonName: string
    titleSize: string
    images: string
    isOff: boolean
}

export interface EventDetail extends EventItem {}

export interface EventForm {
    title: string
    timeZone: string
    address: string
    content: string
    beginTime: number
    endTime: number
    productList: ProductItem[]
    words: string
    buttonName: string
    productButtonName: string
    titleSize: string
    images: string
    isOff: boolean
}

export interface ListParams {
    pageSize: string
    pageNum: string
}

export type EventPersonalInfo = CheckoutPersonalInfo

export type EventBillingAddress = Omit<BaseDelivery, 'type'>

export interface EventBillingAddressDetail extends EventBillingAddress {
    id: string
}

export enum EventOrderEnum {
    PERSONAL = 'personal',
    BILLING = 'billing'
}
