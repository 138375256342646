import { useCallback } from 'react'

import { useMountedPromiseFn, useListState } from 'shared/hooks'
import { services } from 'core/ad-statistics/data-access'
import { AdRecordsList, AdListQuery } from '../../../models'

export const useAdRecordsList = (page: string) => {
    const [state, setState] = useListState<AdRecordsList>()

    const getListApi = useMountedPromiseFn(
        page === 'uv' ? services.getUvAdRecordsList : services.getAdRecordsList
    )
    const getList = useCallback(
        (id: string, params: AdListQuery) => {
            setState({ loading: true })
            return getListApi(id, params)
                .then(({ list, total }) => {
                    setState({ list, total, loading: false })
                    return
                })
                .catch(err => {
                    setState({ loading: false })
                    return Promise.reject(err)
                })
        },
        [getListApi, setState]
    )

    return {
        ...state,
        methods: {
            getList
        }
    }
}
