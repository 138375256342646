import React, { FC, useEffect } from 'react'
import {
    Html5Qrcode,
    // Html5QrcodeScanner,
    Html5QrcodeScannerState,
    Html5QrcodeSupportedFormats
} from 'html5-qrcode'
import styled from '@emotion/styled'

interface Props {
    fps: number
    qrbox?: number
    aspectRatio?: number
    disableFlip?: boolean
    onNewScanResult: (decodedText: string) => void
    onScanError?: (errorMessage: string) => void
}

const qrcodeRegionId = 'html5qr-code-full-region'

export const QrcodeScanner: FC<Props> = ({
    fps,
    qrbox,
    aspectRatio,
    disableFlip,
    onNewScanResult,
    onScanError
}) => {
    useEffect(() => {
        const html5QrCode = new Html5Qrcode(qrcodeRegionId, {
            formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
            verbose: false
        })
        // If you want to prefer front camera
        html5QrCode.start(
            { facingMode: 'environment' },
            {
                fps,
                qrbox,
                aspectRatio,
                disableFlip
            },
            onNewScanResult,
            onScanError
        )
        return () => {
            if (
                [Html5QrcodeScannerState.SCANNING, Html5QrcodeScannerState.PAUSED].includes(
                    html5QrCode.getState()
                )
            ) {
                html5QrCode.pause()
                html5QrCode.stop()
            }
        }
        // const html5QrcodeScanner = new Html5QrcodeScanner(
        //     qrcodeRegionId,
        //     {
        //         fps,
        //         qrbox,
        //         aspectRatio,
        //         disableFlip,
        //         supportedScanTypes: [0],
        //         rememberLastUsedCamera: false,
        //         showTorchButtonIfSupported: false,
        //         showZoomSliderIfSupported: false
        //     },
        //     false
        // )
        // html5QrcodeScanner.render(onNewScanResult, onScanError)

        // return () => {
        //     html5QrcodeScanner.clear().catch(err => console.info('Failed to clear html5QrcodeScanner. ', err))
        // }
    }, [aspectRatio, disableFlip, fps, onNewScanResult, onScanError, qrbox])

    return <QrcodeWrapper id={qrcodeRegionId} className="qrcode-region" />
}

const QrcodeWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    /* > div:first-of-type {
        display: none;
    }

    > div:nth-of-type(2) {
        flex: 1;
        img {
            height: 100%;
            margin: 0 auto;
            width: 80px;
        }
    }

    #html5qr-code-full-region__scan_region {
        video {
            min-height: 290px;
            object-fit: cover;
        }
    }

    #html5-qrcode-button-camera-start,
    #html5-qrcode-button-camera-stop {
        padding: 0 16px;
        height: 30px;
        line-height: 30px;
        border: 1px solid rgba(0, 0, 0, 1);
        border-radius: 19px;
        font-family: OpenSans-Regular;
        font-size: 14px;
        color: #000000;
        font-weight: 400;
    } */
`
