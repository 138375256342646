import React, { FC } from 'react'

import styled from '@emotion/styled'
import { PageContainer } from 'components'

export const SupportReturn: FC = () => {
    return (
        <Wrapper>
            <h1>Returns and Exchanges</h1>
            <div>
                We hope you love your purchase, but if you don’t, we are happy to assist you. To return or
                exchange, items must be returned within 7 days from the delivery date. This return period
                applies to all customers.
            </div>
            <h5>Restocking fees</h5>
            <div className="bottom">
                Certain items we sell have a restocking fee if returned by a customer:
            </div>
            <div className="list">
                <ul>
                    <li>
                        A restocking fee of <span className="weight">20% of the item's purchase price</span>{' '}
                        applies to items priced between US$500 and US$1,000.
                    </li>
                    <li>
                        A restocking fee of <span className="weight">25% of the item's purchase price</span>{' '}
                        applies to items priced between US$1,000.01 and US$1,500.
                    </li>
                    <li>
                        A restocking fee of <span className="weight">30% of the item's purchase price</span>{' '}
                        applies to items priced at US$1,500.01 or higher.
                    </li>
                </ul>
            </div>
            <h5>Final sale and nonreturnable items</h5>
            <div>
                All final sale items cannot be returned or exchanged. Other nonreturnable purchases include
                custom orders, personalized orders, memberships, and completed services.
            </div>
            <h5>Like-new condition</h5>
            <div>
                Items need to be returned in a like-new condition. Items not in this condition will not be
                accepted. Items that are damaged, dented, scratched or missing contents or packaging will be
                denied a return.
            </div>
            <h5>How to return an item</h5>
            <div>
                <ol>
                    <li>
                        <div className="num">1.</div>
                        <div>
                            To begin your return, send an email to{' '}
                            <a href="returns@metasill.io" onClick={e => e.preventDefault()}>
                                returns@metasill.io
                            </a>{' '}
                            requesting a return. Make sure to include your order number (mandatory), specify
                            the item(s) you wish to return (mandatory), and optionally, provide the reason for
                            the return.
                        </div>
                    </li>
                    <li>
                        <div className="num">2.</div>
                        <div>
                            Once we receive your return request, we will process it in accordance with our
                            return/exchange policy. If your item qualifies for a return, we will email you a
                            prepaid return shipping label, return packing slip, and return instructions.
                        </div>
                    </li>
                    <li>
                        <div className="num">3.</div>
                        <div>
                            Gather the item you want to return, along with the original contents and
                            packaging.
                        </div>
                    </li>
                    <li>
                        <div className="num">4.</div>
                        <div>
                            Pack your return securely, preferably using the original shipping package, and
                            include the return packing slip inside.
                        </div>
                    </li>
                    <li>
                        <div className="num">5.</div>
                        <div>Include all original packaging and accessories.</div>
                    </li>
                    <li>
                        <div className="num">6.</div>
                        <div>
                            Affix the provided return shipping label to the package, making sure no other
                            tracking labels are showing.
                        </div>
                    </li>
                    <li>
                        <div className="num">7.</div>
                        <div>
                            Follow any additional return instructions provided in the email sent to you.
                        </div>
                    </li>
                    <li>
                        <div className="num">8.</div>
                        <div>Ship the return package using the provided shipping label.</div>
                    </li>
                </ol>
            </div>
            <h5 className="left">What happens next</h5>
            <div className="left">
                We will refund the cost of the returned item, deducting any applicable fees, within 5 business
                days after the return is received and processed. You will receive an email to confirm once the
                return has been completed. Refunds are in the form of original payment, such as the credit
                card used for the order. If the refund is applied to your credit card, your credit card
                company may take up to 14 additional business days to post the refund to your account.
            </div>
            <h5>How to exchange an item</h5>
            <div>
                <ol>
                    <li>
                        <div className="num">1.</div>
                        <div>
                            To begin your exchange, send an email to{' '}
                            <a href="returns@metasill.io" onClick={e => e.preventDefault()}>
                                returns@metasill.io
                            </a>{' '}
                            requesting an exchange. Make sure to include your order number (mandatory),
                            specify the item(s) you wish to exchange (mandatory), and optionally, provide the
                            reason for the exchange.
                        </div>
                    </li>
                    <li>
                        <div className="num">2.</div>
                        <div>
                            Once we receive your exchange request, we will process it in accordance with our
                            return/exchange policy. If your item qualifies for an exchange, we will email you
                            a prepaid return shipping label, return packing slip, and return instructions.
                        </div>
                    </li>
                    <li>
                        <div className="num">3.</div>
                        <div>
                            Gather the item you want to exchange, along with the original contents and
                            packaging.
                        </div>
                    </li>
                    <li>
                        <div className="num">4.</div>
                        <div>
                            Pack your return securely, preferably using the original shipping package, and
                            include the return packing slip inside.
                        </div>
                    </li>
                    <li>
                        <div className="num">5.</div>
                        <div>Include all original packaging and accessories.</div>
                    </li>
                    <li>
                        <div className="num">6.</div>
                        <div>
                            Affix the provided return shipping label to the package, making sure no other
                            tracking labels are showing.
                        </div>
                    </li>
                    <li>
                        <div className="num">7.</div>
                        <div>
                            Follow any additional return instructions provided in the email sent to you.
                        </div>
                    </li>
                    <li>
                        <div className="num">8.</div>
                        <div>Ship the return package using the provided shipping label.</div>
                    </li>
                </ol>
            </div>
            <h5 className="left">What happens next</h5>
            <div className="bottom left">
                We will bill your credit card for your replacement item and ship it to you.{' '}
            </div>
            <div className="left">
                We will refund the cost of the returned item, deducting any applicable fees, within 5 business
                days after the return is received and processed. Refunds are in the form of original payment,
                such as the credit card used for the order. If the refund is applied to your credit card, your
                credit card company may take up to 14 additional business days to post the refund to your
                account.
            </div>
            <div className="italic">We reserve the right to amend the above terms at any time.</div>
            <div className="italic">Effective Date: October 3, 2022</div>
        </Wrapper>
    )
}

const Wrapper = styled(PageContainer)`
    width: 100%;
    padding: 50px 200px 100px 200px;
    padding-bottom: 10rem;
    font-family: Roboto-Regular;
    font-size: 16px;
    color: #000000;
    line-height: 24px;

    h1 {
        font-family: Roboto-Bold;
        font-size: 36px;
        color: #000000;
        line-height: 28px;
    }
    h5 {
        margin: 30px 0 20px 0;
        font-family: Roboto-Bold;
        font-size: 18px;
        color: #000000;
        line-height: 24px;
    }
    .list {
        padding-left: 16px;
    }
    li {
        list-style-type: disc;
    }
    ol li {
        list-style-type: none;
        display: flex;
    }
    .time {
        margin-top: 30px;
    }
    .italic {
        font-style: italic;
        margin: 20px 0;
    }
    .bottom {
        margin: 16px 0;
    }
    a {
        color: #0000ff;
        text-decoration: underline;
        cursor: default;
    }
    .weight {
        font-weight: 600;
        font-family: Roboto-Bold;
    }
    .left {
        margin-left: 16px;
    }
    .num {
        width: 16px;
        flex-shrink: 0;
    }

    @media (max-width: 576px) {
        padding: 50px 20px 50px;
        width: 100%;
        h1 {
            font-size: 28px;
        }
    }
`
