import React, { FC } from 'react'
import styles from './index.module.css'
import defaultProduct from './assets/defaultProduct.png'
import { Durations } from 'components'

interface ProductItemProps {
    virtual?: boolean
    originUnitPrice: string
    quantity: number
    depositTotalPrice?: string
    depositUnitPrice?: string
    timeZone?: string
    duration?: string
    durations?: string[]
    snapshot: {
        image: string
        name: string
        specText: string
    }
}

export const ProductItem: FC<ProductItemProps> = ({
    originUnitPrice,
    quantity,
    snapshot: { image, name, specText }
}) => {
    return (
        <div className={styles.productInfo}>
            <img
                className={styles.productImg}
                src={image}
                alt=""
                onError={e => (e.currentTarget.src = defaultProduct)}
            />
            <div className={styles.productDesc}>
                <div className={styles.productDescName}>{name}</div>
                {specText && <div className={styles.productDescType}>{specText}</div>}
                <div className={`${styles.productDescPriceNum} productDescPriceNum`}>
                    <div className={styles.productDescPrice}>
                        <span className={styles.moneyUnit}>$</span>
                        {originUnitPrice}
                    </div>
                    <div className={styles.productDescNumInfo}>
                        <div className={styles.productDescNumImg} />
                        <div className={styles.productDescNum}>{quantity}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const ProductItemRent: FC<ProductItemProps> = ({
    virtual = false,
    originUnitPrice,
    quantity,
    depositTotalPrice,
    depositUnitPrice,
    timeZone,
    durations,
    snapshot: { image, name, specText }
}) => {
    return (
        <div className={styles.productInfo}>
            <img
                className={styles.productImg}
                src={image}
                alt=""
                onError={e => (e.currentTarget.src = defaultProduct)}
            />
            <div className={styles.productDesc}>
                <div className={styles.productDescName}>{name}</div>
                <div className={`${styles.productDescPriceNum} productDescPriceNum`}>
                    <div className={styles.rentInfo}>
                        {!virtual && <div>Deposit: $ {depositUnitPrice}</div>}
                        <div>Price: $ {originUnitPrice}</div>
                        {specText && <div>Accessories: {specText}</div>}
                        <div>
                            <Durations data={durations} />
                        </div>
                    </div>
                    <div className={styles.productDescNumInfo}>
                        <div className={styles.productDescNumImg} />
                        <div className={styles.productDescNum}>{quantity}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
