import { useCallback, useState } from 'react'
import { useMountedPromiseFn } from 'shared/hooks'
import { services } from 'core/order/data-access'
import { BaseDelivery } from 'core/delivery/models'

export const usePayBillingAddress = () => {
    const [lodaing, setLodaing] = useState<boolean>()

    const updateBillingAddressApi = useMountedPromiseFn(services.updateBillingAddress)
    const updateBillingAddress = useCallback(
        (orderCode: string, billingId: string, billing?: BaseDelivery) => {
            setLodaing(false)
            return updateBillingAddressApi(orderCode, billingId, billing)
                .then(() => {
                    setLodaing(false)
                })
                .catch(err => {
                    setLodaing(false)
                    return Promise.reject(err)
                })
        },
        [updateBillingAddressApi]
    )

    return {
        lodaing,
        methods: {
            updateBillingAddress
        }
    }
}
