import { services } from 'core/management/nft/data-access'
import { useMemoizedFn, useMountedPromiseFn, useDetailState } from 'shared/hooks'

import type { NftDetail, NftFormData } from '../models'

export const useNftDetail = () => {
    const [detail, setDetail] = useDetailState<NftDetail>()

    const createApi = useMountedPromiseFn(services.create)
    const create = useMemoizedFn((formData: NftFormData) => {
        setDetail({ loading: true })
        return createApi(formData)
            .then(detail => {
                setDetail({ loading: false })
                return detail
            })
            .catch(err => {
                setDetail({ loading: false })
                return Promise.reject(err)
            })
    })

    const editApi = useMountedPromiseFn(services.edit)
    const edit = useMemoizedFn((id: string, formData: NftFormData) => {
        setDetail({ loading: true })
        return editApi(id, formData)
            .then(() => {
                setDetail({ loading: false })
            })
            .catch(err => {
                setDetail({ loading: false })
                return Promise.reject(err)
            })
    })

    const getDetailApi = useMountedPromiseFn(services.getDetail)
    const getDetail = useMemoizedFn((id: string) => {
        setDetail({ loading: true })
        return getDetailApi(id)
            .then(detail => {
                setDetail({ loading: false, detail })
                return detail
            })
            .catch(err => {
                setDetail({ loading: false })
                return Promise.reject(err)
            })
    })

    const syncDataApi = useMountedPromiseFn(services.syncData)
    const syncData = useMemoizedFn((token_address: string, token_id: string) => {
        setDetail({ loading: true })
        return syncDataApi(token_address, token_id)
            .then(detail => {
                setDetail({ loading: false })
                return detail
            })
            .catch(err => {
                setDetail({ loading: false })
                return Promise.reject(err)
            })
    })

    const removeApi = useMountedPromiseFn(services.remove)
    const remove = useMemoizedFn((id: string) => {
        setDetail({ loading: true })
        return removeApi(id)
            .then(() => {
                setDetail({ loading: false })
            })
            .catch(err => {
                setDetail({ loading: false })
                return Promise.reject(err)
            })
    })

    return {
        ...detail,
        methods: { getDetail, syncData, create, edit, remove }
    }
}
