import React, { FC, useState } from 'react'
import styled from '@emotion/styled'

import { useSubscribe } from 'core/homepage/api/useSubscribe'
import { useApiError, useMemoizedFn, useShowMessage } from 'shared/hooks'

import { Input } from 'antd'
import { Response2Col } from 'components'

import imgTwitter from '../assets/twitter.png'
import imgInstagram from '../assets/instagram.png'
import imgYoutube from '../assets/youtube.png'

export const Footer: FC = () => {
    const [email, setEmail] = useState<string>()
    const {
        methods: { subscribe }
    } = useSubscribe()
    const {
        methods: { handleError }
    } = useApiError('ContactUs')
    const {
        methods: { error, success }
    } = useShowMessage()
    const handleSubmit = useMemoizedFn(() => {
        if (!email) {
            error('Please input your email')
            return
        }
        subscribe(email)
            .then(() => {
                success('Thanks for subscribing!')
                setEmail('')
            })
            .catch(handleError)
    })

    return (
        <div className="px-16 pt-16 md:px-24 md:pt-20" style={{ backgroundColor: '#2f2e2e' }}>
            <Response2Col
                leftNode={
                    <div>
                        <Join>
                            Join our community to get Updates and Special Discounts on our New Arrivals.
                        </Join>
                        <div className="flex mt-12">
                            <a href="https://twitter.com/metasill" target="_blank" rel="noopener noreferrer">
                                <Icon src={imgTwitter} alt="" />
                            </a>
                            <a
                                className="ml-4"
                                href="https://www.instagram.com/metasill.io/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Icon src={imgInstagram} alt="" />
                            </a>
                            <a
                                className="ml-4"
                                href="https://www.youtube.com/channel/UCgqvyRcw0ZFtFU_PaAAR2kw"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Icon src={imgYoutube} alt="" />
                            </a>
                        </div>
                    </div>
                }
                rightNode={
                    <div className="flex justify-end w-full mt-8 md:mt-0">
                        <Form className="grid mt-8 gap-y-4">
                            <div className="row-start-1 col-start-1 row-end-2 col-end-2">
                                <Input
                                    style={{ width: '44rem', height: 40, fontFamily: 'HelveticaNeue' }}
                                    name="email"
                                    type="email"
                                    placeholder="Your Email"
                                    required
                                    pattern="^.+@.+\.[a-zA-Z]{2,63}$"
                                    maxLength={250}
                                    aria-label="Your Email"
                                    value={email}
                                    onChange={({ target: { value } }) => setEmail(value)}
                                />
                            </div>
                            <div className="ml-3 row-start-1 col-start-2 row-end-2 col-end-3">
                                <div
                                    className="flex justify-center items-center w-40 text-3xl bg-white cursor-pointer"
                                    onClick={() => handleSubmit()}
                                >
                                    <span
                                        style={{
                                            height: 40,
                                            lineHeight: '40px',
                                            fontFamily: 'HelveticaNeue-Bold'
                                        }}
                                    >
                                        Join
                                    </span>
                                </div>
                            </div>
                            <div className="row-start-2 col-start-1 row-end-3 col-end-3">
                                <span />
                            </div>
                        </Form>
                    </div>
                }
            />
        </div>
    )
}

const Icon = styled.img`
    width: 24px;
    height: 24px;
`

const Join = styled.div`
    font-family: HelveticaNeue-Bold;
    font-size: 18px;
    color: #fff;
    line-height: 24px;
    font-weight: bold;
`

const Form = styled.form`
    grid-template-rows: 2fr 1fr;
    grid-template-columns: 3fr 1fr;
`
