import React, { FC, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'

import { useMemoizedFn, useResponsive, useSignInRedirect } from 'shared/hooks'
import { getWalletToken } from 'shared/config'
import { selectors } from 'core/auth/state'

import { ListHeaderItem, Spin } from 'components'
import { Owned } from './Owned'
import { SignWrapper } from './SignWrapper'
import { Upload } from './Upload'
import { Authorized } from './Authorized'

export interface StateType {
    collectionType?: string
    authorizedType?: string
}

export const Collections: FC = () => {
    const navigate = useNavigate()
    const { md } = useResponsive()
    const { pathname, state } = useLocation()
    const collectionType = (state as StateType)?.collectionType || 'owned'

    const { loginOut } = useSignInRedirect()
    const isLogin = useMemoizedFn(() => getWalletToken())
    const walletAddressList = useSelector(selectors.getUserWalletAddressList)

    useEffect(() => {
        if (!getWalletToken()) loginOut()
    }, [loginOut, walletAddressList])

    return (
        <Spin spinning={false} className="w-full">
            <CollectionsStyle>
                <ListHeader>
                    <ListHeaderItem
                        onClick={() => navigate(`${pathname}`, { state: { collectionType: 'owned' } })}
                        className={collectionType === 'owned' ? 'active' : ''}
                    >
                        Owned by me (wallet)
                    </ListHeaderItem>
                    <ListHeaderItem
                        onClick={() => navigate(`${pathname}`, { state: { collectionType: 'authorized' } })}
                        style={md ? { marginRight: 30, marginLeft: 30 } : { marginRight: 20, marginLeft: 20 }}
                        className={collectionType === 'authorized' ? 'active' : ''}
                    >
                        Shared with me
                    </ListHeaderItem>
                    <ListHeaderItem
                        onClick={() => {
                            navigate(`${pathname}`, { state: { collectionType: 'upload' } })
                        }}
                        className={collectionType === 'upload' ? 'active' : ''}
                    >
                        Uploaded by me
                    </ListHeaderItem>
                </ListHeader>
                {collectionType === 'upload' ? (
                    isLogin() ? (
                        <Upload />
                    ) : (
                        <SignWrapper isUpload={true} />
                    )
                ) : (
                    <div>
                        {isLogin() ? (
                            <WalletWrapper>
                                {collectionType === 'owned' && <Owned />}
                                {collectionType === 'authorized' && <Authorized />}
                            </WalletWrapper>
                        ) : (
                            <SignWrapper isUpload={false} />
                        )}
                    </div>
                )}
            </CollectionsStyle>
        </Spin>
    )
}

const ListHeader = styled.div`
    display: flex;
    align-items: center;
    font-family: OpenSans-Bold;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    font-weight: 700;
    margin: 30px 0;
    .active {
        background: #000000;
        color: #ffffff;
    }
    @media (max-width: 768px) {
        margin: 25px 0;
    }
`

const WalletWrapper = styled.div`
    margin: 0 auto 82px;
`

const CollectionsStyle = styled.div`
    .header {
        height: 28px;
        h2 {
            @media (max-width: 768px) {
                font-family: Roboto-Medium;
                font-size: 18px;
                color: #000000;
                letter-spacing: 0;
                font-weight: 500;
            }
        }
        .wallet-button {
            background-color: #000000;
        }
    }
    .nftheader {
        margin: 24px 0;
        @media (max-width: 768px) {
            margin: 0;
        }
    }
`
