import React, { CSSProperties, FC } from 'react'

interface VideoProps {
    src: HTMLVideoElement['src']
    classNames?: HTMLVideoElement['className']
    autoPlay?: HTMLVideoElement['autoplay']
    muted?: HTMLVideoElement['muted']
    style?: CSSProperties
}

export const Video: FC<VideoProps> = ({ src, classNames, autoPlay = true, muted = true, style }) => {
    return (
        <video
            className={classNames}
            autoPlay={autoPlay}
            muted={muted}
            controls={false}
            loop={true}
            playsInline
            x5-video-player-type="h5"
            style={style}
        >
            <source type="video/mp4" src={src} />
        </video>
    )
}
