import React, { FC, ReactNode } from 'react'
import styled from '@emotion/styled'

import { SingleButtonModalProps, useMemoizedFn } from 'shared/hooks'
import { NOTIFICATION_DURATION } from 'shared/config'

import { Modal, ModalFuncProps } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { FailIcon, SuccessIcon } from 'components'

export interface SuccessModalConfig {
    title?: string
    permanent?: boolean
    footerButtonProps?: FooterButtonProps[]
}

export const successModal = (message: string, config?: SuccessModalConfig) => {
    const modal = customModal({
        message,
        icon: <SuccessIcon />,
        footerButtonProps: config?.footerButtonProps
    })

    if (!config?.permanent) {
        setTimeout(() => modal.destroy(), NOTIFICATION_DURATION)
    }
}

export const errorModal = (message: string) => customModal({ message, icon: <FailIcon /> })

export const customSingleButtonModal = ({
    message,
    type,
    buttonText,
    onButtonClick
}: SingleButtonModalProps) =>
    customModal(
        {
            message,
            icon: type === 'success' ? <SuccessIcon /> : <FailIcon />,
            footerButtonProps: [{ text: buttonText, onClick: onButtonClick }]
        },
        {
            onCancel: onButtonClick
        }
    )

export const customModal = (props: ModalContentProps, extraModalProps?: ModalFuncProps) =>
    Modal.info({
        modalRender: () => (
            <ModalContent
                message={props.message}
                title={props.title}
                icon={props.icon}
                footerButtonProps={props.footerButtonProps}
                onCancel={extraModalProps?.onCancel}
            />
        ),
        centered: true,
        maskClosable: true,
        ...extraModalProps
    })

interface ModalContentProps {
    message: string | ReactNode
    title?: string
    icon?: ReactNode
    footerButtonProps?: FooterButtonProps[]
    onCancel?: () => void
}

const ModalContent: FC<ModalContentProps> = ({ message, title, icon, footerButtonProps, onCancel }) => {
    const onClose = useMemoizedFn(() => {
        onCancel?.()
        Modal.destroyAll()
    })
    const handleFooterButtonClick = useMemoizedFn((onClick: () => void) => {
        Modal.destroyAll()
        onClick()
    })

    return (
        <Content className="ant-modal-content">
            <div className="close-button">
                <CloseOutlined className="cursor-pointer" style={{ fontSize: 16 }} onClick={onClose} />
            </div>
            {icon && <div className="flex justify-center">{icon}</div>}
            {title && <Title>{title}</Title>}
            <Text style={{ paddingTop: title ? 10 : 25 }}>{message}</Text>
            <div className="flex justify-center">
                {footerButtonProps?.length ? (
                    footerButtonProps.map(({ text, onClick }, index) => (
                        <FooterButton
                            key={index}
                            text={text}
                            onClick={() => handleFooterButtonClick(onClick)}
                        />
                    ))
                ) : (
                    <FooterButton text="OK" onClick={onClose} />
                )}
            </div>
        </Content>
    )
}

interface FooterButtonProps {
    text: string
    onClick: () => void
}

const FooterButton: FC<FooterButtonProps> = ({ text, onClick }) => {
    return <Button onClick={onClick}>{text}</Button>
}

const Title = styled.div`
    margin-top: 13px;
    font-family: Roboto-Medium;
    font-size: 18px;
    color: #000000;
    text-align: center;
    line-height: 25px;
`

const Button = styled.div`
    margin: 0 10px;
    width: 120px;
    height: 34px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    line-height: 34px;
    color: rgba(48, 48, 48, 0.85);
    text-align: center;
    font-weight: 400;
    border: 1px solid rgba(144, 144, 144, 1);
    border-radius: 17px;
    cursor: pointer;

    :hover {
        border: 1px solid rgba(0, 0, 0, 1);
    }
`

const Text = styled.div`
    padding-bottom: 30px;
    font-family: Roboto-Regular;
    font-size: 16px;
    color: #000;
    line-height: 22px;
    font-weight: 400;
    text-align: center;
`

const Content = styled.div`
    position: relative;
    padding: 34px 23px 30px;
    background: #fff;
    border-radius: 20px;
    width: 358px;
    @media (max-width: 768px) {
        width: auto;
        margin: 0 48px;
    }

    .close-button {
        position: absolute;
        right: 14px;
        top: 14px;
        height: 16px;
        line-height: 16px;
    }
`
