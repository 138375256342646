import { useI18n } from 'core/language/api'
import { services } from 'core/product/data-access'
import { ProductHot } from 'core/product/models'
import { useListState, useMemoizedFn, useMountedPromiseFn } from 'shared/hooks'

export const useHotProducts = () => {
    const { lang } = useI18n()

    const [state, setState] = useListState<ProductHot>()

    const getListApi = useMountedPromiseFn(services.getHotList)
    const getList = useMemoizedFn(() => {
        setState({ loading: true })
        return getListApi(lang)
            .then(list => {
                setState({ list, loading: false })
                return list
            })
            .catch(err => {
                setState({ loading: false })
                return Promise.reject(err)
            })
    })

    return {
        ...state,
        methods: {
            getList
        }
    }
}
