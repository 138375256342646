import React, { ReactElement } from 'react'
import styles from './index.module.css'

interface OrderInfoRowProps {
    title: string
    content: string | ReactElement
    btnText?: string
    copyAction?: (code?: string) => void
}

export const OrderInfoRow: React.FC<OrderInfoRowProps> = ({ title, content, copyAction, btnText }) => {
    return (
        <div className={styles.orderNumInfo}>
            <div className={`${styles.orderInfoTitle}`}>{title}</div>
            <div className={styles.orderContentInfo}>
                <div className={`${styles.orderContent}`}>{content}</div>
                {typeof copyAction === 'function' && (
                    <div className={`${styles.orderCopyBtn} cursor-pointer`} onClick={() => copyAction()}>
                        {btnText}
                    </div>
                )}
            </div>
        </div>
    )
}
