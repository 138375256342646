import { useCallback } from 'react'
import { useDetailState, useMountedPromiseFn } from 'shared/hooks'
import { services } from 'core/order/data-access'
import { encryptUtils } from 'shared/utils'
import { CLIENT_MOBILE_PAY_IV, CLIENT_MOBILE_PAY_KEY } from 'shared/config'

export const usePayKey = () => {
    const [state, setState] = useDetailState<string>()

    const getStripePayKeyApi = useMountedPromiseFn(services.getStripePayKey)
    const getStripePayKey = useCallback(() => {
        setState({ loading: true })
        return getStripePayKeyApi()
            .then(key => {
                const detail = encryptUtils.decrypt(key, CLIENT_MOBILE_PAY_KEY, CLIENT_MOBILE_PAY_IV)
                setState({ detail, loading: false })
                return detail
            })
            .catch(err => {
                setState({ loading: false })
                return Promise.reject(err)
            })
    }, [getStripePayKeyApi, setState])

    return {
        ...state,
        methods: {
            getStripePayKey
        }
    }
}
