import { useCallback } from 'react'

import { useMountedPromiseFn } from 'shared/hooks'
import { uploadSellerImage } from 'shared/request'
import { pathJoin } from 'shared/utils'
import { PROJECT_PREFIX } from 'shared/config'

export const useBaseTinymce = (uploadImageFn: (formData: FormData) => Promise<string>) => {
    const uploadImageApi = useMountedPromiseFn(uploadImageFn)

    const imagesUploadHandler = (
        blobInfo: { blob: () => string | Blob; filename: () => string | undefined },
        success: (arg0: string) => void,
        failure: (arg0: string) => any,
        progress: any
    ) => {
        const formData = new FormData()
        formData.append('file', blobInfo.blob(), blobInfo.filename())

        return uploadImageApi(formData)
            .then(fileUrl => {
                success(fileUrl)
            })
            .catch(err => failure('Image upload failed due to a XHR Transport error. Code: ' + err.status))
    }

    return {
        methods: {
            imagesUploadHandler
        }
    }
}

export const useTinymce = () => {
    const uploadImage = useCallback((formData: FormData) => {
        return uploadSellerImage(formData).then(fileUrl => {
            return pathJoin(PROJECT_PREFIX, fileUrl)
        })
    }, [])

    return useBaseTinymce(uploadImage)
}
