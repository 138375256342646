import React, { FC, useCallback, useEffect } from 'react'
import styles from './index.module.css'
import { useNavigate, useParams } from 'react-router-dom'

import { useApiError, useMemoizedFn } from 'shared/hooks'
import { useI18n } from 'core/language/api'
import { useOrderDetail } from 'core/order/api'

import {
    DeliveryAddress,
    OrderActions,
    OrderAmount,
    OrderInfo,
    OrderInfoProps,
    OrderItem,
    OrderStatus,
    PageHeader,
    PageWrapper,
    Spin
} from 'components'
import styled from '@emotion/styled'

export const OrderDetail: FC = () => {
    const { id } = useParams<{ id: string }>()
    const navigate = useNavigate()

    const {
        methods: { t }
    } = useI18n()
    const {
        methods: { handleError }
    } = useApiError('OrderDetail')

    const {
        loading,
        detail,
        methods: { getDetail, cancel, confirm, remove }
    } = useOrderDetail(id!)
    useEffect(() => {
        getDetail().catch(handleError)
    }, [getDetail, handleError])

    const handleCancel = useCallback(() => cancel().catch(handleError), [cancel, handleError])

    const handleConfirm = useCallback(() => {
        confirm().catch(handleError)
    }, [confirm, handleError])

    const handleRemove = useMemoizedFn(() =>
        remove()
            .then(() => navigate('/mine/orders', { replace: true }))
            .catch(handleError)
    )

    const orderInfo = {} as OrderInfoProps
    if (detail) {
        if (detail.payTime) orderInfo['payTime'] = detail.payTime
        if (detail.deliveryTime) orderInfo['deliveryTime'] = detail.deliveryTime
        if (detail.shipping) orderInfo['shipping'] = detail.shipping
        if (detail.confirmTime) orderInfo['confirmTime'] = detail.confirmTime
        if (detail.cancelTime) orderInfo['cancelTime'] = detail.cancelTime
        if (detail.code) orderInfo['code'] = detail.code
        if (detail.createTime) orderInfo['createTime'] = detail.createTime
        if (detail.billDeliveryDetail) orderInfo['billDeliveryDetail'] = detail.billDeliveryDetail
    }

    const gotoBack = useCallback(() => {
        navigate('/mine/orders', { replace: true })
    }, [navigate])

    return (
        <Spin spinning={loading}>
            <Wrapper>
                <PageHeader title="Order Detail" className="header" onBack={gotoBack} />
                <div className={styles.body}>
                    {detail && (
                        <div className={styles.list}>
                            {/* Status Card */}
                            <OrderStatus status={detail.status} />
                            {/* Address Card */}
                            {!detail.virtual && <DeliveryAddress detail={detail.deliveryDetail} topBorder />}
                            {/* Shopping Item Information Card */}
                            <OrderItem
                                virtual={detail.virtual}
                                className={styles.orderItem}
                                type={detail.type}
                                name={detail.store.name}
                                items={detail.items}
                                icon={detail.store.icon}
                            />
                            {/* Order Remarks */}
                            {detail.userComment && (
                                <div className={styles.comment}>
                                    <div className={styles.commentLabel}>
                                        {t('client.order.remark.title')}
                                    </div>
                                    <div className={styles.commentText}>{detail.userComment}</div>
                                </div>
                            )}
                            {/* Amount Card */}
                            <OrderAmount
                                subTotal={detail.price.subTotal}
                                tax={!detail.virtual ? detail.price.tax : ''}
                                delivery={!detail.virtual ? detail.price.delivery : ''}
                                discount={detail.price.discount}
                                total={detail.price.total}
                                depositTotal={detail.price.depositTotal}
                            />
                            {/* Order Information Card */}
                            <OrderInfo {...orderInfo} />
                            {/* Operation buttons */}
                            {detail.hasActions ? (
                                <div className={styles.actionBtnWrapper}>
                                    <OrderActions
                                        status={detail.status}
                                        onCancel={handleCancel}
                                        onPay={() => navigate(`/orders/place/${detail.code}`)}
                                        onConfirm={handleConfirm}
                                        onRemove={handleRemove}
                                    />
                                </div>
                            ) : null}
                        </div>
                    )}
                </div>
            </Wrapper>
        </Spin>
    )
}

const Wrapper = styled(PageWrapper)`
    margin-bottom: 30px;
    .header {
        padding-top: 5.2rem;
        margin-bottom: 8px;
        box-sizing: content-box;
    }
    .back-button {
        width: 100px;
        height: 36px;
        margin-right: 12px;
        > div {
            font-family: Roboto-Medium;
            font-size: 18px;
            letter-spacing: 0;
            text-align: center;
            line-height: 20px;
            font-weight: 500;
        }
    }
    @media (max-width: 768px) {
        .header {
            width: calc(100% - 40px);
            margin: 0 20px 8px;
        }
    }
`
