import React, { ComponentProps, CSSProperties, FC, ReactNode } from 'react'
import styled from '@emotion/styled'

import { Image } from 'antd'
import { Response2Col, SectionContainer, SectionTitle } from 'components'

interface Container2ColProps {
    imgSrc?: string
    title: ReactNode
    textNodes: ReactNode[]
    backgroundColor?: CSSProperties['backgroundColor']
    reverse?: ComponentProps<typeof Response2Col>['reverse']
}

export const Container2Col: FC<Container2ColProps> = ({
    imgSrc,
    reverse,
    title,
    backgroundColor,
    textNodes
}) => {
    return (
        <SectionContainer
            style={{ paddingTop: 0, paddingBottom: 0, backgroundColor, maxWidth: '1550px', margin: '0 auto' }}
        >
            {imgSrc ? (
                <Response2Col
                    reverse={reverse}
                    leftNode={
                        <ImageWrapper className="pt-12 md:pt-0">
                            <Image src={imgSrc} preview={false} />
                        </ImageWrapper>
                    }
                    rightNode={
                        <div className="flex flex-col justify-center pt-12 pb-12 px-12 md:px-40">
                            <div className="my-8">
                                <SectionTitle title={title} />
                            </div>
                            {textNodes.map((node, idx) => (
                                <div className="my-4" key={idx}>
                                    {node}
                                </div>
                            ))}
                        </div>
                    }
                />
            ) : (
                <div className="flex flex-wrap">
                    <div className="flex basis-full">
                        <div className="flex flex-col justify-center pt-12 pb-12 px-12 md:px-40">
                            <div className="my-8">
                                <SectionTitle title={title} />
                            </div>
                            {textNodes.map((node, idx) => (
                                <IsolatedContent key={idx}>{node}</IsolatedContent>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </SectionContainer>
    )
}

const ImageWrapper = styled.div`
    width: 100%;
    .ant-image {
        width: 100%;
        height: 100%;
    }

    .ant-image img {
        object-fit: cover;
        height: 100%;
    }
`
const IsolatedContent = styled.div`
    .my-4 {
        margin: 1rem 0;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
        padding: 0;
        font-family: inherit;
        font-weight: inherit;
        line-height: inherit;
        color: inherit;
    }

    h1 {
        font-size: 2em;
    }

    h2 {
        font-size: 1.75em;
    }

    h3 {
        font-size: 1.5em;
    }

    h4 {
        font-size: 1.25em;
    }

    h5 {
        font-size: 1em;
    }

    h6 {
        font-size: 0.875em;
    }

    ol,
    ul,
    menu {
        list-style: revert;
        margin: revert;
        padding: revert;
    }
`
