import { pickValue, SuccessItemResponse } from 'shared/request'
import { pathJoin } from 'shared/utils'
import { clientHttp } from 'shared/request'
import { Delivery, DeliveryFormData, DeliveryTypeEnum, DeliverDefault } from 'core/delivery/models'

const URI = '/delivery'

export const getList = (type: DeliveryTypeEnum) => {
    return clientHttp.get<SuccessItemResponse<Delivery[]>>(URI, { type }).then(pickValue)
}

export const getDetail = (id: string) => {
    const url = pathJoin(URI, id)
    return clientHttp.get<SuccessItemResponse<Delivery>>(url).then(pickValue)
}

export const create = (detail: DeliveryFormData) => {
    return clientHttp.post<SuccessItemResponse<string>>(URI, detail).then(pickValue)
}

export const update = (id: string, detail: DeliveryFormData) => {
    const url = pathJoin(URI, id)
    return clientHttp.put<SuccessItemResponse<void>>(url, detail)
}

export const setdefault = (id: string, isDefault: DeliverDefault) => {
    const url = pathJoin(URI, id, '/default')
    return clientHttp.put<SuccessItemResponse<Delivery>>(url, isDefault)
}

export const remove = (id: string) => {
    const url = pathJoin(URI, id)
    return clientHttp.delete<SuccessItemResponse<void>>(url)
}
