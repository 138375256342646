import React, { FC, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'

import { useResponsive } from 'shared/hooks'

import * as echarts from 'echarts'

import { useI18n } from 'core/language/api'
import { DatePicker, Select } from 'antd'
import { AdStatisticsItem, ResultItem } from 'core/ad-statistics/models'
import no_content from '../assets/no_content.png'

interface EchartProps {
    setSelectTime: (val: string[]) => void
    roleList: string[]
    setSelectValue: (val: string) => void
    selectValue: string
    chartList?: AdStatisticsItem[]
    defaultTime: any
    setDefaultTime: (val: any) => void
    visitsOrUv: string
    setVisitsOrUv: (val: string) => void
    timeDate: string[]
    setTimeDate: (val: string[]) => void
}
interface PreType extends ResultItem {
    totalList: number[]
}

export const EChart: FC<EchartProps> = ({
    setSelectTime,
    roleList,
    setSelectValue,
    selectValue,
    chartList,
    defaultTime,
    setDefaultTime,
    visitsOrUv,
    setVisitsOrUv,
    timeDate,
    setTimeDate
}) => {
    const {
        methods: { t }
    } = useI18n()
    const { md } = useResponsive()

    const TimeList = useMemo(() => {
        if (!chartList) return []
        const list = chartList.map(({ date }) => date)
        list.sort((a, b) => Date.parse(a) - Date.parse(b))
        return list
    }, [chartList])

    const chartItemList = useMemo(() => {
        if (!chartList) return []
        let list: ResultItem[] = []
        chartList.forEach(({ resultItems }) => {
            list = [...list, ...resultItems]
        })
        return list
    }, [chartList])

    const allNum = useMemo(() => {
        if (!chartList) return 0
        let num = 0
        chartList.forEach(({ total }) => {
            num += total
        })
        return num
    }, [chartList])

    const chartData = useMemo(() => {
        const newData = chartItemList.reduce((per: PreType[], item: ResultItem) => {
            const index = per.findIndex(items => items.id === item.id)
            if (index !== -1) {
                per[index].totalList[TimeList.indexOf(item.date)] = item.total
                return per
            } else {
                const totalList = []
                totalList[TimeList.indexOf(item.date)] = item.total
                return [
                    ...per,
                    {
                        ...item,
                        totalList: totalList
                    }
                ]
            }
        }, [])
        return newData
    }, [chartItemList, TimeList])

    const option: echarts.EChartsOption = {
        title: {
            show: allNum > 0 ? true : false,
            text: visitsOrUv === 'uv' ? `UV Total: ${allNum}` : `Total: ${allNum}`,
            textStyle: {
                width: md ? 'auto' : (window.innerWidth - 375) / 5 + 60,
                fontWeight: 'normal',
                fontSize: 14,
                color: '#000',
                overflow: 'break'
            },
            left: md ? '72.3%' : '78%',
            top: 0
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            formatter: params => {
                const data = params as { value: number | undefined; color: string; seriesName: string }[]
                let num = 0
                data.filter(({ value }) => value !== undefined).forEach(({ value }) => {
                    num += value!
                })
                return `<div style="min-width:180px;">
                            <div style="margin-bottom:10px; font-family: Roboto-Medium;
                            font-size: 16px;
                            color: #000000;
                            font-weight: 500;">Total: ${num}</div>
                            ${data
                                .filter(({ value }) => value !== undefined)
                                .map(
                                    ({ color, seriesName }, index: number) =>
                                        `
                                        <div
                                            key=${index}
                                            style="display: flex;
                                                justify-content: flex-start;
                                                align-items: center;"
                                        >
                                            <div
                                                style="background-color:${color};
                                                width:10px;height:10px;border-radius: 50%;
                                                margin-right:${md ? '24px' : '10px'};"
                                            >
                                            </div>
                                            <div style="font-family: Roboto-Medium;
                                            font-size: 16px;
                                            color: #000000;
                                            white-space: break-spaces;
                                            ${!md && 'width:200px'}
                                            ">${seriesName}</div>
                                        </div>
                                `
                                )
                                .join('')}
                        </div>`
            },
            triggerOn: md ? 'mousemove' : 'click'
        },
        legend: {
            type: 'scroll',
            orient: 'vertical',
            left: md ? '72.3%' : '78%',
            top: md ? 24 : 38,
            bottom: 20,
            padding: md ? 5 : 0,
            align: 'left',
            itemGap: 10,
            textStyle: {
                width: md ? (window.innerWidth > 1200 ? 200 : 120) : 40,
                overflow: 'break'
            }
        },
        grid: {
            width: md ? '64%' : '72%',
            top: md ? 10 : 30,
            left: '4%',
            right: '22.9%',
            bottom: '2.2%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: TimeList
            }
        ],
        yAxis: [
            {
                type: 'value',
                minInterval: 1
            }
        ],

        series: chartData.map((item, index) => {
            let num = 0
            item.totalList.forEach(items => {
                num += +items
            })
            return {
                name: `${item.name}: ${num}`,
                nameTextStyle: {
                    fontFamily: 'Roboto-Medium',
                    fontSize: '16px',
                    color: '#000000',
                    fontWeight: 500
                },
                id: `${item.id}`,
                index,
                type: 'bar',
                barWidth: md ? (TimeList.length > 10 ? '18%' : '34px') : '18% ',
                stack: 'Ad',
                emphasis: {
                    disabled: true,
                    focus: 'none'
                },
                data: item.totalList
            }
        }),
        textStyle: {
            fontFamily: 'Roboto-Medium',
            fontSize: '16px',
            color: '#000000',
            fontWeight: 500
        },
        dataZoom: {
            type: 'inside'
        }
    }

    useEffect(() => {
        const chartDom = document.getElementById('chart')
        const myChart = echarts.init(chartDom!)
        if (!md && option && option.tooltip) {
            ;(option.tooltip as any)['position'] = [30, 30]
        }
        myChart.setOption(option, true)
        window.addEventListener('resize', function () {
            myChart.resize()
        })
    }, [chartList, md, option])

    const { RangePicker: RangePickerAntd } = DatePicker
    const RangePicker: any = RangePickerAntd

    const [datePickerKey, setDatePickerKey] = useState('')

    return (
        <Container>
            <Header>
                <SearchTime>
                    <RangePicker
                        key={datePickerKey}
                        width={240}
                        height={32}
                        value={defaultTime}
                        style={{ border: '1px solid  rgba(0,0,0,0.15)', borderRadius: '2px' }}
                        onChange={(value: { format: (arg0: string) => string }[]) => {
                            setDefaultTime(value)
                            setTimeDate([value?.[0].format('YYYY-MM-DD'), value?.[1].format('YYYY-MM-DD')])
                        }}
                    />
                    <SearchButton
                        onClick={() => {
                            setSelectTime(timeDate)
                        }}
                    >
                        Search
                    </SearchButton>
                </SearchTime>
                <div className="twoSelect">
                    <Select
                        allowClear={false}
                        size="middle"
                        style={{
                            width: md ? '160px' : '142px',
                            height: '34px',
                            color: (defaultTime && defaultTime[0]) || timeDate[0] ? '#c0c0c0' : '#000',
                            border: '1px solid  rgba(0,0,0,0.15)',
                            fontFamily: 'PingFangSC - Regular',
                            fontSize: '14px',
                            fontWeight: 400
                        }}
                        popupClassName="popupClassName"
                        value={t(selectValue)}
                        onChange={(value: string) => {
                            setSelectValue(value)
                            setSelectTime([])
                            setTimeDate([])
                            setDefaultTime([])
                            setDatePickerKey(new Date().toString())
                        }}
                    >
                        {roleList.map(item => (
                            <Select.Option key={item} value={item}>
                                {t(item)}
                            </Select.Option>
                        ))}
                    </Select>
                    <Select
                        allowClear={false}
                        size="middle"
                        style={{
                            width: md ? '160px' : '142px',
                            height: '34px',
                            border: '1px solid  rgba(0,0,0,0.15)',
                            fontFamily: 'PingFangSC - Regular',
                            fontSize: '14px',
                            fontWeight: 400,
                            marginLeft: '20px'
                        }}
                        popupClassName="popupClassName"
                        value={t(visitsOrUv)}
                        onChange={(value: string) => {
                            setVisitsOrUv(value)
                        }}
                    >
                        <Select.Option key={'visits'} value="visits">
                            Visits
                        </Select.Option>
                        <Select.Option key={'uv'} value="uv">
                            UV Statistics
                        </Select.Option>
                    </Select>
                </div>
            </Header>
            <div
                id="chart"
                style={{ width: '100%', height: 320 }}
                className={chartList && chartList?.length <= 0 ? 'echarts' : ''}
            />
            {chartList && chartList.length <= 0 && (
                <div className="imageBox">
                    <img className="imgae" src={no_content} alt="adStatistics" />
                </div>
            )}
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    background: #ffffff;
    border: 1px solid rgba(218, 220, 224, 1);
    border-radius: 10px;
    padding: 36px 32px 29px;
    .imageBox {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .imgae {
        width: 232px;
        height: 140px;
    }
    .echarts {
        display: none;
    }
    @media (max-width: 768px) {
        padding: 24px 10px;
    }
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 70px;
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: none !important;
    }
    .twoSelect {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    @media (max-width: 1000px) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 30px;
        .twoSelect {
            margin-top: 20px;
        }
    }
`
const SearchTime = styled.div`
    display: flex;
    align-items: center;
    .ant-picker-separator {
        display: flex;
        align-items: center;
    }
`

const SearchButton = styled.div`
    width: 74px;
    height: 30px;
    background: #000000;
    border-radius: 2px;
    font-family: Roboto-Regular;
    font-size: 14px;
    color: #ffffff;
    line-height: 30px;
    font-weight: 400;
    text-align: center;
    margin-left: 10px;
    cursor: pointer;
`
