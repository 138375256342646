import { useRef, useState } from 'react'

import { useMemoizedFn, useMountedPromiseFn } from 'shared/hooks'
import { services } from 'core/management/nft/data-access'

import type { MyCollectionList, NftParams, NftTypeEnum } from '../models'
import { getVirtualWalletAddress } from 'shared/config'
import { usePageSize } from './usePageSize'

export const useAuthorized = (type: NftTypeEnum) => {
    const pageSize = usePageSize()
    const [loading, setLoading] = useState(false)
    const [hasMoreLoading, setHasMoreLoading] = useState(false)
    const nextToken = useRef('')
    const [hasMore, setHasMore] = useState(false)
    const virtualWalletAddress = getVirtualWalletAddress()
    const [authorizedList, setAuthorizedList] = useState<MyCollectionList[]>([])
    const [authorizedTotal, setAuthorizedTotal] = useState(0)

    const authorizedFetch = useMemoizedFn(() =>
        getAuthorized({ page_size: pageSize, type }).then(res => {
            setAuthorizedList(res.nft_list)
            nextToken.current = res.cursor_next || ''
            setHasMore(!!res.cursor_next)
            setAuthorizedTotal(res.total_nft)
        })
    )

    const authorizedLoadMore = useMemoizedFn(async () => {
        const res = await getAuthorized({
            page_size: pageSize,
            type,
            offset: nextToken.current
        })
        setAuthorizedList(prev => [...prev, ...res.nft_list])
        nextToken.current = res.cursor_next || ''
        setHasMore(!!res.cursor_next)
    })

    const getAuthorizedApi = useMountedPromiseFn(services.ownedNew)
    const getAuthorized = useMemoizedFn((params: NftParams) => {
        if (params.offset) {
            setHasMoreLoading(true)
        } else {
            setLoading(true)
        }
        return getAuthorizedApi(params, virtualWalletAddress)
            .then(res => {
                setHasMoreLoading(false)
                setLoading(false)
                return res
            })
            .catch(err => {
                setLoading(false)
                setHasMoreLoading(false)
                return Promise.reject(err)
            })
    })

    return {
        loading,
        authorizedTotal,
        authorizedList,
        hasMore,
        hasMoreLoading,
        methods: {
            authorizedFetch,
            authorizedLoadMore
        }
    }
}
