import { I18nMap } from 'core/language/models'

import { en } from './en'

export const i18nMap: I18nMap = {
    'en-US': en
}

export * from './Lang'
export { en }
