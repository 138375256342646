import React, { FC, useEffect, useState, useRef } from 'react'
import styled from '@emotion/styled'

import { useI18n } from 'core/language/api'
import { useVerificationCode } from 'core/auth/api'
import { verificationCodeType as verificationCodeTypeTypes } from 'core/auth/models'
import { Spin } from 'components'

interface VerificationCodeProps {
    recaptchaToken: string
    email?: string
    phone?: string
    onResend?: () => void
    onError?: (err: any) => void
    verificationCodeType?: verificationCodeTypeTypes
}

export const VerificationCode: FC<VerificationCodeProps> = ({
    recaptchaToken,
    email = '',
    phone = '',
    onResend,
    onError,
    verificationCodeType = 'signUp'
}) => {
    const {
        methods: { t }
    } = useI18n()

    const {
        loading,
        methods: { sendAuthCode }
    } = useVerificationCode()

    const getCodeTimer = useRef<number>()
    const [authCodeCountDown, setAuthCodeCountDown] = useState(0)
    useEffect(() => {
        if (authCodeCountDown > 0) {
            if (getCodeTimer.current) {
                window.clearTimeout(getCodeTimer.current)
            }
            getCodeTimer.current = window.setTimeout(() => {
                setAuthCodeCountDown(authCodeCountDown - 1)
            }, 1000)
        }
        return () => clearTimeout(getCodeTimer.current)
    }, [authCodeCountDown, setAuthCodeCountDown])

    const [sendRecaptchaToken, setSendRecaptchaToken] = useState('')
    useEffect(() => {
        if ((email || phone) && recaptchaToken && sendRecaptchaToken !== recaptchaToken) {
            setSendRecaptchaToken(recaptchaToken)
            sendAuthCode(phone, email, recaptchaToken, verificationCodeType)
                .then(_token => {
                    setAuthCodeCountDown(60)
                })
                .catch(onError)
        }
    }, [
        recaptchaToken,
        sendAuthCode,
        verificationCodeType,
        onError,
        sendRecaptchaToken,
        setSendRecaptchaToken,
        phone,
        email
    ])

    return (
        <Spin spinning={loading} size="default">
            <Wrapper>
                {authCodeCountDown >= 1 ? (
                    <div className="mb-6 verificationCode">
                        {t('client.auth.verificationCode.countDownMsg').replace(
                            '{S}',
                            `${authCodeCountDown}`
                        )}
                    </div>
                ) : (
                    <div className="resend mb-6 verificationCode" onClick={onResend}>
                        {t('client.auth.verificationCode.emailTipsMsg')}
                    </div>
                )}
            </Wrapper>
        </Spin>
    )
}

const Wrapper = styled.div`
    box-sizing: border-box;
    .resend {
        color: #1890ff;
        cursor: pointer;
    }
    .verificationCode {
        font-family: Roboto-Regular;
    }
`
