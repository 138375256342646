import { apiHttp, pickValue, SuccessItemResponse } from 'shared/request'

import type { WalletAddress } from 'core/walletaddress/models'

const URI = '/wallet/address'

export const walletAddress = () => {
    return apiHttp.get<SuccessItemResponse<WalletAddress[]>>(URI).then(pickValue)
}

export const bind = (address: string, name: string) => {
    return apiHttp.post<SuccessItemResponse<string>>(URI, { address, name }).then(pickValue)
}

export const unbind = (id: number) => {
    return apiHttp.delete<SuccessItemResponse<WalletAddress>>(`${URI}/${id}`).then(pickValue)
}
