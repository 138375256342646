import React, { FC, useState, useEffect } from 'react'
import styled from '@emotion/styled'

import eyeIcon from '../../assets/eye.png'
import eyeClosedIcon from '../../assets/eye-closed.png'

interface Props {
    value: string
    onChange: (value: string) => void
    onFocus?: () => void
    onBlur?: () => void
    placeholder: string
    type?: 'password' | 'email'
    eyeIsClose?: boolean
    length?: number[]
}

const defaultType = 'text'

export const Input: FC<Props> = ({
    value,
    onChange,
    onFocus,
    onBlur,
    type,
    placeholder,
    eyeIsClose = true,
    length = [6, 18]
}) => {
    const [inputType, setInputType] = useState(defaultType)

    useEffect(() => {
        const inputType = type || defaultType
        if (type === 'password') {
            setInputType(eyeIsClose ? 'password' : inputType)
            return
        }
        setInputType(inputType)
    }, [eyeIsClose, type])

    return (
        <InputWrapper>
            {type === 'password' && (
                <div
                    className={`eye ${inputType === 'password' && 'close'}`}
                    onClick={() => setInputType(type => (type === 'password' ? defaultType : 'password'))}
                />
            )}
            <input
                className="input"
                type={inputType}
                minLength={length[0]}
                maxLength={length[1]}
                value={value}
                onChange={e => onChange(e.target.value)}
                onFocus={onFocus}
                onBlur={onBlur}
                autoComplete="new-password"
                placeholder={placeholder}
                style={{ borderRadius: 12 }}
            />
        </InputWrapper>
    )
}

const InputWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    .eye {
        width: 50px;
        height: 50px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background: url('${eyeIcon}') no-repeat center / 20px auto;
    }

    .eye.close {
        background-image: url('${eyeClosedIcon}');
    }
    .input {
        height: 100%;
        width: 100%;
        border: none;
        outline: none;
        text-align: center;
        background-color: transparent;
        color: #000;
        font-family: Roboto-Regular;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 24px;
        &[type='password']::-ms-reveal {
            display: none;
        }
        &:focus-visible {
            border: none;
            outline: none;
        }
        &::placeholder {
            color: #a0a0a9;
        }
    }
    @media (max-width: 576px) {
        .input,
        .input::placeholder {
            font-size: 14px;
        }
    }
`
