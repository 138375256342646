import React, { useCallback } from 'react'
import styles from './index.module.css'

import { useI18n } from 'core/language/api'
import { useMemoizedFn, useShowMessage } from 'shared/hooks'
import { copy } from 'shared/utils'

import { OrderInfoRow } from '../OrderInfoRow'

import type { Delivery } from 'core/delivery/models'
import type { OrderShipping } from 'core/order/models'

export interface OrderInfoProps {
    code: string
    createTime: string
    payTime?: string
    deliveryTime?: string
    shipping?: OrderShipping
    confirmTime?: string
    cancelTime?: string
    billDeliveryDetail?: Delivery
}

export const OrderInfo: React.FC<OrderInfoProps> = ({
    code,
    createTime,
    payTime,
    deliveryTime,
    shipping,
    confirmTime,
    cancelTime,
    billDeliveryDetail
}) => {
    const {
        methods: { t }
    } = useI18n()
    const {
        methods: { success }
    } = useShowMessage()

    const handleCopySuccess = useMemoizedFn(() => success('Copied'))

    const copyOrderCode = useCallback(() => {
        if (!code) return
        copy(code)
        handleCopySuccess()
    }, [code, handleCopySuccess])

    const copyOrderShipCode = useCallback(() => {
        if (!shipping) return
        copy(shipping.code)
        handleCopySuccess()
    }, [handleCopySuccess, shipping])

    return (
        <div className={styles.orderInfo}>
            <div className={styles.orderTitle}>{t('client.order.detail.info')}</div>
            <OrderInfoRow
                title={t('client.order.detail.code')}
                content={code}
                btnText={t('client.order.detail.copy')}
                copyAction={copyOrderCode}
            />
            <OrderInfoRow title={t('client.order.detail.time.order')} content={createTime} />
            {payTime && <OrderInfoRow title={t('client.order.detail.time.payment')} content={payTime} />}
            {deliveryTime && (
                <OrderInfoRow title={t('client.order.detail.time.delivery')} content={deliveryTime} />
            )}
            {shipping && (
                <>
                    <OrderInfoRow
                        title={t('client.order.detail.ship.code')}
                        content={shipping.code}
                        btnText={t('client.order.detail.copy')}
                        copyAction={copyOrderShipCode}
                    />
                    <OrderInfoRow
                        title={t('client.order.detail.ship.companyName')}
                        content={shipping.companyName}
                    />
                </>
            )}
            {confirmTime && (
                <OrderInfoRow title={t('client.order.detail.time.confirm')} content={confirmTime} />
            )}
            {cancelTime && <OrderInfoRow title={t('client.order.detail.time.cancel')} content={cancelTime} />}
            {billDeliveryDetail && (
                <div className={styles.orderNumInfo}>
                    <div className={styles.orderInfoTitle}>
                        <div>{t('Billing address')}</div>
                        <div>{`${billDeliveryDetail.firstName} ${billDeliveryDetail.lastName}`}</div>
                    </div>
                    {/* <div className={styles.orderContentInfo}>{billDeliveryDetail.phone}</div> */}
                    <div className={styles.orderContentInfo}>
                        {/* ${billDeliveryDetail.companyName ? `${billDeliveryDetail.companyName}, ` : ''} */}
                        {`${billDeliveryDetail.apartment ? `${billDeliveryDetail.apartment}, ` : ''}${
                            billDeliveryDetail.streetAddress
                        }, ${billDeliveryDetail.city}, ${billDeliveryDetail.state}, ${
                            billDeliveryDetail.zipCode
                        }`}
                    </div>
                </div>
            )}
        </div>
    )
}
