import { apiHttp, ItemResponse, ListResponse, pickValue } from 'shared/request'
import { SuccessResponse } from 'shared/request'

import { pathJoin } from 'shared/utils'

import {
    AdDetail,
    AdList,
    AdListQuery,
    AdQuery,
    AdRecordsList,
    AdStatistics,
    AdStatisticsItem
} from 'core/ad-statistics/models'

const URI = 'link'

export const getAdStatistics = (query: AdQuery) => {
    const url = pathJoin(URI, '/ad/total')
    return apiHttp.get<SuccessResponse<ItemResponse<AdStatistics>>>(url, { ...query }).then(pickValue)
}

export const getAdStatisticsDetail = (id: string, query: AdQuery) => {
    const url = pathJoin(URI, '/ad/total', id)
    return apiHttp.get<SuccessResponse<ListResponse<AdStatisticsItem>>>(url, { ...query }).then(pickValue)
}

export const getAdList = (params: AdListQuery) => {
    const url = pathJoin(URI)
    return apiHttp.get<SuccessResponse<ListResponse<AdList>>>(url, params).then(async res => {
        const data = res.data.data
        return {
            list: data.value,
            total: data.total
        }
    })
}

export const getAdDetail = (id: string) => {
    const url = pathJoin(URI, id)
    return apiHttp.get<SuccessResponse<ItemResponse<AdDetail>>>(url).then(pickValue)
}

export const getAdRecordsList = (id: string, params: AdListQuery) => {
    const url = pathJoin(URI, id, 'records')
    return apiHttp.get<SuccessResponse<ListResponse<AdRecordsList>>>(url, params).then(async res => {
        const data = res.data.data
        return {
            list: data.value,
            total: data.total
        }
    })
}

export const getUvAdRecordsList = (id: string, params: AdListQuery) => {
    const url = pathJoin(URI, id, 'uv/records')
    return apiHttp.get<SuccessResponse<ListResponse<AdRecordsList>>>(url, params).then(async res => {
        const data = res.data.data
        return {
            list: data.value,
            total: data.total
        }
    })
}

export const createAd = (postData: { name: string; link: string }) => {
    const url = pathJoin(URI)
    return apiHttp.post<SuccessResponse<ItemResponse<void>>>(url, postData).then(pickValue)
}

export const editAd = (id: string, putData: { name: string; link: string }) => {
    const url = pathJoin(URI, id)
    return apiHttp.put<SuccessResponse<ItemResponse<void>>>(url, putData).then(pickValue)
}
