import { useCallback, useState } from 'react'
import { logEvent } from 'firebase/analytics'

import { services } from 'core/delivery/data-access'
import { DeliveryFormData, DeliveryTypeEnum } from 'core/delivery/models'
import { useMountedPromiseFn } from 'shared/hooks'
import { analytics } from 'shared/utils'

export const useDeliveryCreate = (type?: DeliveryTypeEnum) => {
    const [loading, setLoading] = useState<boolean>(false)

    const createApi = useMountedPromiseFn(services.create)
    const create = useCallback(
        (formData: DeliveryFormData) => {
            setLoading(true)
            return createApi({ ...formData, type: type || DeliveryTypeEnum.DELIVERY })
                .then(id => {
                    logEvent(analytics, 'add_shipping_info')
                    setLoading(false)
                    console.log(id)

                    return id
                })
                .catch(err => {
                    setLoading(false)
                    return Promise.reject(err)
                })
        },
        [createApi, type]
    )

    return {
        loading,
        methods: { create }
    }
}
