import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useCheckout } from 'core/checkout/api'
import { CheckoutRequestItem } from 'core/checkout/models'
import { billInfoItem, OrderPrice, personalInfoItem } from 'core/order/models'
import {
    useMemoizedFn,
    useMountedPromiseFn,
    useShowMessage,
    useSignInRedirect,
    useUrlParams
} from 'shared/hooks'
import { useI18n } from 'core/language/api'
import { services } from 'core/event/data-access'
import { services as orderServices } from 'core/order/data-access'
import { EventBillingAddress, EventPersonalInfo } from 'core/event/models'

import { ShowSingleButtonModalMessage } from 'components'
import { getIsTourist, getPaymentClientSecret } from 'shared/config'

export const useCreateOrder = () => {
    const navigate = useNavigate()

    const {
        params: { code },
        changeParams
    } = useUrlParams({
        defaultValues: { code: '' }
    })
    const applyCouponCode = useMemoizedFn((value: string) => {
        changeParams({ code: value })
        item && calculate('', [item], value).then(setOrderPrice)
    })

    const [loading, setLoading] = useState<boolean>(false)
    const { getToken } = useSignInRedirect()
    const {
        methods: { calculate }
    } = useCheckout()
    const [count, setCount] = useState(0)
    const [item, setItem] = useState<CheckoutRequestItem>()
    const [orderPrice, setOrderPrice] = useState<OrderPrice>()
    const addQuantity = useMemoizedFn(
        () =>
            item &&
            calculate('', [{ ...item, quantity: count + 1 }], code).then(orderPrice => {
                setOrderPrice(orderPrice)
                setCount(count + 1)
            })
    )
    const reduceQuantity = useMemoizedFn(
        () =>
            item &&
            count > 1 &&
            calculate('', [{ ...item, quantity: count - 1 }], code).then(orderPrice => {
                setOrderPrice(orderPrice)
                setCount(count - 1)
            })
    )
    const calcPriceForChangeDuration = useMemoizedFn(async (durations: string[]) => {
        item && calculate('', [{ ...item, duration: durations.join(',') }], code).then(setOrderPrice)
    })

    const {
        lang,
        methods: { t }
    } = useI18n()
    const {
        methods: { showSingleButtonModal }
    } = useShowMessage()
    const payOrderFormRef = useRef<any>()
    const handleRedirect = useMemoizedFn((orderCode?: string) =>
        navigate(getToken() ? `/orders/${orderCode}` : '/', { replace: true })
    )
    const handleCannotPay = useMemoizedFn((orderCode: string, errMsg?: string) => {
        showSingleButtonModal({
            message: errMsg || t('error.cannot_pay'),
            type: 'fail',
            buttonText: getToken() ? t('View order') : t('OK'),
            onButtonClick: () => handleRedirect(orderCode)
        })
    })
    const payByCard = useMemoizedFn((orderCode: string) => {
        payOrderFormRef?.current.submit((error?: string) => {
            // payByCardLogEvent(orderCode)
            setTimeout(() => {
                if (error) {
                    handleCannotPay(orderCode, error)
                    return
                }
                showSingleButtonModal({
                    message: (
                        <ShowSingleButtonModalMessage
                            title={t('client.order.pay.success')}
                            context={t('client.order.pay.success_message')}
                        />
                    ),
                    type: 'success',
                    buttonText: getToken() ? t('View order') : t('OK'),
                    onButtonClick: () => handleRedirect(orderCode)
                })
            }, 100)
        })
    })
    const createOrderApi = useMountedPromiseFn(orderServices.create)
    const touristCreateApi = useMountedPromiseFn(orderServices.touristCreate)
    const createOrderbyGuestApi = useMountedPromiseFn(services.createOrder)
    const createOrder = useMemoizedFn(
        ({
            personalInfoTourist,
            billInfoTourist,
            personalInfo,
            billInfo,
            billingDeliveryId
        }: {
            personalInfoTourist?: personalInfoItem
            billInfoTourist?: billInfoItem
            personalInfo?: EventPersonalInfo
            billInfo?: EventBillingAddress
            billingDeliveryId?: string
        }) => {
            if (!item) return
            setLoading(true)
            return (() => {
                return getToken()
                    ? getIsTourist()
                        ? touristCreateApi(
                              {
                                  items: [item],
                                  commentOfStore: {},
                                  couponCode: code,
                                  deliveryType: 0,
                                  billInfo: billInfoTourist,
                                  personalInfo,
                                  clientSecret: getPaymentClientSecret()
                              },
                              lang
                          )
                        : createOrderApi(
                              {
                                  deliveryId: '',
                                  items: [item],
                                  commentOfStore: {},
                                  couponCode: code,
                                  billingDeliveryId: billingDeliveryId,
                                  clientSecret: getPaymentClientSecret()
                              },
                              lang
                          )
                    : createOrderbyGuestApi(lang, [item], personalInfo!, billInfo!, code)
            })()
                .then(orderIds => {
                    payByCard(orderIds[0])
                })
                .catch(err => {
                    setLoading(false)
                    return Promise.reject(err)
                })
        }
    )

    return {
        loading,
        count,
        item,
        orderPrice,
        payOrderFormRef,
        methods: {
            applyCouponCode,
            setCount,
            setItem,
            addQuantity,
            reduceQuantity,
            createOrder,
            setOrderPrice,
            calcPriceForChangeDuration
        }
    }
}
