import React from 'react'
import { render } from 'react-dom'

import './recaptcha'

import 'antd/dist/antd.less'
import './index.css'

import App from './App'

render(<App />, document.getElementById('root'))
