import { useLayoutEffect } from 'react'

import { setToken, setWalletToken } from 'shared/config'
import { useUrlParams } from './useUrlParams'

export const useInitWalletInApp = () => {
    const {
        params: { token, wallet_token },
        changeParams
    } = useUrlParams<{ token: string; wallet_token: string }>({
        defaultValues: { token: '', wallet_token: '' }
    })

    useLayoutEffect(() => {
        if (token !== '' && wallet_token !== '') {
            setToken(token)
            setWalletToken(wallet_token)
            changeParams({ token: '', wallet_token: '' })
        }
    }, [changeParams, token, wallet_token])
}
