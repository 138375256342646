import React, { FC, useMemo } from 'react'
import styled from '@emotion/styled'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

import { Text } from '@mantine/core'
import { DivImage, PageContainer } from 'components'

import type { ScanRentPaymentSuccess } from 'core/order/models'

import successIcon from './assets/success.png'
import imgGoogle from 'assets/google.png'
import imgApple from 'assets/apple.png'

export const PaymentSuccess: FC = () => {
    const { state } = useLocation()

    const email = useMemo(() => (state as ScanRentPaymentSuccess)?.email, [state])
    const password = useMemo(() => (state as ScanRentPaymentSuccess)?.password, [state])
    const endTime = useMemo(() => (state as ScanRentPaymentSuccess)?.endTime, [state])

    return (
        <PaymentSuccessWrapper>
            <SuccessImg>
                <img width={72} height={72} src={successIcon} alt="ok" />
            </SuccessImg>
            <Header>Payment successful</Header>
            <Title>Thank you, your order has been placed.</Title>
            <Content>
                <div className="accountTitle">Pay-To-Show Ends On</div>
                <div className="accountContent" style={{ color: '#000' }}>
                    {moment(endTime).format('MM/DD/YYYY HH:mm')}
                </div>
                {email && <div className="accountTitle">Your MetaSill Account</div>}
                {email && <div className="accountContent">{email}</div>}
                {password && <div className="accountTitle">Login Password</div>}
                {password && <div className="accountContent">{password}</div>}
            </Content>
            <Title>
                {password ? (
                    <>
                        Please <span style={{ fontFamily: 'Roboto-Bold' }}>record your account info</span>,
                        download MetaSill app, and set up your display (you can also do that in your MetaSill
                        account on the web).
                    </>
                ) : (
                    'Download MetaSill app and set up your display (you can also do that in your MetaSill account on the web).'
                )}
            </Title>

            <Footer>
                <a
                    href="https://apps.apple.com/us/app/metasill/id1612433405"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <DivImage src={imgApple} width={22} height={8} />
                </a>
                <a
                    href="https://play.google.com/store/apps/details?id=com.metasill.metasill"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <DivImage className="ml-10" src={imgGoogle} width={22} height={8} />
                </a>
            </Footer>
        </PaymentSuccessWrapper>
    )
}

const Content = styled.div`
    font-family: OpenSans-Bold;
    font-size: 16px;
    color: #4a4a4a;
    margin-bottom: 20px;
    .accountTitle {
        font-family: Roboto-Bold;
        font-size: 20px;
        color: #000000;
        text-align: center;
        font-weight: 700;
        margin-bottom: 10px;
    }
    .accountContent {
        font-family: Roboto-Bold;
        font-size: 20px;
        color: #0091ff;
        text-align: center;
        font-weight: 700;
        margin-bottom: 16px;
    }
`

const Title = styled(Text)`
    font-family: Roboto-Regular;
    font-size: 16px;
    color: #000000;
    text-align: center;
    font-weight: 400;
    margin-bottom: 16px;
`

const Header = styled.div`
    font-family: Roboto-Regular;
    font-size: 20px;
    color: #000000;
    text-align: center;
    font-weight: 400;
    margin-bottom: 16px;
    @media (max-width: 768px) {
        font-size: 18px;
        padding: 0 40px;
    }
`

const Footer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    img {
        width: 150px;
        height: 46px;
    }
`

const SuccessImg = styled.div`
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const PaymentSuccessWrapper = styled(PageContainer)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    @media (max-width: 768px) {
        width: 100%;
        /* flex-wrap: wrap; */
        padding: 50px 20px;
    }
`
