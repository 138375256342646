import { action, payload } from 'ts-action'
import { getActionType } from '../constants'
import { User } from 'core/auth/models'
import { WalletAddress } from 'core/walletaddress/models'

export const setUser = action(getActionType('Set User'), payload<{ user: User }>())

export const clearUser = action(getActionType('Clear User'))

export const setUserAccessToken = action(
    getActionType('Set User Access Token'),
    payload<{ userAccessToken: string }>()
)

export const clearUserAccessToken = action(getActionType('Clear User Access Token'))

export const setWalletAddressList = action(
    getActionType('Set User Wallet Addresses'),
    payload<{ walletAddressList: WalletAddress[] }>()
)

export const clearWalletAddressList = action(getActionType('Clear User Wallet Addresses'))
