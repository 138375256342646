import React, { forwardRef, useMemo, useImperativeHandle, MutableRefObject, FC } from 'react'
import styled from '@emotion/styled'
import { loadStripe } from '@stripe/stripe-js'
import { Elements, useElements, useStripe, PaymentElement } from '@stripe/react-stripe-js'

import { setPaymentClientSecret } from 'shared/config'
import { useI18n } from 'core/language/api'
import { useMount } from 'shared/hooks'

import { usePaymentSecrets } from 'core/order/api/lib/usePaymentSecrets'

export interface PayOrderFormRefProps {
    submit: (fn: (error?: string) => void) => void
}

interface PayOrderFormProps {
    setCardPay?: (value: boolean) => void
    setComplete: (complete: boolean) => void
}

export const Pay = forwardRef<PayOrderFormRefProps, PayOrderFormProps>(({ setCardPay, setComplete }, ref) => {
    const {
        methods: { t }
    } = useI18n()

    const stripe = useStripe()
    const elements = useElements()

    useImperativeHandle(ref, () => ({
        submit: fn => {
            if (!stripe || !elements) return

            stripe
                .confirmPayment({
                    elements,
                    confirmParams: {
                        payment_method_data: {
                            billing_details: {
                                address: { country: 'US' }
                            }
                        }
                    },
                    redirect: 'if_required'
                })
                .then(({ paymentIntent, error }) => {
                    console.log('paymentIntent => ', paymentIntent)

                    if (error) {
                        if (error.type === 'card_error' || error.type === 'validation_error') {
                            // alertError(error, 'error type in if')
                            fn(error.message)
                            return
                        }
                        // alert('error type not in if => ' + error.message)
                        fn(t('client.order.pay.error.fail'))
                        return
                    }
                    fn()
                })
                .catch(error => {
                    // alert('error caught => ' + error.message)
                    fn(t('client.order.pay.error.fail'))
                })
        }
    }))

    return (
        <PayWrapper>
            <PaymentElement
                options={{
                    fields: {
                        billingDetails: {
                            address: { country: 'never' }
                        }
                    }
                }}
                onChange={e => {
                    console.log('stripe payment type => ', e.value.type)
                    setCardPay?.(e.value.type === 'card')
                    setComplete(e.complete)
                }}
            />
        </PayWrapper>
    )
})

const PayWrapper = styled.div`
    .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 5.6rem;
        padding: 0 17px;
        font-family: PingFangSC-Semibold;
        font-size: 16px;
        color: #303030;
        letter-spacing: 0;
        line-height: 20px;
        font-weight: 600;
        border-bottom: 1px solid rgba(218, 218, 218, 1);
        img {
            width: 16px;
            height: 16px;
            margin-right: 6px;
        }
    }

    .form {
        padding: 30px;
    }

    .flexWrap {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        .item {
            width: 47%;
        }
    }

    .item {
        margin-bottom: 3rem;
    }

    .input {
        margin: 0;
        background-color: #f5f5f5;
        font-size: 16px;
        color: #000;
        letter-spacing: 0;
        line-height: 20px;
    }
    .input:focus {
        outline: none;
        border: 1px solid rgba(0, 0, 0, 1);
        box-shadow: none;
    }
    .input.error:focus {
        color: #c24914;
    }
    .label {
        margin-bottom: 10px;
        font-family: HelveticaNeue;
        font-size: 17px;
        color: #000000;
        letter-spacing: 0;
        line-height: 26px;
        font-weight: 400;
    }
    .cardInput {
        width: 100%;
        height: 40px;
        padding: 0 10px;
        border: 1px solid rgba(0, 0, 0, 1);
        font-family: sans-serif;
        border-radius: 0 !important;
    }

    .input::placeholder {
        font-size: 16px;
        color: #cccccc;
        letter-spacing: 0;
        line-height: 20px;
        font-family: sans-serif;
    }
    .input.error {
        border-color: #c24914;
    }
    @media (max-width: 768px) {
        .title {
            height: 55px;
        }
        .item {
            margin-bottom: 0;
            padding-top: 30px;
        }
        .form {
            padding: 0px 17px 30px 17px;
        }
        .label {
            font-size: 17px;
            font-family: Roboto-Regular;
            color: #000000;
            letter-spacing: 0;
            line-height: 26px;
        }
    }
`

interface PayContainerProps {
    innerRef: MutableRefObject<PayOrderFormRefProps>
    setCardPay?: (value: boolean) => void
    setComplete: (complete: boolean) => void
    param: { amount: number } | { orderCode: string }
}

export const PayContainer: FC<PayContainerProps> = ({ innerRef, param, setCardPay, setComplete }) => {
    const {
        detail,
        methods: { getStripeSecrets }
    } = usePaymentSecrets()

    const stripePromise = useMemo(() => {
        if (!detail) return
        return loadStripe(detail.secretKey)
    }, [detail])

    useMount(() => {
        param && getStripeSecrets(param).then(({ clientSecret }) => setPaymentClientSecret(clientSecret))
    })
    console.log(detail)

    if (!stripePromise || !detail?.clientSecret || !param) return null

    return (
        <div>
            <Elements
                stripe={stripePromise}
                options={{
                    appearance: {
                        theme: 'stripe',
                        variables: {
                            borderRadius: '0',
                            colorPrimary: '#000',
                            colorPrimaryText: '#000',
                            fontSizeBase: '16px',
                            fontLineHeight: '40px',
                            fontFamily: 'Roboto-Medium',
                            colorTextPlaceholder: '#ccc'
                        },
                        rules: {
                            '.Label': {
                                fontSize: '17px',
                                fontFamily: 'Roboto-Regular',
                                fontWeight: 'bold',
                                color: '#000',
                                letterSpacing: '0',
                                lineHeight: '26px'
                            },
                            '.Input': {
                                border: '1px solid rgba(0, 0, 0, 1)'
                            },
                            '.Input::placeholder': {
                                fontFamily: 'Roboto-Regular',
                                fontSize: '16px',
                                color: '#CCCCCC',
                                lineHeight: '20px'
                            }
                        }
                    },
                    locale: 'en',
                    clientSecret: detail.clientSecret
                }}
            >
                <Pay ref={innerRef} setCardPay={setCardPay} setComplete={setComplete} />
            </Elements>
        </div>
    )
}
