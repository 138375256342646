import React, { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { logEvent } from 'firebase/analytics'

import { SIGN_STATUS_ENUM } from 'core/auth/models'
import { useAff } from 'core/aff/api'
import { CommissionItem } from 'core/aff/models'
import { getIsTourist } from 'shared/config'
import { useResponsive, useListSearchForm, useShowMessage, useMemoizedFn } from 'shared/hooks'
import { analytics, convertToDollar, copy } from 'shared/utils'

import { Table } from 'antd'
import { Header } from '../components/header'

import click from '../assets/click.png'
import registration from '../assets/registration.png'
import rate from '../assets/rate.png'
import bg from '../assets/bg.png'
import bgMobile from '../assets/bg_mobile.png'
import bgTourist from '../assets/bg_tourist.png'

import type { ColumnsType } from 'antd/es/table'

interface Props {
    userid: string
    link: string
    shareClickCount: string
    shareRegisterCount: string
    totalAmount: number
    withdrawAmount: number
    ingAmount: number
    commissionRate: string
}

const PAGE_SIZE = 10
const defaultSearchValues = {
    pageNum: '1',
    pageSize: `${PAGE_SIZE}`
}

export const Aff: FC<Props> = ({
    userid,
    link,
    shareClickCount,
    shareRegisterCount,
    totalAmount,
    withdrawAmount,
    ingAmount,
    commissionRate
}) => {
    const { md } = useResponsive()
    const navigate = useNavigate()
    const {
        methods: { success }
    } = useShowMessage()
    const {
        total,
        list,
        methods: { getCommission }
    } = useAff()

    const { params, onChangePage } = useListSearchForm(defaultSearchValues, false)
    useEffect(() => {
        getCommission(params)
    }, [getCommission, params])
    const columns: ColumnsType<CommissionItem> = [
        {
            title: 'Purchase date',
            dataIndex: 'createTime'
        },
        {
            title: 'Order',
            dataIndex: 'productName',
            render: (productName: string[]) => {
                return productName.map((item, index) => <div key={index}>{item}</div>)
            }
        },
        {
            title: 'Amount',
            dataIndex: 'totalPrice',
            render: (totalPrice: string) => {
                return `$${convertToDollar(totalPrice)}`
            }
        },
        {
            title: 'Commission',
            dataIndex: 'value',
            render: (value: string) => {
                return `$${convertToDollar(value)}`
            }
        },
        {
            title: 'State',
            dataIndex: 'isGive',
            render: (isGive: boolean) => {
                return isGive ? 'Confirmed' : 'Confirming'
            }
        }
    ]

    const shareLogEvent = useMemoizedFn(() => {
        logEvent(analytics, 'share', {
            content_type: 'affLink',
            item_id: userid
        })
    })
    const handleCopy = (link: string) => {
        copy(link)
        shareLogEvent()
        success(
            `If the link is copied and another user purchases through the link in the same session, you will receive a ${commissionRate}% commission!`,
            { title: 'Copy link successfully', permanent: true }
        )
    }
    return (
        <Wrapper>
            <Header name="Affiliate link" />
            <div className="link">
                {getIsTourist() ? (
                    md ? (
                        <>
                            <img src={bg} style={{ minHeight: '120px' }} alt="" />
                            <div className="touristContent">
                                <div className="left">
                                    <div className="name">My Link</div>
                                    <div
                                        className="btn"
                                        onClick={() => navigate(`/auth/${SIGN_STATUS_ENUM.TOURIST_BINGING}`)}
                                    >
                                        Generate
                                    </div>
                                </div>
                                <div className="right">
                                    Click "Generate" button and obtain your unique affiliate link. Start
                                    earning commissions today.
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <img src={bgTourist} style={{ height: '208px' }} alt="" />
                            <div className="content tourist">
                                <div className="name">My Link</div>
                                <div className="options">
                                    <div className="url">
                                        Click "Generate" button and obtain your unique affiliate link. Start
                                        earning commissions today.
                                    </div>
                                    <div
                                        className="btn"
                                        onClick={() => navigate(`/auth/${SIGN_STATUS_ENUM.TOURIST_BINGING}`)}
                                    >
                                        Generate
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                ) : (
                    <>
                        <img src={md ? bg : bgMobile} style={{ height: md ? '120px' : '' }} alt="" />
                        <div className="content">
                            <div className="name">My Link</div>
                            <div className="options">
                                <div className="url">{link}</div>
                                <div className="btn" onClick={() => handleCopy(link)}>
                                    Share
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className="Withdrawal">
                <div className="item">
                    <div className="name">Available Commissions Balance</div>
                    <div className="value">
                        <span>$</span>
                        {convertToDollar(totalAmount)}
                    </div>
                    <button className="btn disabled">Withdraw</button>
                </div>
                <div className="line"></div>
                <div className="item">
                    <div className="name">Commissions Pending Maturation</div>
                    <div className="value">
                        <span>$</span>
                        {convertToDollar(ingAmount)}
                    </div>
                </div>
                <div className="line"></div>
                <div className="item">
                    <div className="name">Total Amount Withdrawn</div>
                    <div className="value">
                        <span>$</span>
                        {convertToDollar(withdrawAmount)}
                    </div>
                </div>
            </div>
            <div className="statistics">
                <div className="title">
                    <div className="name">
                        <img src={click} alt="" />
                        Statistics of clicks
                    </div>
                    <div className="name">
                        <img src={registration} alt="" />
                        Registrations
                    </div>

                    <div className="name">
                        <img src={rate} alt="" />
                        Conversion rate
                    </div>
                </div>
                <div className="data">
                    <div className="value">{shareClickCount}</div>
                    <div className="value">{shareRegisterCount}</div>
                    <div className="value">
                        {Number(shareClickCount) === 0
                            ? Number(shareClickCount)
                            : ((Number(shareRegisterCount) / Number(shareClickCount)) * 100).toFixed(2)}
                        %
                    </div>
                </div>
            </div>
            <Table
                rowKey={'id'}
                style={{ fontFamily: 'Roboto-Regular' }}
                className="affTable"
                scroll={{ x: 768 }}
                columns={columns}
                dataSource={list}
                pagination={{
                    total,
                    pageSize: Number(params.pageSize),
                    current: Number(params.pageNum),
                    onChange: onChangePage,
                    showSizeChanger: false
                }}
            />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    .link {
        position: relative;
        width: 100%;
        margin: 30px 0;
        img {
            width: 100%;
            height: auto;
        }
        .content {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            width: 100%;
            height: 100%;
            padding: 20px 40px;
            .name {
                padding-left: 20px;
                font-family: Roboto-Bold;
                font-size: 30px;
                color: #ffffff;
                letter-spacing: 0;
                line-height: 30px;
            }
            .options {
                display: flex;
            }
            .url {
                min-width: 543px;
                height: 36px;
                padding: 0 20px;
                background: rgba(0, 0, 0, 0.4);
                border-radius: 18px;
                font-family: Roboto-Medium;
                font-size: 18px;
                color: #ffffff;
                line-height: 36px;
            }
            .touristUrl {
                padding: 10px;
                height: auto;
            }
            .btn {
                width: 140px;
                height: 36px;
                margin-left: 14px;
                background: #ffffff;
                border-radius: 25px;
                font-family: Roboto-Medium;
                font-size: 18px;
                color: #000000;
                text-align: center;
                line-height: 36px;
                cursor: pointer;
            }
        }
        .tourist {
            padding: 18px 17px;
            .url {
                padding: 10px;
                height: auto;
            }
        }
        .touristContent {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0 60px;
            .left {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-right: 40px;
            }
            .name {
                margin-bottom: 18px;
                font-family: Roboto-Bold;
                font-size: 30px;
                color: #ffffff;
                letter-spacing: 0;
                text-align: center;
                line-height: 30px;
                font-weight: 700;
            }
            .btn {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 140px;
                height: 36px;
                background: #ffffff;
                border-radius: 25px;
                font-family: Roboto-Medium;
                font-size: 18px;
                color: #000000;
                letter-spacing: 0;
                cursor: pointer;
            }
            .right {
                width: 560px;
                height: 90px;
                padding: 13px 26px;
                background: rgba(0, 0, 0, 0.4);
                border-radius: 18px;
                font-family: Roboto-Medium;
                font-size: 24px;
                color: #ffffff;
                letter-spacing: 0;
                line-height: 32px;
            }
        }
    }
    .Withdrawal {
        display: flex;
        align-items: center;
        width: 100%;
        height: 164px;
        border: 1px solid #ccc;
        .item {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 33.33%;
            height: 100%;
            font-family: Roboto-Regular;
            font-size: 14px;
            color: #000000;
        }
        .name {
            margin-bottom: 10px;
            line-height: 16px;
        }
        .value {
            display: flex;
            align-items: center;
            font-size: 30px;
            line-height: 30px;
            font-family: Roboto-Bold;
            span {
                display: block;
                margin-right: 4px;
                padding-top: 4px;
                font-size: 16px;
                font-family: Roboto-Regular;
            }
        }
        .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 140px;
            height: 36px;
            margin-top: 20px;
            background: #fff;
            border: 1px solid rgba(0, 0, 0, 1);
            color: #000;
            border-radius: 25px;
            font-family: Roboto-Medium;
            font-size: 18px;
            cursor: pointer;
        }
        .btn.disabled {
            border: 1px solid #ccc;
            color: #ccc;
            cursor: not-allowed;
        }
        .line {
            width: 1px;
            height: 100px;
            background-color: #ccc;
        }
    }
    .statistics {
        margin: 30px 0;
        .title {
            display: flex;
            align-items: flex-start;
        }
        .data {
            display: flex;
            align-items: center;
        }
        .name {
            display: flex;
            justify-content: center;
            font-family: Roboto-Regular;
            width: 33.33%;
            font-size: 14px;
            color: #000000;
            line-height: 16px;
        }
        .value {
            width: 33.33%;
            font-family: Roboto-Medium;
            font-size: 24px;
            color: #000000;
            letter-spacing: 0;
            text-align: center;
        }
        img {
            width: 16px;
            height: 16px;
            margin-right: 4px;
        }
    }
    .affTable .ant-pagination-item-link {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media (max-width: 768px) {
        width: 100%;
        .link {
            .content {
                padding: 18px 17px;
                .name {
                    text-align: center;
                    padding-left: 0;
                }
                .options {
                    display: block;
                }
                .url {
                    min-width: 100%;
                    width: 100%;
                    height: auto;
                    margin-top: 10px;
                    word-break: break-all;
                    font-size: 17px;
                    line-height: 22px;
                    text-align: center;
                    padding: 7px 20px;
                }
                .btn {
                    margin: 0 auto;
                    margin-top: 10px;
                }
            }
        }
        .Withdrawal {
            height: auto;
            flex-direction: column;
            .item {
                width: 100%;
                padding: 30px 0;
            }
            .line {
                width: 80%;
                height: 1px;
                background-color: #ccc;
            }
        }
        .statistics {
            margin: 24px 0;
            .name {
                font-size: 10px;
            }
            .value {
                font-size: 17px;
            }
        }
    }
`
