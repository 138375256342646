import styled from '@emotion/styled'
import { Button } from 'components'

export const DeleteButton = styled(Button)`
    width: 100%;
    height: 50px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    background: #000000;
    border-radius: 35.18px;
    font-family: Roboto-Medium;
    font-size: 25px;
    @media (max-width: 768px) {
        width: 222px;
        height: 50px;
        color: #ffffff;
        text-align: center;
        line-height: 50px;
        cursor: pointer;
        border-radius: 24.5px;
        font-family: OpenSans-SemiBold;
        font-size: 16px;
        font-weight: 600;
    }
`
