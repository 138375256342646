import React, { FC, useMemo } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'

import { useI18n } from 'core/language/api'
import { SIGN_STATUS_ENUM } from 'core/auth/models'

import { SignInEmail } from './SignInEmail'
import { SignUpEmail } from './SignUpEmail'
import { ForgetPasswordEmail } from './ForgetPasswordEmail'
import { TouristBinging } from './TouristBinging'

export const AuthBox: FC = () => {
    const {
        methods: { t }
    } = useI18n()
    const { type } = useParams()
    const navigate = useNavigate()

    const headerNode = useMemo(() => {
        switch (type) {
            case SIGN_STATUS_ENUM.SIGN_IN:
            case SIGN_STATUS_ENUM.SIGN_IN_BY_EMAIL:
            case SIGN_STATUS_ENUM.SIGN_UP:
            case SIGN_STATUS_ENUM.SIGN_UP_BY_EMAIL:
                return (
                    <>
                        <div
                            className={`item mr-16 ${
                                type === SIGN_STATUS_ENUM.SIGN_IN ? 'active cursor-default' : 'cursor-pointer'
                            }`}
                            onClick={() => navigate('/auth/sign_in')}
                        >
                            {t('client.auth.signIn')}
                        </div>
                        <div
                            className={`item ${
                                type === SIGN_STATUS_ENUM.SIGN_UP ? 'active cursor-default' : 'cursor-pointer'
                            }`}
                            onClick={() => navigate('/auth/sign_up')}
                        >
                            {t('client.auth.signUp')}
                        </div>
                    </>
                )
            case SIGN_STATUS_ENUM.FORGOT_PASSWORD:
            case SIGN_STATUS_ENUM.FORGOT_PASSWORD_EMAIL:
                return (
                    <>
                        <div className={`item py-4 cursor-default`}>
                            {t('client.auth.forgetPassword.actionTitle')}
                        </div>
                    </>
                )
            case SIGN_STATUS_ENUM.TOURIST_BINGING:
                return (
                    <>
                        <div className={`item py-4 cursor-default active`}>{t('Account')}</div>
                    </>
                )
            default:
                return <></>
        }
    }, [navigate, t, type])

    const formNode = useMemo(() => {
        switch (type) {
            case SIGN_STATUS_ENUM.SIGN_UP:
            case SIGN_STATUS_ENUM.SIGN_UP_BY_EMAIL:
                return (
                    <>
                        <SignUpEmail />
                    </>
                )
            case SIGN_STATUS_ENUM.FORGOT_PASSWORD:
            case SIGN_STATUS_ENUM.FORGOT_PASSWORD_EMAIL:
                return (
                    <>
                        <ForgetPasswordEmail />
                    </>
                )
            case SIGN_STATUS_ENUM.TOURIST_BINGING:
                return (
                    <>
                        <TouristBinging />
                    </>
                )
            case SIGN_STATUS_ENUM.SIGN_IN:
            case SIGN_STATUS_ENUM.SIGN_IN_BY_EMAIL:
            default:
                return (
                    <>
                        <SignInEmail />
                    </>
                )
        }
    }, [type])

    return (
        <Auth className="auth-block">
            <div className="header flex items-center justify-center">{headerNode}</div>
            {formNode}
        </Auth>
    )
}

const Auth = styled.div`
    position: relative;
    box-sizing: border-box;
    width: 100%;
    max-width: 440px;
    background: #fff;
    box-shadow: 0 2px 40px 0 rgba(178, 178, 178, 0.5);
    padding: 30px 44px;
    border-radius: 0;
    .header {
        font-family: Roboto-Medium;
        font-size: 22px;
        letter-spacing: 0;
        text-align: center;
        line-height: 30px;
        color: #000;
        padding-bottom: 30px;
        .item {
            position: relative;
            padding-bottom: 16px;
        }
        .item::after {
            position: absolute;
            bottom: 0;
            top: auto;
            left: 50%;
            margin-left: -24px;
            display: block;
            content: '';
            width: 48px;
            height: 4px;
            background-color: transparent;
            border-radius: 2px;
        }
        .active::after {
            background-color: #000;
        }
    }
    @media (max-width: 576px) {
        min-height: 100%;
        padding: 30px 25px;
        border-radius: 20px 20px 0 0;
    }
`
