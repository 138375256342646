import { useCallback } from 'react'

import { useDetailState, useMountedPromiseFn } from 'shared/hooks'
import { encryptUtils } from 'shared/utils'
import { services } from 'core/order/data-access'
import { CLIENT_MOBILE_PAY_IV, CLIENT_MOBILE_PAY_KEY } from 'shared/config'

import type { PaymentSecrets } from 'core/order/models'

export const usePaymentSecrets = () => {
    const [state, setState] = useDetailState<PaymentSecrets>()

    const getStripeSecretsApi = useMountedPromiseFn(services.getStripeSecrets)
    const getStripeSecrets = useCallback(
        (param: Parameters<typeof services.getStripeSecrets>[0]) => {
            setState({ loading: true })
            return getStripeSecretsApi(param)
                .then(({ clientSecret, secretKey }) => {
                    setState({
                        detail: {
                            clientSecret,
                            secretKey: encryptUtils.decrypt(
                                secretKey,
                                CLIENT_MOBILE_PAY_KEY,
                                CLIENT_MOBILE_PAY_IV
                            )
                        },
                        loading: false
                    })
                    return { clientSecret, secretKey }
                })
                .catch(err => {
                    setState({ loading: false })
                    return Promise.reject(err)
                })
        },
        [getStripeSecretsApi, setState]
    )

    return {
        ...state,
        methods: {
            getStripeSecrets
        }
    }
}
