import styled from '@emotion/styled'

export const DivImage = styled.div<{
    src: string
    width: number
    height: number
}>`
    background: url(${({ src }) => src}) no-repeat;
    background-size: ${({ width, height }) => `${width}rem ${height}rem`};
    width: ${({ width }) => width}rem;
    height: ${({ height }) => height}rem;
`
