import React, { FC, useEffect, useState } from 'react'
import styled from '@emotion/styled'

import { useApiError, useLoginModal, useMemoizedFn, useResponsive } from 'shared/hooks'

import { useWalletAddress } from 'core/walletaddress/api'
import { copy } from 'shared/utils'
import { stringOmitMiddle } from 'shared/utils/string'

import { Modal } from 'antd'
import { LoginModal, PageHeader, Spin } from 'components'

import copyIcon from '../assets/copy.png'
import deleteIcon from '../assets/delete.png'
import { CreateWalletModal } from '../components/CreateWalletModal'
import { ConnectWallet } from 'modules/management/nft/components/ConnectWallet'

export const WalletAddress: FC<{}> = () => {
    const { md } = useResponsive()

    const {
        methods: { handleError }
    } = useApiError('WalletAddress')

    const {
        loading: walletAddressLoading,
        list,
        methods: { getWalletAddress, bind, unBind }
    } = useWalletAddress()
    const {
        methods: { show }
    } = useLoginModal()

    const [bindModalOpen, setBindModalOpen] = useState<boolean>(false)
    const [walletAddress, setWalletAddress] = useState('')

    useEffect(() => {
        getWalletAddress().catch(handleError)
    }, [getWalletAddress, handleError])

    const handleCopy = useMemoizedFn((address: string) => {
        copy(address)
        Modal.success({
            title: <div className="logout-tips-content">Account address copied to clip board</div>,
            okText: 'OK',
            maskClosable: true,
            icon: '',
            onOk: () => {}
        })
    })

    const handleDelete = useMemoizedFn((id: number) => {
        Modal.success({
            title: (
                <div className="logout-tips-content">
                    Are you sure you want to delete this wallet address?
                </div>
            ),
            maskClosable: true,
            okText: 'Delete',
            icon: '',
            onOk: () => {
                unBind(id)
            }
        })
    })

    return (
        <div>
            <WalletHeader
                title={<HeaderTitle>Wallet</HeaderTitle>}
                right={list.length > 0 && <HeaderAdd onClick={show}>Add</HeaderAdd>}
            />
            <Spin spinning={walletAddressLoading}>
                <WalletWrapper>
                    {list?.map(({ id, name, address }) => {
                        return (
                            <WalletListItem key={id}>
                                <Title>{name}</Title>
                                <WalletAddressWrapper>
                                    <WalletAddressTitle>
                                        {md ? address : stringOmitMiddle(12, 12, address)}
                                    </WalletAddressTitle>
                                    <WalletAddressRight>
                                        <WalletAddressRightCopy onClick={() => handleCopy(address)}>
                                            <WalletIcon src={copyIcon} alt="walletAddress" />
                                        </WalletAddressRightCopy>
                                        <WalletAddressRightCopy onClick={() => handleDelete(id)}>
                                            <WalletIcon src={deleteIcon} alt="deleteIcon" />
                                        </WalletAddressRightCopy>
                                    </WalletAddressRight>
                                </WalletAddressWrapper>
                            </WalletListItem>
                        )
                    })}
                    {!list.length && (
                        <CreateWalletContent>
                            <ConnectWallet
                                onConnectSuccess={(walletAddress: string) => {
                                    setWalletAddress(walletAddress)
                                    setBindModalOpen(true)
                                }}
                            />
                        </CreateWalletContent>
                    )}
                    <LoginModal
                        onConnectSuccess={(walletAddress: string) => {
                            setWalletAddress(walletAddress)
                            setBindModalOpen(true)
                        }}
                    />
                    <CreateWalletModal
                        walletAddress={walletAddress}
                        open={bindModalOpen}
                        setOpen={setBindModalOpen}
                        onBind={bind}
                    />
                </WalletWrapper>
            </Spin>
        </div>
    )
}
const CreateWalletContent = styled.div`
    width: 362px;
    @media (max-width: 768px) {
        width: 325px;
    }
`
const WalletAddressTitle = styled.div`
    flex: 1;
    /* @media (max-width: 768px) {
        flex: 1;
    } */
`

const WalletAddressRightCopy = styled.div`
    width: 40px;
`

const WalletHeader = styled(PageHeader)`
    margin: 2px 0;
    @media (max-width: 768px) {
        margin: 0;
    }
`

const WalletAddressRight = styled.div`
    display: flex;
    width: 80px;
`

const WalletListItem = styled.div`
    width: 100%;
    height: 92px;
    padding: 20px 0;
    border-bottom: 1px solid rgba(151, 151, 151, 1);
`

const HeaderAdd = styled.div`
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 36px;
    border: 1px solid rgba(0, 0, 0, 1);
    border-radius: 22px;
    font-family: Roboto-Medium;
    font-size: 18px;
    cursor: pointer;
    @media (max-width: 768px) {
        width: 77px;
        height: 28px;
        line-height: 28px;
        border: 0.8px solid rgba(0, 0, 0, 1);
        border-radius: 17.6px;
        font-family: Roboto-Medium;
        font-size: 14.4px;
        color: #000000;
        letter-spacing: 0;
        text-align: center;
        line-height: 16px;
        font-weight: 500;
    }
`

const HeaderTitle = styled.div`
    font-family: Roboto-Medium;
    font-size: 24px;
    color: #000000;
    letter-spacing: 0;
    line-height: 28px;
    font-weight: 500;
`

const Title = styled.div`
    width: 100%;
    text-align: left;
    font-family: Roboto-Regular;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: 400;
`

const WalletWrapper = styled.div`
    margin: 10px auto 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const WalletAddressWrapper = styled.div`
    width: 100%;
    font-family: OpenSans-SemiBold;
    font-size: 16px;
    color: #000000;
    letter-spacing: 0;
    line-height: 26px;
    font-weight: 400;
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
        flex-wrap: wrap;
        font-size: 16px;
    }
`

const WalletIcon = styled.img`
    margin-left: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
`
