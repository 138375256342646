import React, { FC, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from '@emotion/styled'

import { useNftDetail } from 'core/management/nft/api/useNftDetail'
import { useApiError, useMemoizedFn, useResponsive } from 'shared/hooks'
import { NftStatusEnum } from 'core/management/nft/models'

import { Modal } from 'antd'
import { Spin, PageWrapper, OptionButtons, Tags, Media } from 'components'
import { StatusInfo } from './components/StatusInfo'

import editImg from 'assets/edit.png'
import deleteImg from 'assets/delete.png'

export const UploadDetail: FC = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const { md } = useResponsive()
    const {
        methods: { handleError }
    } = useApiError('UploadDetail')
    const {
        loading,
        detail,
        methods: { getDetail, remove }
    } = useNftDetail()
    const handleDelete = useMemoizedFn(() => {
        if (!id) return
        Modal.confirm({
            content: 'Do you confirm the deletion?',
            onOk: () =>
                remove(id)
                    .then(() => {
                        navigate(-1)
                    })
                    .catch(handleError)
        })
    })
    useEffect(() => {
        id && getDetail(id).catch(handleError)
    }, [getDetail, handleError, id])

    const optionsButtons = useMemo(() => {
        const buttons = [
            {
                icon: deleteImg,
                onClick: () => handleDelete()
            }
        ]
        if (detail?.status !== NftStatusEnum.Reviewing) {
            return [
                {
                    icon: editImg,
                    onClick: () => navigate(`/management/nft/${detail?.id}/edit`)
                },
                ...buttons
            ]
        }
        return buttons
    }, [detail, handleDelete, navigate])

    return (
        <Spin spinning={loading}>
            <Wrapper>
                {detail && (
                    <div>
                        <Content className="flex">
                            <div className="imageVideo">
                                {detail.type === 'image' && (
                                    <Media size={md ? 524 : '100%'} src={detail.fileUrl} />
                                )}
                                {detail.type === 'video' && (
                                    <DetailVideo
                                        src={detail.fileUrl}
                                        autoPlay={true}
                                        muted={true}
                                        controls={false}
                                        loop={true}
                                        playsInline
                                    />
                                )}
                            </div>
                            <ContentText className="flex-1">
                                <div className="isPopover">
                                    <StatusWrapper>
                                        <StatusInfo
                                            status={detail.status}
                                            note={detail.note}
                                            notePlacement={md ? 'leftTop' : 'bottom'}
                                            noteTrigger={md ? 'hover' : 'click'}
                                            isDirection="left"
                                        />
                                    </StatusWrapper>
                                    {!md && <OptionButtons buttons={optionsButtons} />}
                                </div>
                                <div className="nameText">
                                    <div className="flex justify-between items-start">
                                        <Title>{detail.name}</Title>
                                        {md && (
                                            <div style={{ marginTop: '12px', marginLeft: '10px' }}>
                                                <OptionButtons buttons={optionsButtons} />
                                            </div>
                                        )}
                                    </div>
                                    <Tags tags={detail.tag} />
                                    {detail.address && (
                                        <div className="info-items">
                                            <div className="item flexItem">
                                                <label style={{ flexShrink: 0 }}>Owner address :</label>
                                                <div>{detail.address}</div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="info-items">
                                        <div className="item">
                                            <label>Description:</label>
                                            <div>{detail.description}</div>
                                        </div>
                                    </div>
                                    {detail.externalUrl && (
                                        <div className="info-items">
                                            <div className="item flexItem">
                                                <label style={{ flexShrink: 0 }}>External URL:</label>
                                                <div>{detail.externalUrl}</div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </ContentText>
                        </Content>
                    </div>
                )}
            </Wrapper>
        </Spin>
    )
}

const DetailVideo = styled.video`
    width: 524px;
    height: 524px;
    object-fit: cover;
    @media (max-width: 768px) {
        height: 246px;
    }
`

const Title = styled.div`
    max-width: 480px;
    word-break: break-all;
    font-family: HelveticaNeue-CondensedBold;
    font-size: 50px;
    line-height: 50px;
    color: #000;
    letter-spacing: 0;
    @media (max-width: 768px) {
        font-size: 24px;
        line-height: 26px;
    }
`

// const SyncButton = styled.div`
//     width: 220px;
//     background: #000;
//     border-radius: 35px;
//     font-family: Roboto-Medium;
//     font-size: 25px;
//     color: #ffffff;
//     letter-spacing: 0;
//     line-height: 50px;
//     text-align: center;
//     cursor: pointer;
//     @media (max-width: 768px) {
//         margin-bottom: 80px;
//         margin-left: 24px;
//     }
// `

const StatusWrapper = styled.div`
    display: flex;
    justify-content: end;
    padding: 24px 0 20px 0;
    @media (max-width: 768px) {
        justify-content: flex-start;
        padding: 0;
    }
`

const Wrapper = styled(PageWrapper)`
    min-height: 500px;
    .info-items {
        font-family: Roboto-Regular;
        font-size: 20px;
        color: #000;
        letter-spacing: 0;
        font-weight: 400;
        .flexItem {
            display: flex;
        }
        .item {
            margin: 30px 0;
            word-break: break-word;
            label {
                color: #787878;
                display: inline-block;
                margin-right: 10px;
            }
            span {
                user-select: all;
            }
            @media (max-width: 768px) {
                margin: 20px 0;
                font-size: 16px;
            }
        }
    }
`
const Content = styled.div`
    .imageVideo {
        padding-top: 92px;
    }
    @media (max-width: 768px) {
        flex-direction: column;
        .imageVideo {
            padding-top: 0px;
        }
    }
`
const ContentText = styled.div`
    margin-left: 50px;
    @media (max-width: 768px) {
        margin: 10px 0px 0px;
        .isPopover {
            padding-right: 24px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
        }
    }
    .nameText {
        @media (max-width: 768px) {
            padding: 0px 24px 60px;
        }
    }
`
