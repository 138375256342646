import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'

import { Checkbox } from '@mantine/core'
import { Text, TextProps } from '@mantine/core'
import { Media, Spin } from 'components'
import left_active from './assets/left_active.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { useApiError, useMemoizedFn, useResponsive } from 'shared/hooks'
import { AuthorizedDeletes } from 'core/management/frame/model'
import { useFrameAuth } from 'core/management/frame/api/useFrameAuth'
import { stateType } from './List'
import no_content from '../nft/assets/no_content.png'
import no_content2 from '../nft/assets/no_content2.png'
import { removeAddressToken } from 'shared/config'
import { useAddressToken } from 'core/management/nft/api/useAddressToken'
import { AuthAddressItem } from 'core/management/nft/models'

export const AuthorizedDelete: FC = () => {
    const navigate = useNavigate()
    const { md } = useResponsive()
    const [checkedData, setCheckedData] = useState<number[]>([])
    const { state } = useLocation()
    const framesType = state && (state as stateType).framesType
    const authorizedType = state && (state as stateType).authorizedType
    const authWalletAddress = state && (state as stateType).walletAddress

    const {
        methods: { handleError }
    } = useApiError('AuthorizedDelete')

    const {
        methods: { getAddressToken }
    } = useAddressToken()

    const {
        loading,
        list,
        methods: { getFrameDeleteList, deleteAuthorized }
    } = useFrameAuth()

    useEffect(() => {
        getAddressToken({
            id: `${(authWalletAddress as AuthAddressItem).id}`,
            addressType: `${(authWalletAddress as AuthAddressItem).addressType}`,
            ownerId: `${(authWalletAddress as AuthAddressItem).ownerId}`
        })
            .then(() => {
                getFrameDeleteList()
                    .then(() => {
                        removeAddressToken()
                    })
                    .catch(handleError)
            })
            .catch(handleError)
    }, [authWalletAddress, getAddressToken, getFrameDeleteList, handleError])

    const AuthList = useMemo(() => {
        const data = list.filter(
            item => item.owner.toLowerCase() !== (authWalletAddress as AuthAddressItem).address.toLowerCase()
        )
        return data
    }, [authWalletAddress, list])
    const handleDelete = useCallback(() => {
        let data: AuthorizedDeletes[] = []
        AuthList.map(item => [
            checkedData.forEach(val => {
                if (item.id === val) {
                    data.push({
                        owner: item.owner,
                        guest: item.guest,
                        type: '3',
                        content: item.content.device_id
                    })
                }
            })
        ])
        getAddressToken({
            id: `${(authWalletAddress as AuthAddressItem).id}`,
            addressType: `${(authWalletAddress as AuthAddressItem).addressType}`,
            ownerId: `${(authWalletAddress as AuthAddressItem).ownerId}`
        })
            .then(() => {
                deleteAuthorized(data)
                    .then(() => {
                        removeAddressToken()
                        navigate('/management/frames', {
                            state: {
                                framesType,
                                authorizedType
                            }
                        })
                    })
                    .catch(handleError)
            })
            .catch(handleError)
    }, [
        AuthList,
        authWalletAddress,
        authorizedType,
        checkedData,
        deleteAuthorized,
        framesType,
        getAddressToken,
        handleError,
        navigate
    ])

    const handleClick = useMemoizedFn((id: number) => {
        setCheckedData(prevState => {
            let newData: number[]
            newData = [...prevState, id]
            const newDataLength = newData.filter(item => item === id).length
            if (newDataLength > 1) {
                newData = newData.filter(item => item !== id)
            }
            return newData
        })
    })
    return (
        <Spin spinning={loading}>
            <AuthorizedDeletePage>
                <AuthorizedDeleteHeader>
                    <HeaderTitle>
                        <img src={left_active} onClick={() => navigate(-1)} alt="left_active" />
                        <div>Delete</div>
                    </HeaderTitle>
                    {AuthList.length > 0 && <HeaderDone onClick={handleDelete}>Done</HeaderDone>}
                </AuthorizedDeleteHeader>
                <div>
                    {AuthList.length > 0 ? (
                        <FrameListWrapper>
                            {AuthList.map(
                                ({
                                    id,
                                    content: { device_id, backgroundColor, now_playing_url, device_name }
                                }) => (
                                    <ListWrapperItem key={id} onClick={() => handleClick(id)}>
                                        <div className="list-item">
                                            <CheckboxStyle>
                                                <Checkbox
                                                    color="dark"
                                                    size="lg"
                                                    key={id}
                                                    checked={checkedData?.includes(id)}
                                                    onChange={() => {}}
                                                />
                                            </CheckboxStyle>
                                            <div
                                                // onClick={() => navigate(`/management/frames/${device_id}`)}
                                                style={{
                                                    border: '2px solid rgba(0,0,0,1)',
                                                    background: `${backgroundColor}`
                                                }}
                                            >
                                                <Media size={md ? 196 : 156} src={now_playing_url} />
                                            </div>
                                            <Info>
                                                <Name lineClamp={2}>{device_name || device_id}</Name>
                                            </Info>
                                        </div>
                                    </ListWrapperItem>
                                )
                            )}
                        </FrameListWrapper>
                    ) : (
                        <UploadNoContent>
                            <img src={md ? no_content : no_content2} alt="no_content" />
                        </UploadNoContent>
                    )}
                </div>
            </AuthorizedDeletePage>
        </Spin>
    )
}

const UploadNoContent = styled.div`
    height: 200px;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    img {
        width: 232px;
        height: 140px;
    }
`

const AuthorizedDeletePage = styled.div`
    margin-top: 14px;
    @media (max-width: 768px) {
        margin: 0;
        padding: 0 20px;
    }
`
const AuthorizedDeleteHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 68px;
    font-family: Roboto-Medium;
    font-size: 24px;
    color: #000000;
    letter-spacing: 0;
    line-height: 28px;
    border-bottom: 1px solid rgba(47, 46, 46, 1);
`
const HeaderTitle = styled.div`
    display: flex;
    align-items: center;
    img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        cursor: pointer;
    }
`
const HeaderDone = styled.div`
    border: 1px solid rgba(0, 0, 0, 1);
    border-radius: 22px;
    width: 100px;
    height: 36px;
    font-family: Roboto-Medium;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    line-height: 35px;
    cursor: pointer;
    @media (max-width: 768px) {
        width: 77px;
        height: 28px;
        line-height: 28px;
        font-family: Roboto-Medium;
        font-size: 14.4px;
    }
`

const FrameListWrapper = styled.div`
    margin: 30px 0 50px 0;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 200px);
    grid-gap: 40px;
    .list-item {
        display: block;
        width: 100%;
        cursor: pointer;
        position: relative;
    }
    @media (max-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        grid-template-columns: repeat(auto-fill, 160px);
        justify-content: space-between;
        grid-gap: 0px;
        margin: 24px 0 42px 0;
    }
`
const ListWrapperItem = styled.div`
    height: 250px;
    @media (max-width: 768px) {
        width: 160px;
        height: 205px;
    }
`
const Info = styled.div`
    padding: 15px 0;
    text-align: center;
    @media (max-width: 768px) {
        text-align: left;
        padding: 6px 0;
    }
`
const Name = styled(Text)<TextProps>`
    font-family: Roboto-Regular;
    font-size: 16px;
    color: #000;
    line-height: 18px;
    padding: 0 6px;
    @media (max-width: 768px) {
        font-family: OpenSans-SemiBold;
        font-size: 11px;
        color: #838383;
        letter-spacing: 0;
        line-height: 14px;
    }
`
const CheckboxStyle = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    input[type='checkbox'] {
        border: 2px solid #000;
    }
    .ant-checkbox-inner {
        width: 26px;
        height: 26px;
    }
    .ant-checkbox-inner {
        ::after {
            width: 10px;
            height: 16px;
        }
    }
`
