import React, { FC, useEffect, useState, useRef, useLayoutEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { logEvent } from 'firebase/analytics'

import { useEventDetail } from 'core/event/api'
import { useI18n } from 'core/language/api'
import { useApiError, useMemoizedFn, useShowMessage, useUserInfo } from 'shared/hooks'
import { analytics } from 'shared/utils'
import { isDST } from 'shared/utils'

import { Button, Spin } from 'antd'
import { LineButton } from 'components'

import right from './assets/right.png'
import timeQuantum from './assets/timeQuantum.png'
import location from './assets/location.png'

const CONTENT_DEFAULT_HEIGHT = 607

export const EventDetails: FC = () => {
    const { lang } = useI18n()
    const navigate = useNavigate()
    const { id } = useParams<{ id: string }>()
    const userInfo = useUserInfo()
    const contentRef = useRef<any>()
    const relativeRef = useRef<HTMLDivElement>(null)
    const [box, setBox] = useState('box')
    const [containerPadding, setContainerPadding] = useState(238)

    const {
        methods: { t }
    } = useI18n()
    const {
        methods: { singleModal }
    } = useShowMessage()
    const {
        methods: { handleError }
    } = useApiError('FeatureEventDetail')

    // const [visible, setVisible] = useState(false)
    const [showAllContent] = useState(true)
    const [, setShowMoreBtn] = useState(false)
    const {
        loading,
        detail,
        methods: { getDetail }
    } = useEventDetail(lang)

    useEffect(() => {
        getPositionTop()
        id && getDetail(id).catch(handleError)
    }, [id, getDetail, handleError])

    // eslint-disable-next-line
    useLayoutEffect(() => {
        if (relativeRef.current) {
            setContainerPadding(relativeRef.current.clientHeight + 24)
        }
        if (contentRef.current && contentRef.current.offsetHeight < CONTENT_DEFAULT_HEIGHT) {
            setShowMoreBtn(true)
        }
    })
    const getPositionTop = () => {
        window.onscroll = () => {
            if (!relativeRef.current) return
            window.pageYOffset >= 50 ? setBox('minBox') : setBox('box')
        }
    }
    const isShowSummer = (time: number, zone: string) => {
        const show = isDST(time, zone)

        if (zone === 'America/New_York') {
            return show ? 'EST' : 'EDT'
        } else if (zone === 'America/Denver') {
            return show ? 'MST' : 'MDT'
        } else if (zone === 'America/Chicago') {
            return show ? 'CST' : 'CDT'
        } else if (zone === 'America/Los_Angeles') {
            return show ? 'PST' : 'PDT'
        }
        return ''
    }

    useEffect(() => {
        detail &&
            logEvent(analytics, 'event_view', {
                page_location: window.location.href,
                page_path: window.location.pathname,
                page_title: document.title,
                event_id: detail.id,
                event_name: detail.title
            })
    }, [detail])

    const handleViewProducts = useMemoizedFn(() => {
        if (detail?.isOff) {
            singleModal({
                message: (
                    <>
                        <div>{t('error.event.end.title')}</div>
                        <div>{t('error.event.end.msg')}</div>
                    </>
                )
            })
            return
        }
        navigate(`/event/${detail?.id}/products`)
    })

    return (
        <Spin spinning={loading}>
            <Container style={{ paddingTop: containerPadding }}>
                <DetailsHeader ref={relativeRef}>
                    <div className={box}>
                        <div className="text"> {detail?.words}</div>
                        <HeaderButton className="btn" onClick={handleViewProducts}>
                            {detail?.buttonName}
                        </HeaderButton>
                    </div>
                </DetailsHeader>
                {/* <EventProductModal
                    selectable={false}
                    eventId={id}
                    visible={visible}
                    setVisible={setVisible}
                    productList={detail?.productList}
                /> */}
                <Title>
                    <div className="web">
                        <div className={`name ${box}`} onClick={() => navigate(-1)}>
                            <img src={right} alt="" />
                        </div>

                        <div>
                            {userInfo?.storeAdmin && (
                                <LineButton
                                    className={`createBtn ${box}`}
                                    onClick={() => navigate(`/event/edit/${detail?.id}`)}
                                >
                                    Edit
                                </LineButton>
                            )}
                        </div>
                    </div>
                    <div className="mobile">{detail?.title}</div>
                </Title>
                {detail && (
                    <>
                        <Details>
                            <div className="time">
                                <div className="icon-title">
                                    <img className="mr-4" width={18} height={18} src={timeQuantum} alt="" />
                                    <span className="time-title">Date and time</span>
                                </div>
                                <div className="text">
                                    <div className="time-show time-margin">
                                        <span style={{ width: '43px' }}>Start:&nbsp;</span>
                                        <div>
                                            <span>{detail.beginDateText}</span>
                                            <div>
                                                {detail.beginTimeText}&nbsp;
                                                {isShowSummer(detail.beginTime, detail.timeZone)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="time-show">
                                        <span style={{ width: '43px' }}>End:&nbsp;</span>
                                        <div>
                                            <span>{detail.endDateText}</span>
                                            <div>
                                                {detail.endTimeText}&nbsp;
                                                {isShowSummer(detail.beginTime, detail.timeZone)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*{detail.firstImg && <img className="eventImage" src={detail.firstImg} alt="" />}*/}
                            <div className={`contentBlock ${showAllContent ? 'showContent' : ''}`}>
                                <div className="contentDiv" ref={contentRef}>
                                    <div className="icon-title">
                                        <img className="mr-4" width={18} height={18} src={location} alt="" />
                                        <span className="time-title">Location</span>
                                    </div>
                                    <div className="site">{detail.address}</div>
                                    <div
                                        className="text"
                                        dangerouslySetInnerHTML={{
                                            __html: `<div calss='divstyle'>${detail.content}</div>`
                                        }}
                                    />
                                </div>
                            </div>
                            {/* {!showMoreBtn && (
                                <div className="more">
                                    <span onClick={() => setShowAllContent(state => !state)}>
                                        {showAllContent ? 'Less' : 'More'}
                                    </span>
                                </div>
                            )} */}
                        </Details>
                        <div className="line">
                            <div style={{ borderBottom: '1px solid rgba(204, 204, 204, 1)' }} />
                        </div>
                    </>
                )}
            </Container>
        </Spin>
    )
}

const Container = styled.div`
    width: 100%;
    /* padding: 0 10rem; */
    padding: 0;
    margin: 0;
    .line {
        display: none;
    }
    .detail_modal {
        width: 80%;
        border-radius: 20px !important;
    }
    @media (max-width: 768px) {
        padding: 105px 0 70px;
        width: 100%;
        .line {
            display: block;
            padding: 0 17px;
            width: 100%;
        }
    }
`
const Title = styled.div`
    .web {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 36px;
        padding: 0px 72px;
        margin-bottom: 24px;
        @media (max-width: 768px) {
            padding: 0;
            height: 30px;
            margin-bottom: 0px;
        }
    }
    .mobile {
        font-family: HelveticaNeue-Medium;
        font-size: 42px;
        color: #000000;
        letter-spacing: 0;
        line-height: 50.34px;
        font-weight: 500;
        width: 100%;
        padding: 0px 210px;
        @media (max-width: 768px) {
            padding: 0;
        }
    }
    .minBox {
        background-color: rgba(0, 0, 0, 0);
        &:hover {
            div {
                color: #000;
            }
        }
    }
    .name {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-family: HelveticaNeue-Medium;
        font-size: 18px;
        color: #000;
        line-height: 20px;
        font-weight: 500;
        cursor: pointer;
        img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            transform: rotate(180deg);
        }
    }
    @media (max-width: 768px) {
        padding: 0px 20px;
        .mobile {
            display: block;
            font-family: HelveticaNeue-Medium;
            font-size: 24px;
            color: #000000;
            letter-spacing: 0;
            line-height: 30px;
            font-weight: 500;
            margin: 20px 0px;
        }
    }
`

const Details = styled.div`
    width: 100%;
    height: auto;
    padding: 40px 210px;
    margin-bottom: 40px;
    .time {
        margin-bottom: 20px;
        font-family: Roboto-Regular;
        font-size: 16px;
        color: #000;
        .icon-title {
            font-size: 18px;
            display: flex;
            align-items: center;
            margin-bottom: 20px;
        }
        .text {
            padding-left: 26px;
            .time-margin {
                margin-bottom: 15px;
            }
            .time-show {
                font-size: 16px;
                display: flex;
                span {
                    margin: 0;
                }
            }
        }
        span {
            display: inline-block;
            margin-right: 20px;
        }
    }
    .eventImage {
        display: block;
        width: 100%;
        height: auto;
    }
    .contentBlock {
        box-sizing: content-box;
        position: relative;
        height: ${CONTENT_DEFAULT_HEIGHT + 20}px;
        overflow: hidden;
        font-family: 'Roboto-Regular';
        font-size: 18px;
        color: #000;
        &.showContent {
            min-height: ${CONTENT_DEFAULT_HEIGHT + 20}px;
            height: auto;
            overflow: visible;
        }
        .contentDiv {
            .time-title {
                font-size: 18px;
            }
            .icon-title {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
            }
            .site {
                font-size: 16px;
                margin-bottom: 20px;
                margin-left: 26px;
            }
            .text {
                font-size: 16px;
            }
        }
    }
    .more {
        /* position: absolute;
        left: 0;
        right: 0;
        bottom: 0; */
        padding-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        /* background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 80%); */
        span {
            display: inline-block;
            font-family: PingFangSC-Regular;
            font-size: 18px;
            color: #000;
            text-align: center;
            line-height: 30px;
            text-decoration: underline;
            cursor: pointer;
        }
    }
    @media (max-width: 768px) {
        padding: 0px 20px;
        margin-bottom: 0;
        border: none;
        .time {
            margin-bottom: 10px;
            font-size: 15px;
        }
    }
`

const HeaderButton = styled(Button)`
    display: inline-block;
    /* width: 13%; */
    /* height: 54px; */
    background: #000000;
    border-radius: 27px;
    font-family: Roboto-Bold;
    font-size: 30px;
    color: #ffffff;
    letter-spacing: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    padding: 0px 22px;
    span {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &:hover {
        background: #000000;
        color: #ffffff;
    }
    &:focus {
        background: #000000;
        color: #ffffff;
    }
    @media (max-width: 768px) {
        height: 3.1%;
        font-size: 20px;
        padding: 0px 20px;
    }
`
const DetailsHeader = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 68px;
    z-index: 2;
    .box {
        background-color: #b50024;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #ffffff;
        border: 1px solid #b50024;
        padding: 40px 164px 46px;
        .btn {
            /* width: 324px; */
            height: 54px;
        }
        @media (max-width: 768px) {
            & {
                /* height: 320px; */
                padding: 40px 22px 46px;
            }
            .btn {
                /* width: 292px; */
                height: 50px;
                font-family: Roboto-Bold;
                font-size: 26px;
                color: #ffffff;
                letter-spacing: 0;
                text-align: center;
                line-height: 30px;
                font-weight: 700;
            }
        }
    }
    .text {
        font-size: 48px;
        letter-spacing: 0;
        text-align: center;
        font-family: Roboto;
        text-shadow: 0 4px 20px #ad360a;
        font-weight: 700;
        margin-bottom: 32px;
        @media (max-width: 768px) {
            & {
                font-family: Roboto-Bold;
                font-size: 22px;
                color: #ffffff;
                letter-spacing: 0;
                text-align: center;
                line-height: 36px;
                font-weight: 700;
                /* height: 144px; */
                padding: 0;
                text-align: center;
                margin-bottom: 40px;
            }
        }
    }
    .minBox {
        min-height: 120px;
        padding: 25px 70px;
        background-color: #b50024;
        font-size: 30px;
        font-family: Roboto;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .text {
            text-align: left;
            font-size: 30px;
            margin: 0;
        }
        .btn {
            margin-left: 35px;
            /* width: 324px; */
            height: 54px;
        }
        @media (max-width: 768px) {
            & {
                /* height: 200px; */
                padding: 20px 24px 38px 20px;
                flex-direction: column;
                justify-content: center;
            }
            .text {
                font-family: Roboto-Bold;
                font-size: 16px;
                color: #ffffff;
                letter-spacing: 0;
                text-align: center;
                line-height: 24px;
                font-weight: 700;
                margin-bottom: 20px;
            }
            .btn {
                /* width: 292px; */
                height: 50px;
                font-size: 26px;
                margin-left: 0px;
            }
        }
    }
    @media (max-width: 768px) {
        top: 56px;
    }
`
