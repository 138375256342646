import { createContext } from 'react'

interface LoginModalContextValue {
    visible: boolean
    methods: {
        show: () => void
        hide: () => void
    }
}

export const LoginModalContext = createContext<LoginModalContextValue | undefined>(undefined)
