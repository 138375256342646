import { clientHttp, commonHttp } from 'shared/request'
import { SuccessItemResponse, ListResponse, SuccessResponse } from 'shared/request'

import { Language } from 'core/language/models'

import { CommissionDTO, convertToOrderItem, ProductItem } from '../DTO'
import { pathJoin } from 'shared/utils'

import { ListParams } from 'core/aff/models'

const URI = 'commission'

export const getCommission = (language: Language, params: ListParams) => {
    const p: any = { language, ...params }
    const url = pathJoin(URI)
    return clientHttp.get<SuccessResponse<ListResponse<CommissionDTO, ProductItem>>>(url, p).then(res => {
        const data = res.data.data
        return {
            list: convertToOrderItem(data.value, data.references),
            total: data.total
        }
    })
}

export const readShare = (code: string) => {
    const url = pathJoin('share', code)
    return commonHttp.get<SuccessItemResponse<null>>(url, {}).then()
}
