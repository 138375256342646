import React, { FC, useEffect, useMemo, useState } from 'react'

import styled from '@emotion/styled'
import { Button } from 'components'

import { PayContainer } from '../payment'
import { AddressItemPage } from './addressItem'
import { BillingAddress } from './billingAddress'
import { useDeliveryList } from 'core/delivery/api'

import { Delivery, DeliveryTypeEnum } from 'core/delivery/models'
import { Checkbox, Modal } from 'antd'
import { useApiError, useResponsive, useShowMessage, useSignInRedirect } from 'shared/hooks'
import { AddressList } from './addressList'
import { getIsTourist, getTouristAddress } from 'shared/config'

export enum ListOrAdd {
    LIST,
    ADD
}

export interface Props {
    cardPay: boolean
    setCardPay: (value: boolean) => void
    amount: number
    selectedDelivery?: Delivery
    billingAdress?: Delivery
    setBillingAdress: (billingAdress: Delivery) => void
    payOrderFormRef: any
    onSubmit: (id?: string) => void
    isShippingAddress: boolean
    setIsShippingAddress: (value: boolean) => void
}

export const CommonProductPay: FC<Props> = ({
    cardPay,
    setCardPay,
    amount,
    selectedDelivery,
    billingAdress,
    setBillingAdress,
    payOrderFormRef,
    onSubmit,
    isShippingAddress,
    setIsShippingAddress
}) => {
    const [enable, setEnable] = useState<boolean>(false)
    const [visible, setVisible] = useState<boolean>(false)
    const [isSavedAddress, setIsSavedAddress] = useState<string>('Use a saved address')
    const [isAdd, setIsAdd] = useState<boolean>(false)

    const { md } = useResponsive()
    const {
        methods: { error }
    } = useShowMessage()

    const {
        methods: { handleError }
    } = useApiError('OrderCreate', error as any)

    const { getToken } = useSignInRedirect()
    const {
        billingList,
        methods: { getList }
    } = useDeliveryList(DeliveryTypeEnum.BILLING)

    useEffect(() => {
        getList().catch(handleError)
    }, [getList, getToken, handleError, isAdd])

    const onSave = (values: Delivery) => {
        setVisible(false)
        setIsAdd(false)
        setBillingAdress(values)
    }

    const placeOrderButton = useMemo(
        () => (
            <Button className="continue" onClick={onSubmit} disabled={!enable}>
                Place your order
            </Button>
        ),
        [enable, onSubmit]
    )

    return (
        <Wrapper>
            <div>
                <div className="title payment">Payment</div>
                <div className="paymentForm">
                    <PayContainer
                        key={amount}
                        param={{ amount }}
                        innerRef={payOrderFormRef}
                        setCardPay={setCardPay}
                        setComplete={setEnable}
                    />
                </div>
                {cardPay ? (
                    <>
                        <div className="titleBox">
                            <div className="title billing">Billing address</div>
                            {!getIsTourist() && !isShippingAddress && billingList.length > 0 && (
                                <div onClick={() => setVisible(true)} className="text">
                                    {isSavedAddress}
                                </div>
                            )}
                        </div>
                        <div className="checkbox">
                            <Checkbox
                                className="check"
                                checked={isShippingAddress}
                                onChange={() => setIsShippingAddress(!isShippingAddress)}
                            >
                                Same as shipping address
                            </Checkbox>
                        </div>
                        {selectedDelivery && (isShippingAddress || billingAdress) ? (
                            <div>
                                <AddressItemPage
                                    type="billing"
                                    className="addressItem"
                                    addressItem={isShippingAddress ? selectedDelivery : billingAdress}
                                />
                                {placeOrderButton}
                            </div>
                        ) : getIsTourist() && getTouristAddress() && isShippingAddress ? (
                            <div>
                                <AddressItemPage
                                    type="billing"
                                    className="addressItem"
                                    addressItem={JSON.parse(getTouristAddress() || '{}')}
                                />
                                {placeOrderButton}
                            </div>
                        ) : (
                            <BillingAddress
                                cardPay={cardPay}
                                page={true}
                                onSave={onSave}
                                onSubmit={onSubmit}
                                btnTitle="Place your order"
                                disabled={enable}
                            />
                        )}
                    </>
                ) : (
                    placeOrderButton
                )}

                <Modal
                    open={visible}
                    onCancel={() => {
                        setVisible(false)
                        setIsAdd(false)
                    }}
                    footer={null}
                    width={md ? 850 : '100%'}
                    destroyOnClose
                    bodyStyle={{ padding: '40px 24px 24px !important' }}
                    style={{
                        maxWidth: '100%',
                        margin: md ? '0 auto' : 0,
                        top: md ? 100 : 56
                    }}
                >
                    {isAdd ? (
                        <BillingAddress
                            cardPay={cardPay}
                            addressTitle="Add a new address"
                            onSave={onSave}
                            btnTitle="Save"
                        />
                    ) : (
                        <AddressList
                            type="billing"
                            title="Select a billing address"
                            selectedDeliveryId={selectedDelivery?.id}
                            setIsSavedAddress={setIsSavedAddress}
                            setBillingAdress={setBillingAdress}
                            list={billingList}
                            setVisible={setVisible}
                            add={() => {
                                setIsAdd(true)
                            }}
                        />
                    )}
                </Modal>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    flex: 1;
    margin-right: 58px;
    .paymentForm {
        width: 100%;
        background-color: #f4f4f4;
        @media (max-width: 580px) {
            width: 100%;
        }
    }
    .payment {
        margin: 30px 0;
    }
    .billing {
        margin: 30px 0;
    }
    .title {
        font-family: HelveticaNeue-CondensedBold;
        font-size: 30px;
        color: #000000;
        letter-spacing: 0;
        line-height: 30px;
        @media (max-width: 580px) {
            font-size: 24px;
        }
    }
    .checkbox {
        .check {
            margin-bottom: 30px;
            font-family: HelveticaNeue;
            font-size: 18px;
            color: #000000;
            letter-spacing: 0;
            line-height: 26px;
        }
    }
    .titleBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .addressItem {
        font-family: HelveticaNeue;
        font-size: 18px;
        color: #000000;
        letter-spacing: 0;
        line-height: 26px;
    }
    .continue {
        width: 100%;
        margin-top: 40px;
        font-family: Roboto-Medium;
        font-size: 25.33px;
        color: #ffffff;
        letter-spacing: 0;
        text-align: center;
        line-height: 30.96px;
    }
    .text {
        font-family: HelveticaNeue;
        font-size: 18px;
        color: #000000;
        letter-spacing: 0;
        text-align: right;
        line-height: 26px;
        text-decoration: underline;
        cursor: pointer;
    }
    @media (max-width: 768px) {
        margin-right: 0px;
    }
`
