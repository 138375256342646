/* eslint-disable no-template-curly-in-string */
import { Lang } from './Lang'
export const en: Lang = {
    eg: 'e.g.',
    download: 'Download App',
    form: {
        actions: {
            name: 'Actions',
            new: 'New',
            detail: 'Detail',
            edit: 'Edit',
            save: 'Save',
            submit: 'Submit',
            cancel: 'Cancel',
            change: 'Change',
            clear: 'Clear',
            upload: 'Upload',
            download: 'Download',
            update: 'Update',
            addLocalization: 'Add a localization',
            search: 'Search',
            delete: 'Delete',
            remove: 'Remove',
            ok: 'Ok',
            OK: 'OK',
            confirm: 'Confirm',
            done: 'Done',
            invalid: 'Invalid',
            test: 'Test',
            sort: 'Sort',
            addCategory: 'Add category',
            copy: 'Copy',
            unfold: 'Unfold',
            choose: 'Choose',
            add: 'Add',
            open: 'Open',
            close: 'Close',
            share: 'Share',
            stopShare: 'Stop Share',
            refresh: 'Refresh',
            disable: 'Disable',
            enable: 'Enable',
            longPressImageToSave: 'Long press the image to save',
            back: 'Back',
            next: 'Next',
            skip: 'Skip',
            view: 'View',
            viewChildren: 'View Children',
            complete: 'Complete'
        },
        rules: {
            required: 'Filed is required',
            maxLength: 'Max length is ${{length}}',
            ltSize: 'Image must smaller than ${{size}}',
            noLocalization: 'Please add at least one localization.',
            noDefaultLocalization: 'Please set one base localization',
            makeSure: 'Please make sure you entered the information correctly.',
            completeRule: 'Please make sure rule completed.'
        },
        messages: {
            success: '${{action}} Successful',
            fail: '${{action}} failed'
        },

        confirm: {
            ok: 'Are you sure?'
        },

        radio: {
            yes: 'yes',
            no: 'no'
        }
    },

    share: {
        actions: {
            clickToCopyLink: 'Click to copy',
            scanQrcode:
                'Open WeChat Scan QR Code, open the webpage and click the share button at the top right corner of the screen.'
        }
    },
    language: {
        language: 'Language',
        default: 'Base Language',
        setDefault: 'Set Base Language',
        select: 'Select Language',
        'zh-CN': 'Chinese',
        'zh-HK': 'Cantonese',
        'en-US': 'English',
        'ko-kr': 'Korean',
        'vi-vn': 'Vietnamese',
        'ja-jp': 'Japanese'
    },
    languageTag: {
        all: 'All Languages',
        zh: 'Chinese',
        en: 'English',
        es: 'Spanish'
    },
    dayInWeek: {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesdays',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday'
    },
    pagination: {
        pageNum: 'Page Number',
        placeholder: {
            pageNum: 'Please Enter Page Number'
        }
    },
    direction: {
        up: 'Up',
        down: 'Down'
    },

    compare: {
        gt: 'greater than ',
        gte: 'greater than or equal ',
        eq: 'equal ',
        lt: 'less than ',
        lte: 'less than or equal '
    },
    client: {
        auth: {
            signIn: 'Sign In',
            signUp: 'Sign Up',
            touristSignIn: 'Browse as Guest',
            logOut: 'Log out',
            gotoSignIn: 'Go to Mobile Login',
            gotoSignInEmail: ' Go to email login',
            gotoSignUp: 'Register with your cell phone number',
            gotoSignUpEmail: 'Register with email',
            country: 'Country',
            countryDrawerTitle: 'Country / Region',
            countryDrawerDone: 'Done',
            smsCodeSending: 'Sending',
            email: 'Account',
            password: 'Password',
            passwordByEmail: 'Use email to retrieve password',
            passwordByPhone: 'Use cell phone number to retrieve password',
            goLogin: 'Go to login',
            messages: {
                checkPasswordEqual: 'The retyped password does not match. Please reset.',
                mobileIsExists: 'This phone number has been registered.',
                confirmPasswordError: 'Please make sure your passwords match'
            },
            placeholder: {
                email: 'Email Address',
                mobile: 'Mobile number',
                password: 'Password',
                setPassword: 'Please enter your password',
                setConfirmPassword: 'Confirm Password',
                graphicInput: 'Case insensitive',
                getSmsCode: 'Obtain Code',
                smsCodeInput: 'SMS code'
            },
            forgetPassword: {
                actionTitle: 'Forgot Password',
                phoneTitle: 'Please enter your cell phone number',
                emailTitle: 'Please enter your email address',
                setPasswordTitle: 'Please set a login password',
                note: 'Password length 6~18 digits'
            },
            authStream: {
                eulaPreLabelPhone: 'By entering your phone number, you agree to our',
                eulaSurLabel: '. Thank you',
                eulaPreLabelEmail: 'By entering your email address, you agree to our'
            },
            verificationCode: {
                phoneTitle:
                    'Please enter the verification code, the verification code has been sent to your registered cell phone number',
                phoneCode: 'Enter the verification code',
                phoneTipsMsg: "Didn't receive it? Tap to resend",
                emailTitle: 'Please enter the verification code, which has been sent to your email address',
                emailCode: 'Verification Code',
                emailTipsMsg: `Didn't receive it? Please click to resend the email verification code`,
                countDownMsg: 'Request another code in {S}s'
            }
        },
        cart: {
            title: 'Cart',
            manage: 'Manage',
            empty: 'Cart is empty',
            ineffective: {
                title: 'Ineffective Products ',
                clear: 'Clear'
            },
            pickAll: 'Select All',
            selected: 'Selected ',
            piece: ' pieces',
            total: 'Total: ',
            checkout: 'Checkout',
            fail: {
                cannot_reduce_num: 'Product number can no longer be reduced',
                sku_not_exist: 'Specification do not exist',
                insufficient_remaining_inventory: 'Insufficient remaining inventory',
                maxValue: 'Maximum value:'
            },
            warning: {
                out_of_stock: 'Out of stock',
                reselect_specs: 'Please reselect the specifications',
                no_products_selected: 'No products selected'
            }
        },
        delivery: {
            title: 'Address',
            actions: {
                finish: 'Finished',
                manage: 'Manage'
            },
            empty: 'Addresses not added',
            create: {
                title: 'Add address',
                save: 'Save address'
            },
            edit: 'Edit address',
            placeholder: {
                select: 'Please select'
            }
        },
        home: {
            title: 'Home'
        },
        order: {
            confirm: 'Confirm',
            select_address: 'Please Select Address',
            remark: {
                title: 'Remarks',
                placeholder: 'Optional, please fill in the remarks'
            },
            submit: 'Submit Order',
            totalPrice: 'Total price: ',
            statusText: {
                '30': 'Stock is limited, please pay as soon as possible',
                '32': 'Order has been paid for and is awaiting shipment',
                '61': 'The order has been shipped, please be patient and wait for the receipt',
                '91': 'Order has been cancelled and look forward to coming back again',
                '92': 'The timeout is not paid and the order has been canceled',
                '99': 'Order completed and look forward to coming back again'
            },
            actions: {
                confirm: 'Confirm'
            },
            pay: {
                success: 'Thank you, your order has been placed.',
                success_message:
                    'Please check your email for order confirmation and further information or visit My Center at the top-right corner.',
                select: 'Select payment type',
                do: 'Pay',
                text: 'Pay',
                card: 'Credit or debit card',
                holderName: 'Card holder name',
                error: {
                    card_holder: 'Please fill in the cardholder information correctly',
                    fail: 'Payment failed'
                },
                fail: 'Payment failed'
            },
            cancel: {
                title: 'Cancel Order',
                confirm_msg: 'Are you sure to cancel your order?',
                success: 'Cancel Successfully'
            },
            delete: {
                title: 'Delete Order',
                confirm_msg: 'Are you sure to delete your order?',
                success: 'Delete successfully'
            },
            detail: {
                title: 'Order Details',
                info: 'Order info',
                code: 'Order code',
                copy: 'Copy',
                time: {
                    order: 'Submit time',
                    payment: 'Pay time',
                    delivery: 'Delivery time',
                    confirm: 'Confirm time',
                    cancel: 'Cancel time'
                },
                ship: {
                    code: 'Logistics No',
                    companyName: 'Logistics company'
                }
            },
            list: {
                title: 'Order list'
            },
            empty: 'No orders',
            confirm_receipt: 'Confirm receipt',
            amount: {
                product: 'Goods subtotal',
                tax: 'Tax',
                freight: 'Freight',
                discount: 'Discounts',
                total: 'Total',
                depositTotal: 'Deposit'
            }
        },
        product: {
            msg: {
                cart: {
                    success: 'Success'
                }
            },
            buy: 'Buy',
            addCart: 'Add to cart',
            buyConfirmTips: 'Limited ${{num}} per user per lifetime'
        },
        sku: {
            buy: 'Buy',
            addCart: 'Add to cart',
            confirm: 'OK',
            quantity: 'Quantity',
            remains: 'Only ${{stock}} left'
        },
        me: {
            address: 'My Address',
            orders: 'My Orders',
            logout: 'Logout',
            loginClick: 'Click to login',
            isLogout: 'Whether to log out of the currently logged-in account',
            allOrders: 'All orders'
        }
    },
    category: {
        title: 'Category',
        detail: 'Detail',
        fields: {
            name: 'Name',
            description: 'Description',
            displayOrder: 'DisplayOrder',
            icon: 'Icon',
            keywords: 'Keywords',
            parentId: 'Parent Category'
        },
        placeholder: {
            parentId: 'Please Select Parent Category'
        }
    },
    attrCategory: {
        title: 'Attribute Category',
        attrTitle: 'Attribute',
        fields: {
            name: 'Name',
            keywords: 'Keywords',
            description: 'Description',
            attrNumber: 'AttributeNumber',
            paramNumber: 'ParameterNumber',
            displayOrder: 'DisplayOrder',
            attrValue: 'AttributeValue',
            paramValue: 'ParameterNumber',
            canCustomize: 'CanCustomize',
            attrType: 'AttributeType'
        },
        form: {
            baseInfoTitle: 'Base Info',
            attrListTitle: 'Attribute List',
            specTitle: 'Specifications',
            paramTitle: 'Parameter'
        }
    },
    order: {
        title: 'Order',
        status: {
            all: 'All',
            '30': 'Unpaid',
            '31': 'Paying',
            '32': 'Paid',
            '61': 'Delivered',
            '90': 'Close',
            '91': 'Cancel',
            '92': 'Cancel',
            '99': 'Confirmed'
        },
        statusTitle: {
            all: 'All',
            '30': 'Payment unsuccessful',
            '31': 'Paying',
            '32': 'Payment successful',
            '61': 'Delivered',
            '90': 'Close',
            '91': 'Order cancelled',
            '92': 'Order cancelled',
            '99': 'Order received'
        }
    },
    product: {
        title: 'Product',
        price: {
            title: '价格',
            old: '原售价',
            new: '新售价',
            modal: {
                title: {
                    '1': '设置商品价格-涨价',
                    '-1': '设置商品价格-降价',
                    '0': '设置商品价格'
                },
                note: {
                    '1': '新售价不得低于原售价，不支持填写负数',
                    '-1': '新售价不得高于原售价，不支持填写负数',
                    '0': '设置商品价格时，尽量采用涨价/降价的方式'
                },
                diff: {
                    '1': '涨价幅度',
                    '-1': '降价幅度'
                }
            }
        },
        stock: {
            title: '库存',
            old: '原实际库存',
            new: '新实际库存',
            aboutValue: '约为',
            modal: {
                title: {
                    '1': '补货',
                    '-1': '销货',
                    '0': '清点商品库存'
                },
                label: {
                    '1': '补货库存',
                    '-1': '销货库存',
                    set: '新实际库存'
                }
            }
        }
    },
    store: {
        title: 'Store'
    },
    management: {
        nft: {
            status: {
                0: 'Reviewing',
                1: 'Approved',
                2: 'Rejected'
            }
        }
    },
    error: {
        server_error:
            'Server Error. Sorry the server has encountered an error. Please try again later. If the problem persists, please contact the support.',
        authention_failed: 'Auth failed',
        not_found: 'No corresponding information found',
        not_found_user: 'User not found',
        user_not_found: 'Invalid account or password',
        info: 'The address cannot be validated. Please review your information and make corrections.',
        detail_name_empty: 'The name cannot be empty',
        default_language_images_empty: 'Please upload a product image in the default language',
        on_sale_sku_empty: 'There are no SKUs on the shelves currently',
        is_on_sale: 'It is currently in the shelves',
        is_off_sale: 'It is currently in a delisted state',
        is_disabled: 'It is currently disabled',
        price_empty: 'The price cannot be empty',
        stock_empty: 'The stock cannot be empty',
        product_not_on_sale: 'The product is not on the shelves',
        need_sku: 'The product needs SKU',
        sku_not_on_sale: 'SKU is not in the shelf state',
        out_of_stock: 'Out of stock',
        need_delivery_detail: 'Address information cannot be empty',
        cannot_cancel: 'The order cannot be cancelled',
        cannot_confirm: 'The order cannot be confirmed',
        cannot_delete: 'The order cannot be deleted',
        cannot_pay: 'The order has been cancelled automatically for you due to timeout',
        cannot_delivery: 'The order cannot be shipped',
        pay_failed: 'Payment failed',
        product_changed: 'The product has been changed',
        no_spec_attr: 'There are no specification attributes under classification',
        stock_too_low: 'Out of stock',
        virtual_has_order: "You've already purchased this item.",
        virtual_has_unpay_order:
            'You\'ve placed an order for this item (order status: unpaid). Please go to the "Order List" and complete your payment.',
        order_goods_is_empty: 'This product has been removed from the shelves',
        purchase_restrictions: 'The product you have selected has exceeded the purchase limit',
        purchase_exceeded: 'Already exceeded the purchase limit',
        param_is_null: 'Params Error',
        account_not_registered: 'Account does not exist, please check and try again',
        account_has_registered: 'This account is registered',
        account_has_bind: 'Account already registered',
        authCode: {
            error: 'Incorrect verification code',
            sendOften: 'Frequent operation, please try again later'
        },
        email_error: 'Mailbox format error',
        coupon_unavailable: 'Promo code is invalid',
        password_not_match: 'The original password was entered incorrectly, please check and re-enter',
        quantity_once_limit: 'The product you have selected has exceeded the purchase limit',
        need_rent_time: 'Please reselect your rental time',
        deliveryInfo: {
            phone: ''
        },
        account_log_off: 'Invalid account or password',
        event: {
            end: {
                title: 'This event has ended.',
                msg: 'Don\'t forget to check the latest and upcoming events in the "Event" tab on our website.'
            }
        },
        Invalid_nft_id: 'NFT has been removed',
        frame_had_owner: 'The equipment has been rented',
        email_twice_inconsistent: 'The entered emails are inconsistent.',
        frame_not_found: 'Frame is disabled by admin or invalid serial number',
        pay_secret_error: 'Payment failed',
        frame_is_empty: 'Frame is disabled by admin or invalid serial number',
        frame_rent_duration_is_empty: 'Frame is disabled by admin or invalid serial number',
        sign_in_password: 'Password should be 6 to 18 characters long. Please reset your password.'
    }
}
