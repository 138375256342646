import { take, takeLast } from 'ramda'

export const stringOmitMiddle = (fronts: number, ends: number, str: string) =>
    str.length > fronts + ends + 3 ? `${take(fronts, str)}...${takeLast(ends, str)}` : str

export const stringOmit = (str?: string, limit = 12, ellipsis = '...') => {
    if (!str) {
        return ''
    }

    if (str.length <= limit) {
        return str
    }

    return `${str.slice(0, limit)}${ellipsis}`
}

export const pattern = new RegExp('[\u4E00-\u9FA5]+')
