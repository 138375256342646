import { action, payload } from 'ts-action'
import { getActionType } from '../constants'
import { MyCollectionList } from 'core/management/nft/models'
export const setOwnedList = action(
    getActionType('Set OwnedList'),
    payload<{ ownedList: MyCollectionList[] }>()
)
export const setAuthorizedList = action(
    getActionType('Set AuthorizedList'),
    payload<{ authorizedList: MyCollectionList[] }>()
)
