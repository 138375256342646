import React, { FC, useEffect } from 'react'
import { useSignInWithGoogle } from 'react-firebase-hooks/auth'
import styled from '@emotion/styled'

import { auth } from 'shared/utils'

import gIcon2 from './assets/google-icon2.png'

interface GoogleAuthProps {
    onSignIn?: (idToken: string) => void
}

export const GoogleAuth: FC<GoogleAuthProps> = ({ onSignIn }) => {
    // const [photo, setPhoto] = useState(gIcon2)
    const [signInWithGoogle, user, loading] = useSignInWithGoogle(auth)

    useEffect(() => {
        if (user) {
            // const { photoURL } = user.user
            // setPhoto(photoURL || gIcon2)
            const idToken =
                (user.user as any)?.accessToken || (user.user as any)?.stsTokenManager?.accessToken || ''
            typeof onSignIn === 'function' && onSignIn(idToken)
        }
    }, [onSignIn, user])

    // const BtnText = useMemo(() => {
    //     if (error) {
    //         const messageMatch = error.message.match(/auth\/([^)]+)/)
    //         if (messageMatch) {
    //             return messageMatch[1].replace(/-/g, ' ')
    //         } else {
    //             return error.message
    //         }
    //     } else if (loading) {
    //         return 'Loading'
    //     } else if (user) {
    //         // console.log('google user', user)
    //         const { email, displayName } = user.user
    //         return (
    //             <div className="text-2xl">
    //                 <div>{displayName}</div>
    //                 <div>{email}</div>
    //             </div>
    //         )
    //     } else {
    //         return 'Sign In With Google'
    //     }
    // }, [error, loading, user])

    return (
        <Wrapper>
            <div
                className={`button ${loading ? 'buttonLoading' : ''}`}
                onClick={() => !loading && signInWithGoogle()}
            >
                <img className="google-icon" src={gIcon2} alt="" />
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: inline-block;
    .google-icon {
        width: 50px;
        height: 50px;
    }
    .buttonLoading {
        opacity: 0.8;
    }
`
