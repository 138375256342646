import { useCallback, useState } from 'react'

import { useMountedPromiseFn } from 'shared/hooks'
import { services } from 'core/auth/data-access'
import { verificationCodeType } from 'core/auth/models'

export const useVerificationCode = () => {
    const [loading, setLoading] = useState<boolean>(false)

    // Send login verification code
    const sendAuthCodeApi = useMountedPromiseFn(services.sendAuthCode)
    const sendAuthCode = useCallback(
        (mobile: string, email: string, recaptchaToken: string, type: verificationCodeType) => {
            setLoading(true)

            return sendAuthCodeApi(mobile, email, recaptchaToken, type)
                .then(res => {
                    setLoading(false)
                    return res
                })
                .catch(err => {
                    setLoading(false)
                    return Promise.reject(err)
                })
        },
        [sendAuthCodeApi]
    )

    // Resend login verification code
    const resendAuthCodeApi = useMountedPromiseFn(services.resendAuthCode)
    const resendAuthCode = useCallback(
        (token: string) => {
            setLoading(true)

            return resendAuthCodeApi(token)
                .then(res => {
                    setLoading(false)
                    return res
                })
                .catch(err => {
                    setLoading(false)
                    return Promise.reject(err)
                })
        },
        [resendAuthCodeApi]
    )

    const checkAuthCodeApi = useMountedPromiseFn(services.checkAuthCode)
    const checkAuthCode = useCallback(
        (mobile: string, email: string, type: verificationCodeType, authCode: string) => {
            setLoading(true)

            return checkAuthCodeApi(mobile, email, type, authCode)
                .then(res => {
                    setLoading(false)
                    return res
                })
                .catch(err => {
                    setLoading(false)
                    return Promise.reject(err)
                })
        },
        [checkAuthCodeApi]
    )

    return {
        loading,
        methods: {
            sendAuthCode,
            resendAuthCode,
            checkAuthCode
        }
    }
}
