import React, { CSSProperties, FC, ReactNode } from 'react'

import { useResponsive } from 'shared/hooks'

interface Response2ColProps {
    leftNode: ReactNode
    rightNode: ReactNode
    style?: CSSProperties
    reverse?: boolean
}

export const Response2Col: FC<Response2ColProps> = ({ leftNode, rightNode, style, reverse }) => {
    const { lg } = useResponsive()

    return (
        <div className="flex flex-wrap" style={style}>
            <div className="flex basis-full lg:basis-1/2">{lg && reverse ? rightNode : leftNode}</div>
            <div className="flex basis-full lg:basis-1/2">{lg && reverse ? leftNode : rightNode}</div>
        </div>
    )
}
