import { pathJoin } from 'shared/utils'
import {
    nftHttp,
    clientHttp,
    pickValue,
    pickValueAndReferences,
    SuccessItemResponse,
    SuccessItemResponseNFT,
    SuccessResponse,
    picknftList,
    pickData,
    ListResponse,
    addressTokenHttp
} from 'shared/request'

import { convertToDetail, convertToNFTDetail, convertToNFTList, NFTDetailDTO, screenAccount } from '../DTO'

import type {
    AuthAddressItem,
    NftDetail,
    NftFormData,
    NftInList,
    NftParams,
    uploadParams
} from 'core/management/nft/models'

const URI = '/nft'

const account = 'account'

export const getList = (params: uploadParams) =>
    clientHttp
        .get<
            SuccessResponse<{
                value: NftInList[]
                total: number
            }>
        >(URI, { ...params })
        .then(pickData)

export const getDetail = (id: string) => {
    const url = pathJoin(URI, id)
    return clientHttp
        .get<SuccessItemResponse<NftDetail>>(url)
        .then(pickValueAndReferences)
        .then(({ value, references }) => convertToDetail(value, references))
}

export const remove = (id: string) => clientHttp.delete(`${URI}/${id}`)

export const syncData = (token_address: string, token_id: string) => {
    const url = pathJoin(URI, 'sync')
    return clientHttp.post<SuccessItemResponse<void>>(url, { token_address, token_id }).then(pickValue)
}

export const edit = (id: string, formData: NftFormData) => {
    const url = pathJoin(URI, id)
    return clientHttp.put<SuccessItemResponse<void>>(url, { ...formData }).then(pickValue)
}

export const create = (formData: NftFormData) =>
    clientHttp.post<SuccessItemResponse<string | null>>(URI, { ...formData }).then(pickValue)

export const owned = async (params: NftParams, address: string) => {
    let url = pathJoin(account, address, URI)
    return nftHttp
        .get<SuccessItemResponseNFT<NFTDetailDTO[]>>(url, { ...params })
        .then(picknftList)
        .then(convertToNFTList)
}
export const ownedDetail = (id: string) => {
    const url = pathJoin(URI, id)
    return nftHttp.get<SuccessResponse<NFTDetailDTO>>(url).then(pickData).then(convertToNFTDetail)
}

export const ownedNew = (params: NftParams, address: string) => {
    let url = pathJoin(account, address, URI)
    return nftHttp
        .get<
            SuccessResponse<{
                nft_list: NFTDetailDTO[]
                total_nft: number
                cursor_next: null | string
                cursor_prev: string | null
            }>
        >(url, { ...params })
        .then(pickData)
        .then(res => ({
            ...res,
            nft_list: convertToNFTList(res.nft_list)
        }))
}

export const getAddressList = () => {
    const url = pathJoin(URI, 'authAddress')
    return addressTokenHttp
        .get<SuccessResponse<ListResponse<AuthAddressItem>>>(url)
        .then(pickValue)
        .then(screenAccount)
}

export const getAddressToken = (params: { id: string; addressType: string; ownerId: string }) => {
    const url = pathJoin(URI, 'addressToken')
    return addressTokenHttp.get<SuccessResponse<{ value: string }>>(url, { ...params }).then(pickData)
}
