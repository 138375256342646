import React, { FC, useState } from 'react'
import styled from '@emotion/styled'

import { useApiError, useMemoizedFn, useResponsive } from 'shared/hooks'

import { Input, Modal } from 'antd'
import { Button } from 'components'

import { WalletAddress } from 'core/walletaddress/models'
import { stringOmitMiddle } from 'shared/utils'

import closeImg from 'assets/closeImg.png'
interface Props {
    walletAddress: string
    open: boolean
    setOpen: (value: boolean) => void
    onBind: (address: string, name: string) => Promise<WalletAddress[]>
}

export const CreateWalletModal: FC<Props> = ({ walletAddress, open, setOpen, onBind }) => {
    const { md } = useResponsive()
    const {
        methods: { handleError }
    } = useApiError('CreateWalletModal')
    const [name, setName] = useState('')
    const [disabled, setDisabled] = useState<boolean>(true)

    const handleBind = useMemoizedFn(() => {
        if (!name) return
        onBind(walletAddress, name).catch(handleError)
        setOpen(false)
        setName('')
        setDisabled(true)
    })

    return (
        <Modal
            open={open}
            onCancel={() => setOpen(false)}
            width={md ? 520 : 315}
            footer={null}
            maskStyle={{ background: '#000000', opacity: 0.2 }}
            wrapClassName="add-wallet-modal"
            closeIcon={
                <img
                    style={md ? { marginTop: 24 } : { marginTop: 18 }}
                    width={24}
                    height={24}
                    src={closeImg}
                    onClick={() => setOpen(false)}
                    alt="closeImg"
                />
            }
        >
            <CreateWalletWrapper>
                <Header>Name</Header>
                <Label>Name</Label>
                <NameInput
                    value={name}
                    onChange={({ target: { value } }) => {
                        if (value && value.trim()) {
                            setName(value)
                            setDisabled(false)
                        } else {
                            setName('')
                            setDisabled(true)
                        }
                    }}
                />
                <Title>Address</Title>
                <AddressText>{md ? walletAddress : stringOmitMiddle(13, 10, walletAddress)}</AddressText>
                <BindButton disabled={disabled} onClick={handleBind}>
                    Bind
                </BindButton>
            </CreateWalletWrapper>
        </Modal>
    )
}

const Label = styled.div`
    font-family: Roboto-Regular;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    line-height: 26px;
    font-weight: 400;
    margin: 50px 0 9px;
    @media (max-width: 576px) {
        margin: 30px 0 9px;
    }
`

const NameInput = styled(Input)`
    height: 40px;
`

const BindButton = styled(Button)`
    margin: 30px auto 0;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: #000000;
    border-radius: 35px;
    font-family: Roboto-Medium;
    font-size: 24px !important;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    @media (max-width: 576px) {
        font-family: OpenSans-SemiBold;
        width: 222px;
        height: 50px;
        font-size: 16px !important;
        margin: 30px auto 0px;
    }
`

const AddressText = styled.div`
    font-family: Roboto-Regular;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    line-height: 26px;
    font-weight: 400;
`

const Title = styled.div`
    width: 100%;
    font-family: Roboto-Regular;
    font-size: 18px;
    color: #838383;
    letter-spacing: 0;
    line-height: 26px;
    font-weight: 400;
    margin-top: 25px;
    @media (max-width: 576px) {
        margin-top: 30px;
    }
`
const Header = styled.div`
    width: 100%;
    font-family: Roboto-Medium;
    font-size: 30px;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    line-height: 28px;
    font-weight: 500;
    @media (max-width: 576px) {
        font-family: Roboto-Medium;
        font-size: 20px;
        color: #000000;
    }
`

const CreateWalletWrapper = styled.div`
    /* width: 260px; */
    padding: 20px 4px 46px;
    @media (max-width: 768px) {
        padding: 20px 4px 16px;
    }
`
