import { useState, useCallback } from 'react'

interface State<T> {
    loading: boolean
    empty: boolean
    list: T[]
    total: number
    error?: string
}

export const useListState = <T>(initialState: Partial<State<T>> = {}) => {
    const [state, set] = useState<State<T>>({
        loading: false,
        empty: false,
        list: [],
        total: 0,
        ...initialState
    })

    const setState = useCallback(
        (p: Partial<Omit<State<T>, 'empty'>>) => {
            set(s => {
                const newState = { ...s, ...p }
                const { loading, list } = newState
                const empty = !loading && list.length === 0
                return { ...newState, empty }
            })
        },
        [set]
    )

    return [state, setState, set] as const
}
