import { useEffect } from 'react'
import { I18nMap } from 'core/language/models'
import { useI18n } from 'core/language/api'

export const useLanguageInit = (i18nMapConfig: I18nMap) => {
    const {
        methods: { setI18nMap, setDefaultLanguage }
    } = useI18n()

    useEffect(() => {
        // Setting up internationalization files
        setI18nMap(i18nMapConfig)
    }, [i18nMapConfig, setI18nMap])

    useEffect(() => {
        // Set default language
        setDefaultLanguage()
    }, [setDefaultLanguage])
}
