import { useMountedPromiseFn, useListState } from 'shared/hooks'
import { services } from 'core/management/frame/data-access'

import { useCallback } from 'react'
import { FramesList } from '../model'
import { useI18n } from 'core/language/api'

export const useFrameRentList = () => {
    const { lang } = useI18n()
    const [state, setState] = useListState<FramesList>()

    const getRentListApi = useMountedPromiseFn(services.getRentList)
    const getRentList = useCallback(() => {
        setState({ loading: true })
        return getRentListApi(lang)
            .then(list => {
                setState({ loading: false, list })
                return list
            })
            .catch(err => {
                setState({ loading: false })
                return Promise.reject(err)
            })
    }, [getRentListApi, lang, setState])

    return {
        ...state,
        methods: { getRentList }
    }
}
