import React, { FC, PropsWithChildren, useCallback } from 'react'
import styled from '@emotion/styled'

import { useI18n } from 'core/language/api'

import { Button } from '../Button'
import { useSignInRedirect } from '../../shared/hooks'

interface PageWrapperProps {
    className?: string
    showLoginBtn?: boolean
}

export const PageWrapper: FC<PropsWithChildren<PageWrapperProps>> = ({
    className,
    showLoginBtn = false,
    children
}) => {
    const {
        methods: { t }
    } = useI18n()
    const { checkLogin } = useSignInRedirect()

    const gotoLogin = useCallback(() => {
        checkLogin().catch()
    }, [checkLogin])

    return (
        <Wrapper className={`container mx-auto ${className}`}>
            {children}
            {showLoginBtn && (
                <div className="login-btn bottom-0 px-6 flex justify-center">
                    <Button className="w-full md:w-1/2" onClick={gotoLogin}>
                        {t('client.auth.goLogin')}
                    </Button>
                </div>
            )}
        </Wrapper>
    )
}

export const Wrapper = styled.div`
    position: relative;
    box-sizing: border-box;
    padding: 0 5rem;
    .login-btn {
        width: 100%;
        margin-bottom: 18vh;
    }
    @media (max-width: 576px) {
        padding: 0;
    }
`
