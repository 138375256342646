import { Language } from 'core/language/models'
import { ScanRentPaymentSuccess } from 'core/order/models'
import {
    CreateOrderFormData,
    OrderFormData,
    PriceParams,
    PriceType,
    Product,
    SelfRentalForm,
    touristCreateOrderFormData
} from 'core/scanRent/models'
import { stringify } from 'querystring'
import {
    clientHttp,
    apiHttp,
    pickValue,
    pickValueAndReferences,
    SuccessItemResponse,
    sellerHttp
} from 'shared/request'
import { pathJoin } from 'shared/utils'
import {
    convertToScanRentInDetail,
    convertToScanRentInList,
    convertToScanRentInProductFrame,
    FrameRentListDTO,
    ScanRentDetailDTO,
    ScanRentListDTO,
    ScanRentReferences
} from '../DTO'

const URI = 'product'
const URIS = 'frame/rent'
const PRICE = '/buy/pre/order/price'
const ORDER_URI = 'order'
const ORDER_TURI = '/order/tourist'

export const getList = (language: Language) => {
    return sellerHttp
        .get<SuccessItemResponse<FrameRentListDTO[]>>(URIS, { language })
        .then(pickValueAndReferences)
        .then(({ value }) => convertToScanRentInList(value))
}

export const getDetail = (id: string, language: Language) => {
    const url = pathJoin(URIS, id)
    return apiHttp
        .get<SuccessItemResponse<ScanRentDetailDTO>>(url, { language })
        .then(pickValue)
        .then(convertToScanRentInDetail)
}

export const getEnable = (frameId: string) => {
    return apiHttp.get<SuccessItemResponse<boolean>>('frame/order/enable', { frameId }).then(pickValue)
}

export const getProductFrame = (language: Language) => {
    const url = pathJoin(URI, 'frame')
    return clientHttp
        .get<SuccessItemResponse<ScanRentListDTO[], ScanRentReferences>>(url, { language })
        .then(pickValueAndReferences)
        .then(({ value, references }) => convertToScanRentInProductFrame(value, references))
}

export const create = (params: SelfRentalForm) => {
    return sellerHttp
        .post<SuccessItemResponse<SelfRentalForm[]>>(URIS, { ...params })
        .then(pickValueAndReferences)
}

export const getProduct = (language: Language, id: string) => {
    const url = pathJoin(URI, id)
    return clientHttp.get<SuccessItemResponse<Product>>(url, { language }).then(pickValueAndReferences)
}

export const buyProductPrice = (params: PriceParams) => {
    return clientHttp.post<SuccessItemResponse<PriceType>>(PRICE, { ...params }).then(pickValue)
}

export const order = (formData: OrderFormData, language: Language) => {
    const qsObj = { language }
    const url = `${ORDER_URI}?${stringify(qsObj)}`
    return clientHttp.post<SuccessItemResponse<string[]>>(url, { ...formData }).then(pickValue)
}

export const touristCreateOrder = (formData: touristCreateOrderFormData, language: Language) => {
    const qsObj = { language }
    const url = `${ORDER_TURI}?${stringify(qsObj)}`
    return clientHttp.post<SuccessItemResponse<string[]>>(url, { ...formData }).then(pickValue)
}

export const createOrder = (formData: CreateOrderFormData, language: Language) => {
    const qsObj = { language }
    const url = `buy/rent/${ORDER_URI}?${stringify(qsObj)}`
    return clientHttp.post<SuccessItemResponse<string[]>>(url, { ...formData }).then(pickValue)
}

export const getAccountInfo = (clientSecret: string) =>
    clientHttp
        .post<SuccessItemResponse<ScanRentPaymentSuccess>>('pay/account', { clientSecret })
        .then(pickValue)

export const cancelOrder = (clientSecret: string) => clientHttp.post('pay/cancel', { clientSecret })
