import { useCallback, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { setAuthFailedCallback } from 'shared/request'
import { getIsTourist } from 'shared/config'
import { useSignInRedirect } from './useSignInRedirect'

export const NOT_LOGIN_URL_LIST: string[] = [
    '/contact',
    '/products',
    '/rental',
    '/orders',
    '/orders/create',
    '/cart',
    '/address/select',
    '/address',
    '/auth',
    '/auth/sign_in',
    '/auth/sign_up',
    '/auth/forgot_password',
    'management',
    '/'
]

export const useCheckLogin = () => {
    const { getToken, checkLogin, loginOut } = useSignInRedirect()
    const { pathname } = useLocation()
    const navigate = useNavigate()

    const authFailedCallback = useCallback(() => {
        loginOut()
        checkLogin(pathname).catch()
    }, [checkLogin, loginOut, pathname])

    setAuthFailedCallback(authFailedCallback)

    useEffect(() => {
        // user signed
        if (
            !getToken() &&
            (pathname.includes('/mine') || pathname.includes('/address') || pathname.includes('/orders'))
        ) {
            checkLogin().catch()
        }

        // guest signed
        if (
            getIsTourist() &&
            getToken() &&
            (pathname.includes('/event/create') || pathname.includes('/event/edit'))
        ) {
            navigate('/event', { replace: true })
        }
    }, [checkLogin, pathname, navigate, getToken])
}
