import React, { FC, useCallback, useRef } from 'react'
import styled from '@emotion/styled'

import { Spin } from 'antd'
import { PageContainer } from 'components'
import { useApiError, useShowMessage } from 'shared/hooks'

import { Form, FormRefProps } from './components/form'
import { FormHeader } from './components/formHeader'
import { useEventCreate } from '../../core/event/api'

export const EventCreate: FC = () => {
    const {
        loading,
        methods: { create }
    } = useEventCreate()
    const {
        methods: { success }
    } = useShowMessage()
    const {
        methods: { handleError }
    } = useApiError('FeatureEventCreate')

    const ref = useRef<FormRefProps>({} as FormRefProps)
    const handleSave = useCallback(() => {
        ref.current.submit(data => {
            create(data)
                .then(_ => {
                    ref.current.reset()
                    success('Success')
                })
                .catch(handleError)
        })
    }, [create, handleError, success])

    return (
        <Spin spinning={loading}>
            <Container>
                <FormHeader title="Create" onSave={handleSave} />
                <Form ref={ref} type="create" />
            </Container>
        </Spin>
    )
}

const Container = styled(PageContainer)`
    width: 100%;
    padding: 0 10rem 10rem 10rem;
    @media (max-width: 576px) {
        padding: 0 0 70px;
        width: 100%;
    }
`
