import React, { FC } from 'react'
import styled from '@emotion/styled'
import { Element } from 'react-scroll'

import { DOWNLOAD_URL_ANDROID, DOWNLOAD_URL_IOS } from 'shared/config'
import { useResponsive } from 'shared/hooks'

import { DivImage, SectionTitle, Video } from 'components'

import videoFrame from 'assets/frame.mp4'
import imgGoogle from 'assets/google.png'
import imgApple from 'assets/apple.png'
import emblaCarouselReact from 'embla-carousel-react'

const VideoGuide = styled.a`
    font-family: HelveticaNeue-Medium;
    font-size: 18px;
    color: #2f2e2e;
    line-height: 24px;
`
interface DownloadAppProps {
    spaceClassName?: string
}

export const DownloadApp: FC<DownloadAppProps> = ({ spaceClassName }) => (
    <div className="mt-8">
        <a href={DOWNLOAD_URL_IOS} target="_blank" rel="noopener noreferrer">
            <img src={imgApple} alt="" style={{ height: 64 }} />
        </a>
        <a
            className={spaceClassName || 'ml-8'}
            href={DOWNLOAD_URL_ANDROID}
            target="_blank"
            rel="noopener noreferrer"
        >
            <img src={imgGoogle} alt="" style={{ height: 64 }} />
        </a>
    </div>
)

const list = [
    {
        title: 'Dimensions',
        contents: ['Square 22" & Square 33"', '0.29-inch Bezel', '0.85-inch Thickness']
    },
    {
        title: 'Display',
        contents: ['High Resolution', 'Anti-glare Display', 'Magnificent Image Quality'],
        nodes: [<Element name="downloadElement" />]
    },
    {
        title: 'App Control',
        contents: [
            'Remote control the display through the MetaSill app to curate and manage your artwork between all of your frames. Keep your art and environment new and fresh.'
        ],
        nodes: [
            <VideoGuide
                className="underline"
                href="https://www.youtube.com/watch?v=PJKlN0xlRk8"
                target="_blank"
                rel="noopener noreferrer"
            >
                Video Tutorial Guide
            </VideoGuide>,
            <VideoGuide
                className="underline"
                href="https://682a4175-98e2-481d-9ace-6dbdbc69ddf4.usrfiles.com/ugd/682a41_1e972ed833fe4fff8756a5549e295237.pdf"
                target="_blank"
                rel="noopener noreferrer"
            >
                Download User Guide
            </VideoGuide>,
            <DownloadApp />
        ]
    }
]

export const NFTFrame: FC = () => {
    const { md } = useResponsive()

    return (
        <Wrapper className="px-12 md:px-0">
            <div className="w-2/3 md:w-full">
                <SectionTitle className="mb-20 md:mb-32" title="METASILL NFT FRAME" />
            </div>
            {md ? (
                <>
                    <div className="flex items-center">
                        <div className="w-1/3">
                            <div className="flex items-center justify-end">
                                <div>
                                    <FrameDesc>Square 22-inch Model</FrameDesc>
                                    <FrameDesc>Square 33-inch Model</FrameDesc>
                                </div>
                                <div className="w-48 ml-2">
                                    <Divider />
                                </div>
                            </div>
                            <div className="flex items-center justify-end my-5">
                                <div>
                                    <FrameDesc>High Resolution</FrameDesc>
                                    <FrameDesc>Magnificent Image Quality</FrameDesc>
                                </div>
                                <div className="w-48 ml-2">
                                    <Divider />
                                </div>
                            </div>
                            <div className="flex items-center justify-end">
                                <div>
                                    <FrameDesc>Sleek & Moden Design</FrameDesc>
                                    <FrameDesc>0.29-inch Bezel</FrameDesc>
                                    <FrameDesc>0.85-inch Thickness</FrameDesc>
                                </div>
                                <div className="w-48 ml-2">
                                    <Divider />
                                </div>
                            </div>
                        </div>
                        <div className="w-1/3 pr-14">
                            <Video src={videoFrame} />
                        </div>
                        <div className="w-1/3">
                            <Displaying>Displaying your NFT Has Never Been This Easy</Displaying>
                            <DisplayingDesc className="pt-8 pb-4">
                                Remote control the display through the MetaSill app to curate and manage your
                                artwork between all of your frames. Keep your art and environment new and
                                fresh.
                            </DisplayingDesc>
                            <Element name="downloadElement" />
                            <div className="flex">
                                <a
                                    href="https://www.youtube.com/watch?v=PJKlN0xlRk8"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <GuideLink>Video Tutorial Guide</GuideLink>
                                </a>
                                <a
                                    className="ml-8"
                                    href="https://682a4175-98e2-481d-9ace-6dbdbc69ddf4.usrfiles.com/ugd/682a41_1e972ed833fe4fff8756a5549e295237.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <GuideLink>Download User Guide</GuideLink>
                                </a>
                            </div>
                        </div>
                    </div>
                    <DownloadWrapper>
                        <Available>*Available on iOS and Android Now</Available>
                        <span className="flex justify-center items-center">
                            <a
                                className="mr-16"
                                href="https://apps.apple.com/us/app/metasill/id1612433405"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <DivImage src={imgApple} width={24} height={7.3} />
                            </a>
                            <a
                                href="https://play.google.com/store/apps/details?id=com.metasill.metasill"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <DivImage className="ml-6" src={imgGoogle} width={24} height={7.3} />
                            </a>
                        </span>
                    </DownloadWrapper>
                </>
            ) : (
                <>
                    <div className="w-full md:w-1/2 mx-auto my-0">
                        <Video src={videoFrame} />
                    </div>
                    <div>
                        {list.map(({ title, contents, nodes }) => (
                            <div className="my-8" key={title}>
                                <div className="mb-4">
                                    <Title>{title}</Title>
                                </div>
                                <div className="flex items-top">
                                    <Line className="h-0 mt-8 mr-8" />
                                    <div className="flex-1">
                                        {contents.map((text, idx) => (
                                            <Text className="pb-2" key={idx}>
                                                {text}
                                            </Text>
                                        ))}
                                        {nodes &&
                                            nodes.map((node, idx) => (
                                                <div className="pt-2" key={idx}>
                                                    {node}
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </Wrapper>
    )
}

const DownloadWrapper = styled.div`
    margin: 4rem 36rem 0;
    padding: 4rem 0 5rem;
    text-align: center;
    background: rgba(217, 217, 217, 0.25);
    border-radius: 20px;

    @media (min-width: 1920px) {
        margin: 4rem 24rem 0;
    }
`

const Wrapper = styled.div`
    margin-top: 142px;
    @media (max-width: 1024px) {
        margin-top: 40px;
    }
`

const Text = styled.div`
    font-family: HelveticaNeue-Medium;
    font-size: 18px;
    color: #5d5d5d;
    line-height: 24px;
`

const Line = styled.div`
    width: 60px;
    border-bottom: 1px solid rgba(183, 183, 191, 1);
`

const Title = styled.div`
    font-family: HelveticaNeue-CondensedBold;
    font-size: 24px;
    color: #000;
    line-height: 36px;
    font-weight: bold;
`

const GuideLink = styled.div`
    font-family: HelveticaNeue-Medium;
    font-size: 1.8rem;
    color: #2f2e2e;
    line-height: 2.4rem;
    text-decoration: underline;
`

const DisplayingDesc = styled.div`
    font-family: Roboto-Regular;
    font-size: 1.8rem;
    color: #2f2e2e;
    line-height: 2.1rem;
`

const Displaying = styled.div`
    font-family: HelveticaNeue-CondensedBold;
    font-size: 3rem;
    color: #000;
    line-height: 3.6rem;
    font-weight: bold;
`

const Available = styled.div`
    font-family: HelveticaNeue-Medium;
    font-size: 1.4rem;
    color: #2f2e2e;
    line-height: 2.4rem;
    text-decoration: underline;
    margin-bottom: 3rem;
`

const FrameDesc = styled.div`
    font-family: HelveticaNeue-Medium;
    font-size: 1.8rem;
    color: #5d5d5d;
    text-align: right;
    line-height: 2.4rem;
`

const Divider = styled.div`
    border-bottom: 1px solid rgba(183, 183, 191, 1);
`
