interface NftBase {
    id?: string
    name: string
    description: string
    tag: string[]
    fileId: string[]
    address: string
}

export interface NftFormData extends NftBase {
    externalUrl: string
}

export enum NftStatusEnum {
    Reviewing,
    Approved,
    Rejected
}

export interface NftInList extends NftBase {
    status: NftStatusEnum
    fileUrl: string
    note: string
    thumbUrl: string
    type: 'image' | 'video'
    address: string
    nftId: string
    externalUrl: string
}

export interface NftDetail extends NftInList {
    token_id: string
}

export interface NftUploadFileType {
    fileUrl: string
    type: string
}

export interface NftParams {
    chain?: string
    offset?: string
    page_size?: number
    type: string
}

export interface uploadParams {
    pageNum: string
    pageSize: string
}

export interface MyCollectionList {
    name: string
    imgUrl: string
    id: string
    tokenAddress: string
    tokenId: string
    type: string
    animationUrl: string
    description: string
    backgroundColor: string
    owner: string
}

export enum NftTypeEnum {
    OWNED = 'owned',
    AUTHORIZED = 'authorized',
    UPLOAD = 'uploaded'
}
export interface Params {
    type: NftTypeEnum
    chain?: number
    offset?: number
}

export interface AuthAddressItem {
    address: string
    addressType: number
    id: string | number
    name: string
    ownerName?: string
    type?: number
    email?: string
    ownerId?: string
}
