import styled from '@emotion/styled'

export const ListHeaderItem = styled.div`
    height: 44px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 0.68px solid rgba(0, 0, 0, 1);
    border-radius: 22px;
    padding: 5px 14px;
    font-family: Roboto-Medium;
    font-size: 18px;
    font-weight: 400;
    @media (max-width: 768px) {
        width: 96px;
        min-height: 50px;
        font-family: Roboto-Medium;
        font-size: 12px;
    }
`
