import { useCallback } from 'react'

import { useMountedPromiseFn, useDetailState } from 'shared/hooks'
import { services } from 'core/event/data-access'
import { EventForm } from '../../../models'

export const useEventEdit = () => {
    const [state, setState] = useDetailState()

    const setEditApi = useMountedPromiseFn(services.setEdit)
    const setEdit = useCallback(
        (id: string, postData: EventForm) => {
            setState({ loading: true })
            return setEditApi(id, postData)
                .then(res => {
                    setState({ loading: false })
                    return Promise.resolve(res)
                })
                .catch(err => {
                    setState({ loading: false })
                    return Promise.reject(err)
                })
        },
        [setEditApi, setState]
    )

    return {
        ...state,
        methods: {
            setEdit
        }
    }
}
