/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, useState, useEffect, useCallback, useMemo, Dispatch, SetStateAction } from 'react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { equals } from 'ramda'

import { logEvent } from 'firebase/analytics'
import { analytics } from 'shared/utils/firebase'
import { useI18n } from 'core/language/api'
import { useApiError, useMemoizedFn, useShowMessage, useSignInRedirect } from 'shared/hooks'
import { useAddCartItem, useMiniCartDrawer } from 'core/cart/api'
import { useAuth } from 'core/auth/api'
import { SIGN_STATUS_ENUM } from 'core/auth/models'
import { Product } from 'core/product/models'
import { copy } from 'shared/utils'
import { getIsTourist } from 'shared/config'

import { Checkbox } from 'antd'
import { Spin } from 'components'
import { AddCartButton } from './AddCartButton'

import share from '../assets/share.png'
import reduce from '../assets/reduce.png'
import add from '../assets/add.png'
import addProhibit from '../assets/addProhibit.png'
import helperIcon from '../assets/helper.png'

interface ProductInfoRentProps {
    id: string
    detail: Product
    selectedAttrs: Record<string, string>
    setSelectedAttrs: Dispatch<SetStateAction<Record<string, string>>>
}

export const ProductInfoRent: FC<ProductInfoRentProps> = ({
    id,
    detail,
    selectedAttrs,
    setSelectedAttrs
}) => {
    const {
        methods: { t }
    } = useI18n()
    const {
        methods: { handleError }
    } = useApiError('ProductInfoRent')
    const {
        methods: { showSingleButtonModal, success, error }
    } = useShowMessage()
    const navigate = useNavigate()

    const [duration, setDuration] = useState<string[]>(detail.durations.map(({ label }) => label))

    useEffect(() => {
        detail?.isMultiSku &&
            setSelectedAttrs(() => {
                const attrs: Record<string, string> = {}
                detail?.attrList?.forEach(({ id, values }) => {
                    attrs[id] = values[0].id
                })
                return attrs
            })
    }, [detail, duration, setSelectedAttrs])
    const selectedSku = useMemo(() => {
        if (!detail || !selectedAttrs) return null
        return detail?.skuList?.find(({ specObj }) => equals(specObj, selectedAttrs))
    }, [detail, selectedAttrs])

    const setSelectedAttrVal = useCallback(
        (attrId: string, valueId: string) => {
            setSelectedAttrs(prevState => ({
                ...prevState,
                [attrId]: valueId
            }))
        },
        [setSelectedAttrs]
    )

    const {
        loading: cartLoading,
        methods: { addItem }
    } = useAddCartItem()
    const {
        methods: { updateMiniCartDrawerVisible }
    } = useMiniCartDrawer()
    const [quantity, setQuantity] = useState<number>(1)
    const addToCartLogEvent = useMemoizedFn(() => {
        let variant = ''
        detail?.attrList?.forEach(({ id: attrId, values, name }) => {
            variant += `${name}:`
            values.forEach(({ id, name: valuesName }) => {
                if (selectedAttrs[attrId] === id) {
                    variant += `${valuesName};`
                }
            })
        })
        logEvent(analytics, 'add_to_cart' as never, {
            value: Number(detail?.showPrice) * quantity,
            items: [
                {
                    id: detail?.id,
                    name: detail?.name,
                    price: detail?.showPrice,
                    quantity: quantity,
                    variant: variant
                }
            ]
        })
    })

    const handleAddCart = useMemoizedFn(() => {
        if (!id) return
        if (selectedSku?.preOrder && quantity > selectedSku.onceLimit) {
            error(t('error.quantity_once_limit'))
            return
        }
        ;(() => {
            return addItem({
                type: detail.type,
                productId: id,
                quantity,
                skuId: detail?.isMultiSku ? selectedSku?.id : undefined,
                timeZone: detail?.timeZone,
                duration: duration.sort((a: string, b: string) => +a - +b).join(',')
            })
        })()
            .then(() => {
                addToCartLogEvent()
                updateMiniCartDrawerVisible(true)
            })
            .catch(handleError)
    })

    const isOutStock = useMemo(() => {
        if (detail && detail?.isMultiSku) {
            if (selectedSku?.preOrder) {
                return false
            }
            return selectedSku ? quantity >= selectedSku?.stock : false
        } else {
            if (detail?.preOrder) {
                return false
            }
            return quantity >= (detail?.stock as number)
        }
    }, [detail, quantity, selectedSku])

    // const isDeliveryFree = useMemo(() => {
    //     return detail?.isMultiSku ? selectedSku?.isDeliveryFree : detail?.isDeliveryFree
    // }, [detail, selectedSku])

    const { getToken, checkLogin } = useSignInRedirect()
    const {
        methods: { getUserInfo }
    } = useAuth()
    const shareLogEvent = useMemoizedFn(() => {
        logEvent(analytics, 'share' as never, {
            content_type: 'product',
            item_id: detail?.id
        })
    })

    const getQueryString = useMemoizedFn((name: string, url: string) => {
        let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
        let r = url.match(reg)
        if (r != null) {
            return decodeURIComponent(r[2])
        }
        return null
    })

    const handleShare = useMemoizedFn(() => {
        if (!getToken()) {
            checkLogin('/products/' + id).catch()
            return
        }
        if (getIsTourist()) {
            showSingleButtonModal({
                message:
                    'Click "Generate" button and obtain your unique affiliate link. Start earning commissions today.',
                type: 'fail',
                buttonText: 'Generate',
                onButtonClick: () => navigate('/auth/' + SIGN_STATUS_ENUM.TOURIST_BINGING)
            })
            return
        }
        getUserInfo()
            .then(data => {
                const href = window.location.href
                const shareCode = getQueryString('aff', data.link.split('?')[1])
                if (href.includes('aff')) {
                    copy(`${href.split('?')[0]}?aff=${shareCode}`)
                } else {
                    copy(`${href}?aff=${shareCode}`)
                }
                shareLogEvent()
                success(
                    `If the link is copied and another user purchases through the link in the same session, you will receive a ${data.commissionRate}% commission!`,
                    { title: 'Copy link successfully', permanent: true }
                )
            })
            .catch(handleError)
    })

    const stockNum = useMemo(() => {
        let stock = 0
        if (detail && detail?.isMultiSku) {
            stock = selectedSku?.stock as number
        } else {
            stock = detail?.stock as number
        }
        return stock
    }, [detail, selectedSku])

    const stockStatusText = useMemo(() => {
        if (stockNum >= 10) {
            return 'In Stock'
        }

        if (stockNum < 10 && stockNum > 0) {
            return 'Only a few left in stock - order soon'
        }
        return 'Temporarily Sold Out'
    }, [stockNum])

    const addCartButton = useMemo(() => {
        if (detail && detail?.isMultiSku) {
            const preOrder = selectedSku?.preOrder
            const stock = selectedSku?.stock as number
            if (preOrder && (stock <= 0 || quantity > stock)) {
                return <AddCartButton onClick={handleAddCart}>Pre-Order</AddCartButton>
            } else {
                return (
                    <AddCartButton disabled={stockNum <= 0} onClick={handleAddCart}>
                        Add To Cart
                    </AddCartButton>
                )
            }
        } else {
            const preOrder = detail?.preOrder
            const stock = detail?.stock as number
            if (preOrder && (stock <= 0 || quantity > stock)) {
                return <AddCartButton onClick={handleAddCart}>Pre-Order</AddCartButton>
            } else {
                return (
                    <AddCartButton disabled={stockNum <= 0} onClick={handleAddCart}>
                        Add To Cart
                    </AddCartButton>
                )
            }
        }
    }, [detail, handleAddCart, quantity, selectedSku, stockNum])

    return (
        <ProductsInfo>
            <Spin spinning={cartLoading}>
                <div className="title">
                    <div className="name">{detail?.name}</div>
                    <img src={share} alt="" onClick={handleShare} />
                </div>
                <div className="describe">{detail?.description}</div>
                {/* <div className="tips">
                    *Order Now and Arrive in 3-7 days
                </div> */}
                <div className="price">
                    <div className="value">
                        <span>$</span>
                        {selectedSku?.price || detail?.showPrice}
                    </div>
                    {/*<div className="type">{isDeliveryFree ? 'FREE Shipping' : 'Shipping'}</div>*/}
                    <div className="type"></div>
                </div>
                {!detail.virtual && (
                    <div className="depositPrice infoItem">
                        <div className="label">
                            <div className="depositName">Deposit</div>
                            <div className="helperBtn">
                                <div className="helpContent">
                                    The deposit will be returned when the equipment is returned
                                </div>
                            </div>
                        </div>
                        <div className="value">
                            <span>$</span>
                            {selectedSku?.depositPrice || detail?.depositPrice}
                        </div>
                    </div>
                )}
                {detail?.attrList?.map(attr => (
                    <div key={attr.id} className="options infoItem">
                        <div className="label">{attr.name}</div>
                        <div className="list">
                            {attr.values.map(attrVal => (
                                <div
                                    key={attrVal.id}
                                    className={`item ${
                                        selectedAttrs && selectedAttrs[attr.id] === attrVal.id ? 'active' : ''
                                    }`}
                                    onClick={() => setSelectedAttrVal(attr.id, attrVal.id)}
                                >
                                    {attrVal.name}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
                {detail?.durations.length > 0 && (
                    <div className="time infoItem">
                        <div className="duration">
                            {detail?.durations?.map(({ label, value }) => (
                                <Checkbox
                                    key={label}
                                    checked={duration.includes(label)}
                                    disabled
                                    onChange={({ target: { checked } }) =>
                                        setDuration(prevState => {
                                            const newState = [...prevState]
                                            if (!checked) return newState.filter(item => item !== label)
                                            newState.push(label)
                                            return newState
                                        })
                                    }
                                >
                                    {value}
                                </Checkbox>
                            ))}
                        </div>
                    </div>
                )}
                <div className="quantity infoItem">
                    <div className="label">
                        Quantity<span>{stockStatusText}</span>
                    </div>
                    <div className="btn">
                        <div
                            className="reduce"
                            onClick={() => setQuantity(state => (state > 1 ? state - 1 : 1))}
                        >
                            <img src={reduce} alt="" />
                        </div>
                        <div className="value">{quantity}</div>
                        {!isOutStock ? (
                            <div className="add" onClick={() => setQuantity(state => state + 1)}>
                                <img src={add} alt="" />
                            </div>
                        ) : (
                            <div className="add">
                                <img src={addProhibit} alt="" />
                            </div>
                        )}
                    </div>
                </div>
                {addCartButton}
                {/* <AddCartButton disabled={stockNum <= 0} onClick={handleAddCart}>
                    Add To Cart
                </AddCartButton> */}
            </Spin>
        </ProductsInfo>
    )
}

const ProductsInfo = styled.div`
    position: relative;
    box-sizing: border-box;
    width: 50%;
    padding-left: 7.2rem;
    @media (max-width: 576px) {
        width: 100%;
        padding: 20px 24px;
    }
    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 2.4rem;
        font-family: HelveticaNeue-Bold;
        font-size: 28px;
        color: #000000;
        letter-spacing: 0;
        line-height: 30px;
        font-weight: 700;
        .name {
            flex: 1;
        }
        img {
            width: 34px;
            height: 34px;
            cursor: pointer;
        }
        @media (max-width: 576px) {
            margin-bottom: 10px;
            font-size: 22px;
        }
    }
    .describe {
        margin-bottom: 2.4rem;
        font-family: Roboto-Regular;
        font-size: 18px;
        color: #000000;
        letter-spacing: 0;
        line-height: 24px;
        font-weight: 400;
        white-space: pre-wrap;
        @media (max-width: 576px) {
            margin-bottom: 24px;
            font-size: 16px;
        }
    }
    .tips {
        margin-bottom: 14.4296875px;
        color: #2f2e2e;
        font-weight: normal;
        font-size: 12px;
        font-style: normal;
        letter-spacing: 0;
        line-height: 1.6;
    }
    .infoItem {
        .label {
            font-family: PingFangSC-Regular;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 10px;
            font-family: Roboto-Regular;
            font-size: 16px;
            color: #000000;
            line-height: 24px;
            text-decoration: none;
            text-transform: capitalize;
            text-align: left;
            span {
                margin-left: 11px;
                font-size: 14px;
                color: rgba(102, 102, 102, 0.85);
                letter-spacing: 0;
                text-align: right;
                font-weight: 400;
            }
            @media (max-width: 576px) {
                width: 100%;
            }
        }
    }
    .options {
        .list {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
            width: 85%;
            font-family: PingFangSC-Regular;
            @media (max-width: 576px) {
                width: 100%;
            }
        }
        .item {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 36px;
            padding: 0 8px;
            margin-right: 1.4rem;
            margin-bottom: 1.4rem;
            border: 1px solid #c5c5c5;
            border-radius: 10px;
            font-family: PingFangSC-Regular;
            cursor: pointer;
            font-size: 14px;
            @media (max-width: 576px) {
                width: auto;
                height: 36px;
                padding: 0 10px;
            }
        }
        .active {
            border: 1px solid #000;
            box-sizing: border-box;
        }
    }

    .price {
        display: flex;
        align-self: center;
        justify-self: start;
        margin-bottom: 2.5rem;
        @media (max-width: 576px) {
            margin-bottom: 1.4rem;
        }
        .value {
            display: flex;
            align-self: center;
            justify-self: start;
            margin-right: 20px;

            font-family: HelveticaNeue-Bold;
            font-size: 30px;
            color: #2f2e2e;
            letter-spacing: 0;
            text-align: center;
            line-height: 32px;
            font-weight: 700;
            span {
                font-family: HelveticaNeue-Bold;
                font-size: 20px;
                color: #2f2e2e;
                letter-spacing: 0;
                text-align: center;
                line-height: 28px;
                font-weight: 700;
            }
        }
        .type {
            display: flex;
            align-self: center;
            justify-self: start;
            font-family: Roboto-Regular;
            font-size: 16px;
            color: #000000;
            letter-spacing: 0;
            line-height: 24px;
            font-weight: 400;
        }
    }
    .time {
        margin: 1rem 0;
        .time-label {
            font-family: Roboto-Regular;
            font-size: 16px;
            color: #000;
            line-height: 24px;
        }
        .duration {
            width: 288px;
            border: 1px solid rgba(0, 0, 0, 1);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            padding: 19px 18px 4px 18px;
            margin-top: 10px;
            .ant-checkbox-wrapper {
                margin: 0 !important;
                margin-bottom: 15px !important;
                font-size: 14px !important;
            }
        }
    }
    .quantity {
        margin-bottom: 4rem;
        padding-top: 1.4rem;
        .btn {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 43.8%;
            height: 38px;
            .value {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 5.3rem;
                font-family: PingFangSC-Semibold;
                font-size: 18px;
                color: #303030;
                letter-spacing: 1px;
                line-height: 20px;
                font-weight: 600;
            }
            .add,
            .reduce {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                padding-bottom: 4px;
                border-radius: 10px;
                cursor: pointer;
            }
            .prohibit {
                color: #909090;
                background: #e0e0e0;
            }
        }
    }
    .depositPrice {
        margin-bottom: 24px;
        .label {
            display: flex;
            align-items: center;

            font-family: Roboto-Regular;
            font-size: 16px;
            color: #000000;
            line-height: 24px;
        }
        .depositName {
            font-family: Roboto-Regular;
            font-size: 16px;
            color: #000000;
            letter-spacing: 0;
            line-height: 24px;
        }
        .value {
            font-family: HelveticaNeue-Medium;
            font-size: 24px;
            color: #000;
            letter-spacing: 0.69px;
            line-height: 24px;
        }
    }
    .rangePicker {
        border: 1px solid #303030;
        border-radius: 10px;
        width: 354px;
        height: 36px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #000;
        line-height: 22px;
        .ant-picker-range-separator {
            padding: 0 50px 6px 20px !important;
        }
    }
    .helperBtn {
        width: 16px;
        height: 16px;
        margin-left: 10px;
        background: url('${helperIcon}') no-repeat center / contain;
        cursor: default;
        position: relative;
        .helpContent {
            display: none;
            position: absolute;
            left: 26px;
            top: -10px;
            width: 245px;
            height: auto;
            padding: 12px 9px;
            background-color: #eee;
            border-radius: 5px;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            line-height: 20px;
            color: #000;
            font-weight: 400;
            ::before {
                display: block;
                content: '';
                width: 0;
                height: 0;
                border-width: 6px;
                border-style: solid;
                border-color: transparent #eee transparent transparent;
                position: absolute;
                left: -12px;
                top: 12px;
            }
        }
        &:hover .helpContent {
            display: block;
        }
    }
    .addCartDisable {
        border: 2px solid #ccc !important;
        background-color: #fff !important;
    }
    .addCartDisable .text-white {
        color: #ccc !important;
        line-height: 46px !important;
    }
    @media (max-width: 768px) {
        .rangePicker {
            width: 100%;
        }
    }
`
