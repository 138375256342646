import React, { FC, useEffect } from 'react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'

import { useProductList } from 'core/product/api'
import { useApiError } from 'shared/hooks'

import { Spin } from 'antd'
import { PageContainer, ShopProductItem } from 'components'

export const ProductIndex: FC = () => {
    const navigate = useNavigate()
    const {
        methods: { handleError }
    } = useApiError('FeatureProductDetail')

    const {
        list,
        loading,
        methods: { getList }
    } = useProductList()

    useEffect(() => {
        getList().catch(handleError)
    }, [getList, handleError])

    return (
        <Spin spinning={loading}>
            <Container>
                {list.map(({ id, name, products }) => (
                    <Wrapper key={id}>
                        <div className="title">{name}</div>
                        <div className="productList">
                            {products.map(item => (
                                <ShopProductItem
                                    className="item"
                                    key={item.id}
                                    id={item.id}
                                    image={item.image}
                                    name={item.name}
                                    showPrice={item.showPrice}
                                    onClick={() => navigate(item.id)}
                                    footer={
                                        <div className="buy" onClick={() => navigate(item.id)}>
                                            Buy
                                        </div>
                                    }
                                />
                            ))}
                        </div>
                    </Wrapper>
                ))}
            </Container>
        </Spin>
    )
}

const Container = styled(PageContainer)`
    width: 100vw;
    padding: 5% 10rem;
    @media (max-width: 576px) {
        padding: 0;
        padding-bottom: 70px;
        width: 100%;
    }
`

const Wrapper = styled.div`
    position: relative;
    box-sizing: border-box;
    .title {
        margin-bottom: 40px;
        font-size: 45px;
        font-style: normal;
        text-decoration: none;
        text-align: left;
        font-weight: bold;
        word-break: break-all;
        @media (max-width: 576px) {
            padding: 30px 20px 20px 20px;
            margin-bottom: 0;
            font-family: HelveticaNeue-Bold;
            font-size: 30px;
            color: #000000;
            letter-spacing: 0;
            line-height: 40px;
            font-weight: 700;
        }
    }
    .productList {
        position: relative;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-right: -7.2rem;
        @media (max-width: 576px) {
            padding: 0 20px;
            margin-right: 0;
            flex-direction: column;
            justify-content: center;
        }
    }
    .item {
        height: auto;
        width: 25%;
        margin-bottom: 56px;
        padding-right: 7.2rem;
    }
    .buy {
        width: 100px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        border: 1px solid #000;
        border-radius: 36px;

        font-family: Roboto-Medium;
        font-size: 14px;
        color: #000000;
        letter-spacing: 0;
        text-align: center;
        line-height: 20px;
        font-weight: 500;
        cursor: pointer;
    }
    .buy:hover {
        background-color: #000;
        color: #fff;
    }
    @media (max-width: 576px) {
        .item {
            width: 100%;
            padding-right: 0;
            margin-bottom: 3rem;
        }
    }
`
