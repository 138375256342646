import { logEvent } from 'firebase/analytics'

import { useI18n } from 'core/language/api'
import { services } from 'core/product/data-access'
import { Product } from 'core/product/models'
import { useDetailState, useMemoizedFn, useMountedPromiseFn } from 'shared/hooks'
import { analytics } from 'shared/utils'

export const useProductDetail = (id: string, eventId?: string) => {
    const { lang } = useI18n()

    const [state, setState] = useDetailState<Product>()

    const getDetailApi = useMountedPromiseFn(services.getDetail)
    const getDetail = useMemoizedFn(() => {
        setState({ loading: true })
        return getDetailApi(id, lang, eventId)
            .then(detail => {
                logEvent(analytics, 'select_item', {
                    items: [{ id: detail.id, name: detail.name, price: detail.showPrice }]
                })
                setState({ detail, loading: false })
                return detail
            })
            .catch(err => {
                setState({ loading: false })
                return Promise.reject(err)
            })
    })

    return {
        ...state,
        methods: {
            getDetail
        }
    }
}
