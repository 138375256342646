import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'

import { useResponsive } from 'shared/hooks'

import { Text, TextProps } from '@mantine/core'
import { Media } from 'components'
import { InfiniteScroll } from './InfiniteScroll'

interface ListProps {
    id: string
    name: string
    imgUrl: string
    backgroundColor: string
}

export interface Props {
    list: ListProps[]
    hasMore: boolean
    loadMore: () => Promise<void>
}

export const CollectionListNew: FC<Props> = ({ list, hasMore, loadMore }) => {
    const { md } = useResponsive()
    const navigate = useNavigate()
    return (
        <>
            <CollectionListWrapper>
                {list.length > 0 &&
                    list.map(({ id, name, imgUrl, backgroundColor }) => (
                        <ListWrapperItem key={id}>
                            <div
                                className="list-item"
                                onClick={() => navigate(`/management/collection/${id}`)}
                            >
                                <div
                                    style={
                                        backgroundColor
                                            ? { background: `${backgroundColor}` }
                                            : {
                                                  border: '1px solid #cccccc',
                                                  background: `${backgroundColor}`
                                              }
                                    }
                                >
                                    <div className="relative" />
                                    <Media
                                        size={
                                            md ? (backgroundColor ? 200 : 198) : backgroundColor ? 160 : 158
                                        }
                                        src={imgUrl}
                                    />
                                </div>
                                <Info>
                                    <InfoName>
                                        <Name lineClamp={2}>{name}</Name>
                                    </InfoName>
                                </Info>
                            </div>
                        </ListWrapperItem>
                    ))}
            </CollectionListWrapper>
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
        </>
    )
}
const InfoName = styled.div`
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
        height: 34px;
    }
`
const Name = styled(Text)<TextProps>`
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #000;
    line-height: 20px;
    padding: 0 6px;
    @media (max-width: 768px) {
        font-size: 13px;
        /* height: 29px; */
        line-height: 17px;
    }
`

const Info = styled.div`
    padding: 13px 0;
    text-align: center;
    @media (max-width: 768px) {
        padding: 12px 0;
    }
`

const CollectionListWrapper = styled.div`
    margin: 30px 0 0 0;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 200px);
    grid-gap: 40px;
    .list-item {
        display: block;
        width: 100%;
        background: #fff;
        box-shadow: 0 7px 16px 0 rgba(222, 222, 222, 0.5);
        cursor: pointer;
    }
    @media (max-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        grid-template-columns: repeat(auto-fill, 160px);
        justify-content: space-between;
        grid-gap: 0px;
        margin: 24px 0 0 0;
    }
`
const ListWrapperItem = styled.div`
    height: 266px;
    @media (max-width: 768px) {
        width: 160px;
        height: 260px;
    }
`
