import { useLocalStorage } from '@mantine/hooks'

import { useUnmount } from './useUnmount'

export const useRememberManagementSelected = (
    pageKey: string,
    id?: string | number,
    select?: boolean,
    nfts?: boolean
) => {
    const [selectedId, setValue] = useLocalStorage({ key: pageKey })

    if (nfts) {
        setValue(`${id}`)
    }
    useUnmount(() => {
        if (select) {
            setValue(`${id}`)
        }
    })
    return selectedId
}
