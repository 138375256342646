import React, { FC, useMemo } from 'react'

import styled from '@emotion/styled'

import { Popup } from 'antd-mobile'
import { AuthAddressItem, NftTypeEnum } from 'core/management/nft/models'

import { stringOmitMiddle } from 'shared/utils'
import { setLastwalletAddressId } from 'shared/config'

import myAccountImg from '../assets/myAccount.png'
import walletImg from '../assets/walletImg.png'
import autherizedAccountImg from '../assets/autherized-account.png'
import autherizedWalletImg from '../assets/autherized-wallet.png'
import okIcon from '../assets/selectOk.png'

const addressImages = [
    myAccountImg,
    walletImg,
    autherizedWalletImg,
    autherizedAccountImg,
    autherizedWalletImg
]

interface SelectAdressPopupProps {
    type: NftTypeEnum
    visible: boolean
    setVisible: (val: boolean) => void
    list: Array<AuthAddressItem>
    walletAddress: AuthAddressItem
    setWalletAddress: (walletAddress: AuthAddressItem) => void
    isFrame?: boolean
    setIsClick?: (val: boolean) => void
}

export const SelectAdressPopup: FC<SelectAdressPopupProps> = ({
    type,
    visible,
    setVisible,
    list,
    walletAddress,
    setWalletAddress,
    isFrame,
    setIsClick
}) => {
    const mapList = useMemo(() => {
        if (type === NftTypeEnum.OWNED && !isFrame) {
            return list.filter(({ addressType }) => addressType !== 0)
        } else {
            return list
        }
    }, [isFrame, list, type])

    return (
        <>
            <Popup
                visible={visible}
                onMaskClick={() => {
                    setVisible(false)
                }}
                bodyStyle={{
                    height: '80%',
                    borderRadius: ' 20px 20px 0px 0px',
                    overflow: 'auto'
                    // padding: '35px 20px 50px 30px'
                }}
            >
                <AddressList>
                    {mapList &&
                        mapList.map(item => (
                            <AddressItem
                                onClick={() => {
                                    setIsClick && setIsClick(true)
                                    if (item.addressType === 0) {
                                        setWalletAddress({ ...item, name: 'My Account' })
                                    } else {
                                        setWalletAddress(item)
                                        setLastwalletAddressId(`${item.id}`)
                                    }
                                    setVisible(false)
                                }}
                                key={item.id}
                            >
                                <AddressImg src={addressImages[item.addressType]} alt="" />
                                <AddressItemContent
                                    className={walletAddress.id === item.id ? 'active name' : 'name'}
                                >
                                    {item.addressType === 0 ? 'My Account' : item.name}
                                    <AddressInfo>
                                        {item.addressType === 0 || item.addressType === 3
                                            ? item.email
                                            : stringOmitMiddle(11, 11, item.address)}
                                    </AddressInfo>
                                </AddressItemContent>
                                {walletAddress.id === item.id && (
                                    <OkImg width={16} height={16} src={okIcon} alt="" />
                                )}
                            </AddressItem>
                        ))}
                </AddressList>
            </Popup>
        </>
    )
}
const AddressInfo = styled.div`
    font-family: OpenSans-SemiBold;
    font-size: 16px;
    color: #999999;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 600;
`
const OkImg = styled.img`
    width: 26px;
    height: 26px;
    margin-right: 23px;
    margin-right: 21px;
`
const AddressItemContent = styled.div`
    flex: 1;
`
const AddressImg = styled.img`
    width: 38px;
    height: 38px;
    margin: 0 17px;
`
const AddressList = styled.div``
const AddressItem = styled.div`
    width: 100%;
    height: 87px;
    border-bottom: 1px solid rgba(229, 229, 229, 1);
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    .name {
        font-family: OpenSans-SemiBold;
        font-size: 18px;
        color: #000000;
        line-height: 22px;
        font-weight: 400;
    }
    .active {
        color: #000000;
    }
`
