import React, { FC, PropsWithChildren } from 'react'
import { Spin as SpinAntd } from 'antd'

interface SpinProps {
    spinning: boolean
    tip?: string
    size?: 'small' | 'default' | 'large'
    delay?: number
    className?: string
}

export const Spin: FC<PropsWithChildren<SpinProps>> = ({
    spinning,
    tip = 'Loading',
    size = 'large',
    delay = 100,
    className,
    children
}) => {
    return (
        <SpinAntd wrapperClassName={className} spinning={spinning} tip={tip} size={size} delay={delay}>
            {children}
        </SpinAntd>
    )
}
