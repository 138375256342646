import { createSelector } from 'reselect'

import { FEATURE_NAME } from '../constants'
import { GlobalState } from '../reducer'

const select = (state: GlobalState) => state[FEATURE_NAME]

export const getList = createSelector(select, state => state.storeList)

export const getCartNum = createSelector(select, state => state.cartNumber)

export const getMiniCartDrawerVisible = createSelector(select, state => state.miniCartDrawerVisible)
