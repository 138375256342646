import React, { FC } from 'react'
import styled from '@emotion/styled'

interface Props {
    name: string | React.ReactNode
    btnText?: string
    onClick?: () => void
}

export const Header: FC<Props> = ({ name, btnText, onClick }) => {
    return (
        <Wrapper>
            <div>{name}</div>
            {onClick && (
                <div className="btn" onClick={onClick}>
                    {btnText || 'Save'}
                </div>
            )}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    font-family: Roboto-Medium;
    font-size: 24px;
    color: #000000;
    letter-spacing: 0;
    line-height: 72px;
    border-bottom: 1px solid rgba(47, 46, 46, 1);
    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 36px;
        border: 1px solid rgba(0, 0, 0, 1);
        border-radius: 22px;
        font-size: 18px;
        cursor: pointer;
    }
`
