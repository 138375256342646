import React, { FC, useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'

import { useSignInRedirect } from 'shared/hooks'

import { PageContainer, Spin } from 'components'
import { Header } from '../../order/components/Header'
import { PersonalInfoForm } from './components/PersonalInfoForm'
import { CreateOrderBuy } from './components/CreateOrderBuy'

import type { EventPersonalInfo } from 'core/event/models'
import { getIsTourist } from 'shared/config'

enum EventOrderEnum {
    PERSONAL = 'personal',
    BILLING = 'billing'
}

export const CreateOrder: FC = () => {
    const [step, setStep] = useState<EventOrderEnum>(EventOrderEnum.PERSONAL)
    const [personalInfo, setPersonalInfo] = useState<EventPersonalInfo>()
    const { getToken } = useSignInRedirect()

    useEffect(() => {
        getToken() && !getIsTourist() && setStep(EventOrderEnum.BILLING)
    }, [getToken, setStep])

    const next = useCallback(
        (formData: EventPersonalInfo) => {
            setPersonalInfo(formData)
            setStep(EventOrderEnum.BILLING)
        },
        [setStep]
    )

    return (
        <Spin spinning={false}>
            <Container>
                <HeaderTitle>
                    <Header />
                </HeaderTitle>
                <div>
                    {step === EventOrderEnum.PERSONAL && <PersonalInfoForm step={step} next={next} />}
                    {step === EventOrderEnum.BILLING && (
                        <CreateOrderBuy step={step} personalInfo={personalInfo} />
                    )}
                </div>
            </Container>
        </Spin>
    )
}

const Container = styled(PageContainer)`
    width: 100%;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
        width: 100%;
        flex-wrap: wrap;
        padding: 0px;
    }
`

const HeaderTitle = styled.div`
    @media (max-width: 768px) {
        padding: 0 20px;
    }
`
