import { action, payload } from 'ts-action'
import { getActionType } from '../constants'
import { CartItemGroupByStore } from 'core/cart/models'

export const setDetail = action(
    getActionType('Set Checkout'),
    payload<{ detail: CartItemGroupByStore[] | null }>()
)

export const clearDetail = action(getActionType('Clear Checkout'))
