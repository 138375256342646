import React, { FC } from 'react'
import styled from '@emotion/styled'

import { Flex, Modal } from '@mantine/core'
import { DeleteButton } from './DeleteButton'

import closeImg from 'assets/closeImg.png'

interface Props {
    visible: boolean
    setVisible: (visible: boolean) => void
    onDelete: () => void
}

export const RemovePlaylistModal: FC<Props> = ({ visible, setVisible, onDelete }) => {
    return (
        <Modal
            trapFocus={false}
            zIndex={1200}
            title={
                <div className="flex justify-end">
                    <div>
                        <img
                            width={24}
                            height={24}
                            src={closeImg}
                            onClick={() => setVisible(false)}
                            alt="closeImg"
                        />
                    </div>
                </div>
            }
            centered
            opened={visible}
            onClose={() => setVisible(false)}
            styles={{
                title: {
                    width: '100%',
                    marginRight: 0
                },
                modal: {
                    borderRadius: '20px'
                },
                close: {
                    display: 'none'
                }
            }}
        >
            <Flex direction="column" justify="center" align="center" px={24} py={24}>
                <AddTitle>Deleting the playlist will remove it from your account.</AddTitle>
                <DeleteButton
                    style={{ margin: '36px auto 0' }}
                    onClick={() => {
                        onDelete()
                        setVisible(false)
                    }}
                >
                    Delete
                </DeleteButton>
            </Flex>
        </Modal>
    )
}

const AddTitle = styled.div`
    font-family: OpenSans-SemiBold;
    font-size: 16px;
    color: #838383;
    text-align: center;
    font-weight: 600;
`
