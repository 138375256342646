import React, { FC } from 'react'
import styled from '@emotion/styled'
import step1 from 'assets/step1.png'
import step2 from 'assets/step2.png'
import step3 from 'assets/step3.png'
import step4 from 'assets/step4.png'
import step5 from 'assets/step5.png'
import { PageContainer } from 'components'

export const DeleteSpecification: FC = () => {
    return (
        <Container>
            <h1>Account Deletion Guide</h1>
            <div>
                <div>When you want to delete your account, please follow these steps:</div>
                <div className="list">
                    <ul>
                        <li>
                            1. Go to the "Profile" section in your personal center and click on the avatar to
                            access the account page.
                        </li>
                        <li> 2. On the account page, look for and click on the "Delete Account" option.</li>
                        <li>
                            3. Once you are on the account deletion page, scroll down to find the "Delete my
                            account" button and click on it.
                        </li>
                        <li>
                            4. A confirmation pop-up will appear asking you to confirm the account deletion.
                            Click the "OK" button.
                        </li>
                        <li>
                            5. After successful deletion, the page will automatically redirect you to the
                            login page, indicating that your account has been successfully deleted.
                        </li>
                    </ul>
                </div>
                <div>
                    Please note that once your account is deleted, you will no longer be able to log in,
                    recover data, or reactivate the account. If you have any questions, please contact
                    customer support at contact@metasill.io.
                </div>
                <ImgList>
                    <img src={step1} alt="" />
                    <img src={step2} alt="" className="image" />
                    <img src={step3} alt="" />
                    <img src={step4} alt="" className="image" />
                    <img src={step5} alt="" />
                </ImgList>
            </div>
        </Container>
    )
}

const Container = styled(PageContainer)`
    width: 100%;
    padding: 50px 160px 100px 160px;
    padding-bottom: 10rem;
    font-family: Roboto-Regular;
    font-size: 16px;
    color: #000000;
    line-height: 24px;
    @media (max-width: 1250px) {
        padding: 50px 50px 50px;
    }
    @media (max-width: 576px) {
        padding: 50px 20px 50px;
        width: 100%;
    }

    h1 {
        font-family: Roboto-Bold;
        font-size: 36px;
        color: #000000;
        line-height: 38px;
    }
    h5 {
        margin: 30px 0;
        font-family: Roboto-Bold;
        font-size: 18px;
        color: #000000;
        line-height: 24px;
    }
    .list {
        padding-left: 35px;
    }
    li {
        list-style-type: disc;
    }
    .time {
        margin-top: 30px;
    }
`

const ImgList = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0 10px;
    img {
        width: 176px;
        height: 362px;
        box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.18);
        margin-right: 10px;
        margin-top: 20px;
    }
    @media (max-width: 850px) {
        img {
            width: 160px;
            height: 328px;
            box-shadow: 0px 7.25px 16.31px rgba(0, 0, 0, 0.18);
        }
    }
    @media (max-width: 780px) {
        justify-content: flex-start;
    }

    @media (max-width: 610px) {
        justify-content: space-between;
        img {
            margin-right: 40px;
        }
        .image {
            margin-right: 0 !important;
        }
    }
    @media (max-width: 400px) {
        img {
            margin-right: 20px;
        }
    }
    @media (max-width: 380px) {
        img {
            margin-right: 10px;
        }
    }
    @media (max-width: 370px) {
        img {
            width: 152px;
            height: 313px;
        }
    }
`
