import { OrderPrice } from 'core/order/models'
import { fixAbbr, convertToDollar } from 'shared/utils'

export interface OrderPriceByCartDTO {
    subTotal: string
    tax: string
    delivery: string
    discount: string
    total: string
    depositTotal: string
}

export interface OrderPriceByOrderDTO {
    subTotal: string
    tax: string
    delivery: string
    discount: string
    total: string
    depositTotalPrice: string
}

export const convertToOrderPriceByCart = (dto: OrderPriceByCartDTO): OrderPrice => {
    const { subTotal, tax, delivery, discount, total, depositTotal } = dto

    return {
        subTotal: convertToDollar(fixAbbr(subTotal)),
        tax: convertToDollar(fixAbbr(tax)),
        delivery: convertToDollar(fixAbbr(delivery)),
        discount: convertToDollar(fixAbbr(discount)),
        total: convertToDollar(fixAbbr(total)),
        depositTotal: depositTotal ? convertToDollar(fixAbbr(depositTotal)) : '0.00'
    }
}

export const convertToOrderPriceByOrder = (dto: OrderPriceByOrderDTO): OrderPrice => {
    const { subTotal, tax, delivery, discount, total, depositTotalPrice } = dto

    return {
        subTotal: convertToDollar(fixAbbr(subTotal)),
        tax: convertToDollar(fixAbbr(tax)),
        delivery: convertToDollar(fixAbbr(delivery)),
        discount: convertToDollar(fixAbbr(discount)),
        total: convertToDollar(fixAbbr(total)),
        depositTotal: depositTotalPrice ? convertToDollar(fixAbbr(depositTotalPrice)) : '0.00'
    }
}
