import React, { FC, useEffect, useLayoutEffect, useState } from 'react'
import styled from '@emotion/styled'

import { useApiError, useRememberManagementSelected, useResponsive } from 'shared/hooks'
import { useOwnedNew } from 'core/management/nft/api/useOwnedNew'
import { removeAddressToken } from 'shared/config'
import { useAuthorized } from 'core/management/nft/api/useAuthorized'
import { AuthAddressItem, NftTypeEnum } from 'core/management/nft/models'
import { useAuthAddressList } from 'core/management/nft/api/useAddressList'

import { Spin } from 'components'
import { CollectionListNew } from './CollectionListNew'
import { UploadNoContent } from './StyledComponents'
import { SelectAdressPopup } from '../../frame/components/SelectAdressPopup'
import { SelectAddressModal } from 'modules/management/frame/components/SelectAddressModal'

import no_content1 from '../assets/no_content1.png'
import no_content3 from '../assets/no_content3.png'
import pullDown from 'assets/pull_down.png'
import { useAddressToken } from 'core/management/nft/api/useAddressToken'

const ownedPageKey = 'owned_wallet_address'
const uploadPageKey = 'upload_wallet_address'
const authorizedPageKey = 'authorized_wallet_address'

export const Authorized: FC = () => {
    const { md } = useResponsive()
    const {
        methods: { handleError }
    } = useApiError('Authorized')

    const [visible, setVisible] = useState(false)

    const [walletAddress, setWalletAddress] = useState<AuthAddressItem>()

    const [isClick, setIsClick] = useState(false)

    const {
        list: authAddressList,
        methods: { getAddressList }
    } = useAuthAddressList()

    useLayoutEffect(() => {
        getAddressList().catch(handleError)
    }, [getAddressList, handleError])

    useRememberManagementSelected(uploadPageKey, walletAddress?.id, isClick)
    useRememberManagementSelected(
        ownedPageKey,
        walletAddress?.id,
        walletAddress?.addressType === 0 || walletAddress?.addressType === 3 ? false : isClick
    )
    const selectedId = useRememberManagementSelected(authorizedPageKey, walletAddress?.id, isClick)
    useEffect(() => {
        !!authAddressList.length &&
            setWalletAddress(() => {
                const lastAddress = authAddressList.find(({ id }) => selectedId === `${id}`)
                if (lastAddress && lastAddress.addressType !== 0) return lastAddress

                return { ...authAddressList[0], name: 'My Account' }
            })
    }, [authAddressList, selectedId])

    const {
        loading: ownedLoading,
        hasMoreLoading: ownedHasMoreLoading,
        ownedList,
        hasMore,
        total,
        methods: { firstFetch, loadMore }
    } = useOwnedNew(walletAddress?.address ? walletAddress?.address : '', 'authorized')

    const {
        hasMore: authorizedHasMore,
        authorizedList,
        authorizedTotal,
        methods: { authorizedFetch, authorizedLoadMore }
    } = useAuthorized(NftTypeEnum.AUTHORIZED)

    const {
        methods: { getAddressToken }
    } = useAddressToken()

    useEffect(() => {
        if (walletAddress?.id) {
            getAddressToken({
                id: `${walletAddress.id}`,
                addressType: `${walletAddress.addressType}`,
                ownerId: `${walletAddress.ownerId}`
            })
                .then(() => {
                    if (walletAddress.name !== 'My Account') {
                        firstFetch()
                            .then(() => {
                                removeAddressToken()
                            })
                            .catch(handleError)
                    } else {
                        authorizedFetch()
                            .then(() => {
                                removeAddressToken()
                            })
                            .catch(handleError)
                    }
                })
                .catch(handleError)
        }
    }, [authorizedFetch, firstFetch, getAddressToken, handleError, walletAddress])

    return (
        <Spin spinning={ownedLoading && !ownedHasMoreLoading}>
            {md && (
                <WebSelectAddress>
                    <SelectAdress>
                        <span className="box" onClick={() => setVisible(true)}>
                            <span className="name">{walletAddress?.name}</span>
                            <img width={16} height={16} src={pullDown} alt="NFT" />
                        </span>
                    </SelectAdress>
                </WebSelectAddress>
            )}
            {!md && (
                <SelectAdress>
                    <span className="box" onClick={() => setVisible(true)}>
                        <span className="name">{walletAddress?.name}</span>
                        <img width={16} height={16} src={pullDown} alt="NFT" />
                    </span>
                </SelectAdress>
            )}

            <TotalLength>
                <div className="name">Total</div>
                <span> {walletAddress?.name === 'My Account' ? authorizedTotal : total}</span>
            </TotalLength>
            {authorizedList.length > 0 || ownedList.length > 0 ? (
                <>
                    <CollectionListNew
                        loadMore={walletAddress?.name === 'My Account' ? authorizedLoadMore : loadMore}
                        hasMore={walletAddress?.name === 'My Account' ? authorizedHasMore : hasMore}
                        list={walletAddress?.name === 'My Account' ? authorizedList : ownedList}
                    />
                </>
            ) : (
                <UploadNoContent>
                    <img src={md ? no_content1 : no_content3} alt="no_content" />
                </UploadNoContent>
            )}
            {walletAddress && md && (
                <SelectAddressModal
                    setWalletAddress={setWalletAddress}
                    walletAddress={walletAddress!}
                    list={authAddressList}
                    type={NftTypeEnum.UPLOAD}
                    visible={visible}
                    setVisible={setVisible}
                    setIsClick={setIsClick}
                />
            )}
            {walletAddress && !md && (
                <SelectAdressPopup
                    setWalletAddress={setWalletAddress}
                    walletAddress={walletAddress!}
                    list={authAddressList}
                    type={NftTypeEnum.UPLOAD}
                    visible={visible}
                    setVisible={setVisible}
                    setIsClick={setIsClick}
                />
            )}
        </Spin>
    )
}

const WebSelectAddress = styled.div`
    position: absolute;
    right: 0;
    top: -74px;
`

const SelectAdress = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    /* margin-top: 24px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(151, 151, 151, 1); */
    .box {
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f9f9f9;
        border: 1px solid rgba(211, 211, 211, 1);
        border-radius: 8px;
        padding: 6px 12px;
        @media (max-width: 768px) {
            height: 36px;
        }
    }

    .name {
        font-family: OpenSans-Bold;
        font-size: 18px;
        color: #000000;
        font-weight: 700;
        margin-right: 6px;
    }
`

const TotalLength = styled.div`
    display: flex;
    font-family: OpenSans-Regular;
    font-size: 16px;
    align-items: center;
    color: #acacac;
    margin-right: 6px;
    margin-top: 20px;
    .name {
        font-family: OpenSans-Bold;
        font-size: 18px;
        color: #000000;
        font-weight: 700;
        margin-right: 10px;
    }
`
