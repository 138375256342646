import React, { FC, useEffect, useState } from 'react'
import styled from '@emotion/styled'

import { BaseDelivery, StateList, DeliveryTypeEnum, Delivery } from 'core/delivery/models'

import { Form, Input, Select } from 'antd'
import { Button } from 'components'
import { useDeliveryCreate } from 'core/delivery/api'
import { useApiError, useMemoizedFn } from 'shared/hooks'
import { getIsTourist } from 'shared/config'

const initialValues: BaseDelivery = {
    firstName: '',
    lastName: '',
    companyName: '',
    streetAddress: '',
    apartment: '',
    city: '',
    state: '',
    zipCode: '',
    phone: '',
    isDefault: false
}

export interface BillingProps {
    cardPay: boolean
    btnTitle: string
    disabled?: boolean
    onSubmit?: (id?: string, value?: Delivery) => void
    onSave?: (value: Delivery) => void
    addressTitle?: string
    page?: boolean
}

export const BillingAddress: FC<BillingProps> = ({
    cardPay,
    btnTitle = 'OK',
    disabled,
    onSubmit,
    onSave,
    addressTitle,
    page
}) => {
    const [btnDisabled, setBtnDisabled] = useState<boolean>(false)
    const [form] = Form.useForm<BaseDelivery>()
    const {
        methods: { handleError }
    } = useApiError('AddressList')

    const {
        methods: { create }
    } = useDeliveryCreate(DeliveryTypeEnum.BILLING)

    const handleSubmit = useMemoizedFn(() => {
        form.validateFields().then(values => {
            if (!cardPay || getIsTourist()) {
                onSubmit?.('', { ...values, id: '' })
            } else {
                create(values)
                    .then(id => {
                        if (onSave) onSave({ ...values, id })
                        if (onSubmit) onSubmit(id)
                    })
                    .catch(handleError)
                setBtnDisabled(false)
            }
        })
    })
    useEffect(() => {
        form.setFieldsValue({ state: undefined })
    }, [form])

    const handleValuesChange = (_: Partial<BaseDelivery>, allValues: BaseDelivery) => {
        const { firstName, lastName, streetAddress, city, state, zipCode } = allValues
        if (firstName && lastName && streetAddress && city && state && zipCode) return setBtnDisabled(true)
        return setBtnDisabled(false)
    }

    return (
        <BillingAddressDiv>
            {addressTitle && <AddressTitle>{addressTitle}</AddressTitle>}
            <Form
                form={form}
                onValuesChange={handleValuesChange}
                initialValues={{ ...initialValues }}
                autoComplete="off"
                className="form"
            >
                <Form.Item
                    name="firstName"
                    rules={[{ required: true, message: '' }]}
                    className="formItemleft"
                >
                    <Inputs placeholder="First Name *" className="inputName" />
                </Form.Item>
                <Form.Item name="lastName" rules={[{ required: true, message: '' }]} className="formItem">
                    <Inputs placeholder="Last Name *" className="inputName" />
                </Form.Item>
                <Form.Item
                    name="streetAddress"
                    rules={[{ required: true, message: '' }]}
                    className="formItems"
                >
                    <Inputs placeholder="Street Address *" className="inputName" />
                </Form.Item>
                <Form.Item name="apartment" className="formItems">
                    <Inputs placeholder="Apt, suite, unit, etc." className="inputName" />
                </Form.Item>
                <Form.Item name="city" rules={[{ required: true, message: '' }]} className="formItemleft">
                    <Inputs placeholder="Town / City *" className="inputName" />
                </Form.Item>
                <Form.Item name="state" rules={[{ required: true, message: '' }]} className="formItem">
                    <StateSelect
                        placeholder="State *"
                        className="selectName"
                        options={StateList.map(value => ({ label: value, value }))}
                    />
                </Form.Item>
                <Form.Item name="zipCode" rules={[{ required: true, message: '' }]} className="formItem">
                    <Inputs placeholder="ZIP code *" className="inputName" />
                </Form.Item>
                <Form.Item className="formItemBtn">
                    <SubButton
                        style={{ fontSize: '25.33px' }}
                        onClick={handleSubmit}
                        disabled={page ? (disabled && btnDisabled ? false : true) : !btnDisabled}
                    >
                        {btnTitle}
                    </SubButton>
                </Form.Item>
            </Form>
        </BillingAddressDiv>
    )
}
const AddressTitle = styled.div`
    width: 100%;
    text-align: center;
    font-family: Roboto-Medium;
    font-size: 30px;
    color: #000000;
    text-align: center;
    line-height: 28px;
    margin-bottom: 30px;
`
const BillingAddressDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    .form {
        display: flex;
        flex-wrap: wrap;
        .ant-form-item {
            margin-bottom: 30px !important;
            @media (max-width: 768px) {
                margin-bottom: 20px !important;
            }
        }
        .ant-form-item-explain,
        .ant-form-item-explain-connected {
            min-height: 0px !important;
        }
        .inputName {
            font-family: Roboto-Regular;
            font-size: 16px;
            color: #000000;
            letter-spacing: 0;
            line-height: 16px;
            padding: 17px;
            @media (max-width: 768px) {
                margin: 0;
            }
        }
        .selectName {
            font-family: Roboto-Regular;
            font-size: 16px;
            color: #000000;
            letter-spacing: 0;
            line-height: 16px;
            .ant-select-selector {
                padding-left: 17px !important;
            }
            @media (max-width: 768px) {
                margin: 0;
            }
        }
        width: 580px;
        @media (max-width: 768px) {
            width: 100%;
        }
    }
    .formItem {
        width: 275px;
        @media (max-width: 768px) {
            width: 100%;
        }
        .ant-select-arrow {
            color: #000000 !important;
        }
    }
    .formItems {
        width: 100%;
        @media (max-width: 768px) {
            width: 100%;
            > div {
                padding: 0;
            }
        }
    }
    .formItemBtn {
        width: 100%;
        @media (max-width: 768px) {
            width: 100%;
            padding: 0;
        }
    }
    .formItemleft {
        width: 275px;
        margin-right: 28px;
        @media (max-width: 768px) {
            width: 100%;
            margin-right: 0;
            /* margin-bottom: 20px; */
        }
    }
    .btndisabled {
        opacity: 0.5;
    }
`

const SubButton = styled(Button)`
    width: 100%;
    height: 50px;
    border-radius: 36px;
    font-family: Roboto-Medium;
    color: #fff;
    letter-spacing: 0;
    text-align: center;
    line-height: 30px;
    margin-top: 26px;
`

const Inputs = styled(Input)`
    height: 50px;
    border-radius: 10px;
    .select-before {
        height: 50px;
        .ant-select-selection-search {
            height: 50px !important;
        }
    }
    .ant-input-group-addon {
        height: 50px !important;
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
        .ant-select-selector {
            height: 50px !important;
            border-top-left-radius: 10px !important;
            border-bottom-left-radius: 10px !important;
            .ant-select-selection-item {
                height: 50px !important;
                line-height: 50px !important;
            }
        }
    }
    .ant-input {
        height: 50px !important;
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }
`
const StateSelect = styled(Select)`
    .ant-select-selector {
        height: 50px !important;
        line-height: 50px !important;
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
        .ant-select-selection-item {
            height: 50px !important;
            line-height: 50px !important;
        }
        .ant-select-selection-placeholder {
            line-height: 50px !important;
        }
    }
`
