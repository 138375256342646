import { useLocation } from 'react-router-dom'
const _toString = (arg: any) => Object.prototype.toString.call(arg)
const isObject = (obj: any) => _toString(obj) === '[object Object]'
const isNil = (arg: any) => arg === undefined || arg === null

const queryStringParse = (str: string, decode: boolean = true): { [key: string]: string } => {
    return str
        .replace(/^\?/, '')
        .split('&')
        .reduce((acc, section) => {
            let [key, value] = section.split('=')
            if (isNil(value)) {
                return acc
            }

            if (decode) {
                value = decodeURIComponent(value)
            }
            return { ...acc, [key]: value }
        }, {})
}

const queryStringStringify = (query: { [key: string]: any }, encode: boolean = true) => {
    if (!isObject(query)) {
        throw new Error('query string . stringify need an object type argument')
    } else {
        return Object.keys(query)
            .filter(key => !isNil(query[key]) && `${query[key]}` !== '')
            .map(key => {
                let value = query[key]
                if (encode) {
                    value = encodeURIComponent(value)
                }
                return `${key}=${value}`
            })
            .join('&')
    }
}

export const urlQueryString = {
    parse: queryStringParse,
    stringify: queryStringStringify
}

export const useLocationSearch = () => {
    const { search } = useLocation()
    return urlQueryString.parse(search)
}
