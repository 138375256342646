import React, { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from '@emotion/styled'

import { Collections } from './nft/components/Collections'
import { Frames } from './frame/List'
import { getIsFrameSign, removeIsFrameSign } from 'shared/config'

enum HeaderItemEnum {
    COLLECTIONS = 'collections',
    FRAMES = 'frames'
}

export const Management: FC = () => {
    const navigate = useNavigate()
    const { type } = useParams<{ type: HeaderItemEnum }>()
    useEffect(() => {
        if (getIsFrameSign()) {
            navigate(`/management/${HeaderItemEnum.FRAMES}`)
            removeIsFrameSign()
        }
    }, [navigate])

    return (
        <ManagementContent>
            <Header>
                <HeaderItem
                    className={type === HeaderItemEnum.COLLECTIONS ? 'active' : ''}
                    onClick={() =>
                        type !== HeaderItemEnum.COLLECTIONS &&
                        navigate(`/management/${HeaderItemEnum.COLLECTIONS}`)
                    }
                >
                    <span>Collection</span>
                </HeaderItem>
                <HeaderItem
                    className={type === HeaderItemEnum.FRAMES ? 'active' : ''}
                    onClick={() =>
                        type !== HeaderItemEnum.FRAMES && navigate(`/management/${HeaderItemEnum.FRAMES}`)
                    }
                >
                    <span>Frame</span>
                </HeaderItem>
            </Header>
            {type === HeaderItemEnum.COLLECTIONS ? <Collections /> : <Frames />}
        </ManagementContent>
    )
}

const ManagementContent = styled.div`
    @media (max-width: 768px) {
        padding: 0 20px;
    }
`

const Header = styled.div`
    width: 100%;
    height: 120px;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0;
    .active {
        border-bottom: 3px solid #000;
        span {
            color: #000;
        }
    }
    @media (max-width: 768px) {
        height: 62px;
        margin-bottom: 0;
    }
`

const HeaderItem = styled.div`
    width: auto;
    height: 100%;
    padding: 60px 0 28px;
    margin-right: 66px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    span {
        font-family: Roboto-Medium;
        font-size: 30px;
        color: #999999;
        letter-spacing: 0;
        line-height: 28px;
        font-weight: 500;
        @media (max-width: 768px) {
            font-size: 20px;
        }
    }
    @media (max-width: 768px) {
        font-family: Roboto-Medium;
        font-size: 20px;
        color: #000000;
        letter-spacing: 0;
        font-weight: 500;
        padding: 0;
        margin-right: 30px;
    }
`
