import React, { ReactNode, useState } from 'react'

import { LoginModalContext } from 'shared/context'
import { useMemoizedFn } from 'shared/hooks'

export const LoginModalProvider = ({ children }: { children: ReactNode }) => {
    const [visible, setVisibie] = useState(false)

    const show = useMemoizedFn(() => setVisibie(true))
    const hide = useMemoizedFn(() => setVisibie(false))

    return <LoginModalContext.Provider value={{ visible, methods: { show, hide } }} children={children} />
}
