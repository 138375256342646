import React, { FC, useCallback, useRef, useEffect } from 'react'
import styled from '@emotion/styled'
import { useNavigate, useParams } from 'react-router-dom'

import { useI18n } from 'core/language/api'
import { PageContainer } from 'components'
import { useApiError, useShowMessage } from 'shared/hooks'

import { Spin } from 'antd'
import { Form, FormRefProps } from './components/form'
import { FormHeader } from './components/formHeader'
import { useEventEdit } from '../../core/event/api/lib/useEventEdit'
import { useEventDetail } from '../../core/event/api'

export const EventEdit: FC = () => {
    const { lang } = useI18n()
    const navigate = useNavigate()
    const { id } = useParams()
    const {
        loading,
        detail,
        methods: { getDetail }
    } = useEventDetail(lang)
    const {
        loading: editLoading,
        methods: { setEdit }
    } = useEventEdit()
    const {
        methods: { success }
    } = useShowMessage()
    const {
        methods: { handleError }
    } = useApiError('FeatureEventCreate')

    useEffect(() => {
        if (id) {
            getDetail(id).catch(handleError)
        } else {
            navigate(-1)
        }
    }, [id, getDetail, handleError, navigate])

    const ref = useRef<FormRefProps>({} as FormRefProps)
    const handleSave = useCallback(() => {
        ref.current.submit(data => {
            if (!id) return
            setEdit(id, data)
                .then(_ => {
                    ref.current.reset()
                    success('Success')
                    setTimeout(() => navigate(-1), 1500)
                })
                .catch(handleError)
        })
    }, [id, success, setEdit, navigate, handleError])

    useEffect(() => {
        detail &&
            ref.current.reset({
                title: detail.title,
                timeZone: detail.timeZone,
                address: detail.address,
                content: detail.content,
                beginTime: detail.beginTime,
                endTime: detail.endTime,
                productList: detail.productRefList.map(item => {
                    return { ...item, descriptionList: item.description }
                }),
                words: detail.words,
                buttonName: detail.buttonName,
                productButtonName: detail.productButtonName,
                titleSize: detail.titleSize,
                images: detail.images,
                isOff: detail.isOff
            })
    }, [detail])

    return (
        <Spin spinning={loading || editLoading}>
            <Container>
                <FormHeader title="Edit" onSave={handleSave} />
                <Form ref={ref} />
            </Container>
        </Spin>
    )
}

const Container = styled(PageContainer)`
    width: 100%;
    padding: 0 10rem 10rem 10rem;
    @media (max-width: 576px) {
        padding: 0 0 70px;
        width: 100%;
    }
`
