import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { actions as checkoutActions, selectors as checkoutSelectors } from 'core/checkout/state'
import { CartItemGroupByStore } from 'core/cart/models'
import { useMountedPromiseFn } from 'shared/hooks'
import { services } from 'core/checkout/data-access'
import { CheckoutRequestItem } from 'core/checkout/models'
import { personalInfoItem } from 'core/order/models'

export const useCheckout = () => {
    const dispatch = useDispatch()
    const detail = useSelector(checkoutSelectors.getDetail)

    const [loading, setLoading] = useState<boolean>(false)

    const setItems = useCallback(
        (detail: CartItemGroupByStore[] | null) => {
            dispatch(checkoutActions.setDetail({ detail }))
        },
        [dispatch]
    )

    const remove = useCallback(() => {
        dispatch(checkoutActions.clearDetail)
    }, [dispatch])

    const calculateApi = useMountedPromiseFn(services.calculate)
    const calculate = useCallback(
        (
            deliveryId: string,
            items: CheckoutRequestItem[],
            couponCode?: string,
            personalInfo?: personalInfoItem
        ) => {
            setLoading(true)
            return calculateApi(deliveryId, items, couponCode, personalInfo)
                .then(res => {
                    setLoading(false)
                    return res
                })
                .catch(err => {
                    setLoading(false)
                    return Promise.reject(err)
                })
        },
        [calculateApi]
    )

    return {
        loading,
        detail,
        methods: {
            setItems,
            remove,
            calculate
        }
    }
}
