import React, { FC } from 'react'
import styles from './index.module.css'

import { useI18n } from 'core/language/api'

import { OrderPrice } from 'core/order/models'

export const OrderAmount: FC<OrderPrice> = ({
    subTotal,
    tax = '',
    delivery = '',
    discount = '',
    total,
    depositTotal
}) => {
    const {
        methods: { t }
    } = useI18n()

    return (
        <div className={styles.moneyInfo}>
            <div className={styles.productMoney}>
                <div className={styles.productMoneyLabel}>{t('client.order.amount.product')}</div>
                <div className={styles.productMoneyValue}>${subTotal}</div>
            </div>
            {tax && (
                <div className={styles.taxInfo}>
                    <div className={styles.taxLabel}>{t('client.order.amount.tax')}</div>
                    <div className={styles.taxValue}>${tax}</div>
                </div>
            )}
            {delivery && (
                <div className={styles.taxInfo}>
                    <div className={styles.taxLabel}>{t('client.order.amount.freight')}</div>
                    <div className={styles.taxValue}>${delivery}</div>
                </div>
            )}
            {depositTotal && depositTotal !== '0.00' && (
                <div className={styles.taxInfo}>
                    <div className={styles.taxLabel}>{t('client.order.amount.depositTotal')}</div>
                    <div className={styles.taxValue}>${depositTotal}</div>
                </div>
            )}
            {discount !== '0.00' && (
                <div className={styles.taxInfo}>
                    <div className={styles.taxLabel}>{t('client.order.amount.discount')}</div>
                    <div className={styles.discountValue}>-${discount}</div>
                </div>
            )}
            <div className={styles.totalPriceInfo}>
                <div className={styles.totalPriceLabel}>{t('client.order.amount.total')}</div>
                <div className={styles.totalPriceValue}>
                    <span className={styles.moneyUnit}>$</span>
                    {total}
                </div>
            </div>
        </div>
    )
}
