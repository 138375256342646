import React, { FC, useEffect, useLayoutEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'

import { Text, TextProps } from '@mantine/core'
import { Media, Spin } from 'components'
import { AuthAddressItem, NftStatusEnum, NftTypeEnum } from 'core/management/nft/models'
import { UploadAddress } from './UploadAddress'

import no_content from '../assets/no_content.png'
import no_content2 from '../assets/no_content2.png'
import add from '../../frame/assets/add.png'
import { UploadNoContent } from './StyledComponents'
import { useNftList } from 'core/management/nft/api/useNftList'
import { useOwnedNew } from 'core/management/nft/api/useOwnedNew'
import { useApiError, useRememberManagementSelected, useResponsive } from 'shared/hooks'
import { StateType } from './Collections'
import { removeAddressToken } from 'shared/config'
import { InfiniteScroll } from './InfiniteScroll'
import { SelectAdressPopup } from '../../frame/components/SelectAdressPopup'

import pullDown from 'assets/pull_down.png'
import { SelectAddressModal } from 'modules/management/frame/components/SelectAddressModal'
import { useAuthAddressList } from 'core/management/nft/api/useAddressList'
import { useAddressToken } from 'core/management/nft/api/useAddressToken'

const ownedPageKey = 'owned_wallet_address'
const uploadPageKey = 'upload_wallet_address'
const authorizedPageKey = 'authorized_wallet_address'

export const Upload: FC = () => {
    const navigate = useNavigate()
    const { md } = useResponsive()
    const {
        methods: { handleError }
    } = useApiError('Upload')
    const { state } = useLocation()
    const collectionType = (state as StateType)?.collectionType || 'owned'

    const [walletAddress, setWalletAddress] = useState<AuthAddressItem>()

    const [visible, setVisible] = useState(false)

    const [hoverId, setHoverId] = useState<string>()

    const [isClick, setIsClick] = useState(false)

    const {
        loading,
        list: authAddressList,
        methods: { getAddressList }
    } = useAuthAddressList()

    const {
        loading: uploadLoading,
        list,
        uploadHasMore,
        uploadTotal,
        methods: { uploadFetch, uploadLoadMore }
    } = useNftList()

    useLayoutEffect(() => {
        getAddressList().catch(handleError)
    }, [getAddressList, handleError])

    useRememberManagementSelected(
        ownedPageKey,
        walletAddress?.id,
        walletAddress?.addressType === 0 || walletAddress?.addressType === 3 ? false : isClick
    )
    useRememberManagementSelected(authorizedPageKey, walletAddress?.id, isClick)
    const selectedId = useRememberManagementSelected(uploadPageKey, walletAddress?.id, isClick)
    useEffect(() => {
        !!authAddressList.length &&
            setWalletAddress(() => {
                const lastAddress = authAddressList.find(({ id }) => selectedId === `${id}`)
                if (lastAddress && lastAddress.addressType !== 0) return lastAddress

                return { ...authAddressList[0], name: 'My Account' }
            })
    }, [authAddressList, selectedId])

    const {
        loading: ownedLoading,
        hasMoreLoading: ownedHasMoreLoading,
        ownedList,
        hasMore,
        total,
        methods: { firstFetch, loadMore }
    } = useOwnedNew(walletAddress?.address ? walletAddress?.address : '', 'uploaded')
    const {
        methods: { getAddressToken }
    } = useAddressToken()

    useEffect(() => {
        if (!walletAddress?.id || walletAddress.name === 'My Account') return
        getAddressToken({
            id: `${walletAddress.id}`,
            addressType: `${walletAddress.addressType}`,
            ownerId: `${walletAddress.ownerId}`
        })
            .then(() => {
                firstFetch()
                    .then(() => {
                        removeAddressToken()
                    })
                    .catch(handleError)
            })
            .catch(handleError)
    }, [firstFetch, getAddressToken, handleError, walletAddress])

    useEffect(() => {
        if (walletAddress?.name === 'My Account') {
            getAddressToken({
                id: `${walletAddress.id}`,
                addressType: `${walletAddress.addressType}`,
                ownerId: `${walletAddress.ownerId}`
            })
                .then(() => {
                    uploadFetch()
                        .then(() => {
                            removeAddressToken()
                        })
                        .catch(handleError)
                })
                .catch(handleError)
        }
    }, [getAddressToken, handleError, uploadFetch, walletAddress])

    return (
        <>
            <Spin spinning={(ownedLoading && !ownedHasMoreLoading) || uploadLoading || loading}>
                {md && (
                    <WebSelectAddress>
                        <SelectAdress>
                            <span className="box" onClick={() => setVisible(true)}>
                                <span className="name">{walletAddress?.name}</span>
                                <img width={16} height={16} src={pullDown} alt="NFT" />
                            </span>
                        </SelectAdress>
                    </WebSelectAddress>
                )}
                {!md && (
                    <SelectAdress>
                        <span className="box" onClick={() => setVisible(true)}>
                            <span className="name">{walletAddress?.name}</span>
                            <img width={16} height={16} src={pullDown} alt="NFT" />
                        </span>
                    </SelectAdress>
                )}

                <TotalLength>
                    <div className="total">
                        <div className="name">Total</div>
                        <span> {walletAddress?.name === 'My Account' ? uploadTotal : total}</span>
                    </div>
                    {collectionType === 'upload' && (
                        <div className="float-right flex items-center">
                            <AddImg src={add} alt="add" onClick={() => navigate('/management/nft/upload')} />
                        </div>
                    )}
                </TotalLength>

                {walletAddress?.name === 'My Account' ? (
                    list.length > 0 ? (
                        <>
                            <ListWrapper>
                                {list.map(({ id, name, status, thumbUrl }, index) => (
                                    <ListWrapperItem key={index}>
                                        <div className="list-item">
                                            <ListItemImg onClick={() => navigate(`/management/nft/${id}`)}>
                                                <div
                                                    className="relative"
                                                    style={{
                                                        display:
                                                            status === NftStatusEnum.Approved
                                                                ? 'none'
                                                                : 'block'
                                                    }}
                                                >
                                                    <Mask />
                                                    <StatusInfo>
                                                        {status === NftStatusEnum.Rejected
                                                            ? 'Rejected'
                                                            : 'Pending Review'}
                                                    </StatusInfo>
                                                </div>
                                                <Media size={md ? 198 : 158} src={thumbUrl} />
                                            </ListItemImg>
                                            {md ? (
                                                <Info
                                                    onMouseEnter={() => setHoverId(id)}
                                                    onMouseLeave={() => setHoverId('')}
                                                >
                                                    <InfoName>
                                                        <Name lineClamp={2}>{name}</Name>
                                                    </InfoName>
                                                    <EditButton
                                                        show={hoverId === id}
                                                        canEdit={status !== NftStatusEnum.Reviewing}
                                                        disabled={status === NftStatusEnum.Reviewing}
                                                        onClick={e => {
                                                            e.stopPropagation()
                                                            navigate(`/management/nft/${id}/edit`)
                                                        }}
                                                    >
                                                        Edit
                                                    </EditButton>
                                                </Info>
                                            ) : (
                                                <Info
                                                    onClick={() => {
                                                        if (hoverId) {
                                                            return setHoverId('')
                                                        }
                                                        setHoverId(id)
                                                    }}
                                                    onMouseLeave={() => setHoverId('')}
                                                >
                                                    <InfoName>
                                                        <Name lineClamp={2}>{name}</Name>
                                                    </InfoName>
                                                    <EditButton
                                                        show={hoverId === id}
                                                        canEdit={status !== NftStatusEnum.Reviewing}
                                                        disabled={status === NftStatusEnum.Reviewing}
                                                        onClick={e => {
                                                            e.stopPropagation()
                                                            navigate(`/management/nft/${id}/edit`)
                                                        }}
                                                    >
                                                        Edit
                                                    </EditButton>
                                                </Info>
                                            )}
                                        </div>
                                    </ListWrapperItem>
                                ))}
                            </ListWrapper>
                            <InfiniteScroll loadMore={uploadLoadMore} hasMore={uploadHasMore} />
                        </>
                    ) : (
                        <UploadNoContent>
                            <img src={md ? no_content : no_content2} alt="no_content" />
                        </UploadNoContent>
                    )
                ) : (
                    <>
                        {ownedList.length > 0 && (
                            <UploadAddress loadMore={loadMore} hasMore={hasMore} list={ownedList} />
                        )}
                    </>
                )}
            </Spin>
            {walletAddress && md && (
                <SelectAddressModal
                    setWalletAddress={setWalletAddress}
                    walletAddress={walletAddress}
                    list={authAddressList}
                    type={NftTypeEnum.UPLOAD}
                    visible={visible}
                    setVisible={setVisible}
                    setIsClick={setIsClick}
                />
            )}
            {walletAddress && !md && (
                <SelectAdressPopup
                    setWalletAddress={setWalletAddress}
                    walletAddress={walletAddress}
                    list={authAddressList}
                    type={NftTypeEnum.UPLOAD}
                    visible={visible}
                    setVisible={setVisible}
                    setIsClick={setIsClick}
                />
            )}
        </>
    )
}

const WebSelectAddress = styled.div`
    position: absolute;
    right: 0;
    top: -74px;
`

const AddImg = styled.img`
    width: 24px;
    height: 24px;
    cursor: pointer;
`

const ListItemImg = styled.div`
    width: 100%;
    border: 1px solid #cccccc;
`

const StatusInfo = styled.div`
    position: absolute;
    top: 82px;
    left: 50%;
    transform: translateX(-50%);
    width: 145px;
    font-family: Roboto-Bold;
    font-size: 18px;
    color: #ffffff;
    text-align: center;
    line-height: 38px;
    border: 1px solid rgba(255, 255, 255, 1);
    border-radius: 10px;
    z-index: 2;
    @media (max-width: 768px) {
        width: 116px;
        height: 26px;
        line-height: 26px;
        font-size: 14px;
        top: 68px;
    }
`

const Mask = styled.div`
    position: absolute;
    width: 200px;
    height: 200px;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    left: -1px;
    top: -1px;
    @media (max-width: 768px) {
        width: 160px;
        height: 160px;
    }
`

const EditButton = styled.button<{ show?: boolean; canEdit: boolean }>`
    display: ${({ show }) => (show ? 'block' : 'none')};
    width: 90px;
    margin: 8px auto 0;
    background: #000000;
    opacity: ${({ canEdit }) => (canEdit ? 1 : 0.5)};
    border-radius: 13px;
    font-family: Roboto-Medium;
    font-size: 13px;
    line-height: 25px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 500;
    cursor: pointer;
`

const InfoName = styled.div`
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
        height: 32px;
    }
`

const Name = styled(Text)<TextProps>`
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #000;
    line-height: 20px;
    padding: 0 6px;
    word-break: break-all;
    /* height: 36px; */
    @media (max-width: 768px) {
        font-size: 13px;
        /* height: 29px; */
        line-height: 16px;
    }
`

const Info = styled.div`
    padding: 13px 0;
    text-align: center;
    @media (max-width: 768px) {
        padding: 12px 0;
    }
`

const ListWrapper = styled.div`
    margin: 36px 0 0 0;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 200px);
    grid-gap: 40px;
    .list-item {
        display: block;
        width: 100%;
        background: #fff;
        box-shadow: 0 7px 16px 0 rgba(222, 222, 222, 0.5);
        cursor: pointer;
    }
    @media (max-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        grid-template-columns: repeat(auto-fill, 160px);
        justify-content: space-between;
        grid-gap: 0px;
        margin: 28px 0 0 0;
    }
`
const ListWrapperItem = styled.div`
    height: 300px;
    @media (max-width: 768px) {
        width: 160px;
        height: 260px;
    }
`
const SelectAdress = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    /* margin-top: 24px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(151, 151, 151, 1); */
    .box {
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f9f9f9;
        border: 1px solid rgba(211, 211, 211, 1);
        border-radius: 8px;
        padding: 6px 12px;
        @media (max-width: 768px) {
            height: 36px;
        }
    }

    .name {
        font-family: OpenSans-Bold;
        font-size: 18px;
        color: #000000;
        font-weight: 700;
        margin-right: 6px;
    }
`

const TotalLength = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: OpenSans-Regular;
    font-size: 16px;
    align-items: center;
    color: #acacac;
    margin-top: 20px;
    .total {
        display: flex;
        align-items: center;
    }
    .name {
        font-family: OpenSans-Bold;
        font-size: 18px;
        color: #000000;
        font-weight: 700;
        margin-right: 10px;
    }
`
