import React, { FC, useEffect, useMemo, useState } from 'react'
// import styled from '@emotion/styled'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import styled from '@emotion/styled'

import { useProductDetail, useProductPrice } from 'core/scanRent/api'

import { OrderSummary } from './components/OrderSummary'
import { Payment } from './components/Payment'
import { PersonalInformationForm } from 'core/scanRent/models'
import {
    getCreateOrderPageType,
    getScanProductsInfo,
    getScanRentPersonalInfo,
    setCreateOrderPageType,
    setScanRentPersonalInfo
} from 'shared/config'

import { BaseDelivery, DeliveryTypeEnum } from 'core/delivery/models'
import { useCreateOrder } from 'core/scanRent/api/useCreateOrder'
import { useApiError, useShowMessage, useSignInRedirect, useUrlParams, useUserInfo } from 'shared/hooks'
import { getIsTourist } from 'shared/config'
import { Spin } from 'antd'
import { useDeliveryCreate } from 'core/delivery/api'
import { Summary } from 'components'
import left from '../assets/left.png'
import { CountryCodeList } from 'shared/CountryCode'
import { useI18n } from 'core/language/api'
import { alertError } from 'shared/utils'

export enum StepType {
    orderSummary = 'orderSummary',
    payment = 'payment'
}
const initialCC = 'US'

export const PayToShowCreateOrder: FC = () => {
    const {
        methods: { t }
    } = useI18n()

    const { getToken } = useSignInRedirect()
    const userInfo = useUserInfo()
    const {
        params: { code },
        changeParams
    } = useUrlParams({
        defaultValues: { code: '' }
    })
    const {
        methods: { handleError }
    } = useApiError('scan-rent')

    const navigate = useNavigate()

    const [step, setStep] = useState(
        getToken() && !getIsTourist()
            ? StepType.payment
            : getCreateOrderPageType()
            ? getCreateOrderPageType
            : StepType.orderSummary
    )
    const { id, skuId, quantity } = useParams<{ id: string; skuId: string; quantity: string }>()

    const {
        loading: productDetailLoading,
        detail,
        productSku,
        methods: { getProductDetail }
    } = useProductDetail(`${id}`)

    const {
        priceLoading,
        price,
        methods: { getProductPrice }
    } = useProductPrice()

    const endRentTime = useMemo(() => {
        return (
            price &&
            moment()
                .add(+price?.frameRentDuration, 'hours')
                .format('MM/DD/YYYY HH:mm')
        )
    }, [price])

    const items = useMemo(() => {
        return [
            {
                productId: id ? id : '',
                skuId: skuId === undefined ? '' : skuId === 'sku' ? '' : skuId,
                quantity: quantity ? +quantity : 1,
                virtual: false,
                timeZone: '',
                duration: '',
                frameId: getScanProductsInfo().frameId
            }
        ]
    }, [id, quantity, skuId])

    const orderItems = useMemo(() => {
        if (detail && id && quantity) {
            const obj = productSku?.find(({ id }) => id === skuId)
            return [
                {
                    type: detail.type,
                    image: detail.images[0],
                    name: detail.name,
                    price: obj?.price! ? obj?.price! : detail.showPrice[0],
                    productId: id,
                    quantity: +quantity,
                    timeZone: detail.timeZone,
                    duration: detail.description,
                    dayOrHour: detail.rentDuration
                        ? +detail.rentDuration === 1
                            ? ' Hour'
                            : +detail.rentDuration === 6
                            ? '6 Hours'
                            : 'Day'
                        : obj?.rentDuration && +obj?.rentDuration === 1
                        ? 'Hour'
                        : obj?.rentDuration && +obj?.rentDuration === 6
                        ? '6 Hours'
                        : 'Day',
                    endRentTime
                }
            ]
        }
        return [
            {
                type: 1,
                image: '',
                name: '',
                price: '',
                productId: '',
                quantity: 1,
                timeZone: '',
                duration: '',
                endRentTime: ''
            }
        ]
    }, [detail, endRentTime, id, productSku, quantity, skuId])

    useEffect(() => {
        getProductDetail().catch(handleError)
    }, [getProductDetail, handleError])

    useEffect(() => {
        if (detail) {
            getProductPrice({
                deliveryId: '',
                items: items,
                couponCode: code
            }).catch(handleError)
        }
    }, [code, detail, getProductPrice, handleError, items, productSku])

    const applyCouponCode = (value: string) => {
        changeParams({ code: value })
    }

    const {
        loading: createOrderLoading,
        payOrderFormRef,
        methods: { createOrder }
    } = useCreateOrder()

    const {
        methods: { create }
    } = useDeliveryCreate(DeliveryTypeEnum.BILLING)

    const scanRentPersonalInfo = useMemo(() => {
        return getScanRentPersonalInfo()
    }, [])
    const userInfoEmail = useMemo(() => {
        return userInfo?.userName
    }, [userInfo])

    const [ccLocale, setCcLocale] = useState(
        scanRentPersonalInfo.isShow ? scanRentPersonalInfo.ccLocale : initialCC
    )
    const cc = useMemo(() => CountryCodeList.find(({ locale }) => locale === ccLocale)?.code, [ccLocale])

    const [personalInfoValues, setPersonalInfolValues] = useState<PersonalInformationForm>({
        firstName: scanRentPersonalInfo.isShow ? scanRentPersonalInfo.firstName : '',
        lastName: scanRentPersonalInfo.isShow ? scanRentPersonalInfo.lastName : '',
        emailAddress: scanRentPersonalInfo.isShow ? scanRentPersonalInfo.emailAddress : '',
        confirmEmailAddress: scanRentPersonalInfo.isShow ? scanRentPersonalInfo.confirmEmailAddress : '',
        phone: scanRentPersonalInfo.isShow ? scanRentPersonalInfo.phone : ''
    })

    const [PersonalInformationId, setPersonalInformationId] = useState<string>()

    const [billingAddressValue, setBillingAddressValue] = useState<BaseDelivery>({
        firstName: '',
        lastName: '',
        companyName: '',
        streetAddress: '',
        apartment: '',
        city: '',
        state: '',
        zipCode: '',
        phone: '',
        isDefault: false
    })

    const {
        methods: { showErrorModal }
    } = useShowMessage()

    useEffect(() => {
        if (
            getToken() &&
            !getIsTourist() &&
            !scanRentPersonalInfo.isShow &&
            userInfoEmail &&
            personalInfoValues.confirmEmailAddress === ''
        ) {
            setPersonalInfolValues({
                ...personalInfoValues,
                emailAddress: userInfoEmail,
                confirmEmailAddress: userInfoEmail
            })
        }
    }, [getToken, personalInfoValues, scanRentPersonalInfo.isShow, userInfoEmail])

    const onSubmit = (value: PersonalInformationForm) => {
        setScanRentPersonalInfo(JSON.stringify({ ...value, ccLocale: ccLocale, cc: cc, isShow: false }))
        setCreateOrderPageType(StepType.payment)
        setStep(StepType.payment)
        setPersonalInfolValues(value)
    }

    const [cardPay, setCardPay] = useState(true)
    const onBillingAddressSubmit = (value: BaseDelivery, selectId?: string) => {
        ;(() => {
            if (getToken() && !getIsTourist()) {
                if (!cardPay || selectId) {
                    return createOrder({
                        items: [{ ...getScanProductsInfo() }],
                        couponCode: code,
                        personalInfoId: PersonalInformationId ? PersonalInformationId : '',
                        billInfoId: cardPay ? selectId : ''
                    })
                }

                return create(value).then(id => {
                    createOrder({
                        items: [{ ...getScanProductsInfo() }],
                        couponCode: code,
                        personalInfoId: PersonalInformationId ? PersonalInformationId : '',
                        billInfoId: cardPay ? id : ''
                    })
                })
            } else {
                return createOrder({
                    items: [{ ...getScanProductsInfo() }],
                    couponCode: code,
                    billInfo: cardPay ? value : undefined,
                    personalInfo: {
                        ...personalInfoValues,
                        companyName: '',
                        email: personalInfoValues.emailAddress,
                        phone: personalInfoValues.phone ? `+${cc}${personalInfoValues.phone}` : ''
                    }
                })
            }
        })().catch(err => {
            alertError(err, 'createOrder => ')
            showErrorModal(t(err.data.msg))
        })
    }

    return (
        <Spin spinning={createOrderLoading || priceLoading || productDetailLoading}>
            <Container>
                <Header>
                    <img
                        width={20}
                        height={20}
                        src={left}
                        alt="self"
                        onClick={() => navigate(-1)}
                        style={{ marginRight: '10px' }}
                    />
                    <div>CHECKOUT</div>
                </Header>
                <Summary
                    virtual={true}
                    isSelectedDelivery={false}
                    orderItems={orderItems}
                    orderPrice={price!}
                    couponCodeInput={code}
                    couponCodeValue={code}
                    onApply={applyCouponCode}
                />
                {step === StepType.orderSummary ? (
                    <OrderSummary
                        price={price!}
                        orderItems={orderItems}
                        loading={productDetailLoading || priceLoading}
                        ccLocale={ccLocale}
                        setCcLocale={setCcLocale}
                        initialValues={personalInfoValues}
                        onSubmit={onSubmit}
                        setPersonalInformationId={setPersonalInformationId}
                    />
                ) : (
                    price?.total && (
                        <Payment
                            cardPay={cardPay}
                            setCardPay={setCardPay}
                            total={+price.total}
                            onSubmit={onBillingAddressSubmit}
                            setStep={setStep}
                            initialValues={billingAddressValue}
                            payOrderFormRef={payOrderFormRef}
                            loading={createOrderLoading}
                            setBillingAddressValue={setBillingAddressValue}
                        />
                    )
                )}
            </Container>
        </Spin>
    )
}

const Container = styled.div``

const Header = styled.header`
    display: flex;
    align-items: center;
    font-family: Roboto-Medium;
    font-size: 24px;
    color: #000000;
    line-height: 28px;
    font-weight: 500;
    padding: 20px 0px;
    margin: 0px 20px 24px;
    border-bottom: 1px solid rgba(47, 46, 46, 1);
    @media (max-width: 768px) {
    }
`
