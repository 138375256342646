import { pathJoin } from 'shared/utils'
import { SuccessItemResponse } from 'shared/request'
import { apiHttp } from 'shared/request'

import type { ContactUsFormData } from 'core/homepage/models'

const URI = '/contractUs'

export const contactUs = (formData: ContactUsFormData) =>
    apiHttp.post<SuccessItemResponse<void>>(URI, formData)

export const subscribe = (email: string) => {
    const url = pathJoin(URI, 'join')
    return apiHttp.post<SuccessItemResponse<void>>(url, { email })
}
