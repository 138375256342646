import React, { CSSProperties, FC, PropsWithChildren } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import styled from '@emotion/styled'

interface LineButtonLinkProps {
    to: LinkProps['to']
    className?: string
    style?: CSSProperties
    disabled?: boolean
}

export const LineButtonLink: FC<PropsWithChildren<LineButtonLinkProps>> = ({
    children,
    to,
    className,
    style,
    disabled = false
}) => {
    return (
        <Content
            className={`
                group bg-white border border-black inline-block rounded-full items-center hover:bg-black transition
                ${className}
                ${disabled ? 'disabled' : ''}
            `}
            style={style}
        >
            {disabled ? (
                <div className="text-2xl leading-8 px-6 py-2 inline-block text-black group-hover:text-white">
                    {children}
                </div>
            ) : (
                <Link
                    className="text-2xl leading-8 px-6 py-2 inline-block text-black group-hover:text-white"
                    to={to}
                >
                    {children}
                </Link>
            )}
        </Content>
    )
}

const Content = styled.div`
    cursor: pointer;
    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`
