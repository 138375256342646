import React, { FC } from 'react'

import { useLoginModal, useResponsive } from 'shared/hooks'

import { Modal } from 'antd'
import { ConnectWallet } from 'modules/management/nft/components/ConnectWallet'

import closeImg from 'assets/closeImg.png'

interface Props {
    onConnectSuccess: (value: string) => void
}

export const LoginModal: FC<Props> = ({ onConnectSuccess }) => {
    const { md } = useResponsive()
    const {
        visible,
        methods: { hide }
    } = useLoginModal()

    return (
        <>
            <Modal
                centered
                open={visible}
                footer={null}
                width={md ? 520 : 315}
                onCancel={hide}
                maskStyle={{ background: '#000000', opacity: 0.2 }}
                closeIcon={
                    <img
                        style={md ? { marginTop: 24 } : { marginTop: 18 }}
                        width={24}
                        height={24}
                        src={closeImg}
                        onClick={hide}
                        alt="closeImg"
                    />
                }
                wrapClassName="add-wallet-modal"
            >
                <ConnectWallet onConnectSuccess={onConnectSuccess} />
            </Modal>
        </>
    )
}
