import {
    FrameRentList,
    convertToScanRentProductFrame,
    ScanRentSkuListItem,
    ScanRentDetail
} from 'core/scanRent/models'
import { convertToDollar, convertToImageUrl, getRootUrlPrefix } from 'shared/utils'

export interface ScanRentListDTO {
    id: string
    categoryId: string
    canDirectBuy: boolean
    images: string[]
    description: string
    mobileDescription: string
    name: string
    salesVolume: number
    showPrice: string[]
    skuList: ScanRentSkuListItem[]
}

export interface ScanRentReferences {
    id: string
    parentId: string
    description: string
    displayOrder: number
    icon: string
    keywords: string
    name: string
    type: 'product_category'
}

export interface FrameRentListDTO {
    id: number
    images: string[]
    productButtonName: string
    buttonName: string
    title: string
    titleSize: number
    words: string
    content: string
    address: string
    productList: ScanRentListDTO[]
}

export interface ScanRentDetailDTO {
    id: number
    images: string[]
    productButtonName: string
    buttonName: string
    title: string
    titleSize: number
    words: string
    content: string
    address: string
    productList: ScanRentListDTO[]
}

export const convertToScanRentInDetail = async (dtos: ScanRentDetailDTO): Promise<ScanRentDetail> => {
    const prefix = await getRootUrlPrefix()
    const { id, images, title, titleSize, buttonName, productButtonName, words, content, address } = dtos
    return {
        id,
        images,
        title,
        titleSize,
        buttonName,
        productButtonName,
        words,
        content,
        address,
        productList: dtos.productList.map(dto => {
            const { id, canDirectBuy, images, mobileDescription, description, name, showPrice, skuList } = dto
            return {
                id,
                name,
                canDirectBuy,
                image: convertToImageUrl(prefix, images[0]),
                showPrice: convertToDollar(showPrice[0]),
                description,
                mobileDescription,
                skuList
            }
        })
    }
}

export const convertToScanRentInList = async (dtos: FrameRentListDTO[]): Promise<FrameRentList[]> => {
    const prefix = await getRootUrlPrefix()
    let data: FrameRentList[] = []
    dtos.map(dto => {
        // const { id, images, productButtonName, buttonName, productList } = dto
        return data.push({
            ...dto,
            id: dto.id,
            images: convertToImageUrl(prefix, dto?.images[0])
        })
    })
    return data
}

export const convertToScanRentInProductFrame = async (
    dtos: ScanRentListDTO[],
    references: ScanRentReferences[]
): Promise<convertToScanRentProductFrame[]> => {
    const prefix = await getRootUrlPrefix()
    let data: convertToScanRentProductFrame[] = []
    references.map(({ id }) => {
        return dtos
            .filter(({ categoryId }) => id === categoryId)
            .map(dto => {
                const { id, canDirectBuy, images, mobileDescription, description, name, showPrice, skuList } =
                    dto
                return data.push({
                    id,
                    name,
                    canDirectBuy,
                    image: convertToImageUrl(prefix, images[0]),
                    showPrice: convertToDollar(showPrice[0]),
                    description,
                    mobileDescription,
                    skuList
                })
            })
    })
    return data
}
