import React, { ComponentProps, FC, PropsWithChildren } from 'react'
import { NavLink } from 'react-router-dom'

import { Dropdown, Menu } from 'antd'

interface HeaderDropdownProps {
    items: Array<{ to: ComponentProps<typeof NavLink>['to']; text: string }>
}

export const HeaderDropdown: FC<PropsWithChildren<HeaderDropdownProps>> = ({ children, items }) => {
    return (
        <Dropdown
            placement={'bottom'}
            overlay={
                <Menu
                    items={items.map(({ to, text }, idx) => ({
                        key: idx,
                        label: (
                            <NavLink to={to} state={{ hot: true }}>
                                {text}
                            </NavLink>
                        )
                    }))}
                />
            }
            overlayClassName="header-dropdown"
        >
            <div>{children}</div>
        </Dropdown>
    )
}
