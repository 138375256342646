import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'

import type { BaseDelivery, Delivery } from 'core/delivery/models'
import { logEvent } from 'firebase/analytics'
import { alertError, analytics, removeComma } from 'shared/utils'
import { useCartList } from 'core/cart/api'
import { useI18n } from 'core/language/api'
import { useCreateOrder, useOrderSteps } from 'core/order/api'
import { OrderItemForSummary, STEPS } from 'core/order/models'

import { USER_METASILL_URL, getIsTourist, getTouristAddress, removeTouristAddress } from 'shared/config'
import {
    useApiError,
    useShowMessage,
    useSignInRedirect,
    useUrlParams,
    useResponsive,
    useMemoizedFn
} from 'shared/hooks'
import { Header } from '../components/Header'
import { AddressForm } from '../components/AddressForm'
import { Modal } from 'antd'

import { AddressList } from './addressList'
import { AddressItemPage } from './addressItem'
import { ShowSingleButtonModalMessage } from 'components/ShowSingleButtonModalMessage'
import { Button, Spin, Summary } from 'components'
import { useDeliveryList, useSelectedDelivery } from 'core/delivery/api'
import { CommonProductPay } from './CommonProductPay'

export enum ListOrAdd {
    LIST,
    ADD
}
export const OrderCreate: FC = () => {
    const [visible, setVisible] = useState<boolean>(false)
    const [listOrAdd, setListOrAdd] = useState<ListOrAdd>(ListOrAdd.LIST)
    const [isPay, setIsPay] = useState<boolean>(false)
    const [isShippingAddress, setIsShippingAddress] = useState(true)
    const [billingAdress, setBillingAdress] = useState<Delivery>()
    const [createId, setCreateId] = useState<string>()
    const [cardPayLoading, setCardPayLoading] = useState<boolean>(false)

    const { md } = useResponsive()
    const {
        methods: { t }
    } = useI18n()
    const payOrderFormRef = useRef<any>()

    const {
        methods: { error, showSingleButtonModal }
    } = useShowMessage()
    const {
        methods: { clearCartNum }
    } = useCartList()

    const {
        methods: { handleError }
    } = useApiError('OrderCreate', error as any)

    const { params, changeParams } = useUrlParams({
        defaultValues: { code: '' }
    })

    const { getToken, checkLogin } = useSignInRedirect()
    const navigate = useNavigate()

    const {
        loading,
        orderItems,
        orderPrice,
        methods: { calculatePrice, create, touristCreate }
    } = useCreateOrder()
    const {
        methods: { setSelectedDelivery }
    } = useSelectedDelivery()
    const {
        list,
        methods: { getList }
    } = useDeliveryList()

    useEffect(() => {
        if (!createId) {
            const item = list.find(({ isDefault }) => isDefault)
            if (item) {
                setSelectedDelivery(item.id)
            }
        }
    }, [createId, list, setSelectedDelivery])

    useEffect(() => {
        getToken() && getList().catch(handleError)
        if (createId && createId !== '') {
            setSelectedDelivery(createId)
        }
    }, [createId, getList, getToken, handleError, setSelectedDelivery])

    useEffect(() => {
        if (!orderItems?.length) navigate('/cart')
    }, [navigate, orderItems])

    const {
        selectedDelivery,
        sameShipping,
        methods: { setStep, clearCreateOrderInfo }
    } = useOrderSteps()

    const handleApply = useCallback(
        (value: string) => {
            changeParams({ code: value })
        },
        [changeParams]
    )
    const init = useCallback(
        (couponCode?: string, isPay?: boolean) => {
            const touristAddress: BaseDelivery = JSON.parse(getTouristAddress() || '{}')
            orderItems && getIsTourist()
                ? getTouristAddress()
                    ? calculatePrice('', couponCode, {
                          email: touristAddress?.email ? touristAddress.email : '',
                          firstName: touristAddress?.firstName ? touristAddress.firstName : '',
                          lastName: touristAddress?.lastName ? touristAddress.lastName : '',
                          companyName: touristAddress?.companyName ? touristAddress.companyName : '',
                          streetAddress: touristAddress?.streetAddress ? touristAddress.streetAddress : '',
                          apartment: touristAddress?.apartment ? touristAddress.apartment : '',
                          city: touristAddress?.city ? touristAddress.city : '',
                          state: touristAddress?.state ? touristAddress.state : '',
                          zipCode: touristAddress?.zipCode ? touristAddress.zipCode : '',
                          phone: touristAddress?.phone ? touristAddress.phone : ''
                      })
                          .then(() => {
                              if (isPay) {
                                  setIsPay(true)
                              }
                          })
                          .catch(error => {
                              const msg = error.data.msg
                              if (msg === 'error.quantity_once_limit') {
                                  showSingleButtonModal({
                                      message: t('error.quantity_once_limit'),
                                      type: 'fail',
                                      buttonText: t('View cart'),
                                      onButtonClick: () => navigate(`/cart`, { replace: true })
                                  })
                              } else {
                                  handleError(error)
                              }
                          })
                    : calculatePrice('', couponCode).catch(error => {
                          const msg = error.data.msg
                          if (msg === 'error.quantity_once_limit') {
                              showSingleButtonModal({
                                  message: t('error.quantity_once_limit'),
                                  type: 'fail',
                                  buttonText: t('View cart'),
                                  onButtonClick: () => navigate(`/cart`, { replace: true })
                              })
                          } else {
                              handleError(error)
                          }
                      })
                : calculatePrice(selectedDelivery ? selectedDelivery.id : '', couponCode).catch(error => {
                      const msg = error.data.msg
                      if (msg === 'error.quantity_once_limit') {
                          showSingleButtonModal({
                              message: t('error.quantity_once_limit'),
                              type: 'fail',
                              buttonText: t('View cart'),
                              onButtonClick: () => navigate(`/cart`, { replace: true })
                          })
                      } else {
                          handleError(error)
                      }
                  })
        },
        [calculatePrice, handleError, navigate, orderItems, selectedDelivery, showSingleButtonModal, t]
    )
    useEffect(() => {
        init(params.code)
    }, [init, isPay, params.code])
    const summaryOrderItems = useMemo(() => {
        let data: OrderItemForSummary[] = []
        orderItems?.forEach(({ items }) => {
            items.forEach(
                ({
                    type,
                    productId,
                    image,
                    price,
                    depositPrice,
                    name,
                    quantity,
                    spec,
                    timeZone,
                    duration,
                    durations
                }) => {
                    data.push({
                        type,
                        productId,
                        image,
                        price,
                        depositPrice,
                        name,
                        quantity,
                        spec,
                        timeZone: timeZone || '',
                        duration: duration || '',
                        durations: durations || []
                    })
                }
            )
        })
        return data
    }, [orderItems])

    const allIsVirtual = useMemo(() => {
        let hasMaterial = false
        if (orderItems && orderItems.length) {
            orderItems.forEach(store => {
                if (store?.items.find(item => !item.virtual)) {
                    hasMaterial = true
                }
            })
        }
        return !hasMaterial
    }, [orderItems])

    const onCancel = () => {
        setVisible(false)
        setListOrAdd(ListOrAdd.LIST)
    }

    const toPayment = () => {
        if (getIsTourist()) {
            return init(params.code, true)
        } else {
            return setIsPay(true)
        }
    }

    const handleRedirect = useMemoizedFn((orderCode: string) =>
        navigate(`/orders/${orderCode}`, { replace: true })
    )
    const handleCannotPay = useMemoizedFn((orderCode: string, errMsg?: string) => {
        showSingleButtonModal({
            message: errMsg || t('error.cannot_pay'),
            type: 'fail',
            buttonText: t('View order'),
            onButtonClick: () => handleRedirect(orderCode)
        })
    })
    const payByCardLogEvent = useMemoizedFn((orderCode: string) => {
        logEvent(analytics, 'purchase' as never, {
            shipping: orderPrice?.delivery,
            tax: orderPrice?.tax,
            coupon: params.code,
            transaction_id: orderCode,
            value: orderPrice?.total,
            items: summaryOrderItems.map(({ productId, name, price, quantity }) => {
                return {
                    id: productId,
                    name,
                    price,
                    quantity
                }
            })
        })
    })
    const payByCard = useMemoizedFn((orderCode: string) => {
        setCardPayLoading(true)

        payOrderFormRef?.current.submit((error?: string) => {
            setCardPayLoading(false)
            setStep(STEPS.SHIPPING_DETAILS)
            clearCreateOrderInfo()
            payByCardLogEvent(orderCode)
            setTimeout(() => {
                if (error) {
                    handleCannotPay(orderCode, error)
                    return
                }
                showSingleButtonModal({
                    message: (
                        <ShowSingleButtonModalMessage
                            title={t('client.order.pay.success')}
                            context={t('client.order.pay.success_message')}
                        />
                    ),
                    type: 'success',
                    buttonText: t('View Orders'),
                    onButtonClick: () => handleRedirect(orderCode)
                })
            }, 100)
        })
    })
    const checkLoginHandle = useCallback(
        (step?: STEPS): Promise<void> => {
            return new Promise((resolve, reject) => {
                step && setStep(step)
                if (!getToken()) {
                    checkLogin('/orders/create').catch(handleError)
                    reject()
                }
                resolve()
            })
        },
        [checkLogin, getToken, handleError, setStep]
    )

    const [cardPay, setCardPay] = useState(true)
    const handleCreateOrderThen = useMemoizedFn((orderIds: string[]) => {
        getIsTourist() && removeTouristAddress()
        clearCartNum()
        if (orderIds.length === 1) {
            payByCard(orderIds[0])
        } else {
            // Multiple stores
        }
    })
    const handleCreateOrderCatch = useMemoizedFn((error: any) => {
        alertError(error, 'create order catch')
        setStep(STEPS.SHIPPING_DETAILS)
        clearCreateOrderInfo()
        const msg = error.data.msg
        if (msg === 'error.quantity_once_limit') {
            showSingleButtonModal({
                message: t('error.quantity_once_limit'),
                type: 'fail',
                buttonText: t('View cart'),
                onButtonClick: () => navigate(`/cart`, { replace: true })
            })
        } else if (msg === 'error.cannot_pay') {
            showSingleButtonModal({
                message: t('error.cannot_pay'),
                type: 'fail',
                buttonText: t('View order'),
                onButtonClick: () => navigate(`/orders`, { replace: true })
            })
        } else {
            handleError(error)
        }
    })
    const handleCreateOrder = useMemoizedFn((id?: string, values?: Delivery) => {
        if (!orderItems) return
        checkLoginHandle().then(() => {
            if (getIsTourist()) {
                if (!sameShipping && !values) return
                touristCreate(
                    orderItems,
                    {},
                    params.code,
                    0,
                    JSON.parse(getTouristAddress() || ''),
                    cardPay
                        ? sameShipping
                            ? JSON.parse(getTouristAddress() || '')
                            : values
                            ? values
                            : JSON.parse(getTouristAddress() || '')
                        : undefined
                )
                    .then(handleCreateOrderThen)
                    .catch(handleCreateOrderCatch)
            } else {
                if (!allIsVirtual && !selectedDelivery && !isShippingAddress) return
                create(
                    orderItems,
                    {},
                    params.code,
                    !allIsVirtual ? selectedDelivery?.id : '',
                    cardPay
                        ? isShippingAddress
                            ? selectedDelivery?.id
                            : billingAdress
                            ? billingAdress?.id
                            : id
                        : ''
                )
                    .then(handleCreateOrderThen)
                    .catch(handleCreateOrderCatch)
            }
        })
    })
    console.log('orderPrice => ', orderPrice)

    return (
        <Spin spinning={loading || cardPayLoading}>
            <Wrapper>
                <Header />
                <div className="borderDetail">
                    {isPay && orderPrice ? (
                        <CommonProductPay
                            cardPay={cardPay}
                            setCardPay={setCardPay}
                            amount={removeComma(orderPrice.total)}
                            isShippingAddress={isShippingAddress}
                            setIsShippingAddress={setIsShippingAddress}
                            onSubmit={handleCreateOrder}
                            payOrderFormRef={payOrderFormRef}
                            setBillingAdress={setBillingAdress}
                            billingAdress={billingAdress}
                            selectedDelivery={selectedDelivery}
                        />
                    ) : (
                        <AddressInfo className="left">
                            <div className="title">
                                <span className="text">Where should we send your order?</span>
                                {selectedDelivery && !getIsTourist() && (
                                    <span onClick={() => setVisible(true)} className="change">
                                        Change
                                    </span>
                                )}
                            </div>
                            {selectedDelivery ? (
                                <AddressItems>
                                    <AddressItemPage
                                        type={'delivery'}
                                        className="addressItem"
                                        addressItem={selectedDelivery}
                                    />
                                    <Button
                                        className="continue"
                                        onClick={toPayment}
                                        textStyle={{ padding: 0 }}
                                    >
                                        Continue to Payment
                                    </Button>
                                </AddressItems>
                            ) : getIsTourist() && getTouristAddress() ? (
                                <div className="touristAddress">
                                    <AddressForm
                                        defaultAdress={JSON.parse(getTouristAddress() || '')}
                                        setCreateId={setCreateId}
                                        toPayment={toPayment}
                                        btnWidth={0}
                                        buttonText="Continue to Payment"
                                        btnClassName="ReturnPolicyBtn"
                                    />
                                </div>
                            ) : (
                                <div className="addressFrom touristAddress">
                                    <AddressForm
                                        setCreateId={setCreateId}
                                        toPayment={toPayment}
                                        btnWidth={0}
                                        buttonText="Continue to Payment"
                                        btnClassName="ReturnPolicyBtn"
                                    />
                                </div>
                            )}
                            <ReturnPolicy>
                                <div>
                                    <ReturnPolicyText>
                                        By continuing and completing the purchase,
                                    </ReturnPolicyText>
                                    <ReturnPolicyText>
                                        you agree to abide by our{' '}
                                        <span
                                            className="underline"
                                            onClick={() => {
                                                window.open(USER_METASILL_URL, '_blank')
                                            }}
                                        >
                                            Return Policy
                                        </span>
                                        .
                                    </ReturnPolicyText>
                                </div>
                            </ReturnPolicy>
                        </AddressInfo>
                    )}
                    <SummaryWrapper>
                        <Summary
                            virtual={allIsVirtual}
                            isSelectedDelivery={!!selectedDelivery}
                            orderItems={summaryOrderItems}
                            orderPrice={orderPrice}
                            couponCodeInput={params.code}
                            couponCodeValue={params.code}
                            onBack={() => navigate('/cart')}
                            onApply={handleApply}
                        />
                    </SummaryWrapper>
                </div>
                <Modal
                    open={visible}
                    onCancel={onCancel}
                    footer={null}
                    width={md ? 850 : '100%'}
                    destroyOnClose
                    bodyStyle={{ padding: '40px 24px 24px !important' }}
                    style={{
                        maxWidth: '100%',
                        margin: md ? '0 auto' : 0,
                        top: md ? 100 : 56
                    }}
                >
                    {listOrAdd === ListOrAdd.LIST ? (
                        <AddressList
                            type="delivery"
                            title="Select a shipping address"
                            selectedDeliveryId={selectedDelivery?.id}
                            list={list}
                            setVisible={setVisible}
                            add={() => {
                                setListOrAdd(ListOrAdd.ADD)
                            }}
                        ></AddressList>
                    ) : (
                        <ModalBox className="modalBox">
                            <AddressForm
                                setCreateId={setCreateId}
                                addressTitle="Add a new address"
                                buttonText="Deliver to this address"
                                btnWidth={md ? 378 : '100%'}
                                setVisible={() => {
                                    setVisible(false)
                                    setListOrAdd(ListOrAdd.LIST)
                                }}
                            />
                        </ModalBox>
                    )}
                </Modal>
            </Wrapper>
        </Spin>
    )
}

const ReturnPolicy = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
`

const ReturnPolicyText = styled.div`
    font-family: HelveticaNeue;
    font-size: 16px;
    color: #000000;
    letter-spacing: 0;
    line-height: 18px;
    .underline {
        text-decoration: underline;
        cursor: pointer;
    }
    @media (max-width: 768px) {
        font-size: 14px;
    }
`

const ModalBox = styled.div`
    padding: 0 110px;
    .modalTitle {
        font-family: Roboto-Medium;
        font-size: 30px;
        color: #000000;
        letter-spacing: 0;
        text-align: center;
        line-height: 28px;
    }
    @media (max-width: 580px) {
        padding: 0;
    }
`
const AddressInfo = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 58px;

    .title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 40px 0 30px 0;
        .text {
            font-family: HelveticaNeue-CondensedBold;
            font-size: 30px;
            color: #000000;
            letter-spacing: 0;
            line-height: 30px;
            @media (max-width: 580px) {
                font-size: 18px;
            }
        }
        .change {
            font-family: HelveticaNeue;
            font-size: 18px;
            color: #000000;
            letter-spacing: 0;
            line-height: 26px;
            text-decoration: underline;
            cursor: pointer;
        }
        @media (max-width: 580px) {
            width: 100%;
        }
    }
    .addressFrom {
        width: 580px;
        @media (max-width: 580px) {
            width: 100%;
        }
    }
    @media (max-width: 768px) {
        margin-right: 0px;
    }
`
const Wrapper = styled.div`
    max-width: 1450px;
    margin: 0 auto;
    margin-bottom: 30px;
    padding: 72px 72px 0px;
    .borderDetail {
        padding: 0 138px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 90px;
        @media (max-width: 768px) {
            flex-direction: column;
            padding: 0;
        }
    }
    .left .touristAddress .ReturnPolicyBtn {
        margin-bottom: 0 !important;
    }
    @media (max-width: 768px) {
        padding: 0 20px;
    }
`
const SummaryWrapper = styled.div`
    width: 384px;
    margin-top: 30px;
    .item {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;

        font-family: HelveticaNeue;
        font-size: 18px;
        color: #000000;
        line-height: 26px;
        cursor: pointer;

        img {
            width: 1.6rem;
            height: 1.6rem;
            margin-right: 1rem;
        }
    }
    @media (max-width: 768px) {
        width: 100%;
        margin-left: 0;
    }
`
const AddressItems = styled.div`
    .addressItem {
        font-family: HelveticaNeue;
        font-size: 18px;
        color: #000000;
        letter-spacing: 0;
        line-height: 26px;
    }

    .continue {
        width: 100%;
        margin-top: 40px;
        font-family: Roboto-Medium;
        font-size: 25.33px;
        color: #ffffff;
        letter-spacing: 0;
        text-align: center;
        line-height: 30.96px;
    }
`
