import { useMemo, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { pick, equals } from 'ramda'

import { useMemoizedFn } from 'shared/hooks'
import { urlQueryString } from 'shared/utils'

export const useUrlParams = <T extends Record<string, string>>({
    defaultValues,
    replace: replaceModel = false,
    strict = true
}: {
    defaultValues: T
    replace?: boolean
    strict?: boolean // Whether it is in strict mode or not, in strict mode, only the fields in defaultValues are kept
}) => {
    const location = useLocation()
    const navigate = useNavigate()

    const prevParams = useRef<T>(defaultValues)

    const params = useMemo(() => {
        const locationSearch = urlQueryString.parse(location.search.replace('?', '')) as any as Partial<T>
        const s = strict ? pick(Object.keys(defaultValues), locationSearch) : locationSearch
        const newValues = { ...defaultValues, ...s } as T
        if (!equals(newValues, prevParams.current)) {
            prevParams.current = newValues
        }

        return prevParams.current
    }, [location.search, strict, defaultValues])

    const changeParams = useMemoizedFn((p: Partial<T>) => {
        const newParamsMap: T = { ...params, ...p }

        if (equals(newParamsMap, params)) return // If the parameters do not change, do not change the url

        // Filter out empty strings
        const paramsWithoutNull = Object.keys(newParamsMap).reduce<Partial<T>>((acc, key) => {
            if (newParamsMap[key] !== '') {
                return { ...acc, [key]: newParamsMap[key] }
            }
            return acc
        }, {})

        navigate({ search: `?${urlQueryString.stringify(paramsWithoutNull)}` }, { replace: replaceModel })
    })

    return {
        params,
        changeParams
    }
}
