import styled from '@emotion/styled'
import React, { FC } from 'react'

import bg from 'assets/contact-bg.jpg'
import { Col, Row } from 'antd'
import { useResponsive } from 'shared/hooks'

export const KeyContact: FC = () => {
    const { md } = useResponsive()
    return (
        <Wrapper>
            <Card>
                <Title style={{ marginBottom: md ? 70 : 48 }}>Key Business Contact</Title>
                <Row gutter={md ? 60 : [0, 48]}>
                    <Col span={md ? 12 : 24}>
                        <CardItem>
                            <div>
                                <Title style={{ marginBottom: 20 }}>Leo Lin</Title>
                                <Text style={{ marginBottom: 24 }}>Founder & CEO</Text>
                                <Text>leolin@metasill.io</Text>
                            </div>
                        </CardItem>
                    </Col>
                    <Col span={md ? 12 : 24}>
                        <CardItem>
                            <div>
                                <Title style={{ marginBottom: 20 }}>Chad Hadger</Title>
                                <Text style={{ marginBottom: 24 }}>Head of BD and Operation</Text>
                                <Text>chad@metasill.io</Text>
                            </div>
                        </CardItem>
                    </Col>
                </Row>
            </Card>
        </Wrapper>
    )
}

const CardItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 220px;
    background: #ffffff;
    border-radius: 20px;
`

const Text = styled.div`
    font-family: Superclarendon;
    font-weight: 300;
    font-style: italic;
    color: #000000;
    font-size: 18px;
    text-align: center;
`

const Title = styled.div`
    font-family: Montserrat;
    font-weight: 600;
    color: #000000;
    font-size: 24px;
    text-align: center;
`

const Card = styled.div`
    width: 944px;
    height: 450px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 20px;
    padding: 70px 80px;

    @media (max-width: 768px) {
        height: auto;
        padding: 70px 36px;
    }
`

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 68px - 90px);
    background: url(${bg}) no-repeat;
    background-size: 100% 100%;

    @media (max-width: 768px) {
        min-height: calc(100vh - 56px - 90px);
        background-size: cover;
    }
`
