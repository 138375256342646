import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'

import { useApiError, useMemoizedFn, useShowMessage, useUrlParams } from 'shared/hooks'
import { useDeliveryCreate, useDeliveryList } from 'core/delivery/api'
import { useCreateOrder, useEventDetail } from 'core/event/api'
import { useI18n } from 'core/language/api'
import { getIsTourist, getToken } from 'shared/config'
import { PayContainer } from 'modules/order/payment'
import { BaseDelivery, DeliveryTypeEnum } from 'core/delivery/models'
import { removeComma } from 'shared/utils'

import { Button, Spin } from 'components'
import { Summary } from 'components'
import { BillingList } from './BillingList'
import { AddModal } from './AddModal'
import { CreateSteps } from './CreateSteps'
import { BillingAddress } from './BillingAddress'
import { ProductPanel } from './ProductPanel'

import {
    EventBillingAddressDetail,
    EventPersonalInfo,
    EventBillingAddress,
    EventOrderEnum
} from 'core/event/models'

import type { OrderItemForSummary } from 'core/order/models'

export interface CreateOrderBuyProps {
    personalInfo?: EventPersonalInfo
    step: EventOrderEnum
}

export const CreateOrderBuy: FC<CreateOrderBuyProps> = ({ personalInfo, step }) => {
    const { id, productId } = useParams<{ id: string; productId: string }>()
    const {
        params: { code }
    } = useUrlParams({
        defaultValues: { code: '' }
    })
    const {
        lang,
        methods: { t }
    } = useI18n()
    const {
        methods: { singleModal }
    } = useShowMessage()
    const {
        methods: { handleError: handleErrorBase }
    } = useApiError('CreateOrderBuy')
    const handleError = useMemoizedFn((err: any) => {
        if (err.data.msg === 'error.event_status_error') {
            singleModal({
                message: (
                    <>
                        <div>{t('error.event.end.title')}</div>
                        <div>{t('error.event.end.msg')}</div>
                    </>
                )
            })
            return
        }
        return handleErrorBase(err)
    })

    const [billingButtonText, setBillingButtonText] = useState<string>('')
    const [cardPay, setCardPay] = useState(true)
    const [enable, setEnable] = useState<boolean>(false)
    const [timeSwitch, setTimeSwitch] = useState<boolean>(false)
    const [selectBillingModalVisible, setSelectBillingModalVisible] = useState<boolean>(false)
    const [addBillingModalVisible, setAddBillingModalVisible] = useState<boolean>(false)
    const [selectedBillingId, setSelectedBillingId] = useState<string>()
    const [addressData, setAddressData] = useState<EventBillingAddressDetail>()
    const [checkedDate, setCheckedDate] = useState<string[]>([])

    const {
        detail,
        methods: { getDetail }
    } = useEventDetail(lang)
    const product = useMemo(
        () => detail?.productList.find(item => item.id === productId),
        [detail, productId]
    )
    const productRef = useMemo(
        () => detail?.productRefList.find(item => `${item.productId}` === productId),
        [detail, productId]
    )

    const productSummaryData = useMemo(() => {
        if (!product || !productRef) return
        return {
            name: product.name,
            price: product.showPrice[0],
            desc: product.mobileDescription,
            isDaily: productRef.isDaily,
            list: productRef.durations
        }
    }, [product, productRef])

    useEffect(() => {
        id && getDetail(id).catch(handleError)
    }, [getDetail, handleError, id])

    useEffect(() => {
        productRef && setCheckedDate(productRef.duration.split(','))
    }, [productRef])

    const {
        loading,
        count,
        item,
        orderPrice,
        payOrderFormRef,
        methods: {
            applyCouponCode,
            setItem,
            addQuantity,
            reduceQuantity,
            createOrder,
            calcPriceForChangeDuration
        }
    } = useCreateOrder()

    const initData = useMemoizedFn(async () => {
        if (!productId) return

        if (
            productRef?.isDaily &&
            productRef?.duration.split(',').filter(ts => +ts > dayjs().startOf('day').valueOf()).length === 0
        ) {
            setTimeSwitch(true)
        }
        await setItem({
            productId,
            quantity: 1,
            duration: productRef?.duration
                .split(',')
                .filter(ts => +ts > dayjs().startOf('day').valueOf())
                .join(','),
            eventId: id
        })

        await addQuantity()?.catch(handleError)
    })
    useEffect(() => {
        productSummaryData && initData()
    }, [initData, productSummaryData])

    const orderItems: OrderItemForSummary[] = useMemo(() => {
        if (!item || !product) return []
        const durations = productRef?.durations.map(item => item.label)
        return [
            {
                ...item,
                type: product.type,
                image: product.image,
                name: product.name,
                price: product.showPrice[0],
                durations
            }
        ]
    }, [item, product, productRef])

    const {
        billingList,
        empty,
        methods: { getList: getBillingList }
    } = useDeliveryList(DeliveryTypeEnum.BILLING)

    const {
        methods: { create: addressCreate }
    } = useDeliveryCreate(DeliveryTypeEnum.BILLING)

    useEffect(() => {
        if (getToken() && !getIsTourist()) {
            getBillingList().catch(handleError)
            setBillingButtonText(!empty ? 'Use a saved address' : '')
        }
    }, [empty, getBillingList, handleError])
    const handleSelectAddress = useCallback(
        (id: string) => {
            if (id) {
                setSelectedBillingId(id)
                setBillingButtonText('Change')
                const newData = billingList.filter(item => item.id === id)
                setAddressData(newData[0])
            }
        },
        [billingList]
    )
    const handleAddBilling = useCallback(
        (data: BaseDelivery) => {
            addressCreate(data).then(res => {
                setAddBillingModalVisible(false)
                let addressData = {
                    ...data,
                    id: res
                }
                setSelectedBillingId(res)
                setAddressData(addressData)
            })
        },
        [addressCreate]
    )

    const handleCreateOrder = useMemoizedFn(() =>
        createOrder({
            personalInfo,
            billInfo: cardPay ? addressData : undefined,
            billingDeliveryId: cardPay ? selectedBillingId : ''
        })?.catch(handleError)
    )

    const handleSubmit = useMemoizedFn((values: EventBillingAddress) => {
        if (getToken()) {
            if (!cardPay || getIsTourist()) {
                createOrder({ personalInfo, billInfoTourist: cardPay ? values : undefined })?.catch(
                    handleError
                )
                return
            }
            addressCreate(values)
                .then(id =>
                    createOrder({
                        personalInfo,
                        billInfo: cardPay ? values : undefined,
                        billingDeliveryId: cardPay ? id : ''
                    })?.catch(handleError)
                )
                .catch(handleError)
            return
        }
        createOrder({
            personalInfo,
            billInfo: cardPay ? values : undefined,
            billingDeliveryId: cardPay ? selectedBillingId : ''
        })?.catch(handleError)
    })

    const clickReduceQuantity = useMemoizedFn(() => {
        if (!item) return

        reduceQuantity()
        setItem({
            productId: item.productId,
            quantity: item?.quantity ? (item.quantity - 1 > 0 ? item.quantity - 1 : 1) : 1,
            duration: item?.duration,
            eventId: item?.eventId
        })
    })
    const clickAddQuantity = useMemoizedFn(() => {
        if (!item) return
        addQuantity()
            ?.then(() => {
                setItem({
                    productId: item.productId,
                    quantity: item?.quantity ? item.quantity + 1 : 1,
                    duration: item?.duration,
                    eventId: item?.eventId
                })
            })
            .catch(handleError)
    })
    const selectTime = useMemoizedFn((data: string[]) => {
        if (!item) return
        setTimeSwitch(!(data.length > 0))
        setItem({
            productId: item.productId,
            quantity: item?.quantity,
            duration: data.join(','),
            eventId: item?.eventId
        })
    })

    return (
        <Spin spinning={loading}>
            <CreateOrder>
                <CreateOrderLeft>
                    <CreateSteps step={step} />
                    <BillingCard>
                        <PayCard>
                            {orderPrice && (
                                <PayContainer
                                    key={orderPrice.total}
                                    setCardPay={setCardPay}
                                    param={{ amount: removeComma(orderPrice.total) }}
                                    innerRef={payOrderFormRef}
                                    setComplete={setEnable}
                                />
                            )}
                        </PayCard>
                        {cardPay ? (
                            <BillingAddressStyle>
                                <BillingCardTitle>
                                    <BillingTitle>Billing address</BillingTitle>
                                    <BillingTitleAddress
                                        onClick={() => {
                                            setSelectBillingModalVisible(true)
                                            getBillingList()
                                        }}
                                    >
                                        {billingButtonText}
                                    </BillingTitleAddress>
                                </BillingCardTitle>
                                {selectedBillingId ? (
                                    <BillingAddressContent>
                                        {addressData && (
                                            <div key={addressData.id}>
                                                <div>
                                                    {addressData.firstName} {addressData.lastName}
                                                </div>
                                                <div>{addressData.companyName}</div>
                                                <div>{addressData.streetAddress}</div>
                                                <div>
                                                    {addressData.city}, {addressData.state}{' '}
                                                    {addressData.zipCode}
                                                </div>
                                                {addressData.phone && <div>Phone : {addressData.phone}</div>}
                                            </div>
                                        )}
                                        <SubButton
                                            onClick={handleCreateOrder}
                                            disabled={!enable || timeSwitch}
                                        >
                                            Submit
                                        </SubButton>
                                    </BillingAddressContent>
                                ) : (
                                    <BillingAddress
                                        btnTitle="Submit"
                                        onSubmit={handleSubmit}
                                        disabled={!enable || timeSwitch}
                                    />
                                )}
                            </BillingAddressStyle>
                        ) : (
                            <SubButton onClick={handleCreateOrder} disabled={!enable || timeSwitch}>
                                Submit
                            </SubButton>
                        )}
                    </BillingCard>
                </CreateOrderLeft>
                <CreateOrderRight>
                    <SummaryWrapper>
                        {productSummaryData && (
                            <ProductPanel
                                selectTime={selectTime}
                                count={count}
                                onAddQuantity={clickAddQuantity}
                                onReduceQuantity={clickReduceQuantity}
                                checkedDate={checkedDate}
                                setCheckedDate={setCheckedDate}
                                calcPriceForChangeDuration={calcPriceForChangeDuration}
                                {...productSummaryData}
                            />
                        )}
                    </SummaryWrapper>
                    <SummaryWrapper>
                        <Summary
                            isEventOrder
                            virtual={true}
                            isSelectedDelivery={false}
                            orderItems={orderItems}
                            orderPrice={orderPrice}
                            couponCodeInput={code}
                            couponCodeValue={code}
                            onApply={applyCouponCode}
                        />
                    </SummaryWrapper>
                </CreateOrderRight>
                <BillingList
                    data={billingList}
                    visible={selectBillingModalVisible}
                    onCancel={() => setSelectBillingModalVisible(false)}
                    onSelect={handleSelectAddress}
                    onAddModalShow={() => setAddBillingModalVisible(true)}
                    selectedBillingId={selectedBillingId ? selectedBillingId : ''}
                />
                <AddModal
                    visible={addBillingModalVisible}
                    onCancel={() => setAddBillingModalVisible(false)}
                    onAddBilling={handleAddBilling}
                    disabled={!enable}
                />
            </CreateOrder>
        </Spin>
    )
}

const CreateOrder = styled.div`
    display: flex;
    justify-content: center;
    padding: 30px 0 92px 0;
    @media (max-width: 768px) {
        flex-wrap: wrap;
        padding: 0;
    }
`

const CreateOrderLeft = styled.div`
    width: 621px;
    /* margin-right: 58px; */
    @media (max-width: 768px) {
        width: 100%;
        padding: 0 20px;
    }
`

const BillingAddressStyle = styled.div`
    width: 580px;
    @media (max-width: 768px) {
        width: 100%;
    }
`

const CreateOrderRight = styled.div`
    margin-left: 58px;
    @media (max-width: 768px) {
        width: 100%;
        padding: 0;
        margin: 26px 0 5px 0;
    }
`

const BillingCard = styled.div`
    margin: 30px 0 0 0;
`

const PayCard = styled.div`
    width: 580px;
    background: #f5f5f5;
    @media (max-width: 768px) {
        width: 100%;
        padding: 0;
    }
`

const BillingCardTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;
    @media (max-width: 768px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 30px 0;
    }
`

const BillingAddressContent = styled.div`
    font-family: HelveticaNeue;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    line-height: 26px;
`

const BillingTitle = styled.div`
    font-family: HelveticaNeue-CondensedBold;
    font-size: 30px;
    color: #000000;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: bold;
    @media (max-width: 768px) {
        margin: 0 0 20px 0;
    }
`

const BillingTitleAddress = styled.div`
    font-family: HelveticaNeue;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    text-align: right;
    line-height: 26px;
    text-decoration: underline;
    cursor: pointer;
`

const SummaryWrapper = styled.div`
    width: 384px;
    @media (max-width: 768px) {
        width: 100%;
        margin: 0;
    }
`
const SubButton = styled(Button)`
    width: 100%;
    height: 50px;
    border-radius: 36px;
    margin-top: 40px;
    font-family: Roboto-Medium;
    font-size: 25.33px;
    color: #fff;
    letter-spacing: 0;
    text-align: center;
    line-height: 30.96px;
    @media (max-width: 768px) {
        width: 100%;
        margin: 30px 0 24px 0;
    }
`
