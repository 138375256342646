import { on, reducer as createReducer } from 'ts-action'
import { setI18nMap, setCurrentLanguage } from './actions'
import { Language, I18nMap } from 'core/language/models'
import { FEATURE_NAME } from './constants'
import { assoc } from 'ramda'

export interface State {
    currentLanguage: Language
    i18nMap: I18nMap
}

export interface GlobalState {
    [FEATURE_NAME]: State
}

export const initialState: State = {
    currentLanguage: 'en-US',
    i18nMap: {}
}

export const reducer = createReducer(
    initialState,
    on(setI18nMap, (state, { payload: { i18nMap } }) => assoc('i18nMap', i18nMap, state)),
    on(setCurrentLanguage, (state, { payload: { language } }) => assoc('currentLanguage', language, state))
)
