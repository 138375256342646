import React, { FC, useEffect, useMemo, useRef } from 'react'
import styled from '@emotion/styled'

import { OrderItemForSummary, OrderPrice } from 'core/order/models'
import { useUrlParams } from 'shared/hooks'

import { Tooltip } from 'antd'
import { Durations } from 'components'

import RightOutlined from 'assets/right-outlined.png'
import Circle from 'assets/circle.png'
import { CouponCode, CouponCodeRefProps } from '../modules/order/components/CouponCode'

interface SummaryProps {
    orderItems: OrderItemForSummary[]
    isEventOrder?: boolean
    virtual?: boolean
    isSelectedDelivery?: boolean
    orderPrice?: OrderPrice
    isShowCouponCodeInput?: boolean
    couponCodeInput?: string
    couponCodeValue?: string
    onBack?: () => void
    onApply?: (value: string) => void
}

export const Summary: FC<SummaryProps> = ({
    orderItems,
    isEventOrder = false,
    virtual = false,
    isSelectedDelivery = false,
    orderPrice = {
        subTotal: '0.00',
        tax: '0.00',
        delivery: '0.00',
        discount: '0.00',
        total: '0.00',
        depositTotal: '0.00'
    },
    couponCodeInput = '',
    couponCodeValue,
    isShowCouponCodeInput = true,
    onBack,
    onApply
}) => {
    const orderItemsLength = useMemo(() => orderItems.length, [orderItems])
    const productIds = useMemo<string[]>(() => {
        return orderItems.map(item => item.productId)
    }, [orderItems])

    const orderCodeRef = useRef<CouponCodeRefProps>(null)
    const {
        params: { code }
    } = useUrlParams({
        defaultValues: { code: '' }
    })
    useEffect(() => {
        if (isShowCouponCodeInput && code) {
            orderCodeRef.current?.reApply()
        }
    }, [code, isShowCouponCodeInput])

    return (
        <Wrapper>
            <div className="header">
                <h4>Order summary ({orderItemsLength})</h4>
                {onBack && <img src={RightOutlined} alt="" onClick={() => onBack()} />}
            </div>
            <div className="list">
                <div className="orderItems">
                    {orderItems.map(
                        (
                            {
                                image,
                                price,
                                depositPrice,
                                name,
                                quantity,
                                type,
                                spec,
                                durations,
                                dayOrHour,
                                endRentTime
                            },
                            index
                        ) => (
                            <div className="item" key={index}>
                                {isEventOrder ? (
                                    <div className="left">
                                        <div className="billing-info">
                                            <div className="title">{name}</div>
                                            {type === 1 ? (
                                                <div className="info-items">
                                                    <div className="info-item-price">
                                                        <div>
                                                            <span>$ {price}</span>
                                                        </div>
                                                        <div>X {quantity}</div>
                                                    </div>
                                                    {spec?.map(({ attrName, valueName }, specIndex) => (
                                                        <div className="info-item" key={specIndex}>
                                                            <label>{attrName}: </label>
                                                            <span>{valueName}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <>
                                                    <div className="quantity">Qty: {quantity}</div>
                                                    <div className="price">${price}</div>
                                                    <div>{type}</div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="left">
                                        {type !== 2 && <img src={image} alt="" />}
                                        <div className={type === 2 ? 'scanInfo' : 'info'}>
                                            <div className="title">{name}</div>
                                            {type === 1 ? (
                                                <div className="info-items">
                                                    {!virtual && (
                                                        <div className="info-item">
                                                            <label>Deposit: </label>
                                                            <span>${depositPrice}</span>
                                                        </div>
                                                    )}
                                                    <div className="info-item">
                                                        <label>Price: </label>
                                                        <span>$ {price}</span>
                                                    </div>
                                                    <div className="info-item time">
                                                        <Durations data={durations} />
                                                    </div>
                                                    {spec?.map(({ attrName, valueName }, specIndex) => (
                                                        <div className="info-item" key={specIndex}>
                                                            <label>{attrName}: </label>
                                                            <span>{valueName}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : type === 2 ? (
                                                <div>
                                                    <div className="scanPriceOrNum">
                                                        <div className="scanTitle">
                                                            $ {price} / {dayOrHour}
                                                        </div>
                                                        <div>X {quantity}</div>
                                                    </div>
                                                    <div className="scanEndTime">
                                                        <div className="scanTitle">Pay-To-Show Ends On</div>
                                                        <div className="scanTitle">{endRentTime}</div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    <div className="quantity">Qty: {quantity}</div>
                                                    <div className="price">${price}</div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )
                    )}
                </div>
                <div className={isEventOrder ? 'order-line' : 'line'} />
            </div>
            {isShowCouponCodeInput && (
                <div className="couponCode">
                    <CouponCode
                        ref={orderCodeRef}
                        productIds={productIds}
                        defaultValue={couponCodeInput}
                        onApply={onApply}
                    />
                    {isEventOrder ? <div className="order-line"></div> : ''}
                </div>
            )}
            <div className="moneyInfo">
                <div className="item">
                    <div className="label">Subtotal</div>
                    <div className="value">${orderPrice?.subTotal}</div>
                </div>
                {couponCodeValue && (
                    <>
                        <div className="item" style={{ marginTop: '4rem' }}>
                            <div className="label">Promo Discount</div>
                            <div className="value promoCodeValue">
                                {!isSelectedDelivery && orderPrice?.discount === '0.00'
                                    ? 'TBD'
                                    : `- $${orderPrice?.discount}`}
                            </div>
                        </div>
                    </>
                )}
                {isEventOrder
                    ? ''
                    : !virtual && (
                          <>
                              <div className="item">
                                  <div className="label">Shipping</div>
                                  <div className="value">
                                      {!isSelectedDelivery && orderPrice?.delivery === '0.00'
                                          ? 'TBD'
                                          : `$${orderPrice?.delivery}`}
                                  </div>
                              </div>
                              <div className="item">
                                  <div className="label salesTax">
                                      Sales Tax{' '}
                                      <Tooltip
                                          title="Your final taxes will be shown in Checkout."
                                          color="#000"
                                          trigger={['hover', 'click', 'focus']}
                                      >
                                          <img src={Circle} alt="" />
                                      </Tooltip>
                                  </div>
                                  <div className="value">
                                      {orderPrice?.tax === '0.00' ? 'TBD' : `$${orderPrice?.tax}`}
                                  </div>
                              </div>
                              {orderPrice?.depositTotal !== '0.00' && (
                                  <div className="item">
                                      <div className="label">Deposit</div>
                                      <div className="value">${orderPrice?.depositTotal}</div>
                                  </div>
                              )}
                          </>
                      )}
                <div className="line" />
            </div>
            <div className="total">
                <div className="label">Total</div>
                <div className="value">${orderPrice?.total}</div>
            </div>
        </Wrapper>
    )
}
const Wrapper = styled.header`
    width: 100%;
    background: #f5f5f5;
    .line,
    .order-line {
        width: 100%;
        height: 1px;
        background-color: rgba(47, 46, 46, 1);
    }
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 68px;
        padding: 0 3rem;
        border-bottom: 1px solid rgba(47, 46, 46, 1);
        h4 {
            margin-bottom: 0;
            font-family: Roboto-Medium;
            font-size: 24px;
            color: #000000;
            letter-spacing: 0;
            text-align: center;
            line-height: 28px;
            font-weight: 500;
        }
        img {
            width: 30px;
            height: 30px;
            cursor: pointer;
        }
    }
    .list {
        padding: 0 3rem;
        .item {
            display: flex;
            padding-top: 4rem;
        }
        .line {
            margin-top: 4rem;
        }
        .order-line {
            margin-top: 30px;
        }
        .left {
            display: flex;
            width: 100%;

            font-family: Roboto-Medium;
            font-size: 16px;
            color: #303030;
            letter-spacing: 0;
            line-height: 20px;
            font-weight: 600;
            img {
                width: 10rem;
                height: 10rem;
            }
            .billing-info {
                width: 100%;
            }
            .info {
                width: calc(100% - 60px);
                padding-left: 20px;
                > div {
                    margin-bottom: 1rem;
                }
            }
        }
        .price {
            width: auto;
            margin-left: auto;
        }
        .info-items {
            margin-top: 20px;
            font-weight: normal;
            font-size: 16px;
            .info-item {
                line-height: 18px;
                margin-bottom: 8px;
                label {
                    text-transform: capitalize;
                }
            }
            .info-item-price {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 8px;
            }
        }
    }
    .couponCode {
        /* height: 136px; */
        padding: 4rem 3rem 0;
    }
    .moneyInfo {
        position: relative;
        padding: 4rem 3rem 0 3rem;
        .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 4rem;
            font-family: Roboto-Regular;
            font-size: 18px;
            color: #000000;
            letter-spacing: 0;
            line-height: 20px;
            font-weight: 400;
            .salesTax {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                img {
                    width: 16px;
                    height: 16px;
                    margin-left: 11px;
                }
            }
        }
        .promoCodeValue {
            font-family: Roboto-Regular;
            font-size: 18px;
            color: #ce5324;
            letter-spacing: 0;
            line-height: 20px;
        }
    }
    .total {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 12.3rem;
        padding: 0 3rem;
        font-family: Roboto-Medium;
        font-size: 24px;
        color: #000000;
        letter-spacing: 0;
        line-height: 28px;
        font-weight: 500;
    }

    @media (max-width: 768px) {
        padding: 0;
        .header {
            padding: 0 26px;
            height: 67px;
            img {
                width: 20px;
                height: 20px;
            }
        }
        .list {
            padding: 0 26px;
            .item {
                padding-top: 30px;
            }
            .line {
                margin-top: 30px;
            }
            .left {
                width: 100%;

                img {
                    width: 100px;
                    height: 100px;
                }
                .info {
                    > div {
                        margin-bottom: 10px;
                    }
                }
            }
            .price {
                width: auto;
                padding-left: 0;
                text-align: left;
            }
        }
        .couponCode {
            padding: 30px 26px 0 26px;
            .form {
                margin-bottom: 30px;
            }
            .input {
                height: 30px;
                padding: 0 10px;
            }
            .apply {
                width: 80px;
                height: 40px;
            }
        }
        .moneyInfo {
            padding: 30px 26px 0 26px;
            .item {
                margin-bottom: 30px;
            }
        }
        .total {
            padding: 0 26px;
        }
        .scanTitle {
            font-family: PingFangSC-Semibold;
            font-size: 16px;
            color: #303030;
            line-height: 20px;
            font-weight: 600;
        }
        .scanPriceOrNum {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 20px 0;
        }
        .scanInfo {
            width: 100%;
        }
    }
`
