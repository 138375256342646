import { compose } from 'ramda'

import { clientHttp } from 'shared/request'
import { pathJoin } from 'shared/utils'
import { pickValue, SuccessItemResponse } from 'shared/request'
import { CheckoutRequestItem } from 'core/checkout/models'
import { convertToOrderPriceByCart, OrderPriceByCartDTO } from '../DTO'
import { personalInfoItem } from 'core/order/models'

const URI = '/buy'

export const calculate = (
    deliveryId: string,
    items: CheckoutRequestItem[],
    couponCode?: string,
    personalInfo?: personalInfoItem
) => {
    const url = pathJoin(URI, 'pre', 'order', 'price')
    return clientHttp
        .post<SuccessItemResponse<OrderPriceByCartDTO>>(url, { deliveryId, items, couponCode, personalInfo })
        .then(compose(convertToOrderPriceByCart, pickValue))
}
