import React, { FC, PropsWithChildren, CSSProperties } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import styled from '@emotion/styled'

interface ButtonBaseProps {
    className?: string
    style?: CSSProperties
    disabled?: boolean
}

const defaultTextStyle: CSSProperties = { padding: '0 40px', lineHeight: '50px' }

interface ButtonProps extends ButtonBaseProps {
    textStyle?: CSSProperties
    onClick?: () => void
}

export const Button: FC<PropsWithChildren<ButtonProps>> = ({
    children,
    onClick,
    textStyle,
    className = '',
    style,
    disabled = false
}) => {
    return (
        <Content
            className={`
                w-fit bg-gray-900 rounded-full hover:bg-black transition
                ${className}
                ${disabled ? 'disabled' : ''}
            `}
            style={style}
            onClick={() => !disabled && onClick?.()}
        >
            <div className="text-white" style={{ ...defaultTextStyle, ...textStyle }}>
                {children}
            </div>
        </Content>
    )
}

interface ButtonLinkProps extends ButtonBaseProps {
    to: LinkProps['to']
    textStyle?: CSSProperties
}

export const ButtonLink: FC<PropsWithChildren<ButtonLinkProps>> = ({
    children,
    to,
    textStyle,
    className = '',
    style,
    disabled = false
}) => {
    return (
        <Content
            className={`
                w-fit bg-gray-900 rounded-full hover:bg-black transition
                ${className}
                ${disabled ? 'disabled' : ''}
            `}
            style={style}
        >
            {disabled ? (
                <div className="text-white hover:text-white" style={{ ...defaultTextStyle, ...textStyle }}>
                    {children}
                </div>
            ) : (
                <Link
                    className="text-white hover:text-white"
                    style={{ ...defaultTextStyle, ...textStyle }}
                    to={to}
                >
                    {children}
                </Link>
            )}
        </Content>
    )
}

const Content = styled.div`
    height: 50px;
    font-family: Roboto-Medium;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    background-color: #000;
    cursor: pointer;
    &.disabled {
        background-color: #888c94;
        cursor: not-allowed;
    }
`
