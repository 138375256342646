import { useCallback } from 'react'

import { services } from 'core/management/frame/data-access'
import { useMountedPromiseFn, useDetailState } from 'shared/hooks'

import type { Frame } from '../model'

export const useFrameRentDetail = (id: string) => {
    const [state, setState] = useDetailState<Frame>()

    const getRentDetailApi = useMountedPromiseFn(services.getRentDetail)
    const getRentDetail = useCallback(() => {
        setState({ loading: true })
        return getRentDetailApi(id)
            .then(detail => {
                setState({ loading: false, detail })
                return detail
            })
            .catch(err => {
                if (err.data.msg === 'Invalid nft_id') {
                    err.data.msg = 'error.Invalid_nft_id'
                }
                setState({ loading: false })
                return Promise.reject(err)
            })
    }, [getRentDetailApi, id, setState])

    return {
        ...state,
        methods: { getRentDetail }
    }
}
