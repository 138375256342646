import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { assocPath, remove } from 'ramda'
import styled from '@emotion/styled'

import { usePlaylist } from 'core/management/frame/api/usePlaylist'
import { useWalletAddress } from 'core/walletaddress/api'
import { useApiError, useLoading, useMemoizedFn, useResponsive } from 'shared/hooks'

import { Flex, Image, Modal, Radio, SimpleGrid, Space, Text, TextProps } from '@mantine/core'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Switch } from 'antd'
import { Collapse, Popup } from 'antd-mobile'
import { CloseOutline } from 'antd-mobile-icons'
import { PageHeaderMobile, Button } from 'components'

import type { PlaylistFormData } from 'core/management/frame/model'
import addIcon from 'assets/add-simple.png'
import delIcon from 'assets/delete.png'
import settingIcon from 'assets/setting.png'
import collapseIcon from 'assets/collapse-arrow.png'
import addNftIcon from 'assets/add-nft.png'
import deleteImgIcon from 'assets/deleteImg.png'

import { RemovePlaylistModal } from './components/RemovePlaylistModal'
import { SelectPlaylistNft } from './components/SelectPlaylistNft'

import left_active from './assets/left_active.png'
import defaultImg from 'assets/default.png'
import closeImg from 'assets/closeImg.png'

interface PcSelect {
    id: string
    playlistId: number
    name: string
}
export const SelectPlaylist: FC = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const {
        methods: { handleError }
    } = useApiError('SelectPlaylist')
    const {
        list: walletList,
        loading: walletLoading,
        methods: { getWalletAddress }
    } = useWalletAddress()
    useEffect(() => {
        getWalletAddress().catch(handleError)
    }, [getWalletAddress, handleError])
    const walletaddress = useMemo(() => walletList[0]?.address, [walletList])
    const [selectId, setSelectId] = useState('')
    const location = useLocation()

    useEffect(() => {
        const locationState = location.state as {
            selectId: string
        }
        setSelectId(locationState.selectId)
    }, [location])

    const {
        list,
        loading: listLoading,
        methods: { getList, add, editName, removePlayList, edit }
    } = usePlaylist(walletaddress)

    useEffect(() => {
        getList().catch(handleError)
    }, [getList, handleError, walletaddress])

    const [activeKey, setActiveKey] = useState<string | null>(null)
    const [popupVisible, setPopupVisible] = useState(false)
    const [selectPlaylistNft, setSelectPlaylistNft] = useState(false)

    const inputRef = useRef<HTMLInputElement>(null)
    const [modalVisible, setModalVisible] = useState(false)
    const [storePlaylistType, setStorePlaylistType] = useState<'add' | 'edit'>('add')
    const [newPlaylistName, setNewPlaylistName] = useState('')
    const [saveDisabled, setSaveDisabled] = useState(true)
    const [playlistFormData, setPlaylistFormData] = useState<PlaylistFormData>()

    const handleStorePlaylist = useMemoizedFn(() => {
        if (!newPlaylistName) return
        ;(() => {
            if (storePlaylistType === 'add') {
                return add(newPlaylistName)
            }
            return editName(selectedPlayListId, newPlaylistName)
        })()
            .then(() => {
                setModalVisible(false)
                setNewPlaylistName('')
            })
            .catch(handleError)
    })
    const { md } = useResponsive()
    const [selectedPlayListId, setSelectedPlayListId] = useState(0)
    const [errTime, setErrTime] = useState(false)
    const [pcModal, setPcModal] = useState(false)
    const selectedPlayList = useMemo(
        () => list.find(({ id }) => id === selectedPlayListId),
        [list, selectedPlayListId]
    )

    useEffect(() => {
        setPcName(selectedPlayList?.name)
    }, [selectedPlayList])

    const [pcName, setPcName] = useState(selectedPlayList?.name)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [selectRadio, setSelectRadio] = useState<PcSelect>()

    const handleDeletePlaylist = useMemoizedFn(() => {
        removePlayList([{ id: selectedPlayListId }]).catch(handleError)
        setPopupVisible(false)
        if (md) setPcModal(false)
    })

    const pcSave = () => {
        if (!pcName) return
        if (playlistFormData && playlistFormData?.duration < 5) {
            return setErrTime(true)
        }
        editName(selectedPlayListId, pcName)
        edit(selectedPlayListId, playlistFormData!).then(() => {
            setPcModal(false)
            setPopupVisible(false)
        })
    }

    const donePlaylist = () => {
        if (selectRadio) {
            navigate(`/management/frames/${id}`, {
                replace: true,
                state: { id: selectRadio?.playlistId, name: selectRadio?.name }
            })
        } else {
            navigate(-1)
        }
    }

    useLoading(walletLoading || listLoading)

    return (
        <>
            <PlayListHeader>
                {md ? (
                    <div className="pcHeader">
                        <Flex h={28} align="center">
                            <img
                                onClick={() => navigate(-1)}
                                width={20}
                                height={'20px'}
                                src={left_active}
                                alt="frame"
                                style={{ cursor: 'pointer' }}
                            />
                            <div className="title">Playlist</div>
                        </Flex>
                        <Flex h={36}>
                            <HeaderBtn
                                className="cursor-pointer"
                                onClick={() => {
                                    setModalVisible(true)
                                    setStorePlaylistType('add')
                                    setTimeout(() => inputRef.current?.focus(), 200)
                                }}
                            >
                                Add
                            </HeaderBtn>
                            <HeaderBtn
                                className="cursor-pointer"
                                onClick={() => navigate(`/management/frames/${id}/playlist/delete`)}
                            >
                                Delete
                            </HeaderBtn>
                            <HeaderBtn
                                className="cursor-pointer"
                                onClick={e => {
                                    e.isDefaultPrevented()
                                    donePlaylist()
                                }}
                            >
                                Done
                            </HeaderBtn>
                        </Flex>
                    </div>
                ) : (
                    <PageHeaderMobile
                        title="Playlist"
                        titleClass="titleClass"
                        onBack={() => navigate(-1)}
                        rightNode={
                            <Flex>
                                <Image
                                    src={addIcon}
                                    width={24}
                                    height={24}
                                    onClick={() => {
                                        setModalVisible(true)
                                        setStorePlaylistType('add')
                                        setTimeout(() => inputRef.current?.focus(), 200)
                                    }}
                                />
                                <Space w={24} />
                                {list.length > 0 && (
                                    <Image
                                        src={delIcon}
                                        width={24}
                                        height={24}
                                        onClick={() => navigate(`/management/frames/${id}/playlist/delete`)}
                                    />
                                )}
                            </Flex>
                        }
                    />
                )}
            </PlayListHeader>

            <CollapseWrapper>
                <Radio.Group
                    className="flex-1"
                    name="radio"
                    value={`${selectId}`}
                    onChange={setSelectId}
                    withAsterisk
                >
                    <Collapse accordion activeKey={activeKey} onChange={setActiveKey}>
                        {list.map(({ id: playlistId, name, nfts, random, duration }) => (
                            <Collapse.Panel
                                arrow={
                                    <Image
                                        style={{ marginLeft: 10 }}
                                        src={collapseIcon}
                                        width={16}
                                        height={16}
                                    />
                                }
                                key={`${playlistId}`}
                                title={
                                    <Flex align="center" gap="sm" h={38}>
                                        <Flex
                                            justify="center"
                                            align="center"
                                            gap="lg"
                                            onClick={() =>
                                                setSelectRadio({
                                                    id: id!,
                                                    playlistId,
                                                    name
                                                })
                                            }
                                        >
                                            <Radio
                                                mt={10}
                                                value={`${playlistId}`}
                                                styles={{ radio: { border: '1px solid #000' } }}
                                                onClick={e => {
                                                    e.stopPropagation()
                                                    setSelectRadio({
                                                        id: id!,
                                                        playlistId,
                                                        name
                                                    })
                                                }}
                                            />
                                            <Title lineClamp={1}>{name}</Title>
                                        </Flex>
                                        {activeKey === `${playlistId}` && (
                                            <Image
                                                src={settingIcon}
                                                width={16}
                                                height={16}
                                                onClick={e => {
                                                    e.stopPropagation()
                                                    md ? setPcModal(true) : setPopupVisible(true)
                                                    setSelectedPlayListId(playlistId)
                                                    setPlaylistFormData({
                                                        random,
                                                        duration,
                                                        nfts: nfts.map(({ nft_id, preview_url, order }) => ({
                                                            nft_id,
                                                            preview_url,
                                                            order
                                                        }))
                                                    })
                                                }}
                                            />
                                        )}
                                    </Flex>
                                }
                            >
                                <SimpleGrid cols={md ? 16 : 4}>
                                    {nfts.map(({ id: nftId, preview_url }) => (
                                        <Image
                                            key={nftId}
                                            src={preview_url}
                                            width={74}
                                            height={74}
                                            fit="contain"
                                            withPlaceholder
                                            placeholder={
                                                <img width={74} height={74} src={defaultImg} alt="NFT" />
                                            }
                                        />
                                    ))}
                                </SimpleGrid>
                                <PlaylistRow style={md ? { width: '326px' } : {}}>
                                    <Label>Transition Time</Label>
                                    <Value>{duration} sec</Value>
                                </PlaylistRow>
                                <PlaylistRow style={md ? { width: '326px' } : {}}>
                                    <Label>Shuffle Play</Label>
                                    <Value>
                                        <Switch
                                            style={{ transform: 'scale(1.5)' }}
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            checked={random}
                                            disabled
                                        />
                                    </Value>
                                </PlaylistRow>
                            </Collapse.Panel>
                        ))}
                    </Collapse>
                </Radio.Group>
                {!md && list.length > 0 && (
                    <DoneButton
                        className="cursor-pointer"
                        onClick={e => {
                            e.isDefaultPrevented()
                            donePlaylist()
                        }}
                    >
                        Done
                    </DoneButton>
                )}
            </CollapseWrapper>
            <Modal
                zIndex={1200}
                trapFocus={false}
                overlayColor="rgba(0,0,0,0.4)"
                centered
                title={
                    <div className="flex justify-between">
                        <div />
                        <div>Add Playlist</div>
                        <div>
                            <img
                                width={24}
                                height={24}
                                src={closeImg}
                                onClick={() => {
                                    setModalVisible(false)
                                    setNewPlaylistName('')
                                }}
                                alt="closeImg"
                            />
                        </div>
                    </div>
                }
                withCloseButton={true}
                closeOnClickOutside={false}
                opened={modalVisible}
                onClose={() => {
                    setModalVisible(false)
                    setNewPlaylistName('')
                }}
                styles={{
                    title: {
                        fontFamily: 'Roboto-Medium',
                        fontSize: md ? '30px' : '24px',
                        color: '#000000',
                        textAlign: 'center',
                        marginRight: 0,
                        flex: '1'
                    },
                    modal: {
                        borderRadius: '20px',
                        width: '520px'
                        // height: '300px'
                    },
                    close: {
                        display: 'none'
                    }
                }}
            >
                <Flex direction="column" justify="center" align="center" px={md ? 20 : 8} py={14}>
                    <AddModalTitle>Enter New Playlist Name</AddModalTitle>
                    <AddInput
                        ref={inputRef}
                        value={newPlaylistName}
                        onChange={({ target: { value } }) => {
                            if (value && value.trim()) {
                                setNewPlaylistName(value)
                                setSaveDisabled(false)
                            } else {
                                setNewPlaylistName('')
                                setSaveDisabled(true)
                            }
                        }}
                    />
                    <PlayListAddButton
                        className="cursor-pointer"
                        onClick={handleStorePlaylist}
                        disabled={saveDisabled}
                    >
                        Save
                    </PlayListAddButton>
                </Flex>
            </Modal>
            <Popup
                visible={popupVisible}
                bodyStyle={{
                    height: '92%',
                    borderRadius: '14px 14px 0 0',
                    overflow: 'auto',
                    paddingBottom: '60px'
                }}
            >
                <PopupHeader>
                    <div className="titleName">{selectedPlayList?.name}</div>
                    <CloseOutline onClick={() => setPopupVisible(false)} style={{ fontSize: '24px' }} />
                </PopupHeader>

                <PopupContent>
                    <SimpleGrid cols={4}>
                        {playlistFormData?.nfts.map(({ preview_url, order }, index) => (
                            <div key={order} style={{ width: '74px', height: '74px', position: 'relative' }}>
                                <Image
                                    className="positionImg"
                                    src={preview_url}
                                    width={74}
                                    height={74}
                                    fit="contain"
                                    withPlaceholder
                                />
                                <img
                                    className="positionImg"
                                    src={deleteImgIcon}
                                    width={13}
                                    height={13}
                                    alt="nft"
                                    onClick={() => {
                                        setPlaylistFormData(prev =>
                                            assocPath(['nfts'], remove(index, 1, prev?.nfts!), prev)
                                        )
                                    }}
                                />
                            </div>
                        ))}
                        <Image
                            onClick={() => setSelectPlaylistNft(true)}
                            src={addNftIcon}
                            width={74}
                            height={74}
                            fit="contain"
                        />
                    </SimpleGrid>
                    <PcItem>
                        <div className="title">Playlist Name</div>
                        <Value className="flex items-center">
                            <input
                                className="input"
                                type="text"
                                value={pcName}
                                onChange={({ target: { value } }) => {
                                    setPcName(value)
                                }}
                            />
                        </Value>
                    </PcItem>
                    <PcItem>
                        <div className="title">Transition Time</div>
                        <div className="input inputDiv">
                            <input
                                type="text"
                                value={`${playlistFormData?.duration}`}
                                onChange={({ target: { value } }) =>
                                    setPlaylistFormData(prev => assocPath(['duration'], value, prev))
                                }
                            />
                            <span>sec</span>
                        </div>
                    </PcItem>
                    <PlaylistRow style={{ width: '97%' }}>
                        <Label>Shuffle Play</Label>
                        <Value>
                            <Switch
                                style={{ transform: 'scale(1.5)' }}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={playlistFormData?.random}
                                onChange={() =>
                                    setPlaylistFormData(prev => assocPath(['random'], !prev?.random, prev))
                                }
                            />
                        </Value>
                    </PlaylistRow>
                    <PlaylistRow>
                        <ButtonRemovePlaylist onClick={() => setDeleteModalVisible(true)}>
                            Delete
                        </ButtonRemovePlaylist>
                        <ButtonRemovePlaylist
                            style={{ background: '#000', color: '#FFF', marginLeft: '20px' }}
                            onClick={pcSave}
                        >
                            Save
                        </ButtonRemovePlaylist>
                    </PlaylistRow>
                </PopupContent>
            </Popup>
            <RemovePlaylistModal
                visible={deleteModalVisible}
                setVisible={setDeleteModalVisible}
                onDelete={handleDeletePlaylist}
            />
            <SelectPlaylistNft
                playlistFormData={playlistFormData!}
                setPlaylistFormData={setPlaylistFormData}
                visible={selectPlaylistNft}
                setVisible={setSelectPlaylistNft}
            />
            <Modal
                zIndex={1200}
                onClose={() => setErrTime(false)}
                title={
                    <div className="flex justify-end">
                        <div>
                            <img
                                width={24}
                                height={24}
                                src={closeImg}
                                onClick={() => setErrTime(false)}
                                alt="closeImg"
                            />
                        </div>
                    </div>
                }
                opened={errTime}
                centered
                styles={{
                    title: {
                        width: '100%',
                        marginRight: 0
                    },
                    close: {
                        display: 'none'
                    }
                }}
            >
                <Flex direction="column" justify="center" align="center" py={24}>
                    <AddTitle>{'Transition Time need to be >= 5 seconds'}</AddTitle>
                    <AddButton style={{ marginTop: '45px' }} onClick={() => setErrTime(false)}>
                        OK
                    </AddButton>
                </Flex>
            </Modal>
            <Modal
                onClose={() => setPcModal(false)}
                opened={pcModal}
                centered
                overlayColor="rgba(0,0,0,0.4)"
                styles={{ header: { display: 'none' }, modal: { width: '530px', borderRadius: '20px' } }}
            >
                <PcPopupContent>
                    <Text className="modalTitle" lineClamp={1}>
                        {selectedPlayList?.name}
                    </Text>
                    <SimpleGrid cols={5}>
                        {playlistFormData?.nfts.map(({ preview_url, order }, index) => (
                            <div key={order} style={{ width: '74px', height: '74px', position: 'relative' }}>
                                <Image
                                    className="positionImg"
                                    src={preview_url}
                                    width={74}
                                    height={74}
                                    fit="contain"
                                    withPlaceholder
                                />
                                <img
                                    className="positionImg"
                                    src={deleteImgIcon}
                                    width={13}
                                    height={13}
                                    alt="nft"
                                    onClick={() => {
                                        setPlaylistFormData(prev =>
                                            assocPath(['nfts'], remove(index, 1, prev?.nfts!), prev)
                                        )
                                    }}
                                />
                            </div>
                        ))}
                        <Image
                            onClick={() => setSelectPlaylistNft(true)}
                            src={addNftIcon}
                            width={74}
                            height={74}
                            fit="contain"
                        />
                    </SimpleGrid>
                    <PcItem>
                        <div className="title">Playlist Name</div>
                        <Value className="flex items-center">
                            <input
                                className="input"
                                type="text"
                                value={pcName}
                                onChange={({ target: { value } }) => {
                                    setPcName(value)
                                }}
                            />
                        </Value>
                    </PcItem>
                    <PcItem>
                        <div className="title">Transition Time</div>
                        <div className="input inputDiv">
                            <input
                                type="text"
                                value={`${playlistFormData?.duration}`}
                                onChange={({ target: { value } }) =>
                                    setPlaylistFormData(prev => assocPath(['duration'], value, prev))
                                }
                            />
                            <span>sec</span>
                        </div>
                    </PcItem>
                    <div className="modalSwith">
                        <div className="title">Shuffle Play</div>
                        <Value>
                            <Switch
                                style={{ transform: 'scale(1.5)' }}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={playlistFormData?.random}
                                onChange={() =>
                                    setPlaylistFormData(prev => assocPath(['random'], !prev?.random, prev))
                                }
                            />
                        </Value>
                    </div>
                    <div className="btn">
                        <ButtonRemovePlaylistPc onClick={() => setDeleteModalVisible(true)}>
                            Delete
                        </ButtonRemovePlaylistPc>
                        <ButtonRemovePlaylistPc
                            style={{ background: '#000', color: '#FFF', marginLeft: '20px' }}
                            onClick={pcSave}
                        >
                            Save
                        </ButtonRemovePlaylistPc>
                    </div>
                </PcPopupContent>
            </Modal>
        </>
    )
}
const PlayListAddButton = styled(Button)`
    width: 378px;
    height: 50px;
    line-height: 50px;
    background: #000000;
    border-radius: 24.5px;
    font-family: OpenSans-SemiBold;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    font-weight: 600;
    margin: 20px 0 27px 0;
    @media (max-width: 768px) {
        width: 222px;
        margin: 0 0 7px 0;
        font-size: 16px;
    }
`
const DoneButton = styled.button`
    width: 222px;
    height: 50px;
    background: #000000;
    border-radius: 35.18px;
    font-family: OpenSans-SemiBold;
    font-size: 16px;
    color: #ffffff;
    line-height: 50px;
    text-align: center;
    font-weight: 600;
    margin: 30px auto 50px;
`

const HeaderBtn = styled.div`
    width: 100px;
    height: 36px;
    border: 1px solid rgba(0, 0, 0, 1);
    border-radius: 22px;
    font-family: Roboto-Medium;
    font-size: 18px;
    color: #000000;
    text-align: center;
    line-height: 36px;
    font-weight: 500;
    margin-left: 24px;
`

const PcItem = styled.div`
    margin-top: 30px;
    .title {
        font-family: Roboto-Regular;
        font-size: 18px;
        color: #000000;
        letter-spacing: 0;
        line-height: 26px;
        font-weight: 400;
        margin-bottom: 10px;
    }
    .inputDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        input {
            height: 36px;
            outline: none;
            width: 90%;
        }
    }
    .input {
        width: 100%;
        height: 40px;
        border: 1px solid rgba(0, 0, 0, 1);
        font-family: Roboto-Medium;
        font-size: 16px;
        color: #000000;
        line-height: 40px;
        font-weight: 500;
        padding: 0 10px;
        outline: none;
        border-radius: 0;
    }
`
const ButtonRemovePlaylistPc = styled.div`
    width: 198px;
    height: 50px;
    border: 1.41px solid rgba(0, 0, 0, 1);
    border-radius: 35.18px;
    font-family: Roboto-Medium;
    font-size: 25.33px;
    color: #000000;
    text-align: center;
    line-height: 50px;
    font-weight: 500;
`

const PcPopupContent = styled.div`
    width: 100%;
    padding: 0px 60px;
    .modalSwith {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
        .title {
            font-family: Roboto-Regular;
            font-size: 18px;
            color: #000000;
            letter-spacing: 0;
            line-height: 26px;
            font-weight: 400;
        }
    }
    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 60px;
        margin-bottom: 50px;
    }
    .modalTitle {
        font-family: Roboto-Medium;
        font-size: 30px;
        color: #000000;
        text-align: center;
        line-height: 34px;
        font-weight: 500;
        margin-bottom: 50px;
        line-clamp: 1;
    }
    .positionImg {
        position: absolute;
        top: 0;
        left: 0;
    }
`

const PlayListHeader = styled.div`
    /* margin: 5px 0; */
    .titleClass {
        text-align: left !important;
        margin-left: 10px;
    }
    .pcHeader {
        width: 100%;
        height: 82px;
        display: flex;
        justify-content: space-between;
        text-align: center;
        padding: 30px 0 16px 0;
    }
    .title {
        font-family: Roboto-Medium;
        font-size: 24px;
        color: #000000;
        letter-spacing: 0;
        line-height: 29px;
        font-weight: 500;
        margin-left: 10px;
    }
    @media (max-width: 768px) {
        margin: 5px 0;
    }
`
const ButtonRemovePlaylist = styled.div`
    margin: 80px auto;
    width: 150px;
    height: 50px;
    line-height: 50px;
    border-radius: 24.5px;
    font-family: OpenSans-SemiBold;
    font-size: 16px;
    color: #000000;
    text-align: center;
    font-weight: 600;
    border: 1.41px solid rgba(0, 0, 0, 1);
    border-radius: 35.18px;
`

const PopupContent = styled.div`
    width: 100%;
    height: 300px;
    background-color: #fff;
    padding: 40px 25px;
    .positionImg {
        position: absolute;
        top: 0;
        left: 0;
    }
`

const PopupHeader = styled.div`
    box-shadow: 0px 0.33px 0px 0px rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    .titleName {
        font-family: Roboto-Medium;
        font-size: 24px;
        color: #000000;
        text-align: center;
        line-height: 28px;
        font-weight: 500;
        flex: 1;
        text-align: center;
    }
    .buttonText {
        font-family: AppleSystemUIFont;
        font-size: 17px;
        color: #0a7aff;
        letter-spacing: -0.41px;
        cursor: pointer;
    }
`

const Title = styled(Text)<TextProps>`
    font-family: OpenSans-Bold;
    font-size: 16px;
    color: #000000;
    font-weight: 700;
`

const Value = styled.div`
    font-family: Roboto-Bold;
    font-size: 16px;
    color: #000000;
    line-height: 16px;
    font-weight: 700;

    .anticon {
        vertical-align: 0 !important;
    }
`

const Label = styled.div`
    font-family: OpenSans-SemiBold;
    font-size: 16px;
    color: #838383;
    font-weight: 600;
`

const PlaylistRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 28px 0;
`

const AddButton = styled.div`
    width: 222px;
    height: 50px;
    line-height: 50px;
    background: #000000;
    border-radius: 24.5px;
    font-family: OpenSans-SemiBold;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    font-weight: 600;
    margin-bottom: 26px;
`

const AddInput = styled.input`
    width: 100%;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-bottom: 30px;
    padding: 0 10px;
    outline: none;
    border-radius: 0;
    /* margin: 16px 0 45px 0;
    width: 229px;
    height: 50px;
    line-height: 50px;
    background: #f1f1f1;
    border-radius: 24.5px;
    font-family: OpenSans-SemiBold;
    font-size: 16px;
    color: #000000;
    text-align: center;
    font-weight: 600;
    outline: none; */
`
const AddModalTitle = styled.div`
    width: 100%;
    font-family: Roboto-Regular;
    font-size: 18px;
    color: #000000;
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
    text-align: left;
`

const AddTitle = styled.div`
    width: 100%;
    font-family: Roboto-Regular;
    font-size: 18px;
    color: #000000;
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
    text-align: center;
`

const CollapseWrapper = styled.div`
    min-height: calc(100vh - 68px - 90px - 53px);
    display: flex;
    flex-direction: column;
    padding: 0;
    @media (max-width: 768px) {
        padding: 0 20px;
    }
    .adm-collapse-panel-header:first-of-type {
        border-top: 1px solid rgba(47, 46, 46, 1) !important;
        margin-top: 2px !important;
        @media (max-width: 768px) {
            border-top: solid 1px rgba(151, 151, 151, 1) !important;
        }
    }
    .adm-collapse-panel-header:nth-last-of-type(1) {
        border-top: solid 1px rgba(151, 151, 151, 1) !important;
        border-bottom: solid 1px rgba(151, 151, 151, 1) !important;
        margin-top: 2px;
    }
    .adm-collapse-panel-header {
        border-top: solid 1px rgba(151, 151, 151, 1) !important;
        margin-top: 2px;
    }
    .adm-list {
        --border-top: none !important;
        --border-inner: none !important;
        /* --border-inner: solid 1px #979797; */
    }
    .mantine-16twdu0:checked {
        background: #000 !important;
        border-color: #000 !important;
    }
    .adm-collapse {
        width: 100% !important;
    }
`
