import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { actions, selectors } from 'core/cart/state'

export const useMiniCartDrawer = () => {
    const dispatch = useDispatch()
    const miniCartDrawerVisible = useSelector(selectors.getMiniCartDrawerVisible)

    const updateMiniCartDrawerVisible = useCallback(
        (miniCartDrawerVisible: boolean) => {
            dispatch(actions.updateMiniCartDrawerVisible({ miniCartDrawerVisible }))
        },
        [dispatch]
    )

    return {
        miniCartDrawerVisible,
        methods: {
            updateMiniCartDrawerVisible
        }
    }
}
