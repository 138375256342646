import { services } from 'core/management/playlist/data-access'
import { useListState, useMemoizedFn, useMountedPromiseFn } from 'shared/hooks'

import type { Playlist, delParams, PlaylistFormData } from '../model'

export const usePlaylist = (walletaddress: string) => {
    const [state, setState] = useListState<Playlist>()

    const getListApi = useMountedPromiseFn(services.getList)
    const getList = useMemoizedFn(() => {
        setState({ loading: true })
        return getListApi(walletaddress)
            .then(list => {
                setState({ loading: false, list })
                return list
            })
            .catch(err => {
                setState({ loading: false })
                return Promise.reject(err)
            })
    })

    const addApi = useMountedPromiseFn(services.add)
    const add = useMemoizedFn((name: string) => {
        setState({ loading: true })
        return addApi(name)
            .then(getList)
            .catch(err => {
                setState({ loading: false })
                return Promise.reject(err)
            })
    })

    const removeApi = useMountedPromiseFn(services.remove)
    const removePlayList = useMemoizedFn((params: delParams[]) => {
        setState({ loading: true })
        return removeApi(params)
            .then(getList)
            .catch(err => {
                setState({ loading: false })
                return Promise.reject(err)
            })
    })

    const editNameApi = useMountedPromiseFn(services.editName)
    const editName = useMemoizedFn((id: number, name: string) => {
        setState({ loading: true })
        return editNameApi(id, name)
            .then(getList)
            .catch(err => {
                setState({ loading: false })
                return Promise.reject(err)
            })
    })

    const editApi = useMountedPromiseFn(services.edit)
    const edit = useMemoizedFn((id: number, params: PlaylistFormData) => {
        setState({ loading: true })
        return editApi(id, params)
            .then(getList)
            .catch(err => {
                setState({ loading: false })
                return Promise.reject(err)
            })
    })

    return {
        ...state,
        methods: {
            getList,
            add,
            removePlayList,
            editName,
            edit
        }
    }
}
