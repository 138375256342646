import { useCallback } from 'react'

import { services } from 'core/management/frame/data-access'
import { useMountedPromiseFn, useDetailState, useMemoizedFn } from 'shared/hooks'

import type { Frame } from '../model'

export const useFrameDetail = (id: string) => {
    const [state, setState] = useDetailState<Frame>()

    const getDetailApi = useMountedPromiseFn(services.getDetail)
    const getDetail = useCallback(() => {
        setState({ loading: true })
        return getDetailApi(id)
            .then(detail => {
                setState({ loading: false, detail })
                return detail
            })
            .catch(err => {
                if (err.data.msg === 'Invalid nft_id') {
                    err.data.msg = 'error.Invalid_nft_id'
                }
                setState({ loading: false })
                return Promise.reject(err)
            })
    }, [getDetailApi, id, setState])

    const playApi = useMountedPromiseFn(services.play)
    const play = useMemoizedFn((params: Parameters<typeof playApi>[0]) => {
        setState({ loading: true })
        return playApi(params)
            .then(getDetail)
            .catch(err => {
                setState({ loading: false })
                return Promise.reject(err)
            })
    })

    const playSlideApi = useMountedPromiseFn(services.playSlide)
    const playSlide = useMemoizedFn((params: Parameters<typeof playSlideApi>[0]) => {
        setState({ loading: true })
        return playSlideApi(params)
            .then(getDetail)
            .catch(err => {
                setState({ loading: false })
                return Promise.reject(err)
            })
    })

    return {
        ...state,
        methods: { getDetail, play, playSlide }
    }
}
