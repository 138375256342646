export const copy = (value: string) => {
    const input = document.createElement('input')
    input.id = 'copy_input'
    input.style.position = 'fixed'
    input.style.top = '0'
    input.style.left = '0'
    input.style.border = 'none'
    input.style.outline = 'none'
    input.style.resize = 'none'
    input.style.background = 'transparent'
    input.style.color = 'transparent'
    input.value = value // Modify the content of the text box
    document.body.appendChild(input)

    if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
        // Distinguish iPhone devices
        window.getSelection()?.removeAllRanges() // This code must be placed in front or it is invalid
        var orderCodeDom = document.getElementById('copy_input') // The node to copy the text to
        var range = document.createRange()
        // Select the node to be copied
        orderCodeDom && range.selectNode(orderCodeDom)
        // Execute the selected element
        window.getSelection()?.addRange(range)
        // Perform a copy operation
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        var successful = document.execCommand('copy')

        // Remove selected elements
        window.getSelection()?.removeAllRanges()
    } else {
        input.select() // Select text
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const msg = document.execCommand('copy') ? 'successful' : 'unsuccessful'
        } catch (err) {
            console.log('unable to copy', err)
        }
    }
    document.body.removeChild(input)
}
