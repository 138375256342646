import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'

import rightOutlined from 'assets/right-outlined.png'

export const Header: FC = () => {
    const navigate = useNavigate()
    return (
        <Wrapper>
            <h1>CHECKOUT</h1>
            <div onClick={() => navigate('/products')}>
                Continue Shopping
                <img src={rightOutlined} alt="" />
            </div>
        </Wrapper>
    )
}
const Wrapper = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 6.8rem;
    border-bottom: 1px solid rgba(47, 46, 46, 1);
    font-family: Roboto-Medium;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    font-weight: 500;
    h1 {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 0;
        img {
            width: 57px;
            height: 57px;
            margin-right: 25px;
        }
    }
    div {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 18px;
        line-height: 20px;
        cursor: pointer;
        img {
            width: 20px;
            height: 20px;
            margin-left: 10px;
        }
    }
    @media (max-width: 768px) {
        height: 68px;
        h1 {
            img {
                margin-right: 5px;
            }
        }
        div {
            font-size: 16px;
        }
    }
`
