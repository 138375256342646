import React from 'react'

interface Props {
    title: string
    context: string
}
export const ShowSingleButtonModalMessage: React.FC<Props> = ({ title, context }) => {
    return (
        <div>
            <div>{title}</div>
            <br />
            <div>{context}</div>
        </div>
    )
}
