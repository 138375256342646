import React, { FC } from 'react'

import styled from '@emotion/styled'

import one from 'assets/one.png'
import two from 'assets/two.png'
import { EventOrderEnum } from 'core/event/models'
import { getIsTourist, getToken } from 'shared/config'

export interface CreateStepsProps {
    step: EventOrderEnum
}

export const CreateSteps: FC<CreateStepsProps> = ({ step }) => {
    return (
        <Steps>
            {getToken() && !getIsTourist() ? (
                <StepBilling>
                    <span className={step === EventOrderEnum.BILLING ? '' : 'billingsteps'}>
                        Billing information
                    </span>
                </StepBilling>
            ) : (
                <>
                    <Step>
                        <img src={one} alt="" />
                        <span className={step === EventOrderEnum.PERSONAL ? '' : 'steps'}>
                            Personal information
                        </span>
                    </Step>
                    <Line />
                    <Step>
                        <img src={two} alt="" />
                        <span className={step === EventOrderEnum.BILLING ? '' : 'steps'}>
                            Billing information
                        </span>
                    </Step>
                </>
            )}
        </Steps>
    )
}

const Steps = styled.div`
    display: flex;
    @media (max-width: 576px) {
        flex-direction: column;
        margin-top: 25px;
    }
`
const Step = styled.div`
    display: flex;
    img {
        width: 30px;
        height: 30px;
    }
    span {
        font-family: HelveticaNeue-CondensedBold;
        font-size: 30px;
        color: #000000;
        letter-spacing: 0;
        line-height: 30px;
        font-weight: bold;
        margin-left: 10px;
        @media (max-width: 576px) {
            font-family: HelveticaNeue-CondensedBold;
            font-size: 24px;
        }
    }
    .steps {
        margin-left: 10px;
        opacity: 0.5;
    }
    .billingsteps {
        margin: 0 !important;
    }
`

const StepBilling = styled.div`
    span {
        font-family: HelveticaNeue-CondensedBold;
        font-size: 30px;
        color: #000000;
        letter-spacing: 0;
        line-height: 30px;
        font-weight: bold;
        @media (max-width: 576px) {
            font-family: HelveticaNeue-CondensedBold;
            font-size: 24px;
        }
    }
    .billingsteps {
        margin: 0 !important;
    }
`

const Line = styled.div`
    width: 44px;
    border-top: 1px solid rgba(0, 0, 0, 1);
    margin: 13px 13px 0 13px;
    @media (max-width: 576px) {
        opacity: 0;
        margin: 0;
        height: 25px;
    }
`
