import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectors } from 'core/cart/state'
import { setStoreList } from 'shared/config'

export const useSetLocalStorageStoreList = () => {
    const list = useSelector(selectors.getList)
    useEffect(() => {
        const listener = () => {
            setStoreList(list)
        }
        window.addEventListener('visibilitychange', listener)
        return () => {
            window.removeEventListener('visibilitychange', listener)
        }
    }, [list])
}
