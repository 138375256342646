import React, { FC, useCallback, useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { animateScroll as scroll } from 'react-scroll'
import { logEvent } from 'firebase/analytics'

import { useI18n } from 'core/language/api'
import { useHotProducts } from 'core/product/api'
import { useCartList, useMiniCartDrawer } from 'core/cart/api'
import { TAB_TYPE_ENUM } from 'core/aff/models'
import { getIsTourist, getIsGoogleUser } from 'shared/config'
import { useMemoizedFn, useResponsive, useSignInRedirect } from 'shared/hooks'
import { analytics } from 'shared/utils'

import { Drawer } from 'antd'
import { HeaderDropdown } from 'components'
import { MiniCart } from 'modules/cart/mini'

import closeIcon from './assets/close-icon.png'
import hamburger from './assets/hamburger.png'
import cartIcon from './assets/cart.svg'
import userIcon from './assets/user-icon.png'
import downOutlined from './assets/down-outlined.png'
import logo from './assets/logo.png'
import download from 'assets/download.png'

export const Header: FC = () => {
    const {
        methods: { t }
    } = useI18n()
    const { getToken } = useSignInRedirect()
    const { md, xs } = useResponsive()
    const { pathname } = useLocation()
    const navigate = useNavigate()

    const [showNav, setShowNav] = useState(false)
    const handleClassName = useMemoizedFn(({ isActive }: { isActive: boolean }) =>
        isActive ? 'activeLink link' : 'link'
    )
    useEffect(() => {
        xs && setShowNav(false)
    }, [pathname, xs])

    const {
        list,
        methods: { getList }
    } = useHotProducts()
    useEffect(() => {
        getList().catch()
    }, [getList])

    const [isShowDown, setIsShowDown] = useState<string>('')
    const handleDown = useCallback((e, name) => {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
        setIsShowDown(state => {
            if (state === name) {
                return ''
            } else {
                return name
            }
        })
    }, [])

    const stopPropagation = useCallback(e => {
        e.nativeEvent.stopImmediatePropagation()
        e.stopPropagation()
    }, [])

    const hideNavHandle = useCallback(() => {
        setShowNav(false)
    }, [])

    const homeNavHandle = useCallback(() => {
        hideNavHandle()
        setTimeout(() =>
            window.scrollTo({
                top: 0
            })
        )
    }, [hideNavHandle])

    // Shopping cart quantity change: login; logout; add cart; reduce cart; delete cart; Place an order;
    const {
        cartNumber,
        methods: { getList: getCartList }
    } = useCartList()

    useEffect(() => {
        getCartList && getCartList()
    }, [getCartList])

    const {
        miniCartDrawerVisible,
        methods: { updateMiniCartDrawerVisible }
    } = useMiniCartDrawer()

    const showDrawer = () => {
        if (md) {
            updateMiniCartDrawerVisible(true)
        } else {
            logEvent(analytics, 'view_cart')
            navigate('/cart')
        }
    }

    const handleDrawerClose = () => {
        updateMiniCartDrawerVisible(false)
    }

    // const handleOutLogin = useCallback(() => {
    //     Dialog.confirm({
    //         content: t('client.me.isLogout'),
    //         cancelText: t('form.actions.cancel'),
    //         confirmText: t('form.actions.OK'),
    //         onConfirm: () => {
    //             setShowNav(false)
    //             loginOut('/')
    //         }
    //     })
    // }, [loginOut, t])

    return (
        <>
            <HeaderWrapper className="flex justify-center items-center overflow-hidden">
                <HeaderContent>
                    <div className="cursor-pointer block md:hidden">
                        <img className="hamburger" onClick={() => setShowNav(true)} src={hamburger} alt="" />
                    </div>
                    <NavLink className="mr-0 md:mr-8" to="/">
                        <img className="logo" src={logo} alt="" />
                    </NavLink>
                    {md && (
                        <NavLinksWeb>
                            <NavLink className={handleClassName} onClick={() => scroll.scrollToTop()} to="/">
                                {t('Home')}
                            </NavLink>
                            <NavLink className={handleClassName} to="/products">
                                {list?.length ? (
                                    <HeaderDropdown
                                        items={list.map(({ id, keyWord }) => ({
                                            to: `/products/${id}`,
                                            text: keyWord
                                        }))}
                                    >
                                        {t('Shop')}
                                    </HeaderDropdown>
                                ) : (
                                    t('Shop')
                                )}
                            </NavLink>

                            <NavLink className={handleClassName} to="/rental">
                                {t('Rent')}
                            </NavLink>
                            <NavLink className={handleClassName} to="/event">
                                {t('Event')}
                            </NavLink>
                            <NavLink className={handleClassName} to="/contact">
                                {t('Contact')}
                            </NavLink>
                            <NavLink className={handleClassName} to="/management">
                                {t('Management')}
                            </NavLink>
                            <NavLink
                                className={handleClassName}
                                to="/ad-statistics"
                                style={{ width: '100px' }}
                            >
                                {t('Ads Statistics')}
                            </NavLink>
                            <NavLink
                                className={handleClassName}
                                to="/support/returns"
                                style={{ maxWidth: '160px' }}
                                onClick={e => e.preventDefault()}
                            >
                                <HeaderDropdown
                                    items={[
                                        {
                                            to: `/support/returns`,
                                            text: 'Returns'
                                        }
                                    ]}
                                >
                                    {t('Support')}
                                </HeaderDropdown>
                            </NavLink>
                            <NavLink className={handleClassName} to="/to_download">
                                <HeaderDownload>{t('Download')}</HeaderDownload>
                            </NavLink>
                        </NavLinksWeb>
                    )}
                    {!md && (
                        <NavLinksMobile className={`${showNav ? 'showNav' : ''}`} onClick={hideNavHandle}>
                            <div className="container" onClick={stopPropagation}>
                                <div className="nav-link-wrapper">
                                    <NavLink className={handleClassName} to="/" onClick={homeNavHandle}>
                                        {t('Home')}
                                    </NavLink>
                                    <div className="dropdownWrapper">
                                        <NavLink
                                            className={handleClassName}
                                            to="/products"
                                            onClick={hideNavHandle}
                                        >
                                            {t('Shop')}
                                        </NavLink>
                                        <img
                                            src={downOutlined}
                                            className={`${isShowDown === 'shop' ? 'active' : ''}`}
                                            onClick={e => handleDown(e, 'shop')}
                                            alt=""
                                        />
                                        <div className={`list ${isShowDown === 'shop' ? 'active' : ''}`}>
                                            {list.map(({ id, keyWord }) => (
                                                <NavLink
                                                    key={id}
                                                    className={handleClassName}
                                                    to={`/products/${id}`}
                                                    onClick={hideNavHandle}
                                                >
                                                    {keyWord}
                                                </NavLink>
                                            ))}
                                        </div>
                                    </div>
                                    <NavLink className={handleClassName} to="/rental" onClick={hideNavHandle}>
                                        {t('Rent')}
                                    </NavLink>
                                    <NavLink className={handleClassName} to="/event">
                                        {t('Event')}
                                    </NavLink>
                                    <NavLink
                                        className={handleClassName}
                                        to="/contact"
                                        onClick={hideNavHandle}
                                    >
                                        {t('Contact')}
                                    </NavLink>
                                    <NavLink className={handleClassName} to="/management">
                                        {t('Management')}
                                    </NavLink>
                                    <NavLink className={handleClassName} to="/ad-statistics">
                                        {t('Ads Statistics')}
                                    </NavLink>
                                    <div className="dropdownWrapper">
                                        <NavLink
                                            className={handleClassName}
                                            to="/support/returns"
                                            onClick={e => {
                                                e.preventDefault()
                                                // hideNavHandle()
                                            }}
                                        >
                                            {t('Support')}
                                        </NavLink>
                                        <img
                                            src={downOutlined}
                                            className={`${isShowDown === 'support' ? 'active' : ''}`}
                                            onClick={e => handleDown(e, 'support')}
                                            alt=""
                                        />
                                        <div className={`list ${isShowDown === 'support' ? 'active' : ''}`}>
                                            <NavLink
                                                className={handleClassName}
                                                to={`/support/returns`}
                                                onClick={hideNavHandle}
                                            >
                                                Returns
                                            </NavLink>
                                        </div>
                                    </div>

                                    {/* <a
                                        className="link"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://metasill.io/web_apps/web3-vending/"
                                    >
                                        {t('Web3 Vending')}
                                    </a> */}
                                    <div className="dropdownWrapper">
                                        <NavLink
                                            className={handleClassName}
                                            to={getToken() ? (md ? '/mine' : '/mobile-mine') : '/auth'}
                                            onClick={hideNavHandle}
                                        >
                                            {t('User')}
                                        </NavLink>
                                        <img
                                            src={downOutlined}
                                            className={`${isShowDown === 'user' ? 'active' : ''}`}
                                            onClick={e => handleDown(e, 'user')}
                                            alt=""
                                        />
                                        {getToken() && (
                                            <div className={`list ${isShowDown === 'user' ? 'active' : ''}`}>
                                                <NavLink
                                                    className={handleClassName}
                                                    to={`/mine/${TAB_TYPE_ENUM.AFFILIATE_LINK}`}
                                                    onClick={hideNavHandle}
                                                >
                                                    Affiliate link
                                                </NavLink>
                                                {!getIsTourist() && (
                                                    <NavLink
                                                        className={handleClassName}
                                                        to={`/mine/${TAB_TYPE_ENUM.WALLETADDRESS}`}
                                                        onClick={hideNavHandle}
                                                    >
                                                        Wallet
                                                    </NavLink>
                                                )}
                                                <NavLink
                                                    className={handleClassName}
                                                    to={`/mine/${TAB_TYPE_ENUM.ORDERS}`}
                                                    onClick={hideNavHandle}
                                                >
                                                    Order
                                                </NavLink>
                                                {!getIsTourist() && (
                                                    <NavLink
                                                        className={handleClassName}
                                                        to={`/mine/${TAB_TYPE_ENUM.ADDRESS}`}
                                                        onClick={hideNavHandle}
                                                    >
                                                        Address
                                                    </NavLink>
                                                )}
                                                {/* {!getIsTourist() && !getIsGoogleUser() && (
                                                    <NavLink
                                                        className={handleClassName}
                                                        to={`/mine/${TAB_TYPE_ENUM.EMAIL}`}
                                                        onClick={hideNavHandle}
                                                    >
                                                        Email
                                                    </NavLink>
                                                )} */}
                                                {!getIsTourist() && (
                                                    <NavLink
                                                        className={handleClassName}
                                                        to={`/mine/${TAB_TYPE_ENUM.PHONE}`}
                                                        onClick={hideNavHandle}
                                                    >
                                                        Phone
                                                    </NavLink>
                                                )}
                                                {!getIsTourist() && !getIsGoogleUser() && (
                                                    <NavLink
                                                        className={handleClassName}
                                                        to={`/mine/${TAB_TYPE_ENUM.PASSWORD}`}
                                                        onClick={hideNavHandle}
                                                    >
                                                        Password
                                                    </NavLink>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <NavLink
                                        className={handleClassName}
                                        to="/to_download"
                                        onClick={hideNavHandle}
                                    >
                                        {t('Download')}
                                    </NavLink>
                                    {/* <div className="link" onClick={handleOutLogin}>
                                        {t('Logout')}
                                    </div> */}
                                </div>
                                <img className="closeIcon" src={closeIcon} onClick={hideNavHandle} alt="" />
                            </div>
                        </NavLinksMobile>
                    )}
                    <div className="flex right">
                        <NavLink
                            to={getToken() ? (md ? '/mine' : '/mobile-mine') : '/auth'}
                            className="header-auth md:ml-12"
                        >
                            <img className="userIcon" src={userIcon} alt="" />
                            {md && !getToken() && <span>{t('Login')}</span>}
                        </NavLink>
                        <div className="header-cart md:ml-12" onClick={showDrawer}>
                            <div className="cart-count">{cartNumber}</div>
                            <img className="cartIcon" src={cartIcon} alt="" />
                        </div>
                    </div>
                </HeaderContent>
                <Drawer
                    className={'headerDrawer'}
                    contentWrapperStyle={{ width: md ? '394px' : '80%' }}
                    closeIcon={null}
                    bodyStyle={{ padding: 0 }}
                    placement="right"
                    onClose={handleDrawerClose}
                    open={miniCartDrawerVisible}
                >
                    <MiniCart onClose={handleDrawerClose} />
                </Drawer>
            </HeaderWrapper>

            <Placeholder />
        </>
    )
}
const HeaderDownload = styled.div`
    padding: 0 16px;
    height: 40px;
    font-family: Roboto;
    line-height: 40px;
    background: #000;
    border-radius: 52px;
    font-size: 1.6rem;
`
const NavLinksWeb = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    transition: left 0.3s;
    .link {
        font-size: 16px;
        margin-left: 5rem;
        color: #c2c2c2;
        line-height: 16px;
        font-family: Roboto-Regular;
        cursor: pointer;
        :hover {
            color: #fff;
        }
    }
    .activeLink {
        color: #fff;
    }
`
const NavLinksMobile = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.01);
    transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
    transform: translateX(-100vw);
    &.showNav {
        transform: translateX(0);
    }
    .container {
        position: relative;
        width: 75%;
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: #fff;
        box-shadow: 0 0 20px 0 rgba(96, 96, 96, 0.57);
        box-sizing: border-box;
        .nav-link-wrapper {
            position: relative;
            padding-top: 14.2rem;
            padding-left: 64px;
        }
        .link {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            height: 50px;
            font-family: Roboto-Regular;
            font-size: 17px;
            color: #9b9b9b;
            letter-spacing: 0;
            line-height: 20px;
            font-weight: 400;
        }
        .activeLink {
            font-family: Roboto-Bold;
            font-weight: 700;
            color: #000000;
        }
        .dropdownWrapper {
            position: relative;
            width: 100%;
            img {
                position: absolute;
                left: -40px;
                top: 17px;
                width: 16px;
                height: 16px;
                transform: rotate(0deg);
            }
            img.active {
                transform: rotate(180deg);
            }
            .list {
                padding-left: 20px;
                height: 0;
                overflow: hidden;
                transition: height 0.5s ease-in-out;
            }
            .list.active {
                height: auto;
            }
        }
        .closeIcon {
            position: absolute;
            top: 64px;
            right: 25px;
            width: 16px;
            height: 16px;
        }
    }
`

const HeaderContent = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 5rem;
    .right {
        justify-content: flex-end;
    }
    .hamburger {
        margin-right: 36px;
        width: 24px;
        height: 24px;
    }
    .logo {
        width: 164px;
        height: 24px;
        user-select: none;
    }
    .userIcon,
    .cartIcon {
        width: 3.6rem;
        height: 3.6rem;
    }
    .header-cart {
        position: relative;
        user-select: none;
        cursor: pointer;
    }
    .header-auth {
        display: flex;
        align-items: center;
        font-family: Roboto-Regular;
        font-size: 1.6rem;
        color: #ffffff;
        letter-spacing: 0;
        text-align: right;
        line-height: 20px;
        font-weight: 400;
        user-select: none;
        img {
            margin-right: 1rem;
        }
    }
    .cart-count {
        position: absolute;
        left: 5px;
        right: 0;
        top: 2px;
        color: #fff;
        margin: auto;
        text-align: center;
        font-size: 16px;
    }
    @media (max-width: 1440px) {
        .cart-count {
            font-size: 12px;
        }
    }
    @media (max-width: 768px) {
        justify-content: space-between;
        padding: 0 2.4rem;
        .userIcon,
        .cartIcon {
            width: 24px;
            height: 24px;
        }
        .header-cart {
            margin-left: 14px;
        }
        .cart-count {
            top: -2px;
            transform: scale(0.82);
        }
    }
`

const HeaderWrapper = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 68px;
    background-color: #2f2e2e;
    .ant-drawer-header {
        padding: 0 !important;
    }
    @media (max-width: 768px) {
        width: 100%;
        height: 56px;
        background-color: #000;
    }
`

const Placeholder = styled.div`
    height: 68px;
    @media (max-width: 768px) {
        width: 100%;
        height: 56px;
    }
`
