import React, { FC } from 'react'
import styled from '@emotion/styled'

import { useResponsive } from 'shared/hooks'
import { CountryCodeList } from 'shared/CountryCode'

import { Select } from 'antd'

interface Props {
    popupClassName?: string
    ccLocale: string
    setCcLocale: (val: string) => void
    width?: number
}

export const CountryCodePicker: FC<Props> = ({ popupClassName = '', ccLocale, setCcLocale, width }) => {
    const { md } = useResponsive()

    return (
        <div className="flex items-center">
            <Select
                value={ccLocale}
                className="select-before"
                popupClassName={popupClassName}
                optionLabelProp="label"
                style={{ width: md ? 103 : width ? width : 80 }}
                onChange={val => setCcLocale(val)}
            >
                {CountryCodeList.map(({ code, en, locale }) => (
                    <Select.Option key={locale} value={locale} label={`+${code}`}>
                        <Option className="flex">
                            <div className="w-40">{`+${code}`}</div>
                            <div>{en}</div>
                        </Option>
                    </Select.Option>
                ))}
            </Select>
            <Divider />
        </div>
    )
}

const Option = styled.div`
    align-items: center;
    font-family: Roboto-Regular;
    font-weight: 400;
    height: 30px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
`

const Divider = styled.div`
    height: 30px;
    margin: 9px 0 9px 11px;
    border-right: 1px solid #000;
`
