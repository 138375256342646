import React, { FC, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from '@emotion/styled'

import { useListSearchForm, useShowMessage, useUrlParams } from 'shared/hooks'

import { useI18n } from 'core/language/api'

import { Spin } from 'components'

import backIcon from './assets/back.png'
import { Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { useAdRecordsList } from 'core/ad-statistics/api'
import moment from 'moment'

const roleList = ['All', 'Today', 'Last 3 days', 'Last 7 days', 'Last 30 days']
const PAGE_SIZE = 10
const defaultSearchValues = {
    pageNum: '1',
    pageSize: `${PAGE_SIZE}`
}
const defaultValues = { selectData: '', beginTime: '', endTime: '', page: '' }

export const AdStatisticsDetail: FC = () => {
    const navigate = useNavigate()
    const { id } = useParams<{ id: string }>()
    const {
        params: { selectData, beginTime, endTime, page }
    } = useUrlParams({ defaultValues })

    const {
        methods: { t }
    } = useI18n()
    const {
        methods: { showErrorModal }
    } = useShowMessage()

    const { params, onChangePage } = useListSearchForm(defaultSearchValues, false)

    const startTime = useMemo(() => {
        if (selectData === roleList[0]) {
            return 'all'
        } else if (selectData === roleList[3]) {
            return moment().add(-7, 'd').format('YYYY-MM-DD')
        } else if (selectData === roleList[2]) {
            return moment().add(-3, 'd').format('YYYY-MM-DD')
        } else if (selectData === roleList[1]) {
            return moment().startOf('day').format('YYYY-MM-DD')
        } else if (selectData === roleList[4]) {
            return moment().subtract(1, 'month').format('YYYY-MM-DD')
        }
        return ''
    }, [selectData])

    const {
        loading: recordsLoading,
        list: recordsList,
        total,
        methods: { getList }
    } = useAdRecordsList(page === 'uv' ? 'uv' : '')
    useEffect(() => {
        id &&
            getList(id, {
                ...params,
                beginDate: beginTime ? beginTime : startTime === 'all' ? ' ' : startTime,
                endDate: endTime ? endTime : startTime === 'all' ? ' ' : moment().format('YYYY-MM-DD')
            }).catch(err => showErrorModal(t(err.data.msg)))
    }, [beginTime, endTime, getList, id, params, showErrorModal, startTime, t])

    const columns: ColumnProps<any>[] = [
        {
            title: 'IP Address',
            dataIndex: 'ip',
            className: 'tableStyle'
        },
        {
            title: 'Location',
            dataIndex: 'location',
            className: 'tableStyle'
        },
        {
            title: 'Visit Time',
            dataIndex: 'createTimeFormat',
            className: 'tableStyle'
        }
    ]

    return (
        <Spin spinning={recordsLoading}>
            <Container>
                <Content>
                    <Header>
                        <img
                            width={20}
                            height={20}
                            src={backIcon}
                            alt="adStatistics"
                            onClick={() => navigate(-1)}
                        />
                        <div className="text">Ad details</div>
                    </Header>
                    <div className="table">
                        <Table
                            tableLayout="fixed"
                            columns={columns}
                            dataSource={recordsList}
                            rowKey="id"
                            pagination={
                                total > 10
                                    ? {
                                          total,
                                          pageSize: +params.pageSize,
                                          current: +params.pageNum,
                                          onChange: pageNum => {
                                              onChangePage(pageNum)
                                              // onChangePageSize(pageSize)
                                          },
                                          showSizeChanger: false
                                          //   pageSizeOptions: ['20', '50', '100']
                                      }
                                    : false
                            }
                            rowClassName={(_, index) => (index % 2 === 0 ? '' : 'darkRow')}
                        />
                    </div>
                </Content>
            </Container>
        </Spin>
    )
}
const Content = styled.div`
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
`
const Container = styled.div`
    width: 100%;
    min-height: calc(100vh - 68px - 90px);
    padding: 0 220px 78px;
    display: flex;
    flex-direction: column;
    background-color: #f8f9fa;
    @media (max-width: 768px) {
        width: 100%;
        padding: 0 24px 50px;
    }
    .table {
        width: 100%;
        border: 1px solid #f3f4f5;
        border-radius: 10px;
        overflow: hidden;
        .ant-table-thead
            > tr
            > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
            width: 0px;
        }
    }
    .ant-table-thead > tr > th {
        background-color: #f3f4f5 !important;
    }
    .tableStyle {
        font-family: Roboto-Regular !important;
    }
`
const Header = styled.div`
    width: 100%;
    padding: 34px 0 20px;
    border-bottom: 1px solid rgba(47, 46, 46, 1);
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    img {
        height: 20px;
        cursor: pointer;
        margin-right: 10px;
    }
    .text {
        font-family: Roboto-Medium;
        font-size: 24px;
        color: #000000;
        line-height: 28px;
        font-weight: 500;
    }
`
