import React, { FC, useCallback, useMemo, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { logEvent } from 'firebase/analytics'

import { useAuth } from 'core/auth/api'
import { useI18n } from 'core/language/api'
import {
    getShareCode,
    removeShareCode,
    setIsGoogleUser,
    getCartPath,
    removeCartPath,
    removeTouristAddress
} from 'shared/config'
import { useApiError, useShowMessage, useSignInRedirect, useMemoizedFn } from 'shared/hooks'
import { analytics, Reg } from 'shared/utils'

import { Button, Spin, GoogleAuth, AppleAuth } from 'components'
import { Input } from './common/Input'
import { Reaptcha } from './common/Reaptcha'
import { InputWrapper } from './common/InputWrapper'
import { Line } from './common/Line'
import { useLocalStorage } from '@mantine/hooks'

export enum SignInType {
    EMAIL = 'Email',
    GOOGLE = 'Google',
    TOURIST = 'tourist',
    APPLE = 'Apple'
}

export const SignInEmail: FC = () => {
    const cartpath = getCartPath()
    const guested = useMemo(() => cartpath === '/cart', [cartpath])
    const {
        methods: { t }
    } = useI18n()
    const { redirectBack } = useSignInRedirect()
    const navigate = useNavigate()
    const {
        methods: { error }
    } = useShowMessage()
    const {
        loading,
        methods: { signIn, touristSignIn, signInWithGoogle }
    } = useAuth()
    const {
        methods: { handleError }
    } = useApiError('Auth', error as any, 'auth')

    const [password, setPassword] = useState('')
    const [loginName, setLoginName] = useState('')
    const [isActiveEmail, setIsActiveEmail] = useState(false)
    const [isActivePwd, setIsActivePwd] = useState(false)
    const [showReaptcha, setShowReaptcha] = useState(false)

    const [, setselectOwnedAddressId] = useLocalStorage({ key: 'owned_wallet_address' })
    const [, setselectUploadAddressId] = useLocalStorage({ key: 'upload_wallet_address' })
    const [, setselectAuthorizedAddressId] = useLocalStorage({ key: 'authorized_wallet_address' })
    const [, setselectFrameAddressId] = useLocalStorage({ key: 'Frame' })

    useEffect(() => {
        return () => {
            removeCartPath()
        }
    }, [cartpath])

    const isEmail = useMemo(() => {
        return Reg.email.test(loginName)
    }, [loginName])

    const isPwd = useMemo(() => {
        return !!password && password.length >= 6 && password.length <= 18
    }, [password])

    const submitDisabled = useMemo(() => {
        return !(isEmail && isPwd)
    }, [isEmail, isPwd])

    const signInLogEvent = useMemoizedFn((method: SignInType) => {
        logEvent(analytics, 'login', {
            method: method
        })
    })

    const submitHandle = useCallback(() => {
        setselectOwnedAddressId('')
        setselectUploadAddressId('')
        setselectAuthorizedAddressId('')
        setselectFrameAddressId('')
        const submitData = {
            loginName,
            password
        }
        signIn(submitData)
            .then(_token => {
                signInLogEvent(SignInType.EMAIL)
                redirectBack()
            })
            .catch(err => {
                handleError(err)
            })
    }, [
        setselectOwnedAddressId,
        setselectUploadAddressId,
        setselectAuthorizedAddressId,
        setselectFrameAddressId,
        loginName,
        password,
        signIn,
        signInLogEvent,
        redirectBack,
        handleError
    ])

    const [touristSignInLoading, setTouristSignInLoading] = useState<boolean>(false)
    const onVerify = useCallback(
        (token: string) => {
            setTouristSignInLoading(true)
            touristSignIn(token)
                .then(_token => {
                    signInLogEvent(SignInType.TOURIST)
                    setTouristSignInLoading(false)
                    redirectBack()
                })
                .catch(err => {
                    setTouristSignInLoading(false)
                    handleError(err)
                })
        },
        [touristSignIn, signInLogEvent, redirectBack, handleError]
    )

    const signInWithGoogleHandle = useCallback(
        idToken => {
            const shareCode = getShareCode()
            signInWithGoogle(idToken, shareCode as string)
                .then(_token => {
                    signInLogEvent(SignInType.GOOGLE)
                    setIsGoogleUser(true)
                    removeShareCode()
                    redirectBack()
                })
                .catch(err => {
                    handleError(err)
                })
        },
        [signInWithGoogle, signInLogEvent, redirectBack, handleError]
    )

    return (
        <Spin spinning={loading || touristSignInLoading}>
            <Wrapper>
                <InputWrapper isActive={isActiveEmail}>
                    <Input
                        type="email"
                        value={loginName}
                        placeholder={t('client.auth.placeholder.email')}
                        onChange={setLoginName}
                        onFocus={() => setIsActiveEmail(true)}
                        onBlur={() => setIsActiveEmail(false)}
                        length={[5, 50]}
                    />
                </InputWrapper>
                <InputWrapper isActive={isActivePwd}>
                    <Input
                        type="password"
                        value={password}
                        placeholder={t('client.auth.placeholder.password')}
                        onChange={setPassword}
                        onFocus={() => setIsActivePwd(true)}
                        onBlur={() => setIsActivePwd(false)}
                    />
                </InputWrapper>
                <div className="submit-first flex items-center justify-center">
                    <Button className="submit-btn" onClick={submitHandle} disabled={submitDisabled}>
                        {t('client.auth.signIn')}
                    </Button>
                </div>
                <div className="flex items-center justify-center">
                    <Button
                        className="submit-btn"
                        onClick={() => {
                            removeTouristAddress()
                            setShowReaptcha(true)
                        }}
                        disabled={showReaptcha}
                    >
                        {guested ? t('Guest Checkout') : t('Browse as Guest')}
                    </Button>
                </div>
                {showReaptcha && (
                    <div className="mt-6">
                        <Reaptcha onVerify={onVerify} />
                    </div>
                )}
                <div className="tips text-center">
                    <div className="forget-password" onClick={() => navigate('/auth/forgot_password')}>
                        {t('client.auth.forgetPassword.actionTitle')}
                    </div>
                </div>
                <Line>or</Line>
                <div className="flex items-center justify-center" style={{ marginTop: '20px' }}>
                    <GoogleAuth onSignIn={signInWithGoogleHandle} />
                    <AppleAuth />
                </div>
            </Wrapper>
        </Spin>
    )
}

const Wrapper = styled.div`
    position: relative;
    box-sizing: border-box;
    .submit-first {
        margin: 24px auto 14px;
    }
    .submit-btn {
        width: 100%;
    }
    .tips {
        display: flex;
        justify-content: center;
        font-family: Roboto-Regular;
        font-size: 18px;
        line-height: 24px;
        color: #000;
        text-decoration: underline;
        margin: 24px auto;
        .forget-password {
            cursor: pointer;
        }
    }
`
