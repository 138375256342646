import React, { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'

import { useApiError, useResponsive } from 'shared/hooks'
import { Media, Spin } from 'components'
import { useOwnedDetail } from 'core/management/nft/api/useOwnedDetail'

export const CollectionDetail: FC = () => {
    const { md } = useResponsive()
    const { id } = useParams()
    const {
        methods: { handleError }
    } = useApiError('CollectionDetail')

    const {
        loading,
        detail,
        methods: { getOwnedDetail }
    } = useOwnedDetail()
    useEffect(() => {
        getOwnedDetail(id!).catch(handleError)
    }, [getOwnedDetail, handleError, id])
    return (
        <Spin spinning={loading} className="w-full">
            <Wrapper>
                {detail && (
                    <Content className="flex">
                        <div className="imageVideo" style={{ background: `${detail.backgroundColor}` }}>
                            {detail.type === 'image' && (
                                <Media
                                    size={md ? 524 : '100%'}
                                    height={md ? 524 : '246px'}
                                    src={detail.imgUrl}
                                />
                            )}
                            {detail.type === 'video' && (
                                <DetailVideo
                                    src={detail.animationUrl}
                                    autoPlay={true}
                                    muted={true}
                                    controls={false}
                                    loop={true}
                                    playsInline
                                />
                            )}
                        </div>
                        <ContentText className="flex-1">
                            <Title>{detail.name}</Title>
                            <NftText className="flex">
                                <Label>NFT address:</Label>
                                <div>{detail.tokenAddress}</div>
                            </NftText>
                            <NftText className="flex">
                                <Label>Token ID:</Label>
                                <div>{detail.tokenId}</div>
                            </NftText>
                            <Text className="flex">
                                <Label>Type:</Label>
                                <div>{detail.type}</div>
                            </Text>
                            <Text>
                                <Label>Description:</Label>
                                <div>{detail.description}</div>
                            </Text>
                        </ContentText>
                    </Content>
                )}
            </Wrapper>
        </Spin>
    )
}

const Wrapper = styled.div`
    /* min-height: 500px; */
    min-height: calc(100vh - 68px - 90px);
`
const Content = styled.div`
    margin: 92px 0 50px;
    .imageVideo {
        padding-top: 0;
        width: 524px;
        height: 524px;
    }
    @media (max-width: 768px) {
        flex-direction: column;
        margin-top: 0px;
        .imageVideo {
            width: 100%;
            height: 246px;
            padding-top: 0px;
        }
    }
`
const DetailVideo = styled.video`
    width: 524px;
    height: 524px;
    object-fit: cover;
    @media (max-width: 768px) {
        width: 100%;
        height: 246px;
    }
`
const Title = styled.div`
    font-family: HelveticaNeue-CondensedBold;
    font-size: 50px;
    line-height: 50px;
    color: #000;
    letter-spacing: 0;
    @media (max-width: 768px) {
        margin-top: 20px;
        font-size: 24px;
        line-height: 26px;
    }
`
const NftText = styled.div`
    margin: 30px 0;
    font-family: Roboto-Regular;
    font-size: 20px;
    color: #000;
    word-break: break-all;
    @media (max-width: 768px) {
        font-size: 16px;
        flex-direction: column;
    }
`
const Text = styled.div`
    margin: 30px 0;
    font-family: Roboto-Regular;
    font-size: 20px;
    color: #000;
    @media (max-width: 768px) {
        font-size: 16px;
    }
`
const Label = styled.label`
    color: #787878;
    display: inline-block;
    margin-right: 10px;
    font-family: Roboto-Regular;
    font-size: 20px;
    @media (max-width: 768px) {
        font-size: 16px;
    }
`
const ContentText = styled.div`
    margin-left: 50px;
    @media (max-width: 768px) {
        margin-left: 0;
        padding: 0 20px;
    }
`
