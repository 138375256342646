import React, { FC, useEffect } from 'react'
import { HashRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom'
import { logEvent } from 'firebase/analytics'
import styled from '@emotion/styled'
import { Web3ReactProvider } from '@web3-react/core'

import { TAB_TYPE_ENUM } from 'core/aff/models'
import {
    configResponsive,
    useMount,
    useSetLocalStorageStoreList,
    useSaveShareCode,
    useCheckLogin,
    useInitWalletInApp
} from 'shared/hooks'
import { analytics } from 'shared/utils'
import { getLibrary } from 'wallet/web3'

import { FeatureMain, Footer, Header } from 'components'
import { FeatureStateProvider } from 'state-provider'
import { FeatureAuthShell } from 'modules/auth'
import { Home } from 'modules/home'
import { Contact } from 'modules/Contact'
import { ProductDetails } from 'modules/products/details'
import { ProductIndex } from 'modules/products'
// import { AddressCreate } from 'modules/address/create'
import { CartIndex } from 'modules/cart'
import { OrderCreate } from 'modules/order/create/index'
import { OrderPay } from 'modules/order/pay'
import { OrderDetail } from 'modules/order/detail'
import { Rental } from 'modules/rental'
import { EventShell } from 'modules/event/shell'
import { ManagementShell } from 'modules/management/shell'
import { User } from 'modules/user'
import { MobileUser } from 'modules/user/mobile-user'
import { PrivacyPolicy, PrivacyPolicyZh } from 'modules/privacy-policy'
import { NotFound } from 'modules/not-found'
import { Download } from 'modules/download'
import { LoginModalProvider } from 'shared/providers'
import { SelfRentalShell } from 'modules/self-rental/shell'
import { AdStatisticsShell } from 'modules/ad-statistics/shell'
import { Web3Vending } from 'modules/web3-vending'
import { DeleteSpecification } from 'modules/delete-specification'
import { Boss } from 'modules/Boss'
import { KeyContact } from 'modules/key-contact'
import { Nabd } from 'modules/nabd'
import { ToDownload } from 'modules/ToDownload'
import { SupportReturn } from 'modules/Support/Returns'

const Content: FC = () => {
    useMount(() =>
        configResponsive({
            xs: 0,
            sm: 640,
            md: 768,
            lg: 1024,
            xl: 1280,
            '2xl': 1536
        })
    )

    useInitWalletInApp()

    useSetLocalStorageStoreList()

    useCheckLogin()

    useSaveShareCode()

    const location = useLocation()
    useEffect(() => {
        if (document && location?.pathname !== '/') {
            if (document?.documentElement || document?.body) {
                document.documentElement.scrollTop = document.body.scrollTop = 0 // Manual topping when switching routes
            }
        }
    }, [location])

    useEffect(() => {
        logEvent(analytics, 'page_view', {
            page_location: window.location.href,
            page_path: location?.pathname,
            page_title: document.title
        })
    }, [location])

    return (
        <div>
            <Header />
            <Main className="mx-auto my-0">
                <Routes>
                    <Route path="/products" element={<ProductIndex />} />
                    <Route path="/boss" element={<Boss />} />
                    <Route path="/products/:id/*" element={<ProductDetails />} />
                    <Route path="/cart" element={<CartIndex />} />
                    <Route path="/auth/:type" element={<FeatureAuthShell />} />
                    <Route path="/auth" element={<Navigate to={'/auth/sign_in'} />} />
                    <Route path="/mine/:type" element={<User />} />
                    <Route path="/mobile-mine" element={<MobileUser />} />
                    <Route path="/mine" element={<Navigate to={'/mine/' + TAB_TYPE_ENUM.AFFILIATE_LINK} />} />
                    <Route path="/address" element={<Navigate to={'/mine/address'} />} />
                    {/* <Route path="/address/create" element={<AddressCreate />} /> */}
                    <Route path="/orders/place/:orderCode" element={<OrderPay />} />
                    <Route path="/orders" element={<Navigate to={'/mine/orders'} />} />
                    <Route path="/orders/create" element={<OrderCreate />} />
                    <Route path="/orders/:id/*" element={<OrderDetail />} />
                    <Route path="/rental" element={<Rental />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/event/*" element={<EventShell />} />
                    <Route path="/self_rent/*" element={<SelfRentalShell />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/privacy-policy-zh" element={<PrivacyPolicyZh />} />
                    <Route path="/delete-specification" element={<DeleteSpecification />} />
                    <Route path="/key-contact" element={<KeyContact />} />
                    <Route path="/nabd" element={<Nabd />} />
                    <Route path="/to_download" element={<ToDownload />} />
                    <Route path="/download" element={<Download />} />
                    <Route path="/management/*" element={<ManagementShell />} />
                    <Route path="/ad-statistics/*" element={<AdStatisticsShell />} />
                    <Route path="/web3-vending/*" element={<Web3Vending />} />
                    <Route path="/support/returns/*" element={<SupportReturn />} />
                    <Route index element={<Home />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Main>
            <Footer />
        </div>
    )
}

const App: FC = () => {
    return (
        <FeatureStateProvider>
            <LoginModalProvider>
                <Web3ReactProvider getLibrary={getLibrary}>
                    <FeatureMain>
                        <Router>
                            <Content />
                        </Router>
                    </FeatureMain>
                </Web3ReactProvider>
            </LoginModalProvider>
        </FeatureStateProvider>
    )
}

export default App

const Main = styled.main`
    width: 100%;
    min-height: calc(100vh - 68px - 90px);
    overflow: hidden;
    @media (max-width: 576px) {
        min-height: calc(100vh - 56px - 90px);
        width: 100%;
    }
`
