import React, { FC, PropsWithChildren } from 'react'
import styled from '@emotion/styled'

interface Props {}

export const Line: FC<PropsWithChildren<Props>> = ({ children }) => {
    return (
        <Wrapper className={`flex items-center justify-center`}>
            <div className="label">{children}</div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    height: 20px;
    line-height: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    ::before,
    ::after {
        display: block;
        content: '';
        width: 50%;
        height: 1px;
        background-color: #c4c4d3;
    }
    .label {
        padding: 0 8px;
        flex-shrink: 0;
        font-family: Roboto-Regular;
        font-size: 18px;
        color: #a0a0a9;
        letter-spacing: 0;
    }
`
