import React from 'react'
import { Editor as TinymceEditor } from '@tinymce/tinymce-react'

interface Props {
    className?: string
    value: string
    onChange: (v: string) => void
    imagesUploadHandler: any
    disabled?: boolean
}

export const Editor: React.FC<Props> = ({
    className = '',
    value,
    onChange,
    imagesUploadHandler,
    disabled = false
}) => {
    const handleEditorChange = (content: string, editor: any) => {
        onChange(content)
    }
    const Editor: any = TinymceEditor

    return (
        <div className={className} style={{ minHeight: '500px' }}>
            <Editor
                disabled={disabled}
                value={value}
                apiKey="gijw0if5l0qgmc7xxa94p65w17v2otdytaotuphuj05jsh1e"
                // plugins="contextmenu"
                init={{
                    height: 500,
                    menubar: false,
                    branding: false,
                    automatic_uploads: true,
                    // skin: 'oxide-dark',
                    convert_urls: false,
                    images_upload_handler: imagesUploadHandler,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code'
                    ],
                    toolbar: `undo redo | formatselect | fontsizeselect | fontselect | image | media | link | 
                        bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | 
                        bullist numlist outdent indent | removeformat`,
                    contextmenu: '',
                    fontsize_formats: '8px 10px 12px 14px 16px 18px 24px 36px',
                    font_formats:
                        'Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n;Roboto-Regular'
                }}
                onEditorChange={handleEditorChange}
            />
        </div>
    )
}
