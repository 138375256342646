import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'

import { usePlaylist } from 'core/management/frame/api/usePlaylist'
import { useWalletAddress } from 'core/walletaddress/api'
import { useApiError, useLoading, useResponsive } from 'shared/hooks'
import { stringOmit } from 'shared/utils'

import { Flex, Image, SimpleGrid } from '@mantine/core'
import { Checkbox } from 'antd'
import { Collapse } from 'antd-mobile'
import type { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { PageHeader, PageHeaderMobile } from 'components'
import { RemovePlaylistModal } from './components/RemovePlaylistModal'
import { DeleteButton } from './components/DeleteButton'

import collapseIcon from 'assets/collapse-arrow.png'

export const PlaylistDelete: FC = () => {
    const navigate = useNavigate()
    const inputRef = useRef<HTMLInputElement>(null)
    const { md } = useResponsive()

    const {
        methods: { handleError }
    } = useApiError('SelectPlaylist')
    const {
        list: walletList,
        loading: walletLoading,
        methods: { getWalletAddress }
    } = useWalletAddress()
    useEffect(() => {
        getWalletAddress().catch(handleError)
    }, [getWalletAddress, handleError])
    const walletaddress = useMemo(() => walletList[0]?.address, [walletList])

    const {
        list,
        loading: listLoading,
        methods: { getList, removePlayList }
    } = usePlaylist(walletaddress)
    useEffect(() => {
        getList().catch(handleError)
    }, [getList, handleError, walletaddress])

    const [activeKey, setActiveKey] = useState<string | null>(null)
    const [modalVisible, setModalVisible] = useState(false)
    const [selectPlaylist, setSelectPlaylist] = useState<CheckboxValueType[]>([])

    useLoading(walletLoading || listLoading)

    return (
        <>
            <DeleteHeader>
                {md && (
                    <PageHeader
                        className="deleteheader"
                        title="Playlist Delete"
                        onBack={() => navigate(-1)}
                        border={false}
                    />
                )}
                {!md && <PageHeaderMobile title="Playlist Delete" onBack={() => navigate(-1)} />}
            </DeleteHeader>
            <Checkbox.Group
                style={{ width: '100%' }}
                onChange={checkedValues => setSelectPlaylist(checkedValues)}
            >
                <CollapseWrapper>
                    <Collapse accordion activeKey={activeKey} onChange={setActiveKey}>
                        {list.map(({ id: playlistId, name, nfts, random, duration }) => (
                            <Collapse.Panel
                                arrow={<Image src={collapseIcon} width={16} height={16} />}
                                key={`${playlistId}`}
                                title={
                                    <Flex align="center" gap="sm" h={38}>
                                        <Flex justify="center" align="center" gap="lg">
                                            <Checkbox onClick={e => e.stopPropagation()} value={playlistId} />
                                            <Title>{stringOmit(name, 19)}</Title>
                                        </Flex>
                                    </Flex>
                                }
                            >
                                <SimpleGrid cols={md ? 16 : 4}>
                                    {nfts.map(({ id: nftId, preview_url }) => (
                                        <Image
                                            key={nftId}
                                            src={preview_url}
                                            width={74}
                                            height={74}
                                            fit="contain"
                                        />
                                    ))}
                                </SimpleGrid>
                                {/* <PlaylistRow style={md ? { width: '326px' } : {}}>
                                    <Label>Transition Time</Label>
                                    <Value>{duration} sec</Value>
                                </PlaylistRow>
                                <PlaylistRow style={md ? { width: '326px' } : {}}>
                                    <Label>Shuffle Play</Label>
                                    <Value>
                                        <Switch
                                            style={{ transform: 'scale(1.5)' }}
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            checked={random}
                                            disabled
                                        />
                                    </Value>
                                </PlaylistRow> */}
                            </Collapse.Panel>
                        ))}
                    </Collapse>
                </CollapseWrapper>
                {list.length > 0 && (
                    <DeleteBtn>
                        <DeleteButton
                            disabled={selectPlaylist.length > 0 ? false : true}
                            onClick={() => {
                                setModalVisible(true)
                                setTimeout(() => inputRef.current?.focus(), 200)
                            }}
                        >
                            Delete
                        </DeleteButton>
                    </DeleteBtn>
                )}
            </Checkbox.Group>
            <RemovePlaylistModal
                visible={modalVisible}
                setVisible={setModalVisible}
                onDelete={() =>
                    removePlayList(
                        selectPlaylist?.map(item => {
                            return { id: +item }
                        })
                    )
                }
            />
        </>
    )
}
const DeleteBtn = styled.div`
    width: 580px;
    margin: 50px auto 82px;
    @media (max-width: 768px) {
        width: 222px;
        margin: 50px auto;
    }
`
const DeleteHeader = styled.div`
    margin-top: 14px;
    .deleteheader {
        h2 {
            img {
                width: 20px;
                height: 20px;
            }
        }
    }
    @media (max-width: 768px) {
        margin: 5px 0;
    }
`
const Title = styled.div`
    font-family: OpenSans-Bold;
    font-size: 16px;
    color: #000000;
    font-weight: 700;
`

// const Value = styled.div`
//     font-family: Roboto-Bold;
//     font-size: 16px;
//     color: #000000;
//     line-height: 16px;
//     font-weight: 700;

//     .anticon {
//         vertical-align: 0 !important;
//     }
// `

// const Label = styled.div`
//     font-family: OpenSans-SemiBold;
//     font-size: 16px;
//     color: #838383;
//     font-weight: 600;
// `

// const PlaylistRow = styled.div`
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     margin: 28px 0;
// `
const CollapseWrapper = styled.div`
    padding: 0;
    @media (max-width: 768px) {
        padding: 0 30px 0 24px;
        min-height: 400px;
    }
    .adm-collapse-panel-header:first-of-type {
        border-top: 1px solid rgba(47, 46, 46, 1) !important;
        margin-top: 2px;
        @media (max-width: 768px) {
            border-top: solid 1px rgba(151, 151, 151, 1) !important;
        }
    }
    .adm-collapse-panel-header:nth-last-of-type(1) {
        border-top: solid 1px rgba(151, 151, 151, 1) !important;
        border-bottom: solid 1px rgba(151, 151, 151, 1) !important;
        margin-top: 2px;
    }
    .adm-collapse-panel-header {
        border-top: solid 1px rgba(151, 151, 151, 1) !important;
        margin-top: 2px;
    }
    .adm-list {
        --border-top: none;
        --border-inner: none;
        /* --border-inner: solid 1px rgba(151, 151, 151, 1); */
    }
`
