import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Delivery } from 'core/delivery/models'
import { useOrderSteps } from 'core/order/api'

import { actions, selectors } from 'core/delivery/state'
import { actions as actionsOrder } from 'core/order/state'

export const useSelectedDelivery = () => {
    const dispatch = useDispatch()

    const { selectedDelivery, selectedBilling } = useOrderSteps()

    const setSelectedDelivery = useCallback(
        (id: string) => {
            if (id === selectedBilling?.id) {
                dispatch(actionsOrder.setSameShipping({ sameShipping: true }))
            }
            dispatch(actions.setCurrent({ id }))
        },
        [dispatch, selectedBilling]
    )

    const setSelectedBilling = useCallback(
        (billingId: string) => {
            if (billingId !== selectedDelivery?.id) {
                dispatch(actionsOrder.setSameShipping({ sameShipping: false }))
            } else {
                dispatch(actionsOrder.setSameShipping({ sameShipping: true }))
            }
            dispatch(actions.setBillingId({ billingId }))
        },
        [dispatch, selectedDelivery]
    )

    const unpaidDeliveryDetail = useSelector(selectors.getUnpaid)
    const setUnpaid = useCallback(
        (billDeliveryDetail: Delivery, deliveryDetail: Delivery) => {
            dispatch(actions.setUnpaid({ billDeliveryDetail, deliveryDetail }))
        },
        [dispatch]
    )

    return {
        selectedDelivery,
        selectedBilling,
        unpaidDeliveryDetail,
        methods: {
            setSelectedDelivery,
            setSelectedBilling,
            setUnpaid
        }
    }
}
