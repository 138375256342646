import { useCallback } from 'react'

import { useListState, useMountedPromiseFn } from 'shared/hooks'
import { useI18n } from 'core/language/api'
import { ProductCategoryList } from 'core/product/models'
import { services } from 'core/product/data-access'

export const useProductList = () => {
    const { lang } = useI18n()

    const [state, setState] = useListState<ProductCategoryList>()

    const getListApi = useMountedPromiseFn(services.getList)
    const getList = useCallback(() => {
        setState({ loading: true })
        return getListApi(lang)
            .then(list => {
                setState({ list, loading: false })
                return list
            })
            .catch(err => {
                setState({ loading: false })
                return Promise.reject(err)
            })
    }, [getListApi, lang, setState])

    return {
        ...state,
        methods: {
            getList
        }
    }
}
