import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { logEvent } from 'firebase/analytics'

import { CheckoutRequestItem } from 'core/checkout/models'
import { useCreateOrder, useOrderSteps } from 'core/order/api'
import { STEPS } from 'core/order/models'
import { useApiError, useSignInRedirect, useShowMessage, useMemoizedFn } from 'shared/hooks'
import { analytics } from 'shared/utils'
import { useCartRender } from './api/useCartRender'

import { Spin, Tooltip } from 'antd'
import { PageContainer, Button } from 'components'
import { List } from './components/List'

import RightOutlined from 'assets/right-outlined.png'
import { CouponCode, CouponCodeRefProps } from '../order/components/CouponCode'
import Circle from 'assets/circle.png'
import { setCartPath } from 'shared/config'

interface LogEventItems {
    id: string
    name: string
    price: string
    quantity: number
    variant: string
}

export const CartIndex: FC = () => {
    const navigate = useNavigate()
    const {
        methods: { error }
    } = useShowMessage()
    const {
        methods: { handleError }
    } = useApiError('CartIndex', error)

    const {
        methods: { setStep }
    } = useOrderSteps()

    const { getToken, checkLogin } = useSignInRedirect()

    const {
        loading,
        list,
        methods: { getList, deleteItem, reduceQuantity, updateQuantity, buy }
    } = useCartRender()

    const {
        orderPrice,
        methods: { calculatePriceByCart }
    } = useCreateOrder()

    const couponCodeRef = useRef<CouponCodeRefProps>(null)

    useEffect(() => {
        getList().catch(handleError)
    }, [getList, handleError])

    const [couponCode, setCouponCode] = useState<string>('')
    const handleApply = useCallback(
        (value: string) => {
            setCouponCode(value)
            return Promise.resolve()
        },
        [setCouponCode]
    )
    const onlyProductType = useCallback(() => {
        if (list?.length) {
            let onlyProductList = true,
                onlyRentProductList = true
            list?.forEach(({ items }) => {
                items.forEach(({ type }) => {
                    if (type !== 0 && onlyProductList) {
                        onlyProductList = false
                    }
                    if (type !== 1 && onlyRentProductList) {
                        onlyRentProductList = false
                    }
                })
            })
            if (!onlyProductList && !onlyRentProductList) {
                error('Purchase and lease cannot be settled at the same time')
            } else {
                return true
            }
        }
        return false
    }, [list, error])

    const goodsItems = useMemo(() => {
        const memoItems: CheckoutRequestItem[] = []
        list?.forEach(({ items }) => {
            items.forEach(({ productId, skuId, quantity, timeZone, duration }) => {
                memoItems.push({ productId, skuId, quantity, timeZone, duration })
            })
        })
        return memoItems
    }, [list])

    useEffect(() => {
        // if (onlyProductType()) {
        goodsItems.length > 0 && calculatePriceByCart(goodsItems, couponCode).catch(handleError)
        // }
    }, [calculatePriceByCart, list, couponCode, handleError, onlyProductType, goodsItems])

    const handleDeleteItem = useMemoizedFn(async (code: string) => {
        try {
            await deleteItem(code)
            goodsItems.length && (await couponCodeRef.current?.reApply())
        } catch (error) {
            handleError(error)
        }
    })

    const checkoutLogEvent = useMemoizedFn(() => {
        const logEventItems: LogEventItems[] = []
        list.forEach(({ items }) => {
            items.forEach(item => {
                logEventItems.push({
                    id: item.productId,
                    name: item.name,
                    price: item.price,
                    quantity: item.quantity,
                    variant: item.spec.reduce(
                        (previousValue, currentValue) =>
                            `${previousValue}${currentValue.attrName}:${currentValue.valueName};`,
                        ''
                    )
                })
            })
        })
        logEvent(analytics, 'checkout_progress' as never, {
            checkout_step: 1,
            coupon: couponCode,
            value: orderPrice?.total,
            items: logEventItems
        })
        logEvent(analytics, 'begin_checkout' as never, {
            coupon: couponCode,
            items: logEventItems,
            value: orderPrice?.total
        })
    })
    const handleCheckout = useCallback(() => {
        let isTrue = false
        list.forEach(({ items }) => {
            items.forEach(item => {
                if (item.preOrder && item.onceLimit < item.quantity) {
                    isTrue = true
                }
                if (!item.preOrder && item.stock < item.quantity) {
                    isTrue = true
                }
            })
        })
        if (isTrue) return
        if (onlyProductType()) {
            buy()
                .then(() => {
                    checkoutLogEvent()
                    setStep(STEPS.SHIPPING_DETAILS)
                    const url = `/orders/create${couponCode ? '?code=' + couponCode : ''}`
                    if (getToken()) {
                        navigate(url)
                        return
                    }
                    setCartPath('/cart')
                    window.localStorage.setItem('cartpath', '/cart')
                    checkLogin(encodeURIComponent(url), true)
                })
                .catch(handleError)
        }
    }, [
        list,
        onlyProductType,
        getToken,
        buy,
        checkLogin,
        handleError,
        checkoutLogEvent,
        setStep,
        navigate,
        couponCode
    ])

    const productIds = useMemo(() => {
        const ids: string[] = []
        list?.forEach(({ items }) => {
            items.forEach(({ productId }) => ids.push(productId))
        })
        return ids
    }, [list])

    const allIsVirtual = useMemo(() => list?.[0]?.items?.every(item => item.virtual === true), [list])

    return (
        <Spin spinning={loading}>
            <Container>
                {list.length > 0 && (
                    <>
                        <Cart>
                            <div className="header">
                                <MyCart>My Cart</MyCart>
                                <span onClick={() => navigate('/products')}>
                                    {`Continue Shopping`}
                                    <img src={RightOutlined} alt="" />
                                </span>
                            </div>
                            <List
                                list={list}
                                deleteItem={handleDeleteItem}
                                reduceQuantity={reduceQuantity}
                                updateQuantity={updateQuantity}
                            />
                            <div className="couponCode">
                                <CouponCode
                                    ref={couponCodeRef}
                                    productIds={productIds}
                                    defaultValue={couponCode}
                                    onApply={handleApply}
                                />
                            </div>
                        </Cart>
                        <SummaryWrapper>
                            <div className="header">Order summary</div>
                            <div className="subtotal">
                                <div className="label">Subtotal</div>
                                <div className="value">${orderPrice?.subTotal}</div>
                            </div>
                            {orderPrice && (
                                <>
                                    {couponCode && (
                                        <div className="item" style={{ marginTop: '4rem' }}>
                                            <div className="label">Promo Discount</div>
                                            <div className="value promoCodeValue">
                                                {orderPrice?.discount === '0.00'
                                                    ? 'TBD'
                                                    : `- $${orderPrice?.discount}`}
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                            {!allIsVirtual && (
                                <div className="item">
                                    <div className="label">Shipping</div>
                                    <div className="value">
                                        {!orderPrice || orderPrice?.delivery === '0.00'
                                            ? 'TBD'
                                            : `$${orderPrice?.delivery}`}
                                    </div>
                                </div>
                            )}
                            {!allIsVirtual && (
                                <div className="item">
                                    <div className="label salesTax">
                                        Sales Tax{' '}
                                        <Tooltip
                                            title="Your final taxes will be shown in Checkout."
                                            color="#000"
                                            trigger={['hover', 'click', 'focus']}
                                        >
                                            <img src={Circle} alt="" />
                                        </Tooltip>
                                    </div>
                                    <div className="value">
                                        {!orderPrice || orderPrice?.tax === '0.00'
                                            ? 'TBD'
                                            : `$${orderPrice?.tax}`}
                                    </div>
                                </div>
                            )}
                            {!allIsVirtual && orderPrice && orderPrice?.depositTotal !== '0.00' && (
                                <div className="item">
                                    <div className="label">Deposit</div>
                                    <div className="value">${orderPrice?.depositTotal}</div>
                                </div>
                            )}
                            <div className="total">
                                <div className="label">Total</div>
                                <div className="value">${orderPrice?.total}</div>
                            </div>
                            <Button style={{ width: '100%' }} onClick={handleCheckout}>
                                Checkout
                            </Button>
                        </SummaryWrapper>
                    </>
                )}
                {list.length <= 0 && (
                    <NoData>
                        <div className="header">
                            <MyCart>My Cart</MyCart>
                        </div>
                        <div className="main">
                            <h3>Cart is empty</h3>
                            <span onClick={() => navigate('/products')}>Continue Shopping</span>
                        </div>
                    </NoData>
                )}
            </Container>
        </Spin>
    )
}

const MyCart = styled.div`
    font-family: Roboto-Medium;
    color: #000000;
    letter-spacing: 0;
    font-size: 24px;
`

const Container = styled(PageContainer)`
    width: 100%;
    padding: 3rem 10rem;
    display: flex;
    @media (max-width: 1024px) {
        flex-wrap: wrap;
        padding: 0 2rem 70px;
        width: 100%;
        min-width: auto;
        .list {
            min-width: auto !important;
        }
    }
`

const Cart = styled.div`
    flex-grow: 999;
    @media (max-width: 1024px) {
        width: 100%;
        padding: 0 1rem;
    }
    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 10px 0 16px;
        font-family: Roboto-Medium;
        color: #000000;
        letter-spacing: 0;
        text-align: center;
        font-weight: 500;

        span {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;
            font-size: 18px;
            img {
                width: 20px;
                height: 20px;
                margin-left: 10px;
            }
        }
    }
    .couponCode {
        width: 320px;
        margin-top: 40px;
    }
`

const SummaryWrapper = styled.div`
    flex: 0 0 320px;
    margin-left: 76px;
    .header {
        padding: 10px 0 16px;
        margin-bottom: 32px;
        font-size: 24px;
        color: #000000;
        font-weight: 500;
        font-family: Roboto-Medium;
        border-bottom: 1px solid rgba(47, 46, 46, 1);
    }
    .line {
        width: 100%;
        height: 1px;
        background-color: rgba(47, 46, 46, 1);
    }
    .subtotal {
        display: flex;
        margin-bottom: 4rem;
        width: 100%;
        font-family: Roboto-Regular;
        font-size: 18px;
        color: #000000;
        letter-spacing: 0;
        line-height: 20px;
        font-weight: 400;
        .label {
            text-align: left;
            flex: 2;
        }
        .value {
            text-align: right;
        }
    }
    .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4rem;
        font-family: Roboto-Regular;
        font-size: 18px;
        color: #000000;
        letter-spacing: 0;
        line-height: 20px;
        font-weight: 400;
        .salesTax {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            img {
                width: 16px;
                height: 16px;
                margin-left: 11px;
            }
        }
    }
    .promoCodeValue {
        font-family: Roboto-Regular;
        font-size: 18px;
        color: #ce5324;
        letter-spacing: 0;
        line-height: 20px;
    }
    .estimate {
        padding-bottom: 22px;
        box-sizing: border-box;
        text-decoration: underline;
        cursor: pointer;
        color: rgb(47, 46, 46);
        border-bottom: 1px solid rgba(47, 46, 46, 0.2);
    }
    .total {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        padding-top: 20px;
        padding-bottom: 50px;
        border-top: 1px solid rgba(47, 46, 46, 1);
        font-family: Roboto-Medium;
        font-size: 24px;
        color: #000000;
        letter-spacing: 0;
        line-height: 28px;
        font-weight: 500;
    }
    @media (max-width: 1024px) {
        width: 100%;
        flex: auto;
        margin-left: 0;
        padding: 0 1rem;
        .header {
            padding: 24px 0;
            margin-bottom: 40px;
        }
        .total {
            margin-top: 40px;
            padding-top: 24px;
        }
        .subtotal {
            padding-bottom: 0;
        }
    }
`

const NoData = styled.div`
    width: 100%;
    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 10px 0 16px;
        color: rgb(47, 46, 46);
        font: normal normal normal 15px/18px wfont_22d54f_86d6ed8867c64060a9c7700fa62d226c,
            wf_86d6ed8867c64060a9c7700fa, orig_roboto_condensed;
        font-size: 20px;
    }
    .main {
        padding: 175px 0;
        text-align: center;
        border-top: 1px solid rgba(47, 46, 46, 0.2);
        border-bottom: 1px solid rgba(47, 46, 46, 0.2);
        h3 {
            display: block;
            margin-bottom: 15px;
            font-size: 22px;
            line-height: 1.1;
        }
        span {
            color: rgba(47, 46, 46, 1);
            font-size: 15px;
            text-decoration: underline;
            cursor: pointer;
        }
    }
`
