import React, { FC } from 'react'
import styled from '@emotion/styled'

export const Tags: FC<{ tags: string[] }> = ({ tags }) => {
    return (
        <>
            {!!tags.length && (
                <TagsWrapper>
                    {tags.map((tag, index) => (
                        <div className="tag-item" key={index}>
                            {tag}
                        </div>
                    ))}
                </TagsWrapper>
            )}
        </>
    )
}

const TagsWrapper = styled.div`
    font-family: Roboto-Regular;
    font-size: 20px;
    color: #000;
    letter-spacing: 0;
    display: flex;
    align-items: center;
    cursor: default;
    margin: 39px 0 30px;
    flex-wrap: wrap;
    .tag-item {
        /* height: 40px; */
        border: 1px solid #979797;
        border-radius: 12px;
        padding: 2px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        margin-bottom: 12px;
    }
    @media (max-width: 768px) {
        margin: 0 0 20px;
        font-size: 16px;
        display: flex;
        flex-wrap: wrap;
        .tag-item {
            margin-top: 16px;
            height: 28px;
            padding: 0 10px;
            border-radius: 8px;
            margin-right: 10px;
        }
    }
`
