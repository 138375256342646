import { useCallback, useMemo, useState } from 'react'

import { useI18n } from 'core/language/api'
import { useCartList } from 'core/cart/api'
import { CartItem, CartItemErrorEnum, CartListRendered } from 'core/cart/models'
import { useCheckout } from 'core/checkout/api'
import { getToken } from 'shared/config'
import { convertToDollar } from 'shared/utils'

type SelectedMap = Record<string, boolean>

export const useCartRender = () => {
    const {
        methods: { t }
    } = useI18n()

    const {
        methods: { setItems: setCheckoutItems }
    } = useCheckout()

    const {
        empty,
        cartList,
        ineffectiveList: ineffectiveItems,
        loading,
        methods: cartListMethods
    } = useCartList()

    const [selected, setSelected] = useState<SelectedMap>({})
    const [ineffectiveSelected, setIneffectiveSelected] = useState<SelectedMap>({})
    const [manageStatus, setManageStatus] = useState<boolean>(false)
    const toggleManageStatus = useCallback(() => setManageStatus(prevState => !prevState), [])

    const items = useMemo(() => {
        const items = cartList.reduce<CartItem[]>((acc, { items }) => [...acc, ...items], [])
        setSelected(() => {
            return items.reduce<SelectedMap>((acc, { code }) => ({ ...acc, [code]: true }), {})
        })
        return items
    }, [cartList])

    const selectedItems = useMemo(() => items.filter(({ code }) => selected[code]), [items, selected])

    const isAllItemSelected = useMemo(() => {
        if (!items.length) {
            setManageStatus(false)
            return manageStatus
        }
        return !items.find(item => !selected[item.code])
    }, [items, selected, manageStatus])

    const isAllIneffectiveItemSelected = useMemo(() => {
        if (!ineffectiveItems.length) {
            return manageStatus
        }
        return !ineffectiveItems.find(item => !ineffectiveSelected[item.code])
    }, [ineffectiveItems, ineffectiveSelected, manageStatus])

    const isAllSelected = useMemo(() => {
        if (!manageStatus) {
            return !empty && isAllItemSelected
        }
        return !empty && isAllItemSelected && isAllIneffectiveItemSelected
    }, [empty, isAllItemSelected, isAllIneffectiveItemSelected, manageStatus])

    const onSelectAllItems = useCallback(() => {
        setSelected(() => {
            return items.reduce<SelectedMap>((acc, { code }) => ({ ...acc, [code]: true }), {})
        })
    }, [items])

    const onSelectAllIneffectiveItems = useCallback(() => {
        setIneffectiveSelected(() => {
            return ineffectiveItems.reduce((acc, { code }) => {
                return { ...acc, [code]: true }
            }, {})
        })
    }, [ineffectiveItems])

    const onSelectAll = useCallback(() => {
        if (!manageStatus) {
            if (isAllItemSelected) {
                setSelected({})
            } else {
                onSelectAllItems()
            }
        } else {
            if (isAllSelected) {
                setSelected({})
                setIneffectiveSelected({})
            } else {
                onSelectAllItems()
                onSelectAllIneffectiveItems()
            }
        }
    }, [isAllItemSelected, isAllSelected, manageStatus, onSelectAllIneffectiveItems, onSelectAllItems])

    const onSelectItem = useCallback((code: string) => {
        setSelected(old => ({ ...old, [code]: !old[code] }))
    }, [])

    const onSelectedIneffectiveItem = useCallback((code: string) => {
        setIneffectiveSelected(old => ({ ...old, [code]: !old[code] }))
    }, [])

    const onSelectStore = useCallback(
        (storeId: string) => {
            const items = cartList.find(item => item.store.id === storeId)!.items
            const storeSelected = !items.find(item => !selected[item.code])
            const newSelected = items.reduce(
                (acc, { code }) => ({ ...acc, [code]: !storeSelected }),
                selected
            )
            setSelected(newSelected)
        },
        [cartList, selected]
    )

    const list: CartListRendered[] = useMemo(() => {
        return cartList.map(itemOfStore => {
            const items = itemOfStore.items.map(item => ({
                ...item,
                checked: selected[item.code],
                disabled: !manageStatus && item.error?.scope === CartItemErrorEnum.SKU
            }))
            const storeSelected = !items.find(item => !selected[item.code])
            return { store: { ...itemOfStore.store, checked: storeSelected }, items }
        })
    }, [cartList, manageStatus, selected])

    const ineffectiveList: CartListRendered['items'] = useMemo(
        () =>
            ineffectiveItems.map(item => ({
                ...item,
                checked: ineffectiveSelected[item.code],
                disabled: !manageStatus
            })),
        [ineffectiveItems, ineffectiveSelected, manageStatus]
    )

    const buy = useCallback(() => {
        const lowStocks = []
        const reselectSpecs = []
        const purchaseRestrictions = []
        const checkoutItems: CartListRendered[] = list
            .map(itemOfStore => {
                const items = itemOfStore.items
                    .filter(item => {
                        if (item.checked) {
                            switch (item?.error?.scope) {
                                case 'stock':
                                    lowStocks.push(item)
                                    break
                                case 'sku':
                                    reselectSpecs.push(item)
                                    break
                                case 'PR':
                                    purchaseRestrictions.push(item)
                                    break
                            }
                        }
                        return item.checked // Return to the selected
                    })
                    .map(item => {
                        return {
                            ...item,
                            price: convertToDollar(item.price)
                        }
                    })
                return { ...itemOfStore, items }
            })
            .filter(itemOfStore => !!itemOfStore.items.length)
        // No product selected
        if (!checkoutItems.length) return Promise.reject(t('client.cart.warning.no_products_selected'))
        // Stock shortage judgment
        if (lowStocks.length) return Promise.reject(t('client.cart.warning.out_of_stock'))
        // Need to reselect sku
        if (reselectSpecs.length) return Promise.reject(t('client.cart.warning.reselect_specs'))
        // Product failure, shopping cart can not be selected, no prompt processing
        // Exceeding the product limit
        if (purchaseRestrictions.length) return Promise.reject(t('error.purchase_restrictions'))

        setCheckoutItems(checkoutItems)
        return Promise.resolve()
    }, [list, t, setCheckoutItems])

    const batchDelete = useCallback(() => {
        const codes = [...Object.keys(selected), ...Object.keys(ineffectiveSelected)]
        return cartListMethods.batchDeleteItem(codes)
    }, [cartListMethods, ineffectiveSelected, selected])

    const clearIneffectiveItems = useCallback(() => {
        let codes
        if (getToken()) {
            codes = ineffectiveItems.map(({ code }) => code)
        } else {
            codes = ineffectiveItems.map(({ productId }) => productId)
        }
        return cartListMethods.batchDeleteItem(codes)
    }, [cartListMethods, ineffectiveItems])

    const totalNum = useMemo(
        () => selectedItems.reduce((acc, { quantity }) => acc + quantity, 0),
        [selectedItems]
    )

    const totalPrice = useMemo(
        () =>
            convertToDollar(
                Number(
                    selectedItems.reduce(
                        (acc, { quantity, price }) => acc + quantity * Number(price) * 100,
                        0
                    ) / 100
                ).toFixed(2)
            ),
        [selectedItems]
    )

    return {
        empty,
        list,
        ineffectiveList,
        loading,
        isAllSelected,
        manageStatus,
        totalNum,
        totalPrice,
        methods: {
            ...cartListMethods,
            onSelectItem,
            onSelectStore,
            toggleManageStatus,
            onSelectedIneffectiveItem,
            onSelectAll,
            batchDelete,
            clearIneffectiveItems,
            buy
        }
    }
}
