import React, { CSSProperties, FC, PropsWithChildren } from 'react'

interface SectionContainerProps {
    style?: CSSProperties
    className?: string
}

export const SectionContainer: FC<PropsWithChildren<SectionContainerProps>> = ({
    children,
    style,
    className
}) => {
    return <div children={children} style={style} className={className} />
}
