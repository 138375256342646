import { Language } from 'core/language/models'
import { Delivery } from 'core/delivery/models'
import { Flow, Order, OrderShipping, OrderStatusEnum } from 'core/order/models'
import { convertToOrderPriceByOrder, OrderPriceByOrderDTO } from 'core/checkout/data-access'
import {
    convertDateFromTimestamp,
    convertTimestampToDateWithTz,
    convertToDollar,
    convertToDurations,
    convertToImageUrl,
    convertToTimeZoneString,
    getRootUrlPrefix
} from 'shared/utils'

export interface StoreReference {
    id: string
    name: string
    icon: string
    type: string
}

export interface OrderItemDTO {
    type: number
    productId: string
    skuId: string
    quantity: number
    originUnitPrice: string
    originTotalPrice: string
    depositTotalPrice: string
    depositUnitPrice: string
    rentTimeZone: string
    rentDuration: string
    rentDurationFormat: string
    snapshot: {
        name: string
        image: string
        language: Language
        spec: { attrName: string; valueName: string }[]
    }
}

export interface OrderDTO {
    type: number
    code: string
    storeId: string
    deliveryDetail: Omit<Delivery, 'id' | 'isDefault'>
    items: OrderItemDTO[]
    status: OrderStatusEnum
    flow: Flow[]
    price: OrderPriceByOrderDTO
    createTime: number
    shipping: OrderShipping
    sellerComment: string
    userComment: string
    virtual: boolean
    couponCode: string
    billDeliveryDetail: Delivery
}

export const convertToOrders = async (dtos: OrderDTO[], references: StoreReference[]): Promise<Order[]> => {
    const prefix = await getRootUrlPrefix()
    return dtos.map(dto => {
        return convertToOrderSync(dto, references, prefix)
    })
}

export const convertToOrder = async (dto: OrderDTO, references: StoreReference[]): Promise<Order> => {
    const prefix = await getRootUrlPrefix()
    return convertToOrderSync(dto, references, prefix)
}

const convertToOrderSync = (dto: OrderDTO, references: StoreReference[], imgPrefix: string): Order => {
    const flowTimes = dto.flow.reduce((acc, { status, createTime }) => {
        return { ...acc, [status]: createTime }
    }, {} as any)
    const payTime = flowTimes[OrderStatusEnum.PAID]
    const deliveryTime = flowTimes[OrderStatusEnum.DELIVERED]
    const confirmTime = flowTimes[OrderStatusEnum.FINISH]
    const cancelTime = flowTimes[OrderStatusEnum.CANCEL]

    const storeRef = references.find(item => item.type === 'store' && item.id === dto.storeId)!

    return {
        ...dto,
        createTime: convertDateFromTimestamp(dto.createTime),
        payTime: payTime ? convertDateFromTimestamp(payTime) : null,
        deliveryTime: deliveryTime ? convertDateFromTimestamp(deliveryTime) : null,
        confirmTime: confirmTime ? convertDateFromTimestamp(confirmTime) : null,
        cancelTime: cancelTime ? convertDateFromTimestamp(cancelTime) : null,
        store: { ...storeRef, icon: convertToImageUrl(imgPrefix, storeRef?.icon as string) },
        price: convertToOrderPriceByOrder(dto.price),
        items: dto.items.map(item => ({
            ...item,
            originUnitPrice: convertToDollar(item.originUnitPrice),
            timeZone: convertToTimeZoneString(item.rentTimeZone),
            duration: item.rentDuration,
            durations:
                item.rentTimeZone && item.rentDuration
                    ? convertToDurations(item.rentDuration).map(timestamp =>
                          convertTimestampToDateWithTz(timestamp, item.rentTimeZone)
                      )
                    : [],
            depositTotalPrice: convertToDollar(item.depositTotalPrice),
            depositUnitPrice: convertToDollar(item.depositUnitPrice),
            snapshot: {
                ...item.snapshot,
                specText: item.snapshot.spec.map(({ valueName }) => valueName).join('/'),
                image: convertToImageUrl(imgPrefix, item.snapshot.image)
            }
        })),
        hasActions: [
            OrderStatusEnum.UNPAY,
            OrderStatusEnum.DELIVERED,
            OrderStatusEnum.CANCEL,
            OrderStatusEnum.AUTO_CANCEL
        ].includes(dto.status)
    }
}
