import { on, reducer as createReducer } from 'ts-action'
import { setCoupon, clearCoupon } from './actions'

import { FEATURE_NAME } from './constants'

import { actions as authActions } from 'core/auth/state'

export interface State {
    code: string
}

export interface GlobalState {
    [FEATURE_NAME]: State
}

export const initialState: State = {
    code: ''
}

export const reducer = createReducer(
    initialState,
    on(setCoupon, (_, { payload: { code } }) => {
        return {
            code
        }
    }),
    on(clearCoupon, () => initialState),
    on(authActions.clearUser, () => initialState)
)
