import React, { FC, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'

import { useMemoizedFn, useSignInRedirect } from 'shared/hooks'
import { getIsTourist } from 'shared/config'

interface Props {
    isUpload: boolean
}

export const SignWrapper: FC<Props> = ({ isUpload }) => {
    const navigate = useNavigate()

    const { loginOut } = useSignInRedirect()
    const handleSignUp = useMemoizedFn(() => {
        getIsTourist() && loginOut()
        navigate('/auth/sign_up?redirect=/management')
    })
    const handleSignIn = useMemoizedFn(() => {
        getIsTourist() && loginOut()
        navigate('/auth/sign_in?redirect=/management')
    })

    const note = useMemo(() => (isUpload ? "Can't upload file" : "Can't connect to wallet"), [isUpload])

    return (
        <SignUpWrapper>
            <SignUpNote>
                <div>
                    {note}, please <SignIn onClick={handleSignIn}>sign into your account</SignIn>.
                </div>
                <div>Don't have an account yet? Sign up here.</div>
            </SignUpNote>
            <SignUpButton onClick={handleSignUp}>Sign Up</SignUpButton>
        </SignUpWrapper>
    )
}

const SignUpButton = styled.div`
    margin: 40px auto;
    width: 370px;
    height: 50px;
    background: #000000;
    border-radius: 35px;
    font-family: Roboto-Medium;
    font-size: 25px;
    line-height: 50px;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    @media (max-width: 768px) {
        width: 222px;
        height: 50px;
        line-height: 50px;
        margin: 30px auto;
        font-family: OpenSans-SemiBold;
        font-size: 16px;
    }
`

const SignIn = styled.span`
    color: #2e69bd;
    text-decoration: underline;
    cursor: pointer;
`

const SignUpNote = styled.div`
    font-family: Montserrat-SemiBold;
    font-size: 24px;
    color: #4a4a4a;
    text-align: center;
    line-height: 34px;
    font-weight: 600;
    @media (max-width: 768px) {
        font-size: 18px;
        padding: 0 40px;
    }
`

const SignUpWrapper = styled.div`
    padding: 90px 0 80px;
    @media (max-width: 768px) {
        padding: 45px 0 50px;
    }
`
