import styled from '@emotion/styled'
import React, { FC } from 'react'
import { useResponsive } from 'shared/hooks'
// import { Image } from 'antd'
export const Boss: FC = () => {
    const { md } = useResponsive()

    return (
        <div className="container mx-auto px-4">
            <div className="py-8">
                <div className={md ? 'flex justify-center' : 'flex-col'}>
                    {/* <div className="w-1/3">
                        <Image
                            src="https://placehold.co/300x300"
                            alt="A professional headshot of a smiling man in a suit"
                            className="rounded-full"
                            preview={false}
                        />
                        <Text>jravitch@raine.com</Text>
                        <Text>(212) 603-5508</Text>
                    </div> */}
                    <div className="pl-8">
                        <Title>公司人事部全副助人</Title>
                        <Text>
                            Joe是Raine的联合创始人兼合伙人。Raine的所有业务中都有Joe活跃的身影。Joe以前和现控股的身份，
                            与全球知名大型媒体集团、体育和数字娱乐集团以及领域领先的消费技术公司高层合作。Joe的办公室位于纽约
                            的和洛杉矶，同时也经常前往亚洲、中东和欧洲新兴业务。他在Propagate Media, ReeferLLC
                            和 Imagine
                            Entertainment(均为Raine的投资公司)董事会任职，同时也在新加坡政府投资公司和泰国最大企
                            业团。
                        </Text>
                        <Text>
                            在加入Raine之前，Joe曾担任摩根士丹利的资深合伙人，在企业、香港和纽约从事体育、娱乐和自由行业的投资和咨询达16年之久，期间参与多项重大业务，如创建YESNetwork、出售某体育感应技术公司给索尼和创立NBPA官。在高盛任职期间，Joe负责创建媒体娱乐业务，包括亚洲NTM业务。体育业务和娱乐金融部门。
                            在加入高盛之前，Joe曾在纽约、伦敦和新泽西州的Cleary Gottlieb Steen &
                            HamiltonLLP的律师，期间负责的公司涉及了俱乐部业务。此外，Joe还曾在纽约的米高梅在PierreN.Leval的协已。
                        </Text>
                        <Text>Joe以优异成绩毕业于阿尔伯特学院，并获得了耶鲁法学院的法学博士学位。</Text>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Text = styled.div`
    font-size: 1.6rem;
    margin-top: 2rem;
`

const Title = styled.div`
    font-size: 3rem;
`
