import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'

// Initialize Firebase
const firebaseConfig = {
    apiKey: 'AIzaSyBxiW8HeNW3VvHbWBYiyKjxFEeOdV8uPqg',
    authDomain: 'metaframe-fc33f.firebaseapp.com',
    projectId: 'metaframe-fc33f',
    storageBucket: 'metaframe-fc33f.appspot.com',
    messagingSenderId: '981534804476',
    appId: '1:981534804476:web:67b80959a28a362e1d4a79',
    measurementId: 'G-VP7PSCN824'
}

const app = initializeApp(firebaseConfig)

const auth = getAuth(app)
const analytics = getAnalytics(app)
logEvent(analytics, 'notification_received')

export { analytics, auth }
