import { useCallback } from 'react'

import { useCheckout } from 'core/checkout/api'
import { useDetailState, useMountedPromiseFn } from 'shared/hooks'
import { CartItem, CartItemGroupByStore } from 'core/cart/models'
import { services } from 'core/order/data-access'
import { personalInfoItem, OrderFormData, OrderPrice, TouristOrderFormData } from 'core/order/models'
import { useI18n } from 'core/language/api'
import { CheckoutRequestItem } from '../../../../checkout/models'
import { Delivery } from 'core/delivery/models'
import { getPaymentClientSecret } from 'shared/config'

const generateRequestData = (items: CartItemGroupByStore[]) => {
    return items
        .reduce<CartItem[]>((acc, { items }) => [...acc, ...items], [])
        .map(({ productId, skuId, quantity, virtual, timeZone, duration }) => ({
            productId,
            skuId,
            quantity,
            virtual,
            timeZone,
            duration
        }))
}

export const useCreateOrder = () => {
    const { lang } = useI18n()

    const [state, setState] = useDetailState<OrderPrice>()

    const {
        detail: orderItems,
        methods: { calculate, setItems }
    } = useCheckout()

    const calculatePrice = useCallback(
        (deliveryId: string, couponCode?: string, personalInfo?: personalInfoItem) => {
            setState({ loading: true })
            const items = generateRequestData(orderItems || [])
            return calculate(deliveryId, items, couponCode, personalInfo)
                .then(detail => {
                    setState({ detail, loading: false })
                    return detail
                })
                .catch(err => {
                    setState({ loading: false })
                    return Promise.reject(err)
                })
        },
        [calculate, orderItems, setState]
    )

    const calculatePriceByCart = useCallback(
        (items: CheckoutRequestItem[], couponCode?: string) => {
            setState({ loading: true })
            return calculate('', items, couponCode)
                .then(detail => {
                    setState({ detail, loading: false })
                    return detail
                })
                .catch(err => {
                    setState({ loading: false })
                    return Promise.reject(err)
                })
        },
        [calculate, setState]
    )

    const createApi = useMountedPromiseFn(services.create)
    const create = useCallback(
        (
            orderItems: CartItemGroupByStore[],
            commentOfStore: Record<string, string>,
            couponCode: string = '',
            deliveryId?: string,
            billingDeliveryId?: string
        ) => {
            setState({ loading: true })
            const formData: OrderFormData = {
                deliveryId,
                items: generateRequestData(orderItems),
                commentOfStore,
                couponCode,
                billingDeliveryId,
                clientSecret: getPaymentClientSecret()
            }
            return createApi(formData, lang)
                .then(orderIds => {
                    setState({ loading: false })
                    return orderIds
                })
                .catch(err => {
                    setState({ loading: false })
                    return Promise.reject(err)
                })
        },
        [createApi, lang, setState]
    )

    const touristCreateApi = useMountedPromiseFn(services.touristCreate)
    const touristCreate = useCallback(
        (
            orderItems: CartItemGroupByStore[],
            commentOfStore: Record<string, string>,
            couponCode: string = '',
            deliveryType: number,
            selectedDelivery: Delivery,
            billingAddress?: Delivery
        ) => {
            setState({ loading: true })
            const formData: TouristOrderFormData = {
                items: generateRequestData(orderItems),
                commentOfStore,
                couponCode,
                deliveryType,
                personalInfo: {
                    firstName: selectedDelivery.firstName,
                    lastName: selectedDelivery.lastName,
                    companyName: selectedDelivery.companyName,
                    streetAddress: selectedDelivery.streetAddress,
                    apartment: selectedDelivery.apartment,
                    city: selectedDelivery.city,
                    state: selectedDelivery.state,
                    zipCode: selectedDelivery.zipCode,
                    phone: selectedDelivery.phone,
                    email: selectedDelivery.email ? selectedDelivery.email : ''
                },
                billInfo: billingAddress
                    ? {
                          firstName: billingAddress.firstName,
                          lastName: billingAddress.lastName,
                          companyName: billingAddress.companyName,
                          streetAddress: billingAddress.streetAddress,
                          apartment: billingAddress.apartment,
                          city: billingAddress.city,
                          state: billingAddress.state,
                          zipCode: billingAddress.zipCode,
                          phone: billingAddress.phone
                      }
                    : undefined,
                clientSecret: getPaymentClientSecret()
            }
            return touristCreateApi(formData, lang)
                .then(orderIds => {
                    setState({ loading: false })
                    return orderIds
                })
                .catch(err => {
                    setState({ loading: false })
                    return Promise.reject(err)
                })
        },
        [lang, setState, touristCreateApi]
    )

    return {
        orderPrice: state.detail,
        loading: state.loading,
        orderItems,
        methods: {
            calculatePrice,
            calculatePriceByCart,
            create,
            setItems,
            touristCreate
        }
    }
}
