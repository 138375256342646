import React, { FC, Fragment } from 'react'
import styled from '@emotion/styled'

export interface ButtonProps {
    icon: string
    onClick?: () => void
}

export const OptionButtons: FC<{ buttons: ButtonProps[] }> = ({ buttons }) => (
    <>
        {!!buttons.length && (
            <ListWrapper>
                {buttons.map(({ icon, onClick }, index) => (
                    <Fragment key={index}>
                        {!!index && <div className="space" />}
                        <div className="tag-item" onClick={onClick}>
                            <img src={icon} alt="" />
                        </div>
                    </Fragment>
                ))}
            </ListWrapper>
        )}
    </>
)

const ListWrapper = styled.div`
    display: flex;
    align-items: center;
    .space {
        width: 30px;
    }
    .tag-item {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .img {
            width: 100%;
            height: auto;
        }
    }
`
