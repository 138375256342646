import React, { FC, useCallback, useMemo, useState } from 'react'
import styled from '@emotion/styled'

import { Form, Input } from 'antd'
import { Button, CountryCodePicker } from 'components'

import { PersonalInformationForm } from 'core/scanRent/models'
import { useI18n } from 'core/language/api'
import { getIsTourist, getScanRentPersonalInfo } from 'shared/config'
import { Reg } from 'shared/utils'
import { useDeliveryCreate } from 'core/delivery/api'
import { DeliveryTypeEnum } from 'core/delivery/models'
import { useShowMessage, useSignInRedirect } from 'shared/hooks'
import { CountryCodeList } from 'shared/CountryCode'

import oneIcon from '../../assets/one.png'

export interface PersonalInformationProps {
    initialValues: PersonalInformationForm
    onSubmit: (value: PersonalInformationForm) => void
    ccLocale: string
    setCcLocale: (val: string) => void
    setPersonalInformationId: (val: string) => void
}

export const PersonalInformation: FC<PersonalInformationProps> = ({
    initialValues,
    onSubmit,
    ccLocale,
    setCcLocale,
    setPersonalInformationId
}) => {
    const {
        methods: { t }
    } = useI18n()
    const {
        methods: { showErrorModal }
    } = useShowMessage()
    const [btnDisabled, setBtnDisabled] = useState<boolean>(true)
    const [form] = Form.useForm<PersonalInformationForm>()
    const { getToken } = useSignInRedirect()
    const [isSame, setIsSame] = useState<'' | 'error' | 'success' | 'warning' | 'validating' | undefined>()
    const {
        methods: { create }
    } = useDeliveryCreate(DeliveryTypeEnum.DELIVERY)

    const cc = useMemo(() => CountryCodeList.find(({ locale }) => locale === ccLocale)?.code, [ccLocale])
    const handleSubmit = useCallback(() => {
        form.validateFields().then(values => {
            const { firstName, lastName, phone, emailAddress, confirmEmailAddress } = values
            const emailAddressLC = emailAddress.toLowerCase()
            const confirmEmailAddressLC = confirmEmailAddress.toLowerCase()

            if (emailAddressLC !== confirmEmailAddressLC) {
                showErrorModal(t('error.email_twice_inconsistent'))
                return
            }
            const phoneTrim = phone && phone.trim()
            const formData = {
                ...values,
                phone: phoneTrim,
                firstName: firstName && firstName.trim(),
                lastName: lastName && lastName.trim(),
                emailAddress: emailAddressLC,
                confirmEmailAddress: confirmEmailAddressLC
            }
            if (getToken() && !getIsTourist() && !getScanRentPersonalInfo().firstName) {
                create({
                    ...formData,
                    phone: phone ? `+${cc}${phoneTrim}` : '',
                    companyName: '',
                    streetAddress: '',
                    apartment: '',
                    city: '',
                    state: '',
                    zipCode: '',
                    isDefault: false
                }).then(setPersonalInformationId)
            }
            onSubmit(formData)
        })
    }, [cc, create, form, getToken, onSubmit, setPersonalInformationId, showErrorModal, t])

    const handleValuesChange = (_: Partial<PersonalInformationForm>, allValues: PersonalInformationForm) => {
        const { firstName, lastName, emailAddress, confirmEmailAddress } = allValues
        if (
            emailAddress &&
            emailAddress.toLowerCase() === confirmEmailAddress &&
            confirmEmailAddress.toLowerCase()
        ) {
            setIsSame('success')
        } else {
            setIsSame('warning')
        }
        return setBtnDisabled(!(firstName && lastName && emailAddress && confirmEmailAddress))
    }

    return (
        <Container>
            <Header>
                <img width={24} height={24} src={oneIcon} alt="self-rental" style={{ marginRight: '8px' }} />
                <div className="text">Personal information</div>
            </Header>
            <Explain>
                To send you order confirmation emails and help you solve order related problems, please fill
                in your information.
            </Explain>
            <Form
                form={form}
                onValuesChange={handleValuesChange}
                initialValues={{ ...initialValues }}
                autoComplete="off"
                className="form"
            >
                <Form.Item
                    name="firstName"
                    rules={[{ required: true, message: '' }]}
                    className="formItemleft"
                >
                    <Inputs placeholder="First Name *" className="inputName" />
                </Form.Item>
                <Form.Item name="lastName" rules={[{ required: true, message: '' }]} className="formItem">
                    <Inputs placeholder="Last Name *" className="inputName" />
                </Form.Item>
                <Form.Item
                    name="emailAddress"
                    rules={[
                        {
                            warningOnly: true,
                            validator(_, value) {
                                if (value && Reg.email.test(value)) {
                                    return Promise.resolve()
                                }
                                return Promise.reject()
                            }
                        }
                    ]}
                    className="formItems"
                >
                    <Inputs type="email" placeholder="Email Address *" className="inputName" />
                </Form.Item>
                <Form.Item
                    name="confirmEmailAddress"
                    className="formItems"
                    validateStatus={isSame}
                    rules={[
                        {
                            warningOnly: true,
                            validator(_, value) {
                                if (value && Reg.email.test(value)) {
                                    return Promise.resolve()
                                }
                                return Promise.reject()
                            }
                        }
                    ]}
                >
                    <Inputs type="email" placeholder="Confirm Email Address *" className="inputName" />
                </Form.Item>
                <Form.Item name="phone" className="form-item">
                    <Inputs
                        addonBefore={
                            <CountryCodePicker
                                popupClassName="personal-info-mobile"
                                ccLocale={ccLocale}
                                setCcLocale={setCcLocale}
                            />
                        }
                        placeholder="Phone Number"
                        className="selectName"
                    />
                </Form.Item>
                <Form.Item className="formItemBtn">
                    <SubButton
                        onClick={handleSubmit}
                        disabled={getScanRentPersonalInfo().isShow ? false : btnDisabled}
                    >
                        Next
                    </SubButton>
                </Form.Item>
            </Form>
        </Container>
    )
}

const Container = styled.div`
    .selectName {
        font-family: Roboto-Regular;
        font-size: 16px;
        color: #000000;
        letter-spacing: 0;
        line-height: 16px;
        .ant-select-arrow {
            color: #000 !important;
        }
        .ant-input-group-addon {
            padding-right: 0 !important;
        }
        .ant-input {
            border-left: none !important;
        }
        .ant-select-selector {
            border: none !important;
        }
        @media (max-width: 768px) {
            margin: 0;
        }
    }
`

const Header = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .text {
        font-family: HelveticaNeue-CondensedBold;
        font-size: 24px;
        color: #000000;
        line-height: 24px;
        font-weight: 700;
    }
`

const Explain = styled.div`
    font-family: Roboto-Regular;
    font-size: 16px;
    color: #000000;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 30px;
`

const SubButton = styled(Button)`
    width: 100%;
    height: 50px;
    border-radius: 36px;
    font-family: Roboto-Medium;
    font-size: 25.33px;
    color: #fff;
    text-align: center;
    line-height: 50px;
    font-weight: 500;
    margin-top: 26px;
    cursor: pointer;
`

const Inputs = styled(Input)`
    height: 50px;
    border-radius: 10px;
    .select-before {
        height: 50px;
        .ant-select-selection-search {
            height: 50px !important;
        }
    }
    .ant-input-group-addon {
        height: 50px !important;
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
        .ant-select-selector {
            height: 50px !important;
            border-top-left-radius: 10px !important;
            border-bottom-left-radius: 10px !important;
            .ant-select-selection-item {
                height: 50px !important;
                line-height: 50px !important;
            }
        }
    }
    .ant-input {
        height: 50px !important;
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }
`
