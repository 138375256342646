import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'

import { useHotProducts } from 'core/product/api'
import { useI18n } from 'core/language/api'
import { useResponsive } from 'shared/hooks'

import { Carousel } from 'antd'
import { DivImage, SectionTitle } from 'components'

import type { CarouselRef } from 'antd/es/carousel'

import leftArrow from './assets/left-arrow.png'
import rightArrow from './assets/right-arrow.png'

interface ListingSliderProps {
    isLoading: boolean
}

export const ListingSlider: FC<ListingSliderProps> = () => {
    const navigate = useNavigate()

    const {
        list,
        methods: { getList }
    } = useHotProducts()
    useEffect(() => {
        getList()
    }, [getList])

    const handleViewAll = useCallback(() => navigate('/products'), [navigate])

    const {
        methods: { t }
    } = useI18n()

    const carouselRef = useRef<CarouselRef>(null)
    const { xs, sm, md, lg, xl } = useResponsive()
    // console.log(xs, sm, md, lg, xl)
    const showNum = useMemo(() => {
        if (xl) return 5
        if (lg) return 4
        if (md) return 3
        if (sm) return 3
        if (xs) return 2
        return 1
    }, [lg, md, sm, xl, xs])
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: showNum,
        slidesToScroll: showNum
    }

    return (
        <Wrapper className="px-12 md:px-0">
            <Header className="mb-20">
                <SectionTitle className="mb-0" title="Our Products" />
                <ViewAllButton className="mb-0 text-center cursor-pointer" onClick={handleViewAll}>
                    {t('View All')}
                </ViewAllButton>
            </Header>
            <>
                <div className="md:px-32">
                    <Carousel {...settings} ref={carouselRef}>
                        {list.map(({ id, name, images, showPrice }) => (
                            <div key={id}>
                                <div className="flex flex-col items-center">
                                    <div
                                        className="cursor-pointer"
                                        onClick={() => navigate(`/products/${id}`)}
                                    >
                                        <DivImage
                                            className="mx-auto my-0"
                                            src={images[0]}
                                            width={20}
                                            height={20}
                                        />
                                        <div className="hover:underline">
                                            <GoodsName>{name}</GoodsName>
                                            <GoodsPrice>${showPrice[0]}</GoodsPrice>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
                {md && (
                    <>
                        <ArrowLeft onClick={() => carouselRef.current?.prev()} />
                        <ArrowRight onClick={() => carouselRef.current?.next()} />
                    </>
                )}
            </>
        </Wrapper>
    )
}

const ViewAllButton = styled.h1`
    width: 11rem;
    height: 4.4rem;
    font-family: Roboto-Medium;
    font-size: 1.8rem;
    color: #000;
    line-height: 4.4rem;
    border: 1px solid rgba(0, 0, 0, 1);
    border-radius: 22px;
`

const GoodsPrice = styled.div`
    font-family: HelveticaNeue-Bold;
    font-size: 16px;
    color: #2f2e2e;
    line-height: 20px;
    font-weight: bold;
`

const GoodsName = styled.div`
    margin: 30px 0 8px 0;
    font-family: HelveticaNeue-Medium;
    font-size: 14px;
    color: #2f2e2e;
    line-height: 16px;
`

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    margin-top: 160px;
    @media (max-width: 1024px) {
        margin-top: 50px;
    }
`

const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const ArrowLeft = styled.div`
    position: absolute;
    top: 50%;
    left: 0;
    width: 5.4rem;
    height: 5.4rem;
    background: url(${leftArrow}) no-repeat;
    background-size: 100%;
    cursor: pointer;
`

const ArrowRight = styled.div`
    position: absolute;
    top: 50%;
    right: 0;
    width: 5.4rem;
    height: 5.4rem;
    background: url(${rightArrow}) no-repeat;
    background-size: 100%;
    cursor: pointer;
`
