// import { actions as authActions, selectors as authSelectors } from 'modules/auth/store';
import { urlQueryString, pathJoin } from 'shared/utils'

import Axios from 'axios'

export const pickValue = <T>(res: Response<SuccessItemResponse<T>>) => res.data.data.value
export const pickData = <T>(res: Response<SuccessResponse<T>>) => res.data.data
export const picknftList = <T>(res: Response<SuccessItemResponseNFT<T>>) => res.data.data?.nft_list
export const pickValueAndReferences = <T, R>(res: Response<SuccessItemResponse<T, R>>) => ({
    value: res.data.data.value,
    references: res.data.data.references
})

export const pickResponseTextMsg = (err: any) => {
    try {
        let { msg } = JSON.parse(err.xhr.responseText)
        return Promise.reject(msg)
    } catch (e) {
        let msg = ''
        if (typeof err?.data === 'string' && err.data) {
            msg = err.data
        }
        return Promise.reject(msg || err?.xhr?.responseText?.msg || err?.data?.msg || 'Error')
    }
}

let authFailedCallback = () => {}
export const setAuthFailedCallback = (fn: () => void) => {
    authFailedCallback = fn
}
export interface ErrorResponse {
    status: string
    data: { message: string }
    xhr: any
}
export interface Response<T> {
    data: T
    status: string
    xhr: any
}

export interface SuccessResponse<T> {
    data: T
    code: number
    message: string
}
export interface ListResponse<T, R = never> {
    total: number
    pageNum: number
    pageSize: number
    value: T[]
    references: R[]
}
export interface ItemResponse<T, R = never> {
    value: T
    references: R[]
}
export interface ItemResponseNFT<T, R = never> {
    nft_list: T
    references: R[]
}

export type SuccessItemResponse<T, R = never> = SuccessResponse<ItemResponse<T, R>>
export type SuccessItemResponseNFT<T, R = never> = SuccessResponse<ItemResponseNFT<T, R>>
export type SuccessItemResponseFrames<T, R = never> = SuccessResponse<T>

export interface RequestMethod {
    get: <T>(
        url: string,
        query?: { [key: string]: any },
        headers?: { [key: string]: any }
    ) => Promise<Response<T>>
    post: <T>(
        url: string,
        data: { [key: string]: any },
        headers?: { [key: string]: any }
    ) => Promise<Response<T>>
    put: <T>(
        url: string,
        data: { [key: string]: any },
        headers?: { [key: string]: any }
    ) => Promise<Response<T>>
    patch: <T>(
        url: string,
        data: { [key: string]: any },
        headers?: { [key: string]: any }
    ) => Promise<Response<T>>
    delete: <T>(
        url: string,
        id?: string | number,
        data?: Array<{
            id?: string | number
            owner?: string
            guest?: string
            type?: string
            content?: string
        }>
    ) => Promise<Response<T>>
    batchDelete?: <T>(
        url: string,
        data: Record<string, unknown>,
        headers?: Record<string, unknown>
    ) => Promise<Response<T>>
    addRequestInterceptor: (fn: (p: RequestParams) => RequestParams) => void
}

interface Headers {
    [key: string]: any
}
interface RequestParams {
    url: string
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
    headers: Headers
    data?: { [key: string]: any }
}

export const Request = ({
    urlPrefix,
    getHeaders = () => ({}),
    beforeRequest = p => p
}: {
    urlPrefix: string
    getHeaders?: () => { [key: string]: string }
    beforeRequest?: (p: any) => any
}): RequestMethod => {
    const requestInterceptors: any[] = []
    const addRequestInterceptor = (fn: (p: RequestParams) => RequestParams) => {
        requestInterceptors.push(fn)
    }

    const axiosAjax = <T>(params: any): Promise<Response<T>> => {
        const url = pathJoin(urlPrefix, params.url)
        params = beforeRequest(params)

        const headers: { [key: string]: string } = {
            ...(params.headers || {}),
            ...getHeaders()
        }

        let requestParams: RequestParams = {
            url,
            headers,
            method: params.type,
            data: params.data
        }
        requestParams = requestInterceptors.reduce<RequestParams>((acc, curr) => {
            return curr(acc)
        }, requestParams)
        return (Axios(requestParams) as any as Promise<Response<T>>)
            .then(res => {
                const data = res.data as any
                if (data.code && data.code !== 200) {
                    return Promise.reject(res)
                }
                return res
            })
            .catch(response => {
                response = response?.response || response
                if (response.status === 401 || response?.data?.code === 401) {
                    authFailedCallback()
                    // store.dispatch(authActions.logout() as any);
                }
                return Promise.reject(response)
            })
    }

    const ajaxGet = <T>(url: string, query?: { [key: string]: any }, headers?: { [key: string]: any }) => {
        if (query) {
            const paramsWithoutNull = Object.keys(query).reduce<Partial<T>>((acc, key) => {
                if (typeof query[key] !== 'undefined') {
                    return { ...acc, [key]: query[key] }
                }
                return acc
            }, {})

            url = `${url}?${urlQueryString.stringify(paramsWithoutNull)}`
        }
        const params = {
            type: 'GET',
            url,
            headers
        }
        return axiosAjax<T>(params)
    }

    const ajaxPost = <T>(
        url: string,
        data: { [key: string]: any } = {},
        headers?: { [key: string]: any }
    ) => {
        return axiosAjax<T>({
            type: 'POST',
            url,
            data,
            headers
        })
    }

    const ajaxPut = <T>(url: string, data: { [key: string]: any }, headers?: { [key: string]: any }) => {
        return axiosAjax<T>({
            type: 'PUT',
            url,
            data,
            headers
        })
    }

    const ajaxPatch = <T>(url: string, data: { [key: string]: any }, headers?: { [key: string]: any }) => {
        return axiosAjax<T>({
            type: 'PATCH',
            url,
            data,
            headers
        })
    }

    const ajaxDelete = <T>(
        url: string,
        id?: string | number,
        data?: Array<{
            id?: string | number
            owner?: string
            guest?: string
            type?: string
            content?: string
        }>
    ) => {
        const uri = id ? `${url}/id=${id}` : url
        return axiosAjax<T>({
            type: 'DELETE',
            url: uri,
            data
        })
    }

    const ajaxBatchDelete = <T>(
        url: string,
        data: Record<string, unknown>,
        headers?: Record<string, unknown>
    ) =>
        axiosAjax<T>({
            type: 'DELETE',
            url,
            data,
            headers
        })

    return {
        get: ajaxGet,
        post: ajaxPost,
        put: ajaxPut,
        patch: ajaxPatch,
        delete: ajaxDelete,
        batchDelete: ajaxBatchDelete,
        addRequestInterceptor
    }
}

export const getLanguageHeader = (language: string) => {
    return { 'Accept-Language': language }
}
