import { pathJoin } from 'shared/utils'
import {
    frameHttp,
    SuccessItemResponseFrames,
    pickData,
    SuccessResponse,
    SuccessItemResponse,
    apiHttp,
    pickValue
} from 'shared/request'

import {
    AuthorizedDeletes,
    ConnectFrame,
    Frame,
    FramesAuthDeleteList,
    FramesList
} from 'core/management/frame/model'
import { Language } from 'core/language/models'

const URI = '/device'
const AUTH = '/auth'
const PLAYLISTURI = '/playlist'
const RENT = '/rent/frame'

export const getList = () => frameHttp.get<SuccessItemResponseFrames<FramesList[]>>(URI).then(pickData)
export const getRentList = (language: Language) => {
    return apiHttp.get<SuccessItemResponse<FramesList[]>>(RENT).then(pickValue)
}

export const getDetail = (id: string) => {
    const url = pathJoin(URI, id)
    return frameHttp.get<SuccessResponse<Frame>>(url).then(pickData)
}

export const getRentDetail = (id: string) => {
    const url = pathJoin(RENT, id)
    return apiHttp.get<SuccessItemResponse<Frame>>(url).then(pickValue)
}

export const play = (params: { id: string; type: 'image' | 'video'; device_id: string }) => {
    const url = pathJoin(URI, 'play')
    return frameHttp.put(url, params)
}

export const playSlide = (params: { playlist_id: number; device_id: string }) => {
    const url = pathJoin(URI, 'playslide')
    return frameHttp.put(url, params)
}

export const create = (params: ConnectFrame) => {
    return frameHttp.put<SuccessItemResponse<void>>(URI, { ...params })
}

export const authorizedDelete = (params: AuthorizedDeletes[]) => frameHttp.delete(AUTH, '', params)

export const getPlayListDetail = (id: string) => {
    const url = pathJoin(PLAYLISTURI, id)
    return frameHttp.get<SuccessResponse<Frame>>(url).then(pickData)
}

export const getFrameDeleteList = (type: string) => {
    const url = `${AUTH}?type=${type}`
    return frameHttp.get<SuccessItemResponseFrames<FramesAuthDeleteList[]>>(url).then(pickData)
}
