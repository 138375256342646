import React, { Dispatch, FC, SetStateAction } from 'react'
import styled from '@emotion/styled'

import { isBeforeToday } from 'shared/utils'

import { Checkbox } from 'antd'
import { Text } from '@mantine/core'

import add from 'assets/add.png'
import minus from 'assets/minus.png'

export interface ProductPanelProps {
    name: string
    price: string
    desc: string
    isDaily: boolean
    list: Array<{ label: string; value: string }>
    count: number
    onAddQuantity: () => void
    onReduceQuantity: () => void
    checkedDate: string[]
    setCheckedDate: Dispatch<SetStateAction<string[]>>
    calcPriceForChangeDuration: (durations: string[]) => void
    selectTime: (data: string[]) => void
}

export const ProductPanel: FC<ProductPanelProps> = ({
    name,
    price,
    desc,
    isDaily,
    list,
    count,
    onAddQuantity,
    onReduceQuantity,
    checkedDate,
    setCheckedDate,
    calcPriceForChangeDuration,
    selectTime
}) => {
    return (
        <ProductPanelDiv>
            <NameWrapper>
                <Text weight={500} lineClamp={2} style={{ fontSize: 24, fontFamily: 'Roboto-Medium' }}>
                    {name}
                </Text>
            </NameWrapper>
            <ProductPanelList>
                <MobileDescription dangerouslySetInnerHTML={{ __html: desc }}></MobileDescription>
                {isDaily && (
                    <ProductPanelCheckbox>
                        {list.map(({ label, value }) => {
                            return (
                                <Checkbox
                                    disabled={isBeforeToday(+value)}
                                    checked={checkedDate.includes(value) && !isBeforeToday(+value)}
                                    key={value}
                                    onChange={({ target: { checked } }) => {
                                        setCheckedDate(prevState => {
                                            let newData: string[]
                                            if (checked) {
                                                newData = [
                                                    ...prevState.filter(item => !isBeforeToday(+item)),
                                                    value
                                                ]
                                            } else {
                                                const newState = [...prevState]
                                                newData = newState.filter(
                                                    item => item !== value && !isBeforeToday(+item)
                                                )
                                            }
                                            selectTime(newData)
                                            calcPriceForChangeDuration(newData)
                                            return newData
                                        })
                                    }}
                                    style={{ margin: '0 0 15px 0' }}
                                >
                                    {label}
                                </Checkbox>
                            )
                        })}
                    </ProductPanelCheckbox>
                )}
                <ProductPanelBottom>
                    <ProductPanelBottomPrice>
                        <span>$ </span>
                        <span>{price}</span>
                    </ProductPanelBottomPrice>
                    <ProductPanelBuy>
                        <img
                            className="cursor-pointer"
                            src={minus}
                            alt="minus-img"
                            onClick={onReduceQuantity}
                        />
                        <div>{count}</div>
                        <img className="cursor-pointer" src={add} alt="add-img" onClick={onAddQuantity} />
                    </ProductPanelBuy>
                </ProductPanelBottom>
            </ProductPanelList>
        </ProductPanelDiv>
    )
}

const ProductPanelDiv = styled.div`
    height: auto;
    background: #f5f5f5;
    margin-bottom: 20px;
    padding-bottom: 40px;
`

const MobileDescription = styled.div`
    font-family: Roboto-Medium;
    font-size: 16px;
    color: #303030;
    letter-spacing: 0;
    line-height: 20px;
    font-weight: bold;
`

const NameWrapper = styled.div`
    font-family: Roboto-Medium;
    line-height: 28px;
    height: 96px;
    display: flex;
    padding: 20px 15px 20px 26px;
    align-items: center;
    border-bottom: 1px solid rgba(47, 46, 46, 1);
`

const ProductPanelList = styled.div`
    margin-top: 20px;
    padding: 0 30px;
    img {
        width: 15px;
        height: 15px;
    }
    @media (max-width: 768px) {
        width: 100%;
        /* margin: 20px 10px 40px 10px; */
    }
`

const ProductPanelCheckbox = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 1);
    border-radius: 10px;
    margin-top: 20px;
    padding: 20px 18px 0 18px;
    @media (max-width: 768px) {
        width: 100%;
    }
`

const ProductPanelBottom = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    @media (max-width: 768px) {
        width: 100%;
    }
`

const ProductPanelBottomPrice = styled.div`
    font-family: Roboto-Medium;
    font-size: 24px;
    color: #000000;
    letter-spacing: 0;
    text-align: right;
    line-height: 28px;
`

const ProductPanelBuy = styled.div`
    display: flex;
    align-items: center;
    font-family: Roboto-Medium;
    font-size: 18px;
    color: #303030;
    letter-spacing: 1px;
    line-height: 20px;
    font-weight: 600;
    img {
        width: 30px;
        height: 30px;
    }
    div {
        margin: 0 16px;
    }
`
