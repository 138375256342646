import moment from 'moment-timezone'

import {
    cleanWordHTML,
    convertTimestampToDateWithTz,
    convertToImageUrl,
    getRootUrlPrefix
} from 'shared/utils'

import type { Product } from 'core/product/models'
import type { EventDetail, EventItem, EventProductRef, ProductInList } from '../../../models'

export type EventProductRefDTO = Omit<EventProductRef, 'durations'>

export interface EventItemDTO {
    id: number
    title: string
    timeZone: string
    firstImg: string
    address: string
    beginTime: number
    endTime: number
    content: string
    productList: Product[]
    productRefList: EventProductRefDTO[]
    words: string
    buttonName: string
    productButtonName: string
    titleSize: number
    images: string[]
    isOff: boolean
}

export interface EventDetailDTO extends EventItemDTO {}

export const convertToProduct = (
    dto: Product[],
    prefix: string,
    refList: EventProductRefDTO[]
): ProductInList[] =>
    dto.map(product => ({
        id: product.id,
        canDirectBuy: true,
        image: product.images?.[0] ? convertToImageUrl(prefix, product.images?.[0]) : '',
        name: product.name,
        showPrice: product.showPrice,
        description: product.description,
        mobileDescription: cleanWordHTML(product.mobileDescription),
        isDaily: refList.filter(({ productId }) => +productId === +product.id)[0].isDaily,
        type: product.type,
        skuList: product.skuList
            ? product.skuList.map(item => {
                  return { stock: item.stock }
              })
            : [{ stock: 0 }]
    }))

const convertToEventDate = (timestamp: number | string, timeZone: string) =>
    moment(+timestamp)
        .tz(timeZone)
        .format('dddd, MMMM DD, YYYY')

const convertToEventTime = (timestamp: number | string, timeZone: string) =>
    moment(+timestamp)
        .tz(timeZone)
        .format('h:mm A')

const convertToImages = (images: string[], symbol: string) => images.join(symbol)

const convertToEventProduct = (productRef: EventProductRefDTO): EventProductRef => ({
    ...productRef,
    productId: `${productRef.productId}`,
    durations: productRef.duration.split(',').map(timestamp => ({
        label: convertTimestampToDateWithTz(timestamp, productRef.timeZone),
        value: timestamp
    }))
})

const convertToEvent = (item: EventItemDTO, prefix: string): EventItem => ({
    ...item,
    beginDateText: convertToEventDate(item.beginTime, item.timeZone),
    beginTimeText: convertToEventTime(item.beginTime, item.timeZone),
    endDateText: convertToEventDate(item.endTime, item.timeZone),
    endTimeText: convertToEventTime(item.endTime, item.timeZone),
    productList: convertToProduct(item.productList, prefix, item.productRefList),
    productRefList: item.productRefList.map(convertToEventProduct),
    productIds: item.productList.map(({ id }) => id),
    images: convertToImages(item.images, ','),
    titleSize: `${item.titleSize}`
})

export const convertToEventList = (prefix: string, dto: EventItemDTO[]): EventItem[] =>
    dto.map(item => convertToEvent(item, prefix))

export const convertToEventDetail = async (dto: EventDetailDTO): Promise<EventDetail> =>
    convertToEvent(dto, await getRootUrlPrefix())
