import { useCallback } from 'react'

import { encryptUtils } from 'shared/utils'
import { useI18n } from 'core/language/api'
import { useDetailState, useMemoizedFn, useMountedPromiseFn } from 'shared/hooks'
import { Order } from 'core/order/models'
import { services } from 'core/order/data-access'
import { CLIENT_MOBILE_PAY_IV, CLIENT_MOBILE_PAY_KEY } from 'shared/config'
import { usePayKey } from '../usePayKey'

export const useOrderDetail = (orderCode: string) => {
    const { lang } = useI18n()

    const [state, setState] = useDetailState<Order>()

    const { detail: payKey } = usePayKey()

    const changeTipApi = useMountedPromiseFn(services.changeTip)
    const changeTip = useMemoizedFn((type: number, tip: number) => {
        setState({ loading: true })
        return changeTipApi(orderCode, type, tip)
            .then(() => {
                setState({ loading: false })
            })
            .catch(e => {
                setState({ loading: false })
                return Promise.reject(e)
            })
    })

    const getDetailApi = useMountedPromiseFn(services.getDetail)
    const getDetail = useMemoizedFn(() => {
        if (!orderCode) return Promise.reject()
        setState({ loading: true })
        return getDetailApi(orderCode, lang)
            .then(detail => {
                setState({ detail, loading: false })
                return detail
            })
            .catch(err => {
                setState({ loading: false })
                return Promise.reject(err)
            })
    })

    const cancelApi = useMountedPromiseFn(services.cancel)
    const cancel = useMemoizedFn(() => {
        setState({ loading: true })
        return cancelApi(orderCode)
            .then(getDetail)
            .catch(err => {
                setState({ loading: false })
                return Promise.reject(err)
            })
    })

    const getOnlinePayKeyApi = useMountedPromiseFn(services.getOnlinePayKey)
    const getKey = useMemoizedFn(() => {
        setState({ loading: true })
        return getOnlinePayKeyApi(orderCode)
            .then(({ data }) => {
                const {
                    value: { secret }
                } = data
                const key = encryptUtils.decrypt(secret, CLIENT_MOBILE_PAY_KEY, CLIENT_MOBILE_PAY_IV)
                setState({ loading: false })
                return Promise.resolve(key)
            })
            .catch(err => {
                setState({ loading: false })
                return Promise.reject(err)
            })
    })

    const pay = useMemoizedFn(() => {
        return getKey().then(key => {
            // return payWithKey(CLIENT_MOBILE_PAY_SERVICE_ID, { orderId: orderCode, key })
            //     .then(({ state }) => {
            //         return Promise.resolve(state);
            //     });
        })
    })

    const confirmApi = useMountedPromiseFn(services.confirm)
    const confirm = useMemoizedFn(() => {
        setState({ loading: true })
        return confirmApi(orderCode)
            .then(getDetail)
            .catch(err => {
                setState({ loading: false })
                return Promise.reject(err)
            })
    })

    const removeApi = useMountedPromiseFn(services.remove)
    const remove = useMemoizedFn(() => {
        setState({ loading: true })
        return removeApi(orderCode).catch(err => {
            setState({ loading: false })
            return Promise.reject(err)
        })
    })

    const canPayByDevice = useCallback(() => !!(window as any).saba?.stripePayment, [])

    const payByDevice = useMemoizedFn(
        (orderCode: string, amount: string, onSuccess: () => void, onFail: (err: any) => void) => {
            ;(window as any).saba.stripePayment({
                secret: payKey,
                amount,
                currency: 'USD',
                callbackUrl: services.PAYMENT_CALLBACK_URL,
                orderCode,
                onSuccess,
                onFail
            })
        }
    )

    return {
        ...state,
        methods: {
            getDetail,
            cancel,
            pay,
            confirm,
            remove,
            canPayByDevice,
            changeTip,
            payByDevice
        }
    }
}
