import type { CartCheckItem } from 'core/cart/models'

export const NOTIFICATION_DURATION = 2000

export const NO_RETURNS_ID_LIST = [1, 2, 5, 34]

export const PROJECT_PREFIX = 'metasill'
export const IV_KEY_PASSWORD_ENCRYPT_SELLER = '1qaz2wsx3edc4rfv'

export const CLIENT_MOBILE_PAY_KEY = 'aip'
export const CLIENT_MOBILE_PAY_IV = 'a6s2qm4rfq9o5uh7'
export const CLIENT_MOBILE_PAY_SERVICE_ID = 'mall'

export const DURATIONS_OBJ = 'DURATIONS_OBJ '

const TOKEN_KEY = 'access_token'
const WALLET_TOKEN_KEY = 'wallet_token'
const WALLET_ADDRESS = 'wallet_address'
const STORE_LIST = 'store_list'
// const ACCOUNT_KEY = 'account_address'
// const WALLET_KEY = 'wallet'
const WALLET_ENV_KEY = 'wallet_env'
const IS_FRAME_SIGN = 'is_frame_sign'

const ADDRESS_TOKEN = 'address_token'

export enum WalletEnum {
    METAMASK = 'MetaMask',
    PHANTOM = 'Phantom'
}
// const COMMENT_STORE = 'comment_store'
const IS_TOURIST = 'is_tourist'
const IS_GOOGLE_USER = 'is_google_user'
const SHARE_CODE = 'share_code'
const IS_DELIVERY = 'isDelivery'
const COOKIE_TOKEN_EXPIRES_DAY = 1
const CART_PATCH = 'cartpath'
const TEMPORARY_PHONE = 'temporary_phone'
const TOURISTADDRESS = 'touristAddress'
const DEL_FRAME_ADDRESS = 'del_frame_address'
const LAST_WALLETS_ADDRESS_ID = 'Last_wallet_Address_Id'
const SCAN_RENT_PERSONAL_INFO = 'scan_rent_personal_info'
const SCAN_PRODUCTS_INFO = 'scan_products_info'
const CREATE_ORDER_PAGE_TYPE = 'create_order_page_type'
const CLIENT_SECRET_KEY = 'client_secret'
const SCANNED_URL = 'scanned_url'
const SELECT_E_CHART_TIME = 'select_eChart_time'

export const setTokenToCookie = (token: string, expiresDay: number = COOKIE_TOKEN_EXPIRES_DAY) => {
    const d = new Date()
    d.setTime(d.getTime() + expiresDay * 24 * 60 * 60 * 1000)
    const subDomain = window.location.host.split('.').slice(1).join('.')
    document.cookie = `${TOKEN_KEY}=${token};domain=${subDomain};expires=${d.toUTCString()}`
}

export const getTokenFromCookie = () => {
    const name = TOKEN_KEY + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') c = c.substring(1)
        if (c.indexOf(name) !== -1) return c.substring(name.length, c.length)
    }
    return ''
}

export const deleteTokenFromCookie = () => {
    setTokenToCookie('', -1)
}

export const getToken = () => {
    let token = getTokenFromCookie()
    if (token) {
        setToken(token)
        deleteTokenFromCookie()
    } else {
        token = window.localStorage.getItem(TOKEN_KEY) || ''
    }
    return token
}

export const setToken = (token: string) => {
    window.localStorage.setItem(TOKEN_KEY, token)
}

export const removeToken = () => {
    deleteTokenFromCookie()
    window.localStorage.removeItem(TOKEN_KEY)
    window.localStorage.removeItem(IS_GOOGLE_USER)
}

export const getWalletTokenFromCookie = () => {
    const name = WALLET_TOKEN_KEY + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') c = c.substring(1)
        if (c.indexOf(name) !== -1) return c.substring(name.length, c.length)
    }
    return ''
}

export const deleteWalletTokenFromCookie = () => {
    setTokenToCookie('', -1)
}
export const getWalletToken = () => {
    let token = getWalletTokenFromCookie()
    if (token) {
        setWalletToken(token)
        deleteWalletTokenFromCookie()
    } else {
        token = window.localStorage.getItem(WALLET_TOKEN_KEY) || ''
    }
    return token
}

export const setWalletToken = (token: string) => {
    window.localStorage.setItem(WALLET_TOKEN_KEY, token)
}

export const removeWalletToken = () => {
    deleteWalletTokenFromCookie()
    window.localStorage.removeItem(WALLET_TOKEN_KEY)
    window.localStorage.removeItem(IS_GOOGLE_USER)
}

export const getWalletAddressFromCookie = () => {
    const name = WALLET_ADDRESS + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') c = c.substring(1)
        if (c.indexOf(name) !== -1) return c.substring(name.length, c.length)
    }
    return ''
}

export const deleteWalletAddressFromCookie = () => {
    setTokenToCookie('', -1)
}
export const getVirtualWalletAddress = () => {
    let token = getWalletAddressFromCookie()
    if (token) {
        setWalletAddress(token)
        deleteWalletAddressFromCookie()
    } else {
        token = window.localStorage.getItem(WALLET_ADDRESS) || ''
    }
    return token
}

export const setWalletAddress = (token: string) => {
    window.localStorage.setItem(WALLET_ADDRESS, token)
}

export const removeWalletAddress = () => {
    deleteWalletTokenFromCookie()
    window.localStorage.removeItem(WALLET_ADDRESS)
    window.localStorage.removeItem(IS_GOOGLE_USER)
}

export const getStoreList = () => JSON.parse(window.localStorage.getItem(STORE_LIST as string) || '[]')

export const setStoreList = (storeList: CartCheckItem[]) => {
    return window.localStorage.setItem(STORE_LIST, JSON.stringify(storeList))
}

export const getIsTourist = () => {
    try {
        return window.localStorage.getItem(IS_TOURIST) === 'true'
    } catch {
        return false
    }
}
export const setIsTourist = (isTourist: boolean) => {
    window.localStorage.setItem(IS_TOURIST, `${isTourist}`)
}

export const removeIsTourist = () => {
    window.localStorage.removeItem(IS_TOURIST)
}

export const getIsGoogleUser = () => {
    try {
        return window.localStorage.getItem(IS_GOOGLE_USER) === 'true'
    } catch {
        return false
    }
}
export const setIsGoogleUser = (isGoogleUser: boolean) => {
    window.localStorage.setItem(IS_GOOGLE_USER, `${isGoogleUser}`)
}

export const getShareCode = () => {
    try {
        return window.localStorage.getItem(SHARE_CODE)
    } catch {
        return ''
    }
}
export const setShareCode = (shareCode: string) => {
    window.localStorage.setItem(SHARE_CODE, `${shareCode}`)
}

export const removeShareCode = () => {
    window.localStorage.removeItem(SHARE_CODE)
}

export const setCartPath = (carpath: string) => {
    window.localStorage.setItem(CART_PATCH, carpath)
}

export const getCartPath = () => {
    try {
        return window.localStorage.getItem(CART_PATCH)
    } catch {
        return ''
    }
}

export const removeCartPath = () => {
    window.localStorage.removeItem(CART_PATCH)
}

export const getDurationsObj = () => {
    try {
        return window.localStorage.getItem(DURATIONS_OBJ)
    } catch {
        return ''
    }
}

export const setDurationsObj = (shareCode: string) => {
    window.localStorage.setItem(DURATIONS_OBJ, `${shareCode}`)
}

export const setIsDelivery = (carpath: string) => {
    window.localStorage.setItem(IS_DELIVERY, carpath)
}

export const getIsDelivery = () => {
    try {
        return window.localStorage.getItem(IS_DELIVERY)
    } catch {
        return ''
    }
}

export const removeIsDelivery = () => {
    window.localStorage.removeItem(IS_DELIVERY)
}

export const setTouristAddress = (address: string) => {
    window.localStorage.setItem(TOURISTADDRESS, address)
}

export const getTouristAddress = () => {
    try {
        return window.localStorage.getItem(TOURISTADDRESS)
    } catch {
        return ''
    }
}

export const removeTouristAddress = () => {
    window.localStorage.removeItem(TOURISTADDRESS)
}

export const setTemporaryPhone = (phone: string) => {
    window.localStorage.setItem(TEMPORARY_PHONE, phone)
}

export const getTemporaryPhone = () => {
    try {
        return window.localStorage.getItem(TEMPORARY_PHONE)
    } catch {
        return ''
    }
}

export const removeTemporaryPhone = () => {
    window.localStorage.removeItem(TEMPORARY_PHONE)
}

export const removeIsFrameSign = () => {
    window.localStorage.removeItem(IS_FRAME_SIGN)
}

export const setIsFrameSign = (value: string) => {
    window.localStorage.setItem(IS_FRAME_SIGN, value)
}

export const getIsFrameSign = () => {
    try {
        return window.localStorage.getItem(IS_FRAME_SIGN)
    } catch {
        return ''
    }
}

export const getAddressToken = () => {
    try {
        return window.localStorage.getItem(ADDRESS_TOKEN)
    } catch {
        return ''
    }
}

export const setAddressToken = (token: string) => {
    window.localStorage.setItem(ADDRESS_TOKEN, token)
}

export const removeAddressToken = () => {
    deleteTokenFromCookie()
    window.localStorage.removeItem(ADDRESS_TOKEN)
}

export const setWalletEnv = (wallet: WalletEnum) => window.localStorage.setItem(WALLET_ENV_KEY, wallet)
export const getWalletEnv = () => window.localStorage.getItem(WALLET_ENV_KEY) as WalletEnum | ''
export const removeWalletEnv = () => window.localStorage.removeItem(WALLET_ENV_KEY)

export const removeDelFrameAddress = () => {
    window.localStorage.removeItem(DEL_FRAME_ADDRESS)
}

export const setDelFrameAddress = (value: string) => {
    window.localStorage.setItem(DEL_FRAME_ADDRESS, value)
}

export const getDelFrameAddress = () => {
    try {
        return window.localStorage.getItem(DEL_FRAME_ADDRESS)
    } catch {
        return ''
    }
}

export const removeLastwalletAddressId = () => {
    window.localStorage.removeItem(LAST_WALLETS_ADDRESS_ID)
}

export const setLastwalletAddressId = (id: string) => {
    window.localStorage.setItem(LAST_WALLETS_ADDRESS_ID, id)
}

export const getLastwalletAddressId = () => {
    try {
        return window.localStorage.getItem(LAST_WALLETS_ADDRESS_ID)
    } catch {
        return ''
    }
}

export const removeScanRentPersonalInfo = () => {
    window.localStorage.removeItem(SCAN_RENT_PERSONAL_INFO)
}

export const setScanRentPersonalInfo = (data: string) => {
    window.localStorage.setItem(SCAN_RENT_PERSONAL_INFO, data)
}

export const getScanRentPersonalInfo = () => {
    try {
        return JSON.parse(window.localStorage.getItem(SCAN_RENT_PERSONAL_INFO) || '')
    } catch {
        return ''
    }
}

export const removeScanProductsInfo = () => {
    window.localStorage.removeItem(SCAN_PRODUCTS_INFO)
}

export const setScanProductsInfo = (data: string) => {
    window.localStorage.setItem(SCAN_PRODUCTS_INFO, data)
}

export const getScanProductsInfo = () => {
    try {
        return JSON.parse(window.localStorage.getItem(SCAN_PRODUCTS_INFO) || '')
    } catch {
        return ''
    }
}

export const removeCreateOrderPageType = () => {
    window.localStorage.removeItem(CREATE_ORDER_PAGE_TYPE)
}

export const setCreateOrderPageType = (value: string) => {
    window.localStorage.setItem(CREATE_ORDER_PAGE_TYPE, value)
}

export const getCreateOrderPageType = () => {
    try {
        return window.localStorage.getItem(CREATE_ORDER_PAGE_TYPE)
    } catch {
        return ''
    }
}

export const removeSelectEChartTimeData = () => {
    window.localStorage.removeItem(SELECT_E_CHART_TIME)
}

export const setSelectEChartTimeData = (data: string) => {
    window.localStorage.setItem(SELECT_E_CHART_TIME, data)
}

export const getSelectEChartTimeData = () => {
    try {
        return JSON.parse(window.localStorage.getItem(SELECT_E_CHART_TIME) || '')
    } catch {
        return ''
    }
}

export const setPaymentClientSecret = (cs: string) => window.localStorage.setItem(CLIENT_SECRET_KEY, cs)
export const getPaymentClientSecret = () => window.localStorage.getItem(CLIENT_SECRET_KEY)
export const removePaymentClientSecret = () => window.localStorage.removeItem(CLIENT_SECRET_KEY)

export const setScannedUrl = (url: string) => window.localStorage.setItem(SCANNED_URL, url)
export const getScannedUrl = () => window.localStorage.getItem(SCANNED_URL)
export const removeScannedUrl = () => window.localStorage.removeItem(SCANNED_URL)
