import React, { FC } from 'react'

interface AddItemProps {
    addressItem: any
    className: string
    type: string
}
export const AddressItemPage: FC<AddItemProps> = ({ addressItem, className, type }) => {
    return (
        <div className={className}>
            <div className="name">
                <span>{addressItem.firstName}</span>&nbsp;
                <span>{addressItem.lastName}</span>
            </div>

            {addressItem.companyName && type !== 'billing' && (
                <div className="name">
                    <span>{addressItem.companyName}</span>
                </div>
            )}
            {addressItem.streetAddress && (
                <div className="name">
                    <span>{addressItem.streetAddress}</span>
                </div>
            )}
            {addressItem.apartment && (
                <div className="name">
                    <span>{addressItem.apartment}</span>
                </div>
            )}
            <div className="address">
                {` ${addressItem.city}, ${addressItem.state} ${addressItem.zipCode}`}
            </div>
            {addressItem.phone && type !== 'billing' && (
                <div className="phone">phone: {addressItem.phone}</div>
            )}

            {addressItem.email && type !== 'billing' && (
                <div className="name">
                    <span>Email: {addressItem.email}</span>
                </div>
            )}
        </div>
    )
}
