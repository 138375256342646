import React, { FC, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'

import { useFrameList } from 'core/management/frame/api/useFrameList'
import { useFrameRentList } from 'core/management/frame/api/useFrameRentList'

import {
    getIsFrameSign,
    getIsTourist,
    getVirtualWalletAddress,
    removeAddressToken,
    removeIsFrameSign,
    setIsFrameSign
} from 'shared/config'
import {
    useApiError,
    useMemoizedFn,
    useRememberManagementSelected,
    useResponsive,
    useSignInRedirect
} from 'shared/hooks'

import { Text, TextProps } from '@mantine/core'
import { ListHeaderItem, Media, Spin } from 'components'
import { FramesList } from 'core/management/frame/model'

import no_content from '../nft/assets/no_content.png'
import no_content2 from '../nft/assets/no_content2.png'

import { stringOmitMiddle } from 'shared/utils'
import { FrameCreateModal } from './FrameCreateModal'
import { SelectAdressPopup } from './components/SelectAdressPopup'
import { SelectAddressModal } from './components/SelectAddressModal'
import { AuthAddressItem, NftTypeEnum } from 'core/management/nft/models'

import deleteAuth from './assets/deleteAuth.png'
import add from './assets/add.png'
import pullDown from 'assets/pull_down.png'
import { useAuthAddressList } from 'core/management/nft/api/useAddressList'
import { useAddressToken } from 'core/management/nft/api/useAddressToken'
// import share from './assets/share.png'

export interface stateType {
    framesType?: NftTypeEnum
    authorizedType?: string
    walletAddress?: AuthAddressItem
}

const pageKey = 'Frame'

export const Frames: FC = () => {
    const navigate = useNavigate()
    const { md } = useResponsive()
    const { pathname, state } = useLocation()
    const framesType = (state && (state as stateType).framesType) || 'owned'
    const authorizedType = state && (state as stateType).authorizedType
    const virtualWalletAddress = getVirtualWalletAddress()
    const [open, setOpen] = useState(false)
    const [walletAddress, setWalletAddress] = useState<AuthAddressItem>()
    const [visible, setVisible] = useState(false)
    const [isClick, setIsClick] = useState(false)

    const {
        methods: { handleError }
    } = useApiError('Frames')
    const { getToken, loginOut } = useSignInRedirect()

    const {
        list: authAddressList,
        methods: { getAddressList }
    } = useAuthAddressList()

    const {
        loading,
        list,
        methods: { getList, getCreate }
    } = useFrameList()
    const {
        loading: rentLoading,
        list: rentList,
        methods: { getRentList }
    } = useFrameRentList()

    useEffect(() => {
        getRentList().catch(handleError)
    }, [getRentList, handleError])

    const {
        methods: { getAddressToken }
    } = useAddressToken()

    useLayoutEffect(() => {
        if (getToken() && !getIsTourist()) {
            getAddressList().catch(handleError)
        }
    }, [getAddressList, getToken, handleError])

    const selectedId = useRememberManagementSelected(pageKey, walletAddress?.id, isClick)
    useEffect(() => {
        getRentList().catch(handleError)
    }, [getRentList, handleError])
    useEffect(() => {
        !!authAddressList.length &&
            setWalletAddress(() => {
                const lastAddress = authAddressList.find(({ id }) => selectedId === `${id}`)
                if (lastAddress && lastAddress.addressType !== 0) return lastAddress

                return { ...authAddressList[0], name: 'My Account' }
            })
    }, [authAddressList, selectedId])

    useEffect(() => {
        if (getToken() && !getIsTourist() && walletAddress?.id) {
            getAddressToken({
                id: `${walletAddress.id}`,
                addressType: `${walletAddress.addressType}`,
                ownerId: `${walletAddress.ownerId}`
            })
                .then(() => {
                    getList()
                        .then(() => {
                            removeAddressToken()
                        })
                        .catch(handleError)
                })
                .catch(handleError)
        }
        if (getIsFrameSign()) removeIsFrameSign()
    }, [getList, getToken, handleError, walletAddress, framesType, getAddressToken, getRentList])

    const frameOwnedList = useMemo(() => {
        let data: FramesList[] = []
        if (walletAddress?.name === 'My Account') {
            data = list.filter(item => {
                return item.owner?.toLowerCase() === virtualWalletAddress.toLowerCase()
            })
        } else if (walletAddress?.address) {
            data = list.filter(item => {
                return item.owner?.toLowerCase() === walletAddress?.address.toLowerCase()
            })
        }

        return data
    }, [list, virtualWalletAddress, walletAddress])

    const frameAuthorizedList = useMemo(() => {
        let data: FramesList[] = []
        if (walletAddress?.name === 'My Account') {
            data = list.filter(item => {
                return item.owner?.toLowerCase() !== virtualWalletAddress.toLowerCase()
            })
        } else if (walletAddress?.address) {
            data = list.filter(item => {
                return item.owner?.toLowerCase() !== walletAddress?.address.toLowerCase()
            })
        } else {
            data = list
        }

        return data
    }, [list, virtualWalletAddress, walletAddress])

    const framesList = useMemo(() => {
        if (framesType === 'owned') {
            return frameOwnedList.length > 0 ? frameOwnedList : []
        }
        if (framesType === 'authorized') {
            return frameAuthorizedList.length > 0 ? frameAuthorizedList : []
        }
        if (framesType === 'rent') {
            return rentList.length > 0 ? rentList : []
        }
        return []
    }, [frameAuthorizedList, frameOwnedList, framesType, rentList])

    const handleSignUp = useMemoizedFn(() => {
        setIsFrameSign('true')
        getIsTourist() && loginOut()
        navigate('/auth/sign_up?redirect=/management')
    })
    const handleSignIn = useMemoizedFn(() => {
        setIsFrameSign('true')
        getIsTourist() && loginOut()
        navigate('/auth/sign_in?redirect=/management')
    })

    return (
        <Spin spinning={loading || rentLoading} className="w-full">
            <ListHeader>
                <ListHeaderItem
                    onClick={() => {
                        navigate(`${pathname}`, { state: { framesType: 'owned' } })
                    }}
                    style={md ? { marginRight: 30 } : { marginRight: 20 }}
                    className={framesType === 'owned' ? 'active' : ''}
                >
                    Owned by me
                </ListHeaderItem>
                <ListHeaderItem
                    onClick={() => {
                        navigate(`${pathname}`, { state: { framesType: 'authorized' } })
                    }}
                    style={md ? { marginRight: 30 } : { marginRight: 20 }}
                    className={framesType === 'authorized' ? 'active' : ''}
                >
                    Shared with me
                </ListHeaderItem>
                <ListHeaderItem
                    onClick={() => {
                        navigate(`${pathname}`, { state: { framesType: 'rent' } })
                    }}
                    className={framesType === 'rent' ? 'active' : ''}
                >
                    My rental
                </ListHeaderItem>
                <WebSelectAddress>
                    {getToken() && !getIsTourist() && authAddressList && md && framesType !== 'rent' && (
                        <SelectAdress>
                            <span className="box" onClick={() => setVisible(true)}>
                                <span className="name">{walletAddress?.name}</span>
                                <img width={16} height={16} src={pullDown} alt="NFT" />
                            </span>
                        </SelectAdress>
                    )}
                </WebSelectAddress>
            </ListHeader>
            {getToken() && !getIsTourist() ? (
                <FrameListStyle>
                    {authAddressList && !md && framesType !== 'rent' && (
                        <SelectAdress>
                            <span className="box" onClick={() => setVisible(true)}>
                                <span className="name">{walletAddress?.name}</span>
                                <img width={16} height={16} src={pullDown} alt="NFT" />
                            </span>
                        </SelectAdress>
                    )}
                    {authAddressList && (
                        <TotalLength>
                            <div className="total">
                                <div className="name">Total</div>
                                <span>{framesList.length}</span>
                            </div>
                            {framesType === 'authorized' ? (
                                <AddFrameBtn
                                    onClick={e => {
                                        e.stopPropagation()
                                        navigate('/management/frames/delete', {
                                            state: {
                                                framesType,
                                                authorizedType,
                                                walletAddress
                                            }
                                        })
                                    }}
                                >
                                    <img src={deleteAuth} alt="deleteAuth" />
                                </AddFrameBtn>
                            ) : (
                                walletAddress?.name === 'My Account' &&
                                framesType !== 'rent' && (
                                    <AddFrameBtn
                                        onClick={() => {
                                            // md ? setOpen(true) : navigate(`/management/frames/create`)
                                            setOpen(true)
                                        }}
                                    >
                                        <img width={24} height={24} src={add} alt="addFrames" />
                                    </AddFrameBtn>
                                )
                            )}
                        </TotalLength>
                    )}

                    {framesList.length > 0 ? (
                        <FrameListWrapper>
                            {framesList.map(
                                ({ device_name, device_id, now_playing_url, backgroundColor, rent_id }) => (
                                    <ListWrapperItem key={device_id}>
                                        <div
                                            className="list-item"
                                            onClick={() => {
                                                if (framesType === 'rent') {
                                                    navigate(
                                                        `/management/frames/${device_id}/rent/${rent_id}`,
                                                        {
                                                            state: {
                                                                framesType,
                                                                authorizedType
                                                            }
                                                        }
                                                    )
                                                } else {
                                                    navigate(`/management/frames/${device_id}`, {
                                                        state: {
                                                            framesType,
                                                            authorizedType
                                                        }
                                                    })
                                                }
                                            }}
                                        >
                                            <div
                                                style={{
                                                    border: '2px solid rgba(0,0,0,1)',
                                                    background: `${backgroundColor}`
                                                }}
                                            >
                                                <div className="relative"></div>
                                                <Media size={md ? 196 : 156} src={now_playing_url} />
                                            </div>
                                            <Info>
                                                <Name lineClamp={2}>
                                                    {stringOmitMiddle(16, 0, device_name || device_id)}
                                                </Name>
                                            </Info>
                                        </div>
                                    </ListWrapperItem>
                                )
                            )}
                        </FrameListWrapper>
                    ) : (
                        <UploadNoContent>
                            <img src={md ? no_content : no_content2} alt="no_content" />
                        </UploadNoContent>
                    )}
                    <FrameCreateModal getCreate={getCreate} getList={getList} open={open} setOpen={setOpen} />
                    {walletAddress && md && (
                        <SelectAddressModal
                            setWalletAddress={setWalletAddress}
                            walletAddress={walletAddress!}
                            list={authAddressList}
                            type={framesType === 'authorized' ? NftTypeEnum.AUTHORIZED : NftTypeEnum.OWNED}
                            visible={visible}
                            setVisible={setVisible}
                            isFrame={true}
                            setIsClick={setIsClick}
                        />
                    )}
                    {walletAddress && !md && (
                        <SelectAdressPopup
                            setWalletAddress={setWalletAddress}
                            walletAddress={walletAddress!}
                            list={authAddressList}
                            type={framesType === 'authorized' ? NftTypeEnum.AUTHORIZED : NftTypeEnum.OWNED}
                            visible={visible}
                            setVisible={setVisible}
                            isFrame={true}
                            setIsClick={setIsClick}
                        />
                    )}
                </FrameListStyle>
            ) : (
                <SignUpWrapper>
                    <SignUpNote>
                        <div>
                            Can't use this fuction, please{' '}
                            <SignIn onClick={handleSignIn}>sign into your account</SignIn>.
                        </div>
                        <div>Don't have an account yet? Sign up here.</div>
                    </SignUpNote>
                    <SignUpButton onClick={handleSignUp}>Sign Up</SignUpButton>
                </SignUpWrapper>
            )}
        </Spin>
    )
}

const WebSelectAddress = styled.div`
    position: absolute;
    right: 0;
    top: 30;
`

const SelectAdress = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    /* margin-top: 24px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(151, 151, 151, 1); */
    .box {
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f9f9f9;
        border: 1px solid rgba(211, 211, 211, 1);
        border-radius: 8px;
        padding: 6px 12px;
        @media (max-width: 768px) {
            height: 36px;
        }
    }

    .name {
        font-family: OpenSans-Bold;
        font-size: 18px;
        color: #000000;
        font-weight: 700;
        margin-right: 6px;
    }
`
const TotalLength = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: OpenSans-Regular;
    font-size: 16px;
    color: #acacac;
    margin-right: 6px;
    margin-top: 20px;
    .total {
        display: flex;
        align-items: center;
    }
    .name {
        font-family: OpenSans-Bold;
        font-size: 18px;
        color: #000000;
        font-weight: 700;
        margin-right: 10px;
    }
`

const UploadNoContent = styled.div`
    height: 200px;
    padding-top: 70px;
    display: flex;
    justify-content: center;
    img {
        width: 232px;
        height: 140px;
    }
`
const ListHeader = styled.div`
    display: flex;
    align-items: center;
    font-family: OpenSans-Bold;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    font-weight: 700;
    margin: 30px 0;
    .active {
        background: #000000;
        color: #ffffff;
    }
    @media (max-width: 768px) {
        margin: 25px 0;
    }
`

const AddFrameBtn = styled.div`
    width: 24px;
    height: 24px;
    color: #000000;
    display: flex;
    align-items: center;
    cursor: pointer;
`
const Name = styled(Text)<TextProps>`
    font-family: OpenSans-SemiBold;
    font-size: 16px;
    color: #838383;
    line-height: 20px;
    /* padding: 0 6px; */
    @media (max-width: 768px) {
        font-size: 13px;
        letter-spacing: 0;
        line-height: 16px;
    }
`

const Info = styled.div`
    padding: 15px 0;
    text-align: left;
    @media (max-width: 768px) {
        text-align: left;
        padding: 11px 0;
    }
`

const FrameListWrapper = styled.div`
    margin: 30px 0 50px 0;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 200px);
    grid-gap: 40px;
    .list-item {
        display: block;
        width: 100%;
        cursor: pointer;
        @media (max-width: 768px) {
            position: relative;
        }
    }
    @media (max-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        grid-template-columns: repeat(auto-fill, 160px);
        justify-content: space-between;
        grid-gap: 0px;
        margin: 24px 0 42px 0;
    }
`
const ListWrapperItem = styled.div`
    height: 250px;
    @media (max-width: 768px) {
        width: 160px;
        height: 205px;
    }
`

const SignIn = styled.span`
    color: #2e69bd;
    text-decoration: underline;
    cursor: pointer;
`
const FrameListStyle = styled.div`
    .header {
        height: 28px;
        h2 {
            @media (max-width: 768px) {
                font-family: Roboto-Medium;
                font-size: 18px;
                color: #000000;
                letter-spacing: 0;
                font-weight: 500;
            }
        }
        .right {
            /* flex: 1; */
        }
        .wallet-button {
            background-color: #000000;
        }
    }
    .nftheader {
        margin: 24px 0;
    }
    /* @media (max-width: 768px) {
        padding: 0 10px;
    } */
    @media (max-width: 400px) {
        padding: 0;
    }
`
const SignUpButton = styled.div`
    margin: 40px auto;
    width: 370px;
    height: 50px;
    background: #000000;
    border-radius: 35px;
    font-family: Roboto-Medium;
    font-size: 25px;
    line-height: 50px;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    @media (max-width: 768px) {
        width: 222px;
        height: 50px;
        line-height: 50px;
        margin: 30px auto;
        font-family: OpenSans-SemiBold;
        font-size: 16px;
    }
`
const SignUpNote = styled.div`
    font-family: Montserrat-SemiBold;
    font-size: 24px;
    color: #4a4a4a;
    text-align: center;
    line-height: 34px;
    font-weight: 600;
    @media (max-width: 768px) {
        font-size: 18px;
        padding: 0 40px;
    }
`

const SignUpWrapper = styled.div`
    padding: 90px 0 80px;
    @media (max-width: 768px) {
        padding: 45px 0 50px;
    }
`
