import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import styled from '@emotion/styled'
import moment, { Moment } from 'moment-timezone'

import { EventForm, EventProductState } from 'core/event/models'
import { useEventProducts } from 'core/product/api'
import { ADMIN_IMAGE_UPLOAD_URL, getToken, PROJECT_PREFIX, TIMEZONES } from 'shared/config'
import { useTinymce, useShowMessage, useMemoizedFn, useApiError } from 'shared/hooks'
import { convertDateToTimestampWithTz } from 'shared/utils'

import { Button, Input, DatePicker, Select, Switch } from 'antd'
import { Editor, EventProductModal, UploadImagesFormItem } from 'components'

export interface FormRefProps {
    submit: (fn: (data: any) => void) => void
    reset: (data?: EventForm) => void
}

interface Props {
    className?: string
    type?: 'create' | 'edit'
}

interface Image {
    id: string
    url: string
}

const style = {
    width: '100%',
    height: '40px',
    fontFamily: 'PingFangSC-Medium',
    fontSize: '16px',
    color: '#000',
    lineHeight: '20px'
}

const dateFormat = 'YYYY-MM-DD HH:mm'

const defaultData: EventForm = {
    title: '',
    timeZone: '',
    address: '',
    content: '',
    beginTime: 0,
    endTime: 0,
    productList: [],
    words: '',
    buttonName: '',
    productButtonName: '',
    titleSize: '',
    images: '',
    isOff: false
}

export const Form = forwardRef<FormRefProps, Props>(({ className, type }, ref) => {
    const [keyValue, setKeyValue] = useState('')
    const { RangePicker: RangePickerAntd } = DatePicker
    const RangePicker: any = RangePickerAntd
    const {
        methods: { imagesUploadHandler }
    } = useTinymce()

    const cropper = { width: 400, height: 300 }

    const {
        methods: { handleError }
    } = useApiError('FeatureEventForm')

    const {
        methods: { error }
    } = useShowMessage()

    const {
        list,
        methods: { getList }
    } = useEventProducts()

    const [visible, setVisible] = useState(false)
    const [data, setData] = useState<EventForm>(defaultData)
    const handleModalSave = useMemoizedFn((productList: EventProductState[]) => {
        setData(prevData => ({
            ...prevData,
            productList: productList.filter(({ checked }) => checked)
        }))
        setVisible(false)
    })

    useEffect(() => {
        getList().catch(handleError)
    }, [data.productList, getList, handleError])

    useImperativeHandle(ref, () => {
        return {
            submit: fn => {
                let key: keyof EventForm
                for (key in data) {
                    if (key !== 'isOff' && !data[key]) {
                        error('Please complete the event information')
                        return
                    }
                }
                fn({
                    ...data,
                    productList: data.productList.map(({ productId, isDaily, descriptionList }) => ({
                        id: productId,
                        isDaily,
                        descriptionList
                    }))
                })
            },
            reset: data => {
                setData({ ...defaultData, ...data })
                setKeyValue(String(new Date()))
            }
        }
    })

    return (
        <Wrapper className={className}>
            {data && (
                <>
                    <div className="item">
                        <div className="label">Event Tittle</div>
                        <Input
                            value={data.title}
                            style={style}
                            onChange={e =>
                                setData({
                                    ...(data || {}),
                                    title: e.target.value
                                })
                            }
                        />
                    </div>
                    <div className="item">
                        <div className="label">Time Zone</div>
                        <Select
                            value={data.timeZone}
                            style={style}
                            onChange={value =>
                                setData(prevData => ({
                                    ...prevData,
                                    timeZone: value,
                                    beginTime: 0,
                                    endTime: 0
                                }))
                            }
                        >
                            {TIMEZONES.map(({ label, value }) => (
                                <Select.Option key={label}>{value}</Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div className="item">
                        <div className="label">Event Time</div>
                        <RangePicker
                            key={keyValue}
                            inputReadOnly={true}
                            value={
                                data.beginTime && data.endTime
                                    ? [
                                          moment.tz(data.beginTime, data.timeZone),
                                          moment.tz(data.endTime, data.timeZone)
                                      ]
                                    : []
                            }
                            showTime
                            format={dateFormat}
                            disabled={!data.timeZone}
                            placeholder={['Start time', 'End time']}
                            onChange={(value: Moment[]) =>
                                setData(() => ({
                                    ...data,
                                    beginTime: value
                                        ? convertDateToTimestampWithTz(value[0], data.timeZone)
                                        : 0,
                                    endTime: value ? convertDateToTimestampWithTz(value[1], data.timeZone) : 0
                                }))
                            }
                            style={style}
                        />
                    </div>
                    {type !== 'create' && (
                        <div className="item flex items-center">
                            <div className="label">This event has ended</div>
                            <div className="ml-8">
                                <Switch
                                    className="label"
                                    checked={data.isOff}
                                    style={{
                                        fontFamily: 'PingFangSC-Medium',
                                        fontSize: '16px',
                                        color: '#000',
                                        lineHeight: '20px'
                                    }}
                                    onChange={value => {
                                        setData({
                                            ...(data || {}),
                                            isOff: value
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    <div className="item">
                        <div className="label">Event Location</div>
                        <Input
                            value={data.address}
                            style={style}
                            onChange={e =>
                                setData({
                                    ...data,
                                    address: e.target.value
                                })
                            }
                        />
                    </div>
                    <div className="item">
                        <div className="label">Event Product Summary View Image</div>
                        <UploadImagesFormItem
                            cropper={cropper}
                            max={20}
                            value={
                                data.images.length > 0
                                    ? data.images.split(',').map((img, idx) => ({
                                          id: `${idx}`,
                                          url: img
                                      }))
                                    : []
                            }
                            onChange={value => {
                                setData({
                                    ...(data || {}),
                                    images: value.map(({ url }) => url).join(',')
                                })
                            }}
                            uploadUrl={ADMIN_IMAGE_UPLOAD_URL}
                            accessHeader={{
                                Authorization: `bearer ${getToken()}`
                            }}
                            imageSrcPrefix={PROJECT_PREFIX}
                        />
                    </div>
                    <div className="item">
                        <div className="label">Buy Button Text (in Event Product Summary View)</div>
                        <Input
                            value={data.productButtonName}
                            style={style}
                            onChange={e =>
                                setData({
                                    ...data,
                                    productButtonName: e.target.value
                                })
                            }
                        />
                    </div>
                    <div className="item">
                        <div className="label">Font-Size of Product Name (in Event Product Summary View)</div>
                        <Select
                            value={data.titleSize}
                            style={style}
                            optionLabelProp="label"
                            onChange={value =>
                                setData(prevData => ({
                                    ...prevData,
                                    titleSize: value
                                }))
                            }
                        >
                            {new Array(9)
                                .fill(0)
                                .map((_, index) => index + 14)
                                .map(item => (
                                    <Select.Option
                                        key={item}
                                        label={`${item}px`}
                                    >{`${item}px`}</Select.Option>
                                ))}
                        </Select>
                    </div>
                    <div className="item">
                        <div className="label flex justify-between" style={{ width: '100%' }}>
                            <div>Event Products</div>
                            <Button
                                size="small"
                                shape="round"
                                type="primary"
                                style={{ fontFamily: 'Roboto-Medium' }}
                                onClick={() => setVisible(true)}
                            >
                                Select
                            </Button>
                        </div>
                        <div
                            className="w-full"
                            style={{
                                width: '100%',
                                fontFamily: 'PingFangSC-Medium',
                                fontSize: 14,
                                minHeight: 40,
                                border: '1px solid #000',
                                padding: '8px 11px'
                            }}
                        >
                            {data.productList.length
                                ? list
                                      .filter(({ id }) =>
                                          data.productList.map(({ productId }) => productId).includes(id)
                                      )
                                      .map(({ id, name }) => (
                                          <div key={id} className="my-2">
                                              {name}
                                          </div>
                                      ))
                                : 'Please Select'}
                        </div>
                        <EventProductModal
                            visible={visible}
                            setVisible={setVisible}
                            list={list}
                            defaultProductList={data.productList.map(
                                ({ productId, isDaily, descriptionList }) => ({
                                    productId,
                                    isDaily,
                                    descriptionList,
                                    checked: true
                                })
                            )}
                            onSave={handleModalSave}
                        />
                    </div>
                    <div className="item">
                        <div className="label">Promotional Text</div>
                        <Input
                            value={data.words}
                            style={style}
                            onChange={e =>
                                setData({
                                    ...data,
                                    words: e.target.value
                                })
                            }
                        />
                    </div>
                    <div className="item">
                        <div className="label">Button Text</div>
                        <Input
                            value={data.buttonName}
                            style={style}
                            onChange={e =>
                                setData({
                                    ...data,
                                    buttonName: e.target.value
                                })
                            }
                        />
                    </div>
                    <div className="item" style={{ width: '100%' }}>
                        <div className="label">Event Intro</div>
                        <Editor
                            value={data.content}
                            onChange={value =>
                                setData({
                                    ...data,
                                    content: value
                                })
                            }
                            imagesUploadHandler={imagesUploadHandler}
                        />
                    </div>
                </>
            )}
        </Wrapper>
    )
})

const Wrapper = styled.div`
    padding: 0 14.8rem;
    .item {
        width: 54rem;
        .label {
            margin: 3rem 0 0.9rem 0;
            font-family: PingFangSC-Regular;
            font-size: 18px;
            color: #000000;
            line-height: 26px;
        }
        .ant-select-selector {
            min-height: 40px !important;
        }
        .ant-select-selection-item {
            line-height: 40px !important;
        }
        .item_rangepicker {
            .ant-calendar-input-wrap {
                display: none;
            }
        }
    }
    @media (max-width: 768px) {
        padding: 0 20px;
        .item {
            width: 100%;
        }
    }
    .ant-select-arrow {
        color: #000 !important;
    }
    .ant-picker-suffix {
        color: #000 !important;
    }
`
