import { useState } from 'react'
import { setAddressToken } from 'shared/config'
import { useMemoizedFn, useMountedPromiseFn } from 'shared/hooks'
import { services } from '../data-access'

export const useAddressToken = () => {
    const [myAddressToken, setMyAddressToken] = useState<string>()
    const [loading, setLoading] = useState(false)

    const getAddressTokenApi = useMountedPromiseFn(services.getAddressToken)
    const getAddressToken = useMemoizedFn((params: { id: string; addressType: string; ownerId: string }) => {
        setLoading(true)
        return getAddressTokenApi(params)
            .then(res => {
                setMyAddressToken(res.value)
                setAddressToken(res.value)
            })
            .catch(err => {
                setLoading(false)
                return Promise.reject(err)
            })
    })

    return {
        loading,
        myAddressToken,
        methods: { getAddressToken }
    }
}
