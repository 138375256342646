import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'

import { PageContainer } from 'components'

import notFoundIcon from './assets/404.png'

export const NotFound: FC = () => {
    const navigate = useNavigate()
    return (
        <Container>
            <div className="main">
                <img src={notFoundIcon} alt="" />
                <div className="title">Page Not Found</div>
                <div className="text">
                    We’re sorry, the page you requested could not be found please go back to the homepage
                </div>
                <div className="btn" onClick={() => navigate('/', { replace: true })}>
                    Go Home
                </div>
            </div>
        </Container>
    )
}

const Container = styled(PageContainer)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 68px - 90px);
    overflow: hidden;
    .main {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 390px;
    }
    img {
        width: 384px;
        height: 232px;
    }
    .title {
        margin-bottom: 16px;
        font-family: Roboto-Medium;
        font-size: 26px;
        color: #263238;
        letter-spacing: 0;
    }
    .text {
        margin-bottom: 24px;
        font-family: Roboto-Regular;
        font-size: 16px;
        color: #636b6f;
        letter-spacing: 0;
        text-align: center;
    }
    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 197px;
        height: 50px;
        border-radius: 35px;
        background: #000000;

        font-family: Roboto-Medium;
        font-size: 25.33px;
        color: #ffffff;
        cursor: pointer;
    }
    @media (max-width: 576px) {
        padding: 0;
        height: calc(100vh - 56px - 90px);
        width: 100%;
        .main {
            width: 370px;
        }
    }
`
