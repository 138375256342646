import { createRef, useCallback, useState } from 'react'

import { useUrlParams } from './useUrlParams'

export interface SearchFormRefProps<T> {
    submit: (fn: (formData: T) => void) => void
}

export const useListSearchForm = <T extends { pageSize: string; pageNum: string }>(
    defaultValues: T,
    urlParamsMode = true,
    replaceMode = false
) => {
    const searchFormRef = createRef<SearchFormRefProps<T>>()
    const { params, changeParams } = useUrlParams({
        defaultValues,
        replace: replaceMode
    })
    const [paramsState, setParamsState] = useState<T>(defaultValues)

    const onSearch = useCallback(() => {
        searchFormRef.current &&
            searchFormRef.current.submit(searchFormData => {
                urlParamsMode
                    ? changeParams({
                          ...searchFormData,
                          pageNum: '1'
                      })
                    : setParamsState(state => ({
                          ...state,
                          ...searchFormData,
                          pageNum: '1'
                      }))
            })
    }, [urlParamsMode, searchFormRef, changeParams, setParamsState])

    const onClear = useCallback(() => {
        urlParamsMode ? changeParams(defaultValues) : setParamsState(defaultValues)
    }, [urlParamsMode, changeParams, setParamsState, defaultValues])

    const onChangePage = useCallback(
        (page: number) => {
            urlParamsMode
                ? changeParams({ pageNum: `${page}` } as Partial<T>)
                : setParamsState(state => ({ ...state, pageNum: `${page}` }))
        },
        [urlParamsMode, changeParams, setParamsState]
    )

    const onChangePageSize = useCallback(
        (size: number) => {
            urlParamsMode
                ? changeParams({ pageSize: `${size}` } as Partial<T>)
                : setParamsState(state => ({ ...state, pageSize: `${size}` }))
        },
        [urlParamsMode, changeParams, setParamsState]
    )

    return {
        params: urlParamsMode ? params : paramsState,
        searchFormRef,
        onSearch,
        onClear,
        onChangePage,
        onChangePageSize,
        setParamsState
    }
}
