import { PROJECT_PREFIX } from 'shared/config'

export const isObject = (value: unknown): value is Record<any, any> =>
    value !== null && typeof value === 'object'
export const isFunction = (value: unknown): value is Function => typeof value === 'function'

export const isString = (value: unknown): value is string => typeof value === 'string'
export const isBoolean = (value: unknown): value is boolean => typeof value === 'boolean'
export const isNumber = (value: unknown): value is number => typeof value === 'number'
export const isUndef = (value: unknown): value is undefined => typeof value === 'undefined'

export const isBrowser = !!(typeof window !== 'undefined' && window.document && window.document.createElement)

export const pathJoin = (...args: string[]) => {
    const ret = args
        .reduce((acc, curr) => {
            const str = curr.replace(/^\//, '')
            if (str) {
                return [...acc, str]
            }
            return acc
        }, [] as string[])
        .join('/')
    return /^http/.test(ret) ? ret : `/${ret}`
}

export const pathJoinPrefix = (imageSrcPrefix: string, url: string) => {
    imageSrcPrefix = pathJoin(imageSrcPrefix)
    url = pathJoin(url)

    return url.indexOf(imageSrcPrefix) === 0 ? url : pathJoin(imageSrcPrefix, url)
}

export const getRootUrlPrefix = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return Promise.resolve(window.location.origin)
}

export const convertToImageUrl = (prefix: string, path: string) => pathJoin(prefix, PROJECT_PREFIX, path)
export const deviceIsAndroid = () => {
    const deviceIsWindowsPhone = window.navigator.userAgent.indexOf('Windows Phone') >= 0
    return window.navigator.userAgent.indexOf('Android') > 0 && !deviceIsWindowsPhone
}

export const deviceIsIOS = () => {
    const deviceIsWindowsPhone = window.navigator.userAgent.indexOf('Windows Phone') >= 0
    return /iP(ad|hone|od)/.test(window.navigator.userAgent) && !deviceIsWindowsPhone
}

export const isAndroid = () => /android/i.test(window.navigator.userAgent)

export const fixAbbr = (s: string | number) => parseFloat(`${s}`).toFixed(2)

export const convertToDollar = (s: string | number, n: number = 2) => {
    s = s ? `${s}` : '0'
    const isMinus = /-/.test(s)
    s = s.replace(/^-/, '')
    if (n) {
        n = n > 0 && n <= 20 ? n : 2
    } else {
        n = 0
    }
    s = parseFloat((s + '').replace(/[^\d.-]/g, '')).toFixed(n) + ''
    const l = s.split('.')[0].split('').reverse()
    const r = s.split('.')[1]
    let t = ''
    for (let i = 0; i < l.length; i++) {
        t += l[i] + ((i + 1) % 3 === 0 && i + 1 !== l.length ? ',' : '')
    }
    const result = t.split('').reverse().join('') + (r ? '.' + r : '')

    return isMinus ? `-${result}` : result
}

export const isDev = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
