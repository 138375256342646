import { compose } from 'ramda'
import { pathJoin } from 'shared/utils'
import { ItemResponse, pickValue, SuccessItemResponse, SuccessResponse, pickData } from 'shared/request'
import { apiHttp, clientHttp } from 'shared/request'
import {
    ChangePwdFormData,
    ForgetPasswordPost,
    LoginPost,
    SignUpPost,
    verificationCodeType
} from 'core/auth/models'

import { AuthCodeResponseDTO, convertToAuthCode, getUserInfoDTO, loginDTO } from '../DTO'

const URI = 'auth'

export const signIn = (loginParam: LoginPost) => {
    const url = pathJoin(URI, 'sign_in')
    return apiHttp.post<SuccessItemResponse<loginDTO>>(url, { ...loginParam }).then(pickValue)
}

export const signInWithGoogle = (idToken: string, shareCode?: string) => {
    const url = pathJoin(URI, 'google_login')
    return apiHttp
        .post<SuccessItemResponse<loginDTO>>(url, { googleToken: idToken, shareCode, clientId: 0 })
        .then(pickValue)
}

export const signInWithApple = (
    params: { appleToken: string; email?: string; authCode?: string },
    shareCode?: string
) => {
    const url = pathJoin(URI, 'apple_login')
    return apiHttp
        .post<SuccessItemResponse<loginDTO>>(url, { ...params, shareCode, clientId: 0 })
        .then(pickValue)
}

export const touristSignIn = (recaptchaToken: string) => {
    const url = pathJoin(URI, 'tourist_login')
    return apiHttp
        .post<SuccessItemResponse<loginDTO>>(url, { clientId: 0 }, { 'g-recaptcha': recaptchaToken })
        .then(pickValue)
}

export const touristBind = (signUpParam: SignUpPost) => {
    const url = pathJoin(URI, 'bind')
    return clientHttp.post<SuccessItemResponse<loginDTO>>(url, { ...signUpParam }).then(pickValue)
}

// Register, set password
export const signUp = (signUpParam: SignUpPost) => {
    const url = pathJoin(URI, 'sign_up')
    return apiHttp.post<SuccessItemResponse<loginDTO | string>>(url, { ...signUpParam }).then(pickValue)
}

// Forgot password, reset password
export const forgetPassword = (forgetPasswordParam: ForgetPasswordPost) => {
    const url = pathJoin(URI, 'forget_password')
    return apiHttp.post<SuccessItemResponse<string>>(url, { ...forgetPasswordParam }).then(pickValue)
}

// Send verification code
export const sendAuthCode = (
    mobile: string,
    email: string,
    recaptchaToken: string,
    type: verificationCodeType
) => {
    const p = { mobile, email, type }
    const url = pathJoin(URI, 'auth_code')
    return apiHttp
        .post<SuccessResponse<ItemResponse<AuthCodeResponseDTO | string>>>(url, p, {
            'g-recaptcha': recaptchaToken
        })
        .then(compose(convertToAuthCode, pickValue as any))
}

// Resend, the token is the token to resend the captcha, not the user's token
export const resendAuthCode = (token: string) => {
    const url = pathJoin(URI, 'auth_code', 'resend')
    return apiHttp.post<SuccessResponse<ItemResponse<string>>>(url, { token }).then(pickValue)
}

export const checkAuthCode = (
    mobile: string,
    email: string,
    type: verificationCodeType,
    authCode: string
) => {
    const p = { mobile, email, type, authCode }
    const url = pathJoin(URI, 'check_auth_code')
    return apiHttp
        .post<SuccessResponse<ItemResponse<AuthCodeResponseDTO | string>>>(url, p)
        .then(compose(convertToAuthCode, pickValue as any))
}

// Access to personal information
export const getUserInfo = () => {
    const url = pathJoin(URI, 'getInfo')
    return clientHttp.get<SuccessResponse<ItemResponse<getUserInfoDTO>>>(url).then(pickValue)
}

export const changePwd = (formData: ChangePwdFormData) => {
    const url = pathJoin(URI, 'editPassword')
    return clientHttp.put<SuccessItemResponse<void>>(url, { ...formData })
}

export const signInwallet = (account: string) =>
    apiHttp
        .post<
            SuccessResponse<{
                access_token: string
            }>
        >('account/login', { account })
        .then(pickData)
