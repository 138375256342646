import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'

import {
    getIsTourist,
    getScanRentPersonalInfo,
    setCreateOrderPageType,
    setScanRentPersonalInfo
} from 'shared/config'

import { StepType } from '../index'
import { BillingAddress } from './BillingAddress'
import { BaseDelivery, DeliveryTypeEnum } from 'core/delivery/models'
import { useApiError, useSignInRedirect, useUserInfo } from 'shared/hooks'
import { useDeliveryCreate, useDeliveryList } from 'core/delivery/api'
import { EventBillingAddressDetail } from 'core/event/models'

import { Button } from 'components'
import { PayContainer } from 'modules/order/payment'
import { BillingList } from 'modules/event/create-order/components/BillingList'
import { AddModal } from 'modules/event/create-order/components/AddModal'

import oneIcon from '../../assets/one.png'
import twoIcon from '../../assets/two.png'
export interface PaymentProps {
    cardPay: boolean
    setCardPay: (value: boolean) => void
    total: number
    initialValues: BaseDelivery
    loading: boolean
    payOrderFormRef: any
    setStep: (value: StepType) => void
    onSubmit: (values: BaseDelivery, selectId?: string) => void
    setBillingAddressValue: (val: BaseDelivery) => void
}

export const Payment: FC<PaymentProps> = ({
    cardPay,
    setCardPay,
    total,
    initialValues,
    loading,
    payOrderFormRef,
    setStep,
    onSubmit,
    setBillingAddressValue
}) => {
    const { getToken } = useSignInRedirect()
    const {
        methods: { handleError }
    } = useApiError('scan-rent')
    const userInfo = useUserInfo()

    const PersonalInformation = useMemo(() => {
        return getScanRentPersonalInfo()
    }, [])

    const [complete, setComplete] = useState<boolean>(false)

    const [selectBillingModalVisible, setSelectBillingModalVisible] = useState<boolean>(false)
    const [billingButtonText, setBillingButtonText] = useState<string>('')
    const [selectedBillingId, setSelectedBillingId] = useState<string>()
    const [addressData, setAddressData] = useState<EventBillingAddressDetail>()
    const [addBillingModalVisible, setAddBillingModalVisible] = useState<boolean>(false)

    const {
        billingList,
        empty,
        methods: { getList: getBillingList }
    } = useDeliveryList(DeliveryTypeEnum.BILLING)

    const {
        methods: { create: addressCreate }
    } = useDeliveryCreate(DeliveryTypeEnum.BILLING)

    useEffect(() => {
        if (getToken() && !getIsTourist()) {
            getBillingList().catch(handleError)
            setBillingButtonText(!empty ? 'Use a saved address' : '')
        }
    }, [empty, getBillingList, getToken, handleError])

    const handleSelectAddress = useCallback(
        (id: string) => {
            if (id) {
                setSelectedBillingId(id)
                setBillingButtonText('Change')
                const newData = billingList.filter(item => item.id === id)
                setAddressData(newData[0])
            }
        },
        [billingList]
    )

    const handleAddBilling = useCallback(
        (data: BaseDelivery) => {
            addressCreate(data).then(res => {
                setAddBillingModalVisible(false)
                let addressData = {
                    ...data,
                    id: res
                }
                setSelectedBillingId(res)
                setAddressData(addressData)
            })
        },
        [addressCreate]
    )

    return (
        <Container>
            <Header>
                <div
                    onClick={() => {
                        setScanRentPersonalInfo(
                            JSON.stringify({ ...getScanRentPersonalInfo(), isShow: true })
                        )
                        setStep(StepType.orderSummary)
                        setCreateOrderPageType(StepType.orderSummary)
                    }}
                    className="title"
                >
                    <img
                        width={24}
                        height={24}
                        src={oneIcon}
                        alt="scan-rent"
                        style={{ marginRight: '8px' }}
                    />
                    <div className="text">Personal information</div>
                </div>
                {getToken() && !getIsTourist() && !getScanRentPersonalInfo() ? (
                    <div className="Info">{userInfo?.userName}</div>
                ) : (
                    <>
                        <div className="Info">
                            {`${PersonalInformation.firstName} ${PersonalInformation.lastName}`}
                        </div>
                        <div className="Info">{PersonalInformation.emailAddress}</div>
                        {PersonalInformation.phone && (
                            <div className="Info">{`+${PersonalInformation.cc}${PersonalInformation.phone}`}</div>
                        )}
                    </>
                )}
            </Header>
            <PaymentInfo>
                <div className="title">
                    <img
                        width={24}
                        height={24}
                        src={twoIcon}
                        alt="scan-rent"
                        style={{ marginRight: '8px' }}
                    />
                    <div>Payment</div>
                </div>
                <PayContainer
                    key={total}
                    setCardPay={setCardPay}
                    param={{ amount: total }}
                    innerRef={payOrderFormRef}
                    setComplete={setComplete}
                />
            </PaymentInfo>
            {cardPay ? (
                <BillingAddressInfo>
                    <BillingCardTitle>
                        <BillingTitle>Billing address</BillingTitle>
                        <BillingTitleAddress
                            onClick={() => {
                                setSelectBillingModalVisible(true)
                                getBillingList()
                            }}
                        >
                            {billingButtonText}
                        </BillingTitleAddress>
                    </BillingCardTitle>
                    {selectedBillingId ? (
                        <BillingAddressContent>
                            {addressData && (
                                <div key={addressData.id}>
                                    <div>
                                        {addressData.firstName} {addressData.lastName}
                                    </div>
                                    <div>{addressData.companyName}</div>
                                    <div>{addressData.streetAddress}</div>
                                    <div>
                                        {addressData.city}, {addressData.state} {addressData.zipCode}
                                    </div>
                                    {addressData.phone && <div>Phone : {addressData.phone}</div>}
                                </div>
                            )}
                            <SubButton
                                onClick={() =>
                                    onSubmit(
                                        {
                                            firstName: '',
                                            lastName: '',
                                            companyName: '',
                                            streetAddress: '',
                                            apartment: '',
                                            city: '',
                                            state: '',
                                            zipCode: '',
                                            phone: '',
                                            isDefault: false
                                        },
                                        selectedBillingId
                                    )
                                }
                                disabled={!complete}
                            >
                                Place Order
                            </SubButton>
                        </BillingAddressContent>
                    ) : (
                        <BillingAddress
                            initialValues={initialValues}
                            disabled={!complete}
                            onSubmit={onSubmit}
                            setSaveBillingAddress={setBillingAddressValue}
                        />
                    )}
                </BillingAddressInfo>
            ) : (
                <SubButton
                    onClick={() =>
                        onSubmit(
                            {
                                firstName: '',
                                lastName: '',
                                companyName: '',
                                streetAddress: '',
                                apartment: '',
                                city: '',
                                state: '',
                                zipCode: '',
                                phone: '',
                                isDefault: false
                            },
                            selectedBillingId
                        )
                    }
                    disabled={!complete}
                >
                    Place Order
                </SubButton>
            )}
            <BillingList
                data={billingList}
                visible={selectBillingModalVisible}
                onCancel={() => setSelectBillingModalVisible(false)}
                onSelect={handleSelectAddress}
                onAddModalShow={() => setAddBillingModalVisible(true)}
                selectedBillingId={selectedBillingId ? selectedBillingId : ''}
            />
            <AddModal
                visible={addBillingModalVisible}
                onCancel={() => setAddBillingModalVisible(false)}
                onAddBilling={handleAddBilling}
                disabled={!complete}
            />
        </Container>
    )
}

const Container = styled.div`
    padding: 24px;
    .title {
        font-family: HelveticaNeue-CondensedBold;
        font-size: 24px;
        color: #000000;
        line-height: 24px;
        font-weight: 700;
        display: flex;
        align-items: center;
    }
`
const Header = styled.div`
    margin: 10px auto 30px;
    width: 580px;
    .title {
        margin-bottom: 16px;
        .text {
            opacity: 0.5;
        }
    }

    .Info {
        font-family: HelveticaNeue;
        font-size: 18px;
        color: #000000;
        line-height: 26px;
        font-weight: 400;
    }
    @media (max-width: 768px) {
        width: 100%;
    }
`
const PaymentInfo = styled.div`
    width: 580px;
    margin: 0 auto;
    .title {
        margin-bottom: 30px;
    }
    @media (max-width: 768px) {
        width: 100%;
    }
`

const BillingAddressInfo = styled.div`
    .title {
        width: 580px;
        margin: 24px auto 30px;
    }
    @media (max-width: 768px) {
        width: 100%;
    }
`

const BillingTitleAddress = styled.div`
    font-family: HelveticaNeue;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    text-align: right;
    line-height: 26px;
    text-decoration: underline;
    cursor: pointer;
`
const BillingAddressContent = styled.div`
    font-family: HelveticaNeue;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    line-height: 26px;
`
const SubButton = styled(Button)`
    width: 100%;
    height: 50px;
    border-radius: 36px;
    margin-top: 40px;
    font-family: Roboto-Medium;
    font-size: 25.33px;
    color: #fff;
    letter-spacing: 0;
    text-align: center;
    line-height: 30.96px;
    @media (max-width: 768px) {
        width: 100%;
        margin: 30px 0 24px 0;
    }
`
const BillingCardTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;
    @media (max-width: 768px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 30px 0;
    }
`

const BillingTitle = styled.div`
    font-family: HelveticaNeue-CondensedBold;
    font-size: 30px;
    color: #000000;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: bold;
    @media (max-width: 768px) {
        margin: 0 0 20px 0;
    }
`
