import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'

import { Button, CountryCodePicker } from 'components'
import { Checkbox, Form, Input, Select } from 'antd'
import { BaseDelivery, DeliveryTypeEnum, StateList } from 'core/delivery/models'
import { CountryCodeList } from 'shared/CountryCode'
import { useDeliveryCreate, useDeliveryDetail } from 'core/delivery/api'
import { useApiError, useMemoizedFn, useResponsive } from 'shared/hooks'
import { Reg } from 'shared/utils'
import { getIsTourist } from 'shared/config'

const initialCC = 'US'

const initialValues: BaseDelivery = {
    firstName: '',
    lastName: '',
    companyName: '',
    streetAddress: '',
    apartment: '',
    city: '',
    state: '',
    zipCode: '',
    phone: '',
    isDefault: false
}

export interface AddressFormProps {
    addressType: DeliveryTypeEnum
    addressId: string
    addressTitle: string
    onCancel: () => void
    onSave?: () => void
}

export const AddressList: FC<AddressFormProps> = ({
    addressType,
    addressId,
    addressTitle = 'Add a new address',
    onCancel,
    onSave
}) => {
    const { md } = useResponsive()
    const [btnDisabled, setBtnDisabled] = useState<boolean>(true)
    const [form] = Form.useForm<BaseDelivery>()
    const [ccLocale, setCcLocale] = useState(initialCC)
    const cc = useMemo(() => CountryCodeList.find(({ locale }) => locale === ccLocale)?.code, [ccLocale])
    const {
        methods: { handleError }
    } = useApiError('AddressList')

    const {
        methods: { create: addressCreate }
    } = useDeliveryCreate(addressType)

    const {
        methods: { update, getDetail }
    } = useDeliveryDetail(addressId as string)

    useEffect(() => {
        if (addressId) {
            getDetail()
                .then(res => {
                    const phoneWithCC = res.phone.split(' ')
                    const hasCC = !(phoneWithCC.length === 1)
                    setCcLocale(hasCC ? phoneWithCC[0] : initialCC)
                    form.setFieldsValue({ ...res, phone: hasCC ? phoneWithCC[1] : phoneWithCC[0] })
                    setBtnDisabled(false)
                })
                .catch(handleError)
            return
        }
        form.setFieldsValue({ state: undefined })
    }, [addressId, form, getDetail, handleError])

    const handleValuesChange = useMemoizedFn((_: Partial<BaseDelivery>, allValues: BaseDelivery) => {
        const { firstName, lastName, streetAddress, city, state, zipCode, phone, email } = allValues
        const values = firstName && lastName && streetAddress && city && state && zipCode

        if (getIsTourist()) {
            if (!Reg.email.test(email ? email : '')) return setBtnDisabled(true)
        }
        if (addressType === DeliveryTypeEnum.DELIVERY) {
            if (!Reg.startWithNum.test(phone)) return setBtnDisabled(true)

            if (getIsTourist()) {
                if (values && phone && (cc || ccLocale) && email) return setBtnDisabled(false)
            }
            if (values && phone && (cc || ccLocale)) return setBtnDisabled(false)
        } else {
            if (getIsTourist()) {
                if (values && email) return setBtnDisabled(false)
            }
            if (values) return setBtnDisabled(false)
        }

        return setBtnDisabled(true)
    })

    const handleSubmit = useCallback(() => {
        form.validateFields().then(values => {
            const code = cc ? `+${cc}` : ccLocale
            const {
                firstName,
                lastName,
                streetAddress,
                city,
                state,
                zipCode,
                phone,
                apartment,
                companyName
            } = values
            const phoneTrim = phone && phone.trim()
            const params: BaseDelivery = {
                ...values,
                firstName: firstName && firstName.trim(),
                lastName: lastName && lastName.trim(),
                streetAddress: streetAddress && streetAddress.trim(),
                city: city && city.trim(),
                state: state && state.trim(),
                zipCode: zipCode && zipCode.trim(),
                apartment: apartment && apartment.trim(),
                companyName: companyName && companyName.trim(),
                phone: phone ? `${code} ${phoneTrim}` : ''
            }

            const fn = addressId ? update : addressCreate
            fn(params)
                .then(() => {
                    form.resetFields()
                    onCancel()
                    onSave && onSave()
                })
                .catch(handleError)
        })
    }, [addressCreate, addressId, cc, ccLocale, form, handleError, onCancel, onSave, update])

    return (
        <AddressFormWrapper>
            <AddressTitle>{addressTitle}</AddressTitle>
            <Form
                form={form}
                onValuesChange={handleValuesChange}
                initialValues={{ ...initialValues }}
                autoComplete="off"
                className="addressForm"
            >
                <Form.Item
                    name="firstName"
                    rules={[
                        {
                            validator(_, value) {
                                if (value && value.trim()) {
                                    return Promise.resolve()
                                }
                                return Promise.reject()
                            }
                        }
                    ]}
                    className="formItemleft"
                >
                    <StyledInput placeholder="First Name *" className="inputName" />
                </Form.Item>
                <Form.Item
                    name="lastName"
                    rules={[
                        {
                            validator(_, value) {
                                if (value && value.trim()) {
                                    return Promise.resolve()
                                }
                                return Promise.reject()
                            }
                        }
                    ]}
                    className="formItem"
                >
                    <StyledInput placeholder="Last Name *" className="inputName" />
                </Form.Item>
                {addressType === DeliveryTypeEnum.DELIVERY && (
                    <Form.Item name="companyName" className="formItems">
                        <StyledInput placeholder="Company (optional)" className="inputName" />
                    </Form.Item>
                )}
                <Form.Item
                    name="streetAddress"
                    rules={[
                        {
                            validator(_, value) {
                                if (value && value.trim()) {
                                    return Promise.resolve()
                                }
                                return Promise.reject()
                            }
                        }
                    ]}
                    className="formItems"
                >
                    <StyledInput placeholder="Street Address *" className="inputName" />
                </Form.Item>
                <Form.Item name="apartment" className="formItems">
                    <StyledInput placeholder="Apt, suite, unit, etc." className="inputName" />
                </Form.Item>
                <Form.Item
                    name="city"
                    rules={[
                        {
                            validator(_, value) {
                                if (value && value.trim()) {
                                    return Promise.resolve()
                                }
                                return Promise.reject()
                            }
                        }
                    ]}
                    className="formItemleft"
                >
                    <StyledInput placeholder="Town / City *" className="inputName" />
                </Form.Item>
                <Form.Item
                    name="state"
                    rules={[
                        {
                            validator(_, value) {
                                if (value && value.trim()) {
                                    return Promise.resolve()
                                }
                                return Promise.reject()
                            }
                        }
                    ]}
                    className="formItem"
                >
                    <StateSelect
                        placeholder="State *"
                        className="inputName"
                        options={StateList.map(value => ({ label: value, value }))}
                    />
                </Form.Item>
                <Form.Item
                    name="zipCode"
                    rules={[
                        {
                            validator(_, value) {
                                if (value && value.trim()) {
                                    return Promise.resolve()
                                }
                                return Promise.reject()
                            }
                        }
                    ]}
                    className="formItemleft"
                >
                    <StyledInput placeholder="ZIP code *" className="inputName" />
                </Form.Item>
                {addressType === DeliveryTypeEnum.DELIVERY && (
                    <Form.Item
                        name="phone"
                        rules={[
                            {
                                validator(_, value) {
                                    if (!cc && !ccLocale) return Promise.reject()
                                    if (value && Reg.startWithNum.test(value)) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject('Invalid phone number.')
                                }
                            }
                        ]}
                        className="formItem"
                    >
                        <StyledInput
                            addonBefore={
                                <CountryCodePicker
                                    popupClassName={md ? 'address-modal-cc' : 'address-modal-cc-mobile'}
                                    ccLocale={ccLocale}
                                    setCcLocale={setCcLocale}
                                    width={90}
                                />
                            }
                            placeholder="Phone Number *"
                            className="inputName cc-picker"
                        />
                    </Form.Item>
                )}
                {getIsTourist() && (
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                validator(_, value) {
                                    if (value && Reg.email.test(value)) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject('Invalid email.')
                                }
                            }
                        ]}
                        className="formItems"
                    >
                        <StyledInput placeholder="Email *" className="inputName" />
                    </Form.Item>
                )}
                {addressType === DeliveryTypeEnum.DELIVERY && (
                    <Form.Item name="isDefault" valuePropName="checked" className="formItems">
                        <Checkbox>Make this my default address</Checkbox>
                    </Form.Item>
                )}
                <Form.Item className="formItems">
                    <SubButton disabled={btnDisabled} onClick={handleSubmit}>
                        Save
                    </SubButton>
                </Form.Item>
            </Form>
        </AddressFormWrapper>
    )
}

const AddressFormWrapper = styled.div`
    @media (max-width: 768px) {
        margin: 0;
    }
    .addressForm {
        display: flex;
        flex-wrap: wrap;
        padding: 0 112px;
        .ant-form-item {
            margin-bottom: 30px !important;
            @media (max-width: 768px) {
                margin-bottom: 20px !important;
            }
        }
        .ant-form-item-explain,
        .ant-form-item-explain-connected {
            min-height: 0px !important;
        }
        .inputName {
            font-family: Roboto-Regular;
            font-size: 16px;
            color: #000000;
            letter-spacing: 0;
            line-height: 16px;
            .ant-select-arrow {
                color: #000 !important;
            }
            .ant-input-group-addon {
                padding-right: 0 !important;
            }
            .ant-input {
                border-left: none !important;
                font-size: 16px !important;
                color: #000000 !important;
            }
            .ant-form-item {
                margin-bottom: 30px !important;
            }
            .ant-select-arrow {
                color: #000000 !important;
            }
        }
        .cc-picker {
            .ant-select-selector {
                border: none !important;
            }
        }
        @media (max-width: 768px) {
            width: 100%;
            margin: 0;
            padding: 0 16px;
        }
    }
    .formItem {
        width: 275px;
        @media (max-width: 768px) {
            width: 100%;
        }
    }
    .formItems {
        width: 100%;
        @media (max-width: 768px) {
            width: 100%;
        }
    }
    .formItemleft {
        width: 275px;
        margin-right: 28px;
        @media (max-width: 768px) {
            width: 100%;
            margin-right: 0;
        }
    }
    .btndisabled {
        opacity: 0.5;
    }
`

const AddressTitle = styled.div`
    width: 100%;
    text-align: center;
    font-family: Roboto-Medium;
    font-size: 30px;
    color: #000000;
    text-align: center;
    line-height: 28px;
    margin-bottom: 30px;
`

const SubButton = styled(Button)`
    width: 100%;
    height: 50px;
    border-radius: 36px;
    font-family: Roboto-Medium;
    font-size: 25.33px;
    color: #fff;
    letter-spacing: 0;
    text-align: center;
    line-height: 30px;
    margin-top: 26px;
`

const StyledInput = styled(Input)`
    height: 50px;
    border-radius: 10px;
    .select-before {
        height: 50px;
        .ant-select-selection-search {
            height: 50px !important;
        }
        .selectPopupClass {
            width: 300px !important;
        }
    }
    .ant-input-group-addon {
        height: 50px !important;
        background: #ffffff !important;
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
        .ant-select-selector {
            width: 100%;
            height: 50px !important;
            border-top-left-radius: 10px !important;
            border-bottom-left-radius: 10px !important;
            .ant-select-selection-item {
                font-size: 16px !important;
                height: 50px !important;
                line-height: 50px !important;
            }
        }
    }
    .ant-input {
        height: 50px !important;
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }
`
const StateSelect = styled(Select)`
    .ant-select-selector {
        height: 50px !important;
        line-height: 50px !important;
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
        .ant-select-selection-item {
            font-size: 16px !important;
            height: 50px !important;
            line-height: 50px !important;
        }
        .ant-select-selection-placeholder {
            line-height: 50px !important;
        }
    }
`
