import React, { FC } from 'react'
import styled from '@emotion/styled'

import { CheckCircleOutlined } from '@ant-design/icons'

import fail from 'assets/fail.png'
import backIcon from 'assets/back.png'

export const SuccessIcon: FC = () => <CheckCircleOutlined style={{ fontSize: 63 }} />
export const FailIcon: FC = () => <img style={{ width: '63px', height: '63px' }} src={fail} alt="" />
export const BackIcon: FC<{ onClick: () => void }> = ({ onClick }) => {
    return <IconDiv src={backIcon} onClick={onClick} />
}

const IconDiv = styled.div<{ src: string }>`
    width: 30px;
    height: 30px;
    background-image: url(${({ src }) => src});
    background-repeat: no-repeat;
    background-size: 30px;
    cursor: pointer;
    @media (max-width: 768px) {
        width: 20px;
        height: 20px;
        background-size: 20px;
    }
`
