import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { Tabs } from 'antd-mobile'

import { useApiError, useShowMessage } from 'shared/hooks'
import { useOrderList } from 'core/order/api'
import { useI18n } from 'core/language/api'
import { OrderStatusEnum } from 'core/order/models'
import { getToken } from 'shared/config'
import { useSelectedDelivery } from 'core/delivery/api'

import { OrderActions, OrderItem, Spin } from 'components'
import { Header } from '../components/header'

import noData from '../assets/no-order.png'

const orderStatus = [
    '',
    OrderStatusEnum.UNPAY,
    OrderStatusEnum.PAID,
    OrderStatusEnum.DELIVERED,
    OrderStatusEnum.FINISH
]

export const OrderList: FC = () => {
    const {
        methods: { t }
    } = useI18n()
    const navigate = useNavigate()
    const {
        methods: { error }
    } = useShowMessage()

    const {
        methods: { handleError }
    } = useApiError('OrderList', error as any)

    const {
        empty,
        list,
        loading,
        methods: { getList, cancel, confirm, remove }
    } = useOrderList()

    const tabs: { key: string; title: string }[] = useMemo(() => {
        return orderStatus.map(key => ({
            key: `${key}`,
            title: t(`order.status.${key === '' ? 'all' : key}`)
        }))
    }, [t])

    const [currentStatus, setCurrentStatus] = useState<OrderStatusEnum | ''>('')

    const onTabChange = useCallback(
        (key: OrderStatusEnum | '') => {
            setCurrentStatus(key)
            getToken() && getList(key).catch(handleError)
        },
        [getList, handleError]
    )

    useEffect(() => {
        const T = setTimeout(() => {
            onTabChange('')
        })
        return () => clearTimeout(T)
    }, [onTabChange])

    const handleCancel = useCallback(
        (code: string) => cancel(code, currentStatus).catch(handleError),
        [cancel, handleError, currentStatus]
    )

    const handleConfirm = useCallback(
        (code: string) => {
            confirm(code, currentStatus).catch(handleError)
        },
        [confirm, handleError, currentStatus]
    )

    const handleRemove = useCallback(
        (code: string) => remove(code, currentStatus).catch(handleError),
        [handleError, remove, currentStatus]
    )

    const {
        methods: { setUnpaid }
    } = useSelectedDelivery()
    const handlePayUnpaidOrder = useCallback(
        (code: string, billDeliveryDetail, deliveryDetail) => {
            setUnpaid(billDeliveryDetail, deliveryDetail)
            setTimeout(() => {
                navigate(`/orders/place/${code}`)
            }, 500)
        },
        [navigate, setUnpaid]
    )

    return (
        <Spin spinning={loading}>
            <Wrapper>
                <Header name="Order" />
                <Tabs
                    defaultActiveKey=""
                    stretch={false}
                    onChange={key => onTabChange(key as OrderStatusEnum | '')}
                >
                    {tabs.map(({ title, key }) => (
                        <Tabs.Tab title={title} key={key}>
                            {!getToken() || empty ? (
                                <div className="no-data" />
                            ) : (
                                <div className="listWrapper">
                                    {list.map(
                                        ({
                                            type,
                                            code,
                                            price: { total },
                                            store: { name: storeName, icon },
                                            status,
                                            items,
                                            hasActions,
                                            billDeliveryDetail,
                                            deliveryDetail,
                                            virtual
                                        }) => (
                                            <OrderItem
                                                className="item"
                                                key={code}
                                                virtual={virtual}
                                                type={type}
                                                items={items}
                                                total={total}
                                                name={storeName}
                                                icon={icon}
                                                status={status}
                                                footer={
                                                    hasActions && (
                                                        <div className="mt-6">
                                                            <OrderActions
                                                                status={status}
                                                                onCancel={() => handleCancel(code)}
                                                                onPay={() =>
                                                                    handlePayUnpaidOrder(
                                                                        code,
                                                                        billDeliveryDetail,
                                                                        deliveryDetail
                                                                    )
                                                                }
                                                                onConfirm={() => handleConfirm(code)}
                                                                onRemove={() => handleRemove(code)}
                                                            />
                                                        </div>
                                                    )
                                                }
                                                onClick={() => navigate(`/orders/${code}`)}
                                            />
                                        )
                                    )}
                                </div>
                            )}
                        </Tabs.Tab>
                    ))}
                </Tabs>
            </Wrapper>
        </Spin>
    )
}

const Wrapper = styled.div`
    .adm-tabs-content {
        padding: 0;
    }
    .adm-tabs-tab-wrapper {
        padding: 0 25px;
    }
    .adm-tabs-tab {
        font-family: Roboto-Regular;
        font-size: 20px;
        color: #9d9d9d;
        letter-spacing: 0;
        font-weight: 400;
        padding: 23px 0;
    }
    .adm-tabs-tab-active {
        font-family: Roboto-Regular;
        font-size: 20px;
        color: #000000;
        letter-spacing: 0;
        font-weight: 700;
    }
    .no-data {
        width: 100%;
        height: 33rem;
        background: url(${noData}) no-repeat center top 10rem / 23rem auto;
    }
    .listWrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 20px;
    }
    .item {
        width: 100%;
        .productDescPriceNum {
            position: relative;
            //width: 224px;
        }
    }

    @media (max-width: 768px) {
        .adm-tabs-tab-list {
            justify-content: center;
        }
        .adm-tabs-tab-line {
            left: 0;
        }
        .item {
            width: calc(100% - 40px);
            margin-left: 20px;
            margin-right: 20px;
            .productDescPriceNum {
                position: relative;
                width: 100%;
            }
        }
    }

    @media (max-width: 576px) {
        .adm-tabs {
            margin-top: 20px;
        }
        .adm-tabs-tab-wrapper {
            padding: 0 10px;
        }
        .adm-tabs-tab {
            font-size: 17px;
            padding: 20px 0;
        }
        .item {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }
`
