import {
    CartItem,
    CartItemError,
    CartItemErrorEnum,
    CartItemGroupByStore,
    CartItemRender
} from 'core/cart/models'
import {
    convertTimestampToDateWithTz,
    convertToDollar,
    convertToDurations,
    convertToImageUrl,
    getRootUrlPrefix
} from 'shared/utils'

interface CartItemSpec {
    attrName: string
    valueName: string
}

export interface StoreReference {
    id: string
    name: string
    icon: string
    type: string
}

export interface CartItemDTO {
    code: string
    error: CartItemError | null
    images: string[]
    name: string
    price: string
    depositPrice: string
    productId: string
    quantity: number
    purchaseRestrictions: boolean
    purchaseRestrictionsCount: number
    remainingQ: number
    stock: number
    skuId: string
    spec: CartItemSpec[]
    preOrder: boolean
    onceLimit: number
    storeId: string
    virtual: boolean
    type: number

    timeZone?: string
    duration?: string
}

export const convertToCartItem = async (
    dto: CartItemDTO[],
    references: StoreReference[]
): Promise<CartItemRender> => {
    const prefix = await getRootUrlPrefix()
    const storeMap = references
        .map(store => ({ ...store, icon: convertToImageUrl(prefix, store.icon) }))
        .reduce<Record<string, StoreReference>>(
            (acc, curr) => ({
                ...acc,
                [curr.id]: curr
            }),
            {}
        )

    const cartItems: CartItem[] = dto.map(cartItem => ({
        ...cartItem,
        price: convertToDollar(cartItem.price),
        depositPrice: cartItem.depositPrice ? convertToDollar(cartItem.depositPrice) : '0.00',
        image: convertToImageUrl(prefix, cartItem.images[0]),
        durations:
            cartItem?.timeZone && cartItem?.duration
                ? convertToDurations(cartItem.duration).map(timestamp =>
                      convertTimestampToDateWithTz(timestamp, cartItem.timeZone!)
                  )
                : []
        // spec: cartItem.skuId ? cartItem.spec.map(({ valueName }) => valueName).join('/') : null
    }))
    const ineffectiveList = cartItems.filter(item => item.error?.scope === CartItemErrorEnum.PRODUCT)

    const res = cartItems
        .filter(item => item.error?.scope !== CartItemErrorEnum.PRODUCT)
        .reduce(
            ({ result, indexOfStoreId }, item) => {
                if (!(item.storeId in indexOfStoreId)) {
                    indexOfStoreId[item.storeId] = result.length
                    result.push({ store: storeMap[item.storeId], items: [] })
                }

                result[indexOfStoreId[item.storeId]].items.push(item)

                return { result, indexOfStoreId }
            },
            {
                result: [],
                indexOfStoreId: {}
            } as { result: CartItemGroupByStore[]; indexOfStoreId: Record<string, number> }
        )
    return { cartList: res.result, ineffectiveList }
}

export const convertToCartItemNoAuth = (
    cartList: CartItemGroupByStore[],
    ineffectiveList: CartItem[]
): CartItemRender => {
    return {
        cartList: cartList.map(item => {
            return {
                ...item,
                store: item.store
            }
        }),
        ineffectiveList
    }
}
