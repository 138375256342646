import React, { FC, useCallback, useMemo, useState } from 'react'
import styled from '@emotion/styled'

import { EventOrderEnum, EventPersonalInfo } from 'core/event/models'
import { useI18n } from 'core/language/api'
import { Reg } from 'shared/utils'
import { CountryCodeList } from 'shared/CountryCode'
import { useResponsive, useShowMessage } from 'shared/hooks'

import { Form, Input } from 'antd'
import { Button, CountryCodePicker } from 'components'
import { CreateSteps } from './CreateSteps'

const initialCC = 'US'

interface PersonalInfoFormProps {
    next: (formData: EventPersonalInfo) => void
    step: EventOrderEnum
}

export const PersonalInfoForm: FC<PersonalInfoFormProps> = ({ next, step }) => {
    const {
        methods: { t }
    } = useI18n()
    const { md } = useResponsive()
    const {
        methods: { showErrorModal }
    } = useShowMessage()

    const [disabled, setDisabled] = useState(true)
    const [form] = Form.useForm<EventPersonalInfo>()
    const [ccLocale, setCcLocale] = useState(initialCC)
    const cc = useMemo(() => CountryCodeList.find(({ locale }) => locale === ccLocale)?.code, [ccLocale])

    const handleValuesChange = (_: Partial<EventPersonalInfo>, allValues: EventPersonalInfo) => {
        const { confirmEmail, email, firstName, lastName } = allValues

        return setDisabled(!(confirmEmail && email && firstName && lastName && Reg.email.test(email)))
    }

    const onFinish = useCallback(() => {
        form.validateFields().then(values => {
            const { firstName, lastName, phone, email, confirmEmail } = values
            const emailLC = email.toLowerCase()
            const confirmEmailLC = confirmEmail.toLowerCase()
            if (emailLC !== confirmEmailLC) {
                showErrorModal(t('error.email_twice_inconsistent'))
                return
            }
            const phoneTrim = phone && phone.trim()
            next({
                ...values,
                phone: phone ? `+${cc}${phoneTrim}` : '',
                firstName: firstName && firstName.trim(),
                lastName: lastName && lastName.trim(),
                email: emailLC,
                confirmEmail: confirmEmailLC
            })
        })
    }, [cc, form, next, showErrorModal, t])

    return (
        <Container>
            <PersonalInfoFormDiv>
                <CreateSteps step={step} />
                <Form form={form} onValuesChange={handleValuesChange} autoComplete="off" className="form">
                    <FirstFormItem>
                        <Form.Item
                            name="firstName"
                            rules={[{ required: true, message: '' }]}
                            className="firstFormItemInput"
                        >
                            <Inputs placeholder="First Name *" className="inputName" />
                        </Form.Item>
                        <Form.Item
                            name="lastName"
                            rules={[{ required: true, message: '' }]}
                            className="firstFormItemInput"
                        >
                            <Inputs placeholder="Last Name *" className="inputName" />
                        </Form.Item>
                    </FirstFormItem>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                warningOnly: true,
                                validator(_, value) {
                                    if (value && Reg.email.test(value)) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject()
                                }
                            }
                        ]}
                        className="form-item"
                    >
                        <Inputs type="email" placeholder="Email *" className="inputName" />
                    </Form.Item>
                    <Form.Item
                        name="confirmEmail"
                        rules={[
                            ({ getFieldValue }) => ({
                                warningOnly: true,
                                validator(_, value) {
                                    if (
                                        value &&
                                        Reg.email.test(value) &&
                                        (getFieldValue('email') as string).toLowerCase() ===
                                            (value as string).toLowerCase()
                                    ) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject()
                                }
                            })
                        ]}
                        className="form-item"
                    >
                        <Inputs type="email" placeholder="Confirm Email *" className="inputName" />
                    </Form.Item>
                    <Form.Item name="phone" className="form-item">
                        <Inputs
                            addonBefore={
                                <CountryCodePicker
                                    popupClassName={md ? 'personal-info' : 'personal-info-mobile'}
                                    ccLocale={ccLocale}
                                    setCcLocale={setCcLocale}
                                />
                            }
                            placeholder="Phone Number"
                            className="selectName"
                        />
                    </Form.Item>
                    <Form.Item name="companyName" className="form-item">
                        <Inputs placeholder="Company" className="inputName" />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            style={{ width: '100%' }}
                            className="submit-btn"
                            onClick={onFinish}
                            disabled={disabled}
                        >
                            Next
                        </Button>
                    </Form.Item>
                </Form>
            </PersonalInfoFormDiv>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 30px 0 158px 0;
    @media (max-width: 768px) {
        width: 100%;
        margin: 0;
    }
`
const PersonalInfoFormDiv = styled.div`
    width: 620px;
    .form {
        width: 580px;
        margin: 40px 20px 0 20px;
        .ant-form-item {
            margin-bottom: 30px !important;
            @media (max-width: 768px) {
                margin-bottom: 20px !important;
            }
        }
        .ant-form-item-explain,
        .ant-form-item-explain-connected {
            min-height: 0px !important;
        }
        .inputName {
            font-family: Roboto-Regular;
            font-size: 16px;
            color: #000000;
            letter-spacing: 0;
            line-height: 16px;
            padding: 17px;
            @media (max-width: 768px) {
                margin: 0;
            }
        }
        .selectName {
            font-family: Roboto-Regular;
            font-size: 16px;
            color: #000000;
            letter-spacing: 0;
            line-height: 16px;
            .ant-select-arrow {
                color: #000 !important;
            }
            .ant-input-group-addon {
                padding-right: 0 !important;
            }
            .ant-input {
                border-left: none !important;
            }
            .ant-select-selector {
                border: none !important;
            }
            @media (max-width: 768px) {
                margin: 0;
            }
        }
        @media (max-width: 768px) {
            width: 100%;
            margin: 30px 0 103px 0;
        }
        .form-item {
            height: 50px;
        }
    }
    .formfirstrow {
        margin-left: 10%;
    }
    .submit-btn {
        font-family: Roboto-Medium;
        font-size: 25.33px;
        color: #ffffff;
        letter-spacing: 0;
        text-align: center;
        line-height: 30.96px;
        margin-top: 26px;
    }
    @media (max-width: 768px) {
        width: 100%;
        flex-wrap: wrap;
        padding: 0 20px;
    }
`

const FirstFormItem = styled.div`
    display: flex;
    justify-content: space-between;
    .firstFormItemInput {
        width: 275px;
        /* margin-bottom: 30px; */
        @media (max-width: 768px) {
            width: 100%;
            /* margin-bottom: 20px; */
        }
    }
    /* .ant-form-item-explain,
    .ant-form-item-explain-connected {
        display: none;
    } */
    @media (max-width: 768px) {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
`

const Inputs = styled(Input)`
    height: 50px;
    border-radius: 10px;
    .select-before {
        height: 50px;
        .ant-select-selection-search {
            height: 50px !important;
        }
        .ant-select-dropdown,
        .ant-select-dropdown-placement-bottomLeft {
            width: 300px !important;
        }
    }
    .ant-input-group-addon {
        height: 50px !important;
        background: #ffffff !important;
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
        .ant-select-selector {
            height: 50px !important;
            border-top-left-radius: 10px !important;
            border-bottom-left-radius: 10px !important;
            .ant-select-selection-item {
                height: 50px !important;
                line-height: 50px !important;
            }
        }
    }
    .ant-input {
        height: 50px !important;
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }
`
