import React, { useMemo, useState, useRef, useCallback, useEffect } from 'react'

import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { Upload, Modal, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import type { UploadProps } from 'antd/es/upload/interface'

import { pathJoinPrefix } from 'shared/utils'
import { pathEq, path } from 'ramda'
import { useShowMessage } from 'shared/hooks'

interface Image {
    id: string
    url: string
}

interface Props {
    value?: Image[]
    onChange?: (v: Image[]) => void
    cropper?: {
        width: number
        height: number
    }
    uploadUrl: string
    imageSrcPrefix: string
    accessHeader: { [key: string]: string }
    max?: number
    showUploadList?: UploadProps['showUploadList']
}

export const UploadImagesFormItem: React.FC<Props> = ({
    value = [],
    onChange = () => {},
    cropper,
    uploadUrl,
    imageSrcPrefix,
    accessHeader,
    max = 8,
    showUploadList
}) => {
    const cropperRef = useRef<any>(null)
    const [imageDataUrl, setImageDataUrl] = useState<string>()
    const cropperResolveFn = useRef<(b: Blob) => void>()
    const cropperRejectFn = useRef<() => void>()

    const {
        methods: { error }
    } = useShowMessage()

    const [fileList, setFileList] = useState<any[]>([])
    useEffect(() => {
        setFileList(
            value.map(({ url, id }, index) => ({
                uid: `${index}`,
                name: 'image.png',
                status: 'done',
                url: pathJoinPrefix(imageSrcPrefix, url),
                fileUrl: url,
                fileId: id
            }))
        )
    }, [imageSrcPrefix, value])

    const uploadAccessProps = useMemo(() => {
        return accessHeader
            ? {
                  headers: accessHeader
              }
            : { withCredentials: true }
    }, [accessHeader])

    const handleUploadChange = useCallback(
        (info: any) => {
            if (info.fileList.length === 0) {
                setFileList([])
                onChange([])
                return
            }

            const errorFile = info.fileList.find((item: any) => {
                const code = path(['response', 'code'], item)
                return code && code !== 200
            })
            if (errorFile) {
                error('Upload failed')
            }

            const newFileList = info.fileList
                .filter((file: any) => {
                    // 过滤超过10M的文件
                    if (file?.size) {
                        const isLt10M = file.size / 1024 / 1024 < 10
                        if (!isLt10M) {
                            return false
                        }
                    }
                    // 过滤掉失败的
                    const code = path(['response', 'code'], file)
                    return !code || code === 200
                })
                .map((file: any) => {
                    if (pathEq(['response', 'code'], 200, file)) {
                        const {
                            response: {
                                data: { fileUrl, id }
                            }
                        } = file
                        file.url = pathJoinPrefix(imageSrcPrefix, fileUrl)
                        file.fileUrl = fileUrl
                        file.fileId = id
                    }
                    return file
                })
            setFileList(newFileList)

            if (!newFileList.find((item: { fileUrl: string }) => !item.fileUrl)) {
                // 只有在多上传完成后 才onchange
                onChange(
                    newFileList.map((item: any) => ({
                        id: item.fileId,
                        url: item.fileUrl
                    }))
                )
            }
        },
        [error, imageSrcPrefix, onChange]
    )

    const handleCrop = () => {
        cropperRef
            .current!.getCroppedCanvas({
                imageSmoothingEnabled: true,
                imageSmoothingQuality: 'high'
            })
            .toBlob(
                (blob: Blob) => {
                    setImageDataUrl('')
                    cropperResolveFn.current!(blob!)
                },
                'image/jpeg',
                0.8
            )
    }
    const handleCropCancel = () => {
        setImageDataUrl('')
    }

    const beforeUpload = useCallback(
        (file: File & { uid: string }) => {
            const isLt10M = file.size / 1024 / 1024 < 10
            if (!isLt10M) {
                error('Image must smaller than 10M')
                return false
            }
            if (!cropper) {
                return Promise.resolve(file)
            }
            const reader = new FileReader()
            reader.onload = function (ev) {
                setImageDataUrl(ev.target!.result as string)
            }
            reader.readAsDataURL(file)
            return new Promise<Blob>((resolve, reject) => {
                cropperResolveFn.current = resolve
                cropperRejectFn.current = reject
            }).then(blob => {
                const fileResult: any = new window.File([blob], file.name, {
                    type: file.type
                })
                fileResult.uid = file.uid
                return fileResult
            }) as Promise<any>
        },
        [cropper, error]
    )

    const onPreview: UploadProps['onPreview'] = file => {
        window.open(file.url)
    }

    const uploadProps: UploadProps = useMemo(() => {
        return {
            name: 'file',
            listType: 'picture-card',
            action: uploadUrl,
            accept: 'image/*',
            onChange: handleUploadChange,
            fileList,
            showUploadList:
                showUploadList !== undefined
                    ? showUploadList
                    : {
                          showRemoveIcon: true,
                          showPreviewIcon: true,
                          showDownloadIcon: false
                      },
            beforeUpload,
            onPreview,
            previewFile: (f: any) => {
                return Promise.resolve(fileList.find(item => item.uid === f.uid).url)
            },
            ...uploadAccessProps
        }
    }, [uploadUrl, handleUploadChange, fileList, showUploadList, beforeUpload, uploadAccessProps])

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8, fontSize: 18 }}>Upload</div>
        </div>
    )

    return (
        <>
            <Upload {...uploadProps} className="avatar-uploader">
                {fileList.length < max && uploadButton}
            </Upload>
            <Modal
                className="uploadModal"
                open={!!imageDataUrl}
                style={{ minWidth: '1024' }}
                mask={true}
                maskClosable={false}
                onCancel={handleCropCancel}
                destroyOnClose={true}
                footer={[
                    <Button key="cancel" onClick={handleCropCancel}>
                        取消
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleCrop}>
                        提交
                    </Button>
                ]}
            >
                {cropper && (
                    <Cropper
                        className="uploadCroper"
                        ref={cropperRef}
                        src={imageDataUrl}
                        aspectRatio={cropper.width / cropper.height}
                        rotatable={false}
                        viewMode={1}
                        style={{ width: '100%', height: window.innerWidth > 576 ? '500px' : '400px' }}
                    />
                )}
            </Modal>
        </>
    )
}
