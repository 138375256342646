import React, { FC, useEffect, useCallback, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'

import { useSignInRedirect, useUserInfo } from 'shared/hooks'
import { useAuth } from 'core/auth/api'
import { useI18n } from 'core/language/api'
import { TAB_TYPE_ENUM } from 'core/aff/models'
import { SIGN_STATUS_ENUM } from 'core/auth/models'

import { PageWrapper, Spin } from 'components'
import { Dialog } from 'antd-mobile'

import {
    ADMIN_LINK_DEVICE,
    ADMIN_LINK_NFT,
    ADMIN_LINK_STORE,
    ADMIN_LINK_AD,
    ADMIN_LINK_SPONSOR,
    getIsTourist,
    removeTouristAddress
} from 'shared/config'

import mineIcon from './assets/mine.png'
import bannerIcon from './assets/banner_mobile.png'
import right from './assets/right.png'

interface UserInfo {
    email: string
    phone: string
    userName: string
    status: number
    link: string
    shareClickCount: string
    shareRegisterCount: string
    totalAmount: number
    withdrawAmount: number
    ingAmount: number
}

export const MobileUser: FC = () => {
    const {
        methods: { t }
    } = useI18n()
    const navigate = useNavigate()
    const { checkLogin, loginOut } = useSignInRedirect()

    useEffect(() => {
        checkLogin('/mobile-mine').catch()
    }, [checkLogin])

    const { getToken } = useSignInRedirect()
    const [userInfo, setUserInfo] = useState<UserInfo>({} as UserInfo)
    const canView = useMemo(() => getToken() && !getIsTourist(), [getToken])
    const {
        loading,
        methods: { getUserInfo }
    } = useAuth()
    useEffect(() => {
        getToken() &&
            getUserInfo()
                .then(data => {
                    setUserInfo(data as unknown as UserInfo)
                })
                .catch()
    }, [getUserInfo, getToken])

    const userAdminInfo = useUserInfo()

    const canViewAdminButton = useMemo(
        () =>
            userAdminInfo?.storeAdmin ||
            userAdminInfo?.role.nftSuperAdmin ||
            userAdminInfo?.role.deviceAdmin ||
            userAdminInfo?.role.adAdmin ||
            userAdminInfo?.role.sponsorAdmin ||
            (userAdminInfo?.sponsorList && userAdminInfo.sponsorList.length > 0),
        [userAdminInfo]
    )
    const adminLink = useMemo(() => {
        if (userAdminInfo?.storeAdmin) return ADMIN_LINK_STORE
        if (userAdminInfo?.role.nftSuperAdmin) return ADMIN_LINK_NFT
        if (userAdminInfo?.role.deviceAdmin) return ADMIN_LINK_DEVICE
        if (userAdminInfo?.role.adAdmin) return ADMIN_LINK_AD
        if (
            userAdminInfo?.role.sponsorAdmin ||
            (userAdminInfo?.sponsorList && userAdminInfo.sponsorList.length > 0)
        )
            return ADMIN_LINK_SPONSOR
    }, [userAdminInfo])

    const handleOutLogin = useCallback(() => {
        removeTouristAddress()
        Dialog.confirm({
            content: t('client.me.isLogout'),
            cancelText: t('form.actions.cancel'),
            confirmText: t('form.actions.OK'),
            onConfirm: () => {
                loginOut('/')
            }
        })
    }, [loginOut, t])

    return (
        <Spin spinning={loading}>
            <Wrapper>
                <div className="banner">
                    <img src={bannerIcon} alt="banner" />
                </div>
                <div className="userInfo">
                    <div className="avatar">
                        <img src={mineIcon} alt="" />
                    </div>
                    {userInfo.status === 2 ? (
                        <>
                            <div className="user-name">{t('Guest')}</div>
                        </>
                    ) : (
                        <>
                            <div className="user-name">{userInfo.userName}</div>
                        </>
                    )}
                    {canViewAdminButton && (
                        <AdminButtonWrapper>
                            <AdminButton onClick={() => window.open(adminLink)}>Admin</AdminButton>
                        </AdminButtonWrapper>
                    )}
                </div>
                <div className="content">
                    <div className="item" onClick={() => navigate(`/mine/${TAB_TYPE_ENUM.AFFILIATE_LINK}`)}>
                        <div>Affiliate link</div>
                        <img src={right} alt="" />
                    </div>
                    {canView && (
                        <div
                            className="item"
                            onClick={() => navigate(`/mine/${TAB_TYPE_ENUM.WALLETADDRESS}`)}
                        >
                            <div>Wallet</div>
                            <img src={right} alt="" />
                        </div>
                    )}
                    <div className="item" onClick={() => navigate(`/mine/${TAB_TYPE_ENUM.ORDERS}`)}>
                        <div>Order</div>
                        <img src={right} alt="" />
                    </div>
                    {!getIsTourist() && (
                        <div className="item" onClick={() => navigate(`/mine/${TAB_TYPE_ENUM.ADDRESS}`)}>
                            <div>Address</div>
                            <img src={right} alt="" />
                        </div>
                    )}
                    {userInfo.status !== 2 && (
                        <>
                            {/* <div className="item" onClick={() => navigate(`/mine/${TAB_TYPE_ENUM.EMAIL}`)}>
                                <div>Email</div>
                                <div className="right">
                                    {userInfo.email}
                                    <img src={right} alt="" />
                                </div>
                            </div> */}
                            <div className="item" onClick={() => navigate(`/mine/${TAB_TYPE_ENUM.PHONE}`)}>
                                <div>Phone</div>
                                <div className="right">
                                    {userInfo.phone}
                                    <img src={right} alt="" />
                                </div>
                            </div>
                            <div className="item" onClick={() => navigate(`/mine/${TAB_TYPE_ENUM.PASSWORD}`)}>
                                <div>Password</div>
                                <img src={right} alt="" />
                            </div>
                        </>
                    )}
                </div>
                {userInfo.status === 2 && (
                    <div
                        className="binding"
                        onClick={() => navigate(`/auth/${SIGN_STATUS_ENUM.TOURIST_BINGING}`)}
                    >
                        Generate
                    </div>
                )}
                <div className="logout" onClick={handleOutLogin}>
                    Logout
                </div>
            </Wrapper>
        </Spin>
    )
}

const Wrapper = styled(PageWrapper)`
    padding: 0;
    padding-bottom: 38px;
    .banner {
        width: 100%;
    }
    .userInfo {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: -40px;
        margin-bottom: 10px;
        font-family: PingFangSC-Medium;
        font-size: 18px;
        color: #000000;
        letter-spacing: 0;
        text-align: center;
        line-height: 20px;
    }
    .avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        margin-bottom: 20px;
        background-color: #ebebeb;
        border-radius: 50%;
        img {
            width: 46px;
            height: 46px;
        }
    }
    .content {
        padding: 0 20px;
        .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 60px;

            font-family: Roboto-Bold;
            font-size: 16px;
            color: #000000;
            letter-spacing: 0;
            border-bottom: 1px solid rgba(151, 151, 151, 1);
            .right {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                font-family: Roboto-Regular;
                font-size: 16px;
                color: #666666;
                letter-spacing: 0;
                text-align: right;
            }
            img {
                width: 16px;
                height: 16px;
                margin-left: 10px;
            }
        }
    }
    .logout {
        margin: 30px 0;
        font-family: Roboto-Medium;
        font-size: 16px;
        color: #000000;
        letter-spacing: 0;
        text-align: center;
        line-height: 20px;
        text-decoration: underline;
    }
    .binding {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 197px;
        height: 50px;
        margin: 0 auto;
        margin-top: 140px;
        background: #000000;
        border-radius: 35.18px;
        font-family: Roboto-Medium;
        font-size: 25.33px;
        color: #ffffff;
        letter-spacing: 0;
        text-align: center;
        line-height: 30.96px;
    }
    @media (max-width: 768px) {
        width: 100%;
    }
`
const AdminButtonWrapper = styled.div`
    margin-top: 30px;
`
const AdminButton = styled.div`
    width: 100px;
    border: 1px solid rgba(0, 0, 0, 1);
    border-radius: 22px;
    font-family: Roboto-Medium;
    font-size: 16px;
    color: #000000;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
`
