import { groupBy } from 'ramda'
import { AuthAddressItem, MyCollectionList, NftDetail, NftInList } from '../models'

interface NftReference {
    token_id: string
}

export const convertToDetail = (dto: NftInList, references: NftReference[]): NftDetail => ({
    ...dto,
    token_id: references[0]?.token_id
})

export interface NFTDetailDTO {
    id: string
    name: string
    image_url: string
    token_address: string
    token_id: string
    type: string
    animation_url: string
    description: string
    background_color: string
    owner: string
}
export const convertToNFTDetail = (dto: NFTDetailDTO): MyCollectionList => ({
    name: dto.name,
    imgUrl: dto.image_url,
    id: dto.id,
    tokenId: dto.token_id,
    type: dto.type,
    description: dto.description,
    tokenAddress: dto.token_address,
    animationUrl: dto.animation_url,
    backgroundColor: dto.background_color,
    owner: dto.owner
})

export const convertToNFTList = (dto: NFTDetailDTO[]): MyCollectionList[] => {
    return dto.map(convertToNFTDetail)
}

export const screenAccount = (dto: AuthAddressItem[]): AuthAddressItem[] => {
    if (dto.length === 0) return []

    const grouped = groupBy(({ addressType }) => {
        return addressType === 0
            ? '0'
            : addressType === 1
            ? '1'
            : addressType === 3
            ? '2'
            : addressType === 2
            ? '3'
            : '4'
    }, dto)

    const sorted = []
    if (grouped[0]) {
        sorted.push(...grouped[0])
    }
    if (grouped[1]) {
        sorted.push(...grouped[1])
    }
    if (grouped[2]) {
        sorted.push(...grouped[2])
    }
    if (grouped[3]) {
        sorted.push(...grouped[3])
    }
    if (grouped[4]) {
        sorted.push(...grouped[4])
    }

    return sorted.map(item => ({
        ...item,
        name: item.addressType === 4 ? `${item.ownerName}_${item.name}` : item.name
    }))
}
