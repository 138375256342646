import React, { FC } from 'react'
import styled from '@emotion/styled'

import { Delivery, DeliveryTypeEnum } from 'core/delivery/models'
import { ADDRESS_TYPE } from 'modules/address'

import disabled from '../assets/disabled.png'
import checked from '../assets/checked.png'
import addressDefault from '../assets/addressDefault.png'
import { useDeliveryDetail, useDeliveryList } from 'core/delivery/api'
import { useApiError } from 'shared/hooks'

interface AddressCardProps {
    delivery: Delivery
    onEdit: () => void
    onRemove: () => void
    type?: ADDRESS_TYPE
    selectedId?: string
    className?: string
    onClick?: () => void
    addressType?: DeliveryTypeEnum
}

export const AddressCard: FC<AddressCardProps> = ({
    delivery: {
        firstName,
        lastName,
        phone,
        state,
        streetAddress,
        apartment,
        companyName,
        id,
        city,
        zipCode,
        isDefault,
        email
    },
    onEdit,
    onRemove,
    type,
    selectedId,
    className,
    addressType,
    onClick
}) => {
    const {
        methods: { handleError }
    } = useApiError('AddressCard')
    const {
        methods: { getList }
    } = useDeliveryList(addressType)
    const {
        methods: { setDefault }
    } = useDeliveryDetail(id as string)

    return (
        <Wrapper className={className} onClick={() => onClick && onClick()}>
            <div className="info">
                <WrapperName>
                    <div className="user-name">
                        {firstName} {lastName}
                    </div>
                    {addressType === DeliveryTypeEnum.DELIVERY && isDefault && (
                        <ImgDefault src={addressDefault} alt="" />
                    )}
                </WrapperName>
                <div
                    className={
                        addressType === DeliveryTypeEnum.DELIVERY
                            ? 'address-name'
                            : 'address-name billing-address'
                    }
                >
                    {addressType === DeliveryTypeEnum.DELIVERY && (
                        <div>{companyName && `${companyName}`}</div>
                    )}
                    <div>{streetAddress}</div>
                    <div>{apartment}</div>
                    <div>
                        {city}, {state} {zipCode}
                    </div>
                    {addressType === DeliveryTypeEnum.DELIVERY && <div>{phone && `Phone : ${phone}`}</div>}
                    {addressType === DeliveryTypeEnum.DELIVERY && <div>{email && `Email : ${email}`}</div>}
                </div>
            </div>
            <div className="bottom">
                <div className="left">
                    {type && (
                        <>
                            {selectedId === id ? <img src={checked} alt="" /> : <img src={disabled} alt="" />}
                        </>
                    )}
                </div>
                <div className="right">
                    <div
                        className="cursor-pointer"
                        onClick={e => {
                            e.stopPropagation()
                            onEdit()
                        }}
                    >
                        Edit
                    </div>
                    <ColumnLine />
                    <div
                        className="cursor-pointer"
                        onClick={e => {
                            e.stopPropagation()
                            onRemove()
                        }}
                    >
                        Remove
                    </div>
                    {addressType === DeliveryTypeEnum.DELIVERY && !isDefault && (
                        <div className="flex cursor-pointer">
                            <ColumnLine />
                            <div
                                onClick={() =>
                                    setDefault({ isDefault: true }).then(getList).catch(handleError)
                                }
                            >
                                Set as Default
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    padding: 12px 17px 0;
    margin-bottom: 20px;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);

    .address-name {
        height: 106px;
        font-family: Roboto-Regular;
        font-size: 14px;
        color: #303030;
        letter-spacing: 0;
        line-height: 18px;
        font-weight: 400;
        margin-bottom: 10px;
    }
    .billing-address {
        height: 72px;
    }
    .info {
        width: 100%;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #303030;
        letter-spacing: 0;
        line-height: 20px;
        font-weight: 400;
        border-bottom: 1px solid #f0f0f0;

        .user-name {
            line-height: 32px;
            font-family: Roboto-Medium;
            font-size: 18px;
            color: #000000;
            font-weight: 500;
            margin: 0;
        }
        > div {
            /* margin-bottom: 10px; */
        }
    }
    .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 40px;
        img {
            width: 24px;
            height: auto;
            cursor: pointer;
        }
        .right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-family: Roboto-Medium;
            font-size: 14px;
            color: #000000;
            letter-spacing: 0;
            line-height: 20px;
            img {
                width: 20px;
                height: auto;
                margin-left: 18px;
            }
        }
    }
`

const ColumnLine = styled.div`
    width: 1px;
    height: 20px;
    background: #060606;
    margin: 0 11px;
`

const WrapperName = styled.div`
    display: flex;
    justify-content: space-between;
    height: 32px;
    margin-bottom: 5px;
`

const ImgDefault = styled.img`
    width: 83px;
    height: 32px;
    position: relative;
    right: -17px;
`
