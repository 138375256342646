import styled from '@emotion/styled'

export const AuthorizedHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`

export const AuthorizedHeaderTitle = styled.div`
    display: flex;
    align-items: center;
    font-family: OpenSans-Bold;
    font-size: 18px;
    color: #999999;
    letter-spacing: 0;
    font-weight: 700;
    margin: 0;
    .nftTypeNoActive {
        color: #999999;
    }
    .authActive {
        color: #000000;
    }
    img {
        width: 20px;
        height: 20px;
    }
    @media (max-width: 768px) {
        margin: 0;
    }
`

export const Total = styled.div`
    font-family: OpenSans-Bold;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    font-weight: 700;
    span {
        font-family: OpenSans-Regular;
        font-size: 16px;
        color: #acacac;
        letter-spacing: 0;
        font-weight: 400;
        margin-left: 10px;
        @media (max-width: 768px) {
            font-size: 14px;
        }
    }
    @media (max-width: 768px) {
        font-size: 16px;
    }
`

export const UploadNoContent = styled.div`
    height: 200px;
    padding-top: 70px;
    display: flex;
    justify-content: center;
    img {
        width: 232px;
        height: 140px;
    }
`
