import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { STEPS } from 'core/order/models'
import { useSignInRedirect } from 'shared/hooks'

import { actions, selectors } from 'core/delivery/state'
import { actions as actionsOrder, selectors as selectorsOrder } from 'core/order/state'
import { useDeliveryList } from 'core/delivery/api'
import { getIsTourist } from 'shared/config'

export const useOrderSteps = () => {
    const dispatch = useDispatch()
    const { getToken } = useSignInRedirect()
    const {
        loading,
        list,
        methods: { getList }
    } = useDeliveryList()

    useEffect(() => {
        if (!list.length && getToken()) {
            getList()
        }
    }, [list.length, getList, getToken])

    const selectedDelivery = useSelector(selectors.getCurrent)
    const selectedBilling = useSelector(selectors.getBilling)
    const sameShipping = useSelector(selectorsOrder.getSameShipping)

    const setSelectedDelivery = useCallback(
        (id: string) => {
            dispatch(actions.setCurrent({ id }))
        },
        [dispatch]
    )

    const toggleSameShipping = useCallback(() => {
        if (sameShipping) {
            dispatch(actions.setBillingId({ billingId: '' }))
            dispatch(actionsOrder.setSameShipping({ sameShipping: false }))
        } else {
            dispatch(actionsOrder.setSameShipping({ sameShipping: true }))
        }
    }, [dispatch, sameShipping])

    const isSelfPickUp = useSelector(selectorsOrder.getIsSelfPickUp)

    const setIsSelfPickUp = useCallback(
        (isSelfPickUp: boolean) => {
            dispatch(actionsOrder.setIsSelfPickUp({ isSelfPickUp }))
        },
        [dispatch]
    )

    const step = useSelector(selectorsOrder.getStep)

    const historyStep = useSelector(selectorsOrder.getHistoryStep)
    const setStep = useCallback(
        (step: STEPS) => {
            dispatch(actionsOrder.setStep({ step }))
            if (step > historyStep) {
                dispatch(actionsOrder.setHistoryStep({ historyStep: step }))
            }
        },
        [dispatch, historyStep]
    )

    const clearCreateOrderInfo = useCallback(() => {
        if (getIsTourist()) {
            return
        }
        dispatch(actions.clearDeliveryIdAndBillingId())
        dispatch(actionsOrder.clearCreateOrderInfo())
    }, [dispatch])

    return {
        loading,
        selectedDelivery,
        selectedBilling,
        sameShipping,
        step,
        historyStep,
        isSelfPickUp,
        methods: {
            setSelectedDelivery,
            setIsSelfPickUp,
            setStep,
            toggleSameShipping,
            clearCreateOrderInfo
        }
    }
}
