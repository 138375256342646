import React, { FC, useEffect, useCallback, useState, useMemo } from 'react'
import { useParams } from 'react-router'
import styled from '@emotion/styled'

import { useSignInRedirect } from 'shared/hooks'
import { TAB_TYPE_ENUM } from 'core/aff/models'
import { useAuth } from 'core/auth/api'

import { PageWrapper, Spin } from 'components'
import { PersonalCenter } from './components/PersonalCenter'
import { Aff, Email, Password, Phone, Address, OrderList, WalletAddress } from './features'

interface UserInfo {
    userid: string
    email: string
    phone: string
    userName: string
    status: number
    link: string
    shareClickCount: string
    shareRegisterCount: string
    totalAmount: number
    withdrawAmount: number
    ingAmount: number
    commissionRate: string
}

export const User: FC = () => {
    const { type } = useParams<{ type: TAB_TYPE_ENUM }>()
    const { checkLogin } = useSignInRedirect()

    useEffect(() => {
        checkLogin('/mine/' + type).catch()
    }, [checkLogin, type])

    const { getToken } = useSignInRedirect()
    const [userInfo, setUserInfo] = useState<UserInfo>({} as UserInfo)
    const {
        loading,
        methods: { getUserInfo, touristBind }
    } = useAuth()
    useEffect(() => {
        getToken() &&
            getUserInfo()
                .then(data => {
                    setUserInfo(data as unknown as UserInfo)
                })
                .catch()
    }, [getUserInfo, getToken])

    const handleBind = useCallback(
        submitData => {
            return touristBind(submitData).then(() => {
                getUserInfo().then(data => {
                    setUserInfo(data as unknown as UserInfo)
                })
            })
        },
        [getUserInfo, touristBind]
    )

    const AsideContent = useMemo(() => {
        switch (type) {
            case TAB_TYPE_ENUM.ORDERS:
                return <OrderList />
            case TAB_TYPE_ENUM.WALLETADDRESS:
                return <WalletAddress />
            case TAB_TYPE_ENUM.ADDRESS:
                return <Address />
            case TAB_TYPE_ENUM.EMAIL:
                return <Email userEmail={userInfo.email} bindEmail={handleBind} />
            case TAB_TYPE_ENUM.PHONE:
                return <Phone userPhone={userInfo.phone} bindPhone={handleBind} />
            case TAB_TYPE_ENUM.PASSWORD:
                return <Password />
            case TAB_TYPE_ENUM.AFFILIATE_LINK:
                return <Aff {...userInfo} />
            default:
                return <Aff {...userInfo} />
        }
    }, [handleBind, type, userInfo])

    return (
        <Spin spinning={loading}>
            <Wrapper>
                <PersonalCenter userName={userInfo.userName} status={userInfo.status}>
                    {AsideContent}
                </PersonalCenter>
            </Wrapper>
        </Spin>
    )
}

const Wrapper = styled(PageWrapper)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 73px;
    width: 100%;
    max-width: 1296px;
    @media (max-width: 768px) {
        width: 100%;
        padding: 0 20px;
    }
`
