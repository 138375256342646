import React, { FC, useState, useEffect, useCallback, useMemo, Dispatch, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'
import { equals } from 'ramda'
import styled from '@emotion/styled'
import { logEvent } from 'firebase/analytics'

import { useAuth } from 'core/auth/api'
import { SIGN_STATUS_ENUM } from 'core/auth/models'
import { useAddCartItem, useMiniCartDrawer } from 'core/cart/api'
import { useI18n } from 'core/language/api'
import { Product } from 'core/product/models'
import { NO_RETURNS_ID_LIST, getIsTourist } from 'shared/config'
import { useApiError, useMemoizedFn, useShowMessage, useSignInRedirect } from 'shared/hooks'
import { analytics, copy } from 'shared/utils'

import { Spin } from 'components'
import { AddCartButton } from './AddCartButton'

import share from '../assets/share.png'
import reduce from '../assets/reduce.png'
import add from '../assets/add.png'
import addProhibit from '../assets/addProhibit.png'

interface ProductInfoProps {
    id: string
    detail: Product
    selectedAttrs: Record<string, string>
    setSelectedAttrs: Dispatch<SetStateAction<Record<string, string>>>
}

export const ProductInfo: FC<ProductInfoProps> = ({ id, detail, selectedAttrs, setSelectedAttrs }) => {
    const {
        methods: { t }
    } = useI18n()
    const {
        methods: { handleError }
    } = useApiError('ProductInfo')
    const {
        methods: { showSingleButtonModal, success, error }
    } = useShowMessage()
    const navigate = useNavigate()

    useEffect(() => {
        detail?.isMultiSku &&
            setSelectedAttrs(() => {
                const attrs: Record<string, string> = {}
                detail?.attrList?.forEach(({ id, values }) => {
                    attrs[id] = values[0].id
                })
                return attrs
            })
    }, [detail, setSelectedAttrs])
    const selectedSku = useMemo(() => {
        if (!detail || !selectedAttrs) return null
        return detail?.skuList?.find(({ specObj }) => equals(specObj, selectedAttrs))
    }, [detail, selectedAttrs])

    const setSelectedAttrVal = useCallback(
        (attrId: string, valueId: string) => {
            setSelectedAttrs(prevState => ({
                ...prevState,
                [attrId]: valueId
            }))
        },
        [setSelectedAttrs]
    )

    const {
        loading: cartLoading,
        methods: { addItem }
    } = useAddCartItem()
    const {
        methods: { updateMiniCartDrawerVisible }
    } = useMiniCartDrawer()
    const [quantity, setQuantity] = useState<number>(1)
    const addToCartLogEvent = useMemoizedFn(() => {
        let variant = ''
        detail?.attrList?.forEach(({ id: attrId, values, name }) => {
            variant += `${name}:`
            values.forEach(({ id, name: valuesName }) => {
                if (selectedAttrs[attrId] === id) {
                    variant += `${valuesName};`
                }
            })
        })
        logEvent(analytics, 'add_to_cart' as never, {
            value: Number(detail?.showPrice) * quantity,
            items: [
                {
                    id: detail?.id,
                    name: detail?.name,
                    price: detail?.showPrice,
                    quantity: quantity,
                    variant: variant
                }
            ]
        })
    })
    const handleAddCart = useMemoizedFn(() => {
        if (!id) return
        if (selectedSku?.preOrder && quantity > selectedSku.onceLimit) {
            error(t('error.quantity_once_limit'))
            return
        }
        ;(() => {
            if (detail?.isMultiSku) {
                return addItem({
                    type: detail.type,
                    productId: id,
                    quantity,
                    skuId: selectedSku?.id
                })
            }
            return addItem({
                type: detail.type,
                productId: id,
                quantity,
                skuId: ''
            })
        })()
            .then(() => {
                addToCartLogEvent()
                updateMiniCartDrawerVisible(true)
            })
            .catch(handleError)
    })

    const isOutStock = useMemo(() => {
        if (detail && detail?.isMultiSku) {
            if (selectedSku?.preOrder) {
                return false
            }
            return selectedSku ? quantity >= selectedSku?.stock : false
        } else {
            if (detail?.preOrder) {
                return false
            }
            return quantity >= (detail?.stock as number)
        }
    }, [detail, quantity, selectedSku])

    const isDeliveryFree = useMemo(() => {
        return detail?.isMultiSku ? selectedSku?.isDeliveryFree : detail?.isDeliveryFree
    }, [detail, selectedSku])

    const { getToken, checkLogin } = useSignInRedirect()
    const {
        methods: { getUserInfo }
    } = useAuth()
    const getQueryString = (name: string, url: string) => {
        let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
        let r = url.match(reg)
        if (r != null) {
            return decodeURIComponent(r[2])
        }
        return null
    }
    const shareLogEvent = useMemoizedFn(() => {
        logEvent(analytics, 'share' as never, {
            content_type: 'product',
            item_id: detail?.id
        })
    })
    const handleShare = useCallback(() => {
        if (!getToken()) {
            checkLogin('/products/' + id).catch()
            return
        }
        if (getIsTourist()) {
            showSingleButtonModal({
                message:
                    'Click "Generate" button and obtain your unique affiliate link. Start earning commissions today.',
                type: 'fail',
                buttonText: 'Generate',
                onButtonClick: () => navigate('/auth/' + SIGN_STATUS_ENUM.TOURIST_BINGING)
            })
            return
        }
        getUserInfo()
            .then(data => {
                const href = window.location.href
                const shareCode = getQueryString('aff', data.link.split('?')[1])
                if (href.includes('aff')) {
                    copy(`${href.split('?')[0]}?aff=${shareCode}`)
                } else {
                    copy(`${href}?aff=${shareCode}`)
                }
                shareLogEvent()

                success(
                    `If the link is copied and another user purchases through the link in the same session, you will receive a ${data.commissionRate}% commission!`,
                    { title: 'Copy link successfully', permanent: true }
                )
            })
            .catch(handleError)
    }, [
        checkLogin,
        getToken,
        getUserInfo,
        handleError,
        id,
        navigate,
        shareLogEvent,
        showSingleButtonModal,
        success
    ])

    const stockNum = useMemo(() => {
        let stock = 0
        if (detail && detail?.isMultiSku) {
            stock = selectedSku?.stock as number
        } else {
            stock = detail?.stock as number
        }
        return stock
    }, [detail, selectedSku])

    const stockStatusText = useMemo(() => {
        if (stockNum >= 10) {
            return 'In Stock'
        }

        if (stockNum < 10 && stockNum > 0) {
            return 'Only a few left in stock - order soon'
        }
        return 'Temporarily Sold Out'
    }, [stockNum])

    const addCartButton = useMemo(() => {
        if (detail && detail?.isMultiSku) {
            const preOrder = selectedSku?.preOrder
            const stock = selectedSku?.stock as number
            if (preOrder && (stock <= 0 || quantity > stock)) {
                return <AddCartButton onClick={handleAddCart}>Pre-Order</AddCartButton>
            } else {
                return (
                    <AddCartButton disabled={stockNum <= 0} onClick={handleAddCart}>
                        Add To Cart
                    </AddCartButton>
                )
            }
        } else {
            const preOrder = detail?.preOrder
            const stock = detail?.stock as number
            if (preOrder && (stock <= 0 || quantity > stock)) {
                return <AddCartButton onClick={handleAddCart}>Pre-Order</AddCartButton>
            } else {
                return (
                    <AddCartButton disabled={stockNum <= 0} onClick={handleAddCart}>
                        Add To Cart
                    </AddCartButton>
                )
            }
        }
    }, [detail, handleAddCart, quantity, selectedSku, stockNum])

    return (
        <ProductsInfo>
            <Spin spinning={cartLoading}>
                <div className="title">
                    <div className="name">{detail?.name}</div>

                    <img src={share} alt="" onClick={handleShare} />
                </div>
                <div className="describe">{detail?.description}</div>
                {/* <div className="tips">
                            *Order now and get the MetaSill NFT frame 100 dollars off and free shipping within
                            30 days.
                        </div> */}
                <div className="price">
                    <div className="value">
                        <span>$</span>
                        {selectedSku?.price || detail?.showPrice}
                    </div>
                    <div className="type">{isDeliveryFree ? 'FREE Shipping' : 'Shipping'}</div>
                </div>
                {NO_RETURNS_ID_LIST.includes(+id) ? (
                    <ReturnsText className="text">Final sale. No returns or exchanges.</ReturnsText>
                ) : null}
                {detail?.attrList?.map(attr => (
                    <div key={attr.id} className="options">
                        <div className="label">{attr.name}</div>
                        <div className="list">
                            {attr.values.map(attrVal => (
                                <div
                                    key={attrVal.id}
                                    className={`item ${
                                        selectedAttrs && selectedAttrs[attr.id] === attrVal.id ? 'active' : ''
                                    }`}
                                    onClick={() => setSelectedAttrVal(attr.id, attrVal.id)}
                                >
                                    {attrVal.name}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
                <div className="quantity">
                    <div className="label">
                        Quantity<span>{stockStatusText}</span>
                    </div>
                    <div className="btn">
                        <div
                            className="reduce"
                            onClick={() => setQuantity(state => (state > 1 ? state - 1 : 1))}
                        >
                            <img src={reduce} alt="" />
                        </div>
                        <div className="value">{quantity}</div>
                        {!isOutStock ? (
                            <div className="add" onClick={() => setQuantity(state => state + 1)}>
                                <img src={add} alt="" />
                            </div>
                        ) : (
                            <div className="add">
                                <img src={addProhibit} alt="" />
                            </div>
                        )}
                    </div>
                </div>
                {addCartButton}
                {/* <AddCartButton disabled={stockNum <= 0} onClick={handleAddCart}>
                    Add To Cart
                </AddCartButton> */}
            </Spin>
        </ProductsInfo>
    )
}

const ProductsInfo = styled.div`
    position: relative;
    box-sizing: border-box;
    width: 50%;
    padding-left: 7.2rem;
    @media (max-width: 576px) {
        width: 100%;
        padding: 20px 24px;
    }
    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 2.4rem;
        font-family: HelveticaNeue-Bold;
        font-size: 28px;
        color: #000000;
        letter-spacing: 0;
        line-height: 30px;
        font-weight: 700;
        .name {
            flex: 1;
        }
        img {
            width: 34px;
            height: 34px;
            cursor: pointer;
        }
        @media (max-width: 576px) {
            margin-bottom: 10px;
            font-size: 22px;
        }
    }
    .describe {
        margin-bottom: 2.4rem;
        font-family: Roboto-Regular;
        font-size: 18px;
        color: #000000;
        letter-spacing: 0;
        line-height: 24px;
        font-weight: 400;
        @media (max-width: 576px) {
            margin-bottom: 24px;
            font-size: 16px;
        }
    }
    .tips {
        margin-bottom: 14.4296875px;
        color: #2f2e2e;
        font-weight: normal;
        font-size: 12px;
        font-style: normal;
        letter-spacing: 0;
        line-height: 1.6;
    }
    .options {
        .label {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 10px;
            text-decoration: none;
            text-transform: capitalize;
            font-family: Roboto-Regular;
            font-size: 16px;
            color: #000000;
            line-height: 24px;
            @media (max-width: 576px) {
                width: 100%;
            }
        }
        .list {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
            width: 85%;
            @media (max-width: 576px) {
                width: 100%;
            }
        }
        .item {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 36px;
            padding: 0 8px;
            margin-right: 1.4rem;
            margin-bottom: 1.4rem;
            border: 1px solid #c5c5c5;
            border-radius: 10px;
            font-family: PingFangSC-Regular;
            cursor: pointer;
            font-size: 14px;
            @media (max-width: 576px) {
                width: auto;
                height: 36px;
                padding: 0 10px;
            }
        }
        .active {
            border: 1px solid #000000;
            box-shadow: 0px 0px 0px 0.5px #000000;
            box-sizing: border-box;
        }
    }

    .price {
        display: flex;
        align-self: center;
        justify-self: start;
        margin-bottom: 2.5rem;
        @media (max-width: 576px) {
            margin-bottom: 1.4rem;
        }
        .value {
            display: flex;
            align-self: center;
            justify-self: start;
            margin-right: 20px;

            font-family: HelveticaNeue-Bold;
            font-size: 30px;
            color: #2f2e2e;
            letter-spacing: 0;
            text-align: center;
            line-height: 32px;
            font-weight: 700;
            span {
                font-family: HelveticaNeue-Bold;
                font-size: 20px;
                color: #2f2e2e;
                letter-spacing: 0;
                text-align: center;
                line-height: 28px;
                font-weight: 700;
            }
        }
        .type {
            display: flex;
            align-self: center;
            justify-self: start;
            font-family: Roboto-Regular;
            font-size: 16px;
            color: #000000;
            letter-spacing: 0;
            line-height: 24px;
            font-weight: 400;
        }
    }
    .quantity {
        margin-bottom: 4rem;
        padding-top: 1.4rem;
        .label {
            font-family: PingFangSC-Regular;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 10px;
            font-size: 14px;
            text-decoration: none;
            text-align: left;
            span {
                margin-left: 11px;
                font-size: 14px;
                color: rgba(102, 102, 102, 0.85);
                letter-spacing: 0;
                text-align: right;
                font-weight: 400;
            }
        }
        .btn {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 43.8%;
            height: 38px;
            .value {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 5.3rem;
                font-family: PingFangSC-Semibold;
                font-size: 18px;
                color: #303030;
                letter-spacing: 1px;
                line-height: 20px;
                font-weight: 600;
            }
            .add,
            .reduce {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                padding-bottom: 4px;
                border-radius: 10px;
                cursor: pointer;
            }
            .prohibit {
                color: #909090;
                background: #e0e0e0;
            }
        }
    }
    .addCartDisable {
        border: 2px solid #ccc !important;
        background-color: #fff !important;
    }
    .addCartDisable .text-white {
        color: #ccc !important;
        line-height: 46px !important;
    }
`
const ReturnsText = styled.div`
    margin-bottom: 2rem;
    font-family: Roboto-Regular;
    font-size: 16px;
    color: #000000;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 400;

    @media (max-width: 576px) {
        font-size: 13px;
    }
`
