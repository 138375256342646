import { useCallback, useState } from 'react'

import { useI18n } from 'core/language/api'
import { useListState, useMountedPromiseFn } from 'shared/hooks'
import { Order, OrderStatusEnum } from 'core/order/models'
import { services } from 'core/order/data-access'

export const useOrderList = () => {
    const { lang } = useI18n()

    const [empty, setEmpty] = useState<boolean>(false)
    const [state, setState] = useListState<Order>()

    const getListApi = useMountedPromiseFn(services.getList)
    const getList = useCallback(
        (status?: OrderStatusEnum | '') => {
            setState({ loading: true })
            return getListApi(lang, status)
                .then(list => {
                    setState({ list, loading: false })
                    setEmpty(!list.length)
                    return list
                })
                .catch(err => {
                    setState({ loading: false })
                    return Promise.reject(err)
                })
        },
        [getListApi, lang, setState]
    )

    const cancelApi = useMountedPromiseFn(services.cancel)
    const cancel = useCallback(
        (id: string, status: OrderStatusEnum | '') => {
            setState({ loading: true })
            return cancelApi(id)
                .then(() => getList(status))
                .catch(err => {
                    setState({ loading: false })
                    return Promise.reject(err)
                })
        },
        [cancelApi, getList, setState]
    )

    // const payApi = useMountedPromiseFn(services.pay);
    // const pay = useCallback(
    //     (id: string, status: OrderStatusEnum | '') => {
    //         setState({ loading: true });
    //         return payApi(id)
    //             .then(() => getList(status))
    //             .catch(err => {
    //                 setState({ loading: false });
    //                 return Promise.reject(err);
    //             });
    //     },
    //     [getList, payApi, setState]
    // );

    const confirmApi = useMountedPromiseFn(services.confirm)
    const confirm = useCallback(
        (id: string, status: OrderStatusEnum | '') => {
            setState({ loading: true })
            return confirmApi(id)
                .then(() => getList(status))
                .catch(err => {
                    setState({ loading: false })
                    return Promise.reject(err)
                })
        },
        [confirmApi, getList, setState]
    )

    const removeApi = useMountedPromiseFn(services.remove)
    const remove = useCallback(
        (id: string, status: OrderStatusEnum | '') => {
            setState({ loading: true })
            return removeApi(id)
                .then(() => getList(status))
                .catch(err => {
                    setState({ loading: false })
                    return Promise.reject(err)
                })
        },
        [getList, removeApi, setState]
    )

    return {
        ...state,
        empty,
        methods: {
            getList,
            cancel,
            // pay,
            confirm,
            remove
        }
    }
}
