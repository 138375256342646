import React, { FC } from 'react'

interface DurationsProps {
    data?: string[]
}

export const Durations: FC<DurationsProps> = ({ data }) => {
    return (
        <>
            {data?.map(item => (
                <div key={item}>{item}</div>
            ))}
        </>
    )
}
