import { useCallback } from 'react'

import { useMountedPromiseFn, useListState } from 'shared/hooks'
import { useI18n } from 'core/language/api'
import { services } from 'core/aff/data-access'
import { ListParams, CommissionItem } from 'core/aff/models'

export const useAff = () => {
    const { lang } = useI18n()
    const [state, setState] = useListState<CommissionItem>()

    const getCommissionApi = useMountedPromiseFn(services.getCommission)
    const getCommission = useCallback(
        (params: ListParams) => {
            setState({ loading: true })
            return getCommissionApi(lang, params)
                .then(({ list, total }) => {
                    setState({ list, total, loading: false })
                    return
                })
                .catch(err => {
                    setState({ loading: false })
                    return Promise.reject(err)
                })
        },
        [getCommissionApi, lang, setState]
    )

    const readShareApi = useMountedPromiseFn(services.readShare)
    const readShare = useCallback(
        (code: string) => {
            setState({ loading: true })
            return readShareApi(code)
                .then(() => {
                    setState({ loading: false })
                    return Promise.resolve()
                })
                .catch(err => {
                    setState({ loading: false })
                    return Promise.reject(err)
                })
        },
        [readShareApi, setState]
    )

    return {
        ...state,
        methods: {
            readShare,
            getCommission
        }
    }
}
