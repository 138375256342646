import { useState, useCallback } from 'react'

interface State<T> {
    loading: boolean
    detail?: T
    error?: string
}

export const useDetailState = <T>(initialState: Partial<State<T>> = {}) => {
    const [state, set] = useState<State<T>>({
        loading: false,
        ...initialState
    })

    const setState = useCallback(
        (p: Partial<State<T>>) => {
            set(s => ({ ...s, ...p }))
        },
        [set]
    )

    return [state, setState, set] as const
}
