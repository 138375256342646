import dayjs from 'dayjs'

import { CommissionItem } from 'core/aff/models'

export interface ReferDTO {
    link: string
    shareClickCount: string
    shareRegisterCount: string
    totalAmount: number
    withdrawAmount: number
    ingAmount: number
}

export interface CommissionDTO {
    id: string
    accountId: string
    createTime: string
    isGive: boolean
    origin: string
    originId: string
    value: number
}

export interface ProductItem {
    orderCode: string
    productList: {
        name: string
        quantity: number
    }[]
    totalPrice: number
}

export const convertToOrderItem = (dto: CommissionDTO[], references: ProductItem[]): CommissionItem[] => {
    return dto.map(item => {
        const product = references.find(({ orderCode }) => orderCode === item.originId)
        return {
            ...item,
            productName: product?.productList.map(
                ({ name, quantity }) => `${name} * ${quantity}`
            ) as string[],
            totalPrice: product?.totalPrice as number,
            createTime: dayjs(item.createTime).format('MM/DD/YYYY HH:mm')
        }
    })
}
