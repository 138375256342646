import React, { FC, useCallback } from 'react'
import styles from './index.module.css'

import { useI18n } from 'core/language/api'
import { OrderStatusEnum } from 'core/order/models'

import { Modal, Toast } from 'antd-mobile'
import { OrderActionButton } from '../OrderActionButton'

interface OrderActionsProps {
    status: OrderStatusEnum
    onCancel: (code?: string) => Promise<unknown>
    onPay: () => void
    onConfirm: (code?: string) => void
    onRemove: (code?: string) => Promise<unknown>
}

export const OrderActions: FC<OrderActionsProps> = ({ status, onCancel, onConfirm, onPay, onRemove }) => {
    const {
        methods: { t }
    } = useI18n()

    const handleCancel = useCallback(() => {
        Modal.confirm({
            title: t('client.order.cancel.title'),
            content: t('client.order.cancel.confirm_msg'),
            cancelText: t('form.actions.cancel'),
            confirmText: t('client.order.actions.confirm'),
            onConfirm: () => {
                onCancel().then(() => Toast.show(t('client.order.cancel.success')))
            }
        })
    }, [onCancel, t])

    const handleRemove = useCallback(() => {
        Modal.confirm({
            title: t('client.order.delete.title'),
            content: t('client.order.delete.confirm_msg'),
            cancelText: t('form.actions.cancel'),
            confirmText: t('client.order.actions.confirm'),
            onConfirm: () => {
                onRemove().then(() => Toast.show(t('client.order.delete.success')))
            }
        })
    }, [onRemove, t])

    return (
        <div className="flex justify-end" style={{ marginRight: '12px' }}>
            {status === OrderStatusEnum.UNPAY && (
                <>
                    <OrderActionButton
                        className={styles.cancelBtn}
                        name={t('client.order.cancel.title')}
                        onClick={handleCancel}
                    />
                    <OrderActionButton
                        className={styles.actionBtn}
                        name={t('client.order.pay.text')}
                        onClick={onPay}
                    />
                </>
            )}
            {status === OrderStatusEnum.DELIVERED && (
                <OrderActionButton
                    className={styles.actionBtn}
                    name={t('client.order.confirm_receipt')}
                    onClick={onConfirm}
                />
            )}
            {(status === OrderStatusEnum.CANCEL || status === OrderStatusEnum.AUTO_CANCEL) && (
                <OrderActionButton
                    className={styles.actionBtn}
                    name={t('client.order.delete.title')}
                    onClick={handleRemove}
                />
            )}
        </div>
    )
}
