import { FEATURE_NAME } from './constants'
import { GlobalState } from './reducer'
import { createSelector } from 'reselect'

const select = (state: GlobalState) => state[FEATURE_NAME]

export const getSameShipping = createSelector(select, state => state.sameShipping)

export const getStep = createSelector(select, state => state.step)

export const getHistoryStep = createSelector(select, state => state.historyStep)

export const getIsSelfPickUp = createSelector(select, state => state.isSelfPickUp)
