import { ReactNode, useEffect } from 'react'

import { Toast } from 'antd-mobile'

export const useLoading = (loading: boolean, content?: ReactNode) => {
    useEffect(() => {
        loading
            ? Toast.show({
                  icon: 'loading',
                  content,
                  maskStyle: { zIndex: 1500 }
              })
            : Toast.clear()
        return () => Toast.clear()
    }, [content, loading])
}
