import React, { FC, useMemo } from 'react'
import styled from '@emotion/styled'

import Add from '../assets/add.png'
import Reduce from '../assets/reduce.png'

interface Props {
    quantity: number
    stock: number
    preOrder: boolean
    onceLimit: number
    onReduceQuantity: () => void
    onQuantity: () => void
}

export const QuantityBtn: FC<Props> = ({
    quantity,
    stock,
    preOrder,
    onceLimit,
    onReduceQuantity,
    onQuantity
}) => {
    const outStock = useMemo(() => {
        if (!preOrder) {
            return quantity >= stock
        } else {
            return false
        }
    }, [preOrder, quantity, stock])
    return (
        <Wrapper className={`${outStock ? 'outStockError' : ''}`}>
            <div className="btn" onClick={() => quantity > 1 && onReduceQuantity()}>
                <img src={Reduce} alt="" />
            </div>
            {quantity}
            <div className={outStock ? 'prohibit btn' : 'btn'} onClick={() => !outStock && onQuantity()}>
                <img src={Add} alt="" />
            </div>
        </Wrapper>
    )
}
const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 96px;
    height: 26px;
    padding: 0 3px;
    border: 1px solid #000;
    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 26px;
        height: 26px;
        cursor: pointer;
    }
    .prohibit {
        opacity: 0.1;
        cursor: auto;
    }
    .outStockError {
        border: 1px solid #ff4d4f;
    }
`
