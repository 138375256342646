// package imports
import React, { FC } from 'react'
import styled from '@emotion/styled'

import { useResponsive } from 'shared/hooks'

import web3VendingBackground from 'assets/web3-vending/web3-vending-background.png'
import metasill from 'assets/web3-vending/MetaSill.png'
import web3VendingLogo from 'assets/web3-vending/web3-vending-logo.png'
import web3VendingMachine from 'assets/web3-vending/web3-vending-machine.png'
import ethLogo from 'assets/web3-vending/eth-logo.png'
import polygonMaticLogo from 'assets/web3-vending/polygon-matic-logo.png'
import opLogo from 'assets/web3-vending/op-logo.png'
import arbitrumLogo from 'assets/web3-vending/arbitrum-logo.png'
import BSCLogo from 'assets/web3-vending/BSC-logo.png'
import OCKLogo from 'assets/web3-vending/OCK-logo.png'
import logo from 'assets/web3-vending/logo.png'
import googlePay from 'assets/web3-vending/google-pay.png'
import pay from 'assets/web3-vending/pay.png'

export const Web3Vending: FC = () => {
    const { md } = useResponsive()

    return (
        <StyledApp>
            <Wrapper>
                {md && <BackgroundImg src={web3VendingBackground} alt="web3VendingBackground" />}
                {md && <MetasillImg src={metasill} alt="metasill" />}
                <Content>
                    <ContentTop>
                        <img className="logo" src={web3VendingLogo} alt="web3VendingLogo" />
                        <ContentText>Next Generation Automated Product Sales</ContentText>
                        <div className="contentList">
                            <div className="ContentItem">
                                <div className="backgroundColor1"></div>
                                <div className="ItemBoxLeft">
                                    <Item>
                                        <span className="dot"></span>
                                        <span className="text">Payment in Crypto</span>
                                    </Item>
                                    <Item>
                                        <span className="dot"></span>
                                        <span className="text">NFT Tokengating</span>
                                    </Item>
                                    <Item>
                                        <span className="dot"></span>
                                        <span className="text">Age Verification</span>
                                    </Item>
                                </div>
                            </div>
                            <div
                                className="ContentItem"
                                style={{ marginLeft: md ? '2rem' : '0px', marginTop: md ? '0px' : '2rem' }}
                            >
                                <div className="backgroundColor2"></div>
                                <div className="ItemBoxRight">
                                    <Item>
                                        <span className="dot"></span>
                                        <span className="text">Blockchain Powered</span>
                                    </Item>
                                    <Item>
                                        <span className="dot"></span>
                                        <span className="text">Automated Ad Space Sales</span>
                                    </Item>
                                    <Item>
                                        <span className="dot"></span>
                                        <span className="text">Earn Passive Income</span>
                                    </Item>
                                    <Item>
                                        <span className="dot"></span>
                                        <span className="text">NFT Community Support</span>
                                    </Item>
                                </div>
                            </div>
                        </div>
                    </ContentTop>
                    <ContentMachine>
                        <img src={web3VendingMachine} alt="web3VendingMachine" />
                    </ContentMachine>
                    <ContentBottom>
                        <div style={{ width: md ? '93.5rem' : 'auto' }}>
                            <div className="textOne">
                                <div className="text">NFT Based Discount / Gift</div>
                                <div className="line"></div>
                            </div>
                            <div className="textTwo">
                                <div className="text">Payment and Rebate in Crypto</div>
                                <div className="line"></div>
                            </div>
                        </div>
                        <LogoList>
                            <div className="item">
                                <img
                                    src={ethLogo}
                                    alt=""
                                    style={{
                                        width: md ? '6.2rem' : '3.1rem',
                                        height: md ? '10rem' : '5rem',
                                        margin: `0 auto ${md ? '3.6rem' : '1.4rem'} auto`
                                    }}
                                />
                                <div className="text">Ethereum</div>
                            </div>
                            <div className="item">
                                <img
                                    src={polygonMaticLogo}
                                    alt="polygonMaticLogo"
                                    style={{
                                        width: md ? '10rem' : '5rem',
                                        height: md ? '8.8rem' : '4.4rem',
                                        marginBottom: md ? '3.8rem' : '2.1rem'
                                    }}
                                />
                                <div className="text">Polygon</div>
                            </div>
                            <div className="item">
                                <img
                                    src={opLogo}
                                    alt="opLogo"
                                    style={{
                                        width: md ? '10rem' : '5rem ',
                                        height: md ? '10rem' : '5rem ',
                                        marginBottom: md ? '3.6rem' : '1.6rem'
                                    }}
                                />
                                <div className="text">Optimism</div>
                            </div>
                            <div className="item">
                                <img
                                    src={arbitrumLogo}
                                    alt="arbitrumLogo"
                                    style={{
                                        width: md ? '10rem' : '5rem ',
                                        height: md ? '11.2rem' : '5.6rem ',
                                        marginBottom: md ? '3rem' : '1rem'
                                    }}
                                />
                                <div className="text">Arbitrum</div>
                            </div>
                            <div className="item">
                                <img
                                    src={BSCLogo}
                                    alt="BSC"
                                    style={{
                                        width: md ? '10rem' : '5rem',
                                        height: md ? '10rem' : '5rem',
                                        marginBottom: md ? '3.3rem' : '1.4rem'
                                    }}
                                />
                                <div className="text">BSC</div>
                            </div>
                            <div className="item" style={{ marginRight: '0px' }}>
                                <img
                                    src={OCKLogo}
                                    alt="OCK"
                                    style={{
                                        width: md ? '10rem' : '5rem ',
                                        height: md ? '10rem' : '5rem ',
                                        marginBottom: md ? '3.6rem' : '1.4rem'
                                    }}
                                />
                                <div className="text">OKC</div>
                            </div>
                        </LogoList>
                        <div className="bottomText">Supporting Delegate.cash</div>
                        <div className="bottom">
                            <img
                                src={logo}
                                alt="logo"
                                style={md ? { width: '9.2rem', height: '9.8rem' } : { width: 60 }}
                            />
                            <div className="pay">
                                <img
                                    src={pay}
                                    alt="pay"
                                    style={md ? { width: '12.1rem', height: '7.7rem' } : { width: 70 }}
                                />
                                <img
                                    src={googlePay}
                                    alt="googlePay"
                                    style={
                                        md
                                            ? { width: '31.1rem', height: '6.9rem', marginLeft: '2.7rem' }
                                            : { width: 190, marginLeft: '2.7rem' }
                                    }
                                />
                            </div>
                        </div>
                    </ContentBottom>
                </Content>
            </Wrapper>
        </StyledApp>
    )
}

const MetasillImg = styled.img`
    margin-top: 7rem;
    height: 50%;
    position: absolute;
    right: 0;
`

const BackgroundImg = styled.img`
    height: 100%;
    position: absolute;
`

const Content = styled.div`
    margin: 0 auto;
    max-width: 93.5rem;
    padding: 7rem 0px 10.5rem;
    @media (max-width: 768px) {
        padding: 14px;
        padding-bottom: 60px;
        height: auto;
    }
`

const Wrapper = styled.div`
    position: relative;
`

const Item = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 2.2rem;
    .dot {
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        background-color: #000;
    }
    .text {
        font-family: HelveticaNeue-Bold;
        font-size: 2.6rem;
        color: #000000;
        letter-spacing: 0;
        margin-left: 1.9rem;
    }
    @media (max-width: 768px) {
        .text {
            font-size: 19.5px;
        }
    }
`

const ContentTop = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo {
        width: 48rem;
        height: 13rem;
    }
    .ItemBoxLeft {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 30.5rem;
        padding-left: 5rem;
        padding-top: 3rem;
        background-image: linear-gradient(180deg, rgba(255, 222, 232, 0.79) 1%, rgba(255, 227, 235, 0) 100%);
    }
    .ItemBoxRight {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 30.5rem;
        padding-left: 1.7rem;
        padding-top: 3rem;
        background-image: linear-gradient(180deg, rgba(229, 212, 255, 0.3) 1%, rgba(216, 193, 253, 0) 96%);
    }
    .backgroundColor1 {
        width: 100%;
        height: 1.7rem;
        background-image: linear-gradient(92deg, #fd6fa0 2%, #5ef3ff 100%);
    }
    .backgroundColor2 {
        width: 100%;
        height: 1.7rem;
        background-image: linear-gradient(94deg, #9b31f1 6%, #1f33b9 100%);
    }
    .ContentItem {
        width: 40rem;
        height: 32.2rem;
    }
    .contentList {
        display: flex;
        margin-bottom: 4.8rem;
    }
    @media (max-width: 768px) {
        .logo {
            width: 60%;
            height: auto;
        }
        .ContentItem {
            width: 300px;
            height: 208px;
        }
        .contentList {
            flex-direction: column;
            align-items: center;
            margin-bottom: 20px;
        }
        .ItemBoxRight {
            height: 180px;
        }
        .ItemBoxLeft {
            height: 180px;
        }
    }
`
const ContentBottom = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    .textTwo {
        width: 53.2rem;
        height: 5rem;
        background: #432379;
        margin-bottom: 4rem;
        .text {
            font-family: HelveticaNeue-Bold;
            font-size: 3.4rem;
            color: #ffffff;
            letter-spacing: 0;
            line-height: 5rem;
            text-align: center;
        }
    }
    .textOne {
        width: 44.8rem;
        height: 5rem;
        margin-bottom: 2.4rem;
        background: #432379;
        .text {
            font-family: HelveticaNeue-Bold;
            font-size: 3.4rem;
            color: #ffffff;
            letter-spacing: 0;
            line-height: 5rem;
            text-align: center;
        }
    }
    .line {
        width: 100%;
        height: 0.8rem;
        background: #e85386;
    }
    .bottomText {
        width: 93.5rem;
        font-family: HelveticaNeue-Bold;
        font-size: 3.4rem;
        letter-spacing: 0;
        background-image: linear-gradient(to right, #432379, #e85386);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 4rem;
    }
    .pay {
        display: flex;
        align-items: center;
    }
    .bottom {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    @media (max-width: 768px) {
        width: 100%;
        margin-left: 0px;
        .pay {
            margin-left: 0;
            margin-top: 40px;
        }
        .textTwo {
            width: 290px;
            height: 30px;
            margin: 0 auto 30px;
            .text {
                font-size: 18px;
                height: 30px;
                line-height: 30px;
            }
        }
        .textOne {
            width: 250px;
            height: 30px;
            margin: 0 auto 24px;
            .text {
                font-size: 18px;
                height: 30px;
                line-height: 30px;
            }
        }
        .bottomText {
            width: 100%;
            text-align: center;
            font-size: 18px;
            margin-bottom: 20px;
        }
        .bottom {
            flex-direction: column;
            align-items: center;
        }
    }
`
const LogoList = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 7.2rem;
    .item {
        margin-right: 6.7rem;
    }
    .text {
        width: 10rem;
        font-family: Roboto-Medium;
        font-size: 2.2rem;
        color: #000000;
        letter-spacing: 0;
        font-weight: 500;
        text-align: center;
    }
    @media (max-width: 768px) {
        margin-bottom: 40px;
        .item {
            margin-right: 10px;
        }
        .text {
            width: 50px;
            font-size: 10px;
        }
    }
`
const ContentMachine = styled.div`
    width: 100%;
    padding-right: 16rem;
    display: flex;
    padding-left: 44rem;
    justify-content: center;
    margin-bottom: 11.2rem;
    img {
        max-width: 79rem;
        height: 51.5rem;
    }
    @media (max-width: 768px) {
        justify-content: center;
        align-items: center;
        padding: 0;
        margin-bottom: 40px;
        img {
            width: 100%;
            height: auto;
        }
    }
`
const ContentText = styled.div`
    width: 82rem;
    height: 6rem;
    font-family: HelveticaNeue-Bold;
    font-size: 3.4rem;
    color: #ffffff;
    letter-spacing: 0;
    line-height: 6rem;
    background: #432379;
    text-align: center;
    margin: 2.8rem 0px 4.2rem;
    @media (max-width: 768px) {
        width: 300px;
        margin: 16px 0px 36px;
        height: 32px;
        line-height: 32px;
        font-size: 13px;
    }
`

const StyledApp = styled.div`
    min-height: 100vh;
`
