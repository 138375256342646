import React, { FC, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useLocalStorage, useSetState } from '@mantine/hooks'

import styled from '@emotion/styled'

import { AuthAddressItem, NftTypeEnum } from 'core/management/nft/models'
import { useOwnedNew } from 'core/management/nft/api/useOwnedNew'
import { useApiError, useLoading, useRememberManagementSelected, useResponsive } from 'shared/hooks'

import { SimpleGrid, Radio } from '@mantine/core'

import { NftListHeader } from './components/NftSelectorHeader'
import { NftListItem, NftListItemEnum } from './components/NftSelectorItem'

import { useAuthorized } from 'core/management/nft/api/useAuthorized'

import { InfiniteScroll } from '../nft/components/InfiniteScroll'
import { SelectAdressPopup } from './components/SelectAdressPopup'

import pullDown from 'assets/pull_down.png'
import { SelectAddressModal } from './components/SelectAddressModal'
import { useAuthAddressList } from 'core/management/nft/api/useAddressList'
import { ConnectWallet } from '../nft/components/ConnectWallet'
import { CreateWalletModal } from 'modules/user/components/CreateWalletModal'
import { removeAddressToken } from 'shared/config'
import { useWalletAddress } from 'core/walletaddress/api'
import { useNftList } from 'core/management/nft/api/useNftList'
import { useAddressToken } from 'core/management/nft/api/useAddressToken'

const ownedPageKey = 'owned_wallet_address'
const uploadPageKey = 'upload_wallet_address'
const authorizedPageKey = 'authorized_wallet_address'

export const SelectNft: FC = () => {
    const {
        methods: { handleError }
    } = useApiError('Collections')
    const { md } = useResponsive()
    const { id } = useParams<{ id: string }>()

    const [type, setType] = useState(NftTypeEnum.OWNED)
    const [walletAddressInput, setWalletAddressInput] = useState('')
    const [bindModalOpen, setBindModalOpen] = useState<boolean>(false)
    const [walletAddress, setWalletAddress] = useState<AuthAddressItem>()
    const [isClick, setIsClick] = useState(false)
    const {
        methods: { bind }
    } = useWalletAddress()

    const {
        loading,
        list: authAddressList,
        methods: { getAddressList }
    } = useAuthAddressList()

    const [selectOwnedAddressId, setselectOwnedAddressId] = useLocalStorage({ key: ownedPageKey })
    const [, setselectUploadAddressId] = useLocalStorage({ key: uploadPageKey })
    const [, setselectAuthorizedAddressId] = useLocalStorage({ key: authorizedPageKey })

    useLayoutEffect(() => {
        getAddressList().catch(handleError)
    }, [getAddressList, handleError, bindModalOpen])

    const [rememberLastSelected, setRememberLastSelected] = useSetState<Record<NftTypeEnum, string>>({
        [NftTypeEnum.OWNED]: '',
        [NftTypeEnum.AUTHORIZED]: '',
        [NftTypeEnum.UPLOAD]: ''
    })

    const mapAuthAddressList = useMemo(() => {
        if (type === NftTypeEnum.OWNED) {
            const data = authAddressList.filter(item => item.addressType !== 0 && item.addressType !== 3)
            return data
        }
        return authAddressList
    }, [authAddressList, type])

    const selectedOwnedId = useRememberManagementSelected(
        ownedPageKey,
        walletAddress?.id,
        walletAddress?.addressType === 0 || walletAddress?.addressType === 3 ? false : isClick
    )
    const selectedUploadId = useRememberManagementSelected(uploadPageKey, walletAddress?.id, isClick)
    const selectedAuthorizedId = useRememberManagementSelected(authorizedPageKey, walletAddress?.id, isClick)

    useEffect(() => {
        if (type === NftTypeEnum.OWNED) {
            const newList = authAddressList.filter(({ addressType }) => addressType !== 0)
            const lastSelectOwned = newList.find(({ id }) => selectedOwnedId === id)

            !!authAddressList.length && setWalletAddress(lastSelectOwned || newList[0])
        } else if (type === NftTypeEnum.AUTHORIZED) {
            !!authAddressList.length &&
                setWalletAddress(() => {
                    const lastAddress = authAddressList.find(({ id }) => selectedAuthorizedId === `${id}`)
                    if (lastAddress && lastAddress.addressType !== 0) return lastAddress

                    return { ...authAddressList[0], name: 'My Account' }
                })
        } else if (type === NftTypeEnum.UPLOAD) {
            !!authAddressList.length &&
                setWalletAddress(() => {
                    const lastAddress = authAddressList.find(({ id }) => selectedUploadId === `${id}`)
                    if (lastAddress && lastAddress.addressType !== 0) return lastAddress

                    return { ...authAddressList[0], name: 'My Account' }
                })
        }
    }, [
        authAddressList,
        mapAuthAddressList,
        rememberLastSelected,
        selectOwnedAddressId,
        selectedAuthorizedId,
        selectedOwnedId,
        selectedUploadId,
        type
    ])

    const {
        loading: uploadLoading,
        list: uploadList,
        uploadHasMore,
        methods: { uploadFetch, uploadLoadMore }
    } = useNftList()

    const {
        loading: ownedLoading,
        hasMoreLoading: ownedHasMoreLoading,
        ownedList,
        total,
        hasMore,
        methods: { firstFetch, loadMore }
    } = useOwnedNew(walletAddress?.address!, type)

    const {
        methods: { getAddressToken }
    } = useAddressToken()

    useEffect(() => {
        if (!walletAddress?.id) return
        if (type === NftTypeEnum.UPLOAD && walletAddress?.addressType === 0) {
            uploadFetch().catch(handleError)
        } else if (walletAddress?.addressType !== 0) {
            getAddressToken({
                id: `${walletAddress.id}`,
                addressType: `${walletAddress.addressType}`,
                ownerId: `${walletAddress.ownerId}`
            })
                .then(() => {
                    firstFetch()
                        .then(() => {
                            removeAddressToken()
                        })
                        .catch(handleError)
                })
                .catch(handleError)
        }
    }, [firstFetch, getAddressToken, handleError, type, uploadFetch, walletAddress])

    const {
        hasMore: authorizedHasMore,
        authorizedList,
        authorizedTotal,
        methods: { authorizedFetch, authorizedLoadMore }
    } = useAuthorized(type)

    const mapList = useMemo(() => {
        if (type === NftTypeEnum.UPLOAD && walletAddress?.name === 'My Account') {
            const data = uploadList.filter(({ status }) => status === 1)

            return data.map(item => ({ ...item, id: item.nftId }))
        }
        return ownedLoading || ownedHasMoreLoading || (type === NftTypeEnum.OWNED && !walletAddress?.name)
            ? []
            : (type === NftTypeEnum.AUTHORIZED || type === NftTypeEnum.UPLOAD) &&
              walletAddress?.addressType === 0
            ? authorizedList || []
            : ownedList || []
    }, [authorizedList, ownedHasMoreLoading, ownedList, ownedLoading, type, uploadList, walletAddress])

    useEffect(() => {
        if (!walletAddress?.id) return
        if (type === NftTypeEnum.AUTHORIZED && walletAddress?.name === 'My Account') {
            getAddressToken({
                id: `${walletAddress?.id}`,
                addressType: `${walletAddress?.addressType}`,
                ownerId: `${walletAddress?.ownerId}`
            })
                .then(() => {
                    authorizedFetch()
                        .then(() => {
                            removeAddressToken()
                        })
                        .catch(handleError)
                })
                .catch(handleError)
        }
    }, [authorizedFetch, firstFetch, getAddressList, getAddressToken, handleError, type, walletAddress])

    const [visible, setVisible] = useState(false)

    const location = useLocation()
    const locationState = location.state as { id: string }
    const [value, setValue] = useState('')

    const navigate = useNavigate()
    const onChange = (value: string) => {
        setValue(value)
    }

    const onDone = () => {
        const selected = (
            type === NftTypeEnum.AUTHORIZED || type === NftTypeEnum.UPLOAD
                ? walletAddress?.name === 'My Account'
                    ? [...mapList, ...authorizedList]
                    : [...ownedList, ...authorizedList]
                : ownedList
        ).find(({ id }) => `${id}` === value)!

        if (selected) {
            navigate(`/management/frames/${id}`, {
                replace: true,
                state: { id: selected.id, type: selected.type, name: selected.name }
            })
        }
    }

    useLoading(ownedLoading || ownedHasMoreLoading || loading || uploadLoading)

    return (
        <>
            <Container>
                <NftListHeader
                    goBack={() => navigate(-1)}
                    type={type}
                    setType={setType}
                    title="Choose NFT"
                    onDone={onDone}
                    setLastSelected={() => {
                        setRememberLastSelected({
                            [type]: walletAddress?.id
                        })
                    }}
                />
                {!!authAddressList.filter(({ addressType }) => addressType !== 0).length ||
                type !== NftTypeEnum.OWNED ? (
                    <>
                        {md && (
                            <WebSelectAddress>
                                <SelectAdress>
                                    <span className="box" onClick={() => setVisible(true)}>
                                        <span className="name">{walletAddress?.name}</span>
                                        <img width={16} height={16} src={pullDown} alt="NFT" />
                                    </span>
                                </SelectAdress>
                            </WebSelectAddress>
                        )}

                        {!md && (
                            <SelectAdress>
                                <span className="box" onClick={() => setVisible(true)}>
                                    <span className="name">{walletAddress?.name}</span>
                                    <img width={16} height={16} src={pullDown} alt="NFT" />
                                </span>
                            </SelectAdress>
                        )}

                        <TotalLength>
                            <div className="name">Total</div>
                            <span>
                                {walletAddress?.name === 'My Account'
                                    ? type === NftTypeEnum.UPLOAD
                                        ? mapList.length
                                        : authorizedTotal
                                    : total}
                            </span>
                        </TotalLength>

                        <div className="list">
                            <Radio.Group
                                style={{ width: '100%' }}
                                onChange={onChange}
                                defaultValue={locationState?.id}
                            >
                                {mapList.length > 0 && (
                                    <>
                                        <SimpleGrid className="cursor-pointer" cols={md ? 5 : 2}>
                                            {mapList.map(item => (
                                                <NftListItem
                                                    key={item.id}
                                                    type={NftListItemEnum.NFT}
                                                    item={item}
                                                />
                                            ))}
                                        </SimpleGrid>
                                        <InfiniteScroll
                                            loadMore={
                                                walletAddress?.name !== 'My Account'
                                                    ? type === NftTypeEnum.UPLOAD
                                                        ? uploadLoadMore
                                                        : loadMore
                                                    : authorizedLoadMore
                                            }
                                            hasMore={
                                                walletAddress?.name !== 'My Account'
                                                    ? type === NftTypeEnum.UPLOAD
                                                        ? uploadHasMore
                                                        : hasMore
                                                    : authorizedHasMore
                                            }
                                        />
                                    </>
                                )}
                            </Radio.Group>
                        </div>
                    </>
                ) : (
                    <div className="flex justify-center mt-12">
                        <CreateWalletContent>
                            <ConnectWallet
                                onConnectSuccess={(value: string) => {
                                    setWalletAddressInput(value)
                                    setBindModalOpen(true)
                                }}
                            />
                        </CreateWalletContent>
                        <CreateWalletModal
                            walletAddress={walletAddressInput}
                            open={bindModalOpen}
                            setOpen={setBindModalOpen}
                            onBind={bind}
                        />
                    </div>
                )}
            </Container>
            {walletAddress && md && (
                <SelectAddressModal
                    setWalletAddress={val => {
                        setWalletAddress(val)
                        if (val.addressType !== 0 && val.addressType !== 3) {
                            setselectOwnedAddressId(`${val?.id}`)
                        }
                        setselectUploadAddressId(`${val?.id}`)
                        setselectAuthorizedAddressId(`${val?.id}`)
                    }}
                    walletAddress={walletAddress}
                    list={mapAuthAddressList}
                    type={type}
                    visible={visible}
                    setVisible={setVisible}
                    setIsClick={setIsClick}
                />
            )}
            {walletAddress && !md && (
                <SelectAdressPopup
                    setWalletAddress={val => {
                        setWalletAddress(val)
                        if (val.addressType !== 0 && val.addressType !== 3) {
                            setselectOwnedAddressId(`${val?.id}`)
                        }
                        setselectUploadAddressId(`${val?.id}`)
                        setselectAuthorizedAddressId(`${val?.id}`)
                    }}
                    walletAddress={walletAddress}
                    list={mapAuthAddressList}
                    type={type}
                    visible={visible}
                    setVisible={setVisible}
                    setIsClick={setIsClick}
                />
            )}
        </>
    )
}

const CreateWalletContent = styled.div`
    padding-top: 10px;
    width: 362px;
    @media (max-width: 768px) {
        width: 325px;
        padding-top: 20px;
    }
`

const WebSelectAddress = styled.div`
    position: absolute;
    right: 72px;
    top: 112px;
`

const SelectAdress = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    .box {
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f9f9f9;
        border: 1px solid rgba(211, 211, 211, 1);
        border-radius: 8px;
        padding: 6px 12px;
        @media (max-width: 768px) {
            height: 36px;
        }
    }

    .name {
        font-family: OpenSans-Bold;
        font-size: 18px;
        color: #000000;
        font-weight: 700;
        margin-right: 6px;
    }
    @media (max-width: 768px) {
        margin-top: 24px;
    }
`
const Container = styled.div`
    width: 100%;
    padding: 0 72px;
    position: relative;
    @media (max-width: 768px) {
        padding: 0 20px;
        .list {
            margin-top: 20px;
        }
    }
`
const TotalLength = styled.div`
    display: flex;
    font-family: OpenSans-Regular;
    font-size: 16px;
    align-items: center;
    color: #acacac;
    margin-right: 6px;
    margin-top: 30px;
    .name {
        font-family: OpenSans-Bold;
        font-size: 18px;
        color: #000000;
        font-weight: 700;
        margin-right: 10px;
    }
    @media (max-width: 768px) {
        margin-top: 24px;
    }
`
