import { on, reducer as createReducer } from 'ts-action'

import { updateCart, updateCartNum, updateMiniCartDrawerVisible } from '../actions'
import { FEATURE_NAME } from '../constants'

import { getStoreList } from 'shared/config'
import { CartCheckItem } from 'core/cart/models'

export interface State {
    storeList: CartCheckItem[]
    cartNumber: number
    miniCartDrawerVisible: boolean
}

export interface GlobalState {
    [FEATURE_NAME]: State
}

export const initialState: State = {
    storeList: getStoreList() || [],
    cartNumber: 0,
    miniCartDrawerVisible: false
}

export const reducer = createReducer(
    initialState,
    on(updateCart, (state, { payload: { storeList } }) => {
        return {
            ...state,
            storeList
        }
    }),
    on(updateMiniCartDrawerVisible, (state, { payload: { miniCartDrawerVisible } }) => {
        return {
            ...state,
            miniCartDrawerVisible
        }
    }),
    on(updateCartNum, (state, { payload: { cartNumber } }) => {
        return {
            ...state,
            cartNumber
        }
    })
)
