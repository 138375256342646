import { useCallback } from 'react'

import { useMountedPromiseFn, useDetailState } from 'shared/hooks'
import { services } from 'core/event/data-access'
import { EventDetail } from '../../../models'

export const useEventDetail = (lang: string) => {
    const [state, setState] = useDetailState<EventDetail>()

    const getDetailApi = useMountedPromiseFn(services.getDetail)
    const getDetail = useCallback(
        (id: string) => {
            setState({ loading: true })
            return getDetailApi(lang, id)
                .then(detail => {
                    setState({ detail, loading: false })
                    return detail
                })
                .catch(err => {
                    setState({ loading: false })
                    return Promise.reject(err)
                })
        },
        [lang, getDetailApi, setState]
    )

    return {
        ...state,
        methods: {
            getDetail
        }
    }
}
