import React, { FC, useLayoutEffect } from 'react'
import { scroller } from 'react-scroll'

import { Home } from 'modules/home/home'

export const Contact: FC = () => {
    useLayoutEffect(() => {
        setTimeout(
            () =>
                scroller.scrollTo('myScrollToElement', {
                    duration: 1000,
                    delay: 100,
                    smooth: true,
                    isDynamic: true
                }),
            360
        )
    })

    const handleContact = () =>
        setTimeout(
            () =>
                scroller.scrollTo('myScrollToElement', {
                    duration: 1000,
                    delay: 100,
                    smooth: true,
                    isDynamic: true
                }),
            360
        )

    return (
        <>
            <Home onContact={handleContact} />
        </>
    )
}
