import React, { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import { PaymentSuccess } from './paymentSuccess'
import { SweepRentalPage } from './sweepRental'
import { PayToShowCreateOrder } from './creatOrder'

export const SelfRentalShell: FC = () => (
    <Routes>
        <Route path="/" element={<SweepRentalPage />} />
        <Route path="/sweep_rental" element={<SweepRentalPage />} />
        <Route path="/payment_success" element={<PaymentSuccess />} />
        <Route path="/:id/create_order/:skuId/:quantity" element={<PayToShowCreateOrder />} />
    </Routes>
)
