import React, { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { EventCreate } from './create'
import { EventDetails } from './details'
import { EventIndex } from './index'
import { EventEdit } from './edit'
import { CreateOrder } from './create-order'
import { EventProducts } from './products'

export const EventShell: FC = () => (
    <Routes>
        <Route path="/" element={<EventIndex />} />
        <Route path="/:id" element={<EventDetails />} />
        <Route path="/create" element={<EventCreate />} />
        <Route path="/edit/:id" element={<EventEdit />} />
        <Route path="/:id/products/:productId/create_order" element={<CreateOrder />} />
        <Route path="/:id/products" element={<EventProducts />} />
    </Routes>
)
