import React, { FC } from 'react'
import styled from '@emotion/styled'

import { InfiniteScroll as AntdMobileInfiniteScroll } from 'antd-mobile'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

interface Props {
    hasMore: boolean
    loadMore: () => Promise<void>
}

export const InfiniteScroll: FC<Props> = ({ hasMore, loadMore }) => {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
    return (
        <AntdMobileInfiniteScroll loadMore={loadMore} hasMore={hasMore}>
            <>
                {hasMore && (
                    <LoadingTip>
                        <Spin indicator={antIcon}></Spin>
                        <span style={{ marginLeft: 10 }}>Loading...</span>
                    </LoadingTip>
                )}
            </>
        </AntdMobileInfiniteScroll>
    )
}

const LoadingTip = styled.span`
    font-family: Roboto-Medium;
    font-size: 18px;
    margin-bottom: 80px;
`
