import { on, reducer as createReducer } from 'ts-action'
import { setSameShipping, setStep, setIsSelfPickUp, clearCreateOrderInfo, setHistoryStep } from './actions'
import { STEPS } from 'core/order/models'

import { actions as authActions } from 'core/auth/state'

import { FEATURE_NAME } from './constants'

export interface State {
    sameShipping: boolean
    step: STEPS
    historyStep: STEPS
    isSelfPickUp: boolean
}

export interface GlobalState {
    [FEATURE_NAME]: State
}

export const initialState: State = {
    sameShipping: true,
    step: STEPS.SHIPPING_DETAILS,
    historyStep: STEPS.SHIPPING_DETAILS,
    isSelfPickUp: true
}

export const reducer = createReducer(
    initialState,
    on(setSameShipping, (state, { payload: { sameShipping } }) => {
        return {
            ...state,
            sameShipping
        }
    }),
    on(setStep, (state, { payload: { step } }) => {
        return {
            ...state,
            step
        }
    }),
    on(setHistoryStep, (state, { payload: { historyStep } }) => {
        return {
            ...state,
            historyStep
        }
    }),
    on(setIsSelfPickUp, (state, { payload: { isSelfPickUp } }) => {
        return {
            ...state,
            isSelfPickUp
        }
    }),
    on(clearCreateOrderInfo, () => initialState),
    on(authActions.clearUser, () => initialState)
)
