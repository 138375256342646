import { Store } from 'core/store/models'

export enum CartItemErrorEnum {
    PRODUCT = 'product', // Product Failure
    SKU = 'sku', // Need to reselect sku
    STOCK = 'stock', // Insufficient inventory
    PR = 'PR' // Exceeding limit inventory
}

export interface CartItemError {
    scope: CartItemErrorEnum
}

export interface CartItemRender {
    cartList: CartItemGroupByStore[]
    ineffectiveList: CartItem[]
}

export interface CartItemGroupByStore {
    store: Store
    items: CartItem[]
}

export interface CartItem {
    code: string
    error: CartItemError | null
    storeId: string
    image: string
    name: string
    price: string
    depositPrice: string
    productId: string
    quantity: number
    purchaseRestrictions: boolean
    purchaseRestrictionsCount: number
    remainingQ: number
    stock: number
    skuId: string
    spec: SpecItem[]
    virtual: boolean
    type: number
    preOrder: boolean
    onceLimit: number

    // event
    timeZone?: string
    duration?: string
    durations?: string[]
}

export interface SpecItem {
    attrName: string
    valueName: string
}

export interface CartListRendered {
    store: Store & { checked: boolean }
    items: Array<CartItem & { checked: boolean; disabled: boolean }>
}

export interface CartCheckItem {
    type: number
    productId: string
    skuId?: string
    quantity: number
    timeZone?: string
    duration?: string
}

export type CartApiReturnType = Promise<{
    cartList: CartItemGroupByStore[]
    ineffectiveList: CartItem[]
} | void>
