import React, { FC } from 'react'

import { Image } from '@mantine/core'

import defaultImg from 'assets/default.png'

interface Props {
    src: string
    size: string | number
    height?: string | number
}

export const Media: FC<Props> = ({ src, size, height }) => {
    return (
        <Image
            width={size}
            height={height || size}
            src={src}
            fit="contain"
            withPlaceholder
            placeholder={<img src={defaultImg} alt="palceholder" />}
        />
    )
}
