import React, { FC, useMemo } from 'react'
import styled from '@emotion/styled'
import { isMobile } from 'react-device-detect'

import { useAuth } from 'core/auth/api/lib/useAuth'
import { WalletEnum } from 'shared/config'
import { useMemoizedFn } from 'shared/hooks'
import { Image } from 'antd'
// import { Radio } from '@mantine/core'

import imageMetaMask from 'assets/metamask.png'
import imagePhantom from 'assets/phantom.png'

interface Props {
    onConnectSuccess: (value: string) => void
}
export const ConnectWallet: FC<Props> = ({ onConnectSuccess }) => {
    const {
        hasEthereum,
        hasSolana,
        methods: { connect }
    } = useAuth()

    const needInstallMetaMask = useMemo(() => !hasEthereum && !isMobile, [hasEthereum])
    const needInstallPhantom = useMemo(() => !hasSolana && !isMobile, [hasSolana])

    const handleConnectMetaMask = useMemoizedFn(async () => {
        if (needInstallMetaMask) {
            window.open('https://metamask.io/')
            return
        }
        const walletAddress = await connect(WalletEnum.METAMASK)
        walletAddress && onConnectSuccess(walletAddress)
    })

    const handleConnectPhantom = useMemoizedFn(async () => {
        if (needInstallPhantom) {
            window.open('https://phantom.app/')
            return
        }
        const walletAddress = await connect(WalletEnum.PHANTOM)
        walletAddress && onConnectSuccess(walletAddress)
    })
    return (
        <WalletWrapperDiv>
            <Title>Connect Your Wallet</Title>
            <WalletWrapper onClick={handleConnectMetaMask} style={{ marginBottom: 30 }}>
                <Name>{needInstallMetaMask ? 'Install MetaMask' : 'MetaMask'}</Name>
                <Image src={imageMetaMask} width={44} preview={false} />
            </WalletWrapper>
            <WalletWrapper onClick={handleConnectPhantom}>
                <Name>{needInstallPhantom ? 'Install Phantom' : 'Phantom'}</Name>
                <Image src={imagePhantom} width={44} preview={false} />
            </WalletWrapper>
        </WalletWrapperDiv>
    )
}

const WalletWrapperDiv = styled.div`
    width: 100%;
    padding: 20px 5px 62px;
    @media (max-width: 768px) {
        padding: 20px 0 16px;
    }
`

const Name = styled.div`
    font-family: OpenSans-SemiBold;
    font-size: 18px;
    font-weight: bold;
`

const WalletWrapper = styled.div`
    /* margin-bottom: 30px; */
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border: 1px solid rgba(229, 229, 229, 1);
    border-radius: 10px;
    color: #000000;
    cursor: pointer;

    /* :hover {
        border: 1px solid rgba(0, 0, 0, 1);
    } */
`

const Title = styled.div`
    font-family: Roboto-Medium;
    font-size: 30px;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    line-height: 34px;
    font-weight: 500;
    margin-bottom: 30px;
    @media (max-width: 768px) {
        font-size: 24px;
        font-size: 20px;
        margin-bottom: 28px;
    }
`
