import React, { forwardRef } from 'react'
import styled from '@emotion/styled'

import {
    Button,
    ButtonLink,
    Container2Col,
    ListingSlider,
    Paragraph,
    Response2Col,
    SectionContainer,
    SectionTitle,
    Video,
    PageContainer,
    NFTFrame
} from 'components'
import { ContactUs } from './components/ContactUs'
import { Footer } from './components/Footer'

import videoDisplay from './assets/display.mp4'
import imgRental from './assets/rental.jpg'
import imgJoin from './assets/join.jpg'
import { VideoBlock } from './components/VideoBlock'

interface HomeProps {
    onContact?: () => void
}

export const Home = forwardRef<HTMLDivElement, HomeProps>(({ onContact }, _ref) => {
    return (
        <>
            <Container>
                <div className="md:pt-16">
                    <Response2Col
                        leftNode={
                            <div className="md:my-24">
                                <Video src={videoDisplay} />
                            </div>
                        }
                        rightNode={
                            <div className="flex flex-col justify-center px-12 my-20 md:px-0 md:my-0 md:mx-16 md:mr-32 lg:mr-48">
                                <div className="my-8">
                                    <SectionTitle title="Display your NFT to the Physical World" />
                                </div>
                                <div className="my-8">
                                    <Paragraph style={{ fontWeight: 'bold', color: 'rgb(93, 93, 93)' }}>
                                        The MetaSill NFT Frame is dedicated to displaying your NFT. We
                                        custom-designed a frame to show NFT artworks in the best possible way.
                                        Our high-quality display offers the best visual experiences. Simply
                                        sign in with your wallet to bring your precious NFT artwork into the
                                        real world!
                                    </Paragraph>
                                </div>
                                <div className="py-6">
                                    <ButtonLink to="/products">View Products</ButtonLink>
                                </div>
                            </div>
                        }
                    />
                    <div className="px-12 md:px-0">
                        <div className="border-b border-black border-solid md:pt-36" />
                    </div>
                </div>
                <SectionContainer>
                    <NFTFrame />
                </SectionContainer>
                <SectionContainer>
                    <ListingSlider isLoading={false} />
                </SectionContainer>
                <SectionContainer style={{ marginBottom: '5rem' }}>
                    <VideoBlock />
                </SectionContainer>
            </Container>
            <Container2Col
                backgroundColor="#eeece6"
                imgSrc={imgRental}
                title="MetaSill Rental"
                textNodes={[
                    <Paragraph>
                        Turn the world into the largest NFT gallery. Go outside the box and share your NFTs
                        with a larger crowd. Reach real people through the MetaSill Display Network. Display
                        your collection in MetaSill partnering public spots such as cafés, hotels,
                        restaurants, etc.
                    </Paragraph>,
                    <Paragraph>
                        Want to display NFT at an event? MetaSill offers leasing services for MetaSill NFT
                        Frame. Contact us for a quote.
                    </Paragraph>,
                    <ButtonLink to="/rental">More Information</ButtonLink>
                ]}
            />
            <Container2Col
                backgroundColor="#efefef"
                reverse
                imgSrc={imgJoin}
                title="Become a Partner"
                textNodes={[
                    <Paragraph>
                        We collaborate with galleries and individual NFT collectors to provide high-end,
                        stylish MetaSill Frames that both embody the aesthetic experience and the
                        sophisticated technology for NFT display and authentication.
                    </Paragraph>,
                    <Paragraph>
                        MetaSill is looking for business partners in the industry. If you are interested,
                        please feel free to contact us.
                    </Paragraph>,
                    <Button className="mb-20" onClick={onContact}>
                        Contact Us
                    </Button>
                ]}
            />
            <SectionContainer style={{ maxWidth: '1550px', margin: '0 auto' }}>
                <ContactUs />
            </SectionContainer>
            <Footer />
        </>
    )
})

const Container = styled(PageContainer)`
    @media (max-width: 576px) {
        padding: 0;
        width: 100%;
    }
`
