import React, { FC, useMemo } from 'react'
import styles from './index.module.css'

import { useI18n } from 'core/language/api'
import { OrderStatusEnum } from 'core/order/models'

interface OrderStatusProps {
    status: OrderStatusEnum
}

export const OrderStatus: FC<OrderStatusProps> = ({ status }) => {
    const {
        methods: { t }
    } = useI18n()

    const orderStatus: any = useMemo(
        () => ({
            [OrderStatusEnum.UNPAY]: {
                desc: t(`client.order.statusText.${OrderStatusEnum.UNPAY}`),
                className: 'nopayImg'
            },
            [OrderStatusEnum.PAID]: {
                desc: t(`client.order.statusText.${OrderStatusEnum.PAID}`),
                className: 'nosendImg'
            },
            [OrderStatusEnum.DELIVERED]: {
                desc: t(`client.order.statusText.${OrderStatusEnum.DELIVERED}`),
                className: 'noreceiveImg'
            },
            [OrderStatusEnum.FINISH]: {
                desc: t(`client.order.statusText.${OrderStatusEnum.FINISH}`),
                className: 'finishImg'
            },
            [OrderStatusEnum.CANCEL]: {
                desc: t(`client.order.statusText.${OrderStatusEnum.CANCEL}`),
                className: 'cancelImg'
            },
            [OrderStatusEnum.AUTO_CANCEL]: {
                desc: t(`client.order.statusText.${OrderStatusEnum.AUTO_CANCEL}`),
                className: 'cancelImg'
            }
        }),
        [t]
    )

    return (
        <div className={styles.orderStateImgDesc}>
            <div className={`${styles[orderStatus[status].className]}`} />
            <div className={styles.orderStateDesc}>
                <div className={styles.orderStateTitle}>{t(`order.statusTitle.${status}`)}</div>
                <div className={styles.orderStateContent}>{orderStatus[status].desc}</div>
            </div>
        </div>
    )
}
