import { useCallback, useState } from 'react'

import { isFunction } from 'shared/utils'

export type SetState<S extends Record<string, any>> = <K extends keyof S>(
    state: Pick<S, K> | null | ((prevState: Readonly<S>) => Pick<S, K> | S | null)
) => void

export const useSetState = <S extends Record<string, any>>(initialState: S | (() => S)) => {
    const [state, setState] = useState<S>(initialState)

    const setMergeState = useCallback((patch: any) => {
        setState(prevState => {
            const newState = isFunction(patch) ? patch(prevState) : patch
            return newState ? { ...prevState, ...newState } : prevState
        })
    }, [])

    return [state, setMergeState] as const
}
