import { useState } from 'react'
import { useMemoizedFn, useMountedPromiseFn } from 'shared/hooks'
import { PriceParams, PriceType } from 'core/scanRent/models'
import { services } from 'core/scanRent/data-access'

export const useProductPrice = () => {
    const [price, setPrice] = useState<PriceType>()
    const [priceLoading, setPriceLoading] = useState<boolean>(false)

    const getProductPriceApi = useMountedPromiseFn(services.buyProductPrice)
    const getProductPrice = useMemoizedFn((params: PriceParams) => {
        setPriceLoading(true)
        return getProductPriceApi(params)
            .then(price => {
                setPrice(price)
                setPriceLoading(false)
                return price
            })
            .catch(err => {
                setPriceLoading(false)
                return Promise.reject(err)
            })
    })

    return {
        price,
        priceLoading,
        methods: {
            getProductPrice
        }
    }
}
