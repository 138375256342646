import { action, payload } from 'ts-action'
import { getActionType } from '../constants'

import { CartCheckItem } from 'core/cart/models'

export const addCart = action(getActionType('Add Cart'), payload<{ storeList: CartCheckItem[] }>())

export const updateCart = action(getActionType('Update Cart'), payload<{ storeList: CartCheckItem[] }>())

export const removeCart = action(getActionType('Remove Cart'), payload<{ storeList: CartCheckItem[] }>())

export const clearCart = action(getActionType('Clear Cart'), payload<{ storeList: CartCheckItem[] }>())

export const updateCartNum = action(getActionType('Update Cart Num'), payload<{ cartNumber: number }>())

export const updateMiniCartDrawerVisible = action(
    getActionType('Update Mini Cart Drawer Visible'),
    payload<{ miniCartDrawerVisible: boolean }>()
)
