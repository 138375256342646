import React, { FC } from 'react'
import styled from '@emotion/styled'

import { useSelectedDelivery } from 'core/delivery/api'

import { ScrollArea } from '@mantine/core'
import { Button } from 'components'
import { AddressItemPage } from './addressItem'

import type { Delivery } from 'core/delivery/models'
import { useResponsive } from 'shared/hooks'

interface AddListProps {
    add: () => void
    selectedDeliveryId?: string
    setVisible: (show: boolean) => void
    list: Delivery[]
    title: string
    setBillingAdress?: (billing: Delivery) => void
    setIsSavedAddress?: (title: string) => void
    type: string
}
export const AddressList: FC<AddListProps> = ({
    add,
    selectedDeliveryId,
    setVisible,
    list,
    title,
    setBillingAdress,
    setIsSavedAddress,
    type
}) => {
    const { md } = useResponsive()

    const {
        methods: { setSelectedDelivery }
    } = useSelectedDelivery()

    const select = (id: string, item: Delivery) => {
        if (setBillingAdress) setBillingAdress(item)
        if (!setBillingAdress) setSelectedDelivery(id)
        if (setIsSavedAddress) setIsSavedAddress('Change')
        setVisible(false)
    }

    return (
        <Wrapper>
            <div className="title">{title}</div>
            <ScrollArea.Autosize maxHeight={md ? 'calc(100vh - 440px)' : 'calc(100vh - 310px)'}>
                {list.map(item => (
                    <div
                        onClick={() => select(item.id, item)}
                        className={item.id === selectedDeliveryId ? 'active box' : ' box'}
                        key={item.id}
                    >
                        <AddressItemPage type={type} className="addressItem" addressItem={item} />
                    </div>
                ))}
            </ScrollArea.Autosize>
            <div className="btn">
                <Button className="continue" onClick={add}>
                    Add a new address
                </Button>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    padding-bottom: 26px;
    .title {
        margin-bottom: 30px;
        font-family: Roboto-Medium;
        font-size: 30px;
        color: #000000;
        letter-spacing: 0;
        text-align: center;
        line-height: 28px;
    }

    .box {
        background: #ffffff;
        box-shadow: 0px 6px 12px 0px rgba(10, 4, 60, 0.08);
        border-radius: 10px;
        padding: 20px 0 20px 30px;
        margin: 0 106px 30px;
        @media (max-width: 768px) {
            margin: 0;
            margin-top: 20px;
        }
    }
    .active {
        background: #ffffff;
        border: 2px solid rgba(47, 46, 46, 1);
        box-shadow: 0px 6px 12px 0px rgba(56, 55, 64, 0.27);
        border-radius: 10px;
    }
    .addressItem {
        font-family: PingFangSC-Medium;
        font-size: 20px;
        color: #333333;
        line-height: 28px;
    }
    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .continue {
        width: 378px;
        margin-top: 50px;
        font-family: Roboto-Medium;
        font-size: 25.33px;
        color: #ffffff;
        letter-spacing: 0;
        text-align: center;
        line-height: 30.96px;
    }
`
