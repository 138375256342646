import { useCallback } from 'react'
import { remove } from 'ramda'

import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'core/cart/state'

import { CartCheckItem } from 'core/cart/models'

export const useCart = () => {
    const dispatch = useDispatch()

    const list = useSelector(selectors.getList)

    const addCart = useCallback(
        (item: CartCheckItem) => {
            let storeList = []
            if (list.length === 0) {
                list.push(item)
            } else {
                const index = list.findIndex(
                    ({ productId, skuId }) => `${item.productId}${item.skuId}` === `${productId}${skuId}`
                )
                if (index > -1) {
                    list[index].quantity = list[index].quantity += item.quantity
                    list[index].timeZone = item.timeZone
                    list[index].duration = item.duration
                } else {
                    list.push(item)
                }
            }
            storeList = list
            dispatch(
                actions.updateCart({
                    storeList
                })
            )
        },
        [dispatch, list]
    )

    const removeByCode = useCallback(
        (code: string) => {
            const index = list.findIndex(({ productId, skuId }) => `${productId}_${skuId}` === code)
            dispatch(
                actions.updateCart({
                    storeList: remove(index, 1, list)
                })
            )
        },
        [dispatch, list]
    )

    const updateCartByCode = useCallback(
        (code: string, quantity: number, skuId?: string, timeZone?: string, duration?: string) => {
            dispatch(
                actions.updateCart({
                    storeList: list.map(item => {
                        if (`${item.productId}` === `${code}`) {
                            item.quantity = quantity
                        }
                        return item
                    })
                })
            )
        },
        [dispatch, list]
    )

    const batchDelete = useCallback(
        (codes: string[]) => {
            const storeList: CartCheckItem[] = []
            list.forEach(item => {
                if (codes.findIndex(codesItem => codesItem === item.productId) === -1) {
                    storeList.push(item)
                }
            })
            dispatch(
                actions.updateCart({
                    storeList
                })
            )
        },
        [dispatch, list]
    )

    const clearLocalCart = useCallback(() => {
        const storeList: CartCheckItem[] = []
        dispatch(
            actions.updateCart({
                storeList
            })
        )
    }, [dispatch])

    return {
        list,
        addCart,
        remove: removeByCode,
        clear: null,
        updateCartByCode,
        sync: null,
        batchDelete,
        clearLocalCart
    }
}

export default useCart
