import React, { FC, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import moment from 'moment'

import { useApiError, useMount, useShowMessage } from 'shared/hooks'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Spin } from 'components'
import { useProductDetail, useProductPrice } from 'core/scanRent/api'

import { Carousel } from '@mantine/carousel'
import {
    PROJECT_PREFIX,
    setScanProductsInfo,
    removeScanRentPersonalInfo,
    removeCreateOrderPageType,
    getScanProductsInfo,
    removeScanProductsInfo,
    setScannedUrl
} from 'shared/config'
import { pathJoin } from 'shared/utils'

import addIcon from './assets/addNum.png'
import minusIcon from './assets/minusNum.png'
import { useI18n } from 'core/language/api'

export const SweepRentalPage: FC = () => {
    const {
        methods: { t }
    } = useI18n()
    const {
        methods: { showErrorModal }
    } = useShowMessage()

    const [searchParams] = useSearchParams()
    const product = searchParams.get('product')
    const device = searchParams.get('device')

    const navigate = useNavigate()

    const [num, setNum] = useState(1)
    const [activeIndex, setActiveIndex] = useState(0)
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        if (getScanProductsInfo().quantity) {
            setNum(getScanProductsInfo().quantity)
            getScanProductsInfo().activeIndex && setActiveIndex(getScanProductsInfo().activeIndex)
        }
        removeScanProductsInfo()
    }, [])

    const {
        methods: { handleError }
    } = useApiError('ScanRentalPage')

    const {
        loading,
        detail,
        productSku,
        methods: { getProductDetail }
    } = useProductDetail(product!)

    const {
        priceLoading,
        price,
        methods: { getProductPrice }
    } = useProductPrice()

    const items = useMemo(() => {
        return [
            {
                productId: product ? product : '',
                skuId: detail?.skuList ? detail.skuList[activeIndex].id : '',
                quantity: num,
                virtual: false,
                timeZone: '',
                duration: '',
                frameId: device
            }
        ]
    }, [activeIndex, detail, device, num, product])

    const endRentTime = useMemo(() => {
        return (
            price &&
            moment()
                .add(+price?.frameRentDuration, 'hours')
                .format('MM/DD/YYYY HH:mm')
        )
    }, [price])

    useEffect(() => {
        if (detail) {
            getProductPrice({
                deliveryId: '',
                items: items,
                couponCode: ''
            })
                .then(() => setDisabled(false))
                .catch(err => {
                    setDisabled(true)
                    showErrorModal(t(err.data.msg))
                })
        }
    }, [detail, getProductPrice, items, showErrorModal, t])

    useEffect(() => {
        getProductDetail().catch(handleError)
    }, [getProductDetail, handleError])

    const location = useLocation()
    useMount(() => {
        setScannedUrl(location.pathname + location.search)
    })

    return (
        <Spin spinning={loading || priceLoading}>
            {detail && (
                <Container>
                    <ProductHeader>
                        <Carousel
                            slideGap="md"
                            // height="auto"
                            loop
                            withIndicators
                            styles={{
                                control: {
                                    display: 'none'
                                },
                                indicator: {
                                    backgroundColor: '#fff',
                                    opacity: 1,
                                    '&[data-active]': {
                                        backgroundColor: '#000'
                                    }
                                }
                            }}
                        >
                            {detail.images?.map((item: string, index: number) => (
                                <Carousel.Slide key={index}>
                                    <img src={pathJoin(PROJECT_PREFIX + item)} alt="" />
                                </Carousel.Slide>
                            ))}
                        </Carousel>
                    </ProductHeader>
                    <ProductsInfo>
                        <div className="title">{detail.name}</div>
                        <div className="description">
                            <div></div>
                            <div
                                className="text"
                                dangerouslySetInnerHTML={{
                                    __html: `<div calss='divstyle'>${detail.mobileDescription}</div>`
                                }}
                            />
                            {/* <div>{detail.description}</div> */}
                        </div>
                        <PriceInfo>
                            <div className="priceIcon">$&nbsp;</div>
                            <div className="price">
                                {(productSku && productSku[activeIndex].price) || detail.showPrice[0]}
                            </div>
                            <div className="priceDay">
                                &nbsp;/&nbsp;
                                {detail.rentDuration
                                    ? +detail.rentDuration === 1
                                        ? ' Hour'
                                        : +detail.rentDuration === 6
                                        ? '6 Hours'
                                        : 'Day'
                                    : productSku && +productSku[activeIndex].rentDuration === 1
                                    ? ' Hour'
                                    : productSku && +productSku[activeIndex].rentDuration === 6
                                    ? '6 Hours'
                                    : 'Day'}
                            </div>
                        </PriceInfo>
                    </ProductsInfo>
                    <BuyInfo>
                        <div>
                            {/* <div className="infoTitle">Please select the rental length</div> */}
                            {productSku && (
                                <div>
                                    <div className="infoTitle">Per</div>
                                    <div className="infoItem">
                                        {productSku?.map(({ rentDuration }, index) => {
                                            return (
                                                <RentalType
                                                    key={index}
                                                    className={activeIndex === index ? 'activeType' : ''}
                                                    onClick={() =>
                                                        activeIndex !== index && setActiveIndex(index)
                                                    }
                                                >
                                                    {+rentDuration === 1
                                                        ? 'Hour'
                                                        : +rentDuration === 6
                                                        ? '6 Hours'
                                                        : 'Day'}
                                                </RentalType>
                                            )
                                        })}
                                    </div>
                                </div>
                            )}
                            <div>
                                <div className="infoTitle">Duration</div>
                                <div className="infoItem">
                                    <img
                                        width={30}
                                        height={30}
                                        src={minusIcon}
                                        alt=""
                                        onClick={() => {
                                            const nums = num
                                            num > 1 && setNum(nums - 1)
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    />
                                    <div className="num">{num}</div>
                                    <img
                                        width={30}
                                        height={30}
                                        src={addIcon}
                                        alt=""
                                        onClick={() => {
                                            const nums = num
                                            setNum(nums + 1)
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="infoTitle">Pay-To-Show Ends On: </div>
                                <div className="time">{endRentTime}</div>
                            </div>
                        </div>
                        <Total>
                            <div>Total:&nbsp;</div>
                            <div className="priceNum">
                                <span className="icon">$&nbsp;</span>
                                {disabled
                                    ? productSku
                                        ? productSku[activeIndex].price
                                        : detail.showPrice[0]
                                    : price?.total}
                            </div>
                        </Total>
                        <SummitButton
                            // disabled={disabled}
                            onClick={() => {
                                removeScanRentPersonalInfo()
                                removeCreateOrderPageType()
                                setScanProductsInfo(
                                    JSON.stringify({
                                        productId: product,
                                        skuId: productSku && productSku[activeIndex].id,
                                        quantity: num,
                                        frameId: device,
                                        activeIndex: activeIndex
                                    })
                                )
                                navigate(
                                    `/self_rent/${product}/create_order/${
                                        productSku ? productSku[activeIndex].id : 'sku'
                                    }/${num}`
                                )
                            }}
                            style={{ fontSize: ' 25.33px' }}
                        >
                            Submit
                        </SummitButton>
                    </BuyInfo>
                </Container>
            )}
        </Spin>
    )
}

const Container = styled.div`
    width: 100%;
    min-height: calc(100vh - 68px - 90px);
    padding: 0;
    display: flex;
    flex-direction: column;
    @media (max-width: 576px) {
        width: 100%;
    }
`
const ProductHeader = styled.div`
    width: 100%;
    /* height: 310px; */
    img {
        width: 100%;
        /* height: 375px; */
        object-fit: cover;
    }
    @media (max-width: 576px) {
        /* height: 300px; */
        padding-left: 0;
    }
`
const ProductsInfo = styled.div`
    padding: 24px;
    .title {
        font-family: HelveticaNeue-Bold;
        font-size: 22px;
        color: #000000;
        line-height: 30px;
        font-weight: 700;
    }
    .description {
        padding: 10px 0px 32px;
        font-family: Roboto-Regular;
        font-size: 16px;
        color: #000000;
        line-height: 24px;
        font-weight: 400;
    }
`
const PriceInfo = styled.div`
    display: flex;
    align-items: center;
    .priceIcon {
        font-family: HelveticaNeue-Bold;
        font-size: 20px;
        color: #2f2e2e;
        text-align: center;
        font-weight: 700;
        line-height: 32px;
    }
    .price {
        font-family: HelveticaNeue-Bold;
        font-size: 30px;
        color: #2f2e2e;
        text-align: center;
        line-height: 32px;
        font-weight: 700;
    }
    .priceDay {
        font-family: Roboto-Regular;
        font-size: 16px;
        color: #000000;
        font-weight: 400;
        line-height: 32px;
    }
`
const BuyInfo = styled.div`
    padding: 0px 24px 24px;
    font-family: Roboto-Regular;
    font-size: 16px;
    color: #000000;
    text-align: justify;
    font-weight: 400;
    .time {
        font-family: Roboto-Medium;
        font-size: 18px;
        font-weight: 500;
    }
    .num {
        margin: 14px;
        font-family: PingFangSC-Semibold;
        font-size: 18px;
        color: #303030;
        letter-spacing: 1px;
        line-height: 20px;
        font-weight: 600;
    }
    .infoTitle {
        margin-bottom: 10px;
    }
    .infoItem {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
    }
    .activeType {
        border: 1px solid rgba(48, 48, 48, 1);
    }
`
const RentalType = styled.div`
    padding: 0px 8px;
    height: 36px;
    border: 1px solid rgba(197, 197, 197, 1);
    border-radius: 10px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #000000;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    margin-right: 15px;
    cursor: pointer;
`
const Total = styled.div`
    margin-top: 40px;
    display: flex;
    align-items: center;
    font-family: Roboto-Regular;
    font-size: 16px;
    color: #000000;
    text-align: justify;
    font-weight: 400;
    .icon {
        font-size: 20px;
    }
    .priceNum {
        font-family: HelveticaNeue-Bold;
        font-size: 30px;
        color: #2f2e2e;
        text-align: center;
        line-height: 32px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`
const SummitButton = styled(Button)`
    width: 100%;
    height: 50px;
    border-radius: 36px;
    font-family: Roboto-Medium;
    font-size: 25.33px;
    color: #fff;
    text-align: center;
    line-height: 50px;
    font-weight: 500;
    background: #000000;
    margin: 16px 0px 12px;
    cursor: pointer;
`
