import React, { FC } from 'react'
import { useResponsive } from 'shared/hooks'
import styled from '@emotion/styled'

import { MyCollectionList } from 'core/management/nft/models'
import { Media } from 'components'
import { Text, TextProps } from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import { InfiniteScroll } from './InfiniteScroll'

interface UploadAddressProps {
    list: MyCollectionList[]
    hasMore: boolean
    loadMore: () => Promise<void>
}

export const UploadAddress: FC<UploadAddressProps> = ({ list, hasMore, loadMore }) => {
    const { md } = useResponsive()
    const navigate = useNavigate()

    return (
        <>
            <ListWrapper>
                {list.map(({ id, name, imgUrl }) => (
                    <ListWrapperItem key={id}>
                        <div className="list-item">
                            <ListItemImg
                                onClick={() => navigate(`/management/collection/${id}`)}
                                // style={{ border: '1px solid #cccccc' }}
                            >
                                <Media size={md ? 198 : 158} src={imgUrl} />
                            </ListItemImg>

                            <Info>
                                <InfoName>
                                    <Name lineClamp={2}>{name}</Name>
                                </InfoName>
                                {/* <EditButton
                                    show={hoverId === id}
                                    // canEdit={status !== NftStatusEnum.Reviewing}
                                    // disabled={status === NftStatusEnum.Reviewing}
                                    canEdit={true}
                                    disabled={false}
                                    onClick={e => {
                                        e.stopPropagation()
                                        navigate(`/management/nft/${id}/edit`)
                                    }}
                                >
                                    Edit
                                </EditButton> */}
                            </Info>
                        </div>
                    </ListWrapperItem>
                ))}
            </ListWrapper>
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
        </>
    )
}

const ListItemImg = styled.div`
    width: 100%;
    border: 1px solid #cccccc;
`

const InfoName = styled.div`
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
        height: 32px;
    }
`

const Name = styled(Text)<TextProps>`
    font-family: Roboto-Regular;
    font-size: 16px;
    color: #000;
    line-height: 20px;
    padding: 0 6px;
    /* height: 36px; */
    @media (max-width: 768px) {
        font-family: Roboto-Regular;
        font-size: 13px;
        /* height: 29px; */
        line-height: 16px;
    }
`

const Info = styled.div`
    padding: 15px 0;
    text-align: center;
    @media (max-width: 768px) {
        padding: 12px 0;
    }
`
const ListWrapper = styled.div`
    margin: 36px 0 50px 0;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 200px);
    grid-gap: 40px;
    .list-item {
        display: block;
        width: 100%;
        background: #fff;
        box-shadow: 0 7px 16px 0 rgba(222, 222, 222, 0.5);
        cursor: pointer;
    }
    @media (max-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        grid-template-columns: repeat(auto-fill, 160px);
        justify-content: space-between;
        grid-gap: 0px;
        margin: 28px 0 0 0;
    }
`
const ListWrapperItem = styled.div`
    height: 300px;
    @media (max-width: 768px) {
        width: 160px;
        height: 260px;
    }
`
