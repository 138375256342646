import { clientHttp } from 'shared/request'
import { pathJoin } from 'shared/utils'
import { pickValue, SuccessItemResponse } from 'shared/request'

const URI = '/coupon'

export const verification = (code: string, idStr: string[]) => {
    const url = pathJoin(URI, code)
    return clientHttp.get<SuccessItemResponse<unknown>>(url, { idStr: idStr.join(',') }).then(pickValue)
}
