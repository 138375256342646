import { useMountedPromiseFn, useListState } from 'shared/hooks'
import { services } from 'core/management/frame/data-access'

import { useCallback } from 'react'
import { ConnectFrame, FramesList } from '../model'

export const useFrameList = () => {
    const [state, setState] = useListState<FramesList>()

    const getListApi = useMountedPromiseFn(services.getList)
    const getList = useCallback(() => {
        setState({ loading: true })
        return getListApi()
            .then(list => {
                setState({ loading: false, list })
                return list
            })
            .catch(err => {
                setState({ loading: false })
                return Promise.reject(err)
            })
    }, [getListApi, setState])

    const getCreateApi = useMountedPromiseFn(services.create)
    const getCreate = useCallback(
        (params: ConnectFrame) => {
            setState({ loading: true })
            return getCreateApi(params)
                .then(list => {
                    return list
                })
                .catch(err => {
                    setState({ loading: false })
                    return Promise.reject(err)
                })
        },
        [getCreateApi, setState]
    )

    return {
        ...state,
        methods: { getList, getCreate }
    }
}
