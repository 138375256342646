import { useMemoizedFn, useMountedPromiseFn } from 'shared/hooks'
import { services } from 'core/management/nft/data-access'

import { NftInList } from '../models'
import { useState } from 'react'
import { usePageSize } from './usePageSize'

const params = {
    pageNum: '1'
}
export const useNftList = () => {
    const pageSize = usePageSize()
    const [loading, setLoading] = useState(false)
    const [uploadHasMoreLoading, setUploadHasMoreLoading] = useState(false)
    const [list, setList] = useState<NftInList[]>([])
    const [uploadTotal, setUploadTotal] = useState(0)
    const [uploadHasMore, setUploadHasMore] = useState(false)
    const pageTotal = (uploadTotal > pageSize && Math.ceil(uploadTotal / pageSize)) || '1'

    const uploadFetch = useMemoizedFn(() => {
        return getList({ ...params, pageSize }).then(res => {
            setList(res.value)
            setUploadTotal(res.total)
            setUploadHasMore(params.pageNum === '1')
        })
    })

    const uploadLoadMore = useMemoizedFn(async () => {
        if (params.pageNum > pageTotal || params.pageNum === pageTotal) {
            setUploadHasMore(false)
            return
        }
        const pageNum = parseInt(params.pageNum) + 1
        params.pageNum = `${pageNum}`
        const res = await getList({ ...params, pageSize })
        setList(prev => [...prev, ...res.value])
        const fetchStatus = params.pageNum !== '1' && params.pageNum < pageTotal
        setUploadHasMore(fetchStatus)
    })

    const getListApi = useMountedPromiseFn(services.getList)
    const getList = useMemoizedFn(params => {
        if (params.pageNum !== '1' && params.pageNum < pageTotal) {
            setUploadHasMoreLoading(true)
        } else {
            setLoading(true)
        }
        return getListApi(params)
            .then(list => {
                setLoading(false)
                return list
            })
            .catch(err => {
                setLoading(false)
                return Promise.reject(err)
            })
    })

    return {
        loading,
        uploadHasMoreLoading,
        list,
        uploadTotal,
        uploadHasMore,
        methods: { uploadFetch, uploadLoadMore }
    }
}
