import React, { FC } from 'react'
import styles from './index.module.css'

interface ActionButtonProps {
    className?: string
    name: string
    onClick: () => void
}

export const OrderActionButton: FC<ActionButtonProps> = ({ className, name, onClick }) => {
    return (
        <div className={`${styles.btn} ${className} cursor-pointer`} onClick={onClick}>
            {name}
        </div>
    )
}
