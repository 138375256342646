import React, { FC, ReactNode } from 'react'
import styled from '@emotion/styled'

interface SectionTitleProps {
    title: ReactNode
    className?: string
}

export const SectionTitle: FC<SectionTitleProps> = ({ title, className }) => {
    return (
        <H1
            className={`text-7xl leading-snug md:leading-tight text-black font-bold ${className}`}
            style={{ fontFamily: 'HelveticaNeue-Bold' }}
        >
            {title}
        </H1>
    )
}

const H1 = styled.h1`
    @media (min-width: 1440px) and (max-width: 1460px) {
        font-size: 4.2rem;
    }
`
