import React, { CSSProperties, FC, PropsWithChildren } from 'react'
import styled from '@emotion/styled'
interface ParagraphProps {
    style?: CSSProperties
}

export const Paragraph: FC<PropsWithChildren<ParagraphProps>> = ({ children, style }) => {
    return (
        <P className="text-2xl" style={style}>
            {children}
        </P>
    )
}

const P = styled.p`
    font-size: 18px;
    color: #5d5d5d;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 700;
    font-family: 'PingFangSC-Regular';
`
