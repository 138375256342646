import React, { FC } from 'react'
import styles from './index.module.css'

import { useI18n } from 'core/language/api'

import type { BaseDelivery } from 'core/delivery/models'

interface DeliveryAddressProps {
    detail?: Omit<BaseDelivery, 'isDefault'>
    topBorder?: boolean
    onClick?: () => void
}

export const DeliveryAddress: FC<DeliveryAddressProps> = ({
    detail: { firstName, lastName, companyName, streetAddress, apartment, city, state, zipCode, phone } = {
        firstName: '',
        lastName: '',
        companyName: '',
        streetAddress: '',
        apartment: '',
        city: '',
        state: '',
        zipCode: '',
        phone: ''
    },
    detail,
    topBorder = false,
    onClick
}) => {
    const {
        methods: { t }
    } = useI18n()

    return (
        <div className={styles.delivery} onClick={onClick}>
            {topBorder && <div className={styles.addrSplitImg} />}
            {detail ? (
                <div className={styles.Information}>
                    <div className={styles.info}>{`${firstName} ${lastName}`}</div>
                    <div className={styles.info}>{companyName}</div>
                    <div className={styles.info}>{streetAddress}</div>
                    <div className={styles.info}>{apartment}</div>
                    <div className={styles.info}>
                        {city}, {state} {zipCode}
                    </div>
                    <div className={styles.info}>{phone && `Phone : ${phone}`}</div>
                </div>
            ) : (
                <div className={styles.selectDelivery}>{t('client.order.select_address')}</div>
            )}
            {onClick && <div className={styles.deliveryIcon}></div>}
        </div>
    )
}
