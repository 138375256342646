import { useWeb3React } from '@web3-react/core'
import { useMemo } from 'react'

import { getWalletEnv, WalletEnum } from 'shared/config'
import { useMemoizedFn } from 'shared/hooks'
import { injected } from './connectors'

export const useWeb3 = () => {
    const isInMetaMask = useMemoizedFn(() => getWalletEnv() === WalletEnum.METAMASK)

    const hasEthereum = useMemo(() => !!window?.ethereum, [])

    const { activate, deactivate } = useWeb3React()

    const connectMetaMask = useMemoizedFn(async () => {
        await activate(injected)
        return (await injected.getAccount()) as string
    })
    const disconnectMetaMask = useMemoizedFn(async () => deactivate())

    return {
        hasEthereum,
        methods: {
            isInMetaMask,
            connectMetaMask,
            disconnectMetaMask
        }
    }
}

export * from './utils'
