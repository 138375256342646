import React, { FC, useState, useCallback, useMemo } from 'react'
import styled from '@emotion/styled'

import { useApiError, useShowMessage, useResponsive } from 'shared/hooks'
import { Reg } from 'shared/utils'
import { SignUpPost } from 'core/auth/models'

import { Input } from 'antd'
import { Reaptcha } from 'modules/auth/components/common/Reaptcha'
import { VerificationCode } from '../components/VerificationCode'

import { Header } from '../components/header'

interface Props {
    userEmail: string
    bindEmail: (data: SignUpPost) => Promise<void>
}

const emailMsg = 'Please enter your new email address'
const authCodeMsg = 'Please enter the verification code'

export const Email: FC<Props> = ({ userEmail, bindEmail }) => {
    const { md } = useResponsive()
    const {
        methods: { error, success }
    } = useShowMessage()
    const {
        methods: { handleError }
    } = useApiError('User', error as any, 'auth')

    const [email, setEmail] = useState('')
    const isEmail = useMemo(() => {
        return Reg.email.test(email)
    }, [email])
    const [showReaptcha, setShowReaptcha] = useState(false)
    const [recaptchaToken, setRecaptchaToken] = useState('')
    const [authCode, setAuthCode] = useState('')
    const onVerify = useCallback((token: string) => {
        setRecaptchaToken(token)
        setTimeout(() => {
            setShowReaptcha(false)
        }, 800)
    }, [])
    const resendAuthCode = useCallback(() => {
        setShowReaptcha(true)
        setRecaptchaToken('')
        setAuthCode('')
    }, [setShowReaptcha, setRecaptchaToken, setAuthCode])

    const handleSave = useCallback(() => {
        if (!email) {
            error(emailMsg)
            return
        }
        if (!authCode) {
            error(authCodeMsg)
            return
        }
        const submitData = {
            mobile: '',
            email: email,
            password: '',
            authCode
        }
        bindEmail(submitData)
            .then(() => {
                success('Email bind successfully')
                setAuthCode('')
                setEmail('')
                setRecaptchaToken('')
            })
            .catch(handleError)
    }, [authCode, bindEmail, email, error, handleError, success])

    const isDisabled = useMemo(() => {
        if (showReaptcha) {
            return true
        }
        if (recaptchaToken) {
            return true
        }
        if (!isEmail) {
            return true
        }

        return false
    }, [isEmail, recaptchaToken, showReaptcha])

    const handleChangeEmail = useCallback(
        value => {
            if (showReaptcha || recaptchaToken) {
                setRecaptchaToken('')
                setShowReaptcha(false)
            }
            setEmail(value)
        },
        [recaptchaToken, showReaptcha]
    )

    return (
        <Wrapper>
            <Header name="Email" onClick={handleSave} />
            <div className="email">
                <span>Current binding Email:</span>
                {userEmail}
            </div>
            <div className="formItem">
                <div className="label">{emailMsg}</div>
                <div className="value">
                    <Input
                        style={{ width: md ? '354px' : '100%', height: '40px' }}
                        value={email}
                        onChange={e => handleChangeEmail(e.target.value)}
                        placeholder={'Please enter'}
                    />
                    <div
                        className={`btn ${!isDisabled ? 'active' : ''}`}
                        onClick={() => !isDisabled && setShowReaptcha(true)}
                    >
                        Get email verification code
                    </div>
                </div>
                <div className="reaptcha">
                    {showReaptcha && <Reaptcha onVerify={onVerify} />}
                    {recaptchaToken && (
                        <VerificationCode
                            email={email}
                            recaptchaToken={recaptchaToken}
                            onResend={resendAuthCode}
                            onError={err => {
                                handleError(err)
                                setRecaptchaToken('')
                            }}
                            verificationCodeType={'bind'}
                        />
                    )}
                </div>
            </div>
            <div className="formItem">
                <div className="label">{authCodeMsg}</div>
                <div className="value">
                    <Input
                        style={{ width: md ? '354px' : '100%', height: '40px' }}
                        value={authCode}
                        onChange={e => setAuthCode(e.target.value)}
                        placeholder={'Please enter'}
                    />
                </div>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    .email {
        margin-top: 30px;
        font-family: Roboto-Regular;
        font-size: 18px;
        color: #000000;
        letter-spacing: 0;
        line-height: 26px;
        span {
            margin-right: 28px;
        }
    }
    .formItem {
        margin-top: 30px;
        .value {
            display: flex;
        }
        .label {
            margin-bottom: 10px;
            font-family: Roboto-Regular;
            font-size: 18px;
            color: #000000;
            line-height: 26px;
        }
        .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 261px;
            height: 40px;
            margin-left: 10px;
            background: rgba(0, 0, 0, 0.3);
            font-family: Roboto-Bold;
            font-size: 18px;
            color: #ffffff;
            cursor: pointer;
        }
        .btn.active {
            background: rgba(0, 0, 0, 1);
            cursor: pointer;
        }
        .reaptcha {
            margin-top: 10px;
            .g-recaptcha {
                justify-content: flex-start !important;
            }
        }
        .ant-input {
            font-family: Roboto-Regular;
            font-size: 18px;
            &::placeholder {
                color: #999 !important;
            }
        }
    }
    @media (max-width: 768px) {
        .formItem {
            .value {
                display: block;
            }
            .btn {
                margin-left: 0;
                margin-top: 10px;
            }
        }
    }
`
