import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useMountedPromiseFn } from 'shared/hooks'
import { services } from 'core/delivery/data-access'
import { actions, selectors } from 'core/delivery/state'
import { DeliveryTypeEnum } from 'core/delivery/models'

export const useDeliveryList = (type?: DeliveryTypeEnum) => {
    const dispatch = useDispatch()

    const [empty, setEmpty] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const list = useSelector(selectors.getList)
    const billingList = useSelector(selectors.getBillingList)

    const getListApi = useMountedPromiseFn(services.getList)
    const getList = useCallback(() => {
        setLoading(true)
        return getListApi(type || DeliveryTypeEnum.DELIVERY)
            .then(list => {
                setLoading(false)
                if (type === DeliveryTypeEnum.BILLING) {
                    dispatch(actions.setBillingList({ list }))
                } else {
                    dispatch(actions.setList({ list }))
                }
                setEmpty(!list.length)
                return list
            })
            .catch(err => {
                setLoading(false)
                return Promise.reject(err)
            })
    }, [dispatch, getListApi, type])

    const removeApi = useMountedPromiseFn(services.remove)
    const remove = useCallback(
        (id: string) => {
            setLoading(true)
            return removeApi(id)
                .then(getList)
                .catch(err => {
                    setLoading(false)
                    return Promise.reject(err)
                })
        },
        [getList, removeApi]
    )

    return {
        empty,
        list,
        billingList,
        loading,
        methods: {
            getList,
            remove
        }
    }
}
