import React, { FC } from 'react'
import styled from '@emotion/styled'

import icon from '../assets/delete.png'

interface Props {
    onClick?: () => void
}

export const DeleteIcon: FC<Props> = ({ onClick }) => {
    return (
        <Delete>
            <img src={icon} onClick={onClick} alt="" />
        </Delete>
    )
}
const Delete = styled.div`
    flex-shrink: 0;
    cursor: pointer;
    img {
        width: 20px;
        height: 20px;
    }
`
