import { stringify } from 'querystring'

import { pickValue, pickValueAndReferences, SuccessItemResponse } from 'shared/request'
import { pathJoin } from 'shared/utils'
import { clientHttp } from 'shared/request'
import { Language } from 'core/language/models'
import { CLIENT_URI_PREFIX } from 'shared/config'
import { OrderFormData, OrderStatusEnum, PaymentSecrets, TouristOrderFormData } from 'core/order/models'
import { convertToOrder, convertToOrders, OrderDTO, StoreReference } from '../DTO'
import { BaseDelivery } from 'core/delivery/models'

const URI = 'order'
const TURI = '/order/tourist'

export const PAYMENT_CALLBACK_URL = `${CLIENT_URI_PREFIX}/pay/stripe/payment_token/confirm`

export const create = (formData: OrderFormData, language: Language) => {
    const qsObj = { language }
    const url = `${URI}?${stringify(qsObj)}`
    return clientHttp.post<SuccessItemResponse<string[]>>(url, { ...formData }).then(pickValue)
}

export const getList = (language: Language, status?: OrderStatusEnum | '') => {
    return clientHttp
        .get<SuccessItemResponse<OrderDTO[], StoreReference>>(URI, { language, status })
        .then(pickValueAndReferences)
        .then(({ value, references }) => convertToOrders(value, references))
}

export const getDetail = (orderCode: string, language: Language) => {
    const url = pathJoin(URI, orderCode)
    return clientHttp
        .get<SuccessItemResponse<OrderDTO, StoreReference>>(url, { language })
        .then(pickValueAndReferences)
        .then(({ value, references }) => convertToOrder(value, references))
}

export const cancel = (orderCode: string) => {
    const url = pathJoin(URI, orderCode, 'cancel')
    return clientHttp.put(url, {})
}

export const pay = (orderCode: string) => {
    const url = pathJoin(URI, orderCode, 'pay')
    return clientHttp.post(url, {})
}

export const confirm = (orderCode: string) => {
    const url = pathJoin(URI, orderCode, 'confirm')
    return clientHttp.put(url, {})
}

export const remove = (orderCode: string) => {
    const url = pathJoin(URI, orderCode)
    return clientHttp.delete(url)
}

export const getStripePayKey = () => {
    const url = pathJoin('pay', 'stripe', 'secret')
    return clientHttp.get<SuccessItemResponse<string>>(url).then(pickValue)
}

export const getStripeSecrets = (param: { amount: number } | { orderCode: string }) => {
    const url = pathJoin('pay', 'stripe', 'secret')
    return clientHttp.post<SuccessItemResponse<PaymentSecrets>>(url, { ...param }).then(pickValue)
}

export const confirmPaymentMethod = (orderCode: string, paymentMethodId: string) => {
    const url = pathJoin('pay', 'stripe', 'payment_method', 'confirm')
    return clientHttp.post<SuccessItemResponse<void>>(url, { orderCode, paymentMethodId }).then(pickValue)
}

export const changeTip = (orderCode: string, type: number, tip: number) => {
    const url = pathJoin(URI, orderCode, 'tip')
    return clientHttp.put<SuccessItemResponse<void>>(url, { type, value: tip })
}

export const getOnlinePayKey = (orderCode: string) => {
    const url = pathJoin(URI, orderCode, 'price')
    return clientHttp.get<SuccessItemResponse<any>>(url).then(pickValue)
}

export const touristCreate = (formData: TouristOrderFormData, language: Language) => {
    const qsObj = { language }
    const url = `${TURI}?${stringify(qsObj)}`
    return clientHttp.post<SuccessItemResponse<string[]>>(url, { ...formData }).then(pickValue)
}

export const updateBillingAddress = (
    orderCode: string,
    billingDeliveryId: string,
    billInfo?: BaseDelivery
) => {
    const url = `/${URI}/${orderCode}/billAddress`
    const params = billingDeliveryId ? { billingDeliveryId } : { billInfo }
    return clientHttp.put<SuccessItemResponse<void>>(url, params)
}
