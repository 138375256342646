import { useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { actions, selectors } from 'core/language/state'
import { Language, SwitchLanguageOption, I18nMap, TranslateVariable } from 'core/language/models'
import { storage } from 'core/language/data-access'
import { translate } from '../translate'

const switchAllLanguageOptions: SwitchLanguageOption[] = [
    {
        label: 'English',
        value: 'en-US'
    }
]

export const useI18n = () => {
    const dispatch = useDispatch()

    const currentLang = useSelector(selectors.getCurrentLanguage)
    const i18nMap = useSelector(selectors.getI18nMap)

    const switchLanguageOptions = useMemo(() => {
        return switchAllLanguageOptions.filter(({ value }) => !!i18nMap[value])
    }, [i18nMap])

    const setI18nMap = useCallback(
        (i18n: I18nMap) => {
            dispatch(actions.setI18nMap({ i18nMap: i18n }))
        },
        [dispatch]
    )

    const setLang = useCallback(
        (language: Language) => {
            if (JSON.stringify(i18nMap) !== '{}' && !i18nMap[language]) {
                return
            }
            if (!language) {
                language = storage.getLanguage() as Language
            }
            dispatch(actions.setCurrentLanguage({ language }))
            storage.setCurrentUseLanguage(language)
        },
        [dispatch, i18nMap]
    )

    const changeLang = useCallback(
        (language: Language) => {
            if (!language) {
                language = (storage.getLanguage() as Language) || 'en-US'
            }
            storage.setLanguage(language)
            setLang(language)
        },
        [setLang]
    )

    const setDefaultLanguage = useCallback(
        (defaultLanguage?: string) => {
            // Preferred language for storage
            let language = 'en-US'
            changeLang(language as Language)
        },
        [changeLang]
    )

    const t = useCallback(
        (key: string, variable?: TranslateVariable) => {
            return translate(i18nMap, currentLang, key, variable)
        },
        [i18nMap, currentLang]
    )

    const translateWithLang = useCallback(
        (lang: Language, key: string, variable?: TranslateVariable) => {
            return translate(i18nMap, lang, key, variable)
        },
        [i18nMap]
    )

    const removeLanguage = useCallback(() => {
        storage.removeLanguage()
        storage.removeCurrentUseLanguage()
    }, [])

    return {
        lang: currentLang,
        i18nMap,
        switchLanguageOptions,
        methods: {
            setI18nMap,
            changeLang,
            setDefaultLanguage,
            t,
            translateWithLang,
            removeLanguage
        }
    }
}
