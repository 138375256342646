import React, { FC, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styled from '@emotion/styled'

import { useFrameDetail } from 'core/management/frame/api/useFrameDetail'
import { useFrameRentDetail } from 'core/management/frame/api/useFrameRentDetail'

import { useApiError, useLoading, useMemoizedFn, useResponsive } from 'shared/hooks'
import { copy, pattern, stringOmit, stringOmitMiddle } from 'shared/utils'

import { Tabs, Toast } from 'antd-mobile'
import { Radio } from '@mantine/core'
import { Image } from 'antd'
import { Carousel, Embla } from '@mantine/carousel'
import { stateType } from './List'

import copyIcon from 'assets/copy.png'
import selectArrowIcon from 'assets/select-arrow.png'
import left_active from './assets/left_active.png'
import defaultImg from 'assets/default.png'
import moment from 'moment'

enum PlayContentType {
    SINGLE_NFT = 'single_nft',
    SLIDESHOW = 'slideshow'
}

export const FrameDetail: FC = () => {
    const navigate = useNavigate()
    const { id, rent_id } = useParams()

    const { md } = useResponsive()
    const location = useLocation()
    const { state, pathname } = location
    const framesType = (state && (state as stateType).framesType) || 'owned'
    const authorizedType = state && (state as stateType).authorizedType
    const {
        methods: { handleError }
    } = useApiError('FrameDetail')

    const [coverImg, setCoverImg] = useState('')

    const {
        loading,
        detail: frameDetail,
        methods: { getDetail, play, playSlide }
    } = useFrameDetail(id!)

    const {
        loading: rentLoading,
        detail: rentDetail,
        methods: { getRentDetail }
    } = useFrameRentDetail(rent_id!)

    useLoading(loading || rentLoading)
    useEffect(() => {
        if (pathname.includes('rent')) {
            getRentDetail()
                .then(({ now_playing_playlist }) => {
                    if (now_playing_playlist) {
                        setCoverImg(now_playing_playlist.nfts[0].preview_url)
                    }
                })
                .catch(handleError)
        } else {
            getDetail()
                .then(({ now_playing_playlist }) => {
                    if (now_playing_playlist) {
                        setCoverImg(now_playing_playlist.nfts[0].preview_url)
                    }
                })
                .catch(handleError)
        }
    }, [getDetail, getRentDetail, handleError, pathname])

    const detail = useMemo(() => {
        if (pathname.includes('rent')) {
            return rentDetail
        } else {
            return frameDetail
        }
    }, [frameDetail, pathname, rentDetail])

    const handleCopy = useMemoizedFn(() => {
        if (!detail) return
        copy(detail.device_id)
        Toast.show('Serial number copied to clip board')
    })
    const [embla, setEmbla] = useState<Embla | null>(null)
    const [activeId, setActiveId] = useState<number>(0)

    const handleSelectedSnapChange = useMemoizedFn(() => {
        if (!embla) return
        setActiveId(embla.selectedScrollSnap())
    })
    useEffect(() => {
        if (!embla) return
        embla.on('select', handleSelectedSnapChange)
    }, [embla, handleSelectedSnapChange])

    const [playContentType, setPlayContentType] = useState<string>('')
    useEffect(() => {
        detail?.now_playing_playlist && setPlayContentType(PlayContentType.SLIDESHOW)
        detail?.now_playing_nft && setPlayContentType(PlayContentType.SINGLE_NFT)
    }, [detail])

    const [playNftData, setPlayNftData] = useState<{
        id: string
        name: string
        type: 'image' | 'video'
    } | null>(null)
    const [playSlideShowData, setPlaySlideShowData] = useState<{ id: number; name: string } | null>(null)
    const locationState = location.state as {
        id: string
        name: string
        type: 'image' | 'video'
        framesType?: string
        authorizedType?: string
    }
    const hasLocationState = useMemo(() => !!locationState?.id && !!locationState?.name, [locationState])
    useEffect(() => {
        if (hasLocationState) {
            if (locationState?.type) {
                setPlayNftData({
                    id: locationState.id,
                    name: locationState.name,
                    type: locationState.type
                })
                setPlayContentType(PlayContentType.SINGLE_NFT)
                return
            }
            setPlaySlideShowData({
                id: +locationState?.id,
                name: locationState.name
            })
            setPlayContentType(PlayContentType.SLIDESHOW)
            return
        }
        if (detail?.now_playing_nft) {
            setPlayNftData({
                id: detail.now_playing_nft.nft_id,
                name: detail.now_playing_nft.name,
                type: detail.now_playing_nft.type
            })
        }
        if (detail?.now_playing_playlist) {
            setPlaySlideShowData({
                id: detail.now_playing_playlist.id,
                name: detail.now_playing_playlist.name
            })
        }
    }, [detail, hasLocationState, locationState])
    const handleSubmitPlayContent = useMemoizedFn(() => {
        if (!id) return
        if (playContentType === PlayContentType.SINGLE_NFT) {
            if (!playNftData) return

            play({
                id: playNftData.id,
                type: playNftData.type,
                device_id: id
            }).catch(handleError)
            return
        }

        if (playContentType === PlayContentType.SLIDESHOW) {
            if (!playSlideShowData) return

            playSlide({
                playlist_id: playSlideShowData.id,
                device_id: id
            })
                .then(() => {
                    getDetail()
                        .then(({ now_playing_playlist }) => {
                            if (now_playing_playlist) {
                                setCoverImg(now_playing_playlist.nfts[0].preview_url)
                            }
                        })
                        .catch(handleError)
                })
                .catch(handleError)
        }
    })

    return (
        <Container>
            <Header>
                <img
                    width={md ? 30 : 24}
                    height={md ? 30 : 24}
                    src={left_active}
                    alt="frame"
                    onClick={() => navigate('/management/frames', { state: { framesType, authorizedType } })}
                />
                <div className="title">{detail?.device_name}</div>
            </Header>
            <div className={md ? 'pcStyle' : ''}>
                {md ? (
                    <Cover>
                        <CoverList>
                            {detail?.now_playing_playlist &&
                                detail?.now_playing_playlist.nfts.map((item, index) => (
                                    <Image
                                        width={75}
                                        height={75}
                                        onClick={() => setCoverImg(item.preview_url)}
                                        key={index}
                                        src={item.preview_url}
                                        placeholder={<img src={defaultImg} alt="NFT" />}
                                        fallback={defaultImg}
                                        preview={false}
                                        style={{ marginBottom: '6px' }}
                                    />
                                ))}
                        </CoverList>
                        <CoverImg>
                            <ImageWrapper>
                                {detail?.now_playing_playlist ? (
                                    <Image
                                        width={'100%'}
                                        height={'100%'}
                                        src={coverImg}
                                        placeholder={<img src={defaultImg} alt="NFT" />}
                                        fallback={defaultImg}
                                        preview={false}
                                    />
                                ) : (
                                    <Image
                                        width={'100%'}
                                        height={'100%'}
                                        src={detail?.now_playing_url}
                                        placeholder={<img src={defaultImg} alt="NFT" />}
                                        fallback={defaultImg}
                                        preview={false}
                                    />
                                )}
                            </ImageWrapper>
                        </CoverImg>
                    </Cover>
                ) : detail?.now_playing_playlist ? (
                    <Carousel
                        my={10}
                        slideSize={326}
                        slideGap={0}
                        loop
                        withControls={false}
                        getEmblaApi={setEmbla}
                    >
                        {detail.now_playing_playlist.nfts.map((item, index) => (
                            <Carousel.Slide key={item.id}>
                                <ImageWrapper
                                    style={{
                                        transform: `scale(${activeId === index ? '1' : '0.9'})`
                                    }}
                                >
                                    <Image
                                        src={item.preview_url}
                                        width={322}
                                        height={322}
                                        placeholder={<img src={defaultImg} alt="NFT" />}
                                        fallback={defaultImg}
                                        preview={false}
                                    />
                                </ImageWrapper>
                            </Carousel.Slide>
                        ))}
                    </Carousel>
                ) : (
                    <SingleMediaWrapper>
                        <ImageWrapper>
                            <Image
                                src={detail?.now_playing_url}
                                width={322}
                                height={322}
                                placeholder={<img src={defaultImg} alt="NFT" />}
                                fallback={defaultImg}
                                preview={false}
                            />
                        </ImageWrapper>
                    </SingleMediaWrapper>
                )}
                <Content>
                    <Tabs>
                        <Tabs.Tab title={<TabName>Play</TabName>} key="play">
                            <PlayWrapper>
                                <Radio.Group
                                    orientation="vertical"
                                    value={playContentType}
                                    onChange={setPlayContentType}
                                >
                                    <PlayRow>
                                        <Radio
                                            value={PlayContentType.SINGLE_NFT}
                                            label={<RadioLabel>Single NFT</RadioLabel>}
                                            color="#000"
                                            styles={{
                                                radio: { border: '1px solid #000' },
                                                labelWrapper: { width: '100px' }
                                            }}
                                        />
                                        <SelectorWrapper
                                            onClick={() =>
                                                navigate(`/management/frames/${id}/select/nft`, {
                                                    state: { id: playNftData?.id }
                                                })
                                            }
                                        >
                                            <PlayValue>
                                                {stringOmit(
                                                    playNftData?.name,
                                                    pattern.test(playNftData?.name || '') ? 8 : 15
                                                ) || 'Choose NFT'}
                                            </PlayValue>
                                            <Image
                                                src={selectArrowIcon}
                                                width={16}
                                                height={16}
                                                preview={false}
                                            />
                                        </SelectorWrapper>
                                    </PlayRow>
                                    <PlayRow>
                                        <Radio
                                            value={PlayContentType.SLIDESHOW}
                                            label={<RadioLabel>Slideshow</RadioLabel>}
                                            color="#000"
                                            styles={{
                                                radio: { border: '1px solid #000' },
                                                labelWrapper: { width: '100px' }
                                            }}
                                        />
                                        <SelectorWrapper
                                            onClick={() =>
                                                navigate(`/management/frames/${id}/select/playlist`, {
                                                    state: { selectId: `${playSlideShowData?.id}` }
                                                })
                                            }
                                        >
                                            <PlayValue>
                                                {stringOmit(
                                                    playSlideShowData?.name,
                                                    pattern.test(playSlideShowData?.name || '') ? 8 : 15
                                                ) || 'Choose Playlist'}
                                            </PlayValue>
                                            <Image
                                                src={selectArrowIcon}
                                                width={16}
                                                height={16}
                                                preview={false}
                                            />
                                        </SelectorWrapper>
                                    </PlayRow>
                                </Radio.Group>
                                <PlayButton onClick={handleSubmitPlayContent}>Play</PlayButton>
                            </PlayWrapper>
                        </Tabs.Tab>
                        <Tabs.Tab title={<TabName>Details</TabName>} key="details">
                            <Basic>
                                <TopLabel>Name: </TopLabel>
                                <Value>{detail?.device_name}</Value>
                            </Basic>
                            <Basic>
                                <TopLabel>Status: </TopLabel>
                                <Value>
                                    {detail?.online === null && (
                                        <span style={{ color: '#ACACAC' }}>Unknown</span>
                                    )}
                                    {detail?.online === true && (
                                        <span style={{ color: '#34B074' }}>Online</span>
                                    )}
                                    {detail?.online === false && (
                                        <span style={{ color: '#DD4040' }}>Offline</span>
                                    )}
                                </Value>
                            </Basic>
                            <Basic>
                                <TopLabel>Network: </TopLabel>
                                <Value>{detail?.wifi}</Value>
                            </Basic>
                            <Basic>
                                <TopLabel>Location: </TopLabel>
                                <Value>{detail?.location}</Value>
                            </Basic>
                            <Basic>
                                <TopLabel>Position: </TopLabel>
                                <Value>{detail?.position}</Value>
                            </Basic>
                            {!md && <Divider />}
                            <Basic>
                                <TopLabel>Address: </TopLabel>
                                <Value>{detail?.location}</Value>
                            </Basic>
                            <Basic>
                                <BottomLabel>Serial Number: </BottomLabel>
                                <Value className="flex">
                                    <span>
                                        {detail?.device_id ? stringOmitMiddle(10, 5, detail?.device_id) : ''}
                                    </span>
                                    <CopyIcon src={copyIcon} onClick={handleCopy} alt="walletAddress" />
                                </Value>
                            </Basic>
                            <Basic>
                                <BottomLabel>Model: </BottomLabel>
                                <Value>{detail?.model}</Value>
                            </Basic>
                            <Basic>
                                <BottomLabel>System Version: </BottomLabel>
                                <Value>{detail?.firmware_version}</Value>
                            </Basic>
                            {!md && detail?.end_time && <Divider />}
                            {detail?.end_time && (
                                <Basic>
                                    <BottomLabel>Rental End Time: </BottomLabel>
                                    <Value>{moment(detail?.end_time).format('MM/DD/YYYY HH:mm')}</Value>
                                </Basic>
                            )}
                        </Tabs.Tab>
                    </Tabs>
                </Content>
            </div>
        </Container>
    )
}

const Container = styled.div`
    .pcStyle {
        display: flex;
        padding-bottom: 60px;
    }
`

const Header = styled.div`
    padding: 32px 0 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
        height: 30px;
        cursor: pointer;
    }
    .title {
        font-family: Roboto-Medium;
        font-size: 30px;
        color: #000000;
        line-height: 28px;
        font-weight: 500;
        margin-left: 14px;
    }
    @media (max-width: 768px) {
        padding: 10px 20px;

        height: 54px;

        .img {
            height: 24px;
        }
        .title {
            margin: 0 10px;
            font-family: OpenSans-SemiBold;
            font-size: 21px;
            color: #000000;
            font-weight: 600;
        }
    }
`

const PlayButton = styled.div`
    height: 50px;
    background: #000000;
    border-radius: 24.5px;
    font-family: OpenSans-SemiBold;
    font-size: 18px;
    color: #ffffff;
    line-height: 50px;
    text-align: center;
    font-weight: 600;
    margin: 36px 0 15px;
    padding: 0 40px;
    cursor: pointer;
    display: inline-block;
    @media (max-width: 768px) {
        display: block;
        margin: 50px auto;
        width: 222px;
        padding: 0;
        font-size: 16px;
    }
`

const PlayValue = styled.div`
    flex: 1;
    font-family: OpenSans-Bold;
    font-size: 12px;
    color: #000000;
    font-weight: 700;
`

const SelectorWrapper = styled.div`
    display: flex;
    background: #f1f1f1;
    border-radius: 24.5px;
    padding: 10px 15px 10px 20px;
    width: 168px;
    height: 36px;
    cursor: pointer;
    margin-left: 56px;
    @media (max-width: 768px) {
        margin-left: 0px;
    }
`

const RadioLabel = styled.span`
    width: 100px;
    font-family: OpenSans-SemiBold;
    font-size: 16px;
    color: #838383;
`

const PlayRow = styled.div`
    width: 336px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    @media (max-width: 768px) {
        width: auto;
        margin-bottom: 0px;
    }
`

const PlayWrapper = styled.div`
    margin: 46px 0;
    .mantine-16twdu0:checked {
        background: #000 !important;
        border-color: #000 !important;
    }
    @media (max-width: 768px) {
        margin: 28px 0;
    }
`

const CopyIcon = styled.img`
    margin-left: 10px;
    width: 24px;
    height: 24px;
    cursor: pointer;
`

const Divider = styled.div`
    margin: 20px 0;
    height: 1px;
    background-color: rgba(151, 151, 151, 1);
`

const Value = styled.div`
    flex: 1;
    font-family: OpenSans-SemiBold;
    font-size: 16px;
    color: #000000;
    font-weight: 600;
`

const BottomLabel = styled.div`
    width: 133px;
    font-family: OpenSans-SemiBold;
    font-size: 16px;
    color: #838383;
    letter-spacing: 0;
    font-weight: 600;
`

const TopLabel = styled.div`
    width: 92px;
    font-family: OpenSans-SemiBold;
    font-size: 16px;
    color: #838383;
    letter-spacing: 0;
    font-weight: 600;
`

const Basic = styled.div`
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        margin: 15px 0;
    }
`

const TabName = styled.div`
    font-family: HelveticaNeue-Bold;
    font-size: 28px;
    color: #000000;
    line-height: 30px;
    font-weight: 700;
    @media (max-width: 768px) {
        font-family: OpenSans-Bold;
        font-size: 16px;
        color: #000000;
        font-weight: 700;
    }
`

const Content = styled.div`
    margin-left: 50px;
    --adm-color-border: none;
    .adm-tabs-tab-wrapper {
        padding: 0 !important;
    }
    .adm-tabs-tab {
        margin: 0;
        padding: 8px 0 15px !important;
    }
    .adm-tabs-content {
        padding: 0px !important;
    }
    .adm-tabs-header {
        margin: 0px !important;
    }
    .adm-tabs {
        --active-line-height: 5px !important;
    }
    .adm-tabs-tab-wrapper-stretch {
        flex: none !important;
        margin-right: 50px !important;
    }
    @media (max-width: 768px) {
        margin: 28px 32px;
        .adm-tabs-tab {
            margin: 0 auto !important;
            padding: 8px 0 10px !important;
        }
        .adm-tabs {
            --active-line-height: 2px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .adm-tabs-tab-wrapper-stretch {
            flex: auto !important;
            margin-right: 0px !important;
        }
        .adm-tabs-header {
            width: 134px;
        }
    }
`

const ImageWrapper = styled.div`
    width: 100%;
    height: 52.4rem;
    border: 2px solid rgba(0, 0, 0, 1);
    @media (max-width: 576px) {
        width: 326px;
        height: 326px;
    }
`

const SingleMediaWrapper = styled.div`
    margin: 10px 0;
    display: flex;
    justify-content: center;
`

const Cover = styled.div`
    display: flex;
    justify-content: space-between;
    width: 50%;
    @media (max-width: 576px) {
        width: 100%;
        flex-direction: column;
    }
`
const CoverList = styled.div`
    width: 88px;
    height: 528px;
    padding-right: 8px;
    overflow: auto;
    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }
    &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #535353;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        background: #ededed;
    }
`

const CoverImg = styled.div`
    width: 52.4rem;
    box-sizing: border-box;
    img {
        width: 100%;
        height: auto;
    }

    @media (max-width: 576px) {
        width: 100%;
        padding-left: 0;
        margin-bottom: 10px;
    }
`
