import { Request, RequestMethod, SuccessResponse } from './shared-request'
import {
    ADMIN_IMAGE_UPLOAD_URL,
    ADMIN_URI_PREFIX,
    API_URI_PREFIX,
    PLATFORM_URI_PREFIX,
    SELLER_IMAGE_UPLOAD_URL,
    SELLER_URI_PREFIX,
    CLIENT_URI_PREFIX,
    COMMON_URI_PREFIX,
    ADDRESS_URI_PREFIX
} from 'shared/config'

export const useJsapi = () => {
    return process.env.NODE_ENV === 'production' && (window as any).platform === 'plugin'
}

const NFT_URL_PREFIX =
    process.env.NODE_ENV === 'production' ? 'https://api.metasill.io' : 'http://198.199.107.176:8000'

const makeHttp = (urlPrefix = '') => {
    const request = Request({
        urlPrefix
        // getHeaders: () => {
        //     const token = getToken();
        //     if (token) {
        //         return {
        //             AUTHORIZATION: `bearer ${token}`
        //         };
        //     }
        //     return {};
        // }
    })
    const ajaxGet: RequestMethod['get'] = (...args) => request.get(...args)

    const ajaxPost: RequestMethod['post'] = (...args) => request.post(...args)

    const ajaxPut: RequestMethod['put'] = (...args) => request.put(...args)

    const ajaxDelete: RequestMethod['delete'] = (...args) => request.delete(...args)

    const addRequestInterceptor: RequestMethod['addRequestInterceptor'] = (...args) =>
        request.addRequestInterceptor(...args)

    return {
        get: ajaxGet,
        post: ajaxPost,
        put: ajaxPut,
        delete: ajaxDelete,
        addRequestInterceptor
    }
}

export const adminHttp = makeHttp(ADMIN_URI_PREFIX)
export const http = makeHttp(PLATFORM_URI_PREFIX)
export const apiHttp = makeHttp(API_URI_PREFIX)
export const addressTokenHttp = makeHttp(ADDRESS_URI_PREFIX)
export const nftHttp = makeHttp(`${NFT_URL_PREFIX}/api/v2`)
export const frameHttp = makeHttp(`${NFT_URL_PREFIX}/api/v1`)
// export const frameRentHttp = makeHttp(API_URI_PREFIX)
export const sellerHttp = makeHttp(SELLER_URI_PREFIX)
export const clientHttp = makeHttp(CLIENT_URI_PREFIX)
export const commonHttp = makeHttp(COMMON_URI_PREFIX)

// export const apiHttp = Request({
//     urlPrefix: API_URI_PREFIX,
//     getHeaders: () => {
//         const token = getToken();
//         if (token) {

//             return {
//                 AUTHORIZATION: `bearer ${token}`
//             };
//         }
//         return {};
//     }
// })

export const apiHttpWithoutAuth = makeHttp(API_URI_PREFIX)

export const rootHttp = makeHttp()

export const uploadImage = (formData: FormData) => {
    return rootHttp.post<SuccessResponse<{ fileUrl: string }>>(ADMIN_IMAGE_UPLOAD_URL, formData).then(
        ({
            data: {
                data: { fileUrl }
            }
        }) => {
            return fileUrl
        }
    )
}

export const uploadSellerImage = (formData: FormData) => {
    return rootHttp.post<SuccessResponse<{ fileUrl: string }>>(SELLER_IMAGE_UPLOAD_URL, formData).then(
        ({
            data: {
                data: { fileUrl }
            }
        }) => {
            return fileUrl
        }
    )
}
