import { frameHttp, pickData } from 'shared/request'

import type { SuccessResponse } from 'shared/request'
import type { Playlist, delParams, PlaylistFormData } from 'core/management/frame/model'

const URI = 'playlist'

export const getList = (walletaddress: string) =>
    frameHttp.get<SuccessResponse<Playlist[]>>(`${URI}?account=${walletaddress}`).then(pickData)

export const add = (name: string) => frameHttp.post(URI, { name })

export const editName = (id: number, name: string) => frameHttp.put(`${URI}/${id}`, { name })

export const edit = (id: number, params: PlaylistFormData) => frameHttp.put(`${URI}/${id}`, params)

export const remove = (params: delParams[]) => frameHttp.delete(URI, '', params)
