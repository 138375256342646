import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useMemoizedFn, useMountedPromiseFn, useShowMessage, useSignInRedirect } from 'shared/hooks'
import { useI18n } from 'core/language/api'
import { services } from 'core/scanRent/data-access'

import {
    getIsTourist,
    getPaymentClientSecret,
    getScannedUrl,
    removeCreateOrderPageType,
    removeScannedUrl,
    removeScanProductsInfo,
    removeScanRentPersonalInfo
} from 'shared/config'
import { CreateOrderParams } from '../models'
import { alertError } from 'shared/utils'

export const useCreateOrder = () => {
    const navigate = useNavigate()

    const [loading, setLoading] = useState<boolean>(false)
    const { getToken } = useSignInRedirect()

    const {
        lang,
        methods: { t }
    } = useI18n()
    const {
        methods: { showSingleButtonModal }
    } = useShowMessage()
    const handleRedirect = useMemoizedFn((orderCode?: string) => {
        const to = getToken() ? `/orders/${orderCode}` : getScannedUrl()!
        navigate(to, { replace: true })
    })

    const getAccountInfoApi = useMemoizedFn(services.getAccountInfo)
    const cancelOrderApi = useMemoizedFn(services.cancelOrder)
    const handleCannotPay = useMemoizedFn(async (orderCode: string, errMsg?: string) => {
        !getToken() && (await cancelOrderApi(getPaymentClientSecret()!))
        setTimeout(() => {
            showSingleButtonModal({
                message: errMsg || t('error.pay_failed'),
                type: 'fail',
                buttonText: getToken() ? t('View order') : t('Reorder'),
                onButtonClick: () => handleRedirect(orderCode)
            })
        }, 500)
    })
    const payOrderFormRef = useRef<any>()
    const handlePayment = useMemoizedFn((orderCode: string) => {
        payOrderFormRef?.current.submit((error?: string) => {
            try {
                setTimeout(() => {
                    setLoading(false)
                    if (error) {
                        handleCannotPay(orderCode, error)
                        return
                    }
                    removeCreateOrderPageType()
                    removeScanRentPersonalInfo()
                    removeScanProductsInfo()
                    setLoading(true)
                    let num: number = 0
                    const setAccount = setInterval(() => {
                        num = num + 1
                        getAccountInfoApi(getPaymentClientSecret()!)
                            .then(res => {
                                if (!res) {
                                    if (num >= 30) {
                                        clearInterval(setAccount)
                                        handleCannotPay(orderCode, error)
                                        return
                                    }
                                    return
                                }
                                clearInterval(setAccount)
                                removeScannedUrl()
                                navigate('/self_rent/payment_success', { state: res })
                            })
                            .catch(err => {
                                alertError(err, 'getAccountInfoApi => ')
                                clearInterval(setAccount)
                                setLoading(false)
                                return Promise.reject(err)
                            })
                    }, 1000)
                }, 100)
            } catch (error) {
                alertError(error, 'handlePayment => ')
            }
        })
    })

    const orderApi = useMountedPromiseFn(services.order)
    const touristCreateOrderApi = useMountedPromiseFn(services.touristCreateOrder)
    const createOrderApi = useMountedPromiseFn(services.createOrder)
    const createOrder = useMemoizedFn((params: CreateOrderParams) => {
        setLoading(true)
        return (() => {
            return getToken()
                ? getIsTourist()
                    ? touristCreateOrderApi(
                          {
                              ...params,
                              commentOfStore: {},
                              deliveryType: 0,
                              clientSecret: getPaymentClientSecret()
                          },
                          lang
                      )
                    : orderApi(
                          {
                              items: params.items,
                              couponCode: params.couponCode,
                              commentOfStore: {},
                              deliveryType: 0,
                              deliveryId: params.personalInfoId ? params.personalInfoId : '',
                              billingDeliveryId: params.billInfoId ? params.billInfoId : '',
                              clientSecret: getPaymentClientSecret()
                          },
                          lang
                      )
                : createOrderApi({ ...params, clientSecret: getPaymentClientSecret() }, lang)
        })()
            .then(orderIds => handlePayment(orderIds[0]))
            .catch(err => {
                setLoading(false)
                return Promise.reject(err)
            })
    })

    return {
        loading,
        payOrderFormRef,
        methods: {
            createOrder
        }
    }
}
