import React, { useEffect, useState } from 'react'
import { i18nMap as i18nMapConfig } from 'shared/config/language'
import { useLanguageInit } from 'shared/hooks'
import { getAddressToken, getToken, getWalletToken } from 'shared/config'
import {
    adminHttp,
    apiHttp,
    http,
    rootHttp,
    sellerHttp,
    clientHttp,
    nftHttp,
    frameHttp,
    addressTokenHttp
} from 'shared/request'

export interface Props {
    children: React.ReactNode
}
console.log(getAddressToken())

export const FeatureMain: React.FC<Props> = ({ children }) => {
    const [initialized, setInitialized] = useState<boolean>(false)

    // Internationalized language settings
    useLanguageInit(i18nMapConfig)

    useEffect(() => {
        ;[http, apiHttp, adminHttp, rootHttp, sellerHttp, clientHttp, addressTokenHttp].forEach(method => {
            method.addRequestInterceptor(p => {
                const token = getToken()
                let headers = p.headers
                if (token) {
                    headers = {
                        ...headers,
                        AUTHORIZATION: `bearer ${token}`
                    }
                }
                return { ...p, headers }
            })
        })
        ;[nftHttp, frameHttp].forEach(method => {
            method.addRequestInterceptor(p => {
                const token = getWalletToken()
                let headers = p.headers
                if (token) {
                    headers = {
                        ...headers,
                        Authorization: `Bearer ${getAddressToken() ? getAddressToken() : token}`
                    }
                }
                return { ...p, headers }
            })
        })
        setInitialized(true)
    }, [])

    if (!initialized) return null

    return <>{children}</>
}

export default FeatureMain
