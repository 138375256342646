import React, { CSSProperties, FC, PropsWithChildren } from 'react'
import styled from '@emotion/styled'

interface LineButtonProps {
    onClick?: () => void
    className?: string
    style?: CSSProperties
    disabled?: boolean
}

export const LineButton: FC<PropsWithChildren<LineButtonProps>> = ({
    onClick,
    children,
    className,
    style,
    disabled = false
}) => {
    return (
        <Content
            className={`
                group bg-white border border-black inline-block rounded-full flex items-center justify-center hover:bg-black transition
                ${className}
                ${disabled ? 'disabled' : ''}
            `}
            style={style}
            onClick={() => !disabled && onClick?.()}
        >
            <div className="leading-8 px-14 inline-block text-black group-hover:text-white">{children}</div>
        </Content>
    )
}

const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    font-family: Roboto-Medium;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
    font-size: 18px;
    color: #000;
    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
    @media (max-width: 768px) {
        min-width: 77px;
        height: 28px;
        margin-right: 0;
        > div {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 28px;
            font-size: 15px;
            color: #000;
            letter-spacing: 0;
            line-height: 16px;
        }
    }
`
